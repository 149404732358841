import React, { useState } from "react";
import {
  Logo,
  ForgotImg,
  appleicon,
  facebookicon,
  googleicon,
  Maps,
  verifyImg,
  Globalimg,
  Verify2,
  VerifyUpper2,
  Personal,
  Plane,
  Login1,
  Building,
} from "../../../assets";
import Inputs from "../../../components/common/inputs";
import AuthButton from "../../../components/common/Buttons";
import { Link, useNavigate } from "react-router-dom";
import { MdExpandLess } from "react-icons/md";
import { Sliders } from "../../../components/common";
import { toast } from "react-toastify";
import httpRequest from "../../../axios";
import { useDispatch, useSelector } from "react-redux";
import { FORGOT_REQUEST } from "../../../constants/apiEndPoints";
import { setemail } from "../../../lib/Redux/slices/userslice";

const SliderArr = [
  <div className="relative h-[700px] flex justify-center items-center">
  {/* Background Image with Blur */}
  <div
    className="absolute inset-0 bg-cover bg-center filter blur-[3px]"
    style={{
      backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${Personal})`,
      backgroundSize: "cover",
      backgroundPosition: "center",
    }}
  ></div>

  {/* Content */}
  <div className="relative w-[90%] flex flex-col items-center space-y-6 font-Montserrat">
    <img className="h-auto w-[75%]" src={Plane} alt="Plane" />
    <div className="flex flex-col items-center space-y-3">
      <h1 className="text-white text-3xl font-black text-center">
        FLIGHT BOOKINGS
      </h1>
      <p className="text-white text-center text-xl font-semibold">
        GET YOUR FLIGHTS BOOKED WITH EASE WITHIN MYGUIDEON
      </p>
    </div>
  </div>
</div>,
  <div
    style={{
      backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${verifyImg})`,
      backgroundSize: "cover",
      backgroundPosition: "center",
      position: "relative",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    }}
    className="sm:h-[700px] h-[600px]"
  >
    {/* Background Image with Blur */}
    <div
      className="absolute inset-0 bg-cover bg-center filter blur-[3px]"
      style={{
        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${verifyImg})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    ></div>

    {/* Content */}
    <div className="relative w-[90%] flex flex-col items-center space-y-6 font-Montserrat">
      <img className="h-auto w-[45%]" src={Globalimg} alt="Globalimg" />
      <div className="flex flex-col items-center space-y-3">
        <h1 className="text-white text-center sm:text-3xl text-xl font-black">
          LIVE INTERACTIVE MAPS
        </h1>
        <p className="text-white text-center sm:text-xl text-base font-semibold">
          EXPLORE MAPS IN REALTIME AND PLAN WHAT YOU WANNA DO
        </p>
      </div>
    </div>
  </div>,
  <div className="relative sm:h-[700px] h-[600px] flex justify-center items-center">
  {/* Background Image with Blur */}
  <div
    className="absolute inset-0 bg-cover bg-center filter blur-[3px]"
    style={{
      backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${Login1})`,
      backgroundSize: "cover",
      backgroundPosition: "center",
    }}
  ></div>

  {/* Content */}
  <div className="relative w-[90%] flex flex-col items-center space-y-6 font-Montserrat">
    <img className="h-auto w-[45%]" src={Building} alt="Building" />
    <div className="flex flex-col items-center space-y-3">
      <h1 className="text-white sm:text-3xl text-xl font-black">
        ACCOMODATIONS
      </h1>
      <p className="text-white text-center sm:text-xl text-base font-semibold">
        WORRIED ABOUT YOUR STAY? WE GOT THAT COVERED TOO. FIND THE BEST
        ACCOMMODATIONS AROUND YOU.
      </p>
    </div>
  </div>
</div>,
];

const Forgot = () => {
  const { email } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [Loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    email: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleForgot = async () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!formData?.email) {
      toast.error("Email address is required");
      return;
    }

    if (!emailRegex.test(formData.email)) {
      toast.error("Invalid email format");
      return;
    }
    setLoading(true);
    try {
      const response = await httpRequest.post(FORGOT_REQUEST, {
        email: formData?.email,
      });

      if (response.status == 200 || response.status == 201) {
        toast.success(response?.data?.message);
        dispatch(setemail(formData?.email));
        navigate("/forgot/verify-code");
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="2xl:p-24 md:p-16 p-6 min-h-screen w-full grid lg:grid-cols-2 grid-cols-1 2xl:gap-32 gap-10 font-Montserrat">
      <div className=" col-span-1 space-y-6">
        <Link to={"/"}>
          <img src={Logo} alt="Logo.svg" />
        </Link>
        <div className="space-y-4">
          <p>
            <Link className="flex items-center" to={"/login"}>
              <MdExpandLess className="-rotate-90 text-xl" /> Back to login
            </Link>
          </p>
          <h1 className="font-Montserrat font-bold text-[40px]">
            Forgot your password?
          </h1>
          <p className="text-BlackishGreen font-normal text-base leading-5">
            Don’t worry, happens to all of us. Enter your email below to recover
            your password
          </p>
        </div>
        <div className="grid sm:grid-cols-2 gap-3">
          <div className=" col-span-2">
            <Inputs
              label={"Email"}
              sizelabel={"14px"}
              sizeinput={"16px"}
              type={"eamil"}
              color={"#1C1B1F"}
              fontweight={"400"}
              ActiveColor={"#1C1B1F"}
              htmlfor={"email"}
              name={"email"}
              onchange={handleInputChange}
            />
          </div>
          <div className="col-span-2">
            <AuthButton
              loading={Loading}
              onclick={handleForgot}
              text={"Submit"}
            />
          </div>
        </div>
        <div className="relative py-4 col-span-2">
          <div className="w-full relative border-b-[2px] flex justify-center items-center">
            <span className="absolute bg-white px-4">Or login with</span>
          </div>
        </div>
        <div className="col-span-2 grid sm:grid-cols-2 gap-5">
          <div className=" border border-[#8DD3BB] flex items-center justify-center rounded-lg p-[10px] cursor-pointer hover:bg-[#8DD3BB]">
            <img src={facebookicon} alt="facebook.svg" />
          </div>
          <div className=" border border-[#8DD3BB] flex items-center justify-center rounded-lg p-[10px] cursor-pointer hover:bg-[#8DD3BB]">
            <img src={googleicon} alt="facebook.svg" />
          </div>
        </div>
      </div>
      <div className="col-span-1 rounded-[30px] overflow-hidden ">
        <Sliders items={SliderArr} />
      </div>
    </div>
  );
};

export default Forgot;
