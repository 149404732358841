import React from 'react'

const Divider = ({ color, or }) => {
  return (
    <>
      {
        or ? 
        <div className='w-full justify-between flex ' >
           <div className={`w-[45%] bg-[${color}] h-[0.5px] mt-[10px] mb-[10px] `} ></div>
             <h1 className='text-[16px] font-medium ' >or</h1>
           <div className={`w-[45%] bg-[${color}] h-[0.5px] mt-[10px] mb-[10px] `} ></div>
        </div>
      :
          <div className={`w-full bg-[${color}] h-[0.5px] mt-[10px] mb-[10px] `} ></div>
      }
    </>
  )
}

export default Divider