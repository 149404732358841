import React from "react";
import CustomCarusel from "../../components/CustomCarusel/CustomCarusel";
import { GoggleGroup, Oval } from "../../assets";
import { IoHeart } from "react-icons/io5";
import Imagescard from "../../components/images/Imagescard";
import { ArrowRight, Shiningstar } from "../../assets";
import { FaLocationDot } from "react-icons/fa6";
import ImagecardRound from "../../components/images/ImageRound";

const MapActivit = () => {
  const modelcontent = (
    <>
      <div className="flex flex-col lg:flex-row mt-[15px] items-center   ">
        <div className=" w-[100%] lg:w-[40%] ">
          <div className=" w-[100%] lg:w-[96%] mr-[0px] lg:mr-[4%]  rounded-xl overflow-hidden ">
            <CustomCarusel h={260} />
          </div>
        </div>
        {/* /////// */}
        <div className="flex flex-col mt-[40px] lg:mt-[0px] w-[100%] lg:w-[60%]  ">
          <div className="flex justify-between">
            <h1 className="text-[19px] lg:text-[24px] font-bold text-start w-[90%] leading-6 ">
              Scuba Diving
            </h1>
            <IoHeart
              style={{ color: "#F26A64", width: "25px", height: "25px" }}
            />
          </div>
          {/* // */}
          <div className="flex w-full mt-[15px] ">
            <div className="flex flex-col w-[60%]  ">
              {/* //// */}
              <div className="flex h-[30px] items-center  ">
                <div className="rounded-md h-[25px] flex justify-center items-center  font-medium text-[12px] ">
                  <Imagescard img={Shiningstar} h={10} w={10} />
                  <Imagescard img={Shiningstar} h={10} w={10} />
                  <Imagescard img={Shiningstar} h={10} w={10} />
                  <Imagescard img={Shiningstar} h={10} w={10} />
                  <Imagescard img={Shiningstar} h={10} w={10} />
                </div>
                <h1 className="text-[14px] font-semibold ml-[5px]  ">5.0</h1>
                <p className="text-[14px] font-semibold ml-[2px]  ">{`(190)`}</p>
                <p className="text-[14px] font-semibold ml-[2px] ">{`See Reiview`}</p>
              </div>
              {/* //// */}
              <div className="flex h-[30px] items-center mt-[7px] ">
                <div className="rounded-md h-[25px] w-[35px] flex justify-center items-center  font-medium text-[12px] bg-[#E8F6F1] ">
                  <FaLocationDot style={{ color: "#E8F6F1" }} />
                </div>
                <h1 className="text-[12px] font-bold ml-[5px]  ">
                  Istambol,Turkey
                </h1>
              </div>
              {/* //// */}
            </div>

            <div className="flex flex-col justify-start items-end w-[40%] mt-[7px] ">
              <div className="flex  w-full ">
                <h2 className="text-[#4D594D] text-[12px] font-medium ml-[50px] ">
                  Each Person
                </h2>
              </div>
              <h1 className="text-[#007BFF] text-[16px] lg:text-[24px] font-bold mt-[-5px]  ">
                $240/night
              </h1>
              <h2 className="text-[#4D594D] text-[12px] font-medium mt-[-5px]">
                For 30 Mints
              </h2>
            </div>
          </div>

          <div className="flex w-full ">
            {/* <div className='w-[40%] border border-red-700 ' ></div> */}
            <div className="w-[100% items-start flex flex-col mt-[7px] ">
              <div className="flex">
                <p className="text-[12px] font-bold ml-[5px] text-[#7C7C7C] text-start w-[90%]  ">
                  Aenean augue justo, feugiat quis ullamcorper sit amet,
                  elementum non elit. See more
                </p>
              </div>
              {/* //// */}
              <div className="flex items-center mt-[7px]  ">
                <div className="rounded-md h-[25px] w-[40px] ml-[5px]  flex justify-center items-center  font-medium text-[12px] ">
                  <Imagescard img={GoggleGroup} h={10} w={10} />
                </div>
                <p className="text-[12px] font-bold ml-[5px] text-[#808080] text-start ">
                  Gümüssuyu Mah. Inönü Cad. No:8, Istanbul 344437
                </p>
              </div>
              {/* //// */}
              <div className="flex justify-between w-[100%] items-center ">
                <h1 className="text-[18px] font-bold text-[#808080]">
                  SERVICE PROVIDER
                </h1>
                <div className="flex items-center ">
                  <ImagecardRound h={50} w={50} img={Oval} />
                  <h1 className="text-[#394149] text-[18px] font-semibold ml-[10px] ">
                    Richard Abhina
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* // */}

      {/* //// */}
      <div className="w-full flex justify-end  ">
        <button className="bg-[#007BFF] py-2 px-8 rounded-md mt-5 grid grid-cols-7 w-[100%] lg:w-[470px] ">
          <h1 className="text-white text-lg font-semibold col-span-6">
            Proceed to Booking
          </h1>
          <p className="col-span-1 mt-[6px] ">
            <Imagescard img={ArrowRight} h={5} w={5} />
          </p>
        </button>
      </div>
    </>
  );

  return (
    <>
      <div>{modelcontent}</div>
    </>
  );
};

export default MapActivit;
