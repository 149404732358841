import React from "react";
import { Route, Routes } from "react-router-dom";
import { Forgot, Login, Password, Signup, Verify } from "./pages/Auth";
import PersonalInfo from "./pages/Auth/signup/personals";
import { PublicRoute } from "./routes/routes/PublicRoute";
import { PrivateRoute } from "./routes/routes";
import Home from "./pages/Home";
import { useLocation } from "react-router-dom";
import {
  Activities,
  Pastflights,
  PaymentVouchers,
  PersonalInformation,
  SecurityPrivacy,
  Settings,
  Subscription,
} from "./pages";
import { InProgressComponent } from "./components";
import { BookDetails1, BookDetails2, FlightHome } from "./pages/Flights";
import { HostelDetial, HotelBooking, HotelTerms } from "./pages/Hotels";
import MainHotelsDetails from "./pages/Hotels/HotelDetailsMain";
import Map from "./pages/Map/Map";
import HotelBookingCartPage from "./pages/Hotels/hotelbookingcartpage";
import { Gallery } from "./pages/destinations";
import TicketsBooked from "./pages/Tickits";
import { MainChat } from "./pages/chat";
import FavroutesFlights from "./pages/FavroutesBookings/FavroutesFlights";
import FavroutesActivites from "./pages/FavroutesBookings/FavroutesActivites";
import FavroutesAccomonations from "./pages/FavroutesBookings/Accomondations";
import FavroutesAccomonationsN from "./pages/Favrouties/Accomondations";
import FavroutesActivitesN from "./pages/Favrouties/FavroutesActivites";
import FavroutesFlightsN from "./pages/Favrouties/FavroutesFlights";
import NotFound from "./pages/NoFound";
import MainActivities from "./pages/Home/MainActivities";
import MainAccomondations from "./pages/Home/MainAccomondations";
import ClasicMap from "./pages/Map/ClasicMap";
import { useEffect } from "react";
import { toast } from "react-toastify";

const routes = [
  { path: "/destination", element: <Gallery />, type: "private" },
  { path: "/login", element: <Login />, type: "user" },
  { path: "/user-profile", element: <Home />, type: "private" },
  { path: "/hotel-terms", element: <HotelTerms />, type: "private" },
  { path: "/signup", element: <Signup />, type: "user" },
  { path: "/forgot", element: <Forgot />, type: "user" },
  { path: "/verify-code", element: <Verify />, type: "user" },
  { path: "/forgot/verify-code", element: <Verify />, type: "user" },
  { path: "/settings", element: <Settings />, type: "private" },
  { path: "/personal-info", element: <PersonalInformation />, type: "private" },
  { path: "/inprogress", element: <InProgressComponent />, type: "user" },
  { path: "/settings/pastflights", element: <Pastflights />, type: "private" },
  { path: "/settings/activites", element: <Activities />, type: "private" },

  { path: "/user/Activity", element: <MainActivities />, type: "private" },
  {
    path: "/user/Accomondation",
    element: <MainAccomondations />,
    type: "private",
  },

  {
    path: "/settings/booking/flights",
    element: <FavroutesFlights />,
    type: "private",
  },
  {
    path: "/settings/booking/activities",
    element: <FavroutesActivites />,
    type: "private",
  },
  {
    path: "/settings/booking/accomodations",
    element: <FavroutesAccomonations />,
    type: "private",
  },
  {
    path: "/settings/favroutes/flights",
    element: <FavroutesFlightsN />,
    type: "private",
  },
  {
    path: "/settings/favroutes/activities",
    element: <FavroutesActivitesN />,
    type: "private",
  },
  {
    path: "/settings/favroutes/accomodations",
    element: <FavroutesAccomonationsN />,
    type: "private",
  },
  {
    path: "/settings/subscription",
    element: <Subscription />,
    type: "private",
  },
  {
    path: "/settings/subscription",
    element: <Subscription />,
    type: "private",
  },
  { path: "/messages", element: <MainChat />, type: "private" },

  // { path: "/flights", element: <FlightHome />, type: "user" },
  // { path: "/flights/booking", element: <BookDetails1 />, type: "user" },
  // { path: "/flights/booking/cart", element: <BookDetails2 />, type: "user" },
  {
    path: "/flights/ticket/:id",
    element: <TicketsBooked />,
    type: "private",
  },
  {
    path: "/security-privacy",
    element: <SecurityPrivacy />,
    type: "private",
  },
  {
    path: "/payment-vouchers",
    element: <PaymentVouchers />,
    type: "private",
  },

  {
    path: "/hotels/ticket/:id",
    element: <HotelTerms />,
    type: "private",
  },

  {
    path: "/forgot/verify-success/password",
    element: <Password />,
    type: "user",
  },
  { path: "/user/profile/bio", element: <PersonalInfo />, type: "user" },
  { path: "/Newchat", element: <MainChat />, type: "private" },
];

const App = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    toast.dismiss();
  }, [pathname]);
  return (
    <>
      <Routes>
        {/* All Types of User Can Access These Routes */}
        <Route path="*" element={<NotFound />} />
        <Route path="/" element={<Map />} />
        <Route path="/clasic-map" element={<ClasicMap />} />

        {/* // Flights Routes */}

        <Route path="/flights" element={<FlightHome />} />
        <Route path="/flights/booking" element={<BookDetails1 />} />
        <Route path="/flights/booking/cart" element={<BookDetails2 />} />

        {/* // Hotels Routes */}

        <Route path="/hotels" element={<HotelBooking />} />
        <Route path="/hotel-details/:id" element={<HostelDetial />} />
        <Route
          path="/hotel-details/room-booking/:id"
          element={<MainHotelsDetails />}
        />
        <Route path="/hotels/Booking/:id" element={<HotelBookingCartPage />} />

        {/* / * / ------------ */}
        {/* // Private and Public Routes */}

        {routes.map(({ path, element, type }, index) => (
          <Route
            key={index}
            path={path}
            element={
              type === "user" ? (
                <PublicRoute>{element}</PublicRoute>
              ) : (
                <PrivateRoute>{element}</PrivateRoute>
              )
            }
          />
        ))}
      </Routes>
    </>
  );
};

export default App;
