import React, { useEffect, useState } from "react";
import httpRequest from "../../axios";
import { toast } from "react-toastify";
import { Vouchers_API } from "../../constants/apiEndPoints";
import { useDispatch, useSelector } from "react-redux";
import { setAllFlightsBasicPrices } from "../../lib/Redux/slices/cartslice";
import Slider from "react-slick";
import { setHotelsCartData } from "../../lib/Redux/slices/hotelsDataslice";

const Vouchers = ({
  setVoucherCodes = () => {},
  Total = 0,
  Sfee = 0,
  type = "flight",
}) => {
  const dispatch = useDispatch();
  const selectedFlight = useSelector((state) => state.flight.selectedFlight);
  const { FlightBasicPrices = [] } = useSelector((state) => state.cart);
  const { HoteslCartData = [] } = useSelector((state) => state.hotels_data);

  const [allVouchers, setAllVouchers] = useState([]);
  const [VoucherCode, setVoucherCode] = useState("");

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 5000,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const GetAllvouchers = async () => {
    try {
      const response = await httpRequest.get(`${Vouchers_API}`);
      if (response.status === 200 || response.status === 201) {
        setAllVouchers(response.data);
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  };

  useEffect(() => {
    GetAllvouchers();
  }, []);

  const ApplyVoucher = async () => {
    if (!VoucherCode) return toast.error("Please Enter Voucher Code");

    const ApplyVoucher = allVouchers.filter(
      (item) => item.code === VoucherCode
    );
    if (ApplyVoucher.length > 0) {
      setVoucherCodes(ApplyVoucher[0]?.code);
      if (FlightBasicPrices.length > 0 && type == "flight") {
        const ServiceFee = FlightBasicPrices.filter((item) => {
          return item.title === "Service Fee";
        });

        const Discount = Math.round(
          ServiceFee[0]?.price * (ApplyVoucher[0]?.discount / 100)
        );

        const items = [
          {
            type: null,
            title: "Base Fare",
            price: selectedFlight?.price?.total,
            qty: 1,
            id: selectedFlight.id,
          },
          {
            type: null,
            title: "Service Fee",
            price: selectedFlight?.price?.total * 0.3,
            qty: 1,
            id: 236,
          },
          {
            type: null,
            title: "Discount",
            price: -Discount,
            qty: 1,
            id: 237,
          },
        ];
        dispatch(setAllFlightsBasicPrices(items));
        toast.success("Voucher Applied Successfully");
        console.log("If Runing..");
      } else {
        console.log("Else Runing..");
        const ServiceFee = HoteslCartData.filter((item) => {
          return item.title === "Service Fee";
        });
        const Discount = Math.round(
          ServiceFee[0]?.price * (ApplyVoucher[0]?.discount / 100)
        );

        const CartsDetails = [
          {
            title: "Base Fare ",
            price: Total,
          },
          {
            title: "Discount",
            price: -Discount,
          },
          {
            title: "Service Fee",
            price: Sfee,
          },
        ];
        dispatch(setHotelsCartData(CartsDetails));
        toast.success("Voucher Applied Successfully");
      }
    } else {
      toast.error("Voucher Not Found");
    }
  };

  const RenderData = allVouchers?.map((voucher, index) => {
    return (
      <div key={index} className="rounded-xl justify-between Voutchers h-max">
        <div className="w-[25%] p-5 relative">
          <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 -rotate-90">
            <h1 className="text-textcolor text-3xl font-extrabold leading-normal whitespace-nowrap">
              {voucher?.discount}% OFF
            </h1>
          </div>
        </div>
        <div className="h-[200px] border-dashed border-4 "></div>
        <div className="w-[70%] px-4 space-y-4">
          <h1 className="md:text-md text-xs font-extrabold leading-normal text-textcolor">
            Get {voucher?.discount}% Off on your next Booking
          </h1>
          <div className="p-4 bg-white bg-opacity-20 w-max rounded">
            <h1 className="md:text-xl text-base font-extrabold leading-normal text-white">
              {voucher?.code}
            </h1>
          </div>
          <p className="md:text-xs text-[10px] font-semibold leading-normal text-white">
            Terms & Conditions apply
          </p>
        </div>
      </div>
    );
  });

  return (
    <div className=" md:px-4 bg-white rounded-xl">
      <div className="grid md:grid-cols-2 grid-cols-1 md:w-[90%] w-full py-4 gap-10">
        <div className="rounded-xl flex items-center justify-between RedemVoucher h-max">
          <div className="w-[25%] p-5 relative">
            <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 -rotate-90">
              <h1 className="text-textcolor text-3xl font-extrabold leading-normal whitespace-nowrap">
                PROMOS
              </h1>
            </div>
          </div>
          <div className="h-[200px] border-dashed border-4 "></div>
          <div className="w-[70%] px-4 space-y-4">
            <h1 className="md:text-md text-sm font-extrabold leading-normal text-textcolor">
              Looking to add a Promotional code?
            </h1>
            <div className="p-4 bg-white bg-opacity-20 w-full rounded">
              <h1 className="md:text-xl text-base font-extrabold leading-normal text-white">
                <input
                  type="text"
                  placeholder="Enter Code"
                  onChange={(e) => setVoucherCode(e.target.value)}
                  value={VoucherCode}
                  className="text-white font-bold w-full bg-transparent outline-none border-none placeholder:text-white"
                />
              </h1>
            </div>
            <button
              onClick={ApplyVoucher}
              className="py-3 px-2 bg-[#44C769] rounded text-white font-semibold leading-normal text-[13px]"
            >
              Redeem Now
            </button>
          </div>
        </div>
        {allVouchers?.length > 0 ? (
          <Slider {...settings}>{RenderData}</Slider>
        ) : (
          <p>No Vouchers found</p>
        )}
      </div>
    </div>
  );
};

export default Vouchers;
