import React from "react";
import MainDataLayout from "../../layouts/MainDatalayout";
import Mainlayout from "../../layouts/Mainlayout";

const MainActivities = () => {
  return (
    <Mainlayout>
      <div className="grid grid-cols-1 font-Montserrat">
        <div
          style={{ boxShadow: "0px 8px 12px 0px rgba(62, 63, 122, 0.04)" }}
          className="bg-white p-3 rounded-xl"
        >
          <MainDataLayout>
            <div>
              <h1 className="text-red-600"> Not in this Milestone</h1>
            </div>
          </MainDataLayout>
        </div>
      </div>
    </Mainlayout>
  );
};

export default MainActivities;
