import React, { useEffect, useRef, useState } from "react";
import { PiDotsThree } from "react-icons/pi";
import { IoMdAttach, IoIosSend } from "react-icons/io";
import { BsEmojiSmile } from "react-icons/bs";
import { IoCheckmarkOutline, IoCheckmarkDoneSharp } from "react-icons/io5";

import Picker from "@emoji-mart/react";
import data from "@emoji-mart/data";
import Files from "./Files";
import { GET_MESSAGES, SEND_MESSAGES } from "../../constants/apiEndPoints";
import { useDispatch, useSelector } from "react-redux";
import httpRequest from "../../axios";
  import { toast } from 'react-toastify';
import { convertToLocalTime } from "../../constants/apiEndPoints/functions";
import { UploadAssests } from "../../components/common/UploadAssests";
import Loader from "../../components/common/loader";
import { setCurrentChatFiles } from "../../lib/Redux/slices/webData";

const ViewChat = ({ GetuserHistory = () => {} }) => {
  const dispatch = useDispatch();
  const [message, setMessage] = useState("");
  const [files, setFiles] = useState([]);
  const [messages, setMessages] = useState([]);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const { currentUser, user } = useSelector((state) => state.user);
  const messageContainerRef = useRef(null);

  const handleFileChange = (e) => {
    const selectedFiles = Array.from(e.target.files);
    const filePreviews = selectedFiles.map((file) => ({
      file,
      url: URL.createObjectURL(file),
    }));
    setFiles((prevFiles) => [...prevFiles, ...filePreviews]);
  };

  const removeFile = (index) => {
    setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  const addEmoji = (emoji) => {
    setMessage(message + emoji.native);
  };

  const [loading, setLoading] = useState(false);

  const handleSendMessage = async (e) => {
    e.preventDefault();
    if (message.trim() || files.length) {
      setLoading(true);
      const AllMediaLinks = [];
      try {
        if (files.length >= 1) {
          for (const media of files) {
            const Image = await UploadAssests(media.file);
            AllMediaLinks.push(Image);
          }
        }
        const payload = {
          from: user?._id,
          to: currentUser?._id,
          message: message,
          files: AllMediaLinks,
        };

        const response = await httpRequest.post(SEND_MESSAGES, payload);
        if (response.status === 200 || response.status === 201) {
          setLoading(false);
          setMessage("");
          setFiles([]);
          GetuserHistory();
          GetMessages();
        }
      } catch (error) {
        toast.error(error);
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    GetMessages();
  }, [currentUser]);

  const GetMessages = async () => {
    try {
      const response = await httpRequest.post(GET_MESSAGES, {
        from: user?._id,
        to: currentUser?._id,
      });
      if (response.status === 200 || response.status === 201) {
        setMessages(response?.data?.projectedMessages);
        dispatch(setCurrentChatFiles(response?.data?.allFilesUnique));
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  };

  const getFileType = (url) => {
    const extension = url.split(".").pop();
    switch (extension) {
      case "jpg":
      case "jpeg":
      case "png":
      case "gif":
      case "svg":
        return "image/*";
      case "mp4":
      case "avi":
      case "mkv":
        return "video/*";
      case "mp3":
      case "wav":
      case "ogg":
        return "audio/*";
      case "pdf":
        return "application/pdf";
      default:
        return "application/octet-stream";
    }
  };

  const renderMessage = (message, index) => (
    <div
      key={index}
      className={`w-full flex flex-col items-${
        message?.fromSelf ? "end" : "start"
      } my-2`}
    >
      {message?.message !== "" && (
        <div
          className={`p-3 ${
            message?.fromSelf ? "bg-primary text-white" : "bg-white"
          } w-max max-w-[90%] break-words text-ellipsis border rounded-md ${
            message?.fromSelf ? "rounded-tr-none" : "rounded-tl-none"
          }`}
        >
          <div>{message?.message}</div>
        </div>
      )}

      <div className="flex space-x-2 flex-wrap mt-2">
        {message.files &&
          message.files.length > 0 &&
          message.files.map((file, fileIndex) =>
            renderFilePreview({ url: file }, fileIndex, false)
          )}
      </div>
      <div className="flex justify-end mt-1">
        <span className="text-xs text-[#869AA9]">
          {convertToLocalTime(message.msgTime)}
        </span>
        {message.fromSelf && (
          <span className="text-[#869AA9] text-xs flex items-center space-x-1">
            {message?.read ? (
              <IoCheckmarkDoneSharp size={12} color="#F1674A" />
            ) : (
              <IoCheckmarkOutline size={12} color="#F1674A" />
            )}
          </span>
        )}
      </div>
    </div>
  );

  useEffect(() => {
    const interval = setInterval(() => {
      GetMessages();
      GetuserHistory();
    }, 4000);

    return () => clearInterval(interval);
  }, [currentUser]);

  const renderFilePreview = (file, index, canRemove = true) => {
    const isLocalFile = file.file !== undefined;
    const fileType = isLocalFile ? file.file.type : getFileType(file.url);
    const removeIcon = canRemove ? (
      <span
        className="absolute -top-[10px] -right-[6px] text-red-600 cursor-pointer"
        onClick={() => removeFile(index)}
      >
        ✕
      </span>
    ) : null;

    if (fileType.startsWith("image/")) {
      return (
        <div key={index} className="relative">
          <img
            src={file.url}
            alt="preview"
            className={`${
              isLocalFile ? "h-[50px] w-[50px]" : "h-auto w-[200px]"
            } object-cover`}
          />
          {removeIcon}
        </div>
      );
    } else if (fileType.startsWith("video/")) {
      return (
        <div key={index} className="relative">
          <video
            src={file.url}
            controls
            className={`${
              isLocalFile ? "h-[50px] w-[50px]" : "h-auto w-[200px]"
            } object-cover`}
          ></video>
          {removeIcon}
        </div>
      );
    } else if (fileType === "application/pdf") {
      return (
        <div key={index} className="relative">
          <embed
            src={file.url}
            type="application/pdf"
            className={`${
              isLocalFile ? "h-[50px] w-[50px]" : "h-auto w-[200px]"
            } object-cover`}
          />
          {removeIcon}
        </div>
      );
    } else if (fileType.startsWith("audio/")) {
      return (
        <div key={index} className="relative">
          <audio controls className="h-[50px] w-[50px]">
            <source src={file.url} type={fileType} />
          </audio>
          {removeIcon}
        </div>
      );
    } else {
      return (
        <div key={index} className="relative">
          <a
            href={file.url}
            download={isLocalFile ? file.file.name : `File-${index}`}
            className="h-[50px] w-[50px] flex items-center justify-center border"
          >
            {isLocalFile ? file.file.name : `File-${index}`}
          </a>
          {removeIcon}
        </div>
      );
    }
  };

  const scrollToBottom = () => {
    if (messageContainerRef.current) {
      messageContainerRef.current.scrollTop =
        messageContainerRef.current.scrollHeight;
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  return (
    <div className="w-full grid lg:grid-cols-3 grid-cols-1">
      {currentUser !== null ? (
        <div className="lg:col-span-2 col-span-1">
          <div className="w-full p-5 flex justify-between bg-white">
            <div className="h-[48px] w-[48px] rounded-full overflow-hidden">
              <img
                className="h-full w-full"
                src={
                  currentUser?.profilepic === ""
                    ? "https://lh3.googleusercontent.com/proxy/nNuOOkO-UspGOlN5zIfXjxp2dJtS0wA0EmbBMnUH1GRah17cX5K3k_tYFrX7DVjNe3FZ_4DrJoEKq0uGTuZ7X6P4wjdJAlOI9pu7I0NuIabmGwyFFmU2jO8L"
                    : currentUser?.profilepic
                }
                alt="user.svg"
              />
            </div>
            <div className="flex justify-between py-3 sm:w-[90%] w-[80%]">
              <h1 className="text-[#161616] font-semibold text-base">
                {currentUser?.username}
              </h1>
              <span className="text-[#869AA9] font-normal leading-normal cursor-pointer">
                <PiDotsThree size={24} />
              </span>
            </div>
          </div>
          <div
            ref={messageContainerRef}
            className="w-full bg-[#F8F8F8] h-[67vh] p-6 relative overflow-y-auto"
          >
            {messages.length > 0 &&
              messages.map((message, index) => renderMessage(message, index))}
          </div>
          {/* // Input Box to Send Message */}
          <div className="w-full h-[10vh] bg-white flex items-center justify-center relative">
            <div className="w-full -top-1/2 absolute left-0 flex space-x-2 flex-wrap">
              {files.map((file, index) => renderFilePreview(file, index))}
            </div>
            <form
              className="flex justify-between items-center w-full px-4"
              onSubmit={handleSendMessage}
            >
              <div className="flex space-x-5">
                {/* // File Upload any type */}
                <label>
                  <IoMdAttach
                    size={24}
                    className="text-[#869AA9] cursor-pointer"
                  />
                  <input
                    type="file"
                    multiple
                    onChange={handleFileChange}
                    className="hidden"
                  />
                </label>

                {/* // Emojis Picker */}
                <BsEmojiSmile
                  size={24}
                  className="text-[#869AA9] cursor-pointer"
                  onClick={() => setShowEmojiPicker(!showEmojiPicker)}
                />
                {showEmojiPicker && (
                  <div className="absolute bottom-[60px]">
                    <Picker
                      theme="light"
                      data={data}
                      onEmojiSelect={addEmoji}
                    />
                  </div>
                )}
              </div>

              {/* // Input to Send Message */}
              <input
                type="text"
                disabled={loading}
                className="text-[#B0BFCA] bg-transparent outline-none sm:w-[80%] w-[70%] px-4"
                placeholder="Write a message..."
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              />
              <button
                disabled={loading}
                type="submit"
                className="h-[36px] w-[36px] cursor-pointer bg-primary rounded-full flex items-center justify-center"
              >
                {loading ? (
                  <Loader />
                ) : (
                  <IoIosSend size={24} className="text-white" />
                )}
              </button>
            </form>
          </div>
        </div>
      ) : (
        <div className="lg:col-span-2 col-span-1 bg-white flex justify-center items-center">
          <h1 className="text-red-400">Select a chat to start messaging</h1>
        </div>
      )}

      <div className="col-span-1 lg:block hidden">
        <Files />
      </div>
    </div>
  );
};

export default ViewChat;
