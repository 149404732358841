import React from "react";
import Loader from "../../components/common/loader";

const DeletePopup = ({
  isOpen,
  onCancel = () => {},
  onDelete = () => {},
  loading = false,
}) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-10">
      <div className="bg-white rounded-lg p-8">
        <h2 className="text-xl font-bold mb-4 text-textcolor">
          Are you sure you want to delete?
        </h2>
        <div className="flex justify-end">
          <button
            disabled={loading}
            className="mr-2 px-4 py-2 rounded border border-gray-300 hover:bg-gray-100"
            onClick={onCancel}
          >
            Cancel
          </button>
          <button
            disabled={loading}
            className="px-4 py-2 rounded bg-red-500 text-white hover:bg-red-600"
            onClick={onDelete}
          >
            {loading ? <Loader /> : "Delete"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default DeletePopup;
