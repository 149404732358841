import React from 'react'
import { Navbar } from '../components'
import Footer from '../components/Footer'

const HotelbookingcartLayout = ({children}) => {
  return (
    <>
    <Navbar/>
    {
        children
    }
    <Footer/>
    </>
  )
}

export default HotelbookingcartLayout