import React, { useEffect, useState } from "react";
import { FiSearch } from "react-icons/fi";
import httpRequest from "../../axios";
  import { toast } from 'react-toastify';
import { GET_SPECIFIC_USER } from "../../constants/apiEndPoints";
import { useDispatch, useSelector } from "react-redux";
import { SetCurrentuser } from "../../lib/Redux/slices/userslice";
import { convertToLocalTime } from "../../constants/apiEndPoints/functions";
import { FaXmark } from "react-icons/fa6";

const users = ["", "", "", ""];

const Users = ({
  UserHistory,
  markedAsRead = () => {},
  setchatResponse = () => {},
}) => {
  const dispatch = useDispatch();
  const [SearchText, setSearchText] = useState("");
  const [FiltedUser, setFiltedUser] = useState(null);
  const [search, setSearch] = useState(false);

  const GetSpecificUser = async () => {
    if (!SearchText.length > 0) return toast.error("Please enter user name");

    try {
      const response = await httpRequest.get(
        `${GET_SPECIFIC_USER}/${SearchText}`
      );
      setFiltedUser(response?.data);
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  };

  const handleClick = () => {
    dispatch(SetCurrentuser(FiltedUser));
    setSearch(false);
    setFiltedUser(null);
    setSearchText("");
    setchatResponse(false);
  };

  return (
    <div className="w-full p-4 shadow min-h-[89vh] font-Montserrat bg-white">
      <div className="flex justify-between items-center">
        <div className="bg-[#F8F8F9] p-4 rounded-md w-full flex relative">
          <div className="w-[50px]">
            <FiSearch
              onClick={GetSpecificUser}
              size={24}
              className="text-[#D1D1E0] cursor-pointer"
            />
          </div>
          <input
            type="text"
            onFocus={() => setSearch(true)}
            onChange={(e) => setSearchText(e.target.value)}
            value={SearchText}
            placeholder="Search people or message..."
            className="w-[85%] bg-transparent outline-none"
          />

          {search &&
            (!FiltedUser ? (
              SearchText.length > 0 && (
                <div className="w-full h-20 absolute left-0 top-full shadow-lg bg-white text-red-500 text-sm flex justify-center items-center">
                  No User Found
                </div>
              )
            ) : (
              <div className="w-full h-20 absolute left-0 top-full shadow-lg bg-white">
                <div
                  onClick={handleClick}
                  className="w-full py-5 flex space-x-2 justify-between items-center cursor-pointer"
                >
                  <div className="h-[48px] w-[48px] rounded-full overflow-hidden">
                    <img
                      className="h-full w-full"
                      src={
                        FiltedUser?.profilepic === ""
                          ? "https://lh3.googleusercontent.com/proxy/nNuOOkO-UspGOlN5zIfXjxp2dJtS0wA0EmbBMnUH1GRah17cX5K3k_tYFrX7DVjNe3FZ_4DrJoEKq0uGTuZ7X6P4wjdJAlOI9pu7I0NuIabmGwyFFmU2jO8L"
                          : FiltedUser?.profilepic
                      }
                      alt="user.svg"
                    />
                  </div>
                  <div className="w-[75%]">
                    <div className="flex justify-between">
                      <h1 className="text-[#161616] font-semibold text-base">
                        {FiltedUser?.username}
                      </h1>
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </div>
        <FaXmark
          className="text-red-600 mx-2 text-xl cursor-pointer lg:hidden block "
          onClick={() => setchatResponse(false)}
        />
      </div>

      {UserHistory?.map((user) => (
        <div
          key={user?._id}
          onClick={() => {
            dispatch(SetCurrentuser(user));
            markedAsRead(user?._id);
            setchatResponse(false);
          }}
          className="w-full py-5 flex justify-between items-center cursor-pointer"
        >
          <div className="h-[48px] w-[48px] rounded-full overflow-hidden">
            <img
              className="h-full w-full"
              src={
                user?.profilepic === ""
                  ? "https://lh3.googleusercontent.com/proxy/nNuOOkO-UspGOlN5zIfXjxp2dJtS0wA0EmbBMnUH1GRah17cX5K3k_tYFrX7DVjNe3FZ_4DrJoEKq0uGTuZ7X6P4wjdJAlOI9pu7I0NuIabmGwyFFmU2jO8L"
                  : user?.profilepic
              }
              alt="user.svg"
            />
          </div>
          <div className="w-[83%]">
            <div className="flex justify-between">
              <h1 className="text-[#161616] font-semibold text-base">
                {user?.username}
              </h1>
              <span className="text-[#869AA9] text-[10px] font-normal leading-normal">
                {convertToLocalTime(user?.msgTime)}
              </span>
            </div>
            <div className="w-full flex justify-between">
              <p className="text-[#869AA9] text-xs font-normal leading-normal w-[90%] break-words text-ellipsis truncate">
                {user?.lastMessage || " "}
              </p>
              {user?.messageCount > 0 && (
                <div className="bg-primary text-white font-semibold text-xs py-1 px-2 rounded-md">
                  {user?.messageCount}
                </div>
              )}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Users;
