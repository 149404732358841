import React, { useState, useMemo, useEffect } from "react";
import FlightLayout from "../../../layouts/FlightLayout";
import { BreadcrumbArrow } from "../../../basecomponents";
import { useDispatch, useSelector } from "react-redux";
import {
  setPrevPathActive,
  setPrevPathDeActive,
  setpaths,
} from "../../../lib/Redux/slices/webData";
import { MdEmail } from "react-icons/md";
import Select from "react-select";
import countryList from "react-select-country-list";
import { CiCirclePlus } from "react-icons/ci";
import {
  TimeConverter,
  getAirlineName,
  getCityName,
} from "../../../lib/airline-iata-codes";
import { getAirlineImage } from "../../../lib/airline-iata-codes";
import {
  AirlineSeat,
  Airplane,
  Foodfast,
  FromToPlane,
  StopWatch,
  VisaIcon,
  Wifi,
  facebookicon,
  googleicon,
} from "../../../assets";
import Vouchers from "../../PaymentVouchers/Vouchers";
import { AddCards, Cart } from "../../../components";
import { setCart } from "../../../lib/Redux/slices/cartslice";
import moment from "moment";
import httpRequest from "../../../axios";
import {
  CARDS,
  FlightBooking,
  SOCIAL_LOGIN,
} from "../../../constants/apiEndPoints";
import { toast } from "react-toastify";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { IoCheckmarkCircle } from "react-icons/io5";
import {
  setPaymentSuccess,
  setSelectedFlight,
} from "../../../lib/Redux/slices/flightsSlice";
import { useGoogleLogin } from "@react-oauth/google";
import { setUser, setemail } from "../../../lib/Redux/slices/userslice";

const headers = [
  { label: "MyGuide", url: "/user-profile" },
  { label: "My World", url: "/" },
  { label: "Transport Booking", url: "/flights/booking" },
];

const FlightMenues = [
  {
    id: 2,
    title: "",
    icon: Airplane,
  },
  {
    id: 3,
    title: "",
    icon: Wifi,
  },
  {
    id: 4,
    title: "",
    icon: StopWatch,
  },
  {
    id: 5,
    title: "",
    icon: Foodfast,
  },
  {
    id: 6,
    title: "",
    icon: AirlineSeat,
  },
];

const options = [
  { value: "Male", label: "Male" },
  { value: "Female", label: "Female" },
];

const DocumentType = [
  {
    value: "Passport",
    label: "Passport",
  },
  {
    value: "ID Card",
    label: "ID Card",
  },
];

const BookDetails2 = () => {
  const dispatch = useDispatch();
  const [VoucherCode, setVoucherCode] = useState("");
  const selectedFlight = useSelector((state) => state.flight.selectedFlight);
  const { numOfpessengers = 1, paymentSuccess } = useSelector(
    (state) => state.flight
  );

  const dictionaries = useSelector((state) => state.flight.dictionaries);
  dispatch(setpaths(headers));
  const { paths = [] } = useSelector((state) => state.web);
  const { user } = useSelector((state) => state.user);
  const [Gender, setGender] = useState("");
  const [Country, setCountry] = useState("");
  const [checkMember, setCheckMember] = useState(false);
  const [AddCard, setAddCard] = useState(false);
  const [inputvalue, setInputvalue] = useState(1);
  const { total } = useSelector((state) => state.cart);
  const [selectedEquipments, setSelectedEquipments] = useState([]);
  const Countries = useMemo(() => countryList().getData(), []);

  useEffect(() => {
    if (paymentSuccess) {
      navigate("/flights");
    }
  }, []);

  const handleEquipmentClick = (equipment) => {
    setSelectedEquipments((prevSelected) =>
      prevSelected.some((item) => item.id === equipment.id)
        ? prevSelected.filter((item) => item.id !== equipment.id)
        : [...prevSelected, equipment]
    );
  };

  const [AllCards, setAllCards] = useState([]);
  const [loading, setLoading] = useState(false);

  const FetchCards = async () => {
    setLoading(true);
    try {
      const response = await httpRequest.get(CARDS);
      if (response.status === 200 || response.status === 201) {
        setAllCards(response?.data?.cards);
        setSelectedCard(response?.data?.cards[0]?._id);
        setLoading(false);
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (user !== null) {
      FetchCards();
    }
  }, [user]);

  const handleClick = (data) => {
    const obj = {
      type: null,
      title: data.heading,
      price: data.price,
      qty: 1,
      id: data.id,
    };
    dispatch(setCart(obj));
  };

  const handleInputChange = (e, id, type) => {
    e.stopPropagation();

    const index = total.findIndex((item) => item.id === id);
    if (index !== -1) {
      const updatedTotal = [...total];

      if (type === "plus") {
        updatedTotal[index] = {
          ...updatedTotal[index],
          qty: updatedTotal[index].qty + 1,
        };
        dispatch(setCart(updatedTotal));
      } else {
        if (updatedTotal[index].qty <= 0) {
          updatedTotal[index] = {
            ...updatedTotal[index],
            qty: 1,
          };
          dispatch(setCart(updatedTotal));
        } else {
          updatedTotal[index] = {
            ...updatedTotal[index],
            qty: updatedTotal[index].qty - 1,
          };
          dispatch(setCart(updatedTotal));
        }
      }
    }
  };

  const getQuantity = (id) => {
    const index = total.findIndex((element) => element.id === id);
    return index !== -1 ? total[index].qty : 0;
  };

  const TotalEquipments = [
    {
      id: 1,
      heading: "Additional Hold Baggage",
      subheading: "Add additional hold baggage at an additional cost",
      price: 68,
      qty: 1,
    },
    {
      id: 2,
      heading: "Excess Baggae",
      subheading: "Add excess baggage at an additional cost",
      price: 40,
      qty: 1,
    },
    {
      id: 3,
      heading: "Special Equipment",
      subheading:
        "Choose this option if there’s any special equipments with your luggage",
      price: 50,
      qty: 1,
    },
    {
      id: 4,
      heading: "Flexible change",
      subheading:
        "Allows you to change the date, time or destination of your flight at no or reduced cost.",
      price: 20,
      qty: 1,
    },
    {
      id: 5,
      heading: "Flexible cancellation",
      subheading:
        "Allows you to cancel your flight and obtain a refund or credit note.",
      price: 30,
      qty: 1,
    },
  ];

  const [PersonalDetails, setPersonalDetails] = useState({
    name: "",
    email: "",
    phone: "",
    country: "",
    postCode: "",
    address: "",
    town: "",
    dob: "",
  });

  const [CVC, setCVC] = useState("");

  const [passengerDetails, setPassengerDetails] = useState([
    {
      firstName: "",
      lastName: "",
      gender: null,
      email: "",
      phone: "",
      documentType: null,
      documentNumber: "",
      issueCountry: null,
      expiryDate: "",
      dateOfBirth: "",
      issuanceDate: "",
    },
  ]);

  // Update the passengerDetails array when numOfPassengers changes
  useEffect(() => {
    const updatedPassengerDetails = [...passengerDetails];
    for (let i = updatedPassengerDetails.length; i < numOfpessengers; i++) {
      updatedPassengerDetails.push({
        firstName: "",
        lastName: "",
        gender: null,
        email: "",
        phone: "",
        documentType: null,
        documentNumber: "",
        issueCountry: null,
        expiryDate: "",
        dateOfBirth: "",
        issuanceDate: "",
      });
    }
    setPassengerDetails(updatedPassengerDetails);
  }, [numOfpessengers]);

  const handleInputChangePassenger = (index, event) => {
    const { name, value } = event.target;
    const updatedPassengerDetails = [...passengerDetails];
    updatedPassengerDetails[index][name] = value;
    setPassengerDetails(updatedPassengerDetails);
  };

  const navigate = useNavigate();

  const handleSelectChange = (index, name, selectedOption) => {
    const updatedPassengerDetails = [...passengerDetails];
    updatedPassengerDetails[index][name] = selectedOption;
    setPassengerDetails(updatedPassengerDetails);
  };

  const [BookingFlightLoading, setBookingFlightLoading] = useState(false);
  const [GrandsTotal, setGrandsTotal] = useState(0);
  const [SelectedCard, setSelectedCard] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const isAnyPassengerEmpty = passengerDetails.some((passenger) =>
      Object.values(passenger).some((value) => value === "")
    );
    if (isAnyPassengerEmpty) {
      toast.error("Please fill in all passenger details");
      return;
    }

    if (AllCards.length === 0) {
      toast.error("Please Add Card you don't have any card");
      return;
    }

    if (SelectedCard === null) {
      toast.error("Select Card");
      return;
    }

    if (CVC === "") {
      toast.error("Enter CVC");
      return;
    }
    dispatch(setPrevPathDeActive());

    const travelers = passengerDetails.map((passenger, index) => ({
      id: (index + 1).toString(),
      dateOfBirth: passenger.dateOfBirth,
      name: {
        firstName: passenger.firstName,
        lastName: passenger.lastName,
      },
      gender: passenger.gender.value.toUpperCase(),
      contact: {
        emailAddress: passenger.email,
        phones: [
          {
            deviceType: "MOBILE",
            countryCallingCode: passenger.phone.substring(0, 2),
            number: passenger.phone.substring(2),
          },
        ],
      },
      documents: [
        {
          documentType: passenger.documentType.value,
          birthPlace: passenger.issueCountry.label,
          issuanceLocation: passenger.issueCountry.label,
          issuanceDate: passenger.issuanceDate,
          number: passenger.documentNumber,
          expiryDate: passenger.expiryDate,
          issuanceCountry: passenger.issueCountry.value,
          validityCountry: passenger.issueCountry.value,
          nationality: passenger.issueCountry.value,
          holder: true,
        },
      ],
    }));

    const payload = {
      flightOffer: selectedFlight,
      travelers: travelers,
      career: dictionaries?.carriers[selectedFlight.validatingAirlineCodes[0]],
      aircraftCode:
        dictionaries?.aircraft[
          selectedFlight.itineraries[0]?.segments[0]?.aircraft?.code
        ],
      cardInfo: {
        cardId: SelectedCard,
        cardCVV: CVC,
      },
      couponCode: VoucherCode,
      total: GrandsTotal,
    };
    setBookingFlightLoading(true);
    try {
      const response = await httpRequest.post(FlightBooking, payload);
      if (response.status === 200 || response.status === 201) {
        toast.success("Payment Successfull");
        setBookingFlightLoading(false);
        navigate(`/flights/ticket/${response?.data?.booking?._id}`);
        dispatch(setPaymentSuccess(true));
      }
    } catch (error) {
      toast.error(
        "Sorry, the selected flight is currently unavailable. Please choose another flight or try again later."
      );
      setBookingFlightLoading(false);
    }
  };

  const locaiton = useLocation();

  const HandleAuth = () => {
    localStorage.setItem("Path", locaiton.pathname);
    dispatch(setPrevPathActive());
    navigate("/login");
  };

  // Google Login

  const GoogleLogin = useGoogleLogin({
    onSuccess: async (resp) => {
      try {
        const response = await fetch(
          "https://www.googleapis.com/oauth2/v3/userinfo",
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${resp?.access_token}`,
            },
          }
        );
        if (response.ok) {
          const data = await response.json();
          setLoading(true);
          try {
            const resp = await httpRequest.post(SOCIAL_LOGIN, {
              email: data?.email,
              fullName: data?.name,
              profilepic: data?.picture,
              name: data?.given_name,
            });

            if (resp?.data?.message === "Login successful") {
              toast.success(resp?.data?.message);
              dispatch(setUser(resp?.data?.user));
              localStorage.setItem("token", resp?.data?.token);
            }
            if (resp.status === 201) {
              toast.success(resp?.data?.message);
              dispatch(setemail(data?.email));
              navigate("/user/profile/bio");
            }
          } catch (error) {
            console.log(
              "🚀 ~ onSuccess: ~ error:",
              error?.response?.data?.message
            );
            toast.error(error?.response?.data?.message);
          } finally {
            setLoading(false);
          }
        } else {
          console.error(
            "Request failed:",
            response.status,
            response.statusText
          );
        }
      } catch (error) {
        console.error("Error fetching user details:", error);
        throw error;
      }
    },
    onError: (error) => {
      console.log(error);
    },
  });

  return (
    <FlightLayout>
      <div className="py-3 font-Montserrat">
        <BreadcrumbArrow items={paths} />
      </div>

      <div className="py-10 grid lg:grid-cols-3 grid-cols-1 lg:gap-16 lg:space-y-0 space-y-4 font-Montserrat relative">
        {AddCard && (
          <div className="fixed h-full w-full bg-[#0000008e] left-0 top-0 z-10 flex items-center justify-center">
            <AddCards onclick={setAddCard} FetchCards={FetchCards} />
          </div>
        )}

        <div className="col-span-2 space-y-14">
          {/* // ========== AirBus Details =========== */}

          <div className="w-full space-y-5 shadow-sm">
            <div className="flex justify-between">
              <h1 className="text-BlackishGreen sm:text-2xl text-base font-bold leading-normal">
                {
                  dictionaries?.carriers[
                    selectedFlight.validatingAirlineCodes[0]
                  ]
                }{" "}
                {
                  dictionaries?.aircraft[
                    selectedFlight.itineraries[0]?.segments[0]?.aircraft?.code
                  ]
                }
              </h1>
              <h1 className="sm:text-3xl text-xl text-end font-bold leading-normal text-[#FF8682]">
                ${Math.round(selectedFlight?.price?.grandTotal)}
              </h1>
            </div>
            <div className="flex justify-between flex-wrap">
              <h1 className="text-BlackishGreen sm:text-base text-xs font-bold leading-normal">
                Origin {moment().format("ddd, MMM D")}
              </h1>
              <p className="font-bold sm:text-base text-xs leading-normal underline text-primary cursor-pointer">
                <Link to={`/flights`}>Change Dates</Link>
              </p>
              <p className="text-BlackishGreen sm:text-xl text-xs font-medium leading-normal opacity-75">
                {TimeConverter(selectedFlight?.itineraries[0]?.duration)}
              </p>
            </div>
            <div className="flex justify-between items-center flex-wrap">
              <div className="p-5 border lg:w-max w-full flex items-start lg:justify-start justify-center space-x-5 border-[#8DD3BB] rounded">
                <img
                  className="sm:h-14 h-10"
                  src={getAirlineImage(
                    selectedFlight?.validatingAirlineCodes[0]
                  )}
                  alt="AirlineLogo.svg"
                />
                <div>
                  <h1 className="text-BlackishGreen sm:text-2xl text-base font-semibold leading-normal">
                    {getAirlineName(selectedFlight?.validatingAirlineCodes[0])}
                  </h1>
                  <p className="text-BlackishGreen text-sm font-medium leading-normal">
                    {
                      dictionaries?.aircraft[
                        selectedFlight.itineraries[0]?.segments[0]?.aircraft
                          ?.code
                      ]
                    }
                  </p>
                </div>
              </div>
              <div className="flex flex-wrap">
                {FlightMenues &&
                  FlightMenues.map((item, index) => (
                    <div
                      key={item?.id}
                      className={`px-6 py-2 sm:w-max w-1/2 sm:border-b-0 border-b ${
                        index !== FlightMenues.length - 1 ? "sm:border-r" : ""
                      } h-max flex sm:space-x-4 space-x-1`}
                    >
                      <img className="h-5" src={item?.icon} alt="icon" />
                      <p>{item?.title}</p>
                    </div>
                  ))}
              </div>
            </div>
            <div className="flex sm:justify-between items-center  sm:flex-row flex-col sm:gap-16">
              <h1 className="text-BlackishGreen sm:text-2xl text-lg font-semibold leading-normal w-max">
                {moment(
                  selectedFlight?.itineraries[0]?.segments[0]?.departure?.at
                ).format("hh:mm A")}{" "}
                <span className="text-base font-medium leading-normal opacity-60">
                  {getCityName(
                    selectedFlight?.itineraries[0]?.segments[0]?.departure
                      ?.iataCode
                  )}{" "}
                  (
                  {
                    selectedFlight?.itineraries[0]?.segments[0]?.departure
                      ?.iataCode
                  }
                  )
                </span>
              </h1>
              <img className="h-10" src={FromToPlane} alt="FromToPlane.svg" />
              <h1 className="text-BlackishGreen sm:text-2xl text-lg font-semibold leading-normal w-max">
                {moment(
                  selectedFlight?.itineraries[0]?.segments[0]?.arrival?.at
                ).format("hh:mm A")}{" "}
                <span className="text-base font-medium leading-normal opacity-60">
                  {getCityName(
                    selectedFlight?.itineraries[0]?.segments[0]?.arrival
                      ?.iataCode
                  )}{" "}
                  (
                  {
                    selectedFlight?.itineraries[0]?.segments[0]?.arrival
                      ?.iataCode
                  }
                  )
                </span>
              </h1>
            </div>
          </div>
          {/* // ========== AirBus Details Ending =========== */}

          {/* // ========== Extra Equipments =========== */}

          <div className="space-y-2 ">
            {TotalEquipments &&
              TotalEquipments.map((item, index) => (
                <div
                  key={item?.id}
                  className={`shadow-sm rounded-md cursor-pointer ${
                    selectedEquipments.some((e) => e.id === item.id)
                      ? "bg-primary"
                      : ""
                  } `}
                  onClick={() => handleEquipmentClick(item)}
                >
                  <div
                    // onClick={() => handleClick(item)}
                    className="flex justify-between items-center p-4 "
                  >
                    <div className="space-y-2">
                      <h1
                        className={`${
                          selectedEquipments.some((e) => e.id === item.id)
                            ? "text-white"
                            : "text-BlackishGreen"
                        } sm:text-base text-sm font-bold leading-normal `}
                      >
                        {item?.heading}
                      </h1>
                      <p
                        className={`${
                          selectedEquipments.some((e) => e.id === item.id)
                            ? "text-white"
                            : "text-BlackishGreen"
                        } sm:text-sm text-xs font-normal leading-normal`}
                      >
                        {item?.subheading}
                      </p>
                    </div>
                    <div
                      className={`flex items-center justify-between space-x-5 w-${
                        index === 0 ? "30%" : "20%"
                      } `}
                    >
                      {index !== 0 ? (
                        ""
                      ) : (
                        <div className=" flex d justify-center items-center bg-[white] h-[45px] w-[100px] rounded-lg ">
                          <div className="flex-col items-center justify-center ">
                            <p className="text-[11px] font-normal ">Quantity</p>
                            <p className="text=[14px] font-bold ">
                              <div className="flex w-55px] items-center justify-between ">
                                <button
                                  className=" text-white flex justify-center items-center h-[13px] w-[13px] rounded-full bg-black text-[10px]  "
                                  onClick={(e) => {
                                    handleInputChange(e, item.id, "minus");
                                  }}
                                >
                                  -
                                </button>
                                <div>
                                  {total.some(
                                    (element) => element.id === item?.id
                                  )
                                    ? total.find(
                                        (element) => element.id === item?.id
                                      ).qty
                                    : inputvalue}
                                </div>
                                <button
                                  className=" text-white flex justify-center items-center h-[13px] w-[13px] rounded-full bg-black text-[10px] "
                                  onClick={(e) => {
                                    handleInputChange(e, item.id, "plus");
                                  }}
                                >
                                  +
                                </button>
                              </div>
                            </p>
                          </div>
                        </div>
                      )}
                      {/* <h1
                        className={`${
                          selectedEquipments.some((e) => e.id === item.id)
                            ? "text-white"
                            : "text-BlackishGreen"
                        } sm:text-sm text-xs font-bold leading-normal`}
                      >
                        +${item?.price}
                      </h1> */}
                      <IoCheckmarkCircle
                        size={24}
                        className={`${
                          selectedEquipments.some((e) => e.id === item.id)
                            ? "text-[#8DD3BB]"
                            : "text-[#D3D3D3]"
                        }`}
                      />
                    </div>
                  </div>
                </div>
              ))}
          </div>
          <div className="w-full space-y-2">
            <div
              className={`shadow-sm rounded-md cursor-pointer
                  bg-primary
              `}
            >
              <div className="flex justify-between items-center p-4">
                <div className="space-y-2">
                  <h1
                    className={`text-white sm:text-base text-sm font-bold leading-normal `}
                  >
                    Pay in full
                  </h1>
                  <p
                    className={`text-white sm:text-sm text-xs font-normal leading-normal`}
                  >
                    Pay the total and you are all set
                  </p>
                </div>
                <div className="flex gap-10">
                  <div className="inline-flex items-center">
                    <label
                      className="relative flex items-center p-3 rounded-full cursor-pointer"
                      htmlFor="payfull"
                    >
                      <input
                        name="payfull"
                        type="radio"
                        checked
                        className="before:content[''] peer relative h-5 w-5 cursor-pointer appearance-none rounded-full border border-white text-white transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-transparent before:opacity-0 before:transition-opacity checked:border-white checked:before:bg-white hover:before:opacity-10"
                        id="payfull"
                      />
                      <span class="absolute text-white transition-opacity opacity-0 pointer-events-none top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 peer-checked:opacity-100">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="h-3.5 w-3.5"
                          viewBox="0 0 16 16"
                          fill="currentColor"
                        >
                          <circle
                            data-name="ellipse"
                            cx="8"
                            cy="8"
                            r="8"
                          ></circle>
                        </svg>
                      </span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div className="w-full">
              <div className="space-y-3 p-4">
                <h1
                  className={`text-BlackishGreen sm:text-base text-sm font-bold leading-normal `}
                >
                  Additional Notes Regarding Payment
                </h1>
                <p
                  className={`text-BlackishGreen sm:text-sm text-xs font-normal leading-normal`}
                >
                  Your payment is subject to verification in case required.
                  Please make sure all the details entered are correct.
                </p>
                <p
                  className={`text-BlackishGreen text-xs font-medium leading-normal underline cursor-pointer`}
                >
                  More info
                </p>
              </div>
            </div>
          </div>
          {user == null ? (
            <div className="w-full space-y-4">
              <h1
                className={`text-BlackishGreen sm:text-xl text-base font-bold leading-normal `}
              >
                Login or Sign up to book
              </h1>
              <input
                type="number"
                className="w-full text-[#1C1B1F] border border-[#79747E] outline-none rounded"
                placeholder="Phone Number"
                style={{ padding: "8px 0px 8px 16px" }}
              />
              <p
                className={`text-BlackishGreen sm:text-sm text-xs font-normal leading-normal`}
              >
                We’ll call or text you to confirm your number. Standard message
                and data rates apply.{" "}
                <span className="font-medium hover:underline cursor-pointer">
                  Privacy Policy
                </span>
              </p>
              <button
                style={{ padding: "8px 16px" }}
                // onClick={() => navigate("/flights/booking")}
                className="w-full h-[48px] bg-primary rounded-md hover:bg-blue-800 text-white text-sm font-semibold"
              >
                Continue
              </button>
              <div className="relative py-4 col-span-2">
                <div className="w-full relative border-b-[2px] flex justify-center items-center">
                  <span className="absolute bg-white px-4">Or</span>
                </div>
              </div>
              <div className="col-span-2 grid sm:grid-cols-2 gap-5">
                <div className=" border border-[#8DD3BB] flex items-center justify-center rounded-lg p-[10px] cursor-pointer hover:bg-[#8DD3BB]">
                  <img src={facebookicon} alt="facebook.svg" />
                </div>
                <div
                  onClick={GoogleLogin}
                  className=" border border-[#8DD3BB] flex items-center justify-center rounded-lg p-[10px] cursor-pointer hover:bg-[#8DD3BB]"
                >
                  <img src={googleicon} alt="facebook.svg" />
                </div>
              </div>
              <button
                style={{ padding: "8px 16px" }}
                onClick={HandleAuth}
                className="w-full h-[48px] border border-[#8DD3BB] flex items-center justify-center space-x-3 bg-white rounded-md hover:bg-[#8DD3BB] text-BlackishGreen text-sm font-semibold"
              >
                <MdEmail size={24} />
                <span>Continue with email</span>
              </button>
            </div>
          ) : (
            <>
              <div className="w-full">
                <h1 className="text-black sm:text-[19px] text-base font-semibold leading-normal">
                  Personal Details
                </h1>
                <div className="grid sm:grid-cols-2 grid-cols-1 w-full gap-6 py-6 pl-4">
                  <div
                    style={{ padding: "4px 0px 4px 16px;" }}
                    class="relative w-full h-12 sm:col-span-2 col-span-1"
                  >
                    <input
                      type="text"
                      className="peer w-full h-full bg-transparent text-[#1C1B1F] font-sans font-normal outline outline-0 focus:outline-0 disabled:bg-blue-gray-50 disabled:border-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 border focus:border-2 border-t-transparent focus:border-t-transparent text-sm px-3 py-2.5 rounded-[7px] border-blue-gray-200 focus:border-gray-900"
                      placeholder=" "
                      onChange={(e) =>
                        setPersonalDetails({
                          ...PersonalDetails,
                          name: e.target.value,
                        })
                      }
                      value={PersonalDetails?.name}
                    />
                    <label className="flex w-full h-full select-none pointer-events-none absolute left-0 font-normal !overflow-visible truncate peer-placeholder-shown:text-BlackishGreen leading-tight peer-focus:leading-tight peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-BlackishGreen transition-all -top-1.5 peer-placeholder-shown:text-sm text-[11px] peer-focus:text-[11px] before:content[' '] before:block before:box-border before:w-2.5 before:h-1.5 before:mt-[6.5px] before:mr-1 peer-placeholder-shown:before:border-transparent before:rounded-tl-md before:border-t peer-focus:before:border-t-2 before:border-l peer-focus:before:border-l-2 before:pointer-events-none before:transition-all peer-disabled:before:border-transparent after:content[' '] after:block after:flex-grow after:box-border after:w-2.5 after:h-1.5 after:mt-[6.5px] after:ml-1 peer-placeholder-shown:after:border-transparent after:rounded-tr-md after:border-t peer-focus:after:border-t-2 after:border-r peer-focus:after:border-r-2 after:pointer-events-none after:transition-all peer-disabled:after:border-transparent peer-placeholder-shown:leading-[4.2] blatext-BlackishGreen peer-focus:text-gray-900 before:border-blue-gray-200 peer-focus:before:!border-gray-900 after:border-blue-gray-200 peer-focus:after:!border-gray-900">
                      Full Name (as per Passport/ID)
                    </label>
                  </div>
                  <div className="relative col-span-1">
                    <Select
                      value={Gender}
                      className="PersonalDeatilsFlight"
                      onChange={(selectedOption) => setGender(selectedOption)}
                      options={options}
                    />
                    <label className="absolute -top-2 left-3 text-xs font-normal text-BlackishGreen bg-white px-1">
                      Gender
                    </label>
                  </div>
                  <div
                    style={{ padding: "4px 0px 4px 16px;" }}
                    class="relative w-full h-12 col-span-1"
                  >
                    <input
                      type="date"
                      className="peer w-full h-full bg-transparent text-[#1C1B1F] font-sans font-normal outline outline-0 focus:outline-0 disabled:bg-blue-gray-50 disabled:border-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 border focus:border-2 border-t-transparent focus:border-t-transparent text-sm px-3 py-2.5 rounded-[7px] border-blue-gray-200 focus:border-gray-900"
                      placeholder=" "
                      onChange={(e) =>
                        setPersonalDetails({
                          ...PersonalDetails,
                          dob: e.target.value,
                        })
                      }
                      value={PersonalDetails?.dob}
                    />
                    <label className="flex w-full h-full select-none pointer-events-none absolute left-0 font-normal !overflow-visible truncate peer-placeholder-shown:text-BlackishGreen leading-tight peer-focus:leading-tight peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-BlackishGreen transition-all -top-1.5 peer-placeholder-shown:text-sm text-[11px] peer-focus:text-[11px] before:content[' '] before:block before:box-border before:w-2.5 before:h-1.5 before:mt-[6.5px] before:mr-1 peer-placeholder-shown:before:border-transparent before:rounded-tl-md before:border-t peer-focus:before:border-t-2 before:border-l peer-focus:before:border-l-2 before:pointer-events-none before:transition-all peer-disabled:before:border-transparent after:content[' '] after:block after:flex-grow after:box-border after:w-2.5 after:h-1.5 after:mt-[6.5px] after:ml-1 peer-placeholder-shown:after:border-transparent after:rounded-tr-md after:border-t peer-focus:after:border-t-2 after:border-r peer-focus:after:border-r-2 after:pointer-events-none after:transition-all peer-disabled:after:border-transparent peer-placeholder-shown:leading-[4.2] blatext-BlackishGreen peer-focus:text-gray-900 before:border-blue-gray-200 peer-focus:before:!border-gray-900 after:border-blue-gray-200 peer-focus:after:!border-gray-900">
                      Date of Birth
                    </label>
                  </div>
                  <div
                    style={{ padding: "4px 0px 4px 16px;" }}
                    class="relative w-full h-12 col-span-1"
                  >
                    <input
                      type="text"
                      className="peer w-full h-full bg-transparent text-[#1C1B1F] font-sans font-normal outline outline-0 focus:outline-0 disabled:bg-blue-gray-50 disabled:border-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 border focus:border-2 border-t-transparent focus:border-t-transparent text-sm px-3 py-2.5 rounded-[7px] border-blue-gray-200 focus:border-gray-900"
                      placeholder=" "
                      onChange={(e) =>
                        setPersonalDetails({
                          ...PersonalDetails,
                          address: e.target.value,
                        })
                      }
                      value={PersonalDetails?.address}
                    />
                    <label className="flex w-full h-full select-none pointer-events-none absolute left-0 font-normal !overflow-visible truncate peer-placeholder-shown:text-BlackishGreen leading-tight peer-focus:leading-tight peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-BlackishGreen transition-all -top-1.5 peer-placeholder-shown:text-sm text-[11px] peer-focus:text-[11px] before:content[' '] before:block before:box-border before:w-2.5 before:h-1.5 before:mt-[6.5px] before:mr-1 peer-placeholder-shown:before:border-transparent before:rounded-tl-md before:border-t peer-focus:before:border-t-2 before:border-l peer-focus:before:border-l-2 before:pointer-events-none before:transition-all peer-disabled:before:border-transparent after:content[' '] after:block after:flex-grow after:box-border after:w-2.5 after:h-1.5 after:mt-[6.5px] after:ml-1 peer-placeholder-shown:after:border-transparent after:rounded-tr-md after:border-t peer-focus:after:border-t-2 after:border-r peer-focus:after:border-r-2 after:pointer-events-none after:transition-all peer-disabled:after:border-transparent peer-placeholder-shown:leading-[4.2] blatext-BlackishGreen peer-focus:text-gray-900 before:border-blue-gray-200 peer-focus:before:!border-gray-900 after:border-blue-gray-200 peer-focus:after:!border-gray-900">
                      Address
                    </label>
                  </div>
                  <div
                    style={{ padding: "4px 0px 4px 16px;" }}
                    class="relative w-full h-12 col-span-1"
                  >
                    <input
                      type="text"
                      className="peer w-full h-full bg-transparent text-[#1C1B1F] font-sans font-normal outline outline-0 focus:outline-0 disabled:bg-blue-gray-50 disabled:border-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 border focus:border-2 border-t-transparent focus:border-t-transparent text-sm px-3 py-2.5 rounded-[7px] border-blue-gray-200 focus:border-gray-900"
                      placeholder=" "
                      onChange={(e) =>
                        setPersonalDetails({
                          ...PersonalDetails,
                          town: e.target.value,
                        })
                      }
                      value={PersonalDetails?.town}
                    />
                    <label className="flex w-full h-full select-none pointer-events-none absolute left-0 font-normal !overflow-visible truncate peer-placeholder-shown:text-BlackishGreen leading-tight peer-focus:leading-tight peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-BlackishGreen transition-all -top-1.5 peer-placeholder-shown:text-sm text-[11px] peer-focus:text-[11px] before:content[' '] before:block before:box-border before:w-2.5 before:h-1.5 before:mt-[6.5px] before:mr-1 peer-placeholder-shown:before:border-transparent before:rounded-tl-md before:border-t peer-focus:before:border-t-2 before:border-l peer-focus:before:border-l-2 before:pointer-events-none before:transition-all peer-disabled:before:border-transparent after:content[' '] after:block after:flex-grow after:box-border after:w-2.5 after:h-1.5 after:mt-[6.5px] after:ml-1 peer-placeholder-shown:after:border-transparent after:rounded-tr-md after:border-t peer-focus:after:border-t-2 after:border-r peer-focus:after:border-r-2 after:pointer-events-none after:transition-all peer-disabled:after:border-transparent peer-placeholder-shown:leading-[4.2] blatext-BlackishGreen peer-focus:text-gray-900 before:border-blue-gray-200 peer-focus:before:!border-gray-900 after:border-blue-gray-200 peer-focus:after:!border-gray-900">
                      Town
                    </label>
                  </div>
                  <div className="relative col-span-1">
                    <Select
                      value={Country}
                      className="PersonalDeatilsFlight"
                      onChange={(selectedOption) => setCountry(selectedOption)}
                      options={Countries}
                    />
                    <label className="absolute -top-2 left-3 text-xs font-normal text-BlackishGreen bg-white px-1">
                      Country
                    </label>
                  </div>
                  <div
                    style={{ padding: "4px 0px 4px 16px;" }}
                    class="relative w-full h-12 col-span-1"
                  >
                    <input
                      type="number"
                      min={0}
                      className="peer w-full h-full bg-transparent text-[#1C1B1F] font-sans font-normal outline outline-0 focus:outline-0 disabled:bg-blue-gray-50 disabled:border-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 border focus:border-2 border-t-transparent focus:border-t-transparent text-sm px-3 py-2.5 rounded-[7px] border-blue-gray-200 focus:border-gray-900"
                      placeholder=" "
                      onChange={(e) =>
                        setPersonalDetails({
                          ...PersonalDetails,
                          postCode: e.target.value,
                        })
                      }
                      value={PersonalDetails?.postCode}
                    />
                    <label className="flex w-full h-full select-none pointer-events-none absolute left-0 font-normal !overflow-visible truncate peer-placeholder-shown:text-BlackishGreen leading-tight peer-focus:leading-tight peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-BlackishGreen transition-all -top-1.5 peer-placeholder-shown:text-sm text-[11px] peer-focus:text-[11px] before:content[' '] before:block before:box-border before:w-2.5 before:h-1.5 before:mt-[6.5px] before:mr-1 peer-placeholder-shown:before:border-transparent before:rounded-tl-md before:border-t peer-focus:before:border-t-2 before:border-l peer-focus:before:border-l-2 before:pointer-events-none before:transition-all peer-disabled:before:border-transparent after:content[' '] after:block after:flex-grow after:box-border after:w-2.5 after:h-1.5 after:mt-[6.5px] after:ml-1 peer-placeholder-shown:after:border-transparent after:rounded-tr-md after:border-t peer-focus:after:border-t-2 after:border-r peer-focus:after:border-r-2 after:pointer-events-none after:transition-all peer-disabled:after:border-transparent peer-placeholder-shown:leading-[4.2] blatext-BlackishGreen peer-focus:text-gray-900 before:border-blue-gray-200 peer-focus:before:!border-gray-900 after:border-blue-gray-200 peer-focus:after:!border-gray-900">
                      Post Code
                    </label>
                  </div>
                  <div
                    style={{ padding: "4px 0px 4px 16px;" }}
                    class="relative w-full h-12 col-span-1"
                  >
                    <input
                      type="email"
                      className="peer w-full h-full bg-transparent text-[#1C1B1F] font-sans font-normal outline outline-0 focus:outline-0 disabled:bg-blue-gray-50 disabled:border-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 border focus:border-2 border-t-transparent focus:border-t-transparent text-sm px-3 py-2.5 rounded-[7px] border-blue-gray-200 focus:border-gray-900"
                      placeholder=" "
                      onChange={(e) =>
                        setPersonalDetails({
                          ...PersonalDetails,
                          email: e.target.value,
                        })
                      }
                      value={PersonalDetails?.email}
                    />
                    <label className="flex w-full h-full select-none pointer-events-none absolute left-0 font-normal !overflow-visible truncate peer-placeholder-shown:text-BlackishGreen leading-tight peer-focus:leading-tight peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-BlackishGreen transition-all -top-1.5 peer-placeholder-shown:text-sm text-[11px] peer-focus:text-[11px] before:content[' '] before:block before:box-border before:w-2.5 before:h-1.5 before:mt-[6.5px] before:mr-1 peer-placeholder-shown:before:border-transparent before:rounded-tl-md before:border-t peer-focus:before:border-t-2 before:border-l peer-focus:before:border-l-2 before:pointer-events-none before:transition-all peer-disabled:before:border-transparent after:content[' '] after:block after:flex-grow after:box-border after:w-2.5 after:h-1.5 after:mt-[6.5px] after:ml-1 peer-placeholder-shown:after:border-transparent after:rounded-tr-md after:border-t peer-focus:after:border-t-2 after:border-r peer-focus:after:border-r-2 after:pointer-events-none after:transition-all peer-disabled:after:border-transparent peer-placeholder-shown:leading-[4.2] blatext-BlackishGreen peer-focus:text-gray-900 before:border-blue-gray-200 peer-focus:before:!border-gray-900 after:border-blue-gray-200 peer-focus:after:!border-gray-900">
                      Email
                    </label>
                  </div>
                  <div
                    style={{ padding: "4px 0px 4px 16px;" }}
                    class="relative w-full h-12 col-span-1"
                  >
                    <input
                      type="number"
                      className="peer w-full h-full bg-transparent text-[#1C1B1F] font-sans font-normal outline outline-0 focus:outline-0 disabled:bg-blue-gray-50 disabled:border-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 border focus:border-2 border-t-transparent focus:border-t-transparent text-sm px-3 py-2.5 rounded-[7px] border-blue-gray-200 focus:border-gray-900"
                      placeholder=" "
                      onChange={(e) =>
                        setPersonalDetails({
                          ...PersonalDetails,
                          phone: e.target.value,
                        })
                      }
                      value={PersonalDetails?.phone}
                    />
                    <label className="flex w-full h-full select-none pointer-events-none absolute left-0 font-normal !overflow-visible truncate peer-placeholder-shown:text-BlackishGreen leading-tight peer-focus:leading-tight peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-BlackishGreen transition-all -top-1.5 peer-placeholder-shown:text-sm text-[11px] peer-focus:text-[11px] before:content[' '] before:block before:box-border before:w-2.5 before:h-1.5 before:mt-[6.5px] before:mr-1 peer-placeholder-shown:before:border-transparent before:rounded-tl-md before:border-t peer-focus:before:border-t-2 before:border-l peer-focus:before:border-l-2 before:pointer-events-none before:transition-all peer-disabled:before:border-transparent after:content[' '] after:block after:flex-grow after:box-border after:w-2.5 after:h-1.5 after:mt-[6.5px] after:ml-1 peer-placeholder-shown:after:border-transparent after:rounded-tr-md after:border-t peer-focus:after:border-t-2 after:border-r peer-focus:after:border-r-2 after:pointer-events-none after:transition-all peer-disabled:after:border-transparent peer-placeholder-shown:leading-[4.2] blatext-BlackishGreen peer-focus:text-gray-900 before:border-blue-gray-200 peer-focus:before:!border-gray-900 after:border-blue-gray-200 peer-focus:after:!border-gray-900">
                      Phone Number
                    </label>
                  </div>
                </div>
              </div>
              <div className="w-full">
                {Array.from({ length: numOfpessengers }).map((_, index) => (
                  <div
                    className="w-full grid grid-cols-2 gap-6 my-4"
                    key={index}
                  >
                    <h1 className="text-black sm:text-[19px] text-base font-semibold leading-normal col-span-2">
                      {index + 1} Passenger details
                    </h1>
                    <div
                      style={{ padding: "4px 0px 4px 16px;" }}
                      className="relative w-full h-12 sm:col-span-1 col-span-2"
                    >
                      <input
                        type="text"
                        className="peer w-full h-full bg-transparent text-[#1C1B1F] font-sans font-normal outline outline-0 focus:outline-0 disabled:bg-blue-gray-50 disabled:border-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 border focus:border-2 border-t-transparent focus:border-t-transparent text-sm px-3 py-2.5 rounded-[7px] border-blue-gray-200 focus:border-gray-900"
                        placeholder=" "
                        name="firstName"
                        value={passengerDetails[index]?.firstName}
                        onChange={(e) => handleInputChangePassenger(index, e)}
                      />
                      <label className="flex w-full h-full select-none pointer-events-none absolute left-0 font-normal !overflow-visible truncate peer-placeholder-shown:text-BlackishGreen leading-tight peer-focus:leading-tight peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-BlackishGreen transition-all -top-1.5 peer-placeholder-shown:text-sm text-[11px] peer-focus:text-[11px] before:content[' '] before:block before:box-border before:w-2.5 before:h-1.5 before:mt-[6.5px] before:mr-1 peer-placeholder-shown:before:border-transparent before:rounded-tl-md before:border-t peer-focus:before:border-t-2 before:border-l peer-focus:before:border-l-2 before:pointer-events-none before:transition-all peer-disabled:before:border-transparent after:content[' '] after:block after:flex-grow after:box-border after:w-2.5 after:h-1.5 after:mt-[6.5px] after:ml-1 peer-placeholder-shown:after:border-transparent after:rounded-tr-md after:border-t peer-focus:after:border-t-2 after:border-r peer-focus:after:border-r-2 after:pointer-events-none after:transition-all peer-disabled:after:border-transparent peer-placeholder-shown:leading-[4.2] blatext-BlackishGreen peer-focus:text-gray-900 before:border-blue-gray-200 peer-focus:before:!border-gray-900 after:border-blue-gray-200 peer-focus:after:!border-gray-900">
                        First name
                      </label>
                    </div>
                    <div
                      style={{ padding: "4px 0px 4px 16px;" }}
                      className="relative w-full h-12 sm:col-span-1 col-span-2"
                    >
                      <input
                        type="text"
                        className="peer w-full h-full bg-transparent text-[#1C1B1F] font-sans font-normal outline outline-0 focus:outline-0 disabled:bg-blue-gray-50 disabled:border-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 border focus:border-2 border-t-transparent focus:border-t-transparent text-sm px-3 py-2.5 rounded-[7px] border-blue-gray-200 focus:border-gray-900"
                        placeholder=" "
                        name="lastName"
                        value={passengerDetails[index]?.lastName}
                        onChange={(e) => handleInputChangePassenger(index, e)}
                      />
                      <label className="flex w-full h-full select-none pointer-events-none absolute left-0 font-normal !overflow-visible truncate peer-placeholder-shown:text-BlackishGreen leading-tight peer-focus:leading-tight peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-BlackishGreen transition-all -top-1.5 peer-placeholder-shown:text-sm text-[11px] peer-focus:text-[11px] before:content[' '] before:block before:box-border before:w-2.5 before:h-1.5 before:mt-[6.5px] before:mr-1 peer-placeholder-shown:before:border-transparent before:rounded-tl-md before:border-t peer-focus:before:border-t-2 before:border-l peer-focus:before:border-l-2 before:pointer-events-none before:transition-all peer-disabled:before:border-transparent after:content[' '] after:block after:flex-grow after:box-border after:w-2.5 after:h-1.5 after:mt-[6.5px] after:ml-1 peer-placeholder-shown:after:border-transparent after:rounded-tr-md after:border-t peer-focus:after:border-t-2 after:border-r peer-focus:after:border-r-2 after:pointer-events-none after:transition-all peer-disabled:after:border-transparent peer-placeholder-shown:leading-[4.2] blatext-BlackishGreen peer-focus:text-gray-900 before:border-blue-gray-200 peer-focus:before:!border-gray-900 after:border-blue-gray-200 peer-focus:after:!border-gray-900">
                        Last name
                      </label>
                    </div>
                    <div className="relative col-span-1">
                      <Select
                        value={passengerDetails[index]?.gender}
                        className="PersonalDeatilsFlight"
                        onChange={(selectedOption) =>
                          handleSelectChange(index, "gender", selectedOption)
                        }
                        options={options}
                      />
                      <label className="absolute -top-2 left-3 text-xs font-normal text-BlackishGreen bg-white px-1">
                        Gender
                      </label>
                    </div>
                    <div
                      style={{ padding: "4px 0px 4px 16px;" }}
                      className="relative w-full h-12 sm:col-span-1 col-span-2"
                    >
                      <input
                        type="email"
                        className="peer w-full h-full bg-transparent text-[#1C1B1F] font-sans font-normal outline outline-0 focus:outline-0 disabled:bg-blue-gray-50 disabled:border-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 border focus:border-2 border-t-transparent focus:border-t-transparent text-sm px-3 py-2.5 rounded-[7px] border-blue-gray-200 focus:border-gray-900"
                        placeholder=" "
                        name="email"
                        value={passengerDetails[index]?.email}
                        onChange={(e) => handleInputChangePassenger(index, e)}
                      />
                      <label className="flex w-full h-full select-none pointer-events-none absolute left-0 font-normal !overflow-visible truncate peer-placeholder-shown:text-BlackishGreen leading-tight peer-focus:leading-tight peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-BlackishGreen transition-all -top-1.5 peer-placeholder-shown:text-sm text-[11px] peer-focus:text-[11px] before:content[' '] before:block before:box-border before:w-2.5 before:h-1.5 before:mt-[6.5px] before:mr-1 peer-placeholder-shown:before:border-transparent before:rounded-tl-md before:border-t peer-focus:before:border-t-2 before:border-l peer-focus:before:border-l-2 before:pointer-events-none before:transition-all peer-disabled:before:border-transparent after:content[' '] after:block after:flex-grow after:box-border after:w-2.5 after:h-1.5 after:mt-[6.5px] after:ml-1 peer-placeholder-shown:after:border-transparent after:rounded-tr-md after:border-t peer-focus:after:border-t-2 after:border-r peer-focus:after:border-r-2 after:pointer-events-none after:transition-all peer-disabled:after:border-transparent peer-placeholder-shown:leading-[4.2] blatext-BlackishGreen peer-focus:text-gray-900 before:border-blue-gray-200 peer-focus:before:!border-gray-900 after:border-blue-gray-200 peer-focus:after:!border-gray-900">
                        Email
                      </label>
                    </div>
                    <div
                      style={{ padding: "4px 0px 4px 16px;" }}
                      className="relative w-full h-12 sm:col-span-1 col-span-2"
                    >
                      <input
                        type="text"
                        className="peer w-full h-full bg-transparent text-[#1C1B1F] font-sans font-normal outline outline-0 focus:outline-0 disabled:bg-blue-gray-50 disabled:border-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 border focus:border-2 border-t-transparent focus:border-t-transparent text-sm px-3 py-2.5 rounded-[7px] border-blue-gray-200 focus:border-gray-900"
                        placeholder=" "
                        name="phone"
                        value={passengerDetails[index]?.phone}
                        onChange={(e) => handleInputChangePassenger(index, e)}
                      />
                      <label className="flex w-full h-full select-none pointer-events-none absolute left-0 font-normal !overflow-visible truncate peer-placeholder-shown:text-BlackishGreen leading-tight peer-focus:leading-tight peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-BlackishGreen transition-all -top-1.5 peer-placeholder-shown:text-sm text-[11px] peer-focus:text-[11px] before:content[' '] before:block before:box-border before:w-2.5 before:h-1.5 before:mt-[6.5px] before:mr-1 peer-placeholder-shown:before:border-transparent before:rounded-tl-md before:border-t peer-focus:before:border-t-2 before:border-l peer-focus:before:border-l-2 before:pointer-events-none before:transition-all peer-disabled:before:border-transparent after:content[' '] after:block after:flex-grow after:box-border after:w-2.5 after:h-1.5 after:mt-[6.5px] after:ml-1 peer-placeholder-shown:after:border-transparent after:rounded-tr-md after:border-t peer-focus:after:border-t-2 after:border-r peer-focus:after:border-r-2 after:pointer-events-none after:transition-all peer-disabled:after:border-transparent peer-placeholder-shown:leading-[4.2] blatext-BlackishGreen peer-focus:text-gray-900 before:border-blue-gray-200 peer-focus:before:!border-gray-900 after:border-blue-gray-200 peer-focus:after:!border-gray-900">
                        Phone
                      </label>
                    </div>
                    <div
                      style={{ padding: "4px 0px 4px 16px;" }}
                      className="relative w-full h-12 sm:col-span-1 col-span-2"
                    >
                      <input
                        type="date"
                        className="peer w-full h-full bg-transparent text-[#1C1B1F] font-sans font-normal outline outline-0 focus:outline-0 disabled:bg-blue-gray-50 disabled:border-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 border focus:border-2 border-t-transparent focus:border-t-transparent text-sm px-3 py-2.5 rounded-[7px] border-blue-gray-200 focus:border-gray-900"
                        placeholder=" "
                        name="dateOfBirth"
                        value={passengerDetails[index]?.dateOfBirth}
                        onChange={(e) => handleInputChangePassenger(index, e)}
                        max={new Date().toISOString().split("T")[0]}
                      />

                      <label className="flex w-full h-full select-none pointer-events-none absolute left-0 font-normal !overflow-visible truncate peer-placeholder-shown:text-BlackishGreen leading-tight peer-focus:leading-tight peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-BlackishGreen transition-all -top-1.5 peer-placeholder-shown:text-sm text-[11px] peer-focus:text-[11px] before:content[' '] before:block before:box-border before:w-2.5 before:h-1.5 before:mt-[6.5px] before:mr-1 peer-placeholder-shown:before:border-transparent before:rounded-tl-md before:border-t peer-focus:before:border-t-2 before:border-l peer-focus:before:border-l-2 before:pointer-events-none before:transition-all peer-disabled:before:border-transparent after:content[' '] after:block after:flex-grow after:box-border after:w-2.5 after:h-1.5 after:mt-[6.5px] after:ml-1 peer-placeholder-shown:after:border-transparent after:rounded-tr-md after:border-t peer-focus:after:border-t-2 after:border-r peer-focus:after:border-r-2 after:pointer-events-none after:transition-all peer-disabled:after:border-transparent peer-placeholder-shown:leading-[4.2] blatext-BlackishGreen peer-focus:text-gray-900 before:border-blue-gray-200 peer-focus:before:!border-gray-900 after:border-blue-gray-200 peer-focus:after:!border-gray-900">
                        Date of Birth
                      </label>
                    </div>
                    <div className="relative col-span-1">
                      <Select
                        value={passengerDetails[index]?.documentType}
                        className="PersonalDeatilsFlight"
                        onChange={(selectedOption) =>
                          handleSelectChange(
                            index,
                            "documentType",
                            selectedOption
                          )
                        }
                        options={DocumentType}
                      />
                      <label className="absolute -top-2 left-3 text-xs font-normal text-BlackishGreen bg-white px-1">
                        Document Type
                      </label>
                    </div>
                    <div
                      style={{ padding: "4px 0px 4px 16px;" }}
                      className="relative w-full h-12 sm:col-span-1 col-span-2"
                    >
                      <input
                        type="text"
                        className="peer w-full h-full bg-transparent text-[#1C1B1F] font-sans font-normal outline outline-0 focus:outline-0 disabled:bg-blue-gray-50 disabled:border-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 border focus:border-2 border-t-transparent focus:border-t-transparent text-sm px-3 py-2.5 rounded-[7px] border-blue-gray-200 focus:border-gray-900"
                        placeholder=" "
                        name="documentNumber"
                        value={passengerDetails[index]?.documentNumber}
                        onChange={(e) => handleInputChangePassenger(index, e)}
                      />
                      <label className="flex w-full h-full select-none pointer-events-none absolute left-0 font-normal !overflow-visible truncate peer-placeholder-shown:text-BlackishGreen leading-tight peer-focus:leading-tight peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-BlackishGreen transition-all -top-1.5 peer-placeholder-shown:text-sm text-[11px] peer-focus:text-[11px] before:content[' '] before:block before:box-border before:w-2.5 before:h-1.5 before:mt-[6.5px] before:mr-1 peer-placeholder-shown:before:border-transparent before:rounded-tl-md before:border-t peer-focus:before:border-t-2 before:border-l peer-focus:before:border-l-2 before:pointer-events-none before:transition-all peer-disabled:before:border-transparent after:content[' '] after:block after:flex-grow after:box-border after:w-2.5 after:h-1.5 after:mt-[6.5px] after:ml-1 peer-placeholder-shown:after:border-transparent after:rounded-tr-md after:border-t peer-focus:after:border-t-2 after:border-r peer-focus:after:border-r-2 after:pointer-events-none after:transition-all peer-disabled:after:border-transparent peer-placeholder-shown:leading-[4.2] blatext-BlackishGreen peer-focus:text-gray-900 before:border-blue-gray-200 peer-focus:before:!border-gray-900 after:border-blue-gray-200 peer-focus:after:!border-gray-900">
                        Document Number
                      </label>
                    </div>
                    <div className="relative col-span-1">
                      <Select
                        value={passengerDetails[index]?.issueCountry}
                        className="PersonalDeatilsFlight"
                        onChange={(selectedOption) =>
                          handleSelectChange(
                            index,
                            "issueCountry",
                            selectedOption
                          )
                        }
                        options={Countries}
                      />
                      <label className="absolute -top-2 left-3 text-xs font-normal text-BlackishGreen bg-white px-1">
                        Issue Country
                      </label>
                    </div>
                    <div
                      style={{ padding: "4px 0px 4px 16px;" }}
                      className="relative w-full h-12 sm:col-span-1 col-span-2"
                    >
                      <input
                        type="date"
                        className="peer w-full h-full bg-transparent text-[#1C1B1F] font-sans font-normal outline outline-0 focus:outline-0 disabled:bg-blue-gray-50 disabled:border-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 border focus:border-2 border-t-transparent focus:border-t-transparent text-sm px-3 py-2.5 rounded-[7px] border-blue-gray-200 focus:border-gray-900"
                        placeholder=" "
                        name="issuanceDate"
                        value={passengerDetails[index]?.issuanceDate}
                        onChange={(e) => handleInputChangePassenger(index, e)}
                        max={new Date().toISOString().split("T")[0]}
                      />
                      <label className="flex w-full h-full select-none pointer-events-none absolute left-0 font-normal !overflow-visible truncate peer-placeholder-shown:text-BlackishGreen leading-tight peer-focus:leading-tight peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-BlackishGreen transition-all -top-1.5 peer-placeholder-shown:text-sm text-[11px] peer-focus:text-[11px] before:content[' '] before:block before:box-border before:w-2.5 before:h-1.5 before:mt-[6.5px] before:mr-1 peer-placeholder-shown:before:border-transparent before:rounded-tl-md before:border-t peer-focus:before:border-t-2 before:border-l peer-focus:before:border-l-2 before:pointer-events-none before:transition-all peer-disabled:before:border-transparent after:content[' '] after:block after:flex-grow after:box-border after:w-2.5 after:h-1.5 after:mt-[6.5px] after:ml-1 peer-placeholder-shown:after:border-transparent after:rounded-tr-md after:border-t peer-focus:after:border-t-2 after:border-r peer-focus:after:border-r-2 after:pointer-events-none after:transition-all peer-disabled:after:border-transparent peer-placeholder-shown:leading-[4.2] blatext-BlackishGreen peer-focus:text-gray-900 before:border-blue-gray-200 peer-focus:before:!border-gray-900 after:border-blue-gray-200 peer-focus:after:!border-gray-900">
                        Issueance Date
                      </label>
                    </div>
                    <div
                      style={{ padding: "4px 0px 4px 16px;" }}
                      className="relative w-full h-12 sm:col-span-1 col-span-2"
                    >
                      <input
                        type="date"
                        className="peer w-full h-full bg-transparent text-[#1C1B1F] font-sans font-normal outline outline-0 focus:outline-0 disabled:bg-blue-gray-50 disabled:border-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 border focus:border-2 border-t-transparent focus:border-t-transparent text-sm px-3 py-2.5 rounded-[7px] border-blue-gray-200 focus:border-gray-900"
                        placeholder=" "
                        name="expiryDate"
                        value={passengerDetails[index]?.expiryDate}
                        onChange={(e) => handleInputChangePassenger(index, e)}
                        min={new Date().toISOString().split("T")[0]}
                      />

                      <label className="flex w-full h-full select-none pointer-events-none absolute left-0 font-normal !overflow-visible truncate peer-placeholder-shown:text-BlackishGreen leading-tight peer-focus:leading-tight peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-BlackishGreen transition-all -top-1.5 peer-placeholder-shown:text-sm text-[11px] peer-focus:text-[11px] before:content[' '] before:block before:box-border before:w-2.5 before:h-1.5 before:mt-[6.5px] before:mr-1 peer-placeholder-shown:before:border-transparent before:rounded-tl-md before:border-t peer-focus:before:border-t-2 before:border-l peer-focus:before:border-l-2 before:pointer-events-none before:transition-all peer-disabled:before:border-transparent after:content[' '] after:block after:flex-grow after:box-border after:w-2.5 after:h-1.5 after:mt-[6.5px] after:ml-1 peer-placeholder-shown:after:border-transparent after:rounded-tr-md after:border-t peer-focus:after:border-t-2 after:border-r peer-focus:after:border-r-2 after:pointer-events-none after:transition-all peer-disabled:after:border-transparent peer-placeholder-shown:leading-[4.2] blatext-BlackishGreen peer-focus:text-gray-900 before:border-blue-gray-200 peer-focus:before:!border-gray-900 after:border-blue-gray-200 peer-focus:after:!border-gray-900">
                        Expiry Date
                      </label>
                    </div>

                    {/* Add other input fields similarly */}
                  </div>
                ))}

                {/* 
                <p className="col-span-2 flex items-center space-x-2">
                        <input type="checkbox" className="accent-black" />
                        <span className="sm:text-sm text-[10px] font-medium leading-normal">
                          Securely save my information for future bookings
                        </span>
                      </p>
                 */}
              </div>
              <div className="w-full">
                <h1 className="text-black sm:text-[19px] text-base font-semibold leading-normal">
                  Frequent flyer programme
                </h1>
                <div className="grid grid-cols-2 w-full gap-6 py-6 pl-4">
                  <p className="col-span-2 flex items-center space-x-2">
                    <input
                      type="checkbox"
                      onChange={(e) => setCheckMember(e.target.checked)}
                      className="accent-black"
                    />
                    <span className="sm:text-sm text-[10px] font-medium leading-normal">
                      Check this if you’re is a member of the airline's frequent
                      flyer programme.
                    </span>
                  </p>
                  {checkMember && (
                    <div
                      style={{ padding: "4px 0px 4px 16px;" }}
                      class="relative w-full h-12 col-span-2"
                    >
                      <input
                        type="number"
                        className="peer w-full h-full bg-transparent text-[#1C1B1F] font-sans font-normal outline outline-0 focus:outline-0 disabled:bg-blue-gray-50 disabled:border-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 border focus:border-2 border-t-transparent focus:border-t-transparent text-sm px-3 py-2.5 rounded-[7px] border-blue-gray-200 focus:border-gray-900"
                        placeholder=" "
                      />
                      <label className="flex w-full h-full select-none pointer-events-none absolute left-0 font-normal !overflow-visible truncate peer-placeholder-shown:text-BlackishGreen leading-tight peer-focus:leading-tight peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-BlackishGreen transition-all -top-1.5 peer-placeholder-shown:text-sm text-[11px] peer-focus:text-[11px] before:content[' '] before:block before:box-border before:w-2.5 before:h-1.5 before:mt-[6.5px] before:mr-1 peer-placeholder-shown:before:border-transparent before:rounded-tl-md before:border-t peer-focus:before:border-t-2 before:border-l peer-focus:before:border-l-2 before:pointer-events-none before:transition-all peer-disabled:before:border-transparent after:content[' '] after:block after:flex-grow after:box-border after:w-2.5 after:h-1.5 after:mt-[6.5px] after:ml-1 peer-placeholder-shown:after:border-transparent after:rounded-tr-md after:border-t peer-focus:after:border-t-2 after:border-r peer-focus:after:border-r-2 after:pointer-events-none after:transition-all peer-disabled:after:border-transparent peer-placeholder-shown:leading-[4.2] blatext-BlackishGreen peer-focus:text-gray-900 before:border-blue-gray-200 peer-focus:before:!border-gray-900 after:border-blue-gray-200 peer-focus:after:!border-gray-900">
                        Enter Membership Number
                      </label>
                    </div>
                  )}
                </div>
              </div>
              <div>
                <div className="w-full flex flex-col gap-4">
                  {AllCards.length > 0 ? (
                    AllCards.map((card) => {
                      return (
                        <div
                          onClick={() => setSelectedCard(card?._id)}
                          key={card?._id}
                          className={`shadow-sm rounded-md cursor-pointer ${
                            SelectedCard === card?._id
                              ? " bg-primary"
                              : "bg-white border border-BlackishGreen"
                          }  `}
                        >
                          <div className="flex justify-between items-center p-4">
                            <div className="space-y-2 flex items-center space-x-10">
                              <img src={VisaIcon} alt="VisaIcon.svg" />
                              <p
                                className={`${
                                  SelectedCard === card?._id
                                    ? " text-white"
                                    : "text-BlackishGreen"
                                }  sm:text-base text-sm font-bold leading-normal`}
                              >
                                ***** {card?.cardNumber}{" "}
                                <span className="sm:text-sm text-xs font-normal leading-normal">
                                  {card?.expiryDate}
                                </span>
                              </p>
                            </div>
                            <div className="flex gap-10">
                              <div className="inline-flex items-center">
                                <label
                                  className="relative flex items-center p-3 rounded-full cursor-pointer"
                                  htmlFor="Card"
                                >
                                  <input
                                    name="Card"
                                    type="radio"
                                    checked={SelectedCard === card?._id}
                                    className="before:content[''] peer relative h-5 w-5 cursor-pointer appearance-none rounded-full border border-white text-white transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-transparent before:opacity-0 before:transition-opacity checked:border-white checked:before:bg-white hover:before:opacity-10"
                                    id="Card"
                                  />
                                  <span class="absolute text-white transition-opacity opacity-0 pointer-events-none top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 peer-checked:opacity-100">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      class="h-3.5 w-3.5"
                                      viewBox="0 0 16 16"
                                      fill="currentColor"
                                    >
                                      <circle
                                        data-name="ellipse"
                                        cx="8"
                                        cy="8"
                                        r="8"
                                      ></circle>
                                    </svg>
                                  </span>
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <h1>No Cards Found</h1>
                  )}
                </div>
                {AllCards.length > 0 && SelectedCard && (
                  <div
                    style={{ padding: "4px 0px 4px 16px;" }}
                    className="relative w-full h-12 sm:col-span-1 col-span-2 my-2"
                  >
                    <input
                      type="text"
                      className="peer w-full h-full bg-transparent text-[#1C1B1F] font-sans font-normal outline outline-0 focus:outline-0 disabled:bg-blue-gray-50 disabled:border-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 border focus:border-2 border-t-transparent focus:border-t-transparent text-sm px-3 py-2.5 rounded-[7px] border-blue-gray-200 focus:border-gray-900"
                      placeholder=" "
                      name="cvc"
                      value={CVC}
                      onChange={(e) => setCVC(e.target.value)}
                    />
                    <label className="flex w-full h-full select-none pointer-events-none absolute left-0 font-normal !overflow-visible truncate peer-placeholder-shown:text-BlackishGreen leading-tight peer-focus:leading-tight peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-BlackishGreen transition-all -top-1.5 peer-placeholder-shown:text-sm text-[11px] peer-focus:text-[11px] before:content[' '] before:block before:box-border before:w-2.5 before:h-1.5 before:mt-[6.5px] before:mr-1 peer-placeholder-shown:before:border-transparent before:rounded-tl-md before:border-t peer-focus:before:border-t-2 before:border-l peer-focus:before:border-l-2 before:pointer-events-none before:transition-all peer-disabled:before:border-transparent after:content[' '] after:block after:flex-grow after:box-border after:w-2.5 after:h-1.5 after:mt-[6.5px] after:ml-1 peer-placeholder-shown:after:border-transparent after:rounded-tr-md after:border-t peer-focus:after:border-t-2 after:border-r peer-focus:after:border-r-2 after:pointer-events-none after:transition-all peer-disabled:after:border-transparent peer-placeholder-shown:leading-[4.2] blatext-BlackishGreen peer-focus:text-gray-900 before:border-blue-gray-200 peer-focus:before:!border-gray-900 after:border-blue-gray-200 peer-focus:after:!border-gray-900">
                      Enter CVC
                    </label>
                  </div>
                )}

                <div>
                  <div className="flex flex-col ">
                    <div className="flex justify-between p-4 shadow-sm rounded-md cursor-pointer">
                      <div>
                        <h1
                          className={`
                            text-BlackishGreen
                        sm:text-base text-sm font-bold leading-normal `}
                        >
                          Promotions Available
                        </h1>
                        <h1
                          className={`
                            text-BlackishGreen
                        sm:text-sm text-xs font-bold leading-normal `}
                        >
                          20% OFF on Flights
                        </h1>
                        <p
                          className={`
                            text-[#737B73]
                        sm:text-sm text-xs font-bold leading-normal`}
                        >
                          *Terms & conditions apply.
                        </p>
                        <p
                          className={`text-BlackishGreen text-xs font-medium leading-normal underline cursor-pointer`}
                        >
                          More info
                        </p>
                      </div>
                      <div className="flex items-center space-x-5">
                        <div className="inline-flex items-center">
                          <label
                            className="relative flex items-center p-3 rounded-full cursor-pointer"
                            htmlFor="promotions"
                          >
                            <input
                              name="promotions"
                              type="radio"
                              className="before:content[''] peer relative h-5 w-5 cursor-pointer appearance-none rounded-full border border-BlackishGreen textblaborder-BlackishGreen transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-transparent before:opacity-0 before:transition-opacity checked:border-BlackishGreen checked:before:bgblaborder-BlackishGreen hover:before:opacity-10"
                              id="promotions"
                            />
                            <span class="absolute textblaborder-BlackishGreen transition-opacity opacity-0 pointer-events-none top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 peer-checked:opacity-100">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                class="h-3.5 w-3.5"
                                viewBox="0 0 16 16"
                                fill="currentColor"
                              >
                                <circle
                                  data-name="ellipse"
                                  cx="8"
                                  cy="8"
                                  r="8"
                                ></circle>
                              </svg>
                            </span>
                          </label>
                        </div>
                      </div>
                    </div>
                    <Vouchers type="flight" setVoucherCodes={setVoucherCode} />
                    <div
                      onClick={() => setAddCard(true)}
                      className="h-48 w-full border-dashed border-2 border-[#8DD3BB] rounded-xl flex items-center justify-center flex-col space-y-2 cursor-pointer"
                    >
                      <CiCirclePlus className="text-[#8DD3BB]" size={64} />
                      <p className="text-BlackishGreen opacity-75 text-xs font-medium leading-normal">
                        Add a new card
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>

        <div className="lg:sticky static right-0 top-5 h-max w-full">
          <Cart
            fun={handleSubmit}
            loading={BookingFlightLoading}
            isFlight={true}
            setGrandsTotal={setGrandsTotal}
            GrandsTotal={GrandsTotal}
          />
        </div>
      </div>
    </FlightLayout>
  );
};

export default BookDetails2;
