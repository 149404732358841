import React, { useState, useRef, useEffect, useMemo } from "react";
import HostelbookingLayout from "../../../layouts/HostelbookingLayout";
import { DummyMap, Swap } from "../../../assets";
import countryList from "react-select-country-list";
import DatePicker from "react-multi-date-picker";
import { MockHotels } from "../../../lib/airline-iata-codes/mock-data";
import Loader from "../../../components/common/loader";
import moment from "moment";
import { Ratings } from "../../Flights/jsson";
import HotelCards from "../../../components/hotelcard/HotelCards";
import MultiRangeSlider from "multi-range-slider-react";
import { IoSearch } from "react-icons/io5";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import { getCityListwithCodes } from "../../../lib/airline-iata-codes";
import { useDispatch, useSelector } from "react-redux";
import { setHotels } from "../../../lib/Redux/slices/hotelsDataslice";

import {
  Accordion,
  AccordionHeader,
  AccordionBody,
} from "@material-tailwind/react";
import MapPhotoPin from "../../Map/MapPhotoPin";
import httpRequest from "../../../axios";
  import { toast } from 'react-toastify';
const options = [
  { value: "Return", label: "Return" },
  { value: "One-way", label: "One-way" },
];

const HotelBooking = () => {
  // use hooks
  const dispatch = useDispatch();
  //states
  const [open, setOpen] = React.useState(true);
  const [open2, setOpen2] = React.useState(true);
  const [selectedPassenger, setSelectedPassenger] = useState(null);
  const [selectedClass, setSelectedClass] = useState(null);
  const [Catagory, setCatagory] = useState("Best");
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [passengerDropdownOpen, setPassengerDropdownOpen] = useState(false);
  const [selectedCountry1, setSelectedCountry1] = useState(undefined);
  const allCities = useMemo(() => getCityListwithCodes(), []);
  const [dropdownCities, setDropdownCities] = useState(allCities.slice(0, 100));
  // const [allHotels, setAllHotels] = useState([]);
  const allHotels = useSelector((state) => state.hotels_data.allHotels);
  const [previewHotel, setPreviewHotels] = useState([]);
  const [filteredHotels, setFilteredHotels] = useState([]);
  const [checkInDate, setCheckInDate] = useState((new Date()));
  const [checkOutDate, setCheckOutDate] = useState(new Date());
  const dropdownRef = useRef(null);
  const [ratings, setRating] = useState("0");
  const passengerDropdownRef = useRef(null);
  const [] = useState(MockHotels);
  const [loading, setLoading] = useState(false);
  const [minValue, setMinValue] = useState(0);
  const [maxValue, setMaxValue] = useState(500);

  const Countries = useMemo(() => countryList().getData(), []);

  useEffect(() => {
    // console.log(allCities);
    const handleClickOutside = (event) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        !passengerDropdownRef.current.contains(event.target)
      ) {
        setDropdownOpen(false);
        setPassengerDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  useEffect(() => {
    applyFilters();
  }, [minValue, maxValue, ratings, allHotels]);

  const togglePassengerDropdown = () => {
    setPassengerDropdownOpen((prevState) => !prevState);
  };

  const handleLocationSearch = (e) => {
    const inputs = e.target.value;
    setSelectedCountry1(inputs);
    const filteredCities = allCities.filter((item) => {
      return item.city.toLowerCase().includes(inputs.toLowerCase());
    });
    setDropdownCities(filteredCities.slice(0, 10));
  };

  const toggleDropdown = () => {
    setDropdownOpen(true);
  };
  // search hanlder
  const handleSearch = async () => {
    const obj = {
      cityCode: selectedCountry1,
      checkInDate,
      checkOutDate,
      roomQuantity: selectedPassenger?.value,
      numberOfGuests: selectedClass?.value,
    };
    if (!selectedCountry1) return toast.error("Please select location");
    if (!selectedClass) return toast.error("Please select Rooms");
    if (!selectedPassenger) return toast.error("Please select Guest");
    if (!checkInDate) return toast.error("Please select check In");
    if (!checkOutDate) return toast.error("Please select check Out");
    if (moment(checkInDate).isAfter(checkOutDate))
      return toast.error("Check In must be before Check Out");
    if (moment().isAfter(moment(checkInDate)))
      return toast.error("Check In must be after today");
    if (moment(checkOutDate).isSame(moment(checkInDate))) {
      return toast.error("Check In must be not same as Check Out");
    }

    console.log(obj);
    try {
      const data = await fetchHotels(obj);
      console.log(data);
      const hotels = data.data;
      dispatch(setHotels(hotels));
      // setAllHotels(hotels);
      setPreviewHotels(hotels.slice(0, 4));
      setFilteredHotels(hotels);
    } catch (error) {
      console.log(error);
    }
  };
  //apply filters
  const applyFilters = () => {
    let hotelsData = allHotels;
    const filteredHotelOffers = allHotels.map(hotel => {
      return {
        ...hotel,
        offers: hotel.offers.filter(offer => {
          const totalPrice = parseFloat(offer.price.total);
          return totalPrice >= minValue && totalPrice <= maxValue;
        })
      };
    }).filter(hotel => hotel.offers.length > 0);
    hotelsData = filteredHotelOffers;
    // console.log(filteredHotelOffers,"filteredHotelOffers");

    if (ratings) {
      hotelsData = hotelsData.filter(
        (item) => item.hotel.rating.rating >= ratings
      );
    }
    setFilteredHotels(hotelsData);
    setPreviewHotels(hotelsData.slice(0, 4));
  };
  // fetch Hotels Offers
  const fetchHotels = async (params) => {
    setLoading(true);
    try {
      const data = {
        ...params,
      };
      const response = await httpRequest.post("/api/v1/hotels", data);
      setLoading(false);
      scrollDown();
      return response.data;
    } catch (error) {
      toast.error("No hotels found");
      setLoading(false);
    }
  };
  const handleLoadMore = () => {
    setPreviewHotels(filteredHotels.slice(0, previewHotel.length + 4));
  };

  const scrollDown = () => {
    if (window.innerWidth < 720) {
      window.scrollBy({
        top: window.innerHeight,
        behavior: "smooth",
      });
    }
  };
  return (
    <>
      <HostelbookingLayout>
        <MapPhotoPin />
        <div className="w-[90%] mx-auto pb-10">
          <div className="grid grid-cols-1">
            <div
              style={{ padding: "32px 24px" }}
              className="w-full font-Montserrat flex gap-5 flex-wrap"
            >
              {/* ========= From To =========  */}
              <div
                className="xl:w-[22%] lg:w-[30%] sm:w-1/2 w-full relative border h-11 border-blue-gray-200 focus:border-gray-900 rounded-md"
                ref={dropdownRef}
              >
                <input
                  // onClick={setDropdownOpen(true)}
                  className="w-full h-full px-3 py-3 font-sans text-sm font-normal transition-all bg-transparent border rounded-md peer text-[#1C1B1F] outline outline-0 focus:outline-0  disabled:border-0  placeholder-shown:border-blue-gray-200 "
                  placeholder="City Code"
                  onFocus={toggleDropdown}
                  onChange={handleLocationSearch}
                  value={selectedCountry1 ? `${selectedCountry1}` : ""}
                />
                <label className="absolute -top-2 left-3 text-xs font-normal text-[#9E9E9E] bg-white px-1">
                  Enter Destination
                </label>
                <img
                  src={Swap}
                  alt="swap.svg"
                  className="absolute right-2 top-[10px]"
                />
                {dropdownOpen && (
                  <div className="CountryDropdown  overflow-y-scroll  grid grid-cols py-2 absolute w-full h-60 bg-white shadow-md z-10">
                    <div>
                      {/* <h1 className="text-center text-textcolor border-b border-r">
                        From
                      </h1> */}
                      <div className="border-r h-full">
                        <div className="max-h-[400px] overflow-y-auto space-y-2 p-2 custom-scroll">
                          {allCities &&
                            dropdownCities.map((item, index) => (
                              <div
                                onClick={() => setSelectedCountry1(item.iata)}
                                key={index}
                                className="cursor-pointer text-sm hover:text-primary "
                              >
                                {`${item?.iata} - ${item?.city} ,${item?.country}`}
                              </div>
                            ))}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              {/* ///// */}

              {/* ========= Date Picker (Check-In) =========  */}
              <div className="xl:w-[22%] lg:w-1/3 sm:w-1/2 w-full relative border h-11 border-blue-gray-200 focus:border-gray-900 rounded-md">
                <label className="absolute -top-2 left-3 text-xs font-normal text-[#9E9E9E] bg-white px-1">
                  Check In
                </label>
                <DatePicker
                  //  value={values}
                  format="DD/MM/YYYY"
                  onChange={(date) => {
                    setCheckInDate(moment(new Date(date)).format("YYYY-MM-DD"));
                  }}
                />
              </div>
              {/* ========= Date Picker (Check-In) =========  */}
              <div className="xl:w-[22%] lg:w-1/3 sm:w-1/2 w-full relative border h-11 border-blue-gray-200 focus:border-gray-900 rounded-md">
                <label className="absolute -top-2 left-3 text-xs font-normal text-[#9E9E9E] bg-white px-1">
                  Check Out
                </label>
                <DatePicker
                  format="DD/MM/YYYY"
                  onChange={(date) => {
                    setCheckOutDate(
                      moment(new Date(date)).format("YYYY-MM-DD")
                    );
                  }}
                />
              </div>
              {/* ========= Passenger Class =========  */}
              <div
                className="xl:w-[22%] lg:w-1/3 sm:w-[45%] w-full  relative h-11 border-blue-gray-200 focus:border-gray-900 rounded-md"
                ref={passengerDropdownRef}
              >
                <input
                  className="w-full h-full px-3 py-3 font-sans text-sm font-normal transition-all bg-transparent border rounded-md peer text-[#1C1B1F] outline outline-0 focus:outline-0  disabled:border-0  placeholder-shown:border-blue-gray-200 "
                  placeholder="-"
                  onFocus={togglePassengerDropdown}
                  value={
                    selectedPassenger || selectedClass
                      ? `${selectedPassenger?.label || ""} , ${
                          selectedClass?.label || ""
                        }`
                      : ""
                  }
                />
                <label className="absolute -top-2 left-3 text-xs font-normal text-[#9E9E9E] bg-white px-1">
                  Rooms & Guests
                </label>

                {passengerDropdownOpen && (
                  <div className="CountryDropdown grid grid-cols-2 py-2 absolute w-full h-60 bg-white shadow-md borderz-10">
                    <div>
                      <h1 className="text-center text-textcolor border-b border-r">
                        Rooms
                      </h1>
                      <div className="border-r h-full">
                        <div className="max-h-[200px] overflow-y-auto space-y-2 p-2 custom-scroll">
                        <div className="px-1 ">
                            <input className="w-full px-1 border-2" min={1} max={40} onChange={
                              (e)=>{
                                if(e.target.value > 40) return
                                if(e.target.value < 1) return
                                setSelectedPassenger({
                                value: e.target.value,
                                label: `${e.target.value} Rooms`,
                              })}
                            } type="number"
                            placeholder="No. of Rooms"
                            value={selectedPassenger?.value}
                            />
                          </div>
                          {[1, 2, 3].map((num) => (
                            <div
                              onClick={() =>
                                setSelectedPassenger({
                                  value: num,
                                  label: `${num} Rooms`,
                                })
                              }
                              className="cursor-pointer text-sm hover:text-primary"
                              key={num}
                            >
                              {num} Rooms
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                    <div>
                      <h1 className="text-center text-textcolor border-b">
                        Guests
                      </h1>
                      <div className="border-r h-full">
                        <div className="max-h-[200px] overflow-y-auto space-y-2 p-2 custom-scroll">
                        <div className="px-1 ">
                            <input className="w-full px-1 border-2" min={1} max={40} onChange={
                              (e)=>{
                                if(e.target.value > 40) return
                                if(e.target.value < 1) return
                                setSelectedClass({
                                value: e.target.value,
                                label: `${e.target.value} Guests`,
                              })}
                            } type="number"
                            placeholder="No. of Guests"
                            value={selectedClass?.value}
                            />
                          </div>
                          {["1 Guest", "2 Guests", "3 Guests", " 4 Guests"].map(
                            (option, index) => (
                              <>
                              <div
                                onClick={() =>
                                  setSelectedClass({
                                    value: index + 1,
                                    label: option,
                                  })
                                }
                                className="cursor-pointer text-sm hover:text-primary"
                                key={index}
                              >
                                {option}

                              </div>
                              </>
                            )
                          )}
                        </div>
                        
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <button
                onClick={handleSearch}
                className="xl:w-[4%] lg:w-[30%] w-full h-11 border bg-primary rounded-md cursor-pointer flex justify-center items-center"
              >
                {/* <img src={SearchFlight} alt="SearchFlight.svg" /> */}
                <IoSearch style={{ color: "white", fontSize: "20px" }} />
              </button>
            </div>
            {/* //// */}
          </div>

          <div class="grid grid-cols-1 md:grid-cols-4 gap-4">
            <div>
              {/* /////////////////// */}
              <div className="md:sticky left-0 top-0 h-max  ">
                <h1 className="text-BlackishGreen text-xl leading-normal font-semibold">
                  Filters
                </h1>
                <Accordion
                  open={open}
                  // icon={<Icon id={1} open={open} />}
                  className="border-b"
                >
                  <AccordionHeader
                    className="border-none text-base font-semibold leading-normal"
                    onClick={() => setOpen(!open)}
                  >
                    <div className="flex w-[100%] justify-between">
                      <h1>Price</h1>
                      <div
                        style={{
                          transition: "all linear 0.20s",
                          transform: `rotate(${open ? "180deg" : "0deg"})`,
                        }}
                      >
                        <MdOutlineKeyboardArrowDown
                          style={{ fontSize: "24px" }}
                        />
                      </div>
                    </div>
                  </AccordionHeader>
                  <AccordionBody className="relative px-1">
                    <MultiRangeSlider
                      min={0}
                      max={500}
                      minValue={minValue}
                      maxValue={maxValue}
                      onInput={(e) => {
                        setMinValue(e.minValue);
                        setMaxValue(e.maxValue);
                      }}
                      label={false}
                      ruler={false}
                      style={{
                        border: "none",
                        boxShadow: "none",
                      }}
                      barLeftColor="#79747E"
                      barInnerColor="#112211"
                      barRightColor="#79747E"
                      thumbLeftColor="#007BFF"
                      thumbRightColor="#007BFF"
                    />
                    <div className="flex justify-between">
                      <p>{`$${minValue}`}</p>
                      <p>{`$${maxValue}`}</p>
                    </div>
                  </AccordionBody>
                </Accordion>

                <Accordion open={open2} className="border-b">
                  <AccordionHeader
                    className="border-none text-base font-semibold leading-normal"
                    onClick={() => setOpen2(!open2)}
                  >
                    <div className="flex w-[100%] justify-between">
                      <h1>Rating</h1>
                      <div
                        style={{
                          transition: "all linear 0.20s",
                          transform: `rotate(${open2 ? "180deg" : "0deg"})`,
                        }}
                      >
                        <MdOutlineKeyboardArrowDown
                          style={{ fontSize: "24px" }}
                        />
                      </div>
                    </div>
                  </AccordionHeader>
                  <AccordionBody className="relative px-1">
                    <div className="flex gap-2">
                      {Ratings &&
                        Ratings.map((rating) => (
                          <div
                            key={rating?.id}
                            onClick={() => setRating(rating.rate)}
                            className={`flex items-center justify-center h-[32px] w-[40px] border rounded cursor-pointer border-[#8DD3BB] ${
                              rating?.rate === ratings
                                ? "bg-[#8DD3BB] text-white"
                                : ""
                            }  `}
                          >
                            +{rating?.rate}
                          </div>
                        ))}
                    </div>
                  </AccordionBody>
                </Accordion>
                {/* //// */}
                {/* <Accordion
                  open={open4}
                 
                  className="border-b"
                >
                  <AccordionHeader
                    className="border-none text-base font-semibold leading-normal"
                    onClick={() => setOpen4(!open4)}
                  >
                    <div className="flex w-[100%] justify-between">
                      <h1>Freebies</h1>
                      <div
                        style={{
                          transition: "all linear 0.20s",
                          transform: `rotate(${open4 ? "180deg" : "0deg"})`,
                        }}
                      >
                        <MdOutlineKeyboardArrowDown
                          style={{ fontSize: "24px" }}
                        />
                      </div>
                    </div>
                  </AccordionHeader>
                  <AccordionBody className="relative px-1 flex flex-col">
                    {Freebies &&
                      Freebies.map((items) => (
                        <div key={items?.id} class="inline-flex items-center">
                          <label
                            class="relative flex items-center p-3 rounded-full cursor-pointer"
                            htmlFor="check"
                          >
                            <input
                              type="checkbox"
                              class="before:content[''] peer relative h-5 w-5 cursor-pointer appearance-none rounded-md border-2 border-blue-gray-200 transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity checked:border-gray-900 checked:bg-gray-900 checked:before:bg-gray-900 hover:before:opacity-10"
                              id="check"
                            />
                            <span class="absolute text-white transition-opacity opacity-0 pointer-events-none top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 peer-checked:opacity-100">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                class="h-3.5 w-3.5"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                                stroke="currentColor"
                                stroke-width="1"
                              >
                                <path
                                  fill-rule="evenodd"
                                  d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                  clip-rule="evenodd"
                                ></path>
                              </svg>
                            </span>
                          </label>
                          <label
                            class="mt-px text-BlackishGreen cursor-pointer select-none text-sm font-medium"
                            htmlFor="check"
                          >
                            {items?.name}
                          </label>
                        </div>
                      ))}
                  </AccordionBody>
                </Accordion> */}
                {/* //// */}
                {/* <Accordion
                  open={open3}
              
                  className="border-b"
                >
                  <AccordionHeader
                    className="border-none text-base font-semibold leading-normal"
                    onClick={() => setOpen3(!open3)}
                  >
                    <div className="flex w-[100%] justify-between">
                      <h1>Amenities</h1>
                      <div
                        style={{
                          transition: "all linear 0.20s",
                          transform: `rotate(${open3 ? "180deg" : "0deg"})`,
                        }}
                      >
                        <MdOutlineKeyboardArrowDown
                          style={{ fontSize: "24px" }}
                        />
                      </div>
                    </div>
                  </AccordionHeader>
                  <AccordionBody className="relative px-1 flex flex-col">
                    {Amenities &&
                      Amenities.map((items) => (
                        <div key={items?.id} class="inline-flex items-center">
                          <label
                            class="relative flex items-center p-3 rounded-full cursor-pointer"
                            htmlFor="check"
                          >
                            <input
                              type="checkbox"
                              class="before:content[''] peer relative h-5 w-5 cursor-pointer appearance-none rounded-md border-2 border-blue-gray-200 transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity checked:border-gray-900 checked:bg-gray-900 checked:before:bg-gray-900 hover:before:opacity-10"
                              id="check"
                            />
                            <span class="absolute text-white transition-opacity opacity-0 pointer-events-none top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 peer-checked:opacity-100">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                class="h-3.5 w-3.5"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                                stroke="currentColor"
                                stroke-width="1"
                              >
                                <path
                                  fill-rule="evenodd"
                                  d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                  clip-rule="evenodd"
                                ></path>
                              </svg>
                            </span>
                          </label>
                          <label
                            class="mt-px text-BlackishGreen cursor-pointer select-none text-sm font-medium"
                            htmlFor="check"
                          >
                            {items?.title}
                          </label>
                        </div>
                      ))}
                  </AccordionBody>
                </Accordion> */}
              </div>
              {/* ////////////////// */}
            </div>
            <div className="col-span-3">
              {/* //// */}
              <div className="w-full ">
                <div
                  style={{ padding: "16px 24px" }}
                  className="grid sm:grid-cols-4 grid-cols-1 shadow-sm bg-white"
                >
                  <div className="col-span-1 sm:border-b-0 border-b sm:px-2 cursor-pointer">
                    <div
                      onClick={() => setCatagory("Cheapest")}
                      className={` border-b-4 ${
                        Catagory === "Cheapest"
                          ? "border-[#8DD3BB]"
                          : "border-transparent"
                      }  pb-3`}
                    >
                      <h1 className="text-BlackishGreen text-base font-semibold leading-normal">
                        Hotels
                      </h1>
                      <p className="text-BlackishGreen text-sm font-normal leading-normal opacity-40">
                        <span>{filteredHotels?.length}</span>{" "}
                        <span>places</span>{" "}
                      </p>
                    </div>
                  </div>
                  {/* <div className="col-span-1 sm:border-l sm:border-b-0 border-b sm:px-2 cursor-pointer">
                    <div
                      onClick={() => setCatagory("Best")}
                      className={` border-b-4 ${
                        Catagory === "Best"
                          ? "border-[#8DD3BB]"
                          : "border-transparent"
                      }  pb-3`}
                    >
                      <h1 className="text-BlackishGreen text-base font-semibold leading-normal">
                        Motels
                      </h1>
                      <p className="text-BlackishGreen text-sm font-normal leading-normal opacity-40">
                        <span>{allHotels.length}</span> <span>places</span>{" "}
                      </p>
                    </div>
                  </div> */}
                  {/* <div className="col-span-1 sm:border-b-0 sm:border-l border-b sm:px-2 cursor-pointer">
                    <div
                      onClick={() => setCatagory("Quickest")}
                      className={` border-b-4 ${
                        Catagory === "Quickest"
                          ? "border-[#8DD3BB]"
                          : "border-transparent"
                      }  pb-3`}
                    >
                      <h1 className="text-BlackishGreen text-base font-semibold leading-normal">
                        Resorts
                      </h1>
                      <p className="text-BlackishGreen text-sm font-normal leading-normal opacity-40">
                        <span>40</span> <span>places</span>{" "}
                      </p>
                    </div>
                  </div> */}
                  {/* <div className="col-span-1 sm:border-l sm:px-2 cursor-pointer">
                    <div
                      onClick={() => setCatagory("Others")}
                      className={` border-b-4 ${Catagory === "Others"
                          ? "border-[#8DD3BB]"
                          : "border-transparent"
                        }  pb-3 flex items-center h-full gap-2`}
                    >
                      <span>
                        <HiBars3 size={24} />
                      </span>
                      <h1 className="text-BlackishGreen text-sm font-medium leading-normal">
                        Other sort
                      </h1>
                    </div>
                  </div> */}
                </div>
              </div>
              {/* //// */}
              <div class="w-full flex justify-center items-center pt-3 pb-3">
                <div class="w-full min-h-32 rounded-md border">
                  <img
                    className="h-full w-full object-cover"
                    src={DummyMap}
                    alt="DummyMap.svg"
                  />
                </div>
              </div>
              <div className="">
                <div className="flex justify-between items-center ">
                  <div
                    style={{ fontWeight: "Montserrat" }}
                    className="text-14 font-semibold "
                  >
                    Showing for {previewHotel.length} of{" "}
                    <span style={{ color: "#FF8682" }}>
                      {filteredHotels.length} places{" "}
                    </span>
                  </div>
                  <div className="text-14 font-normal flex cursor-pointer ">
                    Sort by{" "}
                    <span className="font-semibold ml-[3px]"> Recommended</span>
                    <div>
                      <MdOutlineKeyboardArrowDown
                        style={{ fontSize: "20px" }}
                      />
                    </div>
                  </div>
                </div>
                {!loading && (
                  <>
                    {filteredHotels.length > 0 &&
                      previewHotel.map((hotel, index) => (
                        <div key={index}>
                          <HotelCards isHotel={true} data={hotel} />
                        </div>
                      ))}
                    {previewHotel.length !== filteredHotels.length && (
                      <button
                        style={{ padding: "8px 16px" }}
                        onClick={handleLoadMore}
                        className="w-full h-[48px] bg-BlackishGreen rounded-md my-5 hover:bg-green-900 text-white text-sm font-semibold"
                      >
                        Show more results
                      </button>
                    )}
                  </>
                )}
                {loading && <Loader />}
              </div>
            </div>
          </div>
        </div>
        {/* /// */}
      </HostelbookingLayout>
    </>
  );
};

export default HotelBooking;
