import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  header: "",
  paths: [],
  image: "",
  CurrentDestination: null,
  CurrentFiles: [],
  HotelBooked: 0,
  FlightBooked: 0,
  SearchVal: "",
  CurrentInd: [],
  PrevPathActive: false,
};

const webDataSlice = createSlice({
  name: "WebData",
  initialState,
  reducers: {
    setheader: (state, action) => {
      state.header = action.payload;
    },
    setpaths: (state, action) => {
      state.paths = action.payload;
    },
    setSidebarimg: (state, action) => {
      state.image = action.payload;
    },
    SetCurrentDestination: (state, action) => {
      state.CurrentDestination = action.payload;
    },
    setCurrentChatFiles: (state, action) => {
      state.CurrentFiles = action.payload;
    },
    SetHotelBooked: (state, action) => {
      state.HotelBooked = action.payload;
    },
    SetFlightBooked: (state, action) => {
      state.FlightBooked = action.payload;
    },
    SetSerachValue: (state, action) => {
      state.SearchVal = action.payload;
    },
    SetCurrentDestINd: (state, action) => {
      state.CurrentInd = action.payload;
    },
    setPrevPathActive: (state, action) => {
      state.PrevPathActive = true;
    },
    setPrevPathDeActive: (state, action) => {
      state.PrevPathActive = false;
    },
  },
});

export const {
  setheader,
  setpaths,
  setSidebarimg,
  SetCurrentDestination,
  setCurrentChatFiles,
  SetHotelBooked,
  SetFlightBooked,
  SetSerachValue,
  SetCurrentDestINd,
  setPrevPathActive,
  setPrevPathDeActive
} = webDataSlice.actions;
export const selectWebData = (state) => state.WebData.value;
export default webDataSlice.reducer;
