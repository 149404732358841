import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";
import httpRequest from "../../axios";
import { PROTECTED } from "../../constants/apiEndPoints";
import { clearUser } from "../../lib/Redux/slices/userslice";
  import { toast } from 'react-toastify';

export const PrivateRoute = ({ children }) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { isAuthenticated, user } = useSelector((state) => state.user);

  useEffect(() => {
    const checkToken = async () => {
      try {
        const resp = await httpRequest.get(PROTECTED, {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        });
        if (resp?.data?.message === "Access Granted") {
        }
      } catch (err) {
        if (err?.response?.status === 401 || err?.response?.status === 403) {
          handleUnauthorized();
        }
      }
    };
    checkToken();
  }, [location.pathname]);

  const handleUnauthorized = () => {
    toast.error("Session Expired Please Login Again !");
    dispatch(clearUser());
  };

  if (!isAuthenticated) {
    return <Navigate to="/login" replace />;
  }
  return children;
};
