const Ratings = [
  {
    id: 1,
    rate: "0",
  },
  {
    id: 2,
    rate: "1",
  },
  {
    id: 3,
    rate: "2",
  },
  {
    id: 4,
    rate: "3",
  },
  {
    id: 5,
    rate: "4",
  },
];
const Airlines = [
  {
    id: 1,
    name: "Emirated",
  },
  {
    id: 2,
    name: "Fly Dubai",
  },
  {
    id: 3,
    name: "Qatar",
  },
  {
    id: 4,
    name: "Etihad",
  },
];

const Overs = [
  {
    id: 1,
    name: "Direct",
    value: 0,
  },
  {
    id: 2,
    name: "1 Stopover",
    value: 1,
  },
  {
    id: 3,
    name: "2 Stopover",
    value: 2,
  },
];

const Freebies = [
  {
    id: 1,
    name: "Free breakfast",
  },
  {
    id: 2,
    name: "Free parking",
  },
  {
    id: 3,
    name: "Free internet",
  },
  {
    id: 4,
    name: "Free airport shuttle",
  },
  {
    id: 5,
    name: "Free cancellation",
  },
];

const Amenities = [
  {
    id: 1,
    title: "24hr front desk",
  },
  {
    id: 1,
    title: "Air-conditioned",
  },
  {
    id: 1,
    title: "Fitness",
  },
  {
    id: 1,
    title: "Pool",
  },
];

export { Airlines, Ratings, Overs, Amenities, Freebies };
