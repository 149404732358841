import React, { useEffect, useRef, useState } from "react";
import HosteldescriptionLayout from "../../../layouts/HosteldescriptionLayout";
import ContentLoader from "react-content-loader";
import {
  Hotel,
  Location,
  Ratting,
  Cup,
  AccomodationS,
  DummyMap2,
  LocationIcon,
} from "../../../assets";
import { setSelectedHotels } from "../../../lib/Redux/slices/hotelsDataslice";
import { useDispatch, useSelector } from "react-redux";
import { Icon } from "leaflet";
import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet";

import ReviewBox from "../../../components/reviewbox/ReviewBox";
import { startsvg } from "../../../assets";
import HorizantalCard from "../../../components/rooms/HorizantalCard";
import { rectangle } from "../../../assets";
import Divider from "../../../components/divider/Divider";
import { modi } from "../../../assets";
import Amenities from "../../../components/Amenities/Amenities";
import ImageRound from "../../../components/images/ImageRound";
import ReviewCardHorizontaly from "../../../components/reviewcard/ReviewCardHorizontaly";
import { FaStar } from "react-icons/fa";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { IoShareSocialSharp } from "react-icons/io5";
import httpRequest from "../../../axios";
import { getAddress } from "../../../lib/airline-iata-codes";
const Image = "assets/images/overview.png";
const MyLoader = () => (
  <ContentLoader height="500" width="1400" viewBox="0 0 1400 500">
    <rect width="1400" height="89" rx="12" fill="#EBE9E9" />
    <rect y="109" width="1400" height="491" rx="12" fill="#EBE9E9" />
  </ContentLoader>
);
const HostelDetial = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const [hotel, setHotel] = useState(undefined);
  const [hotelAddress, setHotelAddress] = useState(undefined);
  const [position, setPosition] = useState([51.505, -0.09]);
  const [rating, setRatings] = useState({
    rating: 0,
    reviews: 0,
  });
  const [offers, setOffers] = useState([]);
  const fetchHotelDetails = async () => {
    try {
      const reponse = await httpRequest.get(`/api/v1/hotels/${id}`);
      const address = await getAddress(reponse.data.hotel.cityCode);
      console.log(address, "address");
      setHotelAddress(address);
      console.log(reponse.data);
      setHotel(reponse.data.hotel);
      setRatings(reponse.data.hotel.rating);
      setOffers(reponse.data.offers);
      setPosition([reponse.data.hotel.latitude, reponse.data.hotel.longitude]);
      console.log(reponse.data);
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
      navigate("/hotels");
    }
  };
  useEffect(() => {
    fetchHotelDetails();
  }, []);

  // set the booking hotel
  const setHotelsForBooking = () => {
    dispatch(setSelectedHotels(hotel));
  };
  const reviews = [
    {
      img: rectangle,
      user: "Olsen",
      title: "Amazing",
      des: "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi neque sed veritatis tenetur officia magni suscipit. In voluptatum ex saepe voluptate aut magni exercitationem velit quam odit, natus officia maiores?",
    },
    {
      img: rectangle,
      user: "Olsen",
      title: "Amazing",
      des: "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi neque sed veritatis tenetur officia magni suscipit. In voluptatum ex saepe voluptate aut magni exercitationem velit quam odit, natus officia maiores?",
    },
    {
      img: rectangle,
      user: "Olsen",
      title: "Amazing",
      des: "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi neque sed veritatis tenetur officia magni suscipit. In voluptatum ex saepe voluptate aut magni exercitationem velit quam odit, natus officia maiores?",
    },
    {
      img: rectangle,
      user: "Olsen",
      title: "Amazing",
      des: "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi neque sed veritatis tenetur officia magni suscipit. In voluptatum ex saepe voluptate aut magni exercitationem velit quam odit, natus officia maiores?",
    },
  ];

  

  return (
    <HosteldescriptionLayout>
      {loading && <MyLoader />}
      {!loading && (
        <div className="w-[90%] ml-[5%] font-Montserrat ">
          {/* Detail Header Start */}
          <div>
            {hotelAddress && (
              <div className="flex ml-[16px]">
                <h1 className="text-[14px] font-medium">
                  {hotelAddress?.country}
                </h1>
                <span className="ml-[8px] text-[14px]  ">{">"}</span>
                <h1 className="text-[14px] font-medium ml-[8px] ">
                  {hotelAddress.city}
                </h1>
                <span className="ml-[8px] text-[14px] ">{">"}</span>
                <p
                  className="text-[14px] font-medium ml-[8px]"
                  style={{ color: "#A0A7A0" }}
                >
                  {hotel && hotel.name}
                </p>
              </div>
            )}
            <div className="col-span-2 p-4 ">
              <div className="grid md:grid-cols-3 grid-cols-1 font-Montserrat pb-3 w-full ">
                <div className="col-span-2 ">
                  <div className="flex justify-start algin-center overflow-hidden ">
                    <div className="">
                      <p className="font-semibold pb-2 text-[18px] md:text-[20px]">
                        {hotel && hotel.name}
                      </p>
                    </div>
                    {/* <div className=" ml-[8px] flex gap-2 pb-2 items-start flex-col sm:flex-row ">
                 
                    <div className="flex ml-[7px] ">
                      <FaStar style={{ color: "#FF8682", fontSize: "18px" }} />
                      <FaStar style={{ color: "#FF8682", fontSize: "18px" }} />
                      <FaStar style={{ color: "#FF8682", fontSize: "18px" }} />
                      <FaStar style={{ color: "#FF8682", fontSize: "18px" }} />
                      <FaStar style={{ color: "#FF8682", fontSize: "18px" }} />
                    </div>
                    <p class=" flex justify-start items-center w-[250px] font-medium  ml-[8px] sm:ml-[2px] text-[12px] mt-[0px] sm:mt-[2.5px] ">
                      5 Star Hotel
                    </p>
                  </div> */}
                  </div>
                  <div className="flex gap-2 pb-2">
                    <img src={Location} alt="Loction icon" />
                    <p className="text-[12px] md:text-[14px]">
                      {hotel && hotel?.address}
                    </p>
                  </div>
                  <div className="flex gap-2 pb-2">
                    <img src={Cup} alt="cup icon" />
                    <p className="font-medium text-[12px] md:text-[14px]">
                      {" "}
                      <span className="font-semibold">20+</span> Aminities{" "}
                    </p>
                  </div>
                  <div className="flex items-center gap-2 text-[12px] md:text-[14px]">
                    <button class=" text-black font-normal py-1 px-2 border border-[#8DD3BB] rounded-[0.25rem]">
                      {rating && rating.rating}
                    </button>
                    <p className="font-medium">
                      <span className="font-semibold ">Very Good </span>{" "}
                      {rating && rating.reviews} reviews
                    </p>
                  </div>
                </div>
                <div className="text-[#FF8682] md:text-right text-[12px] md:text-[14px]">
                  <p className=" text-black">Starting from</p>
                  <p className="text-[24px] font-bold">
                    {offers[0]?.price?.currency} {offers[0]?.price?.total}
                    {/* <span className="text-[12px] h-1">/night</span> */}
                  </p>
                  <p className="text-black font-[12px] ">excl.tax</p>
                  <div className="flex gap-3 pt-3 text-[12px] md:text-[14px] ">
                    <button class=" text-black font-normal py-1 px-2 border border-[#8DD3BB] rounded-[0.25rem]">
                      🖤
                    </button>
                    <button class=" text-black font-normal py-1 px-2 border border-[#8DD3BB] rounded-[0.25rem]">
                      <IoShareSocialSharp />
                    </button>
                    <button
                      onClick={() => navigate(`/hotels/Booking/${id}`)}
                      className="bg-blue-500 hover:bg-blue-400 transition-colors duration-300 py-2 w-full rounded-md text-white font-medium text-[14px]"
                    >
                      Book now
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Detail Images Start */}

          <div className="grid xl:grid-cols-2 lg:grid-cols-2 md:grid-1 sm:grid-1  gap-2  ">
            <div
              style={{ backgroundImage: `url(${Image})` }}
              className="w-full h-[550px] bg-cover"
            ></div>
            <div className="flex justify-start items-center flex-col">
              <div className="grid grid-cols-2 h-[270px] border w-full gap-2 ">
                <div
                  style={{ backgroundImage: `url(${Image})` }}
                  className="w-full h-[270px] bg-cover"
                ></div>
                <div
                  style={{ backgroundImage: `url(${Image})` }}
                  className="w-full h-[270px] bg-cover"
                ></div>
              </div>
              <div className="grid grid-cols-2 h-[270px] border w-full gap-2 mt-[10px] ">
                <div
                  style={{ backgroundImage: `url(${Image})` }}
                  className="w-full h-[270px] bg-cover"
                ></div>
                <div
                  style={{ backgroundImage: `url(${Image})` }}
                  className="w-full h-[270px] bg-cover"
                ></div>
              </div>
            </div>
          </div>

          <div>
            <Divider color={"#c3c8c3"} />
          </div>

          {/* Detail Overwiew Start */}

          <div className="flex flex-col space-y-4">
            <h1 className="text-[20px] font-bold text-BlackishGreen ">
              Overview
            </h1>
            <p className="text-base font-medium opacity-75 leading-normal">
              {hotel && hotel.name} which is located at {hotel && hotel.address}
              .
            </p>
          </div>

          {/* Rating Card */}

          {/* <div className="grid container 2xl:grid-cols-7 xl:grid-cols-6 lg:grid-cols-4 sm:grid-cols-3 grid-cols-2 gap-5 pt-4">
            {rating?.rating !== 0 && (
              <ReviewBox
                mainheading={rating && rating.rating}
                icon={null}
                heading={"Very Good"}
                reviews={rating && rating.reviews}
                bg={"#007BFF"}
                ml={"0px"}
                border={null}
              />
            )}
            {rating?.rating !== 0 &&
              Object.entries(rating)
                .splice(2)
                .map(([key, value]) => (
                  <ReviewBox
                    mainheading={((value * 5) / 100).toFixed(1)}
                    // icon={startsvg}
                    heading={key}
                    // bg={"white"}
                    ml={""}
                    border={"#8DD3BB"}
                  />
                ))}
          </div> */}

          <div>
            <Divider color={"#c3c8c3"} />
          </div>

          {/* All rooms */}

          <div>
            <h1 className="text-xl font-bold  ">Available Rooms</h1>
            <div className="w-full flex justify-start flex-col  mt-8">
              {offers.length > 0 &&
                offers.map((item) => (
                  <HorizantalCard bookNow={setHotelsForBooking} item={item} />
                ))}
            </div>
            <div>
              <Divider color={"#c3c8c3"} />
            </div>
          </div>

          {/* Map */}

          <div>
            <div className="flex justify-between items-center pb-4">
              <h1 className="text-[20px] font-bold ">Location/Map</h1>
              {/* <div className="rounded bg-[#007BFF] duration-200 cursor-pointer hover:bg-blue-800 flex justify-center items-center text-sm font-medium text-white px-4 py-3">
                View on google maps
              </div> */}
            </div>

            {position && (
              <div className="h-[450px] w-full border flex items-center justify-center ">
                <MapContainer
                  center={position}
                  style={{ height: "100%", width: "100%" }}
                  zoom={13}
                  scrollWheelZoom={false}
                >
                  <TileLayer
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                  />
                  <Marker
                    title="Hotel Marker"
                    position={position}
                    icon={
                      new Icon({
                        iconUrl: LocationIcon,
                        iconSize: [30, 30],
                      })
                    }
                  >
                    <Popup>{hotel && hotel.name}</Popup>
                  </Marker>
                </MapContainer>
              </div>
            )}

            {/* <div className='mt-[64px] mb-[64px] ' ><Divider color={'#c3c8c3'} /></div> */}
          </div>

          {/* Amenities */}

          <div>
            <h1 className="text-[20px] font-bold ">Amentities</h1>
            <div className="grid grid-cols-4 mt-32px">
              {hotel?.rating &&
                Object.entries(hotel?.rating)
                  ?.splice(2)
                  .map(([key, value]) => (
                    <div className="col-span-2 mt-[25px] ">
                      <Amenities items={{ key, value }} />
                    </div>
                  ))}
            </div>
            <div className="mt-[64px] mb-[64px] ">
              <Divider color={"#c3c8c3"} />
            </div>
          </div>
        </div>
      )}
    </HosteldescriptionLayout>
  );
};

export default HostelDetial;
