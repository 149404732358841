import React from "react";

const Contactterms = () => {
  return (
    <div className="flex flex-col mt-[32px] font-Montserrat">
      <h2 className="text-xl text-BlackishGreen font-medium pb-4">
        Contact us
      </h2>
      <ul className="flex flex-col space-y-1">
        <li className="text-sm font-normal text-BlackishGreen leading-5">
          If you have any questions about our Website or our Terms of Use ,
          please contact:
        </li>
        <li className="text-sm font-normal text-BlackishGreen leading-5">
          Guideon Tower
        </li>
        <li className="text-sm font-normal text-BlackishGreen leading-5">
          Guideon Group Q.C.S.C
        </li>
        <li className="text-sm font-normal text-BlackishGreen leading-5">
          P.O. Box : 22550
        </li>
        <li className="text-sm font-normal text-BlackishGreen leading-5">
          Doha, State of Qatar
        </li>
        <li className="text-sm font-normal text-BlackishGreen leading-5">
          Further contact details can be found at Guideon{" "}
          <span className="underline cursor-pointer">.com/help</span>
        </li>
      </ul>
    </div>
  );
};

export default Contactterms;
