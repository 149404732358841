import React, { useEffect, useState } from "react";
import { Loader } from "../common";
import { useDispatch, useSelector } from "react-redux";
import { setHotelsCartData } from "../../lib/Redux/slices/hotelsDataslice";

const HotelCart = ({
  HotelOffers,
  HotelDetails,
  loading = false,
  payment = () => {},
  setGrandTotal = () => {},
}) => {
  const { HoteslCartData = [] } = useSelector((state) => state.hotels_data);
  const { user } = useSelector((state) => state.user);
  const [Currencys, setCurrencys] = useState("$");
  const [Total, setTotal] = useState(0);

  useEffect(() => {
    const Currencys =
      HotelOffers[0]?.price?.currency === "USD"
        ? "$"
        : HotelOffers[0]?.price?.currency + " ";
    setCurrencys(Currencys);
  }, [HoteslCartData]);

  useEffect(() => {
    const total = HoteslCartData.reduce((acc, item) => {
      const price = Number(item.price) || 0;
      return acc + price;
    }, 0);
    setTotal(total);
    setGrandTotal(total);
  }, [HoteslCartData]);

  return (
    <div className="w-full font-Montserrat">
      <div className="w-full bg-white shadow 2xl:p-6 rounded-md">
        <div className="flex sm:flex-row flex-col pb-4 border-b">
          <div className="2xl:h-[120px] 2xl:w-[120px] lg:h-[150px] lg:w-[200px] w-full border rounded-md overflow-hidden">
            <img
              className="h-full w-full object-cover"
              src={
                "https://media.istockphoto.com/id/1677784097/photo/modern-hotel-apartment-with-bathroom.webp?b=1&s=170667a&w=0&k=20&c=rKzi2VKY7EAq_Ggx7AoYtRetG_aNze-L6nk4n4uwLLg="
              }
              alt="EmertiesPlane.svg"
            />
          </div>
          <div className=" px-4 space-y-4 w-4/5">
            <div>
              <h1 className="sm:text-xl lg:text-sm text-base font-semibold text-BlackishGreen">
                {HotelOffers[0]?.room?.description?.text}
              </h1>
            </div>
            <div className="flex 2xl:flex-row lg:flex-col flex-row items-center space-x-1">
              <div className="flex items-center justify-center h-[32px] 2xl:w-[40px] lg:w-full w-[40px] border border-[#8DD3BB] rounded cursor-pointer">
                4.2
              </div>
              <p className="text-xs font-bold text-BlackishGreen">
                Very Good <span className="font-medium">54 reviews</span>
              </p>
            </div>
          </div>
        </div>
        <div className="py-4 border-b">
          <p className="text-BlackishGreen sm:text-base text-sm font-medium leading-normal">
            Your booking is protected by{" "}
            <span className="font-bold">Myguideon</span>
          </p>
        </div>
        <h1 className="sm:text-base text-sm font-bold leading-normal text-BlackishGreen">
          Price Details
        </h1>
        <div className="max-h-[250px] overflow-auto ">
          {HoteslCartData?.map((items) => {
            const { title, price } = items;
            return (
              <>
                <div className="py-4 border-b space-y-4">
                  <div className="flex justify-between">
                    <span className="sm:text-base text-sm font-medium leading-normal">
                      {title}
                    </span>
                    <span>
                      <span className="text-BlackishGreen sm:text-base text-sm font-semibold leading-normal">
                        {Currencys}
                        {Math.round(price)}
                      </span>
                    </span>
                  </div>
                </div>
              </>
            );
          })}
        </div>
        <div className="pt-4">
          <div className="flex justify-between">
            <span className="sm:text-base text-sm font-medium leading-normal">
              Total
            </span>
            <span>
              <span className="text-BlackishGreen sm:text-base text-sm font-semibold leading-normal">
                {Currencys}
                {Math.round(Total)}
              </span>
            </span>
          </div>
        </div>
      </div>
      <div className="py-4">
        {user !== null && (
          <button
            style={{ padding: "8px 16px" }}
            disabled={loading}
            onClick={payment}
            className="w-full h-[48px] bg-primary rounded-md hover:bg-blue-800 text-white text-sm font-semibold"
          >
            {loading ? <Loader /> : "Continue"}
          </button>
        )}
      </div>
    </div>
  );
};

export default HotelCart;
