import React from "react";
import { CultureImg, ReligionGlobe, SvgLine } from "../../../assets";
import { useSelector } from "react-redux";

const Culture = ({ showModal = () => {} }) => {
  const { CurrentDestination = null } = useSelector((state) => state.web);

  return (
    <>
      <div className="grid grid-cols-5 font-Montserrat h-[80vh] overflow-auto pt-[10px] relative">
        <div className="col-span-5 md:col-span-2 ">
          {/* // */}
          <h1 className="text-[#394149] text-[20px] md:text-[18px] lg:text-[20px]  font-extrabold text-justify md:text-right ">
            {CurrentDestination?.cultureheading1}
          </h1>
          <p className="text-[14px] md:text-[12px] lg:text-[14px] text-[#304149] text-justify md:text-right font-medium  md:min-h-[300px] mt-[4px]  ">
            {CurrentDestination?.cultureDesp1}
          </p>
          {/* // */}
          <h1 className="text-[#394149] text-[20px]  md:text-[18px] lg:text-[20px] font-extrabold text-justify md:text-right mt-[10px]  ">
            {CurrentDestination?.cultureheading2}
          </h1>
          <p className="text-[14px] md:text-[12px] lg:text-[14px] text-[#304149] text-justify md:text-right font-medium mt-[4px] ">
            {CurrentDestination?.cultureDesp2}
          </p>
        </div>
        <div className="col-span-5 md:col-span-1 ">
          <div
            className=" w-full flex flex-col items-center bg-[45%] bg-auto "
            style={{
              backgroundImage: `url(${SvgLine})`,
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
            }}
          >
            <div className="h-[190px] w-[140px] md:h-[140px] md:w-[140px] lg:w-[190px] lg:h-[190px] rounded-full overflow-hidden">
              <img
                src={CurrentDestination?.cultureimage}
                className="h-full w-full"
                alt={"cultureimg.svg"}
              />
            </div>
            <img
              src={ReligionGlobe}
              className="h-[190px] md:h-[140px] lg:h-[190px] "
            />
            <img
              src={ReligionGlobe}
              className="h-[190px] md:h-[140px] lg:h-[190px] "
            />
          </div>
        </div>
        <div className="col-span-5 md:col-span-2 ">
          <div
            className="h-[100%] flex justify-end "
            style={{ height: "max-content" }}
          >
            <img src={CultureImg} className="h-[275px] w-[275px] " />
          </div>
          <h1 className="text-[#394149] text-[20px] md:text-[18px] lg:text-[20px] font-extrabold text-left mt-[10px]  ">
            {CurrentDestination?.cultureheading3}
          </h1>
          <p className="text-[14px] md:text-[12px] lg:text-[14px] text-[#304149] text-start font-medium mt-[4px] ">
            {CurrentDestination?.cultureDesp3}
          </p>
        </div>
        <div className="absolute right-0 bottom-0 flex space-x-2">
          <button
            onClick={() => showModal("Step2")}
            className="py-2 px-4 rounded-2xl bg-[#FFC502] text-[#025F80] font-bold text-[18px] w-max "
          >
            Previous
          </button>
          <button
            onClick={() => showModal("Step4")}
            className="py-2 px-4 rounded-2xl bg-[#FFC502] text-[#025F80] font-bold text-[18px] w-max"
          >
            Next
          </button>
        </div>
      </div>
    </>
  );
};

export default Culture;
