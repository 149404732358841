import React, { useState, useRef, useEffect, useMemo } from "react";
import FlightLayout from "../../../layouts/FlightLayout";
import { SearchFlight, Swap } from "../../../assets";
import countryList from "react-select-country-list";
import Select from "react-select";
import { HiBars3 } from "react-icons/hi2";
import DatePicker from "react-multi-date-picker";
import airportcodes from "airport-codes";

import {
  Accordion,
  AccordionHeader,
  AccordionBody,
} from "@material-tailwind/react";
import MultiRangeSlider from "multi-range-slider-react";
import { Overs } from "../jsson";
import { FlightCard } from "../../../components";
import { useNavigate } from "react-router-dom";
  import { toast } from 'react-toastify';
import httpRequest from "../../../axios";
import { GET_FLIGHT_OFFERS } from "../../../constants/apiEndPoints";
import Loader from "../../../components/common/loader";
import { getAirlineName } from "../../../lib/airline-iata-codes";

import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import {
  setAirlines,
  setDictionaries,
  setFlights,
  setPessengers,
} from "../../../lib/Redux/slices/flightsSlice";

/**
 *
 * OPTIONS FOR TICKET ROUTE TYPE
 *
 */
const options = [
  { value: "Return", label: "Return" },
  { value: "One-way", label: "One-way" },
];

/**
 * icon
 * @param {ID,OPEN} param0
 * @returns
 */
function Icon({ id, open }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={2}
      stroke="currentColor"
      className={`${
        id === open ? "rotate-180" : ""
      } h-5 w-5 transition-transform`}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M19.5 8.25l-7.5 7.5-7.5-7.5"
      />
    </svg>
  );
}

const formatDate = (date) => {
  return new Date(date).toLocaleDateString("en-CA");
};

const FlightHome = () => {
  /**
   *
   * HOOKS
   *
   */
  // DISPATCH
  const dispatch = useDispatch();
  // NAVIGATTION HOOK
  const navigate = useNavigate();
  // SAVED AIRLINES
  const allFlights = useSelector((state) => state.flight.allflights);
  // ALL CONTRIES LIST
  const Countries = useMemo(() => countryList().getData(), []);
  // ALL AIRPORTS LIST
  const allAirports = useMemo(() => airportcodes.toJSON(), []);
  // AIRPORT DROPDOWN REF
  const dropdownRef = useRef(null);
  //PASSENGER DROPDOWN REF
  const passengerDropdownRef = useRef(null);

  // LOADING STATE
  const [loading, setLoading] = useState(false);
  // AIRPORY DROPDOWN STATE
  const [dropdownOpen, setDropdownOpen] = useState(false);
  // PASSENGER DROPDOWN STATE
  const [passengerDropdownOpen, setPassengerDropdownOpen] = useState(false);
  // FIRST AIRPORTS LIST
  const [AirportList1, setAirportList1] = useState(allAirports.slice(0, 100));
  // FIRST AIRPORTS SELECT
  const [AirportList2, setAirportList2] = useState(allAirports.slice(0, 100));
  // FIRST AIRPORT SELECT
  const [selectedCountry1, setSelectedCountry1] = useState("");
  //  SECOND AIRPORT SELECT
  const [selectedCountry2, setSelectedCountry2] = useState("");
  // TRIP TYPE SELECTOR
  const [Triptype, setTriptype] = useState(options[0]);
  // ALL AIRLINES RESULT THROUGH API
  // const [airlines, setAirlines] = useState(MockAirlines);
  const airlines = useSelector((state) => state.flight.airlines);
  // VALUE FOR FILTERING THROUGH AIRLINES
  const [selectedAirlines, setSelectedAirlines] = useState([]);
  // Number of stop overs
  const [selectedStops, setSelectedStops] = useState([]);
  // LL FLIGHTS THROUGH API
  // const [allFlights, setAllFlights] = useState([]);
  // FOR BACK IN PREVIEW
  const [dataCollection, setDataCollection] = useState([]);
  // PREVIEW FLIGHTS
  const [previewFlights, setPreviewFlights] = useState([]);
  // Acordian
  // PRICE ACCORDIAN STATE
  const [open, setOpen] = React.useState(true);
  // RATING ACCORDIAN STATE
  const [open2, setOpen2] = React.useState(true);
  // AIRLINES ACCORDIAN STATE
  const [open3, setOpen3] = React.useState(true);
  // NUMBER OF STOP OVERS
  const [open4, setOpen4] = React.useState(true);
  // Multi Range Slider
  // STARTING PRICE STATE
  const [minValue, setMinValue] = useState(0);
  // ENDING PRICE STATE
  const [maxValue, setMaxValue] = useState(5000);
  //ARRIVAL AND DEPARTURE DATE
  const [values, setValues] = useState([
    formatDate(new Date()),
    formatDate(new Date()),
  ]);
  // NUMBER OF PASSENGERS VALUE
  const [selectedPassenger, setSelectedPassenger] = useState(null);
  // SELECTED CLASS VALUE
  const [selectedClass, setSelectedClass] = useState(null);
  // SELECTED CATEGORY
  const [Catagory, setCatagory] = useState("Cheapest");
  /**
   *
   * @STATES END HERE
   *
   *
   */

  /**
   *
   *
   * HANDLERS
   *
   *
   */
  // HANDLER FOR CALLING FLIGHT API
  const fetchFlights = async (params) => {
    try {
      setLoading(true);
      const data = {
        ...params,
        maxLength: 250,
      };
      const response = await httpRequest.post(GET_FLIGHT_OFFERS, data);

      const flightsData = response.data.flights;
      const airlineName = response.data.airlinesCodes;
      const allDictionaries = response.data.dictionaries;

      dispatch(setAirlines(airlineName));
      // setAllFlights(flightsData);
      setDataCollection(flightsData);
      setPreviewFlights(flightsData.slice(0, 4));
      dispatch(setFlights(flightsData));
      dispatch(setDictionaries(allDictionaries));
      await applyFilters();
      setLoading(false);
    } catch (error) {
      toast.error(error.message);
      setLoading(false);
      console.log(error);
    }
  };
  // HANDLES THE FIRST AIRPORT SEARCH DROPDOWN
  const handleFirstAirlineSearch = (e) => {
    setSelectedCountry1(e.target.value);
    if (!e.target.value) {
      setAirportList1(airportcodes.toJSON().slice(0, 100));
    } else {
      // console.log("searching----");
      const searchResults = allAirports.filter((airport) =>
        airport.city.toLowerCase().includes(e.target.value.toLowerCase())
      );
      // console.log(searchResults, "Airports");
      setAirportList1(searchResults);
    }
  };
  // HANDLE THE SECOND AIRPORT SEARCH DROPDOWN
  const handleSecondAirlineSearch = (e) => {
    setSelectedCountry2(e.target.value);
    if (!e.target.value) {
      setAirportList2(airportcodes.toJSON().slice(0, 100));
    } else {
      // console.log("searching----");
      const searchResults = allAirports.filter((airport) =>
        airport.city.toLowerCase().includes(e.target.value.toLowerCase())
      );
      // console.log(searchResults, "Airports");
      setAirportList2(searchResults);
    }
  };
  // HANDLE THE LOADMORE FEATURE IN THE APP
  const handleLoadMore = () => {
    setPreviewFlights(dataCollection.slice(0, previewFlights.length + 4));
  };
  // HANDLE SEARCH CLICK ON BTN
  const handleSearch = async () => {
    try {
      
      const params = {
        originLocationCode: selectedCountry1,
        destinationLocationCode: selectedCountry2,
        type: Triptype.value,
        adults: selectedPassenger?.value,
        travelClass: selectedClass?.value,
        departureDate: values[0],
      };

      if (!selectedCountry1) return toast.error("Please select departure");
      if (!selectedCountry2) return toast.error("Please select destination");
      if (!selectedClass) return toast.error("Please select class");

      if (!selectedPassenger) return toast.error("Please select passenger");
      if (!Triptype.value) return toast.error("Please select trip type");
      if (moment() > moment(values[0]))
        return toast.error("Please select valid date");

      if (Triptype.value === "Return") {
        if (!values[1]) return toast.error("Please select return date");
        if (moment(values[0]) > moment(values[1]))
          return toast.error("Please select valid date");

        params.returnDate = values[1];
      }

      dispatch(setPessengers(selectedPassenger?.value));

      const data = await fetchFlights(params);
      scrollDown();
    } catch (error) {
      console.log(error);
      toast.error("No Result Found");
    }
  };

  const FlightContainer = useRef(null);

  const scrollDown = () => {
    if (window.innerWidth < 720) {
      console.log("scrolling");
      window.scrollBy({
        top: window.innerHeight * 2,
        behavior: "smooth",
      });
    }
  };

  // HANDLES THE AIRLINE FILTERS
  const handleAirlineFilter = (airlineCode) => {
    setSelectedAirlines((prevSelectedAirlines) => {
      if (prevSelectedAirlines.includes(airlineCode)) {
        // Remove airline code if already selected
        return prevSelectedAirlines.filter(
          (airline) => airline !== airlineCode
        );
      } else {
        // Add airline code if not selected
        return [...prevSelectedAirlines, airlineCode];
      }
    });
  };
  // HANDLES THE STOPOVERS FILTERS
  const handleStopoversFilter = (stops) => {
    setSelectedStops((prevSelectedStops) => {
      if (prevSelectedStops.includes(stops)) {
        // Remove airline code if already selected
        return prevSelectedStops.filter((stop) => stop !== stops);
      } else {
        // Add airline code if not selected
        return [...prevSelectedStops, stops];
      }
    });
  };

  const applyFilters = () => {
    let filteredData = allFlights;
    if (selectedAirlines.length) {
      filteredData = allFlights.filter((flight) =>
        selectedAirlines.includes(flight.validatingAirlineCodes[0])
      );
    } else {
      filteredData = allFlights;
    }
    if (selectedStops.length) {
      filteredData = filteredData.filter((flight) => {
        console.log(flight.itineraries[0].segments.length);
        return selectedStops.includes(
          flight.itineraries[0].segments.length - 1
        );
      });
    } else {
      filteredData = filteredData;
    }
    if (minValue || maxValue) {
      const data = filteredData.filter(
        (flight) =>
          parseInt(flight.price.grandTotal) >= parseInt(minValue) &&
          parseInt(flight.price.grandTotal) <= parseInt(maxValue)
      );
      filteredData = data;
    }
    if (Catagory) {
      switch (Catagory) {
        case "Cheapest":
          filteredData = filteredData.sort(
            (a, b) => a.price.grandTotal - b.price.grandTotal
          );
          break;
        case "Quickest":
          filteredData = filteredData.sort((a, b) => a.duration - b.duration);
          break;
        case "Best":
          filteredData = filteredData.sort(
            (a, b) => b.price.grandTotal - a.price.grandTotal
          );
          break;
        default:
          filteredData = filteredData;
      }
    }
    setDataCollection(filteredData);
    setPreviewFlights(filteredData.slice(0, 4));
  };

  /**
   *
   *
   * @HANDLERS ENDED
   *
   *
   *
   */
  // USEEFFECT TO APPLY FILTERS
  useEffect(() => {
    applyFilters();
  }, [
    selectedAirlines,
    allFlights,
    selectedStops,
    minValue,
    maxValue,
    Catagory,
  ]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        !passengerDropdownRef.current.contains(event.target)
      ) {
        setDropdownOpen(false);
        setPassengerDropdownOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  /**
   *
   * TOGGLERS AND SWITCHERS
   *
   */
  const toggleDropdown = () => {
    setDropdownOpen((prevState) => !prevState);
  };
  const openDropdown = () => {
    setDropdownOpen(true);
  };
  const closeDropdown = () => {
    setDropdownOpen(false);
  };

  const togglePassengerDropdown = () => {
    setPassengerDropdownOpen((prevState) => !prevState);
  };

  const handleDateChange = (newValues) => {
    const formattedDates = newValues.map(formatDate);
    setValues(formattedDates);
  };

  const handlePassengerSelect = (option) => {
    setSelectedPassenger(option);
    setPassengerDropdownOpen(false);
  };

  const handleClassSelect = (option) => {
    setSelectedClass(option);
    setPassengerDropdownOpen(false);
  };

  /**
   *
   * @TOGGLERS AND SWITCHERS ENDS HERE
   *
   */

  useEffect(() => {
    dispatch(setFlights([]));
  }, []);

  return (
    <FlightLayout>
      <div
        style={{ padding: "32px 24px" }}
        className="w-full font-Montserrat flex gap-5 flex-wrap"
      >
        {/* ========= From To =========  */}
        <div
          className="xl:w-[24%] lg:w-[30%] sm:w-1/2 w-full relative border h-11 border-blue-gray-200 focus:border-gray-900 rounded-md"
          ref={dropdownRef}
        >
          <div className="w-full h-full  font-sans text-sm font-normal transition-all bg-transparent border rounded-md peer text-[#1C1B1F] outline outline-0 focus:outline-0  disabled:border-0  placeholder-shown:border-blue-gray-200 flex flex-row ">
            <input
              // placeholder="-"
              className="w-1/2 h-full px-3 outline outline-0 focus:outline-0 border-0 text-center "
              onFocus={openDropdown}
              onChange={handleFirstAirlineSearch}
              value={`${selectedCountry1}`}
            />
            <span className="w-1/12 h-full flex items-center justify-center">
              -
            </span>
            <input
              // placeholder="-"
              className="w-1/2 h-full px-3 outline outline-0 focus:outline-0 border-0 text-center "
              onFocus={openDropdown}
              onChange={handleSecondAirlineSearch}
              value={`${selectedCountry2}`}
            />
          </div>
          <label className="absolute -top-2 left-3 text-xs font-normal text-[#9E9E9E] bg-white px-1">
            From - To
          </label>
          <img
            src={Swap}
            alt="swap.svg"
            className="absolute right-2 top-[10px]"
          />
          {dropdownOpen && (
            <div className="CountryDropdown grid grid-cols-2 py-2 absolute w-full h-60 bg-white shadow-md z-10">
              <div>
                <h1 className="text-center text-textcolor border-b border-r">
                  From
                </h1>
                <div className="border-r h-full">
                  <div className="max-h-[200px] overflow-y-auto space-y-2 p-2 custom-scroll">
                    {AirportList1 &&
                      AirportList1.map((airport, index) => (
                      airport.iata &&  <div
                          key={index}
                          onClick={() => {
                            setSelectedCountry1(airport?.iata);
                            closeDropdown();
                          }}
                          className="cursor-pointer text-sm hover:text-primary"
                        >
                          {airport?.city}- {airport?.iata} - {airport?.name}
                        </div>
                      ))}
                  </div>
                </div>
              </div>
              <div>
                <h1 className="text-center text-textcolor border-b">to</h1>
                <div className="border-r h-full">
                  <div className="max-h-[200px] overflow-y-auto space-y-2 p-2 custom-scroll">
                    {AirportList2 &&
                      AirportList2.map((airport, index) => (
                      airport?.iata &&  <div
                          onClick={() => {
                            setSelectedCountry2(airport?.iata);
                            closeDropdown();
                          }}
                          className="cursor-pointer text-sm hover:text-primary"
                          key={index}
                        >
                         {airport?.city}- {airport?.iata} - {airport?.name}
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>

        {/* ========= Return  =========  */}

        <div className="xl:w-[15%] lg:w-[30%] sm:w-[45%] w-full relative">
          <Select
            className="SelectInpFlight"
            value={Triptype}
            onChange={(selectedOption) => setTriptype(selectedOption)}
            options={options}
          />
          <label className="absolute -top-2 left-3 text-xs font-normal text-[#9E9E9E] bg-white px-1">
            Trip
          </label>
        </div>
        {/* ========= Date Picker =========  */}
        <div className="xl:w-[24%] lg:w-1/3 sm:w-1/2 w-full relative border h-11 border-blue-gray-200 focus:border-gray-900 rounded-md">
          <label className="absolute -top-2 left-3 text-xs font-normal text-[#9E9E9E] bg-white px-1">
            Depart- Return
          </label>
          <DatePicker  value={values} onChange={handleDateChange} range />
        </div>
        {/* ========= Passenger Class =========  */}
        <div
          className="xl:w-[24%] lg:w-1/3 sm:w-[45%] w-full  relative h-11 border-blue-gray-200 focus:border-gray-900 rounded-md"
          ref={passengerDropdownRef}
        >
          <input
            className="w-full h-full px-3 py-3 font-sans text-sm font-normal transition-all bg-transparent border rounded-md peer text-[#1C1B1F] outline outline-0 focus:outline-0  disabled:border-0  placeholder-shown:border-blue-gray-200 "
            placeholder="-"
            onFocus={togglePassengerDropdown}
            value={
              selectedPassenger || selectedClass
                ? `${selectedPassenger?.label || ""} , ${
                    selectedClass?.label || " "
                  }`
                : ""
            }
          />
          <label className="absolute -top-2 left-3 text-xs font-normal text-[#9E9E9E] bg-white px-1">
            Passenger - Class
          </label>

          {passengerDropdownOpen && (
            <div className="CountryDropdown grid grid-cols-2 py-2 absolute w-full h-60 bg-white shadow-md border z-10">
              <div>
                <h1 className="text-center text-textcolor border-b border-r">
                  Passenger
                </h1>
                <div className="border-r h-full">
                  <div className="max-h-[200px] overflow-y-auto space-y-2 p-2 custom-scroll">
                  <div className="px-1 ">
                            <input className="w-full px-1 border-2" min={1} max={40} onChange={
                              (e)=>{
                                if(e.target.value > 40) return
                                if(e.target.value < 1) return
                                setSelectedPassenger({
                                value: e.target.value,
                                label: `${e.target.value} Passengers`,
                              })}
                            } type="number"
                            placeholder="No. of Passengers"
                            value={selectedPassenger?.value}
                            />
                          </div>
                    {[1, 2, 3 , 4].map((num) => (
                      <div
                        onClick={() =>
                          setSelectedPassenger({
                            value: num,
                            label: `${num} Passengers`,
                          })
                        }
                        className="cursor-pointer text-sm hover:text-primary"
                        key={num}
                      >
                        {num} Passengers
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div>
                <h1 className="text-center text-textcolor border-b">Class</h1>
                <div className="border-r h-full">
                  <div className="max-h-[200px] overflow-y-auto space-y-2 p-2 custom-scroll">
                    {[{"name": "Economy", "value": "ECONOMY"}, {"name": "Premium Economy", "value": "PREMIUM_ECONOMY"}, {"name": "Business", "value": "BUSINESS"}, {"name": "First Class", "value": "FIRST"} ].map((option, index) => (
                      <div
                        onClick={() =>
                          setSelectedClass({
                            value: option.value.toUpperCase(),
                            label: option.name,
                          })
                        }
                        className="cursor-pointer text-sm hover:text-primary"
                        key={index}
                      >
                        {option.name}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        <button
          onClick={handleSearch}
          className="xl:w-[4%] lg:w-[30%] w-full h-11 border bg-primary rounded-md cursor-pointer flex justify-center items-center"
        >
          <img src={SearchFlight} alt="SearchFlight.svg" />
        </button>
      </div>

      {/* // ======= Side Filter Area ========= */}

      <div className="w-full py-2 grid md:grid-cols-4 grid-cols-1 gap-6 relative">
        <div className="md:sticky left-0 top-0 h-max">
          <h1 className="text-BlackishGreen text-xl leading-normal font-semibold">
            Filters
          </h1>
          <Accordion
            open={open}
            icon={<Icon id={1} open={open} />}
            className="border-b"
          >
            <AccordionHeader
              className="border-none text-base font-semibold leading-normal"
              onClick={() => setOpen(!open)}
            >
              Price
            </AccordionHeader>
            <AccordionBody className="relative px-1">
              <MultiRangeSlider
                min={0}
                max={5000}
                minValue={minValue}
                maxValue={maxValue}
                onInput={(e) => {
                  setMinValue(e.minValue);
                  setMaxValue(e.maxValue);
                }}
                label={false}
                ruler={false}
                style={{
                  border: "none",
                  boxShadow: "none",
                }}
                barLeftColor="#79747E"
                barInnerColor="#112211"
                barRightColor="#79747E"
                thumbLeftColor="#007BFF"
                thumbRightColor="#007BFF"
              />
              <div className="flex justify-between">
                <p>{`$${minValue}`}</p>
                <p>{`$${maxValue}`}</p>
              </div>
            </AccordionBody>
          </Accordion>

          <Accordion
            open={open2}
            icon={<Icon id={2} open={open2} />}
            className="border-b"
          >
            {/* <AccordionHeader
              className="border-none text-base font-semibold leading-normal"
              onClick={() => setOpen2(!open2)}
            >
              Rating
            </AccordionHeader> */}
            {/* <AccordionBody className="relative px-1">
              <div className="flex gap-2">
                {Ratings &&
                  Ratings.map((rating) => (
                    <div
                      key={rating?.id}
                      className="flex items-center justify-center h-[32px] w-[40px] border border-[#8DD3BB] rounded cursor-pointer"
                    >
                      {rating?.rate}
                    </div>
                  ))}
              </div>
            </AccordionBody> */}
          </Accordion>
          <Accordion
            open={open3}
            icon={<Icon id={3} open={open3} />}
            className="border-b"
          >
            <AccordionHeader
              className="border-none text-base font-semibold leading-normal"
              onClick={() => setOpen3(!open3)}
            >
              Airlines
            </AccordionHeader>
            <AccordionBody className="relative px-1 flex flex-col">
              {airlines &&
                airlines.map((airline, index) => (
                  <div key={index} class="inline-flex items-center">
                    <label
                      class="relative flex items-center p-3 rounded-full cursor-pointer"
                      htmlFor={airline}
                    >
                      <input
                        type="checkbox"
                        class="before:content[''] peer relative h-5 w-5 cursor-pointer appearance-none rounded-md border-2 border-blue-gray-200 transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity checked:border-gray-900 checked:bg-gray-900 checked:before:bg-gray-900 hover:before:opacity-10"
                        id={airline}
                        checked={selectedAirlines.includes(airline)}
                        onChange={() => handleAirlineFilter(airline)}
                      />
                      <span class="absolute text-white transition-opacity opacity-0 pointer-events-none top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 peer-checked:opacity-100">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="h-3.5 w-3.5"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          stroke="currentColor"
                          stroke-width="1"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                            clip-rule="evenodd"
                          ></path>
                        </svg>
                      </span>
                    </label>
                    <label
                      class="mt-px text-BlackishGreen cursor-pointer select-none text-sm font-medium"
                      htmlFor={airline}
                    >
                      {getAirlineName(airline)}
                    </label>
                  </div>
                ))}
            </AccordionBody>
          </Accordion>
          <Accordion
            open={open4}
            icon={<Icon id={4} open={open4} />}
            className="border-b"
          >
            <AccordionHeader
              className="border-none text-base font-semibold leading-normal"
              onClick={() => setOpen4(!open4)}
            >
              Number of Stop Overs
            </AccordionHeader>
            <AccordionBody className="relative px-1 flex flex-col">
              {Overs &&
                Overs.map((stop) => (
                  <div key={stop?.id} class="inline-flex items-center">
                    <label
                      class="relative flex items-center p-3 rounded-full cursor-pointer"
                      htmlFor={stop.value}
                    >
                      <input
                        type="checkbox"
                        class="before:content[''] peer relative h-5 w-5 cursor-pointer appearance-none rounded-md border-2 border-blue-gray-200 transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity checked:border-gray-900 checked:bg-gray-900 checked:before:bg-gray-900 hover:before:opacity-10"
                        id={stop.value}
                        onChange={() => handleStopoversFilter(stop.value)}
                        checked={selectedStops.includes(stop.value)}
                      />
                      <span class="absolute text-white transition-opacity opacity-0 pointer-events-none top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 peer-checked:opacity-100">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="h-3.5 w-3.5"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          stroke="currentColor"
                          stroke-width="1"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                            clip-rule="evenodd"
                          ></path>
                        </svg>
                      </span>
                    </label>
                    <label
                      class="mt-px text-BlackishGreen cursor-pointer select-none text-sm font-medium"
                      htmlFor={stop.value}
                    >
                      {stop?.name}
                    </label>
                  </div>
                ))}
            </AccordionBody>
          </Accordion>
        </div>
        {!loading && previewFlights.length > 0 && (
          <div className="col-span-3">
            {/* // =========== Top Filter Area ================ */}

            <div className="w-full">
              <div
                style={{ padding: "16px 24px" }}
                className="grid sm:grid-cols-4 grid-cols-1 shadow-sm bg-white"
              >
                <div className="col-span-1 sm:border-b-0 border-b sm:px-2 cursor-pointer">
                  <div
                    onClick={() => setCatagory("Cheapest")}
                    className={` border-b-4 ${
                      Catagory === "Cheapest"
                        ? "border-[#8DD3BB]"
                        : "border-transparent"
                    }  pb-3`}
                  >
                    <h1 className="text-BlackishGreen text-base font-semibold leading-normal">
                      Cheapest
                    </h1>
                    <p className="text-BlackishGreen text-sm font-normal leading-normal opacity-40">
                      <span>$99</span>.<span>2h 18m</span>{" "}
                    </p>
                  </div>
                </div>
                <div className="col-span-1 sm:border-l sm:border-b-0 border-b sm:px-2 cursor-pointer">
                  <div
                    onClick={() => setCatagory("Best")}
                    className={` border-b-4 ${
                      Catagory === "Best"
                        ? "border-[#8DD3BB]"
                        : "border-transparent"
                    }  pb-3`}
                  >
                    <h1 className="text-BlackishGreen text-base font-semibold leading-normal">
                      Best
                    </h1>
                    <p className="text-BlackishGreen text-sm font-normal leading-normal opacity-40">
                      <span>$99</span>.<span>2h 18m</span>{" "}
                    </p>
                  </div>
                </div>
                <div className="col-span-1 sm:border-b-0 sm:border-l border-b sm:px-2 cursor-pointer">
                  <div
                    onClick={() => setCatagory("Quickest")}
                    className={` border-b-4 ${
                      Catagory === "Quickest"
                        ? "border-[#8DD3BB]"
                        : "border-transparent"
                    }  pb-3`}
                  >
                    <h1 className="text-BlackishGreen text-base font-semibold leading-normal">
                      Quickest
                    </h1>
                    <p className="text-BlackishGreen text-sm font-normal leading-normal opacity-40">
                      <span>$99</span>.<span>2h 18m</span>{" "}
                    </p>
                  </div>
                </div>
                <div className="col-span-1 sm:border-l sm:px-2 cursor-pointer">
                  <div
                    onClick={() => setCatagory("Others")}
                    className={` border-b-4 ${
                      Catagory === "Others"
                        ? "border-[#8DD3BB]"
                        : "border-transparent"
                    }  pb-3 flex items-center h-full gap-2`}
                  >
                    <span>
                      <HiBars3 size={24} />
                    </span>
                    <h1 className="text-BlackishGreen text-sm font-medium leading-normal">
                      Other sort
                    </h1>
                  </div>
                </div>
              </div>
              <div className="w-full flex justify-between py-2 sm:flex-row flex-col sm:gap-0 gap-4">
                <p className="text-BlackishGreen text-sm font-semibold leading-normal">
                  Showing <span>{previewFlights.length}</span> of{" "}
                  <span className="text-[#FF8682]">
                    {dataCollection.length} flights
                  </span>
                </p>
                <p className="text-BlackishGreen text-sm font-normal leading-normal">
                  Sort by <span className="font-semibold">Recommended</span>
                </p>
              </div>
            </div>

            {/* =================== Top Filter Area Ending ================== */}

            <div className="w-full flex flex-col gap-4">
              {previewFlights.map((flight, index) => (
                <FlightCard flight={flight} />
              ))}
              {previewFlights.length !== dataCollection.length && (
                <button
                  style={{ padding: "8px 16px" }}
                  onClick={handleLoadMore}
                  className="w-full h-[48px] bg-BlackishGreen rounded-md hover:bg-green-900 text-white text-sm font-semibold"
                >
                  Show more results
                </button>
              )}
            </div>
          </div>
        )}
        {loading && (
          <>
            <div className="container col-span-3 w-full flex justify-center items-center">
              <Loader />
            </div>
          </>
        )}
      </div>
    </FlightLayout>
  );
};

export default FlightHome;
