import React, { useEffect, useState } from "react";
import SettingsLayout from "../../layouts/settingslayout";
import { useDispatch, useSelector } from "react-redux";
import {
  setSidebarimg,
  setheader,
  setpaths,
} from "../../lib/Redux/slices/webData";
import { Edit, SecuritySide } from "../../assets";
import PrivacyPopup from "../../basecomponents/PrivacyPopup";
import { GET_SETTINGS, UPDATE_SETTINGS } from "../../constants/apiEndPoints";
import httpRequest from "../../axios";
import { toast } from "react-toastify";

const paths = [
  { label: "MyGuide", url: "/user-profile" },
  { label: "My Odyssey", url: "/" },
  { label: "Settings", url: "/settings" },
  { label: "Security & Privacy", url: "/security-privacy" },
];

const SecurityPrivacy = () => {
  const dispatch = useDispatch();
  dispatch(setpaths(paths));
  dispatch(setheader("Security & Privacy"));
  dispatch(setSidebarimg(SecuritySide));

  //////--------
  const { user } = useSelector((state) => state.user);

  const [loading, setLoading] = useState(false);
  const [PopUpOpen, setPopUpOpen] = useState(false);
  const [Settings, setSettings] = useState([]);
  const [EditContent, setEditContent] = useState({});
  const [password, setPassword] = useState(null);
  const [newPassord, setnewPassord] = useState(null);
  const [confirmPassword, setconfirmPassword] = useState(null);
  const [status, setStatus] = useState(null);
  const [Notification, setNotification] = useState(null);

  const handleClose = () => {
    setPopUpOpen(false);
    handleEmpty();
  };

  const handleEmpty = () => {
    setPopUpOpen(false);
    setPassword(null);
    setnewPassord(null);
    setNotification(null);
    setStatus(null);
  };

  const handleEdit = (obj) => {
    setEditContent(obj);
    setPopUpOpen(true);
  };

  const FetchSettings = async () => {
    setLoading();
    try {
      const response = await httpRequest.get(GET_SETTINGS);

      if (response.status === 200 || response.status === 201) {
        setSettings(response?.data);
        setLoading(false);
      }
    } catch (error) {
      toast.error("Error updating user information:", error);
      setLoading(false);
    }
  };
  useEffect(() => {
    FetchSettings();
  }, []);

  const handleUpdateSetting = async () => {
    let payload;

    if (password !== null && newPassord !== null) {
      if (password === null) {
        toast.error("Enter old password");
        return;
      }
      if(newPassord === null){
        toast.error("Enter new password");
        return;
      }

      if (newPassord !== confirmPassword) {
        toast.error("Password do not match !");
        return;
      } else {
        payload = {
          oldPassword: password,
          newPassword: newPassord,
        };
      }
    } else if (status !== null) {
      payload = {
        profileStatus: status,
      };
    } else if (Notification !== null) {
      payload = {
        notifications: Notification,
      };
    }

    setLoading(true);
    try {
      const response = await httpRequest.put(UPDATE_SETTINGS, payload);

      if (response.status === 200 || response.status === 201) {
        toast.success("Success");
        setLoading(false);
        handleEmpty();
        FetchSettings();
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
      setLoading(false);
    }
  };

  return (
    <SettingsLayout>
      <div>
        <h1 className="text-textcolor text-xl leading-normal font-semibold">
          Security & Privacy
        </h1>
        {loading && <p>Loading...</p>}
        <div className="bg-white p-5 rounded-xl space-y-6 ShadowMain">
          {!loading &&
            Settings.length > 0 &&
            Settings.map((setting, index) => {
              return (
                <div
                  key={index}
                  className="flex justify-between sm:flex-row flex-col sm:border-b-0 border-b sm:pb-0 pb-2 sm:space-y-0 space-y-2"
                >
                  {setting.title === "Password" && user?.socail ? null : (
                    <div className="space-y-1">
                      <p className="text-black opacity-50 text-sm font-semibold leading-normal">
                        {setting?.title}
                      </p>
                      <h1 className="text-textcolor text-base leading-normal font-semibold">
                        {setting.title === "Password" ? (
                          "**************"
                        ) : setting.title === "Notification" ? (
                          setting.value ? (
                            <span className="text-green-600">Enabled</span>
                          ) : (
                            <span className="text-red-600">Disabled</span>
                          )
                        ) : (
                          setting.value
                        )}
                      </h1>
                    </div>
                  )}
                  {setting.title === "Password" && user?.socail ? null : (
                    <div
                      className="border border-[#007BFF4D] rounded-md sm:px-8 px-4 py-3 flex items-center justify-center space-x-4 cursor-pointer sm:w-max w-full"
                      onClick={() => handleEdit(setting)}
                    >
                      <img src={Edit} alt="change.svg" />
                      <span className="text-textcolor font-Montserrat text-sm font-semibold leading-normal">
                        Change
                      </span>
                    </div>
                  )}
                </div>
              );
            })}
        </div>
        <PrivacyPopup
          isOpen={PopUpOpen}
          loading={loading}
          onSubmit={handleUpdateSetting}
          close={handleClose}
          EditContent={EditContent}
          setPassword={setPassword}
          setnewPassword={setnewPassord}
          setconfirmPassword={setconfirmPassword}
          setStatus={setStatus}
          setNotification={setNotification}
        />
      </div>
    </SettingsLayout>
  );
};

export default SecurityPrivacy;
