import React, { useEffect, useRef, useState } from "react";
import FlightLayout from "../../layouts/FlightLayout";
import { useDispatch, useSelector } from "react-redux";
import { setpaths } from "../../lib/Redux/slices/webData";
import { BreadcrumbArrow } from "../../basecomponents";
import { MdLocationOn } from "react-icons/md";
import { IoMdShare } from "react-icons/io";
import httpRequest from "../../axios";
import TermsConditions from "../Terms";
import FlightTickets from "../../components/Ticket/Ticket";
import Contactterms from "../../components/contact";
import TicketSlider from "../../components/Slider";
import { useNavigate, useParams } from "react-router-dom";
import { GET_SINGLE_BOOKING } from "../../constants/apiEndPoints";
import { toast } from "react-toastify";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";

const headers = [
  { label: "MyGuide", url: "/user-profile" },
  { label: "My World", url: "/" },
  { label: "Transport Booking", url: "/flights/booking" },
];

const TicketsBooked = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  const [bookingDetail, setBookingDetail] = useState(undefined);

  const fetchBookingDetails = async () => {
    try {
      const result = await httpRequest.get(`${GET_SINGLE_BOOKING}${id}`);
      setBookingDetail(result.data);
    } catch (error) {
      console.log(error);
      toast.error("No Such Booking Available ");
      window.location.href = "/flights";
    }
  };
  useEffect(() => {
    if (!id) {
      window.location.href = "/flights";
    }
    fetchBookingDetails();
  }, []);

  dispatch(setpaths(headers));
  const { paths = [] } = useSelector((state) => state.web);

  const divRef = useRef();

  const handleDownloadPDF = () => {
    const input = divRef.current;
    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "mm", "a4");
      const imgWidth = 210;
      const pageHeight = 200;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      let heightLeft = imgHeight;

      let position = 0;

      pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;

      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        pdf.addPage();
        pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
      }
      pdf.save("Ticket.pdf");
    });
  };

  return (
    <FlightLayout>
      <div className="py-3 font-Montserrat">
        <BreadcrumbArrow items={paths} />
      </div>
      <div className="py-10 font-Montserrat">
        <div className="grid 2xl:grid-cols-2 lg:grid-cols-3 col-span-1">
          <div className="flex flex-col justify-center space-y-2">
            <h1 className="text-BlackishGreen text-2xl font-bold leading-normal">
              {bookingDetail?.bookingInfo.career}{" "}
              {bookingDetail?.bookingInfo.aircraftCode}
            </h1>
            <p className="text-BlackishGreen text-sm font-medium leading-normal flex items-center space-x-2">
              {" "}
              {/* <MdLocationOn /> */}
              {/* Gümüssuyu Mah. Inönü Cad. No:8, Istanbul 34437 */}
            </p>
          </div>
          <div className="flex flex-col space-y-2  2xl:col-span-1 lg:col-span-2 col-span-1">
            <h1 className="text-BlackishGreen text-3xl font-extrabold leading-normal text-right">
              {bookingDetail?.paymentAmount}
            </h1>
            <div className="flex items-center justify-end flex-wrap gap-4">
              <button
                onClick={() => navigate("/settings/booking/flights")}
                className="py-3 px-8 border text-sm md:w-max sm:w-[48%] w-full border-primary rounded duration-200 text-primary hover:text-white hover:bg-primary font-semibold"
              >
                View My Bookings
              </button>

              <button className="py-3 flex justify-center px-8 border text-sm md:w-max sm:w-[48%] w-full border-BlackishGreen rounded hover:bg-primary hover:border-primary hover:text-white">
                <IoMdShare size={21} />
              </button>
              <button onClick={handleDownloadPDF} className="py-3 px-8 border text-sm md:w-max sm:w-[48%] w-full bg-primary rounded hover:bg-white hover:text-primary text-BlackishGreen hover:border-primary font-semibold">
                Download
              </button>
            </div>
          </div>
        </div>

        {/* ///// Ticket Area */}

        <FlightTickets downRef={divRef} bookingDetail={bookingDetail} />

        {/* // Terms and Conditions */}

        <TermsConditions />
        {/* // Contact */}
        <Contactterms />

        {/* // Slider */}

        <TicketSlider />
      </div>
    </FlightLayout>
  );
};

export default TicketsBooked;
