// export const API_BASE_URL = "https://api-guidon.code-xperts.org/";
// export const API_BASE_URL = "http://192.168.1.51:8001/";
// export const API_BASE_URL = "http://localhost:8001/";
export const API_BASE_URL = "https://guidonapi.appdeft.in/";

// protected Route
export const PROTECTED = "api/v1/protected";
// User APIS￼

export const SIGNUP_USER = "api/v1/user/signup";
export const PROFILE_COMPLETE = "api/v1/create-profile";
export const LOGIN_USER = "api/v1/user/login";
export const VERIFY_EMAIL = "api/v1/verify-email";
export const RESEND_CODE = "api/v1/resend-verification-code";
export const RESEND_RESET_CODE = "api/v1/resend-verification-code-reset";
export const FORGOT_REQUEST = "api/v1/forgot-request";
export const FORGOT_EMAIL_VERIFY_REQUEST = "api/v1/emailVerify-request";
export const UPDATE_PROFILE_INFO = "api/v1/user/profile-update";
export const RESET_PASSWORD_REQUEST = "api/v1/reset-password";
export const GET_USER_INFO = "api/v1/user-info";
export const GET_SETTINGS = "api/v1/settings";
export const UPDATE_SETTINGS = "api/v1/update-settings";
export const TRAVEL_DOCUMENTS = "api/v1/travel-document";
export const GET_USER_INFORMATION = "api/v1/get-Information";
export const UPDATE_PROFILE = "api/v1/update-profile";
export const SOCIAL_LOGIN = "api/v1/user/socail-auth";
export const GET_FLIGHT_OFFERS = "api/v1/flights";
export const SEND_MESSAGES = "api/v1/message/addmsg";
export const GET_MESSAGES = "api/v1/message/getmsg";
export const GET_ALL_USERS = "api/v1/users";
export const CLEAR_MESSAGES = "api/v1/message/clearmsg";
export const UNREAD_MESSAGES = "api/v1/message/unread";
export const READ_MESSAGES = "api/v1/message/markread";
export const GET_CHAT_HISTORY = "api/v1/users/chats";
export const GetAllDestinations = "/api/v1/admin/dest/destinations";
export const GetSingleDestination = "/api/v1/admin/dest/destination";
export const Booking = "/api/v1/user/all-bookings";
export const GetTravelMode = "/api/v1/user/all-bookings";

export const FlightBooking = "/api/v1/flights/booking";
export const USER_BOOKINGS = "/api/v1/user/explore/bookings";
export const GET_SINGLE_BOOKING = "/api/v1/user/bookings/";
export const AddFav = "/api/v1/favourites";
export const SEARCH_DESTINATION = "/api/v1/admin/dest/destination/search";

// Chats Rutes

export const GET_SPECIFIC_USER = "/api/v1/fetch-by-username";

export const GET_FLIGHTS_NAME =
  "https://test.api.amadeus.com/v1/reference-data/airlines?airlineCodes=";

// Cards

export const CARDS = "api/v1/cards";

// Vouchers

export const Vouchers_API = "/api/v1/admin/vouchers";

// Commision

export const COMMISSION = "/api/v1/admin/comission";

// Get Location

export const GET_LOCATION = "/api/v1/admin/location/get-coordinates";
