import React, { useEffect, useState } from "react";
import Mainlayout from "../../layouts/Mainlayout";
import { useDispatch } from "react-redux";
import {
  setSidebarimg,
  setheader,
  setpaths,
} from "../../lib/Redux/slices/webData";
import {
  HomeSidebarpic,
  Istanbul,
  LineCenter,
  mainCalender,
  mainFlight,
} from "../../assets";

import { Flights } from "./data";
import httpRequest from "../../axios";
import { Booking } from "../../constants/apiEndPoints";
import { toast } from "react-toastify";
import MainDataLayout from "../../layouts/MainDatalayout";
import moment from "moment";

const paths = [
  { label: "MyGuide", url: "/user-profile" },
  { label: "My Odyssey", url: "/" },
];

const Home = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setpaths(paths));
    dispatch(setheader("My Odyssey"));
    dispatch(setSidebarimg(HomeSidebarpic));
  }, [dispatch]);

  const [Data, setData] = useState([]);
  const [Loading, setLoading] = useState(false);

  const GetData = async () => {
    try {
      setLoading(true);
      const response = await httpRequest.get(`${Booking}/flight`);
      if (response.status === 200 || response.status === 201) {
        setData(response?.data);
        setLoading(false);
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    GetData();
  }, []);

  return (
    <Mainlayout>
      <div className="grid grid-cols-1 font-Montserrat">
        <div
          style={{ boxShadow: "0px 8px 12px 0px rgba(62, 63, 122, 0.04)" }}
          className="bg-white p-3 rounded-xl"
        >
          <MainDataLayout>
            {Loading
              ? "Loading..."
              : Data?.length > 0
              ? Data?.map((flight) => {
                  return (
                    <div className="w-full">
                      <div className="flex items-center justify-between shadow-sm py-2">
                        <div className="flex flex-col space-y-2">
                          <div className="flex space-x-4">
                            <img src={mainFlight} alt="AroundAir" />
                            <div className="flex space-x-4">
                              <span className="text-[#394149] text-xs font-semibold leading-normal">
                                {
                                  flight?.bookingInfo?.flightOffers[0]
                                    ?.departure?.iataCode
                                }
                              </span>
                              <img src={LineCenter} alt="Line.svg" />
                              <span className="text-[#394149] text-xs font-semibold leading-normal">
                                {
                                  flight?.bookingInfo?.flightOffers[0]?.arrival
                                    ?.iataCode
                                }
                              </span>
                            </div>
                          </div>
                          <div className="flex space-x-4">
                            <img src={mainCalender} alt="AroundAir" />
                            <div className="flex space-x-4">
                              <span className="text-[#394149] text-xs font-semibold leading-normal">
                                <span className="text-[#394149] text-xs font-semibold leading-normal">
                                  {moment(
                                    flight?.bookingInfo?.flightOffers[0]
                                      ?.departure?.at
                                  ).format(`DD/MM/YYYY`)}
                                </span>
                              </span>{" "}
                              <span> - </span>
                              <span className="text-[#394149] text-xs font-semibold leading-normal">
                                {moment(
                                  flight?.bookingInfo?.flightOffers[0]?.arrival
                                    ?.at
                                ).format(`DD/MM/YYYY`)}
                              </span>
                            </div>
                          </div>
                        </div>
                        <img
                          className="h-10"
                          src={Istanbul}
                          alt="Istanbul.svg"
                        />
                      </div>
                    </div>
                  );
                })
              : "No Found"}
          </MainDataLayout>
        </div>
      </div>
    </Mainlayout>
  );
};

export default Home;
