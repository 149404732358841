import React, { useState } from "react";
import {
  FaRegFileAlt,
  FaImage,
  FaVideo,
  FaFileAudio,
  FaFilePdf,
} from "react-icons/fa";
import { useSelector } from "react-redux";
import FilePreviewModal from "../../basecomponents/Fileprev/FilePreviewComp";
import { FaXmark } from "react-icons/fa6";

const Files = ({ setFileResponse = () => {} }) => {
  const { CurrentFiles = [] } = useSelector((state) => state.web);
  const [showAll, setShowAll] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedFileType, setSelectedFileType] = useState(null);

  const getFileType = (url) => {
    const extension = url.split(".").pop().toLowerCase();
    switch (extension) {
      case "jpg":
      case "jpeg":
      case "png":
      case "gif":
      case "svg":
        return "image";
      case "mp4":
      case "avi":
      case "mkv":
        return "video";
      case "mp3":
      case "wav":
      case "ogg":
        return "audio";
      case "pdf":
        return "pdf";
      default:
        return "file";
    }
  };

  const extractFileName = (url) => {
    const fileName = url.split("/").pop();
    return fileName.replace(/^\d+_/, "");
  };

  const getFileIcon = (fileType) => {
    switch (fileType) {
      case "image":
        return <FaImage size={20} className="text-[#869AA9]" />;
      case "video":
        return <FaVideo size={20} className="text-[#869AA9]" />;
      case "audio":
        return <FaFileAudio size={20} className="text-[#869AA9]" />;
      case "pdf":
        return <FaFilePdf size={20} className="text-[#869AA9]" />;
      default:
        return <FaRegFileAlt size={20} className="text-[#869AA9]" />;
    }
  };

  const handleFileClick = (fileUrl) => {
    const fileType = getFileType(fileUrl);
    setSelectedFile(fileUrl);
    setSelectedFileType(fileType);
  };

  const renderFiles = (files) => {
    return files.map((item, index) => {
      const fileType = getFileType(item);
      return (
        <div
          key={index}
          className="h-24 border rounded-xl overflow-hidden relative cursor-pointer p-2"
          onClick={() => handleFileClick(item)}
        >
          {fileType === "image" && (
            <img className="h-full w-full" src={item} alt="media" />
          )}
          {fileType === "video" && (
            <video className="h-full w-full" controls>
              <source src={item} type="video/mp4" />
            </video>
          )}
          {fileType === "pdf" && (
            <embed
              className="h-full w-full"
              src={item}
              type="application/pdf"
            />
          )}
          {fileType === "audio" && (
            <audio controls className="h-full w-full">
              <source src={item} type="audio/mpeg" />
            </audio>
          )}
          {fileType === "file" && (
            <a
              href={item}
              download
              className="h-full w-full flex items-center justify-center"
            >
              <FaRegFileAlt size={20} className="text-[#869AA9]" />
            </a>
          )}
        </div>
      );
    });
  };

  return (
    <div className="h-[87vh] py-2 px-4 w-full font-Montserrat">
      <div className="w-full p-4 bg-white rounded-xl">
        <div className="border-b pb-2 relative">
          <h1 className="text-[#394149] text-lg font-semibold leading-normal">
            Media
          </h1>
          <p className="text-[#000] text-sm font-semibold leading-normal opacity-50">
            Images and Videos Shared
          </p>
          <FaXmark
            className="text-red-600 mx-2 text-xl absolute top-0 right-0 cursor-pointer lg:hidden block "
            onClick={() => setFileResponse(false)}
          />
        </div>
        <div className="w-full grid sm:grid-cols-3 grid-cols-2 pt-2 gap-3 h-[115px] overflow-y-auto">
          {showAll
            ? renderFiles(CurrentFiles)
            : renderFiles(CurrentFiles.slice(0, 2))}
          {!showAll && CurrentFiles.length > 2 && (
            <div
              className="h-24 border rounded-xl overflow-hidden flex items-center justify-center bg-black bg-opacity-50 cursor-pointer"
              onClick={() => setShowAll(true)}
            >
              <span className="text-white">
                +{CurrentFiles.length - 2} more
              </span>
            </div>
          )}
        </div>
      </div>
      <div className="max-h-96 w-full my-4 p-4 bg-white rounded-xl overflow-y-auto">
        <div className="border-b pb-2">
          <h1 className="text-[#394149] text-lg font-semibold leading-normal">
            Documents
          </h1>
          <p className="text-[#000] text-sm font-semibold leading-normal opacity-50">
            Files Shared
          </p>
        </div>
        <div className="pb-2">
          {CurrentFiles?.map((item, index) => {
            const fileType = getFileType(item);
            return (
              <div
                key={index}
                className="flex items-center justify-between my-4"
              >
                <div className="bg-[#F6F6FA] h-12 w-12 rounded-lg flex justify-center items-center">
                  {getFileIcon(fileType)}
                </div>
                <div className="w-[80%]">
                  <h1 className="text-[#161616] font-medium leading-normal">
                    {extractFileName(item)}
                  </h1>
                  <p className="text-[#869AA9] text-xs font-normal leading-normal">
                    <span>File Size</span>&nbsp;&nbsp;<span>File Date</span>
                  </p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      {selectedFile && (
        <FilePreviewModal
          fileUrl={selectedFile}
          fileType={selectedFileType}
          onClose={() => setSelectedFile(null)}
        />
      )}
    </div>
  );
};

export default Files;
