import React, { useEffect, useRef, useState } from "react";
import { Navbar, Sidear, Upcomming } from "../components";
import { Header } from "../basecomponents";
import Breadcrumb from "../basecomponents/Breadcrumb";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import {
  FlagIcon,
  SetttngsIcon,
  LocationWorld,
  Badge1,
  Badge2,
  Avtar,
} from "../assets";
import UploadImage from "../components/common/uploadimage";
import { SlPencil } from "react-icons/sl";
import { UploadAssests } from "../components/common/UploadAssests";
import httpRequest from "../axios";
import { toast } from "react-toastify";
import { UPDATE_PROFILE } from "../constants/apiEndPoints";
import { setUser } from "../lib/Redux/slices/userslice";

const data = [
  {
    title: "0 Countires",
    img: FlagIcon,
  },
  {
    title: "0 Cities",
    img: LocationWorld,
  },
];

const Mainlayout = ({ children }) => {
  const { user } = useSelector((state) => state.user);
  const { header = "", paths = [] } = useSelector((state) => state.web);
  const location = useLocation();
  const [imgPopup, setimgPopup] = useState(false);
  const [UpCoimming, setUpCommig] = useState(false);
  const upcomingRef = useRef(null);
  const dispatch = useDispatch();

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 1024) {
        setUpCommig(false);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleBackgroundClick = () => {
    setUpCommig(false);
  };

  const handleUpcomingClick = (e) => {
    e.stopPropagation();
  };

  const [selectedFile, setSelectedFile] = useState(null);
  const [Loading, setLoading] = useState(false);
  const handleFileChange = async (event) => {
    setSelectedFile(event.target.files[0]);

    try {
      setLoading(true);
      const image = await UploadAssests(event.target.files[0]);
      const response = await httpRequest.put(UPDATE_PROFILE, {
        profilepic: image,
      });

      if (response.status === 200 || response.status === 201) {
        toast.success(response?.data?.message);
        dispatch(setUser(response?.data?.user));
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <Navbar />
      <div className="grid grid-cols-12 w-full m-auto relative">
        <div className="col-span-2 sticky left-0 top-0 lg:block hidden h-screen">
          <Sidear />
        </div>
        <div className="lg:col-span-10 col-span-12 grid grid-cols-8 gap-4 px-4 py-8 bg-[#F8F8F8] h-max relative">
          {/* //// Header Part  */}
          <div className="lg:col-span-8 col-span-12 space-y-3 grid grid-cols-8">
            <div className="lg:col-span-6 space-y-1 sm:col-span-4 col-span-12 pb-4">
              <h1>{<Header header={header} />}</h1>
              <div className="w-full">
                <Breadcrumb items={paths} />
              </div>
            </div>
            <div className="lg:col-span-2 sm:col-span-4 col-span-12 flex items-start sm:justify-end">
              {location.pathname === "/user-profile" && (
                <Link to={"/settings"}>
                  <div className="border border-[#E4E4E4] rounded-md px-8 py-3 flex items-center space-x-4 w-max cursor-pointer">
                    <img src={SetttngsIcon} alt="Settings.svg" />
                    <span className="text-textcolor font-Montserrat text-lg font-semibold leading-normal">
                      Settings
                    </span>
                  </div>
                </Link>
              )}
            </div>
          </div>
          {/* //// Header Part End  */}
          {/* //// Center Profile Part */}

          <div className="lg:col-span-6 col-span-12 grid grid-cols-4 font-Montserrat h-max">
            {location.pathname !== "/settings" && (
              <>
                <div className="col-span-4">
                  <div className="w-full h-44 rounded-xl relative">
                    <img
                      src={
                        user?.profileId?.coverPicture ||
                        "https://i.pinimg.com/originals/1c/56/74/1c5674df8896d8c173d8aaf59f5aeecb.jpg"
                      }
                      alt="Cover Image"
                      className="w-full h-full object-cover rounded-xl"
                    />
                    <button
                      onClick={() => setimgPopup(true)}
                      className="bg-blue-500 hover:bg-blue-700 opacity-70 text-white font-bold py-2 px-4 rounded absolute right-2 top-2"
                    >
                      Add Cover
                    </button>
                    <div className="h-[120px] w-[120px] rounded-full absolute -bottom-6 left-6">
                      <div className="h-[120px] w-[120px] border-[3px] border-[#28A745]  rounded-full  overflow-hidden">
                        <img
                          className="h-full w-full object-cover rounded-full"
                          src={
                            user?.profilepic
                              ? user?.profilepic
                              : selectedFile !== null
                              ? URL.createObjectURL(selectedFile)
                              : Avtar
                          }
                          alt="Profile.svg"
                        />
                      </div>
                      <label
                        htmlFor="avatar-upload"
                        className="h-[35px] w-[35px] cursor-pointer absolute rounded-full right-0 bottom-0 bg-primary flex items-center justify-center"
                      >
                        {/* Icon for file upload */}
                        <SlPencil className="text-white text-lg" />
                      </label>
                      {/* Hidden file input */}
                      <input
                        id="avatar-upload"
                        type="file"
                        accept="image/*"
                        className="hidden"
                        onChange={handleFileChange}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-span-4 bg-white mx-4 rounded-b-xl ProfileShadow py-4 grid grid-cols-12">
                  <div className="lg:col-span-3 xl:col-span-2 lg:block flex sm:flex-row flex-col col-span-12 lg:border-r px-4 py-6 lg:space-y-4 lg:space-x-0 sm:space-x-4">
                    {data.length > 0 &&
                      data.map((item, index) => {
                        return (
                          <div
                            key={index}
                            className="flex space-x-3 lg:border-none border-r lg:px-0 sm:px-3 sm:py-0 py-2"
                          >
                            <img src={item?.img} alt="img.svg" />
                            <span className="text-textcolor text-xs font-semibold leading-normal">
                              {item?.title}
                            </span>
                          </div>
                        );
                      })}
                  </div>
                  <div className="lg:col-span-9 xl:col-span-10 col-span-12 px-4 grid grid-cols-5 font-Montserrat space-y-4">
                    <div className="sm:col-span-4 col-span-5 space-y-2">
                      <h1 className="text-textcolor text-lg font-semibold leading-normal">
                        {user?.username}
                      </h1>
                      <p className="text-xs text-textcolor font-semibold leading-normal">
                        {user?.profileId?.bio}
                      </p>
                    </div>
                    <div className="sm:col-span-1 col-span-5 flex items-start justify-end">
                      <div className="XpBox cursor-pointer">
                        {user?.experiencePoint?.xp} XP
                      </div>
                    </div>
                    <div className="col-span-5 space-y-3">
                      <div className="w-full h-8 bg-[#E5E5E5] rounded-[50px] relative overflow-hidden flex items-center justify-center">
                        <div className="h-[32px] w-[32px] rounded-full bg-[#DFDFDF] border border-white absolute left-0 top-0 z-10"></div>
                        <div className="w-10 h-full XpColor rounded-[50px] absolute left-0"></div>
                        <p className="z-10 text-textcolor text-xs leading-normal font-semibold">
                          300 Points required
                        </p>
                        <div className="h-[32px] w-[32px] rounded-full XpColor  border border-white absolute right-0 top-0 z-10"></div>
                      </div>
                      <div className="w-full flex justify-between sm:flex-row flex-col sm:space-y-0 space-y-3 items-start">
                        <div className="flex space-x-2">
                          <img src={Badge1} alt="Badge.svg" />
                          <span className="text-textcolor text-xs font-semibold leading-normal opacity-50">
                            Novice Explorer
                          </span>
                        </div>
                        <div className="flex space-x-2">
                          <img src={Badge2} alt="Badge.svg" />
                          <span className="text-textcolor text-xs font-semibold leading-normal opacity-50">
                            Experienced Explorer
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {imgPopup && (
                  <UploadImage show={imgPopup} onClose={setimgPopup} />
                )}
              </>
            )}

            <div className="py-4 col-span-4 mx-4">{children}</div>
          </div>
          {/* //// Center Profile Part End */}

          <div className="col-span-2 lg:block hidden sticky right-0 top-0 h-screen">
            <Upcomming />
          </div>
        </div>
      </div>
      <div
        onClick={() => setUpCommig(true)}
        className="fixed -right-16 bottom-20 font-Montserrat lg:hidden block"
      >
        <p className="text-white text-sm bg-textcolor p-2  transform -rotate-90">
          Upcoming Bookings
        </p>
      </div>
      <div
        onClick={handleBackgroundClick}
        className={`UpcomminSide h-screen w-full bg-[#00000042] duration-150 z-[100] ${
          UpCoimming ? "right-0" : "-right-full"
        } fixed top-0 `}
      >
        <div
          onClick={handleUpcomingClick}
          ref={upcomingRef}
          className="w-[90%] h-full bg-white absolute right-0 p-4"
        >
          <Upcomming setUpCommig={setUpCommig} />
        </div>
      </div>
    </div>
  );
};

export default Mainlayout;
