import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { MdExpandLess } from "react-icons/md";
import { useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  Building,
  ForgotImg,
  Globalimg,
  Login1,
  Logo,
  Maps,
  Personal,
  Plane,
  Verify2,
  VerifyUpper2,
  verifyImg,
} from "../../../assets";
import httpRequest from "../../../axios";
import { Sliders } from "../../../components/common";
import AuthButton from "../../../components/common/Buttons";
import Inputs from "../../../components/common/inputs";
import {
  FORGOT_EMAIL_VERIFY_REQUEST,
  RESEND_CODE,
  RESEND_RESET_CODE,
  VERIFY_EMAIL,
} from "../../../constants/apiEndPoints";

const SliderArr = [
  <div className="relative sm:h-[700px] h-[600px] flex justify-center items-center">
    {/* Background Image with Blur */}
    <div
      className="absolute inset-0 bg-cover bg-center filter blur-[3px]"
      style={{
        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${ForgotImg})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    ></div>

    {/* Content */}
    <div className="relative w-[90%] flex flex-col items-center space-y-6 font-Montserrat">
      <img className="h-auto w-[45%]" src={Maps} alt="Map" />
      <div className="flex flex-col items-center space-y-3">
        <h1 className="text-white sm:text-3xl text-xl font-black">
          TRIPS & TOURS
        </h1>
        <p className="text-white text-center sm:text-xl text-base font-semibold">
          CREATE YOUR TRIPS AND INVITE PEOPLE TO JOIN & COLLABORATE
        </p>
      </div>
    </div>
  </div>,
  <div className="relative sm:h-[700px] h-[600px] flex justify-center items-center">
    {/* Background Image with Blur */}
    <div
      className="absolute inset-0 bg-cover bg-center filter blur-[3px]"
      style={{
        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${Login1})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    ></div>

    {/* Content */}
    <div className="relative w-[90%] flex flex-col items-center space-y-6 font-Montserrat">
      <img className="h-auto w-[45%]" src={Building} alt="Building" />
      <div className="flex flex-col items-center space-y-3">
        <h1 className="text-white sm:text-3xl text-xl font-black">
          ACCOMODATIONS
        </h1>
        <p className="text-white text-center sm:text-xl text-base font-semibold">
          WORRIED ABOUT YOUR STAY? WE GOT THAT COVERED TOO. FIND THE BEST
          ACCOMMODATIONS AROUND YOU.
        </p>
      </div>
    </div>
  </div>,
  <div className="relative sm:h-[700px] h-[600px] flex justify-center items-center">
    {/* Background Image with Blur */}
    <div
      className="absolute inset-0 bg-cover bg-center filter blur-[3px]"
      style={{
        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${verifyImg})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    ></div>

    {/* Content */}
    <div className="relative w-[90%] flex flex-col items-center space-y-6 font-Montserrat">
      <img className="h-auto w-[45%]" src={Globalimg} alt="Global" />
      <div className="flex flex-col items-center space-y-3">
        <h1 className="text-white text-center sm:text-3xl text-xl font-black">
          LIVE INTERACTIVE MAPS
        </h1>
        <p className="text-white text-center sm:text-xl text-base font-semibold">
          EXPLORE MAPS IN REALTIME AND PLAN WHAT YOU WANNA DO
        </p>
      </div>
    </div>
  </div>,

  <div className="relative sm:h-[700px] h-[600px] flex justify-center items-center">
    {/* Background Image with Blur */}
    <div
      className="absolute inset-0 bg-cover bg-center filter blur-[3px]"
      style={{
        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${Verify2})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    ></div>

    {/* Content */}
    <div className="relative w-[90%] flex flex-col items-center space-y-6 font-Montserrat">
      <img className="h-auto w-[45%]" src={VerifyUpper2} alt="Activity" />
      <div className="flex flex-col items-center space-y-3">
        <h1 className="text-white sm:text-3xl text-xl font-black">
          ACTIVITIES & FUN
        </h1>
        <p className="text-white text-center sm:text-xl text-base font-semibold">
          EXPLORE HUNDREDS OF OUR PROFESSIONALS OFFERING AMAZING ACTIVITIES
          AROUND THE GLOBE
        </p>
      </div>
    </div>
  </div>,
  <div className="relative h-[700px] flex justify-center items-center">
    {/* Background Image with Blur */}
    <div
      className="absolute inset-0 bg-cover bg-center filter blur-[3px]"
      style={{
        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${Personal})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    ></div>

    {/* Content */}
    <div className="relative w-[90%] flex flex-col items-center space-y-6 font-Montserrat">
      <img className="h-auto w-[75%]" src={Plane} alt="Plane" />
      <div className="flex flex-col items-center space-y-3">
        <h1 className="text-white text-3xl font-black text-center">
          FLIGHT BOOKINGS
        </h1>
        <p className="text-white text-center text-xl font-semibold">
          GET YOUR FLIGHTS BOOKED WITH EASE WITHIN MYGUIDEON
        </p>
      </div>
    </div>
  </div>,
];

const Verify = () => {
  const location = useLocation();
  const { email } = useSelector((state) => state.user);
  const navigate = useNavigate();
  const [Loading, setLoading] = useState(false);
  const [disableResend, setDisableResend] = useState(false);
  const [countdown, setCountdown] = useState(null);
  const [formData, setFormData] = useState({
    verificationCode: "",
  });

  useEffect(() => {
    let interval;
    if (disableResend && countdown > 0) {
      interval = setInterval(() => {
        setCountdown((prevCountdown) => {
          return prevCountdown > 0 ? prevCountdown - 1 : 0;
        });
      }, 1000);
    } else {
      clearInterval(interval);
      if (countdown === 0) {
        setDisableResend(false);
      }
    }

    return () => clearInterval(interval);
  }, [disableResend, countdown]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handlesubmit = async () => {
    if (formData?.verificationCode === "") {
      toast.error("Verification code is required");
      return;
    }

    setLoading(true);
    try {
      let response;
      if (location.pathname === "/forgot/verify-code") {
        response = await httpRequest.post(FORGOT_EMAIL_VERIFY_REQUEST, {
          Otp: formData?.verificationCode,
        });
      } else {
        response = await httpRequest.post(VERIFY_EMAIL, formData);
      }

      if (response.status === 200 || response.status === 201) {
        toast.success(response?.data?.message);
        if (location.pathname === "/forgot/verify-code") {
          navigate("/forgot/verify-success/password");
        } else {
          navigate("/user/profile/bio");
        }
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  };

  const handleResend = async () => {
    setLoading(true);
    try {
      let response;
      if (location.pathname === "/forgot/verify-code") {
        response = await httpRequest.post(RESEND_RESET_CODE, { email });
      } else {
        response = await httpRequest.post(RESEND_CODE, { email });
      }

      if (response.status === 200 || response.status === 201) {
        toast.success(response?.data?.message);
        setDisableResend(true);
        setCountdown(1800);
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="2xl:p-24 md:p-16 p-6 min-h-screen w-full grid lg:grid-cols-2 grid-cols-1 2xl:gap-32 gap-10 font-Montserrat">
      <div className=" col-span-1 space-y-6">
        <Link to={"/"}>
          <img src={Logo} alt="Logo.svg" />
        </Link>
        <div className="space-y-4">
          <p>
            <Link className="flex items-center" to={"/login"}>
              <MdExpandLess className="-rotate-90 sm:text-xl text-base" /> Back
              to login
            </Link>
          </p>
          <h1 className="font-Montserrat font-bold sm:text-[40px] text-2xl">
            Verify code
          </h1>
          <p className="text-BlackishGreen font-normal sm:text-base text-sm leading-5">
            An authentication code has been sent to your email.
          </p>
        </div>
        <div className="grid sm:grid-cols-2 gap-3">
          <div className=" col-span-2">
            <Inputs
              label={"Enter Code"}
              sizelabel={"14px"}
              sizeinput={"16px"}
              type={"text"}
              color={"#1C1B1F"}
              fontweight={"400"}
              ActiveColor={"#1C1B1F"}
              htmlfor={"code"}
              name={"verificationCode"}
              onchange={handleInputChange}
            />
          </div>
          <p className="text-BlackishGreen sm:text-sm text-xs leading-4 font-medium">
            {disableResend ? (
              <p className="text-BlackishGreen sm:text-sm text-xs leading-4 font-medium">
                Resend in {Math.floor(countdown / 60)}:
                {("0" + (countdown % 60)).slice(-2)}
              </p>
            ) : (
              <p className="text-BlackishGreen sm:text-sm text-xs leading-4 font-medium">
                Didn’t receive a code?
                <Link
                  onClick={handleResend}
                  className={`text-[#FF8682] font-semibold ${
                    Loading || disableResend
                      ? "cursor-not-allowed"
                      : "cursor-pointer"
                  } `}
                  to={"#"}
                >
                  <button disabled={Loading || disableResend}>
                    {Loading ? "Sending..." : "Resend"}
                  </button>
                  &nbsp;
                </Link>
              </p>
            )}
          </p>
          <div className="col-span-2">
            <AuthButton
              onclick={handlesubmit}
              loading={Loading}
              text={"Verify"}
            />
          </div>
        </div>
      </div>
      <div className="col-span-1 rounded-[30px] overflow-hidden ">
        <Sliders items={SliderArr} />
      </div>
    </div>
  );
};

export default Verify;
