import React, { useEffect, useRef, useState } from "react";
import {
  Building,
  Cross,
  DocIcon,
  Globalimg,
  Login1,
  Login2,
  Logo,
  Personal,
  Plane,
  RedCross,
  SignupSlide1,
  Thinking,
  Upload,
  UpperLogin2,
  verifyImg,
} from "../../../assets";
import { Loader, Sliders } from "../../../components/common";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import { toast } from "react-toastify";
import AuthButton from "../../../components/common/Buttons";
import { UploadAssests } from "../../../components/common/UploadAssests";
import { Link, useNavigate } from "react-router-dom";
import httpRequest from "../../../axios";
import { useDispatch, useSelector } from "react-redux";
import { PROFILE_COMPLETE } from "../../../constants/apiEndPoints";
import { setUser, setemail } from "../../../lib/Redux/slices/userslice";
import Picker from "@emoji-mart/react";
import { RiEmotionHappyLine } from "react-icons/ri";
import ISO6391 from "iso-639-1";

const SliderArr = [
  <div className="relative sm:h-[700px] h-[600px] flex justify-center items-center">
    {/* Background Image with Blur */}
    <div
      className="absolute inset-0 bg-cover bg-center filter blur-[3px]"
      style={{
        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${verifyImg})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    ></div>

    {/* Content */}
    <div className="relative w-[90%] flex flex-col items-center space-y-6 font-Montserrat">
      <img className="h-auto w-[45%]" src={Globalimg} alt="Global" />
      <div className="flex flex-col items-center space-y-3">
        <h1 className="text-white sm:text-3xl text-center text-xl font-black">
          LIVE INTERACTIVE MAPS
        </h1>
        <p className="text-white text-center sm:text-xl text-base font-semibold">
          EXPLORE MAPS IN REALTIME AND PLAN WHAT YOU WANNA DO
        </p>
      </div>
    </div>
  </div>,
  <div className="relative sm:h-[700px] h-[600px] flex justify-center items-center">
    {/* Background Image with Blur */}
    <div
      className="absolute inset-0 bg-cover bg-center filter blur-[3px]"
      style={{
        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${Personal})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    ></div>

    {/* Content */}
    <div className="relative w-[90%] flex flex-col items-center space-y-6 font-Montserrat">
      <img className="h-auto w-[75%]" src={Plane} alt="Plane" />
      <div className="flex flex-col items-center space-y-3">
        <h1 className="text-white sm:text-3xl text-xl font-black">
          FLIGHT BOOKINGS
        </h1>
        <p className="text-white text-center sm:text-xl text-base font-semibold">
          GET YOUR FLIGHTS BOOKED WITH EASE WITHIN MYGUIDEON
        </p>
      </div>
    </div>
  </div>,

  <div className="relative sm:h-[700px] h-[600px] flex justify-center items-center">
    {/* Background Image with Blur */}
    <div
      className="absolute inset-0 bg-cover bg-center filter blur-[3px]"
      style={{
        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${SignupSlide1})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    ></div>

    {/* Content */}
    <div className="relative w-[90%] flex flex-col items-center space-y-6 font-Montserrat">
      <img className="h-auto w-[75%]" src={Thinking} alt="Thinking" />
      <div className="flex flex-col items-center space-y-3">
        <h1 className="text-white text-3xl font-black">TRIP PLANNING</h1>
        <p className="text-white text-center text-xl font-semibold">
          PLAN YOUR TRIPS EASILY WITH OUR EASY OF USE PLANNER & DASHBOARDS
        </p>
      </div>
    </div>
  </div>,
  <div className="relative sm:h-[700px] h-[600px] flex justify-center items-center">
    {/* Background Image with Blur */}
    <div
      className="absolute inset-0 bg-cover bg-center filter blur-[3px]"
      style={{
        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${Login1})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    ></div>

    {/* Content */}
    <div className="relative w-[90%] flex flex-col items-center space-y-6 font-Montserrat">
      <img className="h-auto w-[45%]" src={Building} alt="Building" />
      <div className="flex flex-col items-center space-y-3">
        <h1 className="text-white sm:text-3xl text-xl font-black">
          ACCOMODATIONS
        </h1>
        <p className="text-white text-center sm:text-xl text-base font-semibold">
          WORRIED ABOUT YOUR STAY? WE GOT THAT COVERED TOO. FIND THE BEST
          ACCOMMODATIONS AROUND YOU.
        </p>
      </div>
    </div>
  </div>,
  <div className="relative sm:h-[700px] h-[600px] flex justify-center items-center">
    {/* Background Image with Blur */}
    <div
      className="absolute inset-0 bg-cover bg-center filter blur-[3px]"
      style={{
        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${Login2})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    ></div>

    {/* Content */}
    <div className="relative w-[90%] flex flex-col items-center space-y-6 font-Montserrat">
      <img className="h-auto w-[75%]" src={UpperLogin2} alt="UpperLogin2" />
      <div className="flex flex-col items-center space-y-3">
        <h1 className="text-white text-3xl font-black">QUESTS & CHALLENGES</h1>
        <p className="text-white text-center text-xl font-semibold">
          ENJOY YOUR TRIP BY COMPLETING FUN QUESTS AND EARN REWARDS
        </p>
      </div>
    </div>
  </div>,
];

const PersonalInfo = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { email } = useSelector((state) => state.user);
  const [country, setCountry] = useState("");
  const [region, setRegion] = useState("");
  const [language, setlanguage] = useState("");
  const [Bio, setBio] = useState("");
  const [Languages, setLanguages] = useState([]);
  const [documents, setDocuments] = useState([]);
  const [Loading, setLoading] = useState(false);
  const [AllLanguages, setAllLanguages] = useState([]);
  const [filteredLanguages, setFilteredLanguages] = useState([]);

  const handleAddLanguage = () => {
    if (language === "") {
      toast.error("Please enter language");
      return;
    }
    if (Languages.length >= 6) {
      toast.error("only add upto 6 languages");
      return;
    }
    if (language.trim() !== "") {
      setLanguages([...Languages, language]);
      setlanguage("");
    }
  };

  const handleRemoveLanguage = (index) => {
    const updatedLanguages = [...Languages];
    updatedLanguages.splice(index, 1);
    setLanguages(updatedLanguages);
  };

  const handleDocumentUpload = (event) => {
    if (documents.length >= 3) {
      toast.error("only upload upto 3 documents");
      return;
    }
    const file = event.target.files[0];
    if (file) {
      setDocuments([...documents, file]);
    }
  };
  const path = localStorage.getItem("Path");

  const handleRemoveDocument = (index) => {
    const updatedDocuments = [...documents];
    updatedDocuments.splice(index, 1);
    setDocuments(updatedDocuments);
  };

  const handleSubmit = async () => {
    if (country === "") {
      return toast.error("Please Select Country");
    }
    if (region === "") {
      return toast.error("Please Select Region");
    }

    try {
      setLoading(true);
      const uploadedDocuments = await Promise.all(
        documents.map((file) => UploadAssests(file))
      );

      if (uploadedDocuments.every((doc) => doc)) {
        const profileData = {
          email: email,
          bio: Bio,
          countryOfResidence: country,
          cityOfResidence: region,
          spokenLanguages: Languages,
          identificationDocuments: uploadedDocuments,
        };
        const response = await httpRequest.post(PROFILE_COMPLETE, profileData);
        toast.success(response?.data?.message);
        setLoading(false);
        dispatch(setemail(""));
        dispatch(setUser(response?.data?.user));
        localStorage.setItem("token", response?.data?.token);
        if (path == null) {
          navigate("/user-profile");
        } else {
          localStorage.removeItem("Path");
          navigate(`${path}`);
        }
      } else {
        setLoading(false);
        toast.error("Failed to upload one or more documents");
      }
    } catch (error) {
      setLoading(false);
      console.error("Error submitting profile:", error);
      toast.error("Error submitting profile. Please try again later.");
    }
  };

  const handleChange = (e) => {
    const inputText = e.target.value;
    setBio(inputText);
  };

  const handleEmojiSelect = (emoji) => {
    const { selectionStart, selectionEnd } = textAreaRef.current;

    const newBio =
      Bio.slice(0, selectionStart) + emoji.native + Bio.slice(selectionEnd);

    setBio(newBio);

    textAreaRef.current.selectionStart = selectionStart + emoji.native.length;
    textAreaRef.current.selectionEnd = selectionStart + emoji.native.length;

    setShowPicker(false);

    textAreaRef.current.focus();
  };

  const [showPicker, setShowPicker] = useState(false);
  const textAreaRef = useRef(null);

  useEffect(() => {
    let allLang = ISO6391.getAllNames();
    setAllLanguages(allLang);
  }, []);

  useEffect(() => {
    setFilteredLanguages(
      AllLanguages.filter((lang) =>
        lang.toLowerCase().includes(language.toLowerCase())
      )
    );
  }, [language, AllLanguages]);

  const handleLanguageSelect = (selectedLanguage) => {
    setlanguage(selectedLanguage);
    setFilteredLanguages([]);
  };

  return (
    <div className="2xl:p-24 md:p-16 p-6 min-h-screen w-full grid xl:grid-cols-3 lg:grid-cols-2 grid-cols-1 2xl:gap-20 lg:gap-10 font-Montserrat">
      <div className="col-span-1 rounded-[30px] overflow-hidden lg:order-first order-last ">
        <Sliders items={SliderArr} />
      </div>
      <div className="2xl:col-span-2 col-span-1 space-y-6 ">
        <Link to={"/"}>
          <img src={Logo} alt="Logo.svg" />
        </Link>
        <div className="space-y-4">
          <h1 className="font-Montserrat font-bold text-[40px]">Sign up</h1>
          <p className="text-BlackishGreen font-normal text-base leading-5">
            Let’s get you all set up so you can access your personal account.
          </p>
          <div className="grid grid-cols-2 gap-4 sm:pt-0 pt-5">
            <div className="relative w-full lg:col-span-1 col-span-2">
              <label
                className="absolute bg-white text-sm text-BlackishGreen font-normal left-3 -top-2 px-1"
                htmlFor="country"
              >
                Country of Residence <sup className="text-red-600"> *</sup>
              </label>
              <CountryDropdown
                classes="custom-select countselect"
                value={country}
                onChange={(val) => setCountry(val)}
              />
            </div>
            <div className="relative w-full lg:col-span-1 col-span-2">
              <label
                className="absolute bg-white text-sm text-BlackishGreen font-normal left-3 -top-2 px-1"
                htmlFor="country"
              >
                City of Residence <sup className="text-red-600"> *</sup>
              </label>
              <RegionDropdown
                classes="custom-select region"
                country={country}
                value={region}
                onChange={(val) => setRegion(val)}
              />
            </div>
            <div className="col-span-2 relative">
              <div
                className={`relative border-[1px] border-BlackishGreen font-Montserrat rounded-md p-[8px]`}
              >
                <label
                  className="absolute bg-white text-sm text-BlackishGreen font-normal left-3 -top-2 px-1"
                  htmlFor="Bio"
                >
                  Bio
                </label>
                <textarea
                  required
                  ref={textAreaRef}
                  id="Bio"
                  className={`block px-2.5 font-normal resize-none p-1.5 w-full text-[16px] text-BlackishGreen bg-transparent rounded-lg border-1 border-blatext-BlackishGreen appearance-none focus:outline-none focus:ring-0 peer`}
                  placeholder="Write Here"
                  maxLength={200}
                  value={Bio}
                  onChange={handleChange}
                  rows={5}
                />
                {/* Emoji Picker Toggle Icon */}
                <div
                  className="absolute bottom-3 right-3 z-10 cursor-pointer"
                  onClick={() => setShowPicker(!showPicker)}
                >
                  <RiEmotionHappyLine size={24} />
                </div>
                {/* Emoji Picker */}
                {showPicker && (
                  <div className="absolute bottom-12 right-3 z-10">
                    <Picker
                      onEmojiSelect={handleEmojiSelect}
                      set="apple"
                      theme="light"
                    />
                  </div>
                )}
              </div>
              {/* Display character count */}
              <div className="text-[12px] text-BlackishGreen mt-1 text-right">
                <span className={`${Bio.length > 190 && "text-red-500"}`}>
                  {Bio.length}
                </span>{" "}
                / 200 characters
              </div>
            </div>
            <div className="col-span-2">
              <label
                className=" text-[#394149] text-base font-semibold leading-5 block mb-2"
                htmlFor="language"
              >
                Spoken Languages
              </label>
              <div
                className={`relative border-[1px] border-BlackishGreen font-Montserrat rounded-md p-[8px]`}
              >
                <input
                  type="text"
                  required
                  value={language}
                  id={"language"}
                  className={`block px-2.5 font-normal p-1.5 w-full text-[16px] text-BlackishGreen bg-transparent rounded-lg border-1 border-blatext-BlackishGreen appearance-none  focus:outline-none focus:ring-0 peer`}
                  placeholder="Enter Language"
                  onChange={(e) => setlanguage(e.target.value)}
                />
                <button
                  onClick={handleAddLanguage}
                  className="bg-primary px-4 py-2 text-sm font-semibold font-Montserrat text-white rounded-md absolute right-2 top-[8px]"
                >
                  Add
                </button>
                {language.trim() !== "" && filteredLanguages.length > 0 && (
                  <ul className="absolute z-10 w-full bg-white border border-gray-200 rounded-md shadow-md mt-1 max-h-40 overflow-y-auto">
                    {filteredLanguages.map((lang) => (
                      <li
                        key={lang}
                        onClick={() => handleLanguageSelect(lang)}
                        className="p-2 cursor-pointer hover:bg-gray-100"
                      >
                        {lang}
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            </div>
            <div className="col-span-2 grid md:grid-cols-7 sm:grid-cols-4 c grid-cols-2 gap-4">
              {Languages &&
                Languages.map((lang, index) => {
                  return (
                    <div
                      key={index}
                      className="p-3 bg-[#0A80FE1A] rounded-md flex items-center justify-between"
                    >
                      <span className="text-primary font-Montserrat font-semibold text-xs">
                        {lang}
                      </span>
                      <img
                        onClick={() => handleRemoveLanguage(index)}
                        className="cursor-pointer"
                        src={Cross}
                        alt="Cross.svg"
                      />
                    </div>
                  );
                })}
            </div>
            <div className="col-span-2">
              <div className="relative border-[1px] border-BlackishGreen font-Montserrat rounded-md p-[8px] upload-container">
                <input
                  type="file"
                  required
                  id="Document"
                  className="hidden"
                  onChange={handleDocumentUpload}
                  placeholder="Upload Identification Documents"
                />
                <label htmlFor="Document" className="upload-label">
                  Upload Identification Documents
                </label>
                <img
                  src={Upload}
                  alt="upload.svg"
                  className="absolute right-2 top-1 upload-icon"
                />
              </div>
            </div>
            <div className="col-span-2">
              <div className="col-span-2 grid md:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-4">
                {documents.map((document, index) => (
                  <div
                    key={index}
                    className="p-3 bg-[#F2F2F2] rounded-md flex items-center justify-between"
                  >
                    <div className="flex space-x-2">
                      <img
                        src={DocIcon}
                        alt="DocIcon.svg"
                        className="cursor-pointer"
                      />
                      <span className="text-black font-Montserrat font-semibold text-sm">
                        Document {index + 1}
                      </span>
                    </div>
                    <img
                      onClick={() => handleRemoveDocument(index)}
                      className="cursor-pointer"
                      src={RedCross}
                      alt="Cross.svg"
                    />
                  </div>
                ))}
              </div>
            </div>
            <div className="sm:col-span-1 col-span-2">
              <button
                disabled={Loading}
                onClick={handleSubmit}
                className="bg-[#A5A3A3] py-2 px-10 sm:w-auto w-full rounded-[4px]"
              >
                {Loading ? <Loader /> : "Complete Later"}
              </button>
            </div>
            <div className="sm:col-span-1 col-span-2 lg:mb-0 mb-10">
              <AuthButton
                loading={Loading}
                onclick={handleSubmit}
                text={"Complete profile"}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PersonalInfo;
