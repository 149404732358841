import React from "react";
import { Footer, Navbar } from "../components";

const FlightLayout = ({ children }) => {
  return (
    <div className="w-full">
      <Navbar />
      <div className="w-[90%] m-auto">{children}</div>
      <Footer />
    </div>
  );
};

export default FlightLayout;
