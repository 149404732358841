import React, { useEffect, useState } from "react";
import { Select, Option, Radio } from "@material-tailwind/react";
import { IoEyeOffSharp, IoEyeSharp } from "react-icons/io5";
const PrivacyPopup = ({
  isOpen = false,
  loading = false,
  onSubmit = () => {},
  close = () => {},
  EditContent = {},
  setPassword = () => {},
  setnewPassword = () => {},
  setStatus = () => {},
  setNotification = () => {},
  setconfirmPassword = () => {},
}) => {
  const [privacyStatus, setPrivacyStatus] = useState(EditContent?.value);

  const [notificationEnabled, setNotificationEnabled] = useState(
    EditContent?.value
  );

  const handleNotificationChange = (isEnabled) => {
    setNotificationEnabled(isEnabled);
    setNotification(isEnabled);
  };

  useEffect(() => {
    setPrivacyStatus(EditContent?.value);
    setNotificationEnabled(EditContent?.value);
  }, [EditContent]);

  useEffect(() => {
    setStatus(privacyStatus);
  }, [privacyStatus]);

  const [passwordVisibility, setPasswordVisibility] = useState({
    oldPassword: "password",
    newPassword: "password",
    confirmPassword: "password",
  });

  const togglePasswordVisibility = (field) => {
    setPasswordVisibility((prevState) => ({
      ...prevState,
      [field]: prevState[field] === "password" ? "text" : "password",
    }));
  };

  return (
    isOpen && (
      <div className="fixed inset-0 flex items-center justify-center bg-gray-800 z-[100] bg-opacity-70">
        <div className="bg-white p-8 rounded-md">
          {EditContent?.title === "Password" && (
            <div>
              <div className="mb-4 relative">
                <label
                  className="block mb-2 text-sm font-semibold"
                  htmlFor="oldPassword"
                >
                  Old Password:
                </label>
                <input
                  id="oldPassword"
                  name="oldPassword"
                  type={passwordVisibility.oldPassword}
                  onChange={(e) => setPassword(e.target.value)}
                  className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
                />
                <div className="absolute top-10 right-3">
                  {passwordVisibility.oldPassword === "password" ? (
                    <IoEyeOffSharp
                      onClick={() => togglePasswordVisibility("oldPassword")}
                    />
                  ) : (
                    <IoEyeSharp
                      onClick={() => togglePasswordVisibility("oldPassword")}
                    />
                  )}
                </div>
              </div>

              <div className="mb-4 relative">
                <label
                  className="block mb-2 text-sm font-semibold"
                  htmlFor="newPassword"
                >
                  New Password:
                </label>
                <input
                  id="newPassword"
                  name="newPassword"
                  type={passwordVisibility.newPassword}
                  onChange={(e) => setnewPassword(e.target.value)}
                  className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
                />
                <div className="absolute top-10 right-3">
                  {passwordVisibility.newPassword === "password" ? (
                    <IoEyeOffSharp
                      onClick={() => togglePasswordVisibility("newPassword")}
                    />
                  ) : (
                    <IoEyeSharp
                      onClick={() => togglePasswordVisibility("newPassword")}
                    />
                  )}
                </div>
              </div>

              <div className="mb-4 relative">
                <label
                  className="block mb-2 text-sm font-semibold"
                  htmlFor="confirmPassword"
                >
                  Confirm Password:
                </label>
                <input
                  id="confirmPassword"
                  name="confirmPassword"
                  type={passwordVisibility.confirmPassword}
                  onChange={(e) => setconfirmPassword(e.target.value)}
                  className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
                />
                <div className="absolute top-10 right-3">
                  {passwordVisibility.confirmPassword === "password" ? (
                    <IoEyeOffSharp
                      onClick={() =>
                        togglePasswordVisibility("confirmPassword")
                      }
                    />
                  ) : (
                    <IoEyeSharp
                      onClick={() =>
                        togglePasswordVisibility("confirmPassword")
                      }
                    />
                  )}
                </div>
              </div>
            </div>
          )}

          {EditContent?.title === "Profile Privacy" && (
            <div className="w-72">
              <Select
                label="Select Status"
                value={privacyStatus}
                onChange={(value) => setPrivacyStatus(value)}
              >
                <Option value="Public">Public</Option>
                <Option value="Private">Private</Option>
              </Select>
            </div>
          )}

          {EditContent?.title === "Notification" && (
            <div className="flex gap-10 items-center">
              <div>
                <Radio
                  name="notification"
                  id="notificationEnabled"
                  label="Enabled"
                  color="blue"
                  checked={notificationEnabled === true}
                  onChange={() => handleNotificationChange(true)}
                />
              </div>
              <div>
                <Radio
                  name="notification"
                  id="notificationDisabled"
                  label="Disabled"
                  color="blue"
                  checked={notificationEnabled === false}
                  onChange={() => handleNotificationChange(false)}
                />
              </div>
            </div>
          )}

          <div className="mt-4 flex justify-end">
            <button
              onClick={onSubmit}
              className="px-4 py-2 mr-2 text-white bg-blue-500 rounded-md hover:bg-blue-600 focus:outline-none focus:bg-blue-600"
            >
              {loading ? "Loading..." : "Submit"}
            </button>
            <button
              onClick={close}
              className="px-4 py-2 text-gray-600 bg-gray-200 rounded-md hover:bg-gray-300 focus:outline-none focus:bg-gray-300"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    )
  );
};

export default PrivacyPopup;
