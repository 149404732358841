import React from "react";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";

const TermsConditions = () => {
  return (
    <div className="flex flex-col mt-[50px] font-Montserrat">
      <div className="flex justify-between items-center bg-[#F6F6F6] rounded-2xl px-4 py-2">
        <h1 className="text-2xl font-semibold leading-normal ml-4 text-BlackishGreen">
          Terms and Conditions
        </h1>
        <div className="mr-[16px]">
          <MdOutlineKeyboardArrowDown size={24} className="-rotate-180" />
        </div>
      </div>
      <h2 className="text-xl font-medium text-BlackishGreen leading-normal pt-3">
        Payments
      </h2>
      <ul className="mt-16px">
        <li className="text-sm font-normal mt-4 list-disc ml-4 text-BlackishGreen">
          If you are purchasing your ticket using a debit or credit card via the
          Website, we will process these payments via the automated secure
          common payment gateway which will be subject to fraud screening
          purposes.
        </li>
        <li className="text-sm font-normal mt-4 list-disc ml-4 text-BlackishGreen ">
          If you do not supply the correct card billing address and/or
          cardholder information, your booking will not be confirmed and the
          overall cost may increase. We reserve the right to cancel your booking
          if payment is declined for any reason or if you have supplied
          incorrect card information. If we become aware of, or is notified of,
          any fraud or illegal activity associated with the payment for the
          booking, the booking will be cancelled and you will be liable for all
          costs and expenses arising from such cancellation, without prejudice
          to any action that may be taken against us.
        </li>
        <li className="text-sm font-normal mt-4 list-disc ml-4 text-BlackishGreen ">
          Guideon may require the card holder to provide additional payment
          verification upon request by either submitting an online form or
          visiting the nearest Guideon office, or at the airport at the time of
          check-in. Guideon reserves the right to deny boarding or to collect a
          guarantee payment (in cash or from another credit card) if the card
          originally used for the purchase cannot be presented by the cardholder
          at check-in or when collecting the tickets, or in the case the
          original payment has been withheld or disputed by the card issuing
          bank. Credit card details are held in a secured environment and
          transferred through an internationally accepted system.
        </li>
      </ul>
    </div>
  );
};

export default TermsConditions;
