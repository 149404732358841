import React, { useRef, useState } from "react";
import ImageRound from "../../../components/images/ImageRound";

import GalleryImgCard from "./GalleryImgCard";
import GalleryVideoCard from "./GalleryVideoCard";
import { useSelector } from "react-redux";

const Gallery = ({ showModal = () => {} }) => {
  const { CurrentDestination = null } = useSelector((state) => state.web);
  console.log("🚀 ~ Gallery ~ CurrentDestination:", CurrentDestination);

  return (
    <>
      <div className=" font-Montserrat h-[80vh] overflow-y-auto relative ">
        {/* //// */}
        <div className=" grid grid-cols-1 lg:grid-cols-5">
          {CurrentDestination?.images?.slice(0, 5).map((item, index) => (
            <div key={index} className="col-span-1 lg:col-span-1 ">
              <div className=" ">
                <div className=" lg:h-[180px]  w-[100%] lg:w-[95%] mb-[12px]  ">
                  <GalleryImgCard imgsrc={item} />
                </div>
              </div>
            </div>
          ))}
        </div>
        {/* //// */}
        <div className="grid grid-cols-1 lg:grid-cols-5 ">
          <div className="col-span-1 lg:col-span-1 ">
            <div className=" ">
              <div className=" lg:h-[180px]  w-[100%] lg:w-[95%] mb-[12px]  ">
                <GalleryImgCard imgsrc={CurrentDestination?.images[5]} />
              </div>
            </div>
            <div className="  ">
              <div className=" lg:h-[180px]  w-[100%] lg:w-[95%] mb-[12px]  ">
                <GalleryImgCard imgsrc={CurrentDestination?.images[6]} />
              </div>
            </div>
          </div>
          <div className="col-span-1 lg:col-span-3 ">
            <div className=" lg:h-[372px]  w-full lg:w-[98%] rounded-xl overflow-hidden  mb-[12px]  ">
              <GalleryVideoCard videosrc={CurrentDestination?.video} />
            </div>
          </div>
          <div className="col-span-1 lg:col-span-1 ">
            <div className=" ">
              <div className=" lg:h-[180px] w-[100%] lg:w-[95%] mb-[12px]  ">
                <GalleryImgCard imgsrc={CurrentDestination?.images[7]} />
              </div>
            </div>
            <div className="  ">
              <div className=" lg:h-[180px]  w-[100%] lg:w-[95%] mb-[12px]  ">
                <GalleryImgCard imgsrc={CurrentDestination?.images[8]} />
              </div>
            </div>
          </div>

          {CurrentDestination?.images?.slice(9).map((item, index) => (
            <div key={index} className="col-span-1 lg:col-span-1 ">
              <div className=" ">
                <div className=" lg:h-[180px]  w-[100%] lg:w-[95%] mb-[12px]  ">
                  <GalleryImgCard imgsrc={item} />
                </div>
              </div>
            </div>
          ))}
        </div>
        {/* //// */}
        <div className="absolute right-0 bottom-0 flex space-x-2">
          <button
            onClick={() => showModal("Step5")}
            className="py-2 px-4 rounded-2xl bg-[#FFC502] text-[#025F80] font-bold text-[18px] w-max "
          >
            Previous
          </button>
        </div>
      </div>
    </>
  );
};

export default Gallery;
