import { createSlice } from "@reduxjs/toolkit";

const initial = {
    total: [],
    totalPrice: 0,
};

const HotelSlice = createSlice({
    name: "hotel",
    initialState: initial,
    reducers: {
        setHotelCart: (state, action) => {
            // console.log(action.payload,'pay');
                const { type,title, price,qty,id } = action.payload;
                if(type === null){
                    const isTitleExists = state.total.some(item => item.title === title);
                    if (!isTitleExists) {
                        state.total = [...state.total, { title, price , qty,id }];
                    } else {
                        const filteredData = state.total.filter((item) => item.title !== title);
                        state.total = filteredData;
                    }
                    const totaldata = state.total.reduce((total, item) => total + (item.price *item.qty ) , 0);
                    console.log(totaldata,'total data');
                     state.totalPrice =totaldata;
                    console.log();
                }
                else{
                    state.total = action.payload
                    const totaldata = state.total.reduce((total, item) => total + (item.price *item.qty ) , 0);
                    console.log(totaldata,'total data');
                     state.totalPrice =totaldata;
                }
        },
    },
});

export const { setHotelCart } = HotelSlice.actions;

export const selectTotalPrice = state => state.hotel.totalPrice;

export default HotelSlice.reducer;
