import React from "react";
import { SvgLine, PlaceImg } from "../../../assets";
import { useSelector } from "react-redux";

const Journey = ({ showModal = () => {} }) => {
  const { CurrentDestination = null } = useSelector((state) => state.web);

  return (
    <>
      <div className="grid grid-cols-5 font-Montserrat h-[80vh] overflow-auto pt-[10px] gap-10 relative">
        <div className="col-span-5 md:col-span-2 ">
          {/* // */}
          <h1 className="text-[#394149] text-[20px] md:text-[18px] lg:text-[20px]  font-extrabold text-justify md:text-right ">
            {CurrentDestination?.historyHeading1}
          </h1>
          <p className="text-[14px] md:text-[12px] lg:text-[14px] text-[#304149] text-justify md:text-right font-medium  mt-[4px]  ">
            {CurrentDestination?.historyDesp1}
          </p>
          <div className="w-full h-[40px] md:h-[30px] lg:h-[40px]  md:flex mt-[30px] justify-end hidden ">
            <img src={PlaceImg} className="h-[180px] " />
          </div>
          {/* // */}
          <h1 className="text-[#394149] text-[20px]  md:text-[18px] lg:text-[20px] font-extrabold text-justify md:text-right mt-[180px]  ">
            {CurrentDestination?.historyHeading2}
          </h1>
          <p className="text-[14px] md:text-[12px] lg:text-[14px] text-[#304149] text-justify md:text-right font-medium mt-[4px] ">
            {CurrentDestination?.historyDesp2}
          </p>
        </div>
        <div className="col-span-5 md:col-span-3 ">
          <div
            className=" w-full flex flex-col md:flex-row lg:flex-col items-center "
            style={{
              backgroundImage: `url(${SvgLine})`,
              backgroundRepeat: "no-repeat",
              backgroundPosition: "-0px center",
            }}
          >
            <div className="flex flex-col md:flex-col lg:flex-row items-center justify-center md:justify-between w-[100%] md:w-[50%]  lg:w-full gap-10 lg:overflow-x-auto  ">
              {CurrentDestination?.images?.length &&
                CurrentDestination.images.slice(0, 4).map((items, index) => {
                  return (
                    <img
                      key={index}
                      src={items}
                      className="h-[280px] w-[300px] md:h-[120px] md:w-[130px] rounded-lg"
                      alt="JurnyImg.svg"
                    />
                  );
                })}
            </div>

            <div className="flex flex-col pl-[0px] md:pl-[20px] lg:pl-[0px] justify-start items-start mt-[20px] md:[0px] lg:mt-[70px] w-[100%] lg:w-[70%] lg:ml-[30%]   ">
              <h1 className="text-[20px] font-extrabold text-[#394149] text-start ">
                {CurrentDestination?.historyHeading3}
              </h1>
              <p className="text-[14px] md:text-[12px] lg:text-[14px] text-[#304149] text-start font-medium mt-[4px] ">
                {CurrentDestination?.historyDesp3}
              </p>
            </div>
          </div>
        </div>
        <div className="absolute right-0 bottom-0 flex space-x-2">
          <button
            onClick={() => showModal("Step3")}
            className="py-2 px-4 rounded-2xl bg-[#FFC502] text-[#025F80] font-bold text-[18px] w-max "
          >
            Previous
          </button>
          <button
            onClick={() => showModal("Step5")}
            className="py-2 px-4 rounded-2xl bg-[#FFC502] text-[#025F80] font-bold text-[18px] w-max"
          >
            Next
          </button>
        </div>
      </div>
    </>
  );
};

export default Journey;
