import React, { useState, useRef, useEffect, useMemo } from "react";
import { Hotel, Location, Ratting, Cup, AccomodationS } from "../../assets";
import { useNavigate } from "react-router-dom";

const HotelCards = ({ isHotel, data }) => {
  const { hotel, offers } = data;

  const [Catagory, setCatagory] = useState("Best");
  const [price, setPrice] = useState(offers[0].price.total);
  const navigator = useNavigate();

  const handleClick = () => {
    navigator(`/hotel-details/${hotel?.hotelId}`);
  };

  return (
    <>
      <div
        className={`w-full font-Montserrat ml-[${
          isHotel ? "5px" : null
        }] overflow-hidden `}
        style={{ borderRadius: isHotel ? `10px 10px 10px 10px` : null }}
      >
        <div className="">
          <div className="grid md:grid-cols-3 grid-cols-1 rounded font-Montserrat gap-3 shadow-sm bg-white  mt-6 ">
            <div
              className="relative overflow-hidden "
              style={{ borderRadius: isHotel ? `10px 0px 0px 0px` : null }}
            >
              <img
                className="h-full w-full object-cover"
                src={Hotel}
                alt="First Hotel image"
              />
              <span className="text-[12px] absolute top-3 right-7 bg-white bg-opacity-50 px-2 py-1 rounded-[10px]">
                9 images
              </span>
            </div>

            <div className="col-span-2  p-4 ">
              <div className="grid md:grid-cols-3 grid-cols-1 font-Montserrat gap-3 pb-3 w-full border-b">
                <div className="col-span-2 ">
                  <p  onClick={handleClick} className="font-semibold cursor-pointer pb-2 text-[18px] md:text-[20px]">
                    {hotel?.name}
                  </p>
                  <div className="flex gap-2 pb-2">
                    <img src={Location} alt="Loction icon" />
                    <p className="text-[12px] md:text-[14px] max-w-40 truncate ">
                      {hotel && hotel?.address}
                    </p>
                  </div>
                  {/* <div className="flex gap-2 pb-2">
                    <img src={Ratting} alt="Ratting star" />
                    <p className="font-medium text-[12px] md:text-[14px]">
                      5 Star Hotel
                    </p>
                  </div>  */}
                  <div className="flex gap-2 pb-2">
                    <img src={Cup} alt="cup icon" />
                    <p className="font-medium text-[12px] md:text-[14px]">
                      {" "}
                      <span className="font-semibold">20+</span> Aminities{" "}
                    </p>
                  </div>
                  <div className="flex items-center gap-2 text-[12px] md:text-[14px]">
                    <button class=" text-black font-normal py-1 px-2 border border-[#8DD3BB] rounded-[0.25rem]">
                      {hotel?.rating?.rating}
                    </button>
                    <p className="font-medium">
                      <span className="font-semibold ">Very Good </span>{" "}
                      {hotel?.rating?.reviews} reviews
                    </p>
                  </div>
                </div>
                <div className="text-[#FF8682] md:text-right text-[12px] md:text-[14px]">
                  <p className=" text-black">Starting from</p>
                  <p className="text-[24px] font-bold">
                    {offers[0].price.currency} {Math.round(price)}
                    <span className="text-[12px] h-1">/night</span>
                  </p>
                  <p className="text-black font-[12px] ">excl.tax</p>
                </div>
              </div>
              <div className="flex gap-5 pt-3 text-[12px] md:text-[14px]">
                <button class=" text-black font-normal py-1 px-2 border border-[#8DD3BB] rounded-[0.25rem]">
                  🖤
                </button>
                <button
                  onClick={handleClick}
                  className="bg-blue-500 hover:bg-blue-400 transition-colors duration-300 text-white py-2 w-full rounded-md"
                >
                  View Place
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HotelCards;
