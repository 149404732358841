import { createSlice } from "@reduxjs/toolkit";

const cartstate = {
  allflights: [],
  total: [],
  totalPrice: 0,
  FlightBasicPrices: [],
};

const cartSlice = createSlice({
  name: "cart",
  initialState: cartstate,
  reducers: {
    setCart: (state, action) => {
      // console.log(action.payload,'pay');
      const { type, title, price, qty, id } = action.payload;
      if (type === null) {
        const isTitleExists = state.total.some((item) => item.title === title);
        if (!isTitleExists) {
          state.total = [...state.total, { title, price, qty, id }];
        } else {
          const filteredData = state.total.filter(
            (item) => item.title !== title
          );
          state.total = filteredData;
        }
        const totaldata = state.total.reduce(
          (total, item) => total + item.price * item.qty,
          0
        );
        state.totalPrice = totaldata;
      } else {
        state.total = action.payload;
        const totaldata = state.total.reduce(
          (total, item) => total + item.price * item.qty,
          0
        );
        state.totalPrice = totaldata;
      }
    },
    setAllFlights: (state, action) => {
      state.allflights = action.payload;
    },
    setAllFlightsBasicPrices: (state, action) => {
      state.FlightBasicPrices = action.payload;
    },
  },
});

export const { setCart, setAllFlights, setAllFlightsBasicPrices } =
  cartSlice.actions;

export const selectTotalPrice = (state) => state.cart.totalPrice;

export default cartSlice.reducer;
