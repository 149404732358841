import React, { useEffect, useMemo } from "react";
import { AirlineLogo } from "../../assets";
import { CiHeart } from "react-icons/ci";
import { useNavigate } from "react-router-dom";
import httpRequest from "../../axios";
import moment from "moment";
import { setAllFlights } from "../../lib/Redux/slices/cartslice";
import { getAirlineImage, getAirlineName } from "../../lib/airline-iata-codes";
import { useDispatch } from "react-redux";
import {
  setPaymentSuccess,
  setSelectedFlight,
} from "../../lib/Redux/slices/flightsSlice";
  import { toast } from 'react-toastify';
import { AddFav } from "../../constants/apiEndPoints";

const FlightCard = ({ flight }) => {
  const dispatch = useDispatch();

  const TimeConverter = ({ duration }) => {
    // console.log(duration);
    // Parse the duration string using Moment.js
    const parsedDuration = moment.duration(duration);
    // console.log(parsedDuration);
    // Extract hours and minutes
    const hours = parsedDuration.hours();
    const minutes = parsedDuration.minutes();

    // Format the result
    const formattedDuration = `${hours}h ${minutes}m`;
    // console.log(formattedDuration);

    return formattedDuration;
  };
  // Time calculator
  const durationTime = useMemo(() => {
    return TimeConverter({ duration: flight.itineraries[0].duration });
  }, [flight.itineraries[0].duration]);
  // calculate departure and arrival
  function calculateSegmentTimes(flightObject) {
    const lastSegment =
      flightObject.itineraries[0].segments[
        flightObject.itineraries[0].segments.length - 1
      ];
    const startTime = flightObject.itineraries[0].segments[0].departure.at;
    const lastArrivalTime = lastSegment.arrival.at;
    // console.log(startTime, lastArrivalTime);
    return {
      startTime: startTime,
      lastArrivalTime: lastArrivalTime,
    };
  }
  const departureAndArrival = useMemo(
    () => calculateSegmentTimes(flight),
    [flight]
  );
  //airline logo
  const airlineImage = useMemo(() => {
    return getAirlineImage(flight.itineraries[0].segments[0].carrierCode);
  }, [flight.itineraries[0].segments[0].carrierCode]);
  // airline name
  const airlineName = useMemo(
    () => getAirlineName(flight.itineraries[0].segments[0].carrierCode),
    [flight.itineraries[0].segments[0].carrierCode]
  );

  // calculate stops
  function calculateNumberOfStops(flightObject) {
    let calculatedStops = 0;

    // Iterate through each itinerary
    calculatedStops = flightObject.itineraries[0].segments.length - 1;
    // console.log("number of stops", calculatedStops);
    return calculatedStops;
  }
  const numberOfStop = useMemo(() => calculateNumberOfStops(flight), [flight]);

  const navigate = useNavigate();

  const handleStore = (flight) => {
    // console.log(flight,'fligt');
    dispatch(setSelectedFlight(flight));
    dispatch(setPaymentSuccess(false));
    navigate("/flights/booking");
  };

  const AddFavrotes = async (DataFlight) => {
    console.log("🚀 ~ AddFavrotes ~ DataFlight:", DataFlight);

    try {
      const response = await httpRequest.post(AddFav, {
        bookingType: "flight",
        bookingInfo: DataFlight,
      });
      if (response.status === 201 || response.status === 200) {
        toast.success("Flight added to favourite list");
      }
    } catch (err) {
      console.log(err);
      toast.error(err?.response?.data?.message);
    }
  };

  return (
    <div
      style={{ padding: "24px 16px" }}
      className="grid sm:grid-cols-4 grid-cols-1 gap-4 font-Montserrat shadow-sm"
    >
      <div className="AirlineLogo col-span-1 m-auto">
        <img
          className="w-32 h-auto"
          // src={AirlineLogo}
          src={airlineImage}
          alt="AirlineLogo.svg"
        />
      </div>
      <div className="col-span-3 py-2 flex flex-col gap-4">
        <div className="w-full flex justify-end">
          {/* <div className="flex items-center space-x-1">
            <div className="flex items-center justify-center h-[32px] w-[40px] border border-[#8DD3BB] rounded cursor-pointer">
              4.2
            </div>
            <p className="text-xs font-bold text-BlackishGreen">
              Very Good <span className="font-medium">54 reviews</span>
            </p>
          </div> */}
          <div className="flex flex-col items-end">
            <p className="sm:text-xs text-[10px] text-BlackishGreen font-medium opacity-75 leading-normal">
              starting from
            </p>
            <h1 className="sm:text-2xl text-base font-bold text-[#FF8682]">
              $ <span>{Math.round(flight?.price?.total)}</span>{" "}
            </h1>
          </div>
        </div>
        <div className="pb-4 border-b">
          <div className="lg:w-3/4 w-full grid grid-cols-2 gap-5">
            <div className="flex">
              <div class="inline-flex">
                <label
                  class="relative flex items-center p-3 rounded-full cursor-pointer"
                  htmlFor="check"
                >
                  <input
                    type="checkbox"
                    class="before:content[''] peer relative h-5 w-5 cursor-pointer appearance-none rounded-md border border-blue-gray-200 transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity checked:border-gray-900 checked:bg-gray-900 checked:before:bg-gray-900 hover:before:opacity-10"
                    id="check"
                  />
                  <span class="absolute text-white transition-opacity opacity-0 pointer-events-none top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 peer-checked:opacity-100">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-3.5 w-3.5"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      stroke="currentColor"
                      stroke-width="1"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                  </span>
                </label>
              </div>
              <div>
                <h1 className="text-BlackishGreen sm:text-base text-xs font-semibold leading-normal">
                  <span>
                    {moment(departureAndArrival.startTime).format("hh:mm a")}
                  </span>{" "}
                  -{" "}
                  <span>
                    {moment(departureAndArrival.lastArrivalTime).format(
                      "hh:mm a"
                    )}
                  </span>
                </h1>
                <p className="text-BlackishGreen sm:text-sm text-xs font-normal leading-normal opacity-40">
                  {airlineName}
                </p>
              </div>
            </div>
            <div className="grid grid-cols-2 gap-5">
              <h1 className="sm:text-sm text-xs font-semibold leading-normal text-BlackishGreen opacity-80">
                {numberOfStop !== 0 ? `${numberOfStop} Stops` : "Non Stop"}
              </h1>
              <div>
                <h1 className="sm:text-base text-xs font-semibold leading-normal text-BlackishGreen opacity-80">
                  {durationTime}
                </h1>
                <p className="sm:text-sm text-[9px] min-w-max font-normal leading-normal text-BlackishGreen opacity-40">
                  {/* {flight?.itineraries[0]?.segments[0]?.departure?.iataCode}-
                  {flight?.itineraries[0]?.segments[0]?.arrival?.iataCode} */}
                  {flight?.itineraries[0]?.segments?.map((segment, index) => (
                    <>
                      <span>{segment.departure.iataCode}</span>
                      {" - "}
                      {index ==
                        flight?.itineraries[0]?.segments?.length - 1 && (
                        <span>{segment.arrival.iataCode} </span>
                      )}
                    </>
                  ))}
                </p>
              </div>
            </div>
          </div>
          {flight?.itineraries[0]?.segments?.map((segment) => (
            <div className="lg:w-3/4 w-full grid grid-cols-2 gap-5">
              <div className="flex">
                <div class="inline-flex">
                  <label
                    class="relative flex items-center p-3 rounded-full cursor-pointer"
                    htmlFor="check"
                  >
                    <input
                      type="checkbox"
                      class="before:content[''] peer relative h-5 w-5 cursor-pointer appearance-none rounded-md border border-blue-gray-200 transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity checked:border-gray-900 checked:bg-gray-900 checked:before:bg-gray-900 hover:before:opacity-10"
                      id="check"
                    />
                    <span class="absolute text-white transition-opacity opacity-0 pointer-events-none top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 peer-checked:opacity-100">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-3.5 w-3.5"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        stroke="currentColor"
                        stroke-width="1"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                          clip-rule="evenodd"
                        ></path>
                      </svg>
                    </span>
                  </label>
                </div>
                <div>
                  <h1 className="text-BlackishGreen sm:text-base text-xs font-semibold leading-normal">
                    <span>
                      {moment(segment.departure.at).format("hh:mm a")}
                    </span>{" "}
                    -{" "}
                    <span>{moment(segment.arrival.at).format("hh:mm a")}</span>
                  </h1>
                  <p className="text-BlackishGreen sm:text-sm text-xs font-normal leading-normal opacity-40">
                    {airlineName}
                  </p>
                </div>
              </div>
              <div className="grid grid-cols-2 gap-5">
                <h1 className="sm:text-sm text-xs font-semibold leading-normal text-BlackishGreen opacity-80">
                  {"Non Stop"}
                </h1>
                <div>
                  <h1 className="sm:text-base text-xs font-semibold leading-normal text-BlackishGreen opacity-80">
                    {TimeConverter({ duration: segment.duration })}
                  </h1>
                  <p className="sm:text-sm text-[9px] font-normal leading-normal text-BlackishGreen opacity-40">
                    {segment?.departure?.iataCode}-{segment?.arrival?.iataCode}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="flex gap-4">
          <div
            onClick={() => AddFavrotes(flight)}
            className="h-[48px] w-[48px] border border-[#8DD3BB] rounded cursor-pointer flex items-center justify-center"
          >
            <CiHeart size={20} />
          </div>
          <button
            onClick={() => handleStore(flight)}
            className="w-full bg-primary rounded-md hover:bg-blue-800 text-white text-sm font-semibold"
          >
            View Deals
          </button>
        </div>
      </div>
    </div>
  );
};

export default FlightCard;
