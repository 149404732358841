import React from "react";
import {
  CultureImg,
  SocialMedia,
  TravelImg,
  SvgLine,
  Transport,
  AllSocialMediaIocns,
  Uber,
  Lyft,
} from "../../../assets";
import { useSelector } from "react-redux";

const Practical = ({ showModal = () => {} }) => {
  const { CurrentDestination = null } = useSelector((state) => state.web);
  return (
    <>
      <div className="grid grid-cols-5 font-Montserrat h-[80vh] overflow-auto pt-[10px] relative">
        <div className=" col-span-5 md:col-span-2 ">
          {/* // */}
          <h1 className="text-[#394149] text-[20px] md:text-[18px]  lg:text-[20px] font-extrabold text-start  md:text-right ">
            {CurrentDestination?.practicalHeading1}
          </h1>
          <p className="text-[14px] md:text-[12px] lg:text-[14px] text-[#304149] text-justify md:text-right font-medium  mt-[4px]  ">
            {CurrentDestination?.practicalDesp1}
          </p>
          {/* // */}

          <div className="py-4 grid grid-cols-6">
            {CurrentDestination?.tiktokLink != "" && (
              <div className="h-10 w-10 rounded-md overflow-hidden">
                <a href={CurrentDestination?.tiktokLink} target="_blank">
                  <img
                    className="h-full w-full object-cover cursor-pointer"
                    src="https://p16-va-tiktok.ibyteimg.com/obj/musically-maliva-obj/4a2a1776a08f761c6464f596c0c5e8e6.png"
                    alt="Tiktok.png"
                  />
                </a>
              </div>
            )}
            {CurrentDestination?.telegramLink != "" && (
              <div className="h-10 w-10 rounded-md overflow-hidden">
                <a href={CurrentDestination?.telegramLink} target="_blank">
                  <img
                    className="h-full w-full object-cover cursor-pointer"
                    src="https://i.pinimg.com/736x/0a/50/c1/0a50c1516e434e0108649d2987cfaeb0.jpg"
                    alt="telegram.jpg"
                  />
                </a>
              </div>
            )}
            {CurrentDestination?.spotifyLink != "" && (
              <div className="h-10 w-10 rounded-md overflow-hidden">
                <a href={CurrentDestination?.spotifyLink} target="_blank">
                  <img
                    className="h-full w-full object-cover cursor-pointer"
                    src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSmJzmoqp0wgV_lPiQ3M_EWwztcZSq5dNGaRw&s"
                    alt="Spotify.jpg"
                  />
                </a>
              </div>
            )}
            {CurrentDestination?.snapchatLink != "" && (
              <div className="h-10 w-10 rounded-md overflow-hidden">
                <a href={CurrentDestination?.snapchatLink} target="_blank">
                  <img
                    className="h-full w-full object-cover cursor-pointer"
                    src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQRlktBi1mYWFgWcHjpybZXLqspsdHurDO1Hg&s"
                    alt="Snapchat.jpg"
                  />
                </a>
              </div>
            )}
            {CurrentDestination?.facebookMessengerLink != "" && (
              <div className="h-10 w-10 rounded-md overflow-hidden">
                <a
                  href={CurrentDestination?.facebookMessengerLink}
                  target="_blank"
                >
                  <img
                    className="h-full w-full object-cover cursor-pointer"
                    src="https://upload.wikimedia.org/wikipedia/commons/thumb/b/be/Facebook_Messenger_logo_2020.svg/1200px-Facebook_Messenger_logo_2020.svg.png"
                    alt="Messenger.png"
                  />
                </a>
              </div>
            )}
            {CurrentDestination?.instagramLink != "" && (
              <div className="h-10 w-10 rounded-md overflow-hidden">
                <a href={CurrentDestination?.instagramLink} target="_blank">
                  <img
                    className="h-full w-full object-cover cursor-pointer"
                    src="https://upload.wikimedia.org/wikipedia/commons/thumb/9/95/Instagram_logo_2022.svg/640px-Instagram_logo_2022.svg.png"
                    alt="Instgram.png"
                  />
                </a>
              </div>
            )}
          </div>

          <div className="flex mt-[40px] md:hidden ">
            <img src={Uber} className="h-[50px]  " />
            <img src={Lyft} className="h-[50px] ml-[20px] " />
          </div>

          {/* // */}
          <h1 className="text-[#394149] text-[20px] md:text-[18px] lg:text-[20px] font-extrabold mt-[50px] text-start  md:text-right  ">
            {CurrentDestination?.practicalHeading2}
          </h1>
          <p className="text-[14px] md:text-[12px] lg:text-[14px] text-[#304149] text-justify md:text-right font-medium mt-[4px] ">
            {CurrentDestination?.practicalDesp2}
          </p>
        </div>
        <div className=" col-span-5 md:col-span-1 ">
          <div
            className=" w-full flex flex-col items-center "
            style={{
              backgroundImage: `url(${SvgLine})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "45% auto",
              backgroundPosition: "center",
            }}
          >
            <img
              src={TravelImg}
              className="h-[190px] md:h-[120px] lg:h-[190px] "
            />
            <img
              src={SocialMedia}
              className="h-[190px] md:h-[120px] lg:h-[190px] "
            />
            <img
              src={Transport}
              className="h-[190px] md:h-[120px] lg:h-[190px] "
            />
          </div>
        </div>
        <div className=" col-span-5 md:col-span-2 ">
          <div
            className="h-[100%] flex justify-end "
            style={{ height: "max-content" }}
          >
            <img src={CultureImg} className="h-[275px] w-[275px] " />
          </div>
          <h1 className="text-[#394149] text-[20px] md:text-[18px] lg:text-[20px] font-extrabold text-left mt-[10px]  ">
            {CurrentDestination?.practicalHeading3}
          </h1>
          <p className="text-[14px] md:text-[12px] lg:text-[14px] text-[#304149] text-start font-medium mt-[4px] ">
            {CurrentDestination?.practicalDesp3}
          </p>

          <div className="w-full h-40px flex mt-[30px] justify-start md:justify-end md:hidden ">
            <img src={AllSocialMediaIocns} className="h-[70px] " />
          </div>

          <div className="md:flex mt-[40px] hidden ">
            <img src={Uber} className="h-[50px]" />
            <img src={Lyft} className="h-[50px] ml-[20px] " />
          </div>
        </div>
        <div className="absolute right-0 bottom-0 flex space-x-2">
          <button
            onClick={() => showModal("Step4")}
            className="py-2 px-4 rounded-2xl bg-[#FFC502] text-[#025F80] font-bold text-[18px] w-max "
          >
            Previous
          </button>
          <button
            onClick={() => showModal("Step6")}
            className="py-2 px-4 rounded-2xl bg-[#FFC502] text-[#025F80] font-bold text-[18px] w-max"
          >
            Next
          </button>
        </div>
      </div>
    </>
  );
};

export default Practical;
