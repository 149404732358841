import React, { useEffect, useState } from "react";
import MainDataLayout from "../../layouts/MainDatalayout";
import Mainlayout from "../../layouts/Mainlayout";
import { Istanbul, LineCenter, mainCalender, mainFlight } from "../../assets";
import { toast } from "react-toastify";
import { Booking } from "../../constants/apiEndPoints";
import httpRequest from "../../axios";
import moment from "moment";

const MainAccomondations = () => {
  const [Data, setData] = useState([]);
  const [Loading, setLoading] = useState(false);

  const GetData = async () => {
    setLoading(true);
    try {
      const response = await httpRequest.get(`${Booking}/hotel`);
      if (response.status === 200 || response.status === 201) {
        setData(response?.data);
        setLoading(false);
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    GetData();
  }, []);

  return (
    <Mainlayout>
      <div className="grid grid-cols-1 font-Montserrat">
        <div
          style={{ boxShadow: "0px 8px 12px 0px rgba(62, 63, 122, 0.04)" }}
          className="bg-white p-3 rounded-xl"
        >
          <MainDataLayout>
            {Loading
              ? "Loading..."
              : Data?.length > 0
              ? Data?.map((flight) => {
                  return (
                    <div className="w-full">
                      <div className="flex items-center justify-between shadow-sm py-2 sm:space-x-4">
                        <div className="flex flex-col space-y-2">
                          <div className="flex space-x-4">
                            <img src={mainFlight} alt="AroundAir" />
                            <div className="flex space-x-4">
                              <span className="text-[#394149] text-xs font-semibold leading-normal">
                                {flight?.bookingInfo?.hotelName}
                              </span>
                            </div>
                          </div>
                          <div className="flex space-x-4">
                            <img src={mainCalender} alt="AroundAir" />
                            <div className="flex space-x-4 items-center">
                              <span className="text-[#394149] text-xs font-semibold leading-normal">
                                <span className="text-[#394149] text-xs font-semibold leading-normal">
                                  {moment(
                                    flight?.bookingInfo?.checkInDate
                                  ).format(`DD/MM/YYYY`)}
                                </span>
                              </span>{" "}
                              <span>
                                {" "}
                                <img src={LineCenter} alt="Line.svg" />
                              </span>
                              <span className="text-[#394149] text-xs font-semibold leading-normal">
                                {moment(
                                  flight?.bookingInfo?.checkOutDate
                                ).format(`DD/MM/YYYY`)}
                              </span>
                            </div>
                          </div>
                        </div>
                        <img
                          className="h-10"
                          src={Istanbul}
                          alt="Istanbul.svg"
                        />
                      </div>
                    </div>
                  );
                })
              : "No Found"}
          </MainDataLayout>
        </div>
      </div>
    </Mainlayout>
  );
};

export default MainAccomondations;
