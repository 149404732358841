import React from "react";
import { Navbar } from "../components";
import Footer from "../components/Footer";

const HosteldescriptionLayout = ({ children }) => {
  const textOffooter = {
    text: "Subscirbe to our Newsletter",
    button: "Subscirbe",
  };

  return (
    <>
      <div className="w-[90%] m-auto">
        <Navbar />
        {children}
      </div>
      <Footer text={textOffooter.text} buttonText={textOffooter.button} />
    </>
  );
};

export default HosteldescriptionLayout;
