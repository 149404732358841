import React, { useState } from "react";
import { VisaLogo } from "../../assets";
import AuthButton from "../../components/common/Buttons";
import httpRequest from "../../axios";
import { CARDS } from "../../constants/apiEndPoints";
import { toast } from "react-toastify";

const AddCards = ({ setAddCartActive = () => {}, FetchCards = () => {} }) => {
  const [loading, setLoading] = useState(false);
  const [cardDetails, setCardDetails] = useState({
    name: "",
    number: "",
    expiry: "",
  });
  const [errors, setErrors] = useState({
    name: "",
    number: "",
    expiry: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCardDetails((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let formValid = true;
    const errorsCopy = { ...errors };

    const cardNumberRegex = /^[0-9]{15,16}$/;
    if (!cardNumberRegex.test(cardDetails.number)) {
      errorsCopy.number = "Invalid card number";
      formValid = false;
    } else {
      errorsCopy.number = "";
    }

    const expiryRegex = /^(0[1-9]|1[0-2])\/?([0-9]{2})$/;
    if (!expiryRegex.test(cardDetails.expiry)) {
      errorsCopy.expiry = "Invalid expiry date (mm/yy)";
      formValid = false;
    } else {
      const [month, year] = cardDetails.expiry.split("/");
      const currentDate = new Date();
      const expiryMonth = parseInt(month, 10);
      const expiryYear = parseInt(year, 10) + 2000;
      if (
        expiryYear < currentDate.getFullYear() ||
        (expiryYear === currentDate.getFullYear() &&
          expiryMonth < currentDate.getMonth() + 1)
      ) {
        errorsCopy.expiry = "Invalid expiry date";
        formValid = false;
      } else {
        errorsCopy.expiry = "";
      }
    }

    if (cardDetails.name.trim() === "") {
      errorsCopy.name = "Name is required";
      formValid = false;
    } else {
      errorsCopy.name = "";
    }

    setErrors(errorsCopy);

    if (formValid) {
      let payload = {
        cardHolderName: cardDetails.name,
        cardNumber: cardDetails.number,
        expiryDate: cardDetails.expiry,
      };
      setLoading(true);
      try {
        const response = await httpRequest.post(CARDS, payload);
        if (response.status === 200 || response.status === 201) {
          toast.success(response?.data?.message);
          setAddCartActive(false);
          setLoading(false);
          FetchCards();
        }
      } catch (error) {
        toast.error(error?.response?.data?.message);
        setLoading(false);
      }
    }
  };

  const handlePrevious = () => {
    setAddCartActive(false);
  };

  return (
    <div className="py-8 px-4 bg-white rounded-xl">
      <h1 className="text-textcolor text-base font-semibold leading-normal">
        Add a new Credit/Debit Card
      </h1>
      <div className="grid md:grid-cols-2 grid-cols-1 gap-5 py-4">
        <div>
          <div
            style={{ border: "1px solid rgba(57, 65, 73, 0.25)" }}
            className="rounded p-4 text-[#00000052] "
          >
            <input
              type="text"
              className="h-full w-full border-none outline-none"
              placeholder="Name on Card"
              name="name"
              value={cardDetails.name}
              onChange={handleChange}
            />
          </div>
          {errors.name && <p className="text-red-500">{errors.name}</p>}
        </div>
        <div>
          <div
            style={{ border: "1px solid rgba(57, 65, 73, 0.25)" }}
            className="rounded p-4 text-[#00000052] relative"
          >
            <input
              type="text"
              className="h-full w-[80%] border-none outline-none"
              placeholder="Card Number"
              name="number"
              value={cardDetails.number}
              onChange={handleChange}
            />
            <img
              className="h-[23px] absolute right-4 top-4"
              src={VisaLogo}
              alt=""
            />
          </div>
          {errors.number && <p className="text-red-500">{errors.number}</p>}
        </div>
        <div>
          <div
            style={{ border: "1px solid rgba(57, 65, 73, 0.25)" }}
            className="rounded p-4 text-[#00000052] "
          >
            <input
              type="text"
              className="h-full w-full border-none outline-none"
              placeholder="mm/yy (expires)"
              name="expiry"
              value={cardDetails.expiry}
              onChange={handleChange}
            />
          </div>
          {errors.expiry && <p className="text-red-500">{errors.expiry}</p>}
        </div>
      </div>
      <div className="col-span-2 flex">
        <AuthButton
          loading={loading}
          onclick={handlePrevious}
          text={"Back"}
          backColor={"#007BFF"}
          color={"#FFFFFF"}
          hoverback={"#007BFF"}
          type="submit"
        />
        <AuthButton
          loading={loading}
          onclick={handleSubmit}
          text={"Continue"}
          backColor={"#007BFF"}
          color={"#FFFFFF"}
          hoverback={"#007BFF"}
          type="submit"
        />
      </div>
    </div>
  );
};

export default AddCards;
