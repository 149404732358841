import React, { useState, useMemo, useEffect } from "react";
import FlightLayout from "../../../layouts/FlightLayout";
import { BreadcrumbArrow } from "../../../basecomponents";
import { useDispatch, useSelector } from "react-redux";
import { setpaths } from "../../../lib/Redux/slices/webData";
import { IoCheckmarkCircle } from "react-icons/io5";
import { MdEmail } from "react-icons/md";
import Select from "react-select";
import countryList from "react-select-country-list";
import { CiCirclePlus } from "react-icons/ci";

import {
  AirlineLogo,
  AirlineSeat,
  Airplane,
  Foodfast,
  FromToPlane,
  StopWatch,
  VisaIcon,
  Wifi,
  facebookicon,
  googleicon,
} from "../../../assets";
import Vouchers from "../../PaymentVouchers/Vouchers";
import { AddCards, Cart } from "../../../components";
import { setCart } from "../../../lib/Redux/slices/cartslice";
import HosteldescriptionLayout from "../../../layouts/HosteldescriptionLayout";


const headers = [
  { label: "MyGuide", url: "/user-profile" },
  { label: "My World", url: "/inprogress" },
  { label: "Transport Booking", url: "/flights/booking" },
];

const FlightMenues = [
  {
    id: 2,
    title: "",
    icon: Airplane,
  },
  {
    id: 3,
    title: "",
    icon: Wifi,
  },
  {
    id: 4,
    title: "",
    icon: StopWatch,
  },
  {
    id: 5,
    title: "",
    icon: Foodfast,
  },
  {
    id: 6,
    title: "",
    icon: AirlineSeat,
  },
];

const TotalEquipments = [
  {
    id: 1,
    heading: "Additional Hold Baggage ",
    subheading: "Add additional hold baggage at an additional cost",
    price: 68,
    qty: 1,
  },
  {
    id: 2,
    heading: "Excess Baggae",
    subheading: "Add excess baggage at an additional cost",
    price: 40,
    qty: 1,
  },
  {
    id: 3,
    heading: "Special Equipment",
    subheading:
      "Choose this option if there’s any special equipments with your luggage",
    price: 50,
    qty: 1,
  },
  {
    id: 4,
    heading: "Flexible change",
    subheading:
      "Allows you to change the date, time or destination of your flight at no or reduced cost.",
    price: 20,
    qty: 1,
  },
  {
    id: 5,
    heading: "Flexible cancellation",
    subheading:
      "Allows you to cancel your flight and obtain a refund or credit note.",
    price: 30,
    qty: 1,
  },
];

const options = [
  { value: "Male", label: "Male" },
  { value: "Female", label: "Female" },
];

const DocumentType = [
  {
    value: "Passport",
    label: "Passport",
  },
  {
    value: "ID Card",
    label: "ID Card",
  },
  {
    value: "License",
    label: "License",
  },
];

const MainHotelsDetails = () => {
  const dispatch = useDispatch();
  dispatch(setpaths(headers));
  const { paths = [] } = useSelector((state) => state.web);
  const { user } = useSelector((state) => state.user);
  const [Gender, setGender] = useState("");
  const [Country, setCountry] = useState("");
  const [Document, setDocument] = useState("");
  const [issueCountry, setIssueCountry] = useState("");
  const [checkMember, setCheckMember] = useState(false);
  const [AddCard, setAddCard] = useState(false);
  const [inputvalue, setInputvalue] = useState(1);
  const [Id, setId] = useState(null);

  const { total } = useSelector((state) => state.cart);

  const [selectedEquipments, setSelectedEquipments] = useState([]);
  const Countries = useMemo(() => countryList().getData(), []);

  const handleEquipmentClick = (equipment) => {
    setSelectedEquipments((prevSelected) =>
      prevSelected.some((item) => item.id === equipment.id)
        ? prevSelected.filter((item) => item.id !== equipment.id)
        : [...prevSelected, equipment]
    );
  };


  const handleClick = (data) => {
    const obj = { type: null, title: data.heading, price: data.price, qty: 1, id: data.id }
    dispatch(setCart(obj))
  }

  const handleInputChange = (e, id, type) => {
    e.stopPropagation();

    const index = total.findIndex((item) => item.id === id);
    if (index !== -1) {

      const updatedTotal = [...total];

      if (type === 'plus') {
        setInputvalue((prev) => prev + 1)
        updatedTotal[index] = {
          ...updatedTotal[index],
          qty: inputvalue
        };
        dispatch(setCart(updatedTotal))
        console.log(updatedTotal, 'update total');
      } else {
        if (inputvalue <= 0) {
          setInputvalue(1)
          updatedTotal[index] = {
            ...updatedTotal[index],
            qty: 1
          };
          dispatch(setCart(updatedTotal))
        }
        else {
          setInputvalue((prev) => prev - 1)
          updatedTotal[index] = {
            ...updatedTotal[index],
            qty: inputvalue
          };
          dispatch(setCart(updatedTotal))
        }
      }

    }

    console.log(total, 'cart');
  };



  const getQuantity = (id) => {
    const index = total.findIndex((element) => element.id === id);
    return index !== -1 ? total[index].qty : 0;
};


  return (
    <HosteldescriptionLayout>

      <div className="py-3 font-Montserrat">
        <BreadcrumbArrow items={paths} />
      </div>

      <div className="py-10 grid lg:grid-cols-3 grid-cols-1 lg:gap-16 lg:space-y-0 space-y-4 font-Montserrat relative">
        {AddCard && (
          <div className="fixed h-full w-full bg-[#0000008e] left-0 top-0 z-10 flex items-center justify-center">
            <AddCards onclick={setAddCard} />
          </div>
        )}

        <div className="col-span-2 space-y-14">
          {/* // ========== AirBus Details =========== */}

          <div className="w-full space-y-5 shadow-sm">
            <div className="flex justify-between">
              <h1 className="text-BlackishGreen sm:text-2xl text-base font-bold leading-normal">
                Emirates A380 Airbus
              </h1>
              <h1 className="sm:text-3xl text-xl text-end font-bold leading-normal text-[#FF8682]">
                $240
              </h1>
            </div>
            <div className="flex justify-between flex-wrap">
              <h1 className="text-BlackishGreen sm:text-base text-xs font-bold leading-normal">
                Return Wed, Dec 8
              </h1>
              <p className="font-bold sm:text-base text-xs leading-normal underline text-primary cursor-pointer">
                Change Dates
              </p>
              <p className="text-BlackishGreen sm:text-xl text-xs font-medium leading-normal opacity-75">
                2h 28m
              </p>
            </div>
            <div className="flex justify-between items-center flex-wrap">
              <div className="p-5 border lg:w-max w-full flex items-start lg:justify-start justify-center space-x-5 border-[#8DD3BB] rounded">
                <img
                  className="sm:h-14 h-10"
                  src={AirlineLogo}
                  alt="AirlineLogo.svg"
                />
                <div>
                  <h1 className="text-BlackishGreen sm:text-2xl text-base font-semibold leading-normal">
                    Emirates
                  </h1>
                  <p className="text-BlackishGreen text-sm font-medium leading-normal">
                    Airbus A320
                  </p>
                </div>
              </div>
              <div className="flex flex-wrap">
                {FlightMenues &&
                  FlightMenues.map((item, index) => (
                    <div
                      key={item?.id}
                      className={`px-6 py-2 sm:w-max w-1/2 sm:border-b-0 border-b ${index !== FlightMenues.length - 1 ? "sm:border-r" : ""
                        } h-max flex sm:space-x-4 space-x-1`}
                    >
                      <img className="h-5" src={item?.icon} alt="icon" />
                      <p>{item?.title}</p>
                    </div>
                  ))}
              </div>
            </div>
            <div className="flex sm:justify-between items-center  sm:flex-row flex-col sm:gap-16">
              <h1 className="text-BlackishGreen sm:text-2xl text-lg font-semibold leading-normal w-max">
                12:00 pm{" "}
                <span className="text-base font-medium leading-normal opacity-60">
                  Newark(EWR)
                </span>
              </h1>
              <img className="h-10" src={FromToPlane} alt="FromToPlane.svg" />
              <h1 className="text-BlackishGreen sm:text-2xl text-lg font-semibold leading-normal w-max">
                14:30 pm{" "}
                <span className="text-base font-medium leading-normal opacity-60">
                  Newark(EWR)
                </span>
              </h1>
            </div>
          </div>
          {/* // ========== AirBus Details Ending =========== */}

          {/* // ========== Extra Equipments =========== */}

          <div className="space-y-2 ">
            {TotalEquipments &&
              TotalEquipments.map((item, index) => (
                <div
                  key={item?.id}
                  className={`shadow-sm rounded-md cursor-pointer ${total.some((e) => e.id === item.id)
                    ? "bg-primary"
                    : ""
                    } `}
                  onClick={() => handleEquipmentClick(item)}
                >
                  {/* ///// */}
                  <div onClick={() => handleClick(item)} className="flex justify-between items-center p-4 ">
                    <div className="space-y-2">
                      <h1
                        className={`${selectedEquipments.some((e) => e.id === item.id)
                          ? "text-white"
                          : "text-BlackishGreen"
                          } sm:text-base text-sm font-bold leading-normal `}
                      >
                        {item?.heading}
                      </h1>
                      <p
                        className={`${selectedEquipments.some((e) => e.id === item.id)
                          ? "text-white"
                          : "text-BlackishGreen"
                          } sm:text-sm text-xs font-normal leading-normal`}
                      >
                        {item?.subheading}
                      </p>
                    </div>
                    {/* // */}
                    <div className={`flex items-center justify-between space-x-5 w-${index === 0 ? '30%' : '20%'} `}>
                      {
                        index !== 0 ? '' :
                          <div className=" flex d justify-center items-center bg-[white] h-[45px] w-[100px] rounded-lg " >
                            <div className="flex-col items-center justify-center " >
                              <p className="text-[11px] font-normal " >Quantity</p>
                              <p className="text=[14px] font-bold " >
                                <div className="flex w-55px] items-center justify-between " >
                                  <button className=" text-white flex justify-center items-center h-[13px] w-[13px] rounded-full bg-black text-[10px]  "
                                    onClick={(e) => {
                                      handleInputChange(e, item.id, 'minus');
                                    }}
                                  >-</button>
                                  <div >
                                    {/* {
                                    {/* { total.some((elements)=> elements.id === item.id ) ? getQuantity(item.id) : inputvalue } */}
                                    {inputvalue}
                                  </div>
                                  <button className=" text-white flex justify-center items-center h-[13px] w-[13px] rounded-full bg-black text-[10px] "
                                    onClick={(e) => {
                                      handleInputChange(e, item.id, 'plus');
                                    }}
                                  >+</button>
                                </div>
                              </p>
                            </div>
                          </div>
                      }
                      <h1
                        className={`${selectedEquipments.some((e) => e.id === item.id)
                          ? "text-white"
                          : "text-BlackishGreen"
                          } sm:text-sm text-xs font-bold leading-normal`}
                      >
                        +${item?.price}
                      </h1>
                      <IoCheckmarkCircle
                        size={24}
                        className={`${selectedEquipments.some((e) => e.id === item.id)
                          ? "text-[#8DD3BB]"
                          : "text-[#D3D3D3]"
                          }`}
                      />
                    </div>
                  </div>
                  {/* //////// */}
                </div>
              ))}
          </div>
          <div className="w-full space-y-2">
            <div
              className={`shadow-sm rounded-md cursor-pointer
                  bg-primary
              `}
            >
              <div className="flex justify-between items-center p-4">
                <div className="space-y-2">
                  <h1
                    className={`text-white sm:text-base text-sm font-bold leading-normal `}
                  >
                    Pay in full
                  </h1>
                  <p
                    className={`text-white sm:text-sm text-xs font-normal leading-normal`}
                  >
                    Pay the total and you are all set
                  </p>
                </div>
                <div className="flex gap-10">
                  <div className="inline-flex items-center">
                    <label
                      className="relative flex items-center p-3 rounded-full cursor-pointer"
                      htmlFor="payfull"
                    >
                      <input
                        name="payfull"
                        type="radio"
                        checked
                        className="before:content[''] peer relative h-5 w-5 cursor-pointer appearance-none rounded-full border border-white text-white transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-transparent before:opacity-0 before:transition-opacity checked:border-white checked:before:bg-white hover:before:opacity-10"
                        id="payfull"
                      />
                      <span class="absolute text-white transition-opacity opacity-0 pointer-events-none top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 peer-checked:opacity-100">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="h-3.5 w-3.5"
                          viewBox="0 0 16 16"
                          fill="currentColor"
                        >
                          <circle
                            data-name="ellipse"
                            cx="8"
                            cy="8"
                            r="8"
                          ></circle>
                        </svg>
                      </span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div className="w-full">
              <div className="space-y-3 p-4">
                <h1
                  className={`text-BlackishGreen sm:text-base text-sm font-bold leading-normal `}
                >
                  Additional Notes Regarding Payment
                </h1>
                <p
                  className={`text-BlackishGreen sm:text-sm text-xs font-normal leading-normal`}
                >
                  Your payment is subject to verification in case required.
                  Please make sure all the details entered are correct.
                </p>
                <p
                  className={`text-BlackishGreen text-xs font-medium leading-normal underline cursor-pointer`}
                >
                  More info
                </p>
              </div>
            </div>
          </div>
          {user && Object.keys(user).length === 0 ? (
            <div className="w-full space-y-4">
              <h1
                className={`text-BlackishGreen sm:text-xl text-base font-bold leading-normal `}
              >
                Login or Sign up to book
              </h1>
              <input
                type="number"
                className="w-full text-[#1C1B1F] border border-[#79747E] outline-none rounded"
                placeholder="Phone Number"
                style={{ padding: "8px 0px 8px 16px" }}
              />
              <p
                className={`text-BlackishGreen sm:text-sm text-xs font-normal leading-normal`}
              >
                We’ll call or text you to confirm your number. Standard message
                and data rates apply.{" "}
                <span className="font-medium hover:underline cursor-pointer">
                  Privacy Policy
                </span>
              </p>
              <button
                style={{ padding: "8px 16px" }}
                // onClick={() => navigate("/flights/booking")}
                className="w-full h-[48px] bg-primary rounded-md hover:bg-blue-800 text-white text-sm font-semibold"
              >
                Continue
              </button>
              <div className="relative py-4 col-span-2">
                <div className="w-full relative border-b-[2px] flex justify-center items-center">
                  <span className="absolute bg-white px-4">Or</span>
                </div>
              </div>
              <div className="col-span-2 grid sm:grid-cols-2 gap-5">
                <div className=" border border-[#8DD3BB] flex items-center justify-center rounded-lg p-[10px] cursor-pointer hover:bg-[#8DD3BB]">
                  <img src={facebookicon} alt="facebook.svg" />
                </div>
                <div className=" border border-[#8DD3BB] flex items-center justify-center rounded-lg p-[10px] cursor-pointer hover:bg-[#8DD3BB]">
                  <img src={googleicon} alt="facebook.svg" />
                </div>
              </div>
              <button
                style={{ padding: "8px 16px" }}
                // onClick={() => navigate("/flights/booking")}
                className="w-full h-[48px] border border-[#8DD3BB] flex items-center justify-center space-x-3 bg-white rounded-md hover:bg-[#8DD3BB] text-BlackishGreen text-sm font-semibold"
              >
                <MdEmail size={24} />
                <span>Continue with email</span>
              </button>
            </div>
          ) : (
            <>
              <div className="w-full">
                <h1 className="text-black sm:text-[19px] text-base font-semibold leading-normal">
                  Personal Details
                </h1>
                <div className="grid sm:grid-cols-2 grid-cols-1 w-full gap-6 py-6 pl-4">
                  <div
                    style={{ padding: "4px 0px 4px 16px;" }}
                    class="relative w-full h-12 sm:col-span-2 col-span-1"
                  >
                    <input
                      type="text"
                      className="peer w-full h-full bg-transparent text-[#1C1B1F] font-sans font-normal outline outline-0 focus:outline-0 disabled:bg-blue-gray-50 disabled:border-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 border focus:border-2 border-t-transparent focus:border-t-transparent text-sm px-3 py-2.5 rounded-[7px] border-blue-gray-200 focus:border-gray-900"
                      placeholder=" "
                    />
                    <label className="flex w-full h-full select-none pointer-events-none absolute left-0 font-normal !overflow-visible truncate peer-placeholder-shown:text-BlackishGreen leading-tight peer-focus:leading-tight peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-BlackishGreen transition-all -top-1.5 peer-placeholder-shown:text-sm text-[11px] peer-focus:text-[11px] before:content[' '] before:block before:box-border before:w-2.5 before:h-1.5 before:mt-[6.5px] before:mr-1 peer-placeholder-shown:before:border-transparent before:rounded-tl-md before:border-t peer-focus:before:border-t-2 before:border-l peer-focus:before:border-l-2 before:pointer-events-none before:transition-all peer-disabled:before:border-transparent after:content[' '] after:block after:flex-grow after:box-border after:w-2.5 after:h-1.5 after:mt-[6.5px] after:ml-1 peer-placeholder-shown:after:border-transparent after:rounded-tr-md after:border-t peer-focus:after:border-t-2 after:border-r peer-focus:after:border-r-2 after:pointer-events-none after:transition-all peer-disabled:after:border-transparent peer-placeholder-shown:leading-[4.2] blatext-BlackishGreen peer-focus:text-gray-900 before:border-blue-gray-200 peer-focus:before:!border-gray-900 after:border-blue-gray-200 peer-focus:after:!border-gray-900">
                      Full Name (as per Passport/ID)
                    </label>
                  </div>
                  <div className="relative col-span-1">
                    <Select
                      value={Gender}
                      className="PersonalDeatilsFlight"
                      onChange={(selectedOption) => setGender(selectedOption)}
                      options={options}
                    />
                    <label className="absolute -top-2 left-3 text-xs font-normal text-BlackishGreen bg-white px-1">
                      Trip
                    </label>
                  </div>
                  <div
                    style={{ padding: "4px 0px 4px 16px;" }}
                    class="relative w-full h-12 col-span-1"
                  >
                    <input
                      type="date"
                      className="peer w-full h-full bg-transparent text-[#1C1B1F] font-sans font-normal outline outline-0 focus:outline-0 disabled:bg-blue-gray-50 disabled:border-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 border focus:border-2 border-t-transparent focus:border-t-transparent text-sm px-3 py-2.5 rounded-[7px] border-blue-gray-200 focus:border-gray-900"
                      placeholder=" "
                    />
                    <label className="flex w-full h-full select-none pointer-events-none absolute left-0 font-normal !overflow-visible truncate peer-placeholder-shown:text-BlackishGreen leading-tight peer-focus:leading-tight peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-BlackishGreen transition-all -top-1.5 peer-placeholder-shown:text-sm text-[11px] peer-focus:text-[11px] before:content[' '] before:block before:box-border before:w-2.5 before:h-1.5 before:mt-[6.5px] before:mr-1 peer-placeholder-shown:before:border-transparent before:rounded-tl-md before:border-t peer-focus:before:border-t-2 before:border-l peer-focus:before:border-l-2 before:pointer-events-none before:transition-all peer-disabled:before:border-transparent after:content[' '] after:block after:flex-grow after:box-border after:w-2.5 after:h-1.5 after:mt-[6.5px] after:ml-1 peer-placeholder-shown:after:border-transparent after:rounded-tr-md after:border-t peer-focus:after:border-t-2 after:border-r peer-focus:after:border-r-2 after:pointer-events-none after:transition-all peer-disabled:after:border-transparent peer-placeholder-shown:leading-[4.2] blatext-BlackishGreen peer-focus:text-gray-900 before:border-blue-gray-200 peer-focus:before:!border-gray-900 after:border-blue-gray-200 peer-focus:after:!border-gray-900">
                      Date of Birth
                    </label>
                  </div>
                  <div
                    style={{ padding: "4px 0px 4px 16px;" }}
                    class="relative w-full h-12 col-span-1"
                  >
                    <input
                      type="text"
                      className="peer w-full h-full bg-transparent text-[#1C1B1F] font-sans font-normal outline outline-0 focus:outline-0 disabled:bg-blue-gray-50 disabled:border-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 border focus:border-2 border-t-transparent focus:border-t-transparent text-sm px-3 py-2.5 rounded-[7px] border-blue-gray-200 focus:border-gray-900"
                      placeholder=" "
                    />
                    <label className="flex w-full h-full select-none pointer-events-none absolute left-0 font-normal !overflow-visible truncate peer-placeholder-shown:text-BlackishGreen leading-tight peer-focus:leading-tight peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-BlackishGreen transition-all -top-1.5 peer-placeholder-shown:text-sm text-[11px] peer-focus:text-[11px] before:content[' '] before:block before:box-border before:w-2.5 before:h-1.5 before:mt-[6.5px] before:mr-1 peer-placeholder-shown:before:border-transparent before:rounded-tl-md before:border-t peer-focus:before:border-t-2 before:border-l peer-focus:before:border-l-2 before:pointer-events-none before:transition-all peer-disabled:before:border-transparent after:content[' '] after:block after:flex-grow after:box-border after:w-2.5 after:h-1.5 after:mt-[6.5px] after:ml-1 peer-placeholder-shown:after:border-transparent after:rounded-tr-md after:border-t peer-focus:after:border-t-2 after:border-r peer-focus:after:border-r-2 after:pointer-events-none after:transition-all peer-disabled:after:border-transparent peer-placeholder-shown:leading-[4.2] blatext-BlackishGreen peer-focus:text-gray-900 before:border-blue-gray-200 peer-focus:before:!border-gray-900 after:border-blue-gray-200 peer-focus:after:!border-gray-900">
                      Address
                    </label>
                  </div>
                  <div
                    style={{ padding: "4px 0px 4px 16px;" }}
                    class="relative w-full h-12 col-span-1"
                  >
                    <input
                      type="text"
                      className="peer w-full h-full bg-transparent text-[#1C1B1F] font-sans font-normal outline outline-0 focus:outline-0 disabled:bg-blue-gray-50 disabled:border-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 border focus:border-2 border-t-transparent focus:border-t-transparent text-sm px-3 py-2.5 rounded-[7px] border-blue-gray-200 focus:border-gray-900"
                      placeholder=" "
                    />
                    <label className="flex w-full h-full select-none pointer-events-none absolute left-0 font-normal !overflow-visible truncate peer-placeholder-shown:text-BlackishGreen leading-tight peer-focus:leading-tight peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-BlackishGreen transition-all -top-1.5 peer-placeholder-shown:text-sm text-[11px] peer-focus:text-[11px] before:content[' '] before:block before:box-border before:w-2.5 before:h-1.5 before:mt-[6.5px] before:mr-1 peer-placeholder-shown:before:border-transparent before:rounded-tl-md before:border-t peer-focus:before:border-t-2 before:border-l peer-focus:before:border-l-2 before:pointer-events-none before:transition-all peer-disabled:before:border-transparent after:content[' '] after:block after:flex-grow after:box-border after:w-2.5 after:h-1.5 after:mt-[6.5px] after:ml-1 peer-placeholder-shown:after:border-transparent after:rounded-tr-md after:border-t peer-focus:after:border-t-2 after:border-r peer-focus:after:border-r-2 after:pointer-events-none after:transition-all peer-disabled:after:border-transparent peer-placeholder-shown:leading-[4.2] blatext-BlackishGreen peer-focus:text-gray-900 before:border-blue-gray-200 peer-focus:before:!border-gray-900 after:border-blue-gray-200 peer-focus:after:!border-gray-900">
                      Town
                    </label>
                  </div>
                  <div className="relative col-span-1">
                    <Select
                      value={Country}
                      className="PersonalDeatilsFlight"
                      onChange={(selectedOption) => setCountry(selectedOption)}
                      options={Countries}
                    />
                    <label className="absolute -top-2 left-3 text-xs font-normal text-BlackishGreen bg-white px-1">
                      Country
                    </label>
                  </div>
                  <div
                    style={{ padding: "4px 0px 4px 16px;" }}
                    class="relative w-full h-12 col-span-1"
                  >
                    <input
                      type="number"
                      min={0}
                      className="peer w-full h-full bg-transparent text-[#1C1B1F] font-sans font-normal outline outline-0 focus:outline-0 disabled:bg-blue-gray-50 disabled:border-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 border focus:border-2 border-t-transparent focus:border-t-transparent text-sm px-3 py-2.5 rounded-[7px] border-blue-gray-200 focus:border-gray-900"
                      placeholder=" "
                    />
                    <label className="flex w-full h-full select-none pointer-events-none absolute left-0 font-normal !overflow-visible truncate peer-placeholder-shown:text-BlackishGreen leading-tight peer-focus:leading-tight peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-BlackishGreen transition-all -top-1.5 peer-placeholder-shown:text-sm text-[11px] peer-focus:text-[11px] before:content[' '] before:block before:box-border before:w-2.5 before:h-1.5 before:mt-[6.5px] before:mr-1 peer-placeholder-shown:before:border-transparent before:rounded-tl-md before:border-t peer-focus:before:border-t-2 before:border-l peer-focus:before:border-l-2 before:pointer-events-none before:transition-all peer-disabled:before:border-transparent after:content[' '] after:block after:flex-grow after:box-border after:w-2.5 after:h-1.5 after:mt-[6.5px] after:ml-1 peer-placeholder-shown:after:border-transparent after:rounded-tr-md after:border-t peer-focus:after:border-t-2 after:border-r peer-focus:after:border-r-2 after:pointer-events-none after:transition-all peer-disabled:after:border-transparent peer-placeholder-shown:leading-[4.2] blatext-BlackishGreen peer-focus:text-gray-900 before:border-blue-gray-200 peer-focus:before:!border-gray-900 after:border-blue-gray-200 peer-focus:after:!border-gray-900">
                      Post Code
                    </label>
                  </div>
                  <div
                    style={{ padding: "4px 0px 4px 16px;" }}
                    class="relative w-full h-12 col-span-1"
                  >
                    <input
                      type="email"
                      className="peer w-full h-full bg-transparent text-[#1C1B1F] font-sans font-normal outline outline-0 focus:outline-0 disabled:bg-blue-gray-50 disabled:border-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 border focus:border-2 border-t-transparent focus:border-t-transparent text-sm px-3 py-2.5 rounded-[7px] border-blue-gray-200 focus:border-gray-900"
                      placeholder=" "
                    />
                    <label className="flex w-full h-full select-none pointer-events-none absolute left-0 font-normal !overflow-visible truncate peer-placeholder-shown:text-BlackishGreen leading-tight peer-focus:leading-tight peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-BlackishGreen transition-all -top-1.5 peer-placeholder-shown:text-sm text-[11px] peer-focus:text-[11px] before:content[' '] before:block before:box-border before:w-2.5 before:h-1.5 before:mt-[6.5px] before:mr-1 peer-placeholder-shown:before:border-transparent before:rounded-tl-md before:border-t peer-focus:before:border-t-2 before:border-l peer-focus:before:border-l-2 before:pointer-events-none before:transition-all peer-disabled:before:border-transparent after:content[' '] after:block after:flex-grow after:box-border after:w-2.5 after:h-1.5 after:mt-[6.5px] after:ml-1 peer-placeholder-shown:after:border-transparent after:rounded-tr-md after:border-t peer-focus:after:border-t-2 after:border-r peer-focus:after:border-r-2 after:pointer-events-none after:transition-all peer-disabled:after:border-transparent peer-placeholder-shown:leading-[4.2] blatext-BlackishGreen peer-focus:text-gray-900 before:border-blue-gray-200 peer-focus:before:!border-gray-900 after:border-blue-gray-200 peer-focus:after:!border-gray-900">
                      Email
                    </label>
                  </div>
                  <div
                    style={{ padding: "4px 0px 4px 16px;" }}
                    class="relative w-full h-12 col-span-1"
                  >
                    <input
                      type="number"
                      className="peer w-full h-full bg-transparent text-[#1C1B1F] font-sans font-normal outline outline-0 focus:outline-0 disabled:bg-blue-gray-50 disabled:border-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 border focus:border-2 border-t-transparent focus:border-t-transparent text-sm px-3 py-2.5 rounded-[7px] border-blue-gray-200 focus:border-gray-900"
                      placeholder=" "
                    />
                    <label className="flex w-full h-full select-none pointer-events-none absolute left-0 font-normal !overflow-visible truncate peer-placeholder-shown:text-BlackishGreen leading-tight peer-focus:leading-tight peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-BlackishGreen transition-all -top-1.5 peer-placeholder-shown:text-sm text-[11px] peer-focus:text-[11px] before:content[' '] before:block before:box-border before:w-2.5 before:h-1.5 before:mt-[6.5px] before:mr-1 peer-placeholder-shown:before:border-transparent before:rounded-tl-md before:border-t peer-focus:before:border-t-2 before:border-l peer-focus:before:border-l-2 before:pointer-events-none before:transition-all peer-disabled:before:border-transparent after:content[' '] after:block after:flex-grow after:box-border after:w-2.5 after:h-1.5 after:mt-[6.5px] after:ml-1 peer-placeholder-shown:after:border-transparent after:rounded-tr-md after:border-t peer-focus:after:border-t-2 after:border-r peer-focus:after:border-r-2 after:pointer-events-none after:transition-all peer-disabled:after:border-transparent peer-placeholder-shown:leading-[4.2] blatext-BlackishGreen peer-focus:text-gray-900 before:border-blue-gray-200 peer-focus:before:!border-gray-900 after:border-blue-gray-200 peer-focus:after:!border-gray-900">
                      Phone Number
                    </label>
                  </div>
                </div>
              </div>
              <div className="w-full">
                <h1 className="text-black sm:text-[19px] text-base font-semibold leading-normal">
                  Document Verification
                </h1>
                <div className="grid grid-cols-2 w-full gap-6 py-6 pl-4">
                  <div className="relative sm:col-span-1 col-span-2">
                    <Select
                      value={Document}
                      className="PersonalDeatilsFlight"
                      onChange={(selectedOption) => setDocument(selectedOption)}
                      options={DocumentType}
                    />
                    <label className="absolute -top-2 left-3 text-xs font-normal text-BlackishGreen bg-white px-1">
                      Type of Document
                    </label>
                  </div>
                  <div
                    style={{ padding: "4px 0px 4px 16px;" }}
                    class="relative w-full h-12 sm:col-span-1 col-span-2"
                  >
                    <input
                      type="number"
                      className="peer w-full h-full bg-transparent text-[#1C1B1F] font-sans font-normal outline outline-0 focus:outline-0 disabled:bg-blue-gray-50 disabled:border-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 border focus:border-2 border-t-transparent focus:border-t-transparent text-sm px-3 py-2.5 rounded-[7px] border-blue-gray-200 focus:border-gray-900"
                      placeholder=" "
                    />
                    <label className="flex w-full h-full select-none pointer-events-none absolute left-0 font-normal !overflow-visible truncate peer-placeholder-shown:text-BlackishGreen leading-tight peer-focus:leading-tight peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-BlackishGreen transition-all -top-1.5 peer-placeholder-shown:text-sm text-[11px] peer-focus:text-[11px] before:content[' '] before:block before:box-border before:w-2.5 before:h-1.5 before:mt-[6.5px] before:mr-1 peer-placeholder-shown:before:border-transparent before:rounded-tl-md before:border-t peer-focus:before:border-t-2 before:border-l peer-focus:before:border-l-2 before:pointer-events-none before:transition-all peer-disabled:before:border-transparent after:content[' '] after:block after:flex-grow after:box-border after:w-2.5 after:h-1.5 after:mt-[6.5px] after:ml-1 peer-placeholder-shown:after:border-transparent after:rounded-tr-md after:border-t peer-focus:after:border-t-2 after:border-r peer-focus:after:border-r-2 after:pointer-events-none after:transition-all peer-disabled:after:border-transparent peer-placeholder-shown:leading-[4.2] blatext-BlackishGreen peer-focus:text-gray-900 before:border-blue-gray-200 peer-focus:before:!border-gray-900 after:border-blue-gray-200 peer-focus:after:!border-gray-900">
                      Document Number
                    </label>
                  </div>
                  <div className="relative sm:col-span-1 col-span-2">
                    <Select
                      value={issueCountry}
                      className="PersonalDeatilsFlight"
                      onChange={(selectedOption) =>
                        setIssueCountry(selectedOption)
                      }
                      options={Countries}
                    />
                    <label className="absolute -top-2 left-3 text-xs font-normal text-BlackishGreen bg-white px-1">
                      Country of Issue
                    </label>
                  </div>
                  <div
                    style={{ padding: "4px 0px 4px 16px;" }}
                    class="relative w-full h-12 sm:col-span-1 col-span-2"
                  >
                    <input
                      type="date"
                      className="peer w-full h-full bg-transparent text-[#1C1B1F] font-sans font-normal outline outline-0 focus:outline-0 disabled:bg-blue-gray-50 disabled:border-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 border focus:border-2 border-t-transparent focus:border-t-transparent text-sm px-3 py-2.5 rounded-[7px] border-blue-gray-200 focus:border-gray-900"
                      placeholder=" "
                    />
                    <label className="flex w-full h-full select-none pointer-events-none absolute left-0 font-normal !overflow-visible truncate peer-placeholder-shown:text-BlackishGreen leading-tight peer-focus:leading-tight peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-BlackishGreen transition-all -top-1.5 peer-placeholder-shown:text-sm text-[11px] peer-focus:text-[11px] before:content[' '] before:block before:box-border before:w-2.5 before:h-1.5 before:mt-[6.5px] before:mr-1 peer-placeholder-shown:before:border-transparent before:rounded-tl-md before:border-t peer-focus:before:border-t-2 before:border-l peer-focus:before:border-l-2 before:pointer-events-none before:transition-all peer-disabled:before:border-transparent after:content[' '] after:block after:flex-grow after:box-border after:w-2.5 after:h-1.5 after:mt-[6.5px] after:ml-1 peer-placeholder-shown:after:border-transparent after:rounded-tr-md after:border-t peer-focus:after:border-t-2 after:border-r peer-focus:after:border-r-2 after:pointer-events-none after:transition-all peer-disabled:after:border-transparent peer-placeholder-shown:leading-[4.2] blatext-BlackishGreen peer-focus:text-gray-900 before:border-blue-gray-200 peer-focus:before:!border-gray-900 after:border-blue-gray-200 peer-focus:after:!border-gray-900">
                      Expiry Date
                    </label>
                  </div>
                  <p className="col-span-2 flex items-center space-x-2">
                    <input type="checkbox" className="accent-black" />
                    <span className="sm:text-sm text-[10px] font-medium leading-normal">
                      Securely save my information for future bookings
                    </span>
                  </p>
                </div>
              </div>
              <div className="w-full">
                <h1 className="text-black sm:text-[19px] text-base font-semibold leading-normal">
                  Frequent flyer programme
                </h1>
                <div className="grid grid-cols-2 w-full gap-6 py-6 pl-4">
                  <p className="col-span-2 flex items-center space-x-2">
                    <input
                      type="checkbox"
                      onChange={(e) => setCheckMember(e.target.checked)}
                      className="accent-black"
                    />
                    <span className="sm:text-sm text-[10px] font-medium leading-normal">
                      Check this if you’re is a member of the airline's frequent
                      flyer programme.
                    </span>
                  </p>
                  {checkMember && (
                    <div
                      style={{ padding: "4px 0px 4px 16px;" }}
                      class="relative w-full h-12 col-span-2"
                    >
                      <input
                        type="number"
                        className="peer w-full h-full bg-transparent text-[#1C1B1F] font-sans font-normal outline outline-0 focus:outline-0 disabled:bg-blue-gray-50 disabled:border-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 border focus:border-2 border-t-transparent focus:border-t-transparent text-sm px-3 py-2.5 rounded-[7px] border-blue-gray-200 focus:border-gray-900"
                        placeholder=" "
                      />
                      <label className="flex w-full h-full select-none pointer-events-none absolute left-0 font-normal !overflow-visible truncate peer-placeholder-shown:text-BlackishGreen leading-tight peer-focus:leading-tight peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-BlackishGreen transition-all -top-1.5 peer-placeholder-shown:text-sm text-[11px] peer-focus:text-[11px] before:content[' '] before:block before:box-border before:w-2.5 before:h-1.5 before:mt-[6.5px] before:mr-1 peer-placeholder-shown:before:border-transparent before:rounded-tl-md before:border-t peer-focus:before:border-t-2 before:border-l peer-focus:before:border-l-2 before:pointer-events-none before:transition-all peer-disabled:before:border-transparent after:content[' '] after:block after:flex-grow after:box-border after:w-2.5 after:h-1.5 after:mt-[6.5px] after:ml-1 peer-placeholder-shown:after:border-transparent after:rounded-tr-md after:border-t peer-focus:after:border-t-2 after:border-r peer-focus:after:border-r-2 after:pointer-events-none after:transition-all peer-disabled:after:border-transparent peer-placeholder-shown:leading-[4.2] blatext-BlackishGreen peer-focus:text-gray-900 before:border-blue-gray-200 peer-focus:before:!border-gray-900 after:border-blue-gray-200 peer-focus:after:!border-gray-900">
                        Enter Membership Number
                      </label>
                    </div>
                  )}
                </div>
              </div>
              <div>
                <div className="w-full">
                  <div
                    className={`shadow-sm rounded-md cursor-pointer
                  bg-primary
              `}
                  >
                    <div className="flex justify-between items-center p-4">
                      <div className="space-y-2 flex items-center space-x-10">
                        <img src={VisaIcon} alt="VisaIcon.svg" />
                        <p
                          className={`text-white sm:text-base text-sm font-bold leading-normal`}
                        >
                          ***** 4321{" "}
                          <span className="sm:text-sm text-xs font-normal leading-normal">
                            02/27
                          </span>
                        </p>
                      </div>
                      <div className="flex gap-10">
                        <div className="inline-flex items-center">
                          <label
                            className="relative flex items-center p-3 rounded-full cursor-pointer"
                            htmlFor="Card"
                          >
                            <input
                              name="Card"
                              type="radio"
                              checked
                              className="before:content[''] peer relative h-5 w-5 cursor-pointer appearance-none rounded-full border border-white text-white transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-transparent before:opacity-0 before:transition-opacity checked:border-white checked:before:bg-white hover:before:opacity-10"
                              id="Card"
                            />
                            <span class="absolute text-white transition-opacity opacity-0 pointer-events-none top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 peer-checked:opacity-100">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                class="h-3.5 w-3.5"
                                viewBox="0 0 16 16"
                                fill="currentColor"
                              >
                                <circle
                                  data-name="ellipse"
                                  cx="8"
                                  cy="8"
                                  r="8"
                                ></circle>
                              </svg>
                            </span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="flex flex-col ">
                    <div className="flex justify-between p-4 shadow-sm rounded-md cursor-pointer">
                      <div>
                        <h1
                          className={`
                            text-BlackishGreen
                        sm:text-base text-sm font-bold leading-normal `}
                        >
                          Promotions Available
                        </h1>
                        <h1
                          className={`
                            text-BlackishGreen
                        sm:text-sm text-xs font-bold leading-normal `}
                        >
                          20% OFF on Flights
                        </h1>
                        <p
                          className={`
                            text-[#737B73]
                        sm:text-sm text-xs font-bold leading-normal`}
                        >
                          *Terms & conditions apply.
                        </p>
                        <p
                          className={`text-BlackishGreen text-xs font-medium leading-normal underline cursor-pointer`}
                        >
                          More info
                        </p>
                      </div>
                      <div className="flex items-center space-x-5">
                        <div className="inline-flex items-center">
                          <label
                            className="relative flex items-center p-3 rounded-full cursor-pointer"
                            htmlFor="promotions"
                          >
                            <input
                              name="promotions"
                              type="radio"
                              className="before:content[''] peer relative h-5 w-5 cursor-pointer appearance-none rounded-full border border-BlackishGreen textblaborder-BlackishGreen transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-transparent before:opacity-0 before:transition-opacity checked:border-BlackishGreen checked:before:bgblaborder-BlackishGreen hover:before:opacity-10"
                              id="promotions"
                            />
                            <span class="absolute textblaborder-BlackishGreen transition-opacity opacity-0 pointer-events-none top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 peer-checked:opacity-100">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                class="h-3.5 w-3.5"
                                viewBox="0 0 16 16"
                                fill="currentColor"
                              >
                                <circle
                                  data-name="ellipse"
                                  cx="8"
                                  cy="8"
                                  r="8"
                                ></circle>
                              </svg>
                            </span>
                          </label>
                        </div>
                      </div>
                    </div>
                    <Vouchers />
                    <div
                      onClick={() => setAddCard(true)}
                      className="h-48 w-full border-dashed border-2 border-[#8DD3BB] rounded-xl flex items-center justify-center flex-col space-y-2 cursor-pointer"
                    >
                      <CiCirclePlus className="text-[#8DD3BB]" size={64} />
                      <p className="text-BlackishGreen opacity-75 text-xs font-medium leading-normal">
                        Add a new card
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>

        <div className="lg:sticky static right-0 top-5 h-max w-full">
          <Cart />
        </div>
      </div>
    </HosteldescriptionLayout>

  );
};

export default MainHotelsDetails;
