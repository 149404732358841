import React, { useState } from "react";
import {
  Logo,
  SignupSlide1,
  facebookicon,
  googleicon,
  Building,
  Login1,
  UpperLogin2,
  Login2Down,
  Personal,
  Plane,
  verifyImg,
  Globalimg,
} from "../../../assets";
import Inputs from "../../../components/common/inputs";
import AuthButton from "../../../components/common/Buttons";
import { Link, useNavigate } from "react-router-dom";
import { Loader, Sliders } from "../../../components/common";
import httpRequest from "../../../axios";
import { LOGIN_USER, SOCIAL_LOGIN } from "../../../constants/apiEndPoints";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { setUser, setemail } from "../../../lib/Redux/slices/userslice";
import { useGoogleLogin } from "@react-oauth/google";

const SliderArr = [
  <div className="relative sm:h-[700px] h-[600px] flex justify-center items-center">
    {/* Background Image with Blur */}
    <div
      className="absolute inset-0 bg-cover bg-center filter blur-[3px]"
      style={{
        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${Login1})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    ></div>

    {/* Content */}
    <div className="relative w-[90%] flex flex-col items-center space-y-6 font-Montserrat">
      <img className="h-auto w-[45%]" src={Building} alt="Building" />
      <div className="flex flex-col items-center space-y-3">
        <h1 className="text-white sm:text-3xl text-xl font-black">
          ACCOMODATIONS
        </h1>
        <p className="text-white text-center sm:text-xl text-base font-semibold">
          WORRIED ABOUT YOUR STAY? WE GOT THAT COVERED TOO. FIND THE BEST
          ACCOMMODATIONS AROUND YOU.
        </p>
      </div>
    </div>
  </div>,
  <div className="relative h-[700px] flex justify-center items-center">
    {/* Background Image with Blur */}
    <div
      className="absolute inset-0 bg-cover bg-center filter blur-[3px]"
      style={{
        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${Personal})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    ></div>

    {/* Content */}
    <div className="relative w-[90%] flex flex-col items-center space-y-6 font-Montserrat">
      <img className="h-auto w-[75%]" src={Plane} alt="Plane" />
      <div className="flex flex-col items-center space-y-3">
        <h1 className="text-white text-3xl font-black text-center">
          FLIGHT BOOKINGS
        </h1>
        <p className="text-white text-center text-xl font-semibold">
          GET YOUR FLIGHTS BOOKED WITH EASE WITHIN MYGUIDEON
        </p>
      </div>
    </div>
  </div>,
  <div className="relative sm:h-[700px] h-[600px] flex justify-center items-center">
    {/* Background Image with Blur */}
    <div
      className="absolute inset-0 bg-cover bg-center filter blur-[3px]"
      style={{
        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${verifyImg})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    ></div>

    {/* Content */}
    <div className="relative w-[90%] flex flex-col items-center space-y-6 font-Montserrat">
      <img className="h-auto w-[45%]" src={Globalimg} alt="Global" />
      <div className="flex flex-col items-center space-y-3">
        <h1 className="text-white text-center sm:text-3xl text-xl font-black">
          LIVE INTERACTIVE MAPS
        </h1>
        <p className="text-white text-center sm:text-xl text-base font-semibold">
          EXPLORE MAPS IN REALTIME AND PLAN WHAT YOU WANNA DO
        </p>
      </div>
    </div>
  </div>,
];

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [Loading, setLoading] = useState(false);

  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const path = localStorage.getItem("Path");
  const handlesubmit = async () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!formData?.email) {
      toast.error("Email address is required");
      return;
    }

    if (!emailRegex.test(formData.email)) {
      toast.error("Invalid email format");
      return;
    }

    if (!formData?.password) {
      toast.error("Password is required");
      return;
    }

    if (formData.password.length < 8) {
      toast.error("Password must be at least 8 characters long");
      return;
    }

    setLoading(true);
    try {
      const response = await httpRequest.post(LOGIN_USER, formData);

      if (response.status == 200 || response.status == 201) {
        if (path == null) {
          navigate("/");
        } else {
          localStorage.removeItem("Path");
          navigate(`${path}`);
        }
        dispatch(setUser(response?.data?.user));
        localStorage.setItem("token", response?.data?.token);
      }
    } catch (error) {
      if (error?.response?.status === 403) {
        toast.error(error?.response?.data?.message);
        dispatch(setemail(formData?.email));
        navigate("/verify-code");
      } else {
        toast.error(error?.response?.data?.message);
      }
    } finally {
      setLoading(false);
    }
  };

  const GoogleLogin = useGoogleLogin({
    onSuccess: async (resp) => {
      try {
        const response = await fetch(
          "https://www.googleapis.com/oauth2/v3/userinfo",
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${resp?.access_token}`,
            },
          }
        );
        if (response.ok) {
          const data = await response.json();
          setLoading(true);
          try {
            const resp = await httpRequest.post(SOCIAL_LOGIN, {
              email: data?.email,
              fullName: data?.name,
              profilepic: data?.picture,
              name: data?.given_name,
            });

            if (resp?.data?.message === "Login successful") {
              toast.success(resp?.data?.message);
              dispatch(setUser(resp?.data?.user));
              localStorage.setItem("token", resp?.data?.token);

              if (path == null) {
                navigate("/");
              } else {
                localStorage.removeItem("Path");
                navigate(`${path}`);
              }
            }
            if (resp.status === 201) {
              toast.success(resp?.data?.message);
              dispatch(setemail(data?.email));
              navigate("/user/profile/bio");
            }
          } catch (error) {
            console.log(
              "🚀 ~ onSuccess: ~ error:",
              error?.response?.data?.message
            );
            toast.error(error?.response?.data?.message);
          } finally {
            setLoading(false);
          }
        } else {
          console.error(
            "Request failed:",
            response.status,
            response.statusText
          );
        }
      } catch (error) {
        console.error("Error fetching user details:", error);
        throw error;
      }
    },
    onError: (error) => {
      console.log(error);
    },
  });

  return (
    <div className="2xl:p-24 md:p-16 p-6 min-h-screen w-full grid lg:grid-cols-2 grid-cols-1 2xl:gap-32 gap-10 font-Montserrat">
      <div className=" col-span-1 space-y-6">
        <Link to="/">
          <img src={Logo} alt="Logo.svg" />
        </Link>
        <div className="space-y-4">
          <h1 className="font-Montserrat font-bold text-[40px]">Login</h1>
          <p className="text-BlackishGreen font-normal text-base leading-5">
            Login to explore and customize your travels with your MyGuideOn
            account.
          </p>
        </div>
        <div className="grid sm:grid-cols-2 gap-3">
          <div className=" col-span-2">
            <Inputs
              label={"Email"}
              sizelabel={"14px"}
              sizeinput={"16px"}
              type={"eamil"}
              color={"#1C1B1F"}
              fontweight={"400"}
              ActiveColor={"#1C1B1F"}
              htmlfor={"email"}
              name={"email"}
              onchange={handleInputChange}
            />
          </div>
          <div className=" col-span-2">
            <Inputs
              label={"Password"}
              sizelabel={"14px"}
              sizeinput={"16px"}
              type={"password"}
              color={"#1C1B1F"}
              fontweight={"400"}
              ActiveColor={"#1C1B1F"}
              htmlfor={"password"}
              name={"password"}
              onchange={handleInputChange}
            />
          </div>
        </div>
        <div className="col-span-3 flex space-x-1">
          <input className="border accent-BlackishGreen" type="checkbox" />
          <div className="text-BlackishGreen text-sm leading-4 font-medium flex justify-between w-full">
            <span>Remember me</span>
            <Link to={"/forgot"}>
              <span className="text-[#FF8682] font-medium cursor-pointer">
                Forgot Password
              </span>
            </Link>
          </div>
        </div>
        <div className="col-span-3 space-y-4">
          <AuthButton
            onclick={handlesubmit}
            loading={Loading}
            text={"Login"}
            backColor={"#007BFF"}
            color={"#FFFFFF"}
            hoverback={"#007BFF"}
          />
          <p className="text-BlackishGreen text-sm leading-4 font-medium text-center">
            Don’t have an account?
            <Link
              className="text-[#FF8682] font-semibold cursor-pointer"
              to={"/signup"}
            >
              &nbsp; Sign up
            </Link>
          </p>
        </div>
        <div className="relative py-4 col-span-2">
          <div className="w-full relative border-b-[2px] flex justify-center items-center">
            <span className="absolute bg-white px-4">Or login with</span>
          </div>
        </div>
        <div className="col-span-2 grid sm:grid-cols-2 gap-5">
          <div className=" border border-[#8DD3BB] flex items-center justify-center rounded-lg p-[10px] cursor-pointer hover:bg-[#8DD3BB]">
            {Loading ? (
              <Loader />
            ) : (
              <img src={facebookicon} alt="facebook.svg" />
            )}
          </div>
          <div
            onClick={GoogleLogin}
            className=" border border-[#8DD3BB] flex items-center justify-center rounded-lg p-[10px] cursor-pointer hover:bg-[#8DD3BB]"
          >
            {Loading ? <Loader /> : <img src={googleicon} alt="facebook.svg" />}
          </div>
        </div>
      </div>
      <div className="col-span-1 rounded-[30px] overflow-hidden ">
        <Sliders items={SliderArr} />
      </div>
    </div>
  );
};

export default Login;
