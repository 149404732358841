import React from "react";
import { useDispatch } from "react-redux";
import { clearUser } from "../../lib/Redux/slices/userslice";
import { useNavigate } from "react-router-dom";
import Navbar from "../Navbar";

const InProgressComponent = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogout = () => {
    dispatch(clearUser());
    localStorage.clear();
    navigate("/");
  };

  return (
    <>
      <Navbar />
      <div className="flex flex-col items-center justify-center min-h-screen bg-gradient-to-br from-primary to-secondary">
        <div className="bg-white rounded-lg shadow-md p-8 max-w-md w-full lg:max-w-lg text-center">
          <h1 className="text-4xl lg:text-5xl font-bold text-textcolor mb-6">
            Welcome to Myguideon
          </h1>
          <p className="text-tertiary mb-4">
            As a developer, you may occasionally encounter tasks that are
            currently in progress. This page provides a temporary placeholder to
            indicate ongoing development work. Rest assured, your project is in
            good hands, and the task will be completed shortly.
          </p>
          <p className="text-tertiary mb-8">
            In the meantime, feel free to explore other features of our
            platform.
          </p>
          <button
            onClick={handleLogout}
            className="bg-BlackishGreen hover:bg-opacity-80 text-white font-semibold py-3 px-6 rounded-full transition duration-300 ease-in-out focus:outline-none focus:ring-2 focus:ring-opacity-50 focus:ring-textcolor"
          >
            Logout
          </button>
        </div>
      </div>
    </>
  );
};

export default InProgressComponent;
