import React from "react";
import {
  Avtar,
  Calender2,
  FlightTicket,
  TicketFlightMap,
  barCode,
  timmer,
} from "../../assets";
import moment from "moment";

const FlightTickets = ({ bookingDetail, downRef }) => {
  return (
    <div className="grid grid-cols-6 py-6">
      <div className="bg-[#CCE5FF] p-6 space-y-2 rounded-tl-2xl lg:rounded-bl-2xl lg:rounded-tr-none rounded-tr-2xl lg:col-span-1 col-span-6 lg:block flex sm:flex-row flex-col items-center justify-center space-x-4">
        <h1 className="text-BlackishGreen font-semibold text-3xl leading-normal">
          {moment(
            bookingDetail?.bookingInfo?.flightOffers[0]?.departure?.at
          ).format("hh:mm A")}
        </h1>
        <p className="text-BlackishGreen font-medium text-xs leading-normal opacity-60">
          {bookingDetail?.bookingInfo?.flightOffers[0]?.departure?.iataCode}
        </p>
        <img src={FlightTicket} alt="FlightTicket.svg" />

        <h1 className="text-BlackishGreen font-semibold text-3xl leading-normal">
          {moment(
            bookingDetail?.bookingInfo?.flightOffers[0]?.arrival?.at
          ).format("hh:mm A")}
        </h1>
        <p className="text-BlackishGreen font-medium text-xs leading-normal opacity-60">
          {bookingDetail?.bookingInfo?.flightOffers[0]?.arrival?.iataCode}
        </p>
      </div>
      <div
        ref={downRef}
        className="lg:col-span-3 col-span-6 border-b lg:rounded-tl-none lg:rounded-2xl overflow-hidden"
      >
        <div className="bg-primary grid p-6 sm:grid-cols-2 grid-cols-1">
          <div className="flex space-x-3 items-center">
            <div className="h-[48px] w-[48px] rounded-full border border-white">
              <img className="h-full w-full" src={Avtar} alt="Avtar.svg" />
            </div>
            <div>
              <h1 className="text-white font-bold text-xl leading-normal">
                {bookingDetail?.customer?.fullName}
              </h1>
              <p className="text-sm font-normal leading-normal text-white">
                Boarding Pass N’123
              </p>
            </div>
          </div>
          <div className="flex items-center sm:justify-end  justify-center">
            <h1 className="text-white font-bold text-sm leading-normal">
              {/* Busniess Class */}
            </h1>
          </div>
        </div>
        <div className="grid p-6 sm:grid-cols-4 grid-cols-2 gap-4">
          <div className="flex space-x-3 items-center">
            <div className="bg-[#8dd3ba6c] h-8 w-8 p-1 rounded">
              <img
                className="h-full w-full"
                src={Calender2}
                alt="Calender.svg"
              />
            </div>
            <div>
              <h3 className="text-BlackishGreen text-sm font-semibold leading-normal">
                Date
              </h3>
              <p className="text-BlackishGreen text-xs font-medium leading-normal">
                {
                  bookingDetail?.bookingInfo?.flightOffers[0]?.departure
                    ?.iataCode
                }
              </p>
            </div>
          </div>
          <div className="flex space-x-3 items-center">
            <div className="bg-[#8dd3ba6c] h-8 w-8 p-1 rounded">
              <img className="h-full w-full" src={timmer} alt="Calender.svg" />
            </div>
            <div>
              <h3 className="text-BlackishGreen text-sm font-semibold leading-normal">
                Flight time
              </h3>
              <p className="text-BlackishGreen text-xs font-medium leading-normal">
                {moment(
                  bookingDetail?.bookingInfo?.flightOffers[0]?.departure?.at
                ).format("hh:mm A")}
              </p>
            </div>
          </div>
          <div className="flex space-x-3 items-center">
            <div className="bg-[#8dd3ba6c] h-8 w-8 p-1 rounded">
              <img className="h-full w-full" src={timmer} alt="Calender.svg" />
            </div>
            <div>
              <h3 className="text-BlackishGreen text-sm font-semibold leading-normal">
                Closing Time
              </h3>
              <p className="text-BlackishGreen text-xs font-medium leading-normal">
                N/A
              </p>
            </div>
          </div>
          <div className="flex space-x-3 items-center">
            <div className="bg-[#8dd3ba6c] h-8 w-8 p-1 rounded">
              <img className="h-full w-full" src={timmer} alt="Calender.svg" />
            </div>
            <div>
              <h3 className="text-BlackishGreen text-sm font-semibold leading-normal">
                Gate
              </h3>
              <p className="text-BlackishGreen text-xs font-medium leading-normal">
                N/A
              </p>
            </div>
          </div>
        </div>
        <div className="grid sm:grid-cols-7 grid-cols-4 pl-6 pt-6 pb-2 pr-2">
          <div className="flex flex-col justify-end">
            <h1 className="text-BlackishGreen text-3xl font-semibold leading-normal">
              {bookingDetail?.bookingInfo?.queuingOfficeId?.slice(0, 2)}
            </h1>
            <p className="text-BlackishGreen text-xs font-medium leading-normal">
              {bookingDetail?.bookingInfo?.queuingOfficeId?.slice(2)}
            </p>
          </div>
          <div className="sm:col-span-6 col-span-3 flex flex-col items-end">
            <img src={barCode} alt="barCode.svg" />
            <p className="text-BlackishGreen text-xs font-medium leading-normal">
              NOTE: This is not a boarding pass. You must check in at
              appropriate time.
            </p>
          </div>
        </div>
      </div>
      <div className="lg:col-span-2 col-span-6">
        <img
          className="h-full w-full object-cover"
          src={TicketFlightMap}
          alt=""
        />
      </div>
    </div>
  );
};

export default FlightTickets;
