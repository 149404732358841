import React from "react";
import Mainlayout from "./Mainlayout";
import { Link, useLocation } from "react-router-dom";

const Tabs = [
  {
    title: "Personal Information",
    link: "/personal-info",
  },
  {
    title: "Security & Privacy",
    link: "/security-privacy",
  },
  {
    title: "Payment & Vouchers",
    link: "/payment-vouchers",
  },
];

const SettingsLayout = ({ children }) => {
  const location = useLocation();

  return (
    <Mainlayout>
      <div className="space-y-3">
        <div className="w-full bg-white overflow-hidden grid sm:grid-cols-3 grid-cols-1 gap-4 rounded-xl font-Montserrat ShadowMain">
          {Tabs &&
            Tabs.map((tab, index) => {
              return (
                <Link
                  key={index}
                  to={tab?.link}
                  className={`border-b-[3px] ${
                    location.pathname === tab?.link
                      ? "border-primary"
                      : "border-transparent"
                  } shadow`}
                >
                  <div className="text-center p-5 ">
                    <p
                      className={`text-textcolor ${
                        location.pathname === tab?.link ? "" : "opacity-50"
                      } text-sm font-semibold leading-normal`}
                    >
                      {tab?.title}
                    </p>
                  </div>
                </Link>
              );
            })}
        </div>
        <div className="w-full sm:p-5">{children}</div>
      </div>
    </Mainlayout>
  );
};

export default SettingsLayout;
