import React, { useState } from "react";
import { IoMdEye, IoIosEyeOff } from "react-icons/io";
import { useLocation } from "react-router-dom";

const Inputs = ({
  label,
  fontweight,
  sizelabel,
  sizeinput,
  color,
  ActiveColor,
  htmlfor,
  type,
  name,
  mendetory = false,
  onchange = () => {},
}) => {
  const location = useLocation();
  const [show, setShow] = useState(false);
  const [passwordStrength, setPasswordStrength] = useState(null);

  const checkPasswordStrength = (password) => {
    // Password strength logic
    const hasUpperCase = /[A-Z]/.test(password);
    const hasLowerCase = /[a-z]/.test(password);
    const hasNumber = /\d/.test(password);
    const hasSpecialChar = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+/.test(
      password
    );

    const isLengthValid = password.length >= 6;
    const strength =
      (hasUpperCase + hasLowerCase + hasNumber + hasSpecialChar) * 25;

    if (isLengthValid && strength >= 75) {
      return "Strong";
    } else if (isLengthValid && strength >= 50) {
      return "Medium";
    } else if (isLengthValid) {
      return "Weak";
    } else {
      return "Too short";
    }
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    if (type === "password") {
      setPasswordStrength(
        value.length > 0 ? checkPasswordStrength(value) : null
      );
    }
    onchange(e);
  };

  return (
    <>
      <div
        className={`relative border-[2px] border-[${color}] font-Montserrat rounded-lg p-[6px_0px_6px_10px]`}
      >
        <input
          type={show && type === "password" ? "text" : type}
          required
          id={htmlfor}
          name={name}
          onChange={handleInputChange}
          className={`block px-2.5 font-[${fontweight}] p-1.5 w-full text-[${sizeinput}] text-[${color}] bg-transparent rounded-lg border-1 border-[${color}] appearance-none  focus:outline-none focus:ring-0 peer`}
          placeholder=" "
        />
        <label
          htmlFor={htmlfor}
          className={`absolute font-[${fontweight}] text-[${sizelabel}] text-[${color}] duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white px-2 peer-focus:px-6 peer-focus:text-[${ActiveColor}]  peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 start-1 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto`}
        >
          {label}
          {mendetory && <sup className="text-red-600"> *</sup>}
        </label>
        {type === "password" && (
          <div className="absolute right-3 top-3 cursor-pointer">
            {show ? (
              <IoMdEye size={24} onClick={() => setShow(false)} />
            ) : (
              <IoIosEyeOff size={24} onClick={() => setShow(true)} />
            )}
          </div>
        )}
      </div>
      {type === "password" &&
        passwordStrength &&
        location.pathname !== "/login" && (
          <div className="text-xs text-gray-500 mt-1">
            <div
              className={`h-1 rounded-md ${
                passwordStrength === "Strong"
                  ? "bg-green-500"
                  : passwordStrength === "Medium"
                  ? "bg-yellow-400"
                  : passwordStrength === "Weak"
                  ? "bg-red-500"
                  : "hidden"
              }`}
              style={{ width: "100%" }}
            ></div>{" "}
            {passwordStrength}
          </div>
        )}
      <style jsx global>{`
        /* Target autofill state */
        input:-webkit-autofill {
          -webkit-box-shadow: 0 0 0px 1000px white inset !important;
        }

        /* Firefox */
        input:-moz-placeholder {
          color: ${color} !important;
          background-color: white !important;
        }

        /* Edge */
        input:-ms-input-placeholder {
          color: ${color} !important;
          background-color: white !important;
        }
      `}</style>
    </>
  );
};

export default Inputs;
