import React from "react";
import Loader from "../loader";

const AuthButton = ({ text = "", onclick = () => {}, loading = false }) => {
  return (
    <button
      disabled={loading}
      onClick={onclick}
      type="button"
      className={`text-white w-full bg-primary hover:bg-[#0059ff] font-semibold duration-300 rounded-lg text-sm px-5 py-2.5 me-2 mb-2  focus:outline-none`}
    >
      {loading ? <Loader /> : text}
    </button>
  );
};

export default AuthButton;
