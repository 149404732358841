import React, { useEffect, useState, useRef } from "react";
import { Loader } from "../../components/common";
import { toast } from "react-toastify";
import { MuiTelInput } from "mui-tel-input";
import Picker from "@emoji-mart/react";
import data from "@emoji-mart/data";
import { RiEmotionHappyLine } from "react-icons/ri";

const InfoPopup = ({
  isOpen,
  onClose,
  label,
  initialValue,
  onChange,
  onSubmit,
  loading = false,
  setnewValue = () => {},
}) => {
  const [editedValue, setEditedValue] = useState(initialValue);
  const [Type, setType] = useState("text");
  const [newLanguage, setNewLanguage] = useState("");
  const [genderOptions] = useState(["Male", "Female", "Other"]);
  const [showPicker, setShowPicker] = useState(false);
  const textAreaRef = useRef(null);

  useEffect(() => {
    setEditedValue(initialValue);
  }, [isOpen, initialValue]);

  useEffect(() => {
    if (label === "dob") {
      setType("date");
    } else if (label === "Phone") {
      setType("phone");
    } else if (label === "Email") {
      setType("email");
    } else {
      setType("text");
    }
  }, [label]);

  const handleChange = (e) => {
    setEditedValue(e.target.value);
    setnewValue(e.target.value);
  };

  const handleLanguageChange = (e) => {
    const selectedLanguages = Array.from(
      e.target.selectedOptions,
      (option) => option.value
    );
    setEditedValue(selectedLanguages);
    setnewValue(selectedLanguages);
  };

  const deleteLanguage = (index) => {
    const updatedLanguages = [...editedValue];
    updatedLanguages.splice(index, 1);
    setEditedValue(updatedLanguages);
  };

  const addLanguage = () => {
    if (newLanguage.trim() !== "" && editedValue.length < 5) {
      setEditedValue([...editedValue, newLanguage]);
      setNewLanguage("");
    }
  };

  const handleSubmit = () => {
    if (label === "Email" && !isValidEmail(editedValue)) {
      return toast.error("Please Enter Valid Email");
    }
    onChange(editedValue);
    onSubmit();
  };

  useEffect(() => {
    setnewValue(editedValue);
  }, [editedValue]);

  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleDateChange = (e) => {
    const selectedDate = new Date(e.target.value);
    const currentDate = new Date();
    if (selectedDate > currentDate) {
      e.target.valueAsDate = currentDate;
      setEditedValue(currentDate.toISOString().split("T")[0]);
      toast.error("Future dates are not allowed");
    } else {
      setEditedValue(e.target.value);
      setnewValue(e.target.value);
    }
  };

  const handleEmojiSelect = (emoji) => {
    const { selectionStart, selectionEnd } = textAreaRef.current;
    const newText =
      editedValue.slice(0, selectionStart) +
      emoji.native +
      editedValue.slice(selectionEnd);
    setEditedValue(newText);
    setnewValue(newText);
    setShowPicker(false);
    textAreaRef.current.focus();
  };

  return (
    isOpen && (
      <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 z-[100]">
        <div className="bg-white p-8 rounded-md relative">
          <label
            className="block mb-2 text-sm font-semibold"
            htmlFor="inputField"
          >
            {label}:
          </label>
          {label === "Gender" ? (
            <select
              id="inputField"
              value={editedValue}
              onChange={handleChange}
              className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
            >
              {genderOptions.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
          ) : label === "language" ? (
            <div>
              {editedValue !== undefined &&
                editedValue.map((language, index) => (
                  <div key={index} className="flex items-center gap-2">
                    <input
                      type="text"
                      value={language}
                      onChange={(e) => handleLanguageChange(e, index)}
                      placeholder="Language"
                      className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
                    />
                    <button
                      onClick={() => deleteLanguage(index)}
                      className="text-red-500"
                    >
                      &#10005;
                    </button>
                  </div>
                ))}
              <div className="mt-2">
                <input
                  type="text"
                  value={newLanguage}
                  onChange={(e) => setNewLanguage(e.target.value)}
                  placeholder="Add Language"
                  className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
                />
                <button
                  onClick={addLanguage}
                  className="mt-1 px-4 py-1 text-white bg-blue-500 rounded-md hover:bg-blue-600 focus:outline-none focus:bg-blue-600"
                >
                  Add
                </button>
              </div>
            </div>
          ) : label === "bio" ? (
            <div className="relative">
              <textarea
                id="inputField"
                ref={textAreaRef}
                value={editedValue}
                onChange={handleChange}
                className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500 resize-none"
                rows={5}
                maxLength={200}
              ></textarea>
              <div
                className="absolute bottom-8 right-2 z-10 cursor-pointer"
                onClick={() => setShowPicker(!showPicker)}
              >
                <RiEmotionHappyLine size={24} />
              </div>
              {showPicker && (
                <div className="absolute bottom-12 right-3 z-10">
                  <Picker
                    data={data}
                    onEmojiSelect={handleEmojiSelect}
                    theme="light"
                  />
                </div>
              )}
              <div className="text-[12px] text-BlackishGreen mt-1 text-right">
                <span
                  className={`${editedValue?.length > 190 && "text-red-500"}`}
                >
                  {editedValue?.length}
                </span>{" "}
                / 200 characters
              </div>
            </div>
          ) : label === "Phone" ? (
            <MuiTelInput
              value={editedValue}
              onChange={handleChange}
              defaultCountry="us"
              label="Phone Number"
            />
          ) : (
            <input
              id="inputField"
              type={Type}
              value={editedValue}
              onChange={handleDateChange}
              max={new Date().toISOString().split("T")[0]}
              className={`w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500 ${
                label === "Phone" ? "number-input" : ""
              }`}
            />
          )}
          <div className="mt-4 flex justify-end">
            <button
              disabled={loading}
              onClick={handleSubmit}
              className="px-4 py-2 mr-2 text-white bg-blue-500 rounded-md hover:bg-blue-600 focus:outline-none focus:bg-blue-600"
            >
              {loading ? <Loader /> : "Submit"}
            </button>
            <button
              disabled={loading}
              onClick={onClose}
              className="px-4 py-2 text-gray-600 bg-gray-200 rounded-md hover:bg-gray-300 focus:outline-none focus:bg-gray-300"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    )
  );
};

export default InfoPopup;
