import React from "react";
import {
  AirlineSeat,
  Airplane,
  Bag,
  Foodfast,
  FromToPlane,
  StopWatch,
  Wifi,
} from "../../assets";
import { useNavigate } from "react-router-dom";
import {
  TimeConverter,
  getAirlineImage,
  getAirlineName,
  getCityName,
} from "../../lib/airline-iata-codes";
import moment from "moment";
import { useSelect } from "@material-tailwind/react";
import { useSelector } from "react-redux";
const FlightMenues = [
  {
    id: 1,
    title: "30 kg",
    icon: Bag,
  },
  {
    id: 2,
    title: "",
    icon: Airplane,
  },
  {
    id: 3,
    title: "",
    icon: Wifi,
  },
  {
    id: 4,
    title: "",
    icon: StopWatch,
  },
  {
    id: 5,
    title: "",
    icon: Foodfast,
  },
  {
    id: 6,
    title: "",
    icon: AirlineSeat,
  },
];

const FlightDetailsCard = ({ data, flight }) => {
  const navigate = useNavigate();
  const dictionaries = useSelector((state) => state.flight.dictionaries);
  return (
    <div
      // key={data?.id}
      style={{ padding: "32px 24px" }}
      // onClick={() => navigate(`/flights/booking/${data?.id}`)}
      className="font-Montserrat space-y-6 my-4 shadow cursor-pointer hover:scale-[1.01] duration-300"
    >
      <div className="flex justify-between flex-wrap">
        <h1 className="text-BlackishGreen sm:text-xl text-base font-bold leading-normal">
          {flight} {moment(data?.departure?.at).format("ddd, MMM DD")}
        </h1>
        <p className="text-BlackishGreen sm:text-xl text-xs font-medium leading-normal opacity-75">
          {TimeConverter(data.duration)}
        </p>
      </div>
      <div className="flex justify-between items-center flex-wrap">
        <div className="p-5 border lg:w-max w-full flex items-start lg:justify-start justify-center space-x-5 border-[#8DD3BB] rounded">
          <img
            className="sm:h-14 h-10"
            src={getAirlineImage(data.carrierCode)}
            alt="AirlineLogo.svg"
          />
          <div>
            <h1 className="text-BlackishGreen sm:text-2xl text-base font-semibold leading-normal">
              {getAirlineName(data.carrierCode)}
            </h1>
            <p className="text-BlackishGreen text-sm font-medium leading-normal">
              {dictionaries?.aircraft[data?.aircraft?.code]}
            </p>
          </div>
        </div>
        <div className="flex flex-wrap">
          {FlightMenues &&
            FlightMenues.map((item, index) => (
              <div
                key={item?.id}
                className={`px-6 py-2 sm:w-max w-1/2 sm:border-b-0 border-b ${
                  index !== FlightMenues.length - 1 ? "sm:border-r" : ""
                } h-max flex sm:space-x-4 space-x-1`}
              >
                <img className="h-5" src={item?.icon} alt="icon" />
                <p>{item?.title}</p>
              </div>
            ))}
        </div>
      </div>
      <div className="flex sm:justify-center items-center  sm:flex-row flex-col sm:gap-16">
        <h1 className="text-BlackishGreen sm:text-2xl text-lg font-semibold leading-normal w-max">
          {moment(data?.departure?.at).format("hh:mm a")}{" "}
          <span className="text-base font-medium leading-normal opacity-60">
            {getCityName(data?.departure?.iataCode)} (
            {data?.departure?.iataCode})
          </span>
        </h1>
        <img className="h-10" src={FromToPlane} alt="FromToPlane.svg" />
        <h1 className="text-BlackishGreen sm:text-2xl text-lg font-semibold leading-normal w-max">
          {moment(data?.arrival?.at).format("hh:mm a")}{" "}
          <span className="text-base font-medium leading-normal opacity-60">
            {getCityName(data?.arrival?.iataCode)} ({data?.arrival?.iataCode})
          </span>
        </h1>
      </div>
    </div>
  );
};

export default FlightDetailsCard;
