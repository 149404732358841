import React, { useEffect, useState } from "react";
import HotelbookingcartLayout from "../../../layouts/HotelbookingcartLayout";
import Imagescard from "../../../components/images/Imagescard";
import {
  Location,
  BuildingIcon,
  VisaIcon,
  googleicon,
  facebookicon,
} from "../../../assets";
import { AddCards, Cart } from "../../../components";
import { CiCirclePlus } from "react-icons/ci";
import { useSelector, useDispatch } from "react-redux";
import { setHotelCart } from "../../../lib/Redux/slices/hotelslice";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import httpRequest from "../../../axios";
import { formatDate } from "../../../constants/apiEndPoints/functions";
import {
  CARDS,
  COMMISSION,
  SOCIAL_LOGIN,
} from "../../../constants/apiEndPoints";
import { toast } from "react-toastify";
import Vouchers from "../../PaymentVouchers/Vouchers";
import HotelCart from "../../../components/HotelCart/HotelCart";
import { useGoogleLogin } from "@react-oauth/google";
import { setUser, setemail } from "../../../lib/Redux/slices/userslice";
import { MdEmail } from "react-icons/md";
import { setPrevPathActive } from "../../../lib/Redux/slices/webData";
import { setHotelsCartData } from "../../../lib/Redux/slices/hotelsDataslice";

const HotelBookingCartPage = () => {
  const dispatch = useDispatch();

  const { total } = useSelector((state) => state.hotel);
  const { user } = useSelector((state) => state.user);
  const [AddCard, setAddCard] = useState(false);
  const [selectedEquipments, setSelectedEquipments] = useState([]);
  const [inputvalue, setInputvalue] = useState(1);
  const [HotelDetails, setHotelDetails] = useState([]);
  const [HotelOffers, setHotelOffers] = useState([]);

  // ---------------------

  const [Total, setTotal] = useState(0);
  const [ServiceFee, setServiceFee] = useState(0);
  const [Tax, setTax] = useState(0);
  const [VoucherCode, setVoucherCode] = useState("");
  const [GrandTotal, setGrandTotal] = useState(0);

  const handleEquipmentClick = (equipment) => {
    setSelectedEquipments((prevSelected) =>
      prevSelected.some((item) => item.id === equipment.id)
        ? prevSelected.filter((item) => item.id !== equipment.id)
        : [...prevSelected, equipment]
    );
  };

  const handleClick = (data) => {
    const obj = {
      type: null,
      title: data.heading,
      price: data.price,
      qty: 1,
      id: data.id,
    };
    dispatch(setHotelCart(obj));
  };
  const handleInputChange = (e, id, type) => {
    e.stopPropagation();

    const index = total.findIndex((item) => item.id === id);
    if (index !== -1) {
      const updatedTotal = [...total];

      if (type === "plus") {
        // setInputvalue((prev) => prev + 1)
        // console.log(inputvalue, 'inputvalue,...');
        updatedTotal[index] = {
          ...updatedTotal[index],
          qty: updatedTotal[index].qty + 1,
        };
        dispatch(setHotelCart(updatedTotal));
        // console.log(updatedTotal, 'update total');
      } else {
        if (updatedTotal[index].qty <= 0) {
          // setInputvalue(1)
          updatedTotal[index] = {
            ...updatedTotal[index],
            qty: 1,
          };
          dispatch(setHotelCart(updatedTotal));
        } else {
          // setInputvalue((prev) => prev - 1)
          updatedTotal[index] = {
            ...updatedTotal[index],
            qty: updatedTotal[index].qty - 1,
          };
          dispatch(setHotelCart(updatedTotal));
        }
      }
    }

    // console.log(total, 'cart');
  };

  // APIS

  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const fetchHotelDetails = async () => {
    try {
      const reponse = await httpRequest.get(`/api/v1/hotels/${id}`);
      setLoading(false);
      setHotelDetails(reponse?.data?.hotel);
      setHotelOffers(reponse?.data?.offers);
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    fetchHotelDetails();
  }, []);

  const [AllCards, setAllCards] = useState([]);
  const [SelectedCard, setSelectedCard] = useState(null);
  const [CVC, setCVC] = useState(null);

  const FetchCards = async () => {
    setLoading(true);
    try {
      const response = await httpRequest.get(CARDS);
      if (response.status === 200 || response.status === 201) {
        setAllCards(response?.data?.cards);
        setSelectedCard(response?.data?.cards[0]?._id);
        setLoading(false);
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (user !== null) {
      FetchCards();
    }
  }, [user]);
  const navigate = useNavigate();
  const [loadingPayment, setLoadingPayment] = useState(false);
  const handlePayment = async () => {
    if (!SelectedCard) {
      toast.error("Please select card");
      return;
    }

    if (!CVC) {
      toast.error("Please enter CVC");
      return;
    }

    const payload = {
      HotelOfferId: HotelOffers[0]?.id,
      hotelAddress: HotelDetails?.address,
      cardInfo: {
        cardId: SelectedCard,
        cardCVV: CVC,
      },
      couponCode: VoucherCode,
      totalPrice: Total,
    };

    setLoadingPayment(true);
    try {
      const response = await httpRequest.post(
        "/api/v1/hotels/booking",
        payload
      );
      if (response.status === 200 || response.status === 201) {
        toast.success("Booking successfuly");
        setLoadingPayment(false);
        navigate(`/hotels/ticket/${response?.data?.booking?._id}`);
      }
    } catch (error) {
      toast.error(
        "Sorry, the selected room is currently unavailable. Please choose another room or try again later. "
      );
      setLoadingPayment(false);
    }
  };

  // Google Login
  const [getCommissions, setGetCommissions] = useState([]);

  const GoogleLogin = useGoogleLogin({
    onSuccess: async (resp) => {
      try {
        const response = await fetch(
          "https://www.googleapis.com/oauth2/v3/userinfo",
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${resp?.access_token}`,
            },
          }
        );
        if (response.ok) {
          const data = await response.json();
          setLoading(true);
          try {
            const resp = await httpRequest.post(SOCIAL_LOGIN, {
              email: data?.email,
              fullName: data?.name,
              profilepic: data?.picture,
              name: data?.given_name,
            });

            if (resp?.data?.message === "Login successful") {
              toast.success(resp?.data?.message);
              dispatch(setUser(resp?.data?.user));
              localStorage.setItem("token", resp?.data?.token);
            }
            if (resp.status === 201) {
              toast.success(resp?.data?.message);
              dispatch(setemail(data?.email));
              navigate("/user/profile/bio");
            }
          } catch (error) {
            console.log(
              "🚀 ~ onSuccess: ~ error:",
              error?.response?.data?.message
            );
            toast.error(error?.response?.data?.message);
          } finally {
            setLoading(false);
          }
        } else {
          console.error(
            "Request failed:",
            response.status,
            response.statusText
          );
        }
      } catch (error) {
        console.error("Error fetching user details:", error);
        throw error;
      }
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const locaiton = useLocation();

  const HandleAuth = () => {
    localStorage.setItem("Path", locaiton.pathname);
    dispatch(setPrevPathActive());
    navigate("/login");
  };

  useEffect(() => {
    const Total = Number(HotelOffers[0]?.price?.total);
    setTotal(Total);
    const ServiceFee = Number(
      (HotelOffers[0]?.price?.total / 100) * getCommissions?.hotelCommission
    );
    setServiceFee(ServiceFee);

    const CartsDetails = [
      {
        title: "Base Fare ",
        price: Total,
      },
      {
        title: "Service Fee",
        price: ServiceFee,
      },
    ];
    dispatch(setHotelsCartData(CartsDetails));
  }, [HotelOffers]);

  const FetchCommissions = async () => {
    try {
      const Response = await httpRequest.get(COMMISSION);

      if (Response.status === 200 || Response.status === 201) {
        setGetCommissions(Response?.data);
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  };

  useEffect(() => {
    FetchCommissions();
  }, []);

  return (
    <>
      <HotelbookingcartLayout>
        {/*starting header */}
        {AddCard && (
          <div className="fixed h-full w-full bg-[#0000008e] left-0 top-0 z-10 flex items-center justify-center">
            <AddCards onclick={setAddCard} />
          </div>
        )}
        {/* //// */}
        <div className="grid grid-cols-12 w-[90%] ml-[5%] gap-2 font-Montserrat relative">
          <div className=" col-span-12 lg:col-span-8 lg:pr-[35px] xl:pr-[5px] ">
            <div className="">
              <div className="flex ml-[16px]">
                <h1 className="text-[14px] font-medium">Turkey</h1>
                <span className="ml-[8px] text-[14px]  ">{">"}</span>
                <h1 className="text-[14px] font-medium ml-[8px] ">Istambol</h1>
                <span className="ml-[8px] text-[14px] ">{">"}</span>
                <p
                  className="text-[14px] font-medium ml-[8px]"
                  style={{ color: "#A0A7A0" }}
                >
                  {HotelDetails?.name}
                </p>
              </div>
              <div className="col-span-2 p-4 ">
                <div className="grid md:grid-cols-3 grid-cols-1 font-Montserrat pb-3 w-full ">
                  <div className="col-span-2 ">
                    <div className="flex justify-start algin-center">
                      <p className="font-semibold pb-2 text-[18px] md:text-[20px] text-BlackishGreen">
                        {HotelDetails?.name}
                      </p>
                    </div>
                  </div>
                  <div className="text-[#FF8682] md:text-right text-[12px] md:text-[14px]">
                    <p className="text-[24px] font-bold">
                      {HotelOffers[0]?.price?.currency}{" "}
                      {HotelOffers[0]?.price?.total}
                      <span className="text-[12px] h-1">/night</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            {/* address */}
            <div className="bg-white shadow rounded-xl p-6">
              <div className="flex border border-[#8DD3BB] pt-[16px] pb-[16px] pl-[32px] pr-[32px] rounded-lg w-[100%]   ">
                <Imagescard
                  img={
                    "https://media.istockphoto.com/id/1677784097/photo/modern-hotel-apartment-with-bathroom.webp?b=1&s=170667a&w=0&k=20&c=rKzi2VKY7EAq_Ggx7AoYtRetG_aNze-L6nk4n4uwLLg="
                  }
                  h={60}
                  w={60}
                />
                <div className="flex flex-col ml-[24px] ">
                  <h1 className="text-[24px] font-bold text-BlackishGreen">
                    {HotelDetails?.name}
                  </h1>
                  <div className="flex mt-2">
                    <Imagescard img={Location} h={18} w={18} />
                    <p className="text-sm font-medium text-BlackishGreen">
                      {HotelDetails?.address}
                    </p>
                  </div>
                </div>
              </div>
              {/* days */}
              <div className="flex flex-col md:flex-row justify-center items-center md:justify-between mt-[40px]">
                <div
                  style={{ width: "max-content" }}
                  className="flex flex-col mb-3 md:mb-[0px] "
                >
                  <h1 className="text-xl font-semibold text-BlackishGreen">
                    {formatDate(HotelOffers[0]?.checkInDate)}
                  </h1>
                  <p className="text-sm font-medium text-[#707A70] ">
                    Check-in
                  </p>
                </div>
                <Imagescard img={BuildingIcon} h={62} w={62} />
                <div
                  style={{ width: "max-content" }}
                  className="flex flex-col mt-3 md:mt-[0px] "
                >
                  <h1 className="text-[20px] font-semibold text-BlackishGreen">
                    {formatDate(HotelOffers[0]?.checkOutDate)}
                  </h1>
                  <p className="text-[14px] font-medium text-[#707A70] ">
                    Check-out
                  </p>
                </div>
              </div>
            </div>

            <div className="w-full space-y-2 mb-4 mt-12 bg-white shadow rounded-xl p-6">
              <div
                className={`shadow-sm rounded-md cursor-pointer
                  bg-primary
              `}
              >
                <div className="flex justify-between items-center p-4">
                  <div className="space-y-2">
                    <h1
                      className={`text-white sm:text-base text-sm font-bold leading-normal `}
                    >
                      Pay in full
                    </h1>
                    <p
                      className={`text-white sm:text-sm text-xs font-normal leading-normal`}
                    >
                      Pay the total and you are all set
                    </p>
                  </div>
                  <div className="flex gap-10">
                    <div className="inline-flex items-center">
                      <label
                        className="relative flex items-center p-3 rounded-full cursor-pointer"
                        htmlFor="payfull"
                      >
                        <input
                          name="payfull"
                          type="radio"
                          checked
                          className="before:content[''] peer relative h-5 w-5 cursor-pointer appearance-none rounded-full border border-white text-white transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-transparent before:opacity-0 before:transition-opacity checked:border-white checked:before:bg-white hover:before:opacity-10"
                          id="payfull"
                        />
                        <span class="absolute text-white transition-opacity opacity-0 pointer-events-none top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 peer-checked:opacity-100">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            class="h-3.5 w-3.5"
                            viewBox="0 0 16 16"
                            fill="currentColor"
                          >
                            <circle
                              data-name="ellipse"
                              cx="8"
                              cy="8"
                              r="8"
                            ></circle>
                          </svg>
                        </span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-full">
                <div className="space-y-3 p-4">
                  <h1
                    className={`text-BlackishGreen sm:text-base text-sm font-bold leading-normal `}
                  >
                    Pay part now, part later
                  </h1>
                  <p
                    className={`text-BlackishGreen sm:text-sm text-xs font-normal leading-normal`}
                  >
                    Pay $207.43 now, and the rest ($207.43) will be
                    automatically charged to the same payment method on Nov 14,
                    2022. No extra fees.
                  </p>
                  <p
                    className={`text-BlackishGreen text-xs font-medium leading-normal underline cursor-pointer`}
                  >
                    More info
                  </p>
                </div>
              </div>
            </div>

            {/* // All Cards */}

            <div>
              {user == null ? (
                <div className="w-full space-y-4">
                  <h1
                    className={`text-BlackishGreen sm:text-xl text-base font-bold leading-normal `}
                  >
                    Login or Sign up to book
                  </h1>
                  <input
                    type="number"
                    className="w-full text-[#1C1B1F] border border-[#79747E] outline-none rounded"
                    placeholder="Phone Number"
                    style={{ padding: "8px 0px 8px 16px" }}
                  />
                  <p
                    className={`text-BlackishGreen sm:text-sm text-xs font-normal leading-normal`}
                  >
                    We’ll call or text you to confirm your number. Standard
                    message and data rates apply.{" "}
                    <span className="font-medium hover:underline cursor-pointer">
                      Privacy Policy
                    </span>
                  </p>
                  <button
                    style={{ padding: "8px 16px" }}
                    // onClick={() => navigate("/flights/booking")}
                    className="w-full h-[48px] bg-primary rounded-md hover:bg-blue-800 text-white text-sm font-semibold"
                  >
                    Continue
                  </button>
                  <div className="relative py-4 col-span-2">
                    <div className="w-full relative border-b-[2px] flex justify-center items-center">
                      <span className="absolute bg-white px-4">Or</span>
                    </div>
                  </div>
                  <div className="col-span-2 grid sm:grid-cols-2 gap-5">
                    <div className=" border border-[#8DD3BB] flex items-center justify-center rounded-lg p-[10px] cursor-pointer hover:bg-[#8DD3BB]">
                      <img src={facebookicon} alt="facebook.svg" />
                    </div>
                    <div
                      onClick={GoogleLogin}
                      className=" border border-[#8DD3BB] flex items-center justify-center rounded-lg p-[10px] cursor-pointer hover:bg-[#8DD3BB]"
                    >
                      <img src={googleicon} alt="facebook.svg" />
                    </div>
                  </div>
                  <button
                    style={{ padding: "8px 16px" }}
                    onClick={HandleAuth}
                    className="w-full h-[48px] border border-[#8DD3BB] flex items-center justify-center space-x-3 bg-white rounded-md hover:bg-[#8DD3BB] text-BlackishGreen text-sm font-semibold"
                  >
                    <MdEmail size={24} />
                    <span>Continue with email</span>
                  </button>
                </div>
              ) : (
                <div className="bg-white shadow rounded-xl p-6 my-5">
                  <div className="w-full flex flex-col gap-4">
                    {AllCards.length > 0 ? (
                      AllCards.map((card) => {
                        return (
                          <div
                            onClick={() => setSelectedCard(card?._id)}
                            key={card?._id}
                            className={`shadow-sm rounded-md cursor-pointer ${
                              SelectedCard === card?._id
                                ? " bg-primary"
                                : "bg-white border border-BlackishGreen"
                            }  `}
                          >
                            <div className="flex justify-between items-center p-4">
                              <div className="space-y-2 flex items-center space-x-10">
                                <img src={VisaIcon} alt="VisaIcon.svg" />
                                <p
                                  className={`${
                                    SelectedCard === card?._id
                                      ? " text-white"
                                      : "text-BlackishGreen"
                                  }  sm:text-base text-sm font-bold leading-normal`}
                                >
                                  ***** {card?.cardNumber}{" "}
                                  <span className="sm:text-sm text-xs font-normal leading-normal">
                                    {card?.expiryDate}
                                  </span>
                                </p>
                              </div>
                              <div className="flex gap-10">
                                <div className="inline-flex items-center">
                                  <label
                                    className="relative flex items-center p-3 rounded-full cursor-pointer"
                                    htmlFor="Card"
                                  >
                                    <input
                                      name="Card"
                                      type="radio"
                                      checked={SelectedCard === card?._id}
                                      className="before:content[''] peer relative h-5 w-5 cursor-pointer appearance-none rounded-full border border-white text-white transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-transparent before:opacity-0 before:transition-opacity checked:border-white checked:before:bg-white hover:before:opacity-10"
                                      id="Card"
                                    />
                                    <span class="absolute text-white transition-opacity opacity-0 pointer-events-none top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 peer-checked:opacity-100">
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        class="h-3.5 w-3.5"
                                        viewBox="0 0 16 16"
                                        fill="currentColor"
                                      >
                                        <circle
                                          data-name="ellipse"
                                          cx="8"
                                          cy="8"
                                          r="8"
                                        ></circle>
                                      </svg>
                                    </span>
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })
                    ) : (
                      <h1>No Cards Found</h1>
                    )}
                  </div>
                  {AllCards.length > 0 && SelectedCard && (
                    <div
                      style={{ padding: "4px 0px 4px 16px;" }}
                      className="relative w-full h-12 sm:col-span-1 col-span-2 my-2"
                    >
                      <input
                        type="text"
                        className="peer w-full h-full bg-transparent text-[#1C1B1F] font-sans font-normal outline outline-0 focus:outline-0 disabled:bg-blue-gray-50 disabled:border-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 border focus:border-2 border-t-transparent focus:border-t-transparent text-sm px-3 py-2.5 rounded-[7px] border-blue-gray-200 focus:border-gray-900"
                        placeholder=" "
                        name="cvc"
                        value={CVC}
                        onChange={(e) => setCVC(e.target.value)}
                      />
                      <label className="flex w-full h-full select-none pointer-events-none absolute left-0 font-normal !overflow-visible truncate peer-placeholder-shown:text-BlackishGreen leading-tight peer-focus:leading-tight peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-BlackishGreen transition-all -top-1.5 peer-placeholder-shown:text-sm text-[11px] peer-focus:text-[11px] before:content[' '] before:block before:box-border before:w-2.5 before:h-1.5 before:mt-[6.5px] before:mr-1 peer-placeholder-shown:before:border-transparent before:rounded-tl-md before:border-t peer-focus:before:border-t-2 before:border-l peer-focus:before:border-l-2 before:pointer-events-none before:transition-all peer-disabled:before:border-transparent after:content[' '] after:block after:flex-grow after:box-border after:w-2.5 after:h-1.5 after:mt-[6.5px] after:ml-1 peer-placeholder-shown:after:border-transparent after:rounded-tr-md after:border-t peer-focus:after:border-t-2 after:border-r peer-focus:after:border-r-2 after:pointer-events-none after:transition-all peer-disabled:after:border-transparent peer-placeholder-shown:leading-[4.2] blatext-BlackishGreen peer-focus:text-gray-900 before:border-blue-gray-200 peer-focus:before:!border-gray-900 after:border-blue-gray-200 peer-focus:after:!border-gray-900">
                        Enter CVC
                      </label>
                    </div>
                  )}

                  {/* login and sign up to book */}
                  <div className="flex flex-col ">
                    <div className="flex justify-between p-4 shadow-sm rounded-md cursor-pointer">
                      <div>
                        <h1
                          className={`
                            text-BlackishGreen
                        sm:text-base text-sm font-bold leading-normal `}
                        >
                          Promotions Available
                        </h1>
                        <h1
                          className={`
                            text-BlackishGreen
                        sm:text-sm text-xs font-bold leading-normal `}
                        >
                          20% OFF on Hotels
                        </h1>
                        <p
                          className={`
                            text-[#737B73]
                        sm:text-sm text-xs font-bold leading-normal`}
                        >
                          *Terms & conditions apply.
                        </p>
                        <p
                          className={`text-BlackishGreen text-xs font-medium leading-normal underline cursor-pointer`}
                        >
                          More info
                        </p>
                      </div>
                      <div className="flex items-center space-x-5">
                        <div className="inline-flex items-center">
                          <label
                            className="relative flex items-center p-3 rounded-full cursor-pointer"
                            htmlFor="promotions"
                          >
                            <input
                              name="promotions"
                              type="radio"
                              className="before:content[''] peer relative h-5 w-5 cursor-pointer appearance-none rounded-full border border-BlackishGreen textblaborder-BlackishGreen transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-transparent before:opacity-0 before:transition-opacity checked:border-BlackishGreen checked:before:bgblaborder-BlackishGreen hover:before:opacity-10"
                              id="promotions"
                            />
                            <span class="absolute textblaborder-BlackishGreen transition-opacity opacity-0 pointer-events-none top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 peer-checked:opacity-100">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                class="h-3.5 w-3.5"
                                viewBox="0 0 16 16"
                                fill="currentColor"
                              >
                                <circle
                                  data-name="ellipse"
                                  cx="8"
                                  cy="8"
                                  r="8"
                                ></circle>
                              </svg>
                            </span>
                          </label>
                        </div>
                      </div>
                    </div>
                    <Vouchers
                      Sfee={ServiceFee}
                      Total={Total}
                      setVoucherCodes={setVoucherCode}
                      type="hotel"
                    />
                    <div
                      onClick={() => setAddCard(true)}
                      className="h-48 w-full border-dashed border-2 border-[#8DD3BB] rounded-xl flex items-center justify-center flex-col space-y-2 cursor-pointer"
                    >
                      <CiCirclePlus className="text-[#8DD3BB]" size={64} />
                      <p className="text-BlackishGreen opacity-75 text-xs font-medium leading-normal">
                        Add a new card
                      </p>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          {/* cart section */}
          <div className="col-span-12 lg:col-span-4 flex justify-center sticky right-0 top-5 h-max ">
            <div className="">
              <HotelCart
                HotelDetails={HotelDetails}
                HotelOffers={HotelOffers}
                loading={loadingPayment}
                payment={handlePayment}
                setGrandTotal={setGrandTotal}
              />
            </div>
          </div>
        </div>
      </HotelbookingcartLayout>
    </>
  );
};

export default HotelBookingCartPage;
