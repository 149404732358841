import React from "react";
import { GoArrowLeft, GoArrowRight } from "react-icons/go";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Cards from "../AddCards/Cards/Cards";
import { Explore, rectangle } from "../../assets";

const TicketSlider = () => {
  const CustomArrow = (props) => {
    const { className, style, onClick, arrowType } = props;
    return (
      <div
        style={{ position: "absolute", top: "-20%", left: "91%" }}
        className="flex justify-start items-center gap-3 "
      >
        <div
          onClick={onClick}
          className="rounded-full bg-[#007BFF] text-white h-[30px] w-[30px] flex items-center justify-center "
        >
          <GoArrowLeft />
        </div>
        <div
          onClick={onClick}
          className="rounded-full bg-[#007BFF] text-white h-[30px] w-[30px]  flex items-center justify-center  "
        >
          <GoArrowRight />
        </div>
      </div>
    );
  };

  var settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 10000,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    initialSlide: 0,
    prevArrow: <CustomArrow arrowType="prev" />,
    nextArrow: <CustomArrow arrowType="next" />,
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          prevArrow: null,
          nextArrow: null,
        },
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
          prevArrow: null,
          nextArrow: null,
        },
      },
    ],
  };

  const sliderdata = [
    {
      title: "Para Sailing",
      img: rectangle,
      rating: 5,
      starting: 35.0,
    },
    {
      title: "Jet Ski Ride",
      img: rectangle,
      rating: 5,
      starting: 25.0,
    },
    {
      title: "Scuba Div",
      img: rectangle,
      rating: 5,
      starting: 45.0,
    },
    {
      title: "Comple City Tour",
      img: rectangle,
      rating: 5,
      starting: 50.0,
    },
    {
      title: "Para Sailing",
      img: rectangle,
      rating: 5,
      starting: 35,
    },
    {
      title: "Para Sailing",
      img: rectangle,
      rating: 5,
      starting: 35,
    },
  ];

  const Cardrender = sliderdata.map((items, index) => {
    return <Cards key={index} data={items} />;
  });

  return (
    <div className="py-10">
      <div className="relative min-h-[450px] bg-[#E5F2FF] rounded-lg p-[30px] mt-[50px]">
        <div className="h-[20%] w-full flex">
          <div className="flex">
            <img
              src={Explore}
              style={{ width: "auto", height: "40px" }}
              alt=""
            />
            <div className="flex flex-col ml-[15px]">
              <h1 className="text-[#394149] text-[18px] font-medium">
                Explore more about your destination
              </h1>
              <p className="text-[#394149] text-[14px] font-normal">
                See what's available when you reach there and explore more of it
              </p>
            </div>
          </div>
        </div>
        <div className="h-[80%] w-full mt-[20px]">
          <Slider {...settings}>{Cardrender}</Slider>
        </div>
      </div>
    </div>
  );
};

export default TicketSlider;
