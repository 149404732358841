import React, { useEffect, useState } from "react";
import { FaYoutube } from "react-icons/fa";
import { FaFacebook } from "react-icons/fa6";
import { FaTwitter } from "react-icons/fa";
import { RiInstagramFill } from "react-icons/ri";
import Imagescard from "../images/Imagescard";
import { FotterImage, FooterLogo } from "../../assets";
import { sections } from "./FooterLinks";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const Footer = ({ buttonText }) => {
  const [CurrentState, setCurrentState] = useState(false);
 
  const location = useLocation();
  const isAuthenticated = useSelector((state)=>state.user.isAuthenticated)
  // console.log("🚀 ~ Footer ~ user:", isAuthenticated)
  const navigate = useNavigate()

  useEffect(() => {
    if (location.pathname == "/flights") {
      setCurrentState(true);
    } else {
      setCurrentState(false);
    }
  }, [location.pathname]);

  return (
    <>
      <div className=" h-[1050px] sm:h-[1050px] md:h-[550px] lg:h-[350px] xl:h-[350px]  w-[full] flex flex-col mt-[160px] font-Montserrat">
        <div className="h-[100%] bg-[#007BFF] w-[100%] relative">
         {!isAuthenticated &&  <div className=" overflow-hidden h-[25%] sm:h-[25%] md:h-[50%] lg:h-[80%] w-[90%] bg-[#CCE5FF] rounded-xl ml-[5%] translate-y-[-140px] absolute grid grid-cols-4   ">
            <div className="col-span-4 xl:col-span-3 lg:col-span-3 md:col-span-4 sm:col-span-4 xs:col-span-4">
              <div className="w-[90%] flex flex-col ml-[5%] justify-center h-[100%] ">
                <p className="text-base sx:text-[24px] sm:text-[28px] md:text-[44px] text-BlackishGreen font-bold leading-[40px] md:leading-[45px]">
                  {CurrentState
                    ? "Login/Sign up now and Start Planning your Trip."
                    : "Subscribe to our Newsletter"}
                </p>
                <h5 className="text-lg font-bold mt-[15px] text-BlackishGreen">
                  Guidone
                </h5>
                <p className="mt-[5px] text-BlackishGreen sm:text-base text-sm font-medium leading-normal">
                  Get inspired! Receive travel discounts, tips and behid the
                  scencs stories
                </p>
                <div className="flex mt-[10px] ">
                  <input
                    placeholder="Your email address"
                    className=" p-[15px] h-[56px] w-[85%] rounded text-[#1C1B1F] outline-none"
                  />
                  <button onClick={() => navigate("/signup")} className="rounded-sm bg-BlackishGreen text-white h-[56px] font-[bold] text-sm font-semibold ml-[15px] pl-[16px] pr-[16px] ">
                    {buttonText ? buttonText : "Continue"}
                  </button>
                </div>
              </div>
            </div>
            <div className="mr-[5px] flex justify-center items-center xl:col-span-1 lg:col-span-1 md:col-span-4 sm:col-span-4 xs:col-span-4 ">
              <Imagescard img={FotterImage} h={80} w={80} isFotter={true} />
            </div>
          </div>}
          <div className="grid grid-cols-1  md:grid-cols-3 lg:grid-cols-6 w-[90%] m-auto h-[10px] translate-y-[150px] mt-[20px] sm:gap-10 ">
            <div className="flex flex-col mb-[16px] ">
              <img
                className="w-[80%] h-auto"
                src={FooterLogo}
                alt="GuidoneLogo.svg"
              />
              <div className="flex mt-[15px] ">
                <FaYoutube className="text-[white] mr-[10px] text-[22px] cursor-pointer " />
                <FaFacebook className="text-[white] mr-[10px] text-[22px] cursor-pointer " />
                <FaTwitter className="text-[white] mr-[10px] text-[22px] cursor-pointer " />
                <RiInstagramFill className="text-[white] mr-[10px] text-[22px] cursor-pointer " />
              </div>
            </div>
            {/* /// */}
            {sections.map((section, index) => (
              <div key={index} className="flex flex-col mb-4">
                <h1 className="text-base font-bold text-white mb-4">
                  {section.title}
                </h1>
                {section.links.map((link, linkIndex) => (
                  <Link
                    key={linkIndex}
                    to={link.to}
                    className="text-sm font-medium text-white mb-2 opacity-70 hover:opacity-100"
                  >
                    {link.name}
                  </Link>
                ))}
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
