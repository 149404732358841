const SignupSlide1 = "/assets/images/SignupSlide1.png";
const Avtar = "/assets/images/Avtar.png";
const facebookicon = "/assets/icons/facebook.svg";
const googleicon = "/assets/icons/Google.svg";
const appleicon = "/assets/icons/Apple.svg";
const Building = "/assets/images/Building.svg";
const Thinking = "/assets/images/Thinking.svg";
const Personal = "/assets/images/personalBack.png";
const Plane = "/assets/images/Plane.svg";
const Cross = "/assets/icons/Cross.svg";
const Upload = "/assets/icons/Upload.svg";
const DocIcon = "/assets/icons/DocIcon.svg";
const RedCross = "/assets/icons/RedClose.svg";
const loader = "/assets/icons/loader.gif";
const Maps = "/assets/images/view-map.svg";
const ForgotImg = "/assets/images/hotel.png";
const verifyImg = "/assets/images/Trees.png";
const Globalimg = "/assets/images/Global.svg";
const Forgotpass = "/assets/images/Forgotpass.png";
const ForgotCenter = "/assets/images/forgotCenter.svg";
const Logo = "/assets/images/MainLogo.svg";
const MessageIcon = "/assets/icons/Message.svg";
const WorldIcon = "/assets/icons/World.svg";
const SearchIcon = "/assets/icons/SearchIcon.svg";
const user = "/assets/icons/User.svg";
const Medal = "/assets/images/Medal.svg";
const WorkingCharacter = "/assets/images/WorkingMan.svg";
const GreaterThen = "/assets/icons/Greaterthen.svg";
const SetttngsIcon = "/assets/icons/SettingsIcon.svg";
const FlagIcon = "/assets/images/Flag.svg";
const LocationWorld = "/assets/images/LocationWorld.svg";
const Badge1 = "/assets/images/Badge1.svg";
const Badge2 = "/assets/images/Badge2.svg";
const Mask = "/assets/images/Mask.svg";
const HomeSidebarpic = "/assets/images/Home Side img.svg";
const CrownPic = "/assets/images/Crown.svg";
const Heart = "/assets/icons/heart.svg";
const Information = "/assets/icons/Information.svg";
const PictureTag = "/assets/icons/price-tag.svg";
const Sheild = "/assets/icons/shield.svg";
const WellClock = "/assets/icons/wall-clock.svg";
const SettingsSidebar = "/assets/images/SettingsSidebar.svg";
const FlightImg = "/assets/images/Flight.svg";
const Flight = "/assets/icons/Flight.svg";
const clander = "/assets/icons/Calender.svg";
const LineCenter = "/assets/icons/Line.svg";
const PersonalSide = "/assets/images/PersonalSidebar.svg";
const SecuritySide = "/assets/images/SecuritySide.svg";
const PaymentSide = "/assets/images/paymentSide.svg";
const Edit = "/assets/icons/Edit.svg";
const VisaLogo = "/assets/images/Visa.png";
const RightLines = "/assets/images/RightCardLines.svg";
const LeftLines = "/assets/images/LeftSidelines.svg";
const Trash = "/assets/icons/Trash.svg";
const VoutchersThumb = "/assets/images/VoutcherThumb.svg";
const Playicon = "/assets/images/playicon.svg";
const Diving = "/assets/images/diving.svg";
const Istanbul = "/assets/images/istanbul.svg";
const Swap = "/assets/icons/Swap.svg";
const SearchFlight = "/assets/icons/Search.svg";
const AirlineLogo = "/assets/images/AirlineLogo.svg";
const Hotel = "/assets/images/Hotel 1.svg";
const Location = "/assets/images/Location.svg";
const Ratting = "/assets/images/Ratting.svg";
const Cup = "/assets/images/cup.svg";
const AccomodationS = "/assets/images/Accomosilde.svg";
const SUBside = "/assets/images/sideSubs.svg";
const Messages = "/assets/images/message.svg";
const EmertiesPlane = "/assets/images/EmertiesPlane.svg";
const PlaneWindow = "/assets/images/PlaneWindow.svg";
const StopWatch = "/assets/images/StopWatch.svg";
const Bag = "/assets/icons/Bag.svg";
const AirlineSeat = "/assets/icons/Airline-seat.svg";
const Wifi = "/assets/icons/Wifi.svg";
const Airplane = "/assets/icons/airplane.svg";
const Foodfast = "/assets/icons/fast-food.svg";
const FromToPlane = "/assets/images/FromToplane.svg";
const Planes = "/assets/images/Planesub.svg";
const BackgroundS = "/assets/images/backSub.svg";
const BackgroundS1 = "/assets/images/BackgroundS1.svg";
const Dots = "/assets/images/threedots.svg";
const Media = "/assets/images/mediamessage.svg";
const Mediaicon = "/assets/icons/mediaicon.svg";
const Attach = "/assets/images/attachfile.svg";
const startsvg = "/assets/images/Stars.svg";
const rectangle = "/assets/images/Rectangle 5.png";
const modi = "/assets/images/modi.svg";
const smallflag = "/assets/images/smallFlag.svg";
const VisaIcon = "/assets/icons/Visa-icon.svg";
const Shiningstar = "/assets/images/Shiningstar.svg";
const Explore = "/assets/images/explore.png";
const timmer = "/assets/images/timmer.svg";
const Calender2 = "/assets/images/NewCelender.svg";
const strip = "/assets/images/strip.svg";
const doubleimage = "/assets/images/doubleimg.png";
const TravelIcon = "/assets/icons/TravelIcon.svg";
const Emoji = "/assets/icons/emojicon.svg";
const SendI = "/assets/icons/sendicon.svg";
const airplaneAround = "/assets/icons/airplare-aroundbluesvg.svg";
const MyGuidblue = "/assets/icons/Myguideblue.svg";
const messageblue = "/assets/icons/messagesblue.svg";
const letterbox = "/assets/images/letterbox.svg";
const MoneyBox = "/assets/images/MoneyBox.png";
const SearchHome = "/assets/images/SearchHome.png";
const DontUnderstand = "/assets/images/DontUnderstand.png";
const Oval = "/assets/images/Oval.svg";
const Cafe = "/assets/images/cafe.svg";
const LocationIcon = "/assets/images/Location.svg";
const GoggleGroup = "/assets/images/GoggleGroup.svg";
const ArrowRight = "/assets/images/RightArraw.svg";
const World = "/assets/images/world.svg";
const Hicking = "/assets/images/hiking.svg";
const TransPortWorld = "/assets/images/transpotWorld.png";
const AccomodatoinWorld = "/assets/images/accomodatoinWorld.png";
const ActivityWorld = "/assets/images/ActivityWorld.png";
const DummyMap = "/assets/images/DummyMap.svg";
const DummyMap2 = "/assets/images/DummyMap2.svg";
const BuildingIcon = "/assets/images/BuildingIcon.svg";
const FotterImage = "/assets/images/FotterImage.png";
const Sports = "/assets/images/sport.svg";
const Yoga = "/assets/images/yoga.svg";
const HeartInHand = "/assets/images/heartinhand.svg";
const Sahkura = "/assets/images/sakura.svg";
const CultureImg = "/assets/icons/culture-icons.png";
const LanguageGlobe = "/assets/images/languageGlobe.png";
const ReligionGlobe = "/assets/images/ReligionGlobe.png";
const SvgLine = "/assets/images/SvgLine.svg";
const TravelImg = "/assets/images/travelImg.png";
const SocialMedia = "/assets/images/Socialmedia.png";
const Transport = "/assets/images/transport.png";
const AllSocialMediaIocns = "/assets/images/allsocialmediaicons.png";
const Lyft = "/assets/images/lyft.png";
const Uber = "/assets/images/uber.png";
const PlaceImg = "/assets/images/placeImg.png";
const PersonOvel = "/assets/images/PersonOval.svg";
const Dollers = "/assets/images/dollars.svg";
const IslandImg = "/assets/images/IslandImg.png";
const HotelLogo = "/assets/images/HotelLogo.png";
const EmailIcon = "/assets/images/emailicon.svg";
const ImageIcon = "/assets/images/image.svg";
const VideoIcon = "/assets/images/film.svg";
const FileIcon = "/assets/images/file-text.svg";
const FooterLogo = "/assets/images/FooterLogo.svg";
const FlightTicket = "/assets/images/FlightTicketLogo.svg";
const barCode = "/assets/images/BarCode.svg";
const TicketFlightMap = "/assets/images/TicketFlightMap.svg";
const Cultureimg = "/assets/images/Cultures.svg";
const historyimg = "/assets/images/History.svg";
const PracInfo = "/assets/images/PracticalInfo.svg";
const GalleryImg = "/assets/images/Gallery.svg";
const mainFlight = "/assets/icons/MainFlight.svg";
const mainCalender = "/assets/icons/MainCalneder.svg";
const Login1 = "/assets/images/Login1.svg";
const Login2 = "/assets/images/Login2.svg";
const UpperLogin2 = "/assets/images/Loginupper2.svg";
const UpperLogin3 = "/assets/images/LoginUpper3.svg";
const Verify2 = "/assets/images/VerifyLower2.svg";
const VerifyUpper2 = "/assets/images/VerifyUpper.svg";
const Login2Down = "/assets/images/Login2back.svg";
const EmailLogo = "/assets/images/EmailLogo.png";
const MoneyLogo = "/assets/icons/money.svg";
const FoodLogo = "/assets/icons/food.svg";
const SecurityLogo = "/assets/icons/security.svg";
const LanguageDest = "/assets/images/language.svg";
const BudgetDest = "/assets/images/budget.svg";
const GuideGlobe = "/assets/images/GuideBlobe.svg";
const classicExplore1 = "/assets/images/ClasicExplore.svg";
const classicExplore2 = "/assets/images/ClassicExplore2.svg";
const Arrowback = "/assets/icons/Arrowback.svg";
const upclasic = "/assets/icons/UpClasic.svg";
const ClassisGoal = "/assets/images/classicGoal.svg";


export {
  ClassisGoal,
  upclasic,
  Arrowback,
  classicExplore1,
  classicExplore2,
  GuideGlobe,
  LanguageDest,
  BudgetDest,
  MoneyLogo,
  FoodLogo,
  SecurityLogo,
  EmailLogo,
  Login2Down,
  Verify2,
  VerifyUpper2,
  Login1,
  Login2,
  UpperLogin2,
  UpperLogin3,
  GalleryImg,
  Cultureimg,
  historyimg,
  PracInfo,
  Dollers,
  PersonOvel,
  PlaceImg,
  Uber,
  Lyft,
  AllSocialMediaIocns,
  Transport,
  SocialMedia,
  TravelImg,
  SvgLine,
  LanguageGlobe,
  CultureImg,
  Sports,
  Yoga,
  HeartInHand,
  Sahkura,
  ReligionGlobe,
  IslandImg,
  TicketFlightMap,
  barCode,
  Calender2,
  FlightTicket,
  FooterLogo,
  VideoIcon,
  ImageIcon,
  FileIcon,
  FotterImage,
  EmailIcon,
  BuildingIcon,
  HotelLogo,
  TransPortWorld,
  AccomodatoinWorld,
  ActivityWorld,
  Hicking,
  World,
  ArrowRight,
  MoneyBox,
  SearchHome,
  DontUnderstand,
  Oval,
  Cafe,
  LocationIcon,
  GoggleGroup,
  DummyMap2,
  DummyMap,
  letterbox,
  doubleimage,
  strip,
  timmer,
  Explore,
  Shiningstar,
  MyGuidblue,
  messageblue,
  airplaneAround,
  TravelIcon,
  smallflag,
  modi,
  rectangle,
  startsvg,
  VisaIcon,
  FromToPlane,
  Foodfast,
  Airplane,
  Wifi,
  AirlineSeat,
  Bag,
  StopWatch,
  PlaneWindow,
  EmertiesPlane,
  AirlineLogo,
  SearchFlight,
  Swap,
  Logo,
  WorldIcon,
  MessageIcon,
  SearchIcon,
  user,
  Medal,
  WorkingCharacter,
  GreaterThen,
  SetttngsIcon,
  FlagIcon,
  LocationWorld,
  Badge1,
  Badge2,
  Mask,
  HomeSidebarpic,
  CrownPic,
  Heart,
  Information,
  PictureTag,
  Sheild,
  WellClock,
  SettingsSidebar,
  SignupSlide1,
  Avtar,
  facebookicon,
  googleicon,
  appleicon,
  Building,
  Thinking,
  Personal,
  Plane,
  Cross,
  Upload,
  DocIcon,
  RedCross,
  Maps,
  ForgotImg,
  verifyImg,
  Globalimg,
  Forgotpass,
  ForgotCenter,
  loader,
  FlightImg,
  Flight,
  clander,
  LineCenter,
  PersonalSide,
  SecuritySide,
  PaymentSide,
  Edit,
  VisaLogo,
  RightLines,
  LeftLines,
  Trash,
  VoutchersThumb,
  Playicon,
  Diving,
  Istanbul,
  Hotel,
  Location,
  Ratting,
  Cup,
  AccomodationS,
  SUBside,
  Planes,
  BackgroundS,
  BackgroundS1,
  Messages,
  Dots,
  Media,
  Mediaicon,
  Attach,
  Emoji,
  SendI,
  mainFlight,
  mainCalender,
};
