import React, { useState } from "react";
import Imagescard from "../../components/images/Imagescard";

const MapPills = ({
  bigimg,
  borderW,
  borderC,
  bg,
  textC,
  content,
  img,
  imgH,
  imgW,
  Activebg,
  ActiveColor,
  mode,
  Methods,
  fun = () => {},
}) => {
  return (
    <>
      <div
        onClick={() => fun(content, bigimg)}
        style={{
          width: "max-content",
          // border: `${
          //   mode === content && !bigimg
          //     ? `4px solid #007BFF`
          //     : "4px solid transparent"
          // }`,
        }}
        className={`shadow-lg relative mx-4 h-[50px] ${
          bigimg ? "space-x-14" : "space-x-4"
        }   border-${borderW}  rounded-3xl flex items-center justify-center px-4 cursor-pointer 
        ${mode === content && !bigimg ? "bg-[#c0D2DC]" : "bg-white"}
        ${
          mode === content && !bigimg
            ? `border-4 border-[#007BFF] `
            : "border-4 border-transparent"
        }
        hover:bg-[#B1E4FA] hover:text-[#007BFF] hover:border-[#007BFF] hover:border-2
        `}
      >
        <div
          className={`${
            bigimg ? " absolute left-[-30px] h-[96px] w-[90px]" : ""
          }`}
        >
          <Imagescard img={img} h={imgH} w={imgW} />
        </div>
        <p
          style={{
            color: `${
              (mode === content && !bigimg) || (bigimg && Methods === content)
                ? ActiveColor
                : textC
            }`,
          }}
          className={`sm:text-sm text-xs font-bold w-max text-end`}
        >
          {content}
        </p>
        {/* <img src={img} /> */}
      </div>
    </>
  );
};

export default MapPills;
