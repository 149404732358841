import React from "react";
import { GreaterThen, Medal, WorkingCharacter, user } from "../../assets";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const Sidear = () => {
  const navigate = useNavigate();
  const { image = "" } = useSelector((state) => state.web);
  const handleProgreess = (e) => {
    navigate(`${e}`);
  };

  return (
    <div className=" bg-[#F0F0F0] px-4 py-8 h-screen relative border">
      <div className="border-b border-[#00000027] pb-4 space-y-8">
        <div
          onClick={() => handleProgreess("/user-profile")}
          className="bg-[#007BFF] p-3 w-full rounded-md flex space-x-4 cursor-pointer"
        >
          <img src={user} alt="user.svg" />
          <span className="text-white text-base font-medium font-Montserrat leading-normal">
            My Odyssey
          </span>
        </div>
        <div
          onClick={() => handleProgreess("/inprogress")}
          className="bg-transparent p-3 w-full rounded-md flex space-x-4 cursor-pointer"
        >
          <img src={Medal} alt="user.svg" />
          <span className="text-black text-base font-medium font-Montserrat leading-normal">
            Subscription & Experience Levels
          </span>
        </div>
      </div>
      <div onClick={() => handleProgreess("/inprogress")} className="py-4">
        <div className="bg-transparent p-3 w-full rounded-md flex justify-between cursor-pointer">
          <img src={WorkingCharacter} alt="user.svg" />
          <span className="text-black text-base font-medium font-Montserrat leading-normal">
            Professionals
          </span>
          <img src={GreaterThen} alt="Greater.svg" />
        </div>
      </div>
      <div className="h-56 w-full absolute bottom-0 left-0">
        <img className="h-full" src={image} alt="sideimg.svg" />
      </div>
    </div>
  );
};

export default Sidear;
