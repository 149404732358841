const sections = [
  {
    title: "Our Destinations",
    links: [
      { name: "Canada", to: "#" },
      { name: "Alaska", to: "#" },
      { name: "France", to: "#" },
      { name: "Iceland", to: "#" },
    ],
  },
  {
    title: "Our Activities",
    links: [
      { name: "Northern Lights", to: "#" },
      { name: "Cruising & sailing", to: "#" },
      { name: "Multi-activities", to: "#" },
      { name: "Kayaking", to: "#" },
    ],
  },
  {
    title: "Travel Blogs",
    links: [
      { name: "Bali Travel Guide", to: "#" },
      { name: "Sri Lanka Travel Guide", to: "#" },
      { name: "Peru Travel Guide", to: "#" },
    ],
  },
  {
    title: "About Us",
    links: [
      { name: "Our Story", to: "#" },
      { name: "Work with us", to: "#" },
    ],
  },
  {
    title: "Contact Us",
    links: [
      { name: "Contact Information", to: "#" },
      { name: "Support", to: "#" },
    ],
  },
];

export { sections };
