import React from 'react'


const ImageRound = ({h,w,img}) => {
  return (
    <>
       {/* <img className={`w-[${w}px] h-[${h}px] rounded-full `} src={img} alt="" /> */}
       <img className={`w-[${w}px] h-[${h}px] rounded-full `} src={img} alt="" /> 
    </>
  )
}

export default ImageRound