import React, { useRef, useState  } from "react";
import { FaPlay } from "react-icons/fa";
import { PersonOvel } from "../../../assets";

const GalleryVideoCard = ({videosrc}) => {

  const VideoREf = useRef();

  const [Showplaycontroller, setShowplaycontroller] = useState(false);

  const handleplay = () => {
    VideoREf.current.play();
    setShowplaycontroller(true);
  };

  const handlePlayPause = () => {
    setShowplaycontroller(false);
  };


  return (
    <>
      <div className="w-[100%] h-[100%] mb-[10px] relative ">
        {!Showplaycontroller && (
          <button
            onClick={handleplay}
            className="bg-white z-50 rounded-full h-[50px] w-[50px] flex justify-center items-center absolute cursor-pointer top-[48%] left-[48%] "
          >
            <FaPlay />
          </button>
        )}
        <video
          ref={VideoREf}
          controls={Showplaycontroller ? true : false}
          // onPlay={handlePlayPause}
          onPause={handlePlayPause}
          className="w-full h-full  object-cover rounded-md border border-[#d8d8d8] "
        >
          <source src={videosrc} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        {!Showplaycontroller && (
          <div className=" z-40 top-[89.5%] left-[1.2%] absolute ">
            <img src={PersonOvel} className="h-[35px] w-[35px]  " />
          </div>
        )}
      </div>
    </>
  );
};

export default GalleryVideoCard;
