import React from "react";
import { GreaterThen, Logo, Medal, WorkingCharacter, user } from "../../assets";
import Search from "../Search";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Links } from "./Links";
import { HiBell } from "react-icons/hi";
import { useDispatch, useSelector } from "react-redux";
import { FaXmark } from "react-icons/fa6";
import { clearUser } from "../../lib/Redux/slices/userslice";
import { FiLogOut, FiUser } from "react-icons/fi";
import { LuSearch } from "react-icons/lu";

const ResponsiveNavbar = ({
  setResponsiveNavOpen = () => {},
  setSearchVal = () => {},
  SearchVal = "",
  SearchDestination = [],
  handlemapDesSerch = () => {},
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const data = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const userimg = data?.user?.profilepic;

  const handleProgreess = (e) => {
    navigate(`${e}`);
    setResponsiveNavOpen(false);
  };

  const handleLogout = () => {
    dispatch(clearUser());
    localStorage.clear();
    navigate("/");
  };

  return (
    <div className="h-screen w-full overflow-y-auto py-3">
      <div className="flex flex-col justify-center px-2 items-start space-y-2 border-b pb-2">
        <div className="flex justify-between items-center w-full">
          <img
            className="cursor-pointer"
            onClick={() => handleProgreess("/")}
            src={Logo}
            alt="Logo.svg"
          />
          <FaXmark
            className="text-red-600 mx-2 text-xl"
            onClick={() => setResponsiveNavOpen(false)}
          />
        </div>
        <div className="w-full relative">
          <div className="border flex items-center justify-between rounded-lg">
            <input
              type="text"
              onClick={() => navigate("/")}
              onChange={(e) => setSearchVal(e.target.value)}
              value={SearchVal}
              placeholder="Search your dream destination, activities ...."
              className="p-4 w-[90%] text-[#00000083] outline-none placeholder:text-[#00000080] text-sm font-semibold leading-normal"
            />
            <LuSearch className="text-2xl text-[#0000008a] mx-4 cursor-pointer" />
          </div>
          {SearchVal !== "" && (
            <div className="w-full z-40 bg-white shadow absolute left-0 top-10">
              <ul class="bg-white border border-gray-100 w-full mt-2 ">
                {SearchDestination.length > 0 &&
                  SearchDestination.map((item, index) => {
                    return (
                      <li
                        key={index}
                        onClick={() => handlemapDesSerch(item)}
                        class="pl-8 pr-2 py-1 border-b-2 border-gray-100 relative cursor-pointer hover:bg-yellow-50 hover:text-gray-900"
                      >
                        <svg
                          class="stroke-current absolute w-4 h-4 left-2 top-2"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
                          />
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
                          />
                        </svg>
                        <b>{item?.display_name}</b>
                      </li>
                    );
                  })}
              </ul>
              {SearchDestination.length === 0 && (
                <p className="text-red-500 p-4 text-center">Not Found</p>
              )}
            </div>
          )}
        </div>
      </div>
      <div className="py-2">
        <div className="border-b border-[#00000027] pb-4 space-y-3 px-4 py-4">
          <div
            onClick={() => handleProgreess("/")}
            className="bg-[#007BFF] p-3 w-full rounded-md flex space-x-4 cursor-pointer"
          >
            <img src={user} alt="user.svg" />
            <span className="text-white text-base font-medium font-Montserrat leading-normal">
              My Odyssey
            </span>
          </div>
          <div
            onClick={() => handleProgreess("/inprogress")}
            className="bg-transparent p-3 w-full rounded-md flex space-x-4 cursor-pointer"
          >
            <img src={Medal} alt="user.svg" />
            <span className="text-black text-base font-medium font-Montserrat leading-normal">
              Subscription & Experience Levels
            </span>
          </div>
          <div>
            <div
              onClick={() => handleProgreess("/inprogress")}
              className="bg-transparent p-3 w-full rounded-md flex lg:justify-between lg:space-x-0 space-x-3 cursor-pointer"
            >
              <img src={WorkingCharacter} alt="user.svg" />
              <span className="text-black text-base font-medium font-Montserrat leading-normal">
                Professionals
              </span>
              <img src={GreaterThen} alt="Greater.svg" />
            </div>
          </div>
        </div>
        <ul className="p-4">
          {Links &&
            Links.map((item) => {
              return (
                <div
                  key={item?.id}
                  onClick={() => handleProgreess(item?.link)}
                  className={`flex items-center cursor-pointer space-x-2 py-3 px-4  rounded-md ${
                    location.pathname === item?.link
                      ? "border-2 border-primary bg-[#007bff59]"
                      : ""
                  }`}
                >
                  <img
                    src={
                      location.pathname === item?.link
                        ? item?.active
                        : item?.icon
                    }
                    alt="imgs.svg"
                  />
                  <span
                    className={`${
                      location.pathname === item?.link
                        ? "text-primary"
                        : "text-[#999999]"
                    } text-[13px] leading-normal font-medium `}
                  >
                    {item?.title}
                  </span>
                </div>
              );
            })}
        </ul>
        <div className="py-2 px-4">
          <div className="flex items-center">
            <div
              onClick={() => handleProgreess("/inprogress")}
              className=" h-full w-[50px] flex items-center justify-center rounded-md relative"
            >
              <HiBell className="text-3xl text-[#007BFF]" />
              <div className="h-[15px] w-[15px] bg-white absolute rounded-full right-2 top-2 flex items-center justify-center">
                <div className="h-[10px] w-[10px] rounded-full bg-[#28A745]"></div>
              </div>
            </div>
            <h1 className="text-[#999999] cursor-pointer">Notifications</h1>
          </div>
          <div className="flex items-center py-2">
            <div className="h-[50px] overflow-hidden w-[50px] border #F0F0F0 rounded-full cursor-pointer my-2 mx-2">
              <img
                className="h-full w-full"
                src={
                  userimg ||
                  "https://i.pinimg.com/originals/2a/4e/8d/2a4e8dab6791ed643d5180bc72167f6d.jpg"
                }
                alt="profile.png"
              />
            </div>
            <h1
              onClick={() => handleProgreess("/user-profile")}
              className="text-[#999999] cursor-pointer"
            >
              My Profile
            </h1>
          </div>
          {data?.user !== null ? (
            <div
              onClick={handleLogout}
              className="flex space-x-2 cursor-pointer text-[#999999] mx-4"
            >
              <FiLogOut />
              <span>Logout</span>
            </div>
          ) : (
            <Link className=" p-3" to={"/login"}>
              <div className="flex space-x-2 cursor-pointer text-[#999999] mx-4">
                <FiUser />
                <span>Login</span>
              </div>
            </Link>
          )}
        </div>
      </div>
    </div>
  );
};

export default ResponsiveNavbar;
