import React, { useEffect, useState } from "react";
import SettingsLayout from "../../layouts/settingslayout";
import { useDispatch, useSelector } from "react-redux";
import {
  setSidebarimg,
  setheader,
  setpaths,
} from "../../lib/Redux/slices/webData";
import { Edit, PersonalSide } from "../../assets";
import httpRequest from "../../axios";
import {
  GET_USER_INFO,
  TRAVEL_DOCUMENTS,
  UPDATE_PROFILE_INFO,
} from "../../constants/apiEndPoints";
import { InfoPopup } from "../../basecomponents";
  import { toast } from 'react-toastify';
import UploadTravelDocuments from "../../basecomponents/documentpopup";
import {
  FetchUserInfo,
  dateFormatter,
} from "../../constants/apiEndPoints/functions";
import { setUser } from "../../lib/Redux/slices/userslice";

const paths = [
  { label: "MyGuide", url: "/user-profile" },
  { label: "My Odyssey", url: "/" },
  { label: "Settings", url: "/settings" },
  { label: "Personal Information", url: "/personal-info" },
];

const PersonalInformation = () => {
  const dispatch = useDispatch();
  dispatch(setpaths(paths));
  dispatch(setheader("Personal Information"));
  dispatch(setSidebarimg(PersonalSide));

  const [userDetails, setUserDetails] = useState({});
  const [loading, setLoading] = useState(true);
  const [editableField, setEditableField] = useState(null);
  const [newValue, setnewValue] = useState("");
  const [TravelPopup, setTravelPopup] = useState(false);
  const [AllTravelDocuments, setAllTravelDocuments] = useState([]);
  const [EditTravel, setEditTravel] = useState(false);
  const [CurrentType, setCurrentType] = useState(false);
  const [travelDocumentInfo, setTravelDocuments] = useState({
    passportNumber: "",
    expirationDate: "",
    nationality: "",
  });

  const getUserInfo = async () => {
    try {
      const response = await httpRequest.get(GET_USER_INFO);
      setUserDetails(response?.data?.user || {});
      setCurrentType(response?.data?.AccType);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    getUserInfo();
  }, []);

  useEffect(() => {
    if (editableField) {
      setnewValue(userDetails[editableField] || "");
    }
  }, [editableField, userDetails]);

  const handleFieldEdit = (field) => {
    setEditableField(field);
  };

  const handleFieldChange = (field, newValue) => {
    setUserDetails((prevDetails) => ({
      ...prevDetails,
      [field]: newValue,
    }));
  };

  // Submittt Personal Information

  const handleSubmit = async () => {
    const updatedField = { [editableField]: newValue };
    setLoading(true);
    try {
      const response = await httpRequest.put(UPDATE_PROFILE_INFO, updatedField);

      if (response.status === 200 || response.status === 201) {
        toast.success(response?.data?.message);
        setLoading(false);
        setEditableField(null);
        const data = await FetchUserInfo();
        if (data) {
          dispatch(setUser(data));
        }
      }
    } catch (error) {
      console.error("Error updating user information:", error);
      toast.error(error?.response?.data?.message);
      setEditableField(null);
      setLoading(false);
    }
  };

  // Submittt Travel Document Information

  const handleSubmitTravel = async () => {
    if (travelDocumentInfo?.nationality === "") {
      return toast.error("Please Enter Nationality");
    }
    if (travelDocumentInfo?.passportNumber === "") {
      return toast.error("Please Enter Passport Number");
    }
    if (travelDocumentInfo?.expirationDate === "") {
      return toast.error("Please Enter Expiration Date");
    }

    let action;
    if (EditTravel) {
      action = httpRequest.put;
      travelDocumentInfo.travelInfoId = travelDocumentInfo._id;
    } else {
      action = httpRequest.post;
    }
    setLoading(true);
    try {
      const response = await action(TRAVEL_DOCUMENTS, travelDocumentInfo);

      if (response.status === 200 || response.status === 201) {
        toast.success("Success");
        setLoading(false);
        setTravelDocuments({
          passportNumber: "",
          expirationDate: "",
          nationality: "",
        });
        setTravelPopup(false);
        setEditTravel(false);
        FetchDocuments();
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
      setLoading(false);
    }
  };

  const handleChangeTravel = (e) => {
    const { name, value } = e.target;
    setTravelDocuments((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const FetchDocuments = async () => {
    try {
      const response = await httpRequest.get(TRAVEL_DOCUMENTS);

      if (response.status === 200 || response.status === 201) {
        setAllTravelDocuments(response?.data);
      }
    } catch (error) {
      toast.error("Error updating user information:", error);
    }
  };
  useEffect(() => {
    FetchDocuments();
  }, []);

  const HandleEditTravel = (doc) => {
    setTravelDocuments(doc);
    setTravelPopup(true);
    setEditTravel(true);
  };

  const handleClose = () => {
    setTravelPopup(false);
    setTravelDocuments({});
    setEditTravel(false);
  };

  return (
    <SettingsLayout>
      <div className="font-Montserrat">
        <h1 className="text-textcolor text-xl leading-normal font-semibold py-4">
          Personal Information
        </h1>

        {loading && <p>Loading...</p>}
        {!loading && Object.keys(userDetails).length > 0 && (
          <div className="bg-white sm:p-5  rounded-xl space-y-6 ShadowMain">
            {!loading && Object.keys(userDetails).length > 0 && (
              <div className="bg-white p-5 rounded-xl space-y-6 ShadowMain">
                {Object.entries(userDetails).map(
                  ([label, value], index, array) => {
                    // Capitalize the first letter of the label
                    const capitalizedLabel =
                      label.charAt(0).toUpperCase() + label.slice(1);

                    return (
                      <div
                        key={index}
                        className={`flex justify-between sm:flex-row flex-col ${
                          index === array.length - 1 ? "" : "border-b"
                        }  pb-2 sm:space-y-0 space-y-2`}
                      >
                        <div className="space-y-1">
                          <p className="text-black opacity-50 text-sm font-semibold leading-normal">
                            {capitalizedLabel === "Dob"
                              ? "Date of birth"
                              : capitalizedLabel === "Name"
                              ? "Full Name"
                              : capitalizedLabel === "Language"
                              ? "Preferred Language(s)"
                              : capitalizedLabel}
                          </p>
                          {Array.isArray(value) ? (
                            <h1 className="text-textcolor text-base leading-normal font-semibold">
                              {value.join(", ")}
                            </h1>
                          ) : (
                            <h1 className="text-textcolor text-base leading-normal font-semibold">
                              {value === "" ? "----" : value}
                            </h1>
                          )}
                        </div>
                        {label === "Email" && CurrentType ? null : (
                          <div
                            className="border border-[#007BFF4D] rounded-md sm:px-8 px-4 py-3 flex items-center justify-center space-x-4 cursor-pointer sm:w-max w-full"
                            onClick={() => handleFieldEdit(label)}
                          >
                            <img src={Edit} alt="change.svg" />
                            <span className="text-textcolor font-Montserrat text-sm font-semibold leading-normal">
                              Change
                            </span>
                          </div>
                        )}
                      </div>
                    );
                  }
                )}
              </div>
            )}
          </div>
        )}

        <div>
          <div className="flex items-center justify-between sm:flex-row flex-col py-4 sm:space-y-0 space-y-4">
            <h1 className="text-textcolor text-xl leading-normal font-semibold">
              Passport and Travel Document Information
            </h1>
            <button
              onClick={() => setTravelPopup(true)}
              className="px-4 py-2 mr-2 text-white sm:w-max w-full bg-blue-500 rounded-md hover:bg-blue-600 focus:outline-none focus:bg-blue-600"
            >
              + Add
            </button>
          </div>
          <div className="bg-white p-5 rounded-xl space-y-6 ShadowMain">
            {AllTravelDocuments.length > 0
              ? AllTravelDocuments.map((doc) => {
                  return (
                    <div
                      key={doc?._id}
                      className="grid grid-cols-6 gap-4 border-b sm:py-2 py-4"
                    >
                      <div className="sm:col-span-4 col-span-6 grid sm:grid-cols-3 grid-cols-1 gap-4">
                        <div>
                          <div className="space-y-1">
                            <p className="text-black opacity-50 text-sm font-semibold leading-normal">
                              Passport Number
                            </p>
                            <h1 className="text-textcolor text-base leading-normal font-semibold">
                              {doc?.passportNumber}
                            </h1>
                          </div>
                        </div>
                        <div className="space-y-1">
                          <p className="text-black opacity-50 text-sm font-semibold leading-normal">
                            Expiration Date
                          </p>
                          <h1 className="text-textcolor text-base leading-normal font-semibold">
                            {dateFormatter(doc?.expirationDate)}
                          </h1>
                        </div>
                        <div className="space-y-1">
                          <p className="text-black opacity-50 text-sm font-semibold leading-normal">
                            Nationality
                          </p>
                          <h1 className="text-textcolor text-base leading-normal font-semibold">
                            {doc?.nationality}
                          </h1>
                        </div>
                      </div>
                      <div className="sm:col-span-2 col-span-6 flex justify-end">
                        <div
                          onClick={() => HandleEditTravel(doc)}
                          className="border border-[#007BFF4D] rounded-md sm:px-8 px-4 py-3 flex items-center justify-center space-x-4 cursor-pointer sm:w-max w-full"
                        >
                          <img src={Edit} alt="change.svg" />
                          <span className="text-textcolor font-Montserrat text-sm font-semibold leading-normal">
                            Change
                          </span>
                        </div>
                      </div>
                    </div>
                  );
                })
              : "No record Found"}
          </div>
        </div>

        {/* Popup For Changing Personal Information */}
        <InfoPopup
          isOpen={!!editableField}
          onClose={() => setEditableField(null)}
          label={editableField}
          loading={loading}
          setnewValue={setnewValue}
          initialValue={userDetails[editableField]}
          onChange={(newValue) => handleFieldChange(editableField, newValue)}
          onSubmit={handleSubmit}
        />

        <UploadTravelDocuments
          isOpen={TravelPopup}
          handleClose={handleClose}
          loading={loading}
          initialvalues={travelDocumentInfo}
          onSubmit={handleSubmitTravel}
          onchange={handleChangeTravel}
        />
      </div>
    </SettingsLayout>
  );
};

export default PersonalInformation;
