import React, { useEffect, useState } from "react";
import FavoritesLayout from "../../layouts/FavLayout";
import { useDispatch } from "react-redux";
import {
  setheader,
  SetHotelBooked,
  setpaths,
  setSidebarimg,
} from "../../lib/Redux/slices/webData";
import { Hotel, Location, Ratting, Cup, AccomodationS } from "../../assets";
import httpRequest from "../../axios";
import { Booking } from "../../constants/apiEndPoints";
  import { toast } from 'react-toastify';
import { useNavigate } from "react-router-dom";

const paths = [
  { label: "MyGuide", url: "/user-profile" },
  { label: "My Odyssey", url: "/" },
  { label: "Settings", url: "/settings" },
  { label: "Favorites", url: "/settings/favroutes/flights" },
];

const FavroutesAccomonationsN = () => {
  const dispatch = useDispatch();
  dispatch(setpaths(paths));
  dispatch(setheader("Bookings"));
  dispatch(setSidebarimg(AccomodationS));
  const [Data, setData] = useState([]);
  const navigate = useNavigate();

  const GetData = async () => {
    try {
      const response = await httpRequest.get(`${Booking}/hotel`);
      if (response.status === 200 || response.status === 201) {
        setData(response?.data);
        dispatch(SetHotelBooked(response?.data?.length));
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  };

  useEffect(() => {
    GetData();
  }, []);

  return (
    <FavoritesLayout>
      {Data.length > 0 ? (
        Data.map((data, i) => {
          return (
            <div className="w-full font-Montserrat">
              <div className="">
                <div className="grid md:grid-cols-3 grid-cols-1 rounded font-Montserrat gap-3 shadow-sm bg-white  mt-6">
                  <div className="relative">
                    <img
                      className="h-full w-full object-cover"
                      src={Hotel}
                      alt="First Hotel image"
                    />
                    <span className="text-[12px] absolute top-3 right-7 bg-white bg-opacity-50 px-2 py-1 rounded-[10px]">
                      9 images
                    </span>
                  </div>

                  <div className="col-span-2 p-4 flex flex-col justify-between ">
                    <div className="grid md:grid-cols-3 grid-cols-1 font-Montserrat gap-3 pb-3 w-full border-b">
                      <div className="col-span-2 ">
                        <p className="font-semibold pb-2 text-[18px] md:text-[20px]">
                          {data?.bookingInfo?.hotelName}
                        </p>
                        <div className="flex gap-2 pb-2">
                          <img src={Location} alt="Loction icon" />
                          <p className="text-[12px] md:text-[14px]">
                            {data?.bookingInfo?.hotelAddress}
                          </p>
                        </div>
                        <div className="flex gap-2 pb-2">
                          <img src={Ratting} alt="Ratting star" />
                          <p className="font-medium text-[12px] md:text-[14px]">
                            5 Star Hotel
                          </p>
                        </div>
                        <div className="flex gap-2 pb-2">
                          <img src={Cup} alt="cup icon" />
                          <p className="font-medium text-[12px] md:text-[14px]">
                            {" "}
                            <span className="font-semibold">
                              20+
                            </span> Aminities{" "}
                          </p>
                        </div>
                        {/* <div className="flex items-center gap-2 text-[12px] md:text-[14px]">
                          <button class=" text-black font-normal py-1 px-2 border border-[#8DD3BB] rounded-[0.25rem]">
                            4.2
                          </button>
                          <p className="font-medium">
                            <span className="font-semibold ">Very Good </span>{" "}
                            371 reviews
                          </p>
                        </div> */}
                      </div>
                      <div className="text-[#FF8682] md:text-right text-[12px] md:text-[14px]">
                        <p className=" text-black">Starting from</p>
                        <p className="text-[24px] font-bold">
                          {data?.paymentAmount}
                          {/* <span className="text-[12px] h-1">/night</span> */}
                        </p>
                        <p className="text-black font-[12px] ">excl.tax</p>
                      </div>
                    </div>
                    <div className="flex gap-5 pt-3 text-[12px] md:text-[14px]">
                      <button class=" text-black font-normal py-1 px-2 border border-[#8DD3BB] rounded-[0.25rem]">
                        🖤
                      </button>
                      <button
                        onClick={() => navigate(`/hotels/ticket/${data?._id}`)}
                        className="bg-blue-500 hover:bg-blue-400 transition-colors duration-300 text-white py-2 w-full rounded-md"
                      >
                        View Place
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })
      ) : (
        <h1 className="p-10">No Data Found</h1>
      )}
    </FavoritesLayout>
  );
};

export default FavroutesAccomonationsN;
