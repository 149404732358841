import React, { useEffect, useState } from "react";
import {
  BudgetDest,
  Dollers,
  FoodLogo,
  LanguageDest,
  MoneyLogo,
  SecurityLogo,
} from "../../../assets";
import { BsPersonGear } from "react-icons/bs";
import { useSelector } from "react-redux";

const DetailsAndRatings = ({ showModal = {} }) => {
  const { CurrentDestination = null } = useSelector((state) => state.web);
  const WeatherDetails = CurrentDestination?.weather || null;
  const [finalRating, setFinalRating] = useState(0);

  useEffect(() => {
    let TotalRating =
      Number(CurrentDestination?.destinationRating || 0) +
      Number(CurrentDestination?.foodRating || 0) +
      Number(CurrentDestination?.priceRating || 0) +
      Number(CurrentDestination?.securityRating || 0);

    let calculatedFinalRating = TotalRating / 4;
    setFinalRating(calculatedFinalRating);
  }, [CurrentDestination]);

  const Castle = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
    >
      <g clip-path="url(#clip0_5037_54075)">
        <path
          d="M25.9094 13.4687L23.0844 7.81653C22.772 7.19162 21.8802 7.19162 21.5678 7.81653L18.7428 13.4687C18.4609 14.0325 18.8709 14.6957 19.5012 14.6957H19.7827V16.3913H16.9566V11.3044H17.8456C18.4759 11.3044 18.8858 10.6411 18.604 10.0774L13.7584 0.468686C13.4459 -0.156229 12.5542 -0.156229 12.2417 0.468686L7.3962 10.0774C7.11427 10.6411 7.52422 11.3043 8.15452 11.3043H9.04355V16.3913H6.21747V14.6957H6.49895C7.12925 14.6957 7.5391 14.0324 7.25732 13.4687L4.4322 7.81653C4.1198 7.19162 3.22803 7.19162 2.91562 7.81653L0.0906102 13.4687C-0.191277 14.0325 0.218681 14.6957 0.848977 14.6957H1.13046V25.1522C1.13046 25.6204 1.51005 26 1.9783 26H5.08698H6.21742H9.0435V23.2236C9.0435 21.042 10.7884 19.2337 12.97 19.2175C15.1689 19.2012 16.9565 20.9788 16.9565 23.1739V26H19.7826H24.0217C24.4899 26 24.8696 25.6204 24.8696 25.1522V14.6957H25.1511C25.7814 14.6957 26.1913 14.0325 25.9094 13.4687Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_5037_54075">
          <rect width="26" height="26" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );

  return (
    <>
      <div className="overflow-x-auto h-[80vh] flex flex-col pt-[20px] font-Montserrat ">
        <div
          className="grid grid-cols-11 gap-3"
          style={{ height: "max-content" }}
        >
          <div className=" col-span-11 md:col-span-3 lg:col-span-5 xl:cols-span-5 ">
            <img
              src={
                CurrentDestination?.images?.length &&
                CurrentDestination?.images[0]
              }
              className="md:h-[335px] w-[100%] rounded-xl "
              alt=""
            />
          </div>
          {/* /// */}
          <div className=" col-span-11 md:col-span-8 lg:col-span-6 xl:cols-span-6  flex flex-col">
            <div className="grid grid-cols-4 gap-3">
              {CurrentDestination?.images?.length &&
                CurrentDestination.images.slice(0, 8).map((items, index) => {
                  return (
                    <div
                      key={index}
                      className="col-span-4 md:col-span-1 flex justify-center md:justify-start"
                    >
                      <img
                        src={items}
                        className="mt-[10px] md:mt-[0px] md:h-[160px] w-[93%] rounded-xl"
                      />
                    </div>
                  );
                })}
            </div>
          </div>
          {/* /// */}
        </div>
        {/* //// */}

        <div className="grid grid-cols-6 mt-[32px]">
          <div className=" col-span-6 lg:col-span-4 flex flex-col  ">
            <h1 className="text-[#394149] text-[30px] font-bold text-left ">
              {CurrentDestination?.name}
            </h1>
            <div className="flex sm:justify-start sm:space-x-4 justify-between flex-wrap col-span-6 w-full">
              <div className="rounded-2xl lg:w-max sm:w-[47%] w-full bg-[#F2ECDB] flex p-[15px] mt-[32px]">
                <div className="w-[30px] flex justify-center items-center ">
                  <img src={Dollers} className="w-[30px]" />
                </div>
                <div className="flex flex-col ml-[15px] ">
                  <p className="text-[#7F7C71] text-left text-[14px]">
                    Currencies
                  </p>
                  {CurrentDestination?.currencies && (
                    <h1 className="text-[#000] text-[12px] font-bold">
                      {CurrentDestination?.currencies.join(", ")}
                    </h1>
                  )}
                </div>
              </div>
              <div className="rounded-2xl bg-[#F2ECDB] lg:w-max sm:w-[47%] w-full flex p-[15px] mt-[32px]  ">
                <div className="w-[30px]  flex justify-center items-center ">
                  <img src={LanguageDest} className="w-[30px]" />
                </div>
                <div className="flex flex-col ml-[15px] ">
                  <p className="text-[#7F7C71] text-left text-[14px]">
                    Languages
                  </p>
                  {CurrentDestination?.languages && (
                    <h1 className="text-[#000] text-[12px] font-bold">
                      {CurrentDestination?.languages.join(", ")}
                    </h1>
                  )}
                </div>
              </div>
              <div className="rounded-2xl bg-[#F2ECDB] lg:w-max sm:w-[47%] w-full flex p-[15px] mt-[32px]  ">
                <div className="w-[30px]  flex justify-center items-center ">
                  <img src={BudgetDest} className="w-[30px]" />
                </div>
                <div className="flex flex-col ml-[15px] ">
                  <p className="text-[#7F7C71] text-left text-[14px]">
                    Budget Required for a Trip
                  </p>
                  {CurrentDestination?.budget && (
                    <h1 className="text-[#000] text-[12px] font-bold">
                      ${CurrentDestination?.budget} for a every week spent
                    </h1>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className=" col-span-6 lg:col-span-2  flex justify-start lg:justify-end  ">
            <div className="rounded-[40px] mt-[25px] lg:mt-[0px] h-[145px] lg:w-[350px] sm:w-[70%] w-full bg-[#FFC502] flex flex-col overflow-hidden ">
              <div className="flex h-[50%] px-[24px] w-full items-center pt-[0px] ">
                <BsPersonGear style={{ color: "#025F80", fontSize: "40px" }} />
                <p className="text-[#025F80] font-bold text-[18px] ml-[8px] ">
                  Overall Rating
                </p>
                <h1 className="text-[#025F80] font-extrabold text-[40px] ml-[14px] ">
                  {finalRating || 0}/10
                </h1>
              </div>

              <div
                className="bg-[#FFD64F] border border-[#F1E2AF] w-[90%] ml-[5%] px-[40px] pt-[6px] h-[50%] "
                style={{ borderRadius: "40px 40px 0px 0px" }}
              >
                <div className="flex justify-between">
                  <div className="flex justify-center ">
                    <img src={MoneyLogo} alt="" />
                    <div className="text-white ml-[8px] mt-[5px] ">
                      {CurrentDestination?.priceRating}/10
                    </div>
                  </div>
                  <div className="flex">
                    {Castle}
                    <div className="text-white ml-[8px] mt-[5px]  ">
                      {CurrentDestination?.destinationRating}/10
                    </div>
                  </div>
                </div>
                <div className="flex justify-between mt-[5px]">
                  <div className="flex">
                    <img src={FoodLogo} alt="" />

                    <div className="text-white ml-[5px] mt-[5px]  ">
                      {CurrentDestination?.foodRating}/10
                    </div>
                  </div>
                  <div className="flex">
                    <img src={SecurityLogo} alt="" />

                    <div className="text-white ml-[5px] mt-[5px]  ">
                      {CurrentDestination?.securityRating}/10
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {WeatherDetails !== null && (
          <div className="w-full p-6 my-4 WeatherCls flex sm:flex-row flex-col-reverse justify-between items-center rounded-lg shadow-lg">
            <div className="text-white flex flex-col items-start">
              <h1 className="sm:text-xl text-sm font-semibold mb-2">
                City Name:{" "}
                <span className="font-bold">{WeatherDetails?.name}</span>
              </h1>
              <h1 className="sm:text-xl text-sm font-semibold mb-2">
                Temperature:{" "}
                <span className="font-bold">
                  {Math.round(WeatherDetails?.main?.temp - 273)}°C
                </span>
              </h1>
              <div className="flex items-center sm:text-xl text-sm font-semibold mb-2">
                Description:
                <span className="font-bold ml-1">
                  {WeatherDetails?.weather[0]?.description}
                </span>
              </div>
              <h1 className="sm:text-xl text-sm font-semibold">
                Wind Speed:{" "}
                <span className="font-bold">
                  {WeatherDetails?.wind?.speed} km/h
                </span>
              </h1>
            </div>

            <img
              src={`https://openweathermap.org/img/wn/${WeatherDetails?.weather[0]?.icon}@2x.png`}
              alt="weatherImg"
              className="h-auto w-[150px]"
            />
          </div>
        )}

        <div className="flex justify-end mt-2">
          <button
            onClick={() => showModal("Step2")}
            className="py-2 px-4 rounded-2xl bg-[#FFC502] text-[#025F80] font-bold text-[18px] w-max"
          >
            Continue
          </button>
        </div>
      </div>
    </>
  );
};

export default DetailsAndRatings;
