import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  allHotels: [],
  selectedHotels: {},
  HoteslCartData: [],
};

const hotelSlice = createSlice({
  name: "hotels_data",
  initialState,
  reducers: {
    setHotels: (state, action) => {
      state.allHotels = action.payload;
    },
    setSelectedHotels: (state, action) => {
      state.selectedHotels = action.payload;
    },
    setHotelsCartData: (state, action) => {
      state.HoteslCartData = action.payload;
    },
  },
});

export const { setHotels, setSelectedHotels, setHotelsCartData } =
  hotelSlice.actions;
export default hotelSlice.reducer;
