import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Loader } from "../common";

const Cart = ({
  isFlight,
  fun = () => {},
  loading = false,
  setGrandsTotal = () => {},
  GrandsTotal,
}) => {
  const { user } = useSelector((state) => state.user);
  const selectedFlight = useSelector((state) => state.flight.selectedFlight);
  const dictionaries = useSelector((state) => state.flight.dictionaries);

  const { total, FlightBasicPrices = [] } = useSelector((state) =>
    isFlight ? state.cart : state.hotel
  );
  const mergedArray = [...FlightBasicPrices, ...total];

  useEffect(() => {
    const grandTotal = mergedArray.reduce((acc, item) => {
      const price = Number(item.price) || 0;
      return acc + price;
    }, 0);

    setGrandsTotal(grandTotal);
  }, [mergedArray]);

  return (
    <div className="w-full font-Montserrat">
      <div className="w-full bg-white shadow 2xl:p-6 rounded-md">
        <div className="flex sm:flex-row flex-col pb-4 border-b">
          <div className="2xl:h-[120px] 2xl:w-[120px] lg:h-[150px] lg:w-[200px] w-full border rounded-md overflow-hidden">
            <img
              className="h-full w-full object-cover"
              src={
                "https://static.ffx.io/images/$zoom_1%2C$multiply_0.5141%2C$ratio_1.777778%2C$width_1992%2C$x_0%2C$y_208/t_crop_custom/q_86%2Cf_auto/74e96500a9e08cd4166a1416e5b41eecc2ecd518"
              }
              alt="EmertiesPlane.svg"
            />
          </div>
          <div className="2xl:p-4 lg:p-2 p-4 space-y-4">
            <div>
              <h1 className="sm:text-xl lg:text-sm text-base font-semibold text-BlackishGreen">
                {
                  dictionaries?.carriers[
                    selectedFlight.validatingAirlineCodes[0]
                  ]
                }{" "}
                {
                  dictionaries?.aircraft[
                    selectedFlight.itineraries[0]?.segments[0]?.aircraft?.code
                  ]
                }
              </h1>
            </div>
            <div className="flex 2xl:flex-row lg:flex-col flex-row items-center space-x-1">
              <div className="flex items-center justify-center h-[32px] 2xl:w-[40px] lg:w-full w-[40px] border border-[#8DD3BB] rounded cursor-pointer">
                4.2
              </div>
              <p className="text-xs font-bold text-BlackishGreen">
                Very Good <span className="font-medium">54 reviews</span>
              </p>
            </div>
          </div>
        </div>
        <div className="py-4 border-b">
          <p className="text-BlackishGreen sm:text-base text-sm font-medium leading-normal">
            Your booking is protected by{" "}
            <span className="font-bold">guidone</span>
          </p>
        </div>
        <h1 className="sm:text-base text-sm font-bold leading-normal text-BlackishGreen">
          Price Details
        </h1>
        <div className="max-h-[250px] overflow-auto ">
          {mergedArray.map((items) => {
            const { title, price, qty } = items;
            return (
              <>
                <div className="py-4 border-b space-y-4">
                  <div className="flex justify-between">
                    <span className="sm:text-base text-sm font-medium leading-normal">
                      {title}
                    </span>
                    <span>
                      <span className="text-BlackishGreen sm:text-base text-sm font-semibold leading-normal">
                        ${Math.round(price)}
                      </span>
                    </span>
                  </div>
                </div>
              </>
            );
          })}
        </div>
        <div className="pt-4">
          <div className="flex justify-between">
            <span className="sm:text-base text-sm font-medium leading-normal">
              Total
            </span>
            <span>
              ${" "}
              <span className="text-BlackishGreen sm:text-base text-sm font-semibold leading-normal">
                {Math.round(GrandsTotal)}
              </span>
            </span>
          </div>
        </div>
      </div>
      {user && Object.keys(user).length !== 0 && (
        <div className="py-4">
          <button
            style={{ padding: "8px 16px" }}
            onClick={fun}
            className="w-full h-[48px] bg-primary rounded-md hover:bg-blue-800 text-white text-sm font-semibold"
          >
            {loading ? <Loader /> : "Continue"}
          </button>
        </div>
      )}
    </div>
  );
};

export default Cart;
