import React from "react";
import Imagescard from "../images/Imagescard";
import Divider from "../divider/Divider";
import { useNavigate } from "react-router-dom";
import { rectangle } from "../../assets";
const HorizantalCard = ({ item, bookNow }) => {
  const navigate = useNavigate();
  console.log(item, "item");
  return (
    <>
      <div className="grid grid-cols-1 md:grid-cols-6 font-Montserrat">
        <div className=" flex items-center md:col-span-6 lg:col-span-4 xl:col-span-4">
          <Imagescard h={"50"} w={"50"} img={rectangle} />
          <h1 className="text-base font-medium ml-4 text-BlackishGreen ">
            {item?.room?.description?.text}
          </h1>
        </div>
        <div className="flex justify-end items-center md:col-span-6 lg:col-span-2 xl:col-span-2 space-x-16">
          <div className="text-2xl font-semibold w-[225px] flex justify-end ">
            {" "}
            {item?.price?.currency} {item?.price?.total}
            {/* <span className="text-sm">/night</span> */}
          </div>
          <button
            onClick={bookNow}
            className="bg-[#007BFF] hover:bg-blue-800  text-sm text-white rounded-lg  w-[150px] h-[45px]  "
          >
            Book now
          </button>
        </div>
      </div>
      <Divider color={"#c3c8c3"} />
    </>
  );
};

export default HorizantalCard;
