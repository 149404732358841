import React, { useEffect, useState } from "react";
import SettingsLayout from "../../layouts/settingslayout";
import { useDispatch } from "react-redux";
import {
  setSidebarimg,
  setheader,
  setpaths,
} from "../../lib/Redux/slices/webData";
import { PaymentSide } from "../../assets";

import Cards from "./Cards";
import AddCards from "./AddCards";
import { CARDS } from "../../constants/apiEndPoints";
import httpRequest from "../../axios";
  import { toast } from 'react-toastify';
import Vouchers from "./Vouchers";

const paths = [
  { label: "MyGuide", url: "/user-profile" },
  { label: "My Odyssey", url: "/" },
  { label: "Settings", url: "/settings" },
  { label: "Payments & Vouchers", url: "/payment-vouchers" },
];

const PaymentVouchers = () => {
  const dispatch = useDispatch();
  dispatch(setpaths(paths));
  dispatch(setheader("Payments & Vouchers"));
  dispatch(setSidebarimg(PaymentSide));

  //=========
  const [AddCartActive, setAddCartActive] = useState(false);
  const [AllCards, setAllCards] = useState([]);
  const [loading, setLoading] = useState(false);

  const FetchCards = async () => {
    setLoading(true);
    try {
      const response = await httpRequest.get(CARDS);
      if (response.status === 200 || response.status === 201) {
        setAllCards(response?.data?.cards);
        setLoading(false);
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
      setLoading(false);
    }
  };

  
  useEffect(() => {
    FetchCards();
  }, []);

  return (
    <SettingsLayout>
      <div className="font-Montserrat">
        <h1 className="text-textcolor text-xl leading-normal font-semibold">
          Payment & Vouchers
        </h1>
        {loading && "Loading..."}
        {!AddCartActive && (
          <Cards
            data={AllCards}
            loading={loading}
            setAddCartActive={setAddCartActive}
            FetchCards={FetchCards}
          />
        )}
        {AddCartActive && (
          <AddCards
            setAddCartActive={setAddCartActive}
            FetchCards={FetchCards}
          />
        )}
        <div className="my-8 py-8">
          <h1 className="text-textcolor text-base font-semibold leading-normal">
            Available Vouchers
          </h1>
          <Vouchers />
        </div>
      </div>
    </SettingsLayout>
  );
};

export default PaymentVouchers;
