import React, { useState } from "react";
import { useDispatch } from "react-redux";
import {
  setheader,
  setpaths,
  setSidebarimg,
} from "../../lib/Redux/slices/webData";
import MainLayout from "../../layouts/Mainlayout";
import { SUBside, Planes, BackgroundS, BackgroundS1 , CrownPic } from "../../assets";

const paths = [
  { label: "MyGuide", url: "/user-profile" },
  { label: "My Odyssey", url: "/" },
  { label: "Settings", url: "/settings" },
  { label: "Bookings", url: "/settings/favorites" },
  { label: "My Subscription Status", url: "/settings/Subscription" },
];

const Subscription = () => {
  const dispatch = useDispatch();
  dispatch(setpaths(paths));
  dispatch(setheader("Bookings"));
  dispatch(setSidebarimg(SUBside));

  const [Catagory, setCatagory] = useState("Cheapest");

  return (
    <MainLayout>
      <div className="font-semibold	text-xl pb-4  font-Montserrat">
        <p>My Subscription Status</p>
      </div>
      <div className="bg-white rounded border  font-Montserrat relative">
      <img className=" absolute bottom-0" src={BackgroundS} alt="" />
      <img className=" absolute left-72 bottom-0" src={BackgroundS1} alt="" />
        <div className="grid grid-cols-1 md:grid-cols-4  gap-3 p-4">
          <div>
            <p className="text-sm font-semibold opacity-[.6] pb-3">Your Current Status</p>
            <span className="text-md font-semibold">Free</span>
          </div>
          <div className="col-span-2 flex flex-col">
            <p className="text-sm font-semibold opacity-[.6] pb-3">Validilty</p>
            <span className="text-md font-semibold">Lifetime</span>
            <div className="pt-8">
              <button className="text-xl font-normal bg-[#FFC107] py-3 rounded w-[90%]">Upgrade to Pro</button>
            </div>
          </div>
          <div>
            <img src={Planes} alt="Crown Pic" />
          </div>
        </div>
      </div>
    </MainLayout>
  );
};

export default Subscription;
