import React from "react";

const Header = ({ header = "" }) => {
  return (
    <div className="font-Montserrat">
      <h1 className="text-black text-[28px] font-semibold leading-normal">
        {header}
      </h1>
    </div>
  );
};

export default Header;
