import React from "react";
function convertToTitleCase(str) {
  return str.replace(/([A-Z])/g, " $1").replace(/^./, function (s) {
    return s.toUpperCase();
  });
}
const ReviewBox = ({ mainheading, icon, heading, reviews, bg, ml, border }) => {
  return (
    <div
      style={{
        backgroundColor: bg ? bg : "white",
        border: `solid 1px ${border} `,
      }}
      className={` mt-[20px] rounded-xl h-[145px] flex flex-col justify-between border ml-[${ml}] mt-40px  `}
    >
      {icon != null ? (
        <img className="h-[40px] w-[40px] ml-[16px] mt-[16px]" src={icon} />
      ) : (
        <h1
          className={` text-[32px] font-bold ml-[16px] mt-[16px] ${
            bg ? " text-white" : "text-black"
          }`}
        >
          {mainheading}
        </h1>
      )}
      <div className="w-[166px] rounded-lg  flex flex-col justify-start mb-[16px] ">
        <div
          className={`text-[16px] font-bold ml-[16px] capitalize ${
            bg ? "text-white" : "text-black"
          }`}
        >
          {heading && convertToTitleCase(heading)}
        </div>
        {reviews && (
          <div
            className={`text-[14px] font-medium ml-[16px] ${
              bg === "white" ? "text-black" : "text-white"
            } `}
          >
            {" "}
            {reviews} Reviws
          </div>
        )}
      </div>
    </div>
  );
};

export default ReviewBox;
