import React, { useState } from "react";
import { UploadAssests } from "../UploadAssests";
  import { toast } from 'react-toastify';
import httpRequest from "../../../axios";
import {
  GET_USER_INFORMATION,
  UPDATE_PROFILE_INFO,
} from "../../../constants/apiEndPoints";
import { useDispatch } from "react-redux";
import { setUser } from "../../../lib/Redux/slices/userslice";
import { FetchUserInfo } from "../../../constants/apiEndPoints/functions";

const UploadImage = ({ show = false, onClose = () => {} }) => {
  const [coverPicture, setCoverPicture] = useState(null);
  const [Loading, setLoading] = useState(false);
  const [selectedFile, setselectedFile] = useState(null);
  const dispatch = useDispatch();

  const handleCoverUpload = (event) => {
    const file = event.target.files[0];
    setselectedFile(event.target.files[0]);
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setCoverPicture(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSubmitt = async () => {
    if (selectedFile === null) {
      return toast.error("Please Upload Image");
    }

    setLoading(true);
    try {
      const Image = await UploadAssests(selectedFile);
      let payload = {
        coverPicture: Image,
      };
      const response = await httpRequest.put(UPDATE_PROFILE_INFO, payload);

      if (response.status === 200 || response.status === 201) {
        toast.success(response?.data?.message);
        handleCancel();
        setLoading(false);
        const data = await FetchUserInfo();
        if (data) {
          dispatch(setUser(data));
        }
      }
    } catch (error) {
      console.error("Error", error);
      setLoading(false);
    }
  };

  const handleCancel = () => {
    onClose(false);
    setCoverPicture(null);
    setselectedFile(null);
  };

  return (
    show && (
      <div className="fixed top-0 left-0 w-full h-full bg-gray-700 bg-opacity-50 flex justify-center items-center z-[100]">
        <div className="bg-white p-8 rounded-md max-h-[90vh]">
          <h2 className="text-lg font-semibold mb-4">Upload Picture</h2>
          <input type="file" accept="image/*" onChange={handleCoverUpload} />
          {coverPicture && (
            <div className="mt-4">
              <h3 className="text-lg font-semibold mb-2">Preview:</h3>
              <img
                src={coverPicture}
                alt="Cover Preview"
                className="h-auto w-1/3 rounded-md"
              />
            </div>
          )}
          <button
            disabled={Loading}
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-4 mr-2"
            onClick={handleSubmitt}
          >
            {Loading ? "Uploading..." : "Upload"}
          </button>
          <button
            className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded mt-4"
            onClick={handleCancel}
          >
            Cancel
          </button>
        </div>
      </div>
    )
  );
};

export default UploadImage;
