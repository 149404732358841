import React from "react";
import { Link } from "react-router-dom";
import { MdKeyboardArrowRight } from "react-icons/md";

const BreadcrumbArrow = ({ items }) => {
  return (
    <nav aria-label="breadcrumb">
      <ol className="flex items-center space-x-2 sm:text-sm text-xs">
        {items.map((item, index) => (
          <li
            key={index}
            className={`flex items-center cursor-pointer ${
              index === items.length - 1
                ? "text-BlackishGreen  opacity-40"
                : "text-BlackishGreen"
            }`}
          >
            <Link to={item?.url}>
              <span className="mr-1">{item.label}</span>
            </Link>
            {index !== items.length - 1 && (
              <span className="text-gray-500">
                <MdKeyboardArrowRight className="text-BlackishGreen" />
              </span>
            )}
          </li>
        ))}
      </ol>
    </nav>
  );
};

export default BreadcrumbArrow;
