import React from "react";

const Imagescard = ({ h, w, img, isFotter }) => {
  return (
    <>
      <img
        src={img}
        className={`w-[${w}px] h-[${h}px] ${isFotter ? "hidden" : "block"} md:${
          isFotter ? "hidden" : "block"
        } sm:${isFotter ? "hidden" : "block"} xm:${
          isFotter ? "hidden" : "block"
        } lg:block`}
      />
    </>
  );
};

export default Imagescard;
