import React, { useState, useEffect, useRef, useCallback } from "react";
import { Avtar, Logo } from "../../assets";
import { Links } from "./Links";
import { HiBell } from "react-icons/hi";
import { FaBars } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import ResponsiveNavbar from "./Responsive";
import Search from "../Search";
import { FiUser, FiLogOut } from "react-icons/fi";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { clearUser } from "../../lib/Redux/slices/userslice";
import { GET_LOCATION, SEARCH_DESTINATION } from "../../constants/apiEndPoints";
import httpRequest from "../../axios";
import { SetCurrentDestINd } from "../../lib/Redux/slices/webData";
import { LuSearch } from "react-icons/lu";
import { FaGear } from "react-icons/fa6";
import { IoMdHeart } from "react-icons/io";
import debounce from "lodash.debounce";

const Navbar = () => {
  const location = useLocation();
  const { user } = useSelector((state) => state.user);
  // const { SearchVal = "" } = useSelector((state) => state.web);
  const [responsiveNavOpen, setResponsiveNavOpen] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const dropdownRef = useRef(null);
  const [SearchDestination, setSearchDestination] = useState([]);
  const [SearchVal, setSearchVal] = useState("");

  const handleSearchDest = async (e) => {
    if (SearchVal === "") return;
    const payload = {
      address: SearchVal,
    };
    try {
      const response = await httpRequest.post(GET_LOCATION, payload);

      if (response.status === 200 || response.status === 201) {
        console.log(response?.data);
        setSearchDestination(response?.data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  // Create a debounced version of the fethCoordinates function
  const debouncedFetchCoordinates = useCallback(
    debounce(() => {
      handleSearchDest();
    }, 500),
    [SearchVal]
  );

  useEffect(() => {
    if (SearchVal !== "") {
      debouncedFetchCoordinates();
    }
    return () => {
      debouncedFetchCoordinates.cancel();
    };
  }, [SearchVal, debouncedFetchCoordinates]);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 1000) {
        setResponsiveNavOpen(false);
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const closeResponsiveNav = () => {
    setResponsiveNavOpen(false);
  };

  const toggleResponsiveNav = () => {
    setResponsiveNavOpen((prevState) => !prevState);
  };

  const handleLogout = () => {
    dispatch(clearUser());
    localStorage.clear();
    navigate("/");
  };

  const handleDropdownToggle = () => {
    setDropdownOpen((prevOpen) => !prevOpen);
  };

  const handleDropdownClose = (e) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(e.target) &&
      !e.target.closest(".dropdown-menu")
    ) {
      setDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleDropdownClose);

    return () => {
      document.removeEventListener("mousedown", handleDropdownClose);
    };
  }, []);

  const handleProgreess = (e) => {
    navigate(`${e}`);
  };

  const handlemapDesSerch = (item) => {
    dispatch(SetCurrentDestINd([item?.lat, item?.lon]));
    setResponsiveNavOpen(false);
    setSearchVal("");
  };

  return (
    <div className="w-full py-2 bg-white">
      <div className="w-[95%] m-auto grid grid-cols-2">
        <div className="grid lg:grid-cols-2">
          <div className="cursor-pointer" onClick={() => handleProgreess("/")}>
            <img src={Logo} alt="Logo.svg" />
          </div>
          <div className="lg:grid grid-cols-3 hidden">
            {Links &&
              Links.map((item) => {
                return (
                  <div
                    key={item?.id}
                    onClick={() => handleProgreess(item?.link)}
                    className={`flex flex-col items-center justify-center cursor-pointer rounded-md ${
                      location.pathname === item?.link
                        ? "border-2 border-primary bg-[#007bff59]"
                        : ""
                    } `}
                  >
                    <img
                      src={
                        location.pathname === item?.link
                          ? item?.active
                          : item?.icon
                      }
                      alt="messages.svg"
                    />
                    <span
                      className={`${
                        location.pathname === item?.link
                          ? "text-primary"
                          : "text-[#999999]"
                      } text-[13px] leading-normal font-medium `}
                    >
                      {item?.title}
                    </span>
                  </div>
                );
              })}
          </div>
        </div>
        <div className="grid grid-cols-5">
          <div className="col-span-5 flex justify-end items-center">
            <FaBars
              onClick={toggleResponsiveNav}
              className="text-xl text-textcolor cursor-pointer lg:hidden"
            />
            <div className="w-[90%] lg:grid grid-cols-6 hidden">
              <div className="relative col-span-4">
                <div className="border flex items-center justify-between rounded-lg">
                  <input
                    type="text"
                    onClick={() => navigate("/")}
                    onChange={(e) => setSearchVal(e.target.value)}
                    value={SearchVal}
                    placeholder="Search your dream destination, activities ..."
                    className="p-4 w-[90%] text-[#00000083] outline-none placeholder:text-[#00000080] text-sm font-semibold leading-normal"
                  />
                  <LuSearch className="text-2xl text-[#0000008a] mx-4 cursor-pointer" />
                </div>
                {SearchVal !== "" && (
                  <div className="w-full z-40 bg-white shadow absolute left-0 top-10">
                    <ul class="bg-white border border-gray-100 w-full mt-2 ">
                      {SearchDestination.length > 0 &&
                        SearchDestination.map((item, index) => {
                          return (
                            <li
                              key={index}
                              onClick={() => handlemapDesSerch(item)}
                              class="pl-8 pr-2 py-1 border-b-2 border-gray-100 relative cursor-pointer hover:bg-yellow-50 hover:text-gray-900"
                            >
                              <svg
                                class="stroke-current absolute w-4 h-4 left-2 top-2"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                              >
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  stroke-width="2"
                                  d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
                                />
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  stroke-width="2"
                                  d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
                                />
                              </svg>
                              <b>{item?.display_name}</b>
                            </li>
                          );
                        })}
                    </ul>
                    {SearchDestination.length === 0 && (
                      <p className="text-red-500 p-4 text-center">Not Found</p>
                    )}
                  </div>
                )}
              </div>
              <div className="col-span-2 grid grid-cols-2">
                {" "}
                <div
                  onClick={() => handleProgreess("/inprogress")}
                  className="flex justify-end items-center"
                >
                  <div className="border border-[#F0F0F0] h-full w-[50px] flex items-center justify-center rounded-md relative">
                    <HiBell className="text-3xl text-[#007BFF]" />
                    <div className="h-[15px] w-[15px] bg-white absolute rounded-full right-2 top-2 flex items-center justify-center">
                      <div className="h-[10px] w-[10px] rounded-full bg-[#28A745]"></div>
                    </div>
                  </div>
                </div>
                <div className="flex justify-end items-center relative">
                  <div
                    onClick={handleDropdownToggle}
                    className="h-[50px] overflow-hidden w-[50px] border #F0F0F0 rounded-full cursor-pointer"
                    ref={dropdownRef}
                  >
                    <img
                      className="h-full w-full"
                      src={user?.profilepic || Avtar}
                      alt="profile.png"
                    />
                  </div>
                  {dropdownOpen && (
                    <div
                      className="absolute w-40 bg-white shadow-md top-[125%] z-[100] space-y-4 text-textcolor"
                      ref={dropdownRef}
                    >
                      {user === null ? (
                        <div className="flex flex-col space-y-2">
                          <Link className="border-b p-3" to={"/login"}>
                            <div
                              className="flex space-x-2 cursor-pointer"
                              onClick={handleDropdownClose}
                            >
                              <FiUser />
                              <span>Login</span>
                            </div>
                          </Link>
                        </div>
                      ) : (
                        <div className="flex flex-col space-y-2">
                          <Link className="border-b p-3" to={"/user-profile"}>
                            <div
                              className="flex space-x-2 cursor-pointer"
                              onClick={handleDropdownClose}
                            >
                              <FiUser />
                              <span>View Profile</span>
                            </div>
                          </Link>
                          <Link className="border-b p-3" to={"/settings"}>
                            <div
                              className="flex space-x-2 cursor-pointer"
                              onClick={handleDropdownClose}
                            >
                              <FaGear />
                              <span>Settings</span>
                            </div>
                          </Link>
                          <Link
                            className="border-b p-3"
                            to={"/settings/favroutes/flights"}
                          >
                            <div
                              className="flex space-x-2 cursor-pointer"
                              onClick={handleDropdownClose}
                            >
                              <IoMdHeart />
                              <span>My Favorites</span>
                            </div>
                          </Link>
                          <div
                            onClick={handleLogout}
                            className="flex space-x-2 cursor-pointer p-3"
                          >
                            <FiLogOut />
                            <span>Logout</span>
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`w-full bg-[#00000074] duration-150 h-screen fixed ${
          responsiveNavOpen ? "left-0" : "-left-full"
        } l top-0 z-[1000]`}
        onClick={closeResponsiveNav}
      >
        <div
          className="Sidenav h-screen w-[90%] bg-white sm:w-2/3"
          onClick={(e) => e.stopPropagation()}
        >
          <ResponsiveNavbar
            handlemapDesSerch={handlemapDesSerch}
            SearchDestination={SearchDestination}
            SearchVal={SearchVal}
            handleSearchDest={handleSearchDest}
            setSearchVal={setSearchVal}
            setResponsiveNavOpen={setResponsiveNavOpen}
          />
        </div>
      </div>
    </div>
  );
};

export default Navbar;
