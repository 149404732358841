import React from "react";
import { PersonOvel } from "../../../assets";

const GalleryImgCard = ({ imgsrc }) => {
  return (
    <>

      <div className=" relative h-[100%] w-[100%] ">
        <div className=" z-40 absolute bottom-0 left-0  " style={{ transform: 'translate(7px, -7px)' }} >
          <img src={PersonOvel} className="h-[35px] w-[35px]  " />
        </div>
        <img
          src={imgsrc}
          style={{
            minWidth: "100%",
            maxWidth: "100%",
            marginBottom: "10px",
            marginBottom: "10px",
          }}
            className=" rounded-lg object-cover  h-[100%] "
        />
      </div>
    </>
  );
};

export default GalleryImgCard;
