import airlineCodes from "airline-iata-code";
import airports from "airport-codes";
import moment from "moment";
const getCityListwithCodes = () => {
  const data = airports.toJSON();
  const newData = data.map((item) => {
    const { iata, city, country } = item;
    return { iata, city, country };
  });
  return newData;
};
const getAirlineName = (airlineCode) => {
  const data = airlineCodes(airlineCode);
  return data[0].Airline;
};

const getAirlineImage = (airlineCode) => {
  const data = airlineCodes(airlineCode);
  return data[0].logo;
};
const TimeConverter = (duration) => {
  const parsedDuration = moment.duration(duration);

  const hours = parsedDuration.hours();
  const minutes = parsedDuration.minutes();
  const formattedDuration = `${hours}h ${minutes}m`;

  return formattedDuration;
};
const getCityName = (iata) => {
  const findName = airports.findWhere({ iata });
  const formatName = findName.toJSON();
  return formatName.city;
};
const getAddress = (iata) => {
  const data = airports.findWhere({ iata });
  const address = data.toJSON();
  return address;
};
export {
  getAirlineName,
  getAirlineImage,
  TimeConverter,
  getCityName,
  getCityListwithCodes,
  getAddress,
};
