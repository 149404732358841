// store.js
import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";

import {
  webDataslice,
  userslice,
  cartslice,
  hotelslice,
  flightsSlice,
  hotelsDataslice,
} from "./slices";

const rootReducer = combineReducers({
  user: userslice,
  web: webDataslice,
  cart: cartslice,
  hotel: hotelslice,
  flight: flightsSlice,
  hotels_data: hotelsDataslice,
});

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["user", "tabs", "cards", "flight", "hotels_data"],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== "production",
});

export const persistor = persistStore(store);
