import React from 'react'
import { Carousel } from 'antd';
import Imagescard from '../images/Imagescard';

const Carusel = ({h}) => {

    const contentStyle = {
        margin: 0,
        height: `${h}px`,
        color: '#fff',
        lineHeight: '160px',
        textAlign: 'center',
        background: '#364d79',
      };

    const onChange = (currentSlide) => {
        console.log(currentSlide);
    };

    const data =[
        {
            img:'https://images.pexels.com/photos/18408920/pexels-photo-18408920/free-photo-of-colonnade-decorated-with-potted-plants.jpeg?auto=compress&cs=tinysrgb&w=600&lazy=load',
        },
        {
           img:'https://images.pexels.com/photos/20386774/pexels-photo-20386774/free-photo-of-a-black-and-white-photo-of-a-snow-covered-mountain.jpeg?auto=compress&cs=tinysrgb&w=600&lazy=load',
        },
        {
           img:'https://images.pexels.com/photos/13672739/pexels-photo-13672739.jpeg?auto=compress&cs=tinysrgb&w=600&lazy=load',
        },
        {
            img:'https://images.pexels.com/photos/20510033/pexels-photo-20510033/free-photo-of-a-view-of-the-blue-mosque-from-the-water.jpeg?auto=compress&cs=tinysrgb&w=600&lazy=load',
        },
    ]

    const ItemsRender = data.map((items)=>{
        const {img}=items
        return (
            <>
             <div>
                    <div style={contentStyle}>
                        <div style={{backgroundImage:`url(${img})`,backgroundSize:'cover' ,backgroundPosition:'center'  }} className={`h-[${h}px] w-[full] `} ></div>
                    </div>
                </div>
            </>
        )
    })

    return (
        <>
            <Carousel afterChange={onChange}>
              {
                ItemsRender
              }
            </Carousel>
        </>
    )
}

export default Carusel