import { Tag } from "antd";
import React from "react";
import { CultureImg, historyimg, PracInfo, GalleryImg } from "../../../assets";
import { MapContainer, TileLayer } from "react-leaflet";
import { useSelector } from "react-redux";

const Details = ({ showModal }) => {
  const { CurrentDestination = null } = useSelector((state) => state.web);

  const activies = [
    {
      icon: CultureImg,
      title: "Cultures",
      path: "Step3",
    },
    {
      icon: historyimg,
      title: "History",
      path: "Step4",
    },
    {
      icon: PracInfo,
      title: "Practical Info",
      path: "Step5",
    },
    {
      icon: GalleryImg,
      title: "Gallery",
      path: "Step6",
    },
  ];

  return (
    <>
      <div className="overflow-auto h-[75vh] w-full flex flex-col pt-[10px] font-Montserrat">
        <h1 className="text-[#394149] font-Montserrat sm:text-[20px] text-xs font-bold text-start ">
          Explore More about {CurrentDestination?.name}
        </h1>

        <div className=" mt-[29px] grid md:grid-cols-6 sm:grid-cols-3 grid-cols-1 gap-4">
          {activies.map((items) => {
            const { icon, title } = items;
            return (
              <div
                onClick={() => showModal(items.path)}
                className="cursor-pointer h-full"
              >
                <div className=" rounded-lg shadow-md border flex justify-center py-4 h-[80%]">
                  <img className="h-auto w-1/2" src={icon} alt="" />
                </div>

                <p className="text-[#394149] text-base font-semibold py-1">
                  {title}
                </p>
              </div>
            );
          })}
        </div>

        <h1 className="text-[#394149] font-Montserrat text-[20px] font-bold text-start mt-[20px] ">
          Explore the Map
        </h1>

        <div className="w-full h-[400px] rounded-lg  mt-[10px] ">
          <MapContainer
            style={{ height: "100%", width: "100%" }}
            center={[51.505, -0.05]}
            zoom={15}
          >
            <TileLayer
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            {/* <Marker
              eventHandlers={{ click: (e) => showModal("hotel") }}
              key={index}
              position={[position.y, position.x]}
              icon={customIcons[index]}
            ></Marker> */}
          </MapContainer>
        </div>
      </div>
    </>
  );
};

export default Details;
