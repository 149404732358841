import { Modal } from "antd";
import React from "react";
import { BsArrowLeft } from "react-icons/bs";

const Model = ({
  handleOk,
  handleCancel,
  component,
  open,
  header,
  width,
  modelBackArrow,
  showModal,
}) => {
  const Header = (
    <>
      <div className="flex  ">
        {modelBackArrow ? (
          <div className={`w-[33.3%] flex justify-start items-center `}>
            <div onClick={() => showModal("Step2")} className="flex items-center cursor-pointer">
              <BsArrowLeft
                style={{ color: "black", fontSize: "20px", cursor: "pointer" }}
              />{" "}
              <h2 className="text-[#808080] text-[12px] sm:text-[18px] font-bold ml-[4px] sm:ml-[18px] ">
                Back to Main
              </h2>
            </div>
          </div>
        ) : null}
        <div
          className={`w-[${modelBackArrow ? "33.3%" : "50%"}] flex ${
            modelBackArrow ? "justify-center" : "justify-start"
          }  `}
        >
          <h1 className="text-[#394149] text-[24px] font-bold  ">{header}</h1>
        </div>
        <div className={`w-[${modelBackArrow ? "33.3%" : "50%"}]  `}></div>
      </div>
    </>
  );

  return (
    <>
      <Modal
        title={Header}
        open={open}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        width={width}
        okButtonProps={{
          disabled: true,
        }}
        cancelButtonProps={{
          disabled: true,
        }}
        style={{ textAlign: "center", marginTop: "-70px" }}
      >
        <div className="border border-[#E4E4E4] pb-[17px] pl-[17px] pr-[17px] rounded-xl">
          {component}
        </div>
      </Modal>
    </>
  );
};

export default Model;
