import React from "react";
import { FaRegFileAlt, FaTimes } from "react-icons/fa";

const FilePreviewModal = ({ fileUrl, fileType, onClose }) => {
  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="relative bg-white rounded-lg p-4 max-w-xl w-full">
        <button
          onClick={onClose}
          className="absolute top-2 right-2 text-gray-600 hover:text-gray-800"
        >
          <FaTimes size={20} />
        </button>
        <div className="p-4">
          {fileType === "image" && (
            <img src={fileUrl} alt="Preview" className="w-full h-auto" />
          )}
          {fileType === "video" && (
            <video controls className="w-full h-auto">
              <source src={fileUrl} type="video/mp4" />
            </video>
          )}
          {fileType === "audio" && (
            <audio controls className="w-full">
              <source src={fileUrl} type="audio/mpeg" />
            </audio>
          )}
          {fileType === "pdf" && (
            <embed
              src={fileUrl}
              type="application/pdf"
              className="w-full h-96"
            />
          )}
          {fileType === "file" && (
            <div className="flex flex-col items-center justify-center h-96">
              <FaRegFileAlt size={50} className="text-gray-500" />
              <a href={fileUrl} download className="text-blue-500 mt-2">
                Download File
              </a>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default FilePreviewModal;
