import { createSlice } from "@reduxjs/toolkit";

const flightsSlice = createSlice({
  name: "flights",
  initialState: {
    allflights: [],
    selectedFlight: {},
    dictionaries: {},
    airlines: [],
    selectedSegment: {},
    numOfpessengers: 1,
    paymentSuccess: false,
  },
  reducers: {
    setFlights: (state, action) => {
      state.allflights = action.payload;
    },
    setDictionaries: (state, action) => {
      state.dictionaries = action.payload;
    },
    setSelectedFlight: (state, action) => {
      state.selectedFlight = action.payload;
    },
    setAirlines: (state, action) => {
      state.airlines = action.payload;
    },
    setPessengers: (state, action) => {
      state.numOfpessengers = action.payload;
    },
    setPaymentSuccess: (state, action) => {
      state.paymentSuccess = action.payload;
    },
  },
});

export const {
  setFlights,
  setDictionaries,
  setSelectedFlight,
  setAirlines,
  setPessengers,
  setPaymentSuccess
} = flightsSlice.actions;
export default flightsSlice.reducer;
