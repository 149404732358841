const MockFlights = [
  {
    type: "flight-offer",
    id: "1",
    source: "GDS",
    instantTicketingRequired: false,
    nonHomogeneous: false,
    oneWay: false,
    lastTicketingDate: "2024-05-06",
    lastTicketingDateTime: "2024-05-06",
    numberOfBookableSeats: 7,
    itineraries: [
      {
        duration: "PT7H15M",
        segments: [
          {
            departure: {
              iataCode: "LHE",
              at: "2024-05-06T03:30:00",
            },
            arrival: {
              iataCode: "DXB",
              terminal: "3",
              at: "2024-05-06T05:40:00",
            },
            carrierCode: "EK",
            number: "623",
            aircraft: {
              code: "77W",
            },
            operating: {
              carrierCode: "EK",
            },
            duration: "PT3H10M",
            id: "10",
            numberOfStops: 0,
            blacklistedInEU: false,
          },
          {
            departure: {
              iataCode: "DXB",
              terminal: "3",
              at: "2024-05-06T06:55:00",
            },
            arrival: {
              iataCode: "JED",
              terminal: "1",
              at: "2024-05-06T08:45:00",
            },
            carrierCode: "EK",
            number: "805",
            aircraft: {
              code: "388",
            },
            operating: {
              carrierCode: "EK",
            },
            duration: "PT2H50M",
            id: "11",
            numberOfStops: 0,
            blacklistedInEU: false,
          },
        ],
      },
      {
        duration: "PT11H20M",
        segments: [
          {
            departure: {
              iataCode: "JED",
              terminal: "1",
              at: "2024-05-11T12:30:00",
            },
            arrival: {
              iataCode: "DXB",
              terminal: "2",
              at: "2024-05-11T16:30:00",
            },
            carrierCode: "EK",
            number: "2335",
            aircraft: {
              code: "73H",
            },
            operating: {
              carrierCode: "FZ",
            },
            duration: "PT3H",
            id: "40",
            numberOfStops: 0,
            blacklistedInEU: false,
          },
          {
            departure: {
              iataCode: "DXB",
              terminal: "3",
              at: "2024-05-11T21:50:00",
            },
            arrival: {
              iataCode: "LHE",
              at: "2024-05-12T01:50:00",
            },
            carrierCode: "EK",
            number: "622",
            aircraft: {
              code: "77W",
            },
            operating: {
              carrierCode: "EK",
            },
            duration: "PT3H",
            id: "41",
            numberOfStops: 0,
            blacklistedInEU: false,
          },
        ],
      },
    ],
    price: {
      currency: "USD",
      total: "1893.86",
      base: "1538.00",
      fees: [
        {
          amount: "0.00",
          type: "SUPPLIER",
        },
        {
          amount: "0.00",
          type: "TICKETING",
        },
      ],
      grandTotal: "1893.86",
    },
    pricingOptions: {
      fareType: ["PUBLISHED"],
      includedCheckedBagsOnly: true,
    },
    validatingAirlineCodes: ["EK"],
    travelerPricings: [
      {
        travelerId: "1",
        fareOption: "STANDARD",
        travelerType: "ADULT",
        price: {
          currency: "USD",
          total: "1893.86",
          base: "1538.00",
        },
        fareDetailsBySegment: [
          {
            segmentId: "10",
            cabin: "BUSINESS",
            fareBasis: "OAAAPPK1",
            brandedFare: "BSSAVER",
            brandedFareLabel: "BUSINESS SAVER",
            class: "O",
            includedCheckedBags: {
              weight: 40,
              weightUnit: "KG",
            },
            amenities: [
              {
                description: "PRE RESERVED SEAT ASSIGNMENT",
                isChargeable: false,
                amenityType: "PRE_RESERVED_SEAT",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "BEVERAGE",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "MEAL VOUCHER",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "FAST TRACK",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PREMIUM CHECK IN",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BOARDING",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BAGGAGE",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
            ],
          },
          {
            segmentId: "11",
            cabin: "BUSINESS",
            fareBasis: "OAAAPPK1",
            brandedFare: "BSSAVER",
            brandedFareLabel: "BUSINESS SAVER",
            class: "O",
            includedCheckedBags: {
              weight: 40,
              weightUnit: "KG",
            },
            amenities: [
              {
                description: "PRE RESERVED SEAT ASSIGNMENT",
                isChargeable: false,
                amenityType: "PRE_RESERVED_SEAT",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "BEVERAGE",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "MEAL VOUCHER",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "FAST TRACK",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PREMIUM CHECK IN",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BOARDING",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BAGGAGE",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
            ],
          },
          {
            segmentId: "40",
            cabin: "BUSINESS",
            fareBasis: "OAAAPPK1",
            brandedFare: "BSSAVER",
            brandedFareLabel: "BUSINESS SAVER",
            class: "O",
            includedCheckedBags: {
              weight: 40,
              weightUnit: "KG",
            },
            amenities: [
              {
                description: "PRE RESERVED SEAT ASSIGNMENT",
                isChargeable: false,
                amenityType: "PRE_RESERVED_SEAT",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "BEVERAGE",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "MEAL VOUCHER",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "FAST TRACK",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PREMIUM CHECK IN",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BOARDING",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BAGGAGE",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
            ],
          },
          {
            segmentId: "41",
            cabin: "BUSINESS",
            fareBasis: "OAAAPPK1",
            brandedFare: "BSSAVER",
            brandedFareLabel: "BUSINESS SAVER",
            class: "O",
            includedCheckedBags: {
              weight: 40,
              weightUnit: "KG",
            },
            amenities: [
              {
                description: "PRE RESERVED SEAT ASSIGNMENT",
                isChargeable: false,
                amenityType: "PRE_RESERVED_SEAT",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "BEVERAGE",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "MEAL VOUCHER",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "FAST TRACK",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PREMIUM CHECK IN",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BOARDING",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BAGGAGE",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
            ],
          },
        ],
      },
    ],
  },
  {
    type: "flight-offer",
    id: "2",
    source: "GDS",
    instantTicketingRequired: false,
    nonHomogeneous: false,
    oneWay: false,
    lastTicketingDate: "2024-05-06",
    lastTicketingDateTime: "2024-05-06",
    numberOfBookableSeats: 7,
    itineraries: [
      {
        duration: "PT10H",
        segments: [
          {
            departure: {
              iataCode: "LHE",
              at: "2024-05-06T03:30:00",
            },
            arrival: {
              iataCode: "DXB",
              terminal: "3",
              at: "2024-05-06T05:40:00",
            },
            carrierCode: "EK",
            number: "623",
            aircraft: {
              code: "77W",
            },
            operating: {
              carrierCode: "EK",
            },
            duration: "PT3H10M",
            id: "1",
            numberOfStops: 0,
            blacklistedInEU: false,
          },
          {
            departure: {
              iataCode: "DXB",
              terminal: "2",
              at: "2024-05-06T09:25:00",
            },
            arrival: {
              iataCode: "JED",
              terminal: "1",
              at: "2024-05-06T11:30:00",
            },
            carrierCode: "EK",
            number: "2334",
            aircraft: {
              code: "73H",
            },
            operating: {
              carrierCode: "FZ",
            },
            duration: "PT3H5M",
            id: "2",
            numberOfStops: 0,
            blacklistedInEU: false,
          },
        ],
      },
      {
        duration: "PT11H20M",
        segments: [
          {
            departure: {
              iataCode: "JED",
              terminal: "1",
              at: "2024-05-11T12:30:00",
            },
            arrival: {
              iataCode: "DXB",
              terminal: "2",
              at: "2024-05-11T16:30:00",
            },
            carrierCode: "EK",
            number: "2335",
            aircraft: {
              code: "73H",
            },
            operating: {
              carrierCode: "FZ",
            },
            duration: "PT3H",
            id: "40",
            numberOfStops: 0,
            blacklistedInEU: false,
          },
          {
            departure: {
              iataCode: "DXB",
              terminal: "3",
              at: "2024-05-11T21:50:00",
            },
            arrival: {
              iataCode: "LHE",
              at: "2024-05-12T01:50:00",
            },
            carrierCode: "EK",
            number: "622",
            aircraft: {
              code: "77W",
            },
            operating: {
              carrierCode: "EK",
            },
            duration: "PT3H",
            id: "41",
            numberOfStops: 0,
            blacklistedInEU: false,
          },
        ],
      },
    ],
    price: {
      currency: "USD",
      total: "1893.86",
      base: "1538.00",
      fees: [
        {
          amount: "0.00",
          type: "SUPPLIER",
        },
        {
          amount: "0.00",
          type: "TICKETING",
        },
      ],
      grandTotal: "1893.86",
    },
    pricingOptions: {
      fareType: ["PUBLISHED"],
      includedCheckedBagsOnly: true,
    },
    validatingAirlineCodes: ["EK"],
    travelerPricings: [
      {
        travelerId: "1",
        fareOption: "STANDARD",
        travelerType: "ADULT",
        price: {
          currency: "USD",
          total: "1893.86",
          base: "1538.00",
        },
        fareDetailsBySegment: [
          {
            segmentId: "1",
            cabin: "BUSINESS",
            fareBasis: "OAAAPPK1",
            brandedFare: "BSSAVER",
            brandedFareLabel: "BUSINESS SAVER",
            class: "O",
            includedCheckedBags: {
              weight: 40,
              weightUnit: "KG",
            },
            amenities: [
              {
                description: "PRE RESERVED SEAT ASSIGNMENT",
                isChargeable: false,
                amenityType: "PRE_RESERVED_SEAT",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "BEVERAGE",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "MEAL VOUCHER",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "FAST TRACK",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PREMIUM CHECK IN",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BOARDING",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BAGGAGE",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
            ],
          },
          {
            segmentId: "2",
            cabin: "BUSINESS",
            fareBasis: "OAAAPPK1",
            brandedFare: "BSSAVER",
            brandedFareLabel: "BUSINESS SAVER",
            class: "O",
            includedCheckedBags: {
              weight: 40,
              weightUnit: "KG",
            },
            amenities: [
              {
                description: "PRE RESERVED SEAT ASSIGNMENT",
                isChargeable: false,
                amenityType: "PRE_RESERVED_SEAT",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "BEVERAGE",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "MEAL VOUCHER",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "FAST TRACK",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PREMIUM CHECK IN",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BOARDING",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BAGGAGE",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
            ],
          },
          {
            segmentId: "40",
            cabin: "BUSINESS",
            fareBasis: "OAAAPPK1",
            brandedFare: "BSSAVER",
            brandedFareLabel: "BUSINESS SAVER",
            class: "O",
            includedCheckedBags: {
              weight: 40,
              weightUnit: "KG",
            },
            amenities: [
              {
                description: "PRE RESERVED SEAT ASSIGNMENT",
                isChargeable: false,
                amenityType: "PRE_RESERVED_SEAT",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "BEVERAGE",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "MEAL VOUCHER",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "FAST TRACK",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PREMIUM CHECK IN",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BOARDING",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BAGGAGE",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
            ],
          },
          {
            segmentId: "41",
            cabin: "BUSINESS",
            fareBasis: "OAAAPPK1",
            brandedFare: "BSSAVER",
            brandedFareLabel: "BUSINESS SAVER",
            class: "O",
            includedCheckedBags: {
              weight: 40,
              weightUnit: "KG",
            },
            amenities: [
              {
                description: "PRE RESERVED SEAT ASSIGNMENT",
                isChargeable: false,
                amenityType: "PRE_RESERVED_SEAT",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "BEVERAGE",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "MEAL VOUCHER",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "FAST TRACK",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PREMIUM CHECK IN",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BOARDING",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BAGGAGE",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
            ],
          },
        ],
      },
    ],
  },
  {
    type: "flight-offer",
    id: "3",
    source: "GDS",
    instantTicketingRequired: false,
    nonHomogeneous: false,
    oneWay: false,
    lastTicketingDate: "2024-05-06",
    lastTicketingDateTime: "2024-05-06",
    numberOfBookableSeats: 7,
    itineraries: [
      {
        duration: "PT11H",
        segments: [
          {
            departure: {
              iataCode: "LHE",
              at: "2024-05-06T09:00:00",
            },
            arrival: {
              iataCode: "DXB",
              terminal: "3",
              at: "2024-05-06T11:15:00",
            },
            carrierCode: "EK",
            number: "625",
            aircraft: {
              code: "77W",
            },
            operating: {
              carrierCode: "EK",
            },
            duration: "PT3H15M",
            id: "3",
            numberOfStops: 0,
            blacklistedInEU: false,
          },
          {
            departure: {
              iataCode: "DXB",
              terminal: "3",
              at: "2024-05-06T15:40:00",
            },
            arrival: {
              iataCode: "JED",
              terminal: "1",
              at: "2024-05-06T18:00:00",
            },
            carrierCode: "EK",
            number: "803",
            aircraft: {
              code: "388",
            },
            operating: {
              carrierCode: "EK",
            },
            duration: "PT3H20M",
            id: "4",
            numberOfStops: 0,
            blacklistedInEU: false,
          },
        ],
      },
      {
        duration: "PT11H20M",
        segments: [
          {
            departure: {
              iataCode: "JED",
              terminal: "1",
              at: "2024-05-11T12:30:00",
            },
            arrival: {
              iataCode: "DXB",
              terminal: "2",
              at: "2024-05-11T16:30:00",
            },
            carrierCode: "EK",
            number: "2335",
            aircraft: {
              code: "73H",
            },
            operating: {
              carrierCode: "FZ",
            },
            duration: "PT3H",
            id: "40",
            numberOfStops: 0,
            blacklistedInEU: false,
          },
          {
            departure: {
              iataCode: "DXB",
              terminal: "3",
              at: "2024-05-11T21:50:00",
            },
            arrival: {
              iataCode: "LHE",
              at: "2024-05-12T01:50:00",
            },
            carrierCode: "EK",
            number: "622",
            aircraft: {
              code: "77W",
            },
            operating: {
              carrierCode: "EK",
            },
            duration: "PT3H",
            id: "41",
            numberOfStops: 0,
            blacklistedInEU: false,
          },
        ],
      },
    ],
    price: {
      currency: "USD",
      total: "1893.86",
      base: "1538.00",
      fees: [
        {
          amount: "0.00",
          type: "SUPPLIER",
        },
        {
          amount: "0.00",
          type: "TICKETING",
        },
      ],
      grandTotal: "1893.86",
    },
    pricingOptions: {
      fareType: ["PUBLISHED"],
      includedCheckedBagsOnly: true,
    },
    validatingAirlineCodes: ["EK"],
    travelerPricings: [
      {
        travelerId: "1",
        fareOption: "STANDARD",
        travelerType: "ADULT",
        price: {
          currency: "USD",
          total: "1893.86",
          base: "1538.00",
        },
        fareDetailsBySegment: [
          {
            segmentId: "3",
            cabin: "BUSINESS",
            fareBasis: "OAAAPPK1",
            brandedFare: "BSSAVER",
            brandedFareLabel: "BUSINESS SAVER",
            class: "O",
            includedCheckedBags: {
              weight: 40,
              weightUnit: "KG",
            },
            amenities: [
              {
                description: "PRE RESERVED SEAT ASSIGNMENT",
                isChargeable: false,
                amenityType: "PRE_RESERVED_SEAT",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "BEVERAGE",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "MEAL VOUCHER",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "FAST TRACK",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PREMIUM CHECK IN",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BOARDING",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BAGGAGE",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
            ],
          },
          {
            segmentId: "4",
            cabin: "BUSINESS",
            fareBasis: "OAAAPPK1",
            brandedFare: "BSSAVER",
            brandedFareLabel: "BUSINESS SAVER",
            class: "O",
            includedCheckedBags: {
              weight: 40,
              weightUnit: "KG",
            },
            amenities: [
              {
                description: "PRE RESERVED SEAT ASSIGNMENT",
                isChargeable: false,
                amenityType: "PRE_RESERVED_SEAT",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "BEVERAGE",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "MEAL VOUCHER",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "FAST TRACK",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PREMIUM CHECK IN",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BOARDING",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BAGGAGE",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
            ],
          },
          {
            segmentId: "40",
            cabin: "BUSINESS",
            fareBasis: "OAAAPPK1",
            brandedFare: "BSSAVER",
            brandedFareLabel: "BUSINESS SAVER",
            class: "O",
            includedCheckedBags: {
              weight: 40,
              weightUnit: "KG",
            },
            amenities: [
              {
                description: "PRE RESERVED SEAT ASSIGNMENT",
                isChargeable: false,
                amenityType: "PRE_RESERVED_SEAT",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "BEVERAGE",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "MEAL VOUCHER",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "FAST TRACK",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PREMIUM CHECK IN",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BOARDING",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BAGGAGE",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
            ],
          },
          {
            segmentId: "41",
            cabin: "BUSINESS",
            fareBasis: "OAAAPPK1",
            brandedFare: "BSSAVER",
            brandedFareLabel: "BUSINESS SAVER",
            class: "O",
            includedCheckedBags: {
              weight: 40,
              weightUnit: "KG",
            },
            amenities: [
              {
                description: "PRE RESERVED SEAT ASSIGNMENT",
                isChargeable: false,
                amenityType: "PRE_RESERVED_SEAT",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "BEVERAGE",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "MEAL VOUCHER",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "FAST TRACK",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PREMIUM CHECK IN",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BOARDING",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BAGGAGE",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
            ],
          },
        ],
      },
    ],
  },
  {
    type: "flight-offer",
    id: "4",
    source: "GDS",
    instantTicketingRequired: false,
    nonHomogeneous: false,
    oneWay: false,
    lastTicketingDate: "2024-05-06",
    lastTicketingDateTime: "2024-05-06",
    numberOfBookableSeats: 7,
    itineraries: [
      {
        duration: "PT10H",
        segments: [
          {
            departure: {
              iataCode: "LHE",
              at: "2024-05-06T03:30:00",
            },
            arrival: {
              iataCode: "DXB",
              terminal: "3",
              at: "2024-05-06T05:40:00",
            },
            carrierCode: "EK",
            number: "623",
            aircraft: {
              code: "77W",
            },
            operating: {
              carrierCode: "EK",
            },
            duration: "PT3H10M",
            id: "1",
            numberOfStops: 0,
            blacklistedInEU: false,
          },
          {
            departure: {
              iataCode: "DXB",
              terminal: "2",
              at: "2024-05-06T09:25:00",
            },
            arrival: {
              iataCode: "JED",
              terminal: "1",
              at: "2024-05-06T11:30:00",
            },
            carrierCode: "EK",
            number: "2334",
            aircraft: {
              code: "73H",
            },
            operating: {
              carrierCode: "FZ",
            },
            duration: "PT3H5M",
            id: "2",
            numberOfStops: 0,
            blacklistedInEU: false,
          },
        ],
      },
      {
        duration: "PT13H5M",
        segments: [
          {
            departure: {
              iataCode: "JED",
              terminal: "1",
              at: "2024-05-11T10:45:00",
            },
            arrival: {
              iataCode: "DXB",
              terminal: "3",
              at: "2024-05-11T14:45:00",
            },
            carrierCode: "EK",
            number: "806",
            aircraft: {
              code: "388",
            },
            operating: {
              carrierCode: "EK",
            },
            duration: "PT3H",
            id: "32",
            numberOfStops: 0,
            blacklistedInEU: false,
          },
          {
            departure: {
              iataCode: "DXB",
              terminal: "3",
              at: "2024-05-11T21:50:00",
            },
            arrival: {
              iataCode: "LHE",
              at: "2024-05-12T01:50:00",
            },
            carrierCode: "EK",
            number: "622",
            aircraft: {
              code: "77W",
            },
            operating: {
              carrierCode: "EK",
            },
            duration: "PT3H",
            id: "33",
            numberOfStops: 0,
            blacklistedInEU: false,
          },
        ],
      },
    ],
    price: {
      currency: "USD",
      total: "1893.86",
      base: "1538.00",
      fees: [
        {
          amount: "0.00",
          type: "SUPPLIER",
        },
        {
          amount: "0.00",
          type: "TICKETING",
        },
      ],
      grandTotal: "1893.86",
    },
    pricingOptions: {
      fareType: ["PUBLISHED"],
      includedCheckedBagsOnly: true,
    },
    validatingAirlineCodes: ["EK"],
    travelerPricings: [
      {
        travelerId: "1",
        fareOption: "STANDARD",
        travelerType: "ADULT",
        price: {
          currency: "USD",
          total: "1893.86",
          base: "1538.00",
        },
        fareDetailsBySegment: [
          {
            segmentId: "1",
            cabin: "BUSINESS",
            fareBasis: "OAAAPPK1",
            brandedFare: "BSSAVER",
            brandedFareLabel: "BUSINESS SAVER",
            class: "O",
            includedCheckedBags: {
              weight: 40,
              weightUnit: "KG",
            },
            amenities: [
              {
                description: "PRE RESERVED SEAT ASSIGNMENT",
                isChargeable: false,
                amenityType: "PRE_RESERVED_SEAT",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "BEVERAGE",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "MEAL VOUCHER",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "FAST TRACK",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PREMIUM CHECK IN",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BOARDING",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BAGGAGE",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
            ],
          },
          {
            segmentId: "2",
            cabin: "BUSINESS",
            fareBasis: "OAAAPPK1",
            brandedFare: "BSSAVER",
            brandedFareLabel: "BUSINESS SAVER",
            class: "O",
            includedCheckedBags: {
              weight: 40,
              weightUnit: "KG",
            },
            amenities: [
              {
                description: "PRE RESERVED SEAT ASSIGNMENT",
                isChargeable: false,
                amenityType: "PRE_RESERVED_SEAT",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "BEVERAGE",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "MEAL VOUCHER",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "FAST TRACK",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PREMIUM CHECK IN",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BOARDING",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BAGGAGE",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
            ],
          },
          {
            segmentId: "32",
            cabin: "BUSINESS",
            fareBasis: "OAAAPPK1",
            brandedFare: "BSSAVER",
            brandedFareLabel: "BUSINESS SAVER",
            class: "O",
            includedCheckedBags: {
              weight: 40,
              weightUnit: "KG",
            },
            amenities: [
              {
                description: "PRE RESERVED SEAT ASSIGNMENT",
                isChargeable: false,
                amenityType: "PRE_RESERVED_SEAT",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "BEVERAGE",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "MEAL VOUCHER",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "FAST TRACK",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PREMIUM CHECK IN",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BOARDING",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BAGGAGE",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
            ],
          },
          {
            segmentId: "33",
            cabin: "BUSINESS",
            fareBasis: "OAAAPPK1",
            brandedFare: "BSSAVER",
            brandedFareLabel: "BUSINESS SAVER",
            class: "O",
            includedCheckedBags: {
              weight: 40,
              weightUnit: "KG",
            },
            amenities: [
              {
                description: "PRE RESERVED SEAT ASSIGNMENT",
                isChargeable: false,
                amenityType: "PRE_RESERVED_SEAT",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "BEVERAGE",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "MEAL VOUCHER",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "FAST TRACK",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PREMIUM CHECK IN",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BOARDING",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BAGGAGE",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
            ],
          },
        ],
      },
    ],
  },
  {
    type: "flight-offer",
    id: "5",
    source: "GDS",
    instantTicketingRequired: false,
    nonHomogeneous: false,
    oneWay: false,
    lastTicketingDate: "2024-05-06",
    lastTicketingDateTime: "2024-05-06",
    numberOfBookableSeats: 7,
    itineraries: [
      {
        duration: "PT7H15M",
        segments: [
          {
            departure: {
              iataCode: "LHE",
              at: "2024-05-06T03:30:00",
            },
            arrival: {
              iataCode: "DXB",
              terminal: "3",
              at: "2024-05-06T05:40:00",
            },
            carrierCode: "EK",
            number: "623",
            aircraft: {
              code: "77W",
            },
            operating: {
              carrierCode: "EK",
            },
            duration: "PT3H10M",
            id: "10",
            numberOfStops: 0,
            blacklistedInEU: false,
          },
          {
            departure: {
              iataCode: "DXB",
              terminal: "3",
              at: "2024-05-06T06:55:00",
            },
            arrival: {
              iataCode: "JED",
              terminal: "1",
              at: "2024-05-06T08:45:00",
            },
            carrierCode: "EK",
            number: "805",
            aircraft: {
              code: "388",
            },
            operating: {
              carrierCode: "EK",
            },
            duration: "PT2H50M",
            id: "11",
            numberOfStops: 0,
            blacklistedInEU: false,
          },
        ],
      },
      {
        duration: "PT16H",
        segments: [
          {
            departure: {
              iataCode: "JED",
              terminal: "1",
              at: "2024-05-11T07:50:00",
            },
            arrival: {
              iataCode: "DXB",
              terminal: "2",
              at: "2024-05-11T11:50:00",
            },
            carrierCode: "EK",
            number: "2259",
            aircraft: {
              code: "7M8",
            },
            operating: {
              carrierCode: "FZ",
            },
            duration: "PT3H",
            id: "30",
            numberOfStops: 0,
            blacklistedInEU: false,
          },
          {
            departure: {
              iataCode: "DXB",
              terminal: "3",
              at: "2024-05-11T21:50:00",
            },
            arrival: {
              iataCode: "LHE",
              at: "2024-05-12T01:50:00",
            },
            carrierCode: "EK",
            number: "622",
            aircraft: {
              code: "77W",
            },
            operating: {
              carrierCode: "EK",
            },
            duration: "PT3H",
            id: "31",
            numberOfStops: 0,
            blacklistedInEU: false,
          },
        ],
      },
    ],
    price: {
      currency: "USD",
      total: "1893.86",
      base: "1538.00",
      fees: [
        {
          amount: "0.00",
          type: "SUPPLIER",
        },
        {
          amount: "0.00",
          type: "TICKETING",
        },
      ],
      grandTotal: "1893.86",
    },
    pricingOptions: {
      fareType: ["PUBLISHED"],
      includedCheckedBagsOnly: true,
    },
    validatingAirlineCodes: ["EK"],
    travelerPricings: [
      {
        travelerId: "1",
        fareOption: "STANDARD",
        travelerType: "ADULT",
        price: {
          currency: "USD",
          total: "1893.86",
          base: "1538.00",
        },
        fareDetailsBySegment: [
          {
            segmentId: "10",
            cabin: "BUSINESS",
            fareBasis: "OAAAPPK1",
            brandedFare: "BSSAVER",
            brandedFareLabel: "BUSINESS SAVER",
            class: "O",
            includedCheckedBags: {
              weight: 40,
              weightUnit: "KG",
            },
            amenities: [
              {
                description: "PRE RESERVED SEAT ASSIGNMENT",
                isChargeable: false,
                amenityType: "PRE_RESERVED_SEAT",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "BEVERAGE",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "MEAL VOUCHER",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "FAST TRACK",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PREMIUM CHECK IN",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BOARDING",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BAGGAGE",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
            ],
          },
          {
            segmentId: "11",
            cabin: "BUSINESS",
            fareBasis: "OAAAPPK1",
            brandedFare: "BSSAVER",
            brandedFareLabel: "BUSINESS SAVER",
            class: "O",
            includedCheckedBags: {
              weight: 40,
              weightUnit: "KG",
            },
            amenities: [
              {
                description: "PRE RESERVED SEAT ASSIGNMENT",
                isChargeable: false,
                amenityType: "PRE_RESERVED_SEAT",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "BEVERAGE",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "MEAL VOUCHER",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "FAST TRACK",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PREMIUM CHECK IN",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BOARDING",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BAGGAGE",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
            ],
          },
          {
            segmentId: "30",
            cabin: "BUSINESS",
            fareBasis: "OAAAPPK1",
            brandedFare: "BSSAVER",
            brandedFareLabel: "BUSINESS SAVER",
            class: "O",
            includedCheckedBags: {
              weight: 40,
              weightUnit: "KG",
            },
            amenities: [
              {
                description: "PRE RESERVED SEAT ASSIGNMENT",
                isChargeable: false,
                amenityType: "PRE_RESERVED_SEAT",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "BEVERAGE",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "MEAL VOUCHER",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "FAST TRACK",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PREMIUM CHECK IN",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BOARDING",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BAGGAGE",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
            ],
          },
          {
            segmentId: "31",
            cabin: "BUSINESS",
            fareBasis: "OAAAPPK1",
            brandedFare: "BSSAVER",
            brandedFareLabel: "BUSINESS SAVER",
            class: "O",
            includedCheckedBags: {
              weight: 40,
              weightUnit: "KG",
            },
            amenities: [
              {
                description: "PRE RESERVED SEAT ASSIGNMENT",
                isChargeable: false,
                amenityType: "PRE_RESERVED_SEAT",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "BEVERAGE",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "MEAL VOUCHER",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "FAST TRACK",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PREMIUM CHECK IN",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BOARDING",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BAGGAGE",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
            ],
          },
        ],
      },
    ],
  },
  {
    type: "flight-offer",
    id: "6",
    source: "GDS",
    instantTicketingRequired: false,
    nonHomogeneous: false,
    oneWay: false,
    lastTicketingDate: "2024-05-06",
    lastTicketingDateTime: "2024-05-06",
    numberOfBookableSeats: 7,
    itineraries: [
      {
        duration: "PT10H",
        segments: [
          {
            departure: {
              iataCode: "LHE",
              at: "2024-05-06T03:30:00",
            },
            arrival: {
              iataCode: "DXB",
              terminal: "3",
              at: "2024-05-06T05:40:00",
            },
            carrierCode: "EK",
            number: "623",
            aircraft: {
              code: "77W",
            },
            operating: {
              carrierCode: "EK",
            },
            duration: "PT3H10M",
            id: "1",
            numberOfStops: 0,
            blacklistedInEU: false,
          },
          {
            departure: {
              iataCode: "DXB",
              terminal: "2",
              at: "2024-05-06T09:25:00",
            },
            arrival: {
              iataCode: "JED",
              terminal: "1",
              at: "2024-05-06T11:30:00",
            },
            carrierCode: "EK",
            number: "2334",
            aircraft: {
              code: "73H",
            },
            operating: {
              carrierCode: "FZ",
            },
            duration: "PT3H5M",
            id: "2",
            numberOfStops: 0,
            blacklistedInEU: false,
          },
        ],
      },
      {
        duration: "PT16H",
        segments: [
          {
            departure: {
              iataCode: "JED",
              terminal: "1",
              at: "2024-05-11T07:50:00",
            },
            arrival: {
              iataCode: "DXB",
              terminal: "2",
              at: "2024-05-11T11:50:00",
            },
            carrierCode: "EK",
            number: "2259",
            aircraft: {
              code: "7M8",
            },
            operating: {
              carrierCode: "FZ",
            },
            duration: "PT3H",
            id: "30",
            numberOfStops: 0,
            blacklistedInEU: false,
          },
          {
            departure: {
              iataCode: "DXB",
              terminal: "3",
              at: "2024-05-11T21:50:00",
            },
            arrival: {
              iataCode: "LHE",
              at: "2024-05-12T01:50:00",
            },
            carrierCode: "EK",
            number: "622",
            aircraft: {
              code: "77W",
            },
            operating: {
              carrierCode: "EK",
            },
            duration: "PT3H",
            id: "31",
            numberOfStops: 0,
            blacklistedInEU: false,
          },
        ],
      },
    ],
    price: {
      currency: "USD",
      total: "1893.86",
      base: "1538.00",
      fees: [
        {
          amount: "0.00",
          type: "SUPPLIER",
        },
        {
          amount: "0.00",
          type: "TICKETING",
        },
      ],
      grandTotal: "1893.86",
    },
    pricingOptions: {
      fareType: ["PUBLISHED"],
      includedCheckedBagsOnly: true,
    },
    validatingAirlineCodes: ["EK"],
    travelerPricings: [
      {
        travelerId: "1",
        fareOption: "STANDARD",
        travelerType: "ADULT",
        price: {
          currency: "USD",
          total: "1893.86",
          base: "1538.00",
        },
        fareDetailsBySegment: [
          {
            segmentId: "1",
            cabin: "BUSINESS",
            fareBasis: "OAAAPPK1",
            brandedFare: "BSSAVER",
            brandedFareLabel: "BUSINESS SAVER",
            class: "O",
            includedCheckedBags: {
              weight: 40,
              weightUnit: "KG",
            },
            amenities: [
              {
                description: "PRE RESERVED SEAT ASSIGNMENT",
                isChargeable: false,
                amenityType: "PRE_RESERVED_SEAT",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "BEVERAGE",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "MEAL VOUCHER",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "FAST TRACK",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PREMIUM CHECK IN",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BOARDING",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BAGGAGE",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
            ],
          },
          {
            segmentId: "2",
            cabin: "BUSINESS",
            fareBasis: "OAAAPPK1",
            brandedFare: "BSSAVER",
            brandedFareLabel: "BUSINESS SAVER",
            class: "O",
            includedCheckedBags: {
              weight: 40,
              weightUnit: "KG",
            },
            amenities: [
              {
                description: "PRE RESERVED SEAT ASSIGNMENT",
                isChargeable: false,
                amenityType: "PRE_RESERVED_SEAT",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "BEVERAGE",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "MEAL VOUCHER",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "FAST TRACK",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PREMIUM CHECK IN",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BOARDING",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BAGGAGE",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
            ],
          },
          {
            segmentId: "30",
            cabin: "BUSINESS",
            fareBasis: "OAAAPPK1",
            brandedFare: "BSSAVER",
            brandedFareLabel: "BUSINESS SAVER",
            class: "O",
            includedCheckedBags: {
              weight: 40,
              weightUnit: "KG",
            },
            amenities: [
              {
                description: "PRE RESERVED SEAT ASSIGNMENT",
                isChargeable: false,
                amenityType: "PRE_RESERVED_SEAT",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "BEVERAGE",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "MEAL VOUCHER",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "FAST TRACK",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PREMIUM CHECK IN",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BOARDING",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BAGGAGE",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
            ],
          },
          {
            segmentId: "31",
            cabin: "BUSINESS",
            fareBasis: "OAAAPPK1",
            brandedFare: "BSSAVER",
            brandedFareLabel: "BUSINESS SAVER",
            class: "O",
            includedCheckedBags: {
              weight: 40,
              weightUnit: "KG",
            },
            amenities: [
              {
                description: "PRE RESERVED SEAT ASSIGNMENT",
                isChargeable: false,
                amenityType: "PRE_RESERVED_SEAT",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "BEVERAGE",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "MEAL VOUCHER",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "FAST TRACK",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PREMIUM CHECK IN",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BOARDING",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BAGGAGE",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
            ],
          },
        ],
      },
    ],
  },
  {
    type: "flight-offer",
    id: "7",
    source: "GDS",
    instantTicketingRequired: false,
    nonHomogeneous: false,
    oneWay: false,
    lastTicketingDate: "2024-05-06",
    lastTicketingDateTime: "2024-05-06",
    numberOfBookableSeats: 7,
    itineraries: [
      {
        duration: "PT11H",
        segments: [
          {
            departure: {
              iataCode: "LHE",
              at: "2024-05-06T09:00:00",
            },
            arrival: {
              iataCode: "DXB",
              terminal: "3",
              at: "2024-05-06T11:15:00",
            },
            carrierCode: "EK",
            number: "625",
            aircraft: {
              code: "77W",
            },
            operating: {
              carrierCode: "EK",
            },
            duration: "PT3H15M",
            id: "3",
            numberOfStops: 0,
            blacklistedInEU: false,
          },
          {
            departure: {
              iataCode: "DXB",
              terminal: "3",
              at: "2024-05-06T15:40:00",
            },
            arrival: {
              iataCode: "JED",
              terminal: "1",
              at: "2024-05-06T18:00:00",
            },
            carrierCode: "EK",
            number: "803",
            aircraft: {
              code: "388",
            },
            operating: {
              carrierCode: "EK",
            },
            duration: "PT3H20M",
            id: "4",
            numberOfStops: 0,
            blacklistedInEU: false,
          },
        ],
      },
      {
        duration: "PT16H",
        segments: [
          {
            departure: {
              iataCode: "JED",
              terminal: "1",
              at: "2024-05-11T07:50:00",
            },
            arrival: {
              iataCode: "DXB",
              terminal: "2",
              at: "2024-05-11T11:50:00",
            },
            carrierCode: "EK",
            number: "2259",
            aircraft: {
              code: "7M8",
            },
            operating: {
              carrierCode: "FZ",
            },
            duration: "PT3H",
            id: "30",
            numberOfStops: 0,
            blacklistedInEU: false,
          },
          {
            departure: {
              iataCode: "DXB",
              terminal: "3",
              at: "2024-05-11T21:50:00",
            },
            arrival: {
              iataCode: "LHE",
              at: "2024-05-12T01:50:00",
            },
            carrierCode: "EK",
            number: "622",
            aircraft: {
              code: "77W",
            },
            operating: {
              carrierCode: "EK",
            },
            duration: "PT3H",
            id: "31",
            numberOfStops: 0,
            blacklistedInEU: false,
          },
        ],
      },
    ],
    price: {
      currency: "USD",
      total: "1893.86",
      base: "1538.00",
      fees: [
        {
          amount: "0.00",
          type: "SUPPLIER",
        },
        {
          amount: "0.00",
          type: "TICKETING",
        },
      ],
      grandTotal: "1893.86",
    },
    pricingOptions: {
      fareType: ["PUBLISHED"],
      includedCheckedBagsOnly: true,
    },
    validatingAirlineCodes: ["EK"],
    travelerPricings: [
      {
        travelerId: "1",
        fareOption: "STANDARD",
        travelerType: "ADULT",
        price: {
          currency: "USD",
          total: "1893.86",
          base: "1538.00",
        },
        fareDetailsBySegment: [
          {
            segmentId: "3",
            cabin: "BUSINESS",
            fareBasis: "OAAAPPK1",
            brandedFare: "BSSAVER",
            brandedFareLabel: "BUSINESS SAVER",
            class: "O",
            includedCheckedBags: {
              weight: 40,
              weightUnit: "KG",
            },
            amenities: [
              {
                description: "PRE RESERVED SEAT ASSIGNMENT",
                isChargeable: false,
                amenityType: "PRE_RESERVED_SEAT",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "BEVERAGE",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "MEAL VOUCHER",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "FAST TRACK",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PREMIUM CHECK IN",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BOARDING",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BAGGAGE",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
            ],
          },
          {
            segmentId: "4",
            cabin: "BUSINESS",
            fareBasis: "OAAAPPK1",
            brandedFare: "BSSAVER",
            brandedFareLabel: "BUSINESS SAVER",
            class: "O",
            includedCheckedBags: {
              weight: 40,
              weightUnit: "KG",
            },
            amenities: [
              {
                description: "PRE RESERVED SEAT ASSIGNMENT",
                isChargeable: false,
                amenityType: "PRE_RESERVED_SEAT",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "BEVERAGE",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "MEAL VOUCHER",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "FAST TRACK",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PREMIUM CHECK IN",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BOARDING",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BAGGAGE",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
            ],
          },
          {
            segmentId: "30",
            cabin: "BUSINESS",
            fareBasis: "OAAAPPK1",
            brandedFare: "BSSAVER",
            brandedFareLabel: "BUSINESS SAVER",
            class: "O",
            includedCheckedBags: {
              weight: 40,
              weightUnit: "KG",
            },
            amenities: [
              {
                description: "PRE RESERVED SEAT ASSIGNMENT",
                isChargeable: false,
                amenityType: "PRE_RESERVED_SEAT",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "BEVERAGE",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "MEAL VOUCHER",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "FAST TRACK",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PREMIUM CHECK IN",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BOARDING",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BAGGAGE",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
            ],
          },
          {
            segmentId: "31",
            cabin: "BUSINESS",
            fareBasis: "OAAAPPK1",
            brandedFare: "BSSAVER",
            brandedFareLabel: "BUSINESS SAVER",
            class: "O",
            includedCheckedBags: {
              weight: 40,
              weightUnit: "KG",
            },
            amenities: [
              {
                description: "PRE RESERVED SEAT ASSIGNMENT",
                isChargeable: false,
                amenityType: "PRE_RESERVED_SEAT",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "BEVERAGE",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "MEAL VOUCHER",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "FAST TRACK",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PREMIUM CHECK IN",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BOARDING",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BAGGAGE",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
            ],
          },
        ],
      },
    ],
  },
  {
    type: "flight-offer",
    id: "8",
    source: "GDS",
    instantTicketingRequired: false,
    nonHomogeneous: false,
    oneWay: false,
    lastTicketingDate: "2024-05-06",
    lastTicketingDateTime: "2024-05-06",
    numberOfBookableSeats: 7,
    itineraries: [
      {
        duration: "PT16H30M",
        segments: [
          {
            departure: {
              iataCode: "LHE",
              at: "2024-05-06T03:30:00",
            },
            arrival: {
              iataCode: "DXB",
              terminal: "3",
              at: "2024-05-06T05:40:00",
            },
            carrierCode: "EK",
            number: "623",
            aircraft: {
              code: "77W",
            },
            operating: {
              carrierCode: "EK",
            },
            duration: "PT3H10M",
            id: "17",
            numberOfStops: 0,
            blacklistedInEU: false,
          },
          {
            departure: {
              iataCode: "DXB",
              terminal: "3",
              at: "2024-05-06T15:40:00",
            },
            arrival: {
              iataCode: "JED",
              terminal: "1",
              at: "2024-05-06T18:00:00",
            },
            carrierCode: "EK",
            number: "803",
            aircraft: {
              code: "388",
            },
            operating: {
              carrierCode: "EK",
            },
            duration: "PT3H20M",
            id: "18",
            numberOfStops: 0,
            blacklistedInEU: false,
          },
        ],
      },
      {
        duration: "PT11H20M",
        segments: [
          {
            departure: {
              iataCode: "JED",
              terminal: "1",
              at: "2024-05-11T12:30:00",
            },
            arrival: {
              iataCode: "DXB",
              terminal: "2",
              at: "2024-05-11T16:30:00",
            },
            carrierCode: "EK",
            number: "2335",
            aircraft: {
              code: "73H",
            },
            operating: {
              carrierCode: "FZ",
            },
            duration: "PT3H",
            id: "40",
            numberOfStops: 0,
            blacklistedInEU: false,
          },
          {
            departure: {
              iataCode: "DXB",
              terminal: "3",
              at: "2024-05-11T21:50:00",
            },
            arrival: {
              iataCode: "LHE",
              at: "2024-05-12T01:50:00",
            },
            carrierCode: "EK",
            number: "622",
            aircraft: {
              code: "77W",
            },
            operating: {
              carrierCode: "EK",
            },
            duration: "PT3H",
            id: "41",
            numberOfStops: 0,
            blacklistedInEU: false,
          },
        ],
      },
    ],
    price: {
      currency: "USD",
      total: "1893.86",
      base: "1538.00",
      fees: [
        {
          amount: "0.00",
          type: "SUPPLIER",
        },
        {
          amount: "0.00",
          type: "TICKETING",
        },
      ],
      grandTotal: "1893.86",
    },
    pricingOptions: {
      fareType: ["PUBLISHED"],
      includedCheckedBagsOnly: true,
    },
    validatingAirlineCodes: ["EK"],
    travelerPricings: [
      {
        travelerId: "1",
        fareOption: "STANDARD",
        travelerType: "ADULT",
        price: {
          currency: "USD",
          total: "1893.86",
          base: "1538.00",
        },
        fareDetailsBySegment: [
          {
            segmentId: "17",
            cabin: "BUSINESS",
            fareBasis: "OAAAPPK1",
            brandedFare: "BSSAVER",
            brandedFareLabel: "BUSINESS SAVER",
            class: "O",
            includedCheckedBags: {
              weight: 40,
              weightUnit: "KG",
            },
            amenities: [
              {
                description: "PRE RESERVED SEAT ASSIGNMENT",
                isChargeable: false,
                amenityType: "PRE_RESERVED_SEAT",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "BEVERAGE",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "MEAL VOUCHER",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "FAST TRACK",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PREMIUM CHECK IN",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BOARDING",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BAGGAGE",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
            ],
          },
          {
            segmentId: "18",
            cabin: "BUSINESS",
            fareBasis: "OAAAPPK1",
            brandedFare: "BSSAVER",
            brandedFareLabel: "BUSINESS SAVER",
            class: "O",
            includedCheckedBags: {
              weight: 40,
              weightUnit: "KG",
            },
            amenities: [
              {
                description: "PRE RESERVED SEAT ASSIGNMENT",
                isChargeable: false,
                amenityType: "PRE_RESERVED_SEAT",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "BEVERAGE",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "MEAL VOUCHER",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "FAST TRACK",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PREMIUM CHECK IN",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BOARDING",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BAGGAGE",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
            ],
          },
          {
            segmentId: "40",
            cabin: "BUSINESS",
            fareBasis: "OAAAPPK1",
            brandedFare: "BSSAVER",
            brandedFareLabel: "BUSINESS SAVER",
            class: "O",
            includedCheckedBags: {
              weight: 40,
              weightUnit: "KG",
            },
            amenities: [
              {
                description: "PRE RESERVED SEAT ASSIGNMENT",
                isChargeable: false,
                amenityType: "PRE_RESERVED_SEAT",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "BEVERAGE",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "MEAL VOUCHER",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "FAST TRACK",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PREMIUM CHECK IN",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BOARDING",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BAGGAGE",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
            ],
          },
          {
            segmentId: "41",
            cabin: "BUSINESS",
            fareBasis: "OAAAPPK1",
            brandedFare: "BSSAVER",
            brandedFareLabel: "BUSINESS SAVER",
            class: "O",
            includedCheckedBags: {
              weight: 40,
              weightUnit: "KG",
            },
            amenities: [
              {
                description: "PRE RESERVED SEAT ASSIGNMENT",
                isChargeable: false,
                amenityType: "PRE_RESERVED_SEAT",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "BEVERAGE",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "MEAL VOUCHER",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "FAST TRACK",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PREMIUM CHECK IN",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BOARDING",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BAGGAGE",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
            ],
          },
        ],
      },
    ],
  },
  {
    type: "flight-offer",
    id: "9",
    source: "GDS",
    instantTicketingRequired: false,
    nonHomogeneous: false,
    oneWay: false,
    lastTicketingDate: "2024-05-06",
    lastTicketingDateTime: "2024-05-06",
    numberOfBookableSeats: 7,
    itineraries: [
      {
        duration: "PT10H",
        segments: [
          {
            departure: {
              iataCode: "LHE",
              at: "2024-05-06T03:30:00",
            },
            arrival: {
              iataCode: "DXB",
              terminal: "3",
              at: "2024-05-06T05:40:00",
            },
            carrierCode: "EK",
            number: "623",
            aircraft: {
              code: "77W",
            },
            operating: {
              carrierCode: "EK",
            },
            duration: "PT3H10M",
            id: "1",
            numberOfStops: 0,
            blacklistedInEU: false,
          },
          {
            departure: {
              iataCode: "DXB",
              terminal: "2",
              at: "2024-05-06T09:25:00",
            },
            arrival: {
              iataCode: "JED",
              terminal: "1",
              at: "2024-05-06T11:30:00",
            },
            carrierCode: "EK",
            number: "2334",
            aircraft: {
              code: "73H",
            },
            operating: {
              carrierCode: "FZ",
            },
            duration: "PT3H5M",
            id: "2",
            numberOfStops: 0,
            blacklistedInEU: false,
          },
        ],
      },
      {
        duration: "PT19H40M",
        segments: [
          {
            departure: {
              iataCode: "JED",
              terminal: "1",
              at: "2024-05-11T04:10:00",
            },
            arrival: {
              iataCode: "DXB",
              terminal: "3",
              at: "2024-05-11T07:55:00",
            },
            carrierCode: "EK",
            number: "802",
            aircraft: {
              code: "388",
            },
            operating: {
              carrierCode: "EK",
            },
            duration: "PT2H45M",
            id: "44",
            numberOfStops: 0,
            blacklistedInEU: false,
          },
          {
            departure: {
              iataCode: "DXB",
              terminal: "3",
              at: "2024-05-11T21:50:00",
            },
            arrival: {
              iataCode: "LHE",
              at: "2024-05-12T01:50:00",
            },
            carrierCode: "EK",
            number: "622",
            aircraft: {
              code: "77W",
            },
            operating: {
              carrierCode: "EK",
            },
            duration: "PT3H",
            id: "45",
            numberOfStops: 0,
            blacklistedInEU: false,
          },
        ],
      },
    ],
    price: {
      currency: "USD",
      total: "1893.86",
      base: "1538.00",
      fees: [
        {
          amount: "0.00",
          type: "SUPPLIER",
        },
        {
          amount: "0.00",
          type: "TICKETING",
        },
      ],
      grandTotal: "1893.86",
    },
    pricingOptions: {
      fareType: ["PUBLISHED"],
      includedCheckedBagsOnly: true,
    },
    validatingAirlineCodes: ["EK"],
    travelerPricings: [
      {
        travelerId: "1",
        fareOption: "STANDARD",
        travelerType: "ADULT",
        price: {
          currency: "USD",
          total: "1893.86",
          base: "1538.00",
        },
        fareDetailsBySegment: [
          {
            segmentId: "1",
            cabin: "BUSINESS",
            fareBasis: "OAAAPPK1",
            brandedFare: "BSSAVER",
            brandedFareLabel: "BUSINESS SAVER",
            class: "O",
            includedCheckedBags: {
              weight: 40,
              weightUnit: "KG",
            },
            amenities: [
              {
                description: "PRE RESERVED SEAT ASSIGNMENT",
                isChargeable: false,
                amenityType: "PRE_RESERVED_SEAT",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "BEVERAGE",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "MEAL VOUCHER",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "FAST TRACK",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PREMIUM CHECK IN",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BOARDING",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BAGGAGE",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
            ],
          },
          {
            segmentId: "2",
            cabin: "BUSINESS",
            fareBasis: "OAAAPPK1",
            brandedFare: "BSSAVER",
            brandedFareLabel: "BUSINESS SAVER",
            class: "O",
            includedCheckedBags: {
              weight: 40,
              weightUnit: "KG",
            },
            amenities: [
              {
                description: "PRE RESERVED SEAT ASSIGNMENT",
                isChargeable: false,
                amenityType: "PRE_RESERVED_SEAT",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "BEVERAGE",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "MEAL VOUCHER",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "FAST TRACK",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PREMIUM CHECK IN",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BOARDING",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BAGGAGE",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
            ],
          },
          {
            segmentId: "44",
            cabin: "BUSINESS",
            fareBasis: "OAAAPPK1",
            brandedFare: "BSSAVER",
            brandedFareLabel: "BUSINESS SAVER",
            class: "O",
            includedCheckedBags: {
              weight: 40,
              weightUnit: "KG",
            },
            amenities: [
              {
                description: "PRE RESERVED SEAT ASSIGNMENT",
                isChargeable: false,
                amenityType: "PRE_RESERVED_SEAT",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "BEVERAGE",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "MEAL VOUCHER",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "FAST TRACK",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PREMIUM CHECK IN",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BOARDING",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BAGGAGE",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
            ],
          },
          {
            segmentId: "45",
            cabin: "BUSINESS",
            fareBasis: "OAAAPPK1",
            brandedFare: "BSSAVER",
            brandedFareLabel: "BUSINESS SAVER",
            class: "O",
            includedCheckedBags: {
              weight: 40,
              weightUnit: "KG",
            },
            amenities: [
              {
                description: "PRE RESERVED SEAT ASSIGNMENT",
                isChargeable: false,
                amenityType: "PRE_RESERVED_SEAT",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "BEVERAGE",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "MEAL VOUCHER",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "FAST TRACK",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PREMIUM CHECK IN",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BOARDING",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BAGGAGE",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
            ],
          },
        ],
      },
    ],
  },
  {
    type: "flight-offer",
    id: "10",
    source: "GDS",
    instantTicketingRequired: false,
    nonHomogeneous: false,
    oneWay: false,
    lastTicketingDate: "2024-05-06",
    lastTicketingDateTime: "2024-05-06",
    numberOfBookableSeats: 7,
    itineraries: [
      {
        duration: "PT19H10M",
        segments: [
          {
            departure: {
              iataCode: "LHE",
              at: "2024-05-06T09:00:00",
            },
            arrival: {
              iataCode: "DXB",
              terminal: "3",
              at: "2024-05-06T11:15:00",
            },
            carrierCode: "EK",
            number: "625",
            aircraft: {
              code: "77W",
            },
            operating: {
              carrierCode: "EK",
            },
            duration: "PT3H15M",
            id: "8",
            numberOfStops: 0,
            blacklistedInEU: false,
          },
          {
            departure: {
              iataCode: "DXB",
              terminal: "3",
              at: "2024-05-07T00:10:00",
            },
            arrival: {
              iataCode: "JED",
              terminal: "1",
              at: "2024-05-07T02:10:00",
            },
            carrierCode: "EK",
            number: "801",
            aircraft: {
              code: "388",
            },
            operating: {
              carrierCode: "EK",
            },
            duration: "PT3H",
            id: "9",
            numberOfStops: 0,
            blacklistedInEU: false,
          },
        ],
      },
      {
        duration: "PT11H20M",
        segments: [
          {
            departure: {
              iataCode: "JED",
              terminal: "1",
              at: "2024-05-11T12:30:00",
            },
            arrival: {
              iataCode: "DXB",
              terminal: "2",
              at: "2024-05-11T16:30:00",
            },
            carrierCode: "EK",
            number: "2335",
            aircraft: {
              code: "73H",
            },
            operating: {
              carrierCode: "FZ",
            },
            duration: "PT3H",
            id: "40",
            numberOfStops: 0,
            blacklistedInEU: false,
          },
          {
            departure: {
              iataCode: "DXB",
              terminal: "3",
              at: "2024-05-11T21:50:00",
            },
            arrival: {
              iataCode: "LHE",
              at: "2024-05-12T01:50:00",
            },
            carrierCode: "EK",
            number: "622",
            aircraft: {
              code: "77W",
            },
            operating: {
              carrierCode: "EK",
            },
            duration: "PT3H",
            id: "41",
            numberOfStops: 0,
            blacklistedInEU: false,
          },
        ],
      },
    ],
    price: {
      currency: "USD",
      total: "1893.86",
      base: "1538.00",
      fees: [
        {
          amount: "0.00",
          type: "SUPPLIER",
        },
        {
          amount: "0.00",
          type: "TICKETING",
        },
      ],
      grandTotal: "1893.86",
    },
    pricingOptions: {
      fareType: ["PUBLISHED"],
      includedCheckedBagsOnly: true,
    },
    validatingAirlineCodes: ["EK"],
    travelerPricings: [
      {
        travelerId: "1",
        fareOption: "STANDARD",
        travelerType: "ADULT",
        price: {
          currency: "USD",
          total: "1893.86",
          base: "1538.00",
        },
        fareDetailsBySegment: [
          {
            segmentId: "8",
            cabin: "BUSINESS",
            fareBasis: "OAAAPPK1",
            brandedFare: "BSSAVER",
            brandedFareLabel: "BUSINESS SAVER",
            class: "O",
            includedCheckedBags: {
              weight: 40,
              weightUnit: "KG",
            },
            amenities: [
              {
                description: "PRE RESERVED SEAT ASSIGNMENT",
                isChargeable: false,
                amenityType: "PRE_RESERVED_SEAT",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "BEVERAGE",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "MEAL VOUCHER",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "FAST TRACK",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PREMIUM CHECK IN",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BOARDING",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BAGGAGE",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
            ],
          },
          {
            segmentId: "9",
            cabin: "BUSINESS",
            fareBasis: "OAAAPPK1",
            brandedFare: "BSSAVER",
            brandedFareLabel: "BUSINESS SAVER",
            class: "O",
            includedCheckedBags: {
              weight: 40,
              weightUnit: "KG",
            },
            amenities: [
              {
                description: "PRE RESERVED SEAT ASSIGNMENT",
                isChargeable: false,
                amenityType: "PRE_RESERVED_SEAT",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "BEVERAGE",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "MEAL VOUCHER",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "FAST TRACK",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PREMIUM CHECK IN",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BOARDING",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BAGGAGE",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
            ],
          },
          {
            segmentId: "40",
            cabin: "BUSINESS",
            fareBasis: "OAAAPPK1",
            brandedFare: "BSSAVER",
            brandedFareLabel: "BUSINESS SAVER",
            class: "O",
            includedCheckedBags: {
              weight: 40,
              weightUnit: "KG",
            },
            amenities: [
              {
                description: "PRE RESERVED SEAT ASSIGNMENT",
                isChargeable: false,
                amenityType: "PRE_RESERVED_SEAT",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "BEVERAGE",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "MEAL VOUCHER",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "FAST TRACK",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PREMIUM CHECK IN",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BOARDING",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BAGGAGE",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
            ],
          },
          {
            segmentId: "41",
            cabin: "BUSINESS",
            fareBasis: "OAAAPPK1",
            brandedFare: "BSSAVER",
            brandedFareLabel: "BUSINESS SAVER",
            class: "O",
            includedCheckedBags: {
              weight: 40,
              weightUnit: "KG",
            },
            amenities: [
              {
                description: "PRE RESERVED SEAT ASSIGNMENT",
                isChargeable: false,
                amenityType: "PRE_RESERVED_SEAT",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "BEVERAGE",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "MEAL VOUCHER",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "FAST TRACK",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PREMIUM CHECK IN",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BOARDING",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BAGGAGE",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
            ],
          },
        ],
      },
    ],
  },
  {
    type: "flight-offer",
    id: "11",
    source: "GDS",
    instantTicketingRequired: false,
    nonHomogeneous: false,
    oneWay: false,
    lastTicketingDate: "2024-05-06",
    lastTicketingDateTime: "2024-05-06",
    numberOfBookableSeats: 7,
    itineraries: [
      {
        duration: "PT16H30M",
        segments: [
          {
            departure: {
              iataCode: "LHE",
              at: "2024-05-06T03:30:00",
            },
            arrival: {
              iataCode: "DXB",
              terminal: "3",
              at: "2024-05-06T05:40:00",
            },
            carrierCode: "EK",
            number: "623",
            aircraft: {
              code: "77W",
            },
            operating: {
              carrierCode: "EK",
            },
            duration: "PT3H10M",
            id: "17",
            numberOfStops: 0,
            blacklistedInEU: false,
          },
          {
            departure: {
              iataCode: "DXB",
              terminal: "3",
              at: "2024-05-06T15:40:00",
            },
            arrival: {
              iataCode: "JED",
              terminal: "1",
              at: "2024-05-06T18:00:00",
            },
            carrierCode: "EK",
            number: "803",
            aircraft: {
              code: "388",
            },
            operating: {
              carrierCode: "EK",
            },
            duration: "PT3H20M",
            id: "18",
            numberOfStops: 0,
            blacklistedInEU: false,
          },
        ],
      },
      {
        duration: "PT16H",
        segments: [
          {
            departure: {
              iataCode: "JED",
              terminal: "1",
              at: "2024-05-11T07:50:00",
            },
            arrival: {
              iataCode: "DXB",
              terminal: "2",
              at: "2024-05-11T11:50:00",
            },
            carrierCode: "EK",
            number: "2259",
            aircraft: {
              code: "7M8",
            },
            operating: {
              carrierCode: "FZ",
            },
            duration: "PT3H",
            id: "30",
            numberOfStops: 0,
            blacklistedInEU: false,
          },
          {
            departure: {
              iataCode: "DXB",
              terminal: "3",
              at: "2024-05-11T21:50:00",
            },
            arrival: {
              iataCode: "LHE",
              at: "2024-05-12T01:50:00",
            },
            carrierCode: "EK",
            number: "622",
            aircraft: {
              code: "77W",
            },
            operating: {
              carrierCode: "EK",
            },
            duration: "PT3H",
            id: "31",
            numberOfStops: 0,
            blacklistedInEU: false,
          },
        ],
      },
    ],
    price: {
      currency: "USD",
      total: "1893.86",
      base: "1538.00",
      fees: [
        {
          amount: "0.00",
          type: "SUPPLIER",
        },
        {
          amount: "0.00",
          type: "TICKETING",
        },
      ],
      grandTotal: "1893.86",
    },
    pricingOptions: {
      fareType: ["PUBLISHED"],
      includedCheckedBagsOnly: true,
    },
    validatingAirlineCodes: ["EK"],
    travelerPricings: [
      {
        travelerId: "1",
        fareOption: "STANDARD",
        travelerType: "ADULT",
        price: {
          currency: "USD",
          total: "1893.86",
          base: "1538.00",
        },
        fareDetailsBySegment: [
          {
            segmentId: "17",
            cabin: "BUSINESS",
            fareBasis: "OAAAPPK1",
            brandedFare: "BSSAVER",
            brandedFareLabel: "BUSINESS SAVER",
            class: "O",
            includedCheckedBags: {
              weight: 40,
              weightUnit: "KG",
            },
            amenities: [
              {
                description: "PRE RESERVED SEAT ASSIGNMENT",
                isChargeable: false,
                amenityType: "PRE_RESERVED_SEAT",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "BEVERAGE",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "MEAL VOUCHER",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "FAST TRACK",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PREMIUM CHECK IN",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BOARDING",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BAGGAGE",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
            ],
          },
          {
            segmentId: "18",
            cabin: "BUSINESS",
            fareBasis: "OAAAPPK1",
            brandedFare: "BSSAVER",
            brandedFareLabel: "BUSINESS SAVER",
            class: "O",
            includedCheckedBags: {
              weight: 40,
              weightUnit: "KG",
            },
            amenities: [
              {
                description: "PRE RESERVED SEAT ASSIGNMENT",
                isChargeable: false,
                amenityType: "PRE_RESERVED_SEAT",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "BEVERAGE",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "MEAL VOUCHER",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "FAST TRACK",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PREMIUM CHECK IN",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BOARDING",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BAGGAGE",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
            ],
          },
          {
            segmentId: "30",
            cabin: "BUSINESS",
            fareBasis: "OAAAPPK1",
            brandedFare: "BSSAVER",
            brandedFareLabel: "BUSINESS SAVER",
            class: "O",
            includedCheckedBags: {
              weight: 40,
              weightUnit: "KG",
            },
            amenities: [
              {
                description: "PRE RESERVED SEAT ASSIGNMENT",
                isChargeable: false,
                amenityType: "PRE_RESERVED_SEAT",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "BEVERAGE",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "MEAL VOUCHER",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "FAST TRACK",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PREMIUM CHECK IN",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BOARDING",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BAGGAGE",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
            ],
          },
          {
            segmentId: "31",
            cabin: "BUSINESS",
            fareBasis: "OAAAPPK1",
            brandedFare: "BSSAVER",
            brandedFareLabel: "BUSINESS SAVER",
            class: "O",
            includedCheckedBags: {
              weight: 40,
              weightUnit: "KG",
            },
            amenities: [
              {
                description: "PRE RESERVED SEAT ASSIGNMENT",
                isChargeable: false,
                amenityType: "PRE_RESERVED_SEAT",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "BEVERAGE",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "MEAL VOUCHER",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "FAST TRACK",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PREMIUM CHECK IN",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BOARDING",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BAGGAGE",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
            ],
          },
        ],
      },
    ],
  },
  {
    type: "flight-offer",
    id: "12",
    source: "GDS",
    instantTicketingRequired: false,
    nonHomogeneous: false,
    oneWay: false,
    lastTicketingDate: "2024-05-06",
    lastTicketingDateTime: "2024-05-06",
    numberOfBookableSeats: 7,
    itineraries: [
      {
        duration: "PT19H10M",
        segments: [
          {
            departure: {
              iataCode: "LHE",
              at: "2024-05-06T09:00:00",
            },
            arrival: {
              iataCode: "DXB",
              terminal: "3",
              at: "2024-05-06T11:15:00",
            },
            carrierCode: "EK",
            number: "625",
            aircraft: {
              code: "77W",
            },
            operating: {
              carrierCode: "EK",
            },
            duration: "PT3H15M",
            id: "8",
            numberOfStops: 0,
            blacklistedInEU: false,
          },
          {
            departure: {
              iataCode: "DXB",
              terminal: "3",
              at: "2024-05-07T00:10:00",
            },
            arrival: {
              iataCode: "JED",
              terminal: "1",
              at: "2024-05-07T02:10:00",
            },
            carrierCode: "EK",
            number: "801",
            aircraft: {
              code: "388",
            },
            operating: {
              carrierCode: "EK",
            },
            duration: "PT3H",
            id: "9",
            numberOfStops: 0,
            blacklistedInEU: false,
          },
        ],
      },
      {
        duration: "PT16H",
        segments: [
          {
            departure: {
              iataCode: "JED",
              terminal: "1",
              at: "2024-05-11T07:50:00",
            },
            arrival: {
              iataCode: "DXB",
              terminal: "2",
              at: "2024-05-11T11:50:00",
            },
            carrierCode: "EK",
            number: "2259",
            aircraft: {
              code: "7M8",
            },
            operating: {
              carrierCode: "FZ",
            },
            duration: "PT3H",
            id: "30",
            numberOfStops: 0,
            blacklistedInEU: false,
          },
          {
            departure: {
              iataCode: "DXB",
              terminal: "3",
              at: "2024-05-11T21:50:00",
            },
            arrival: {
              iataCode: "LHE",
              at: "2024-05-12T01:50:00",
            },
            carrierCode: "EK",
            number: "622",
            aircraft: {
              code: "77W",
            },
            operating: {
              carrierCode: "EK",
            },
            duration: "PT3H",
            id: "31",
            numberOfStops: 0,
            blacklistedInEU: false,
          },
        ],
      },
    ],
    price: {
      currency: "USD",
      total: "1893.86",
      base: "1538.00",
      fees: [
        {
          amount: "0.00",
          type: "SUPPLIER",
        },
        {
          amount: "0.00",
          type: "TICKETING",
        },
      ],
      grandTotal: "1893.86",
    },
    pricingOptions: {
      fareType: ["PUBLISHED"],
      includedCheckedBagsOnly: true,
    },
    validatingAirlineCodes: ["EK"],
    travelerPricings: [
      {
        travelerId: "1",
        fareOption: "STANDARD",
        travelerType: "ADULT",
        price: {
          currency: "USD",
          total: "1893.86",
          base: "1538.00",
        },
        fareDetailsBySegment: [
          {
            segmentId: "8",
            cabin: "BUSINESS",
            fareBasis: "OAAAPPK1",
            brandedFare: "BSSAVER",
            brandedFareLabel: "BUSINESS SAVER",
            class: "O",
            includedCheckedBags: {
              weight: 40,
              weightUnit: "KG",
            },
            amenities: [
              {
                description: "PRE RESERVED SEAT ASSIGNMENT",
                isChargeable: false,
                amenityType: "PRE_RESERVED_SEAT",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "BEVERAGE",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "MEAL VOUCHER",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "FAST TRACK",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PREMIUM CHECK IN",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BOARDING",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BAGGAGE",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
            ],
          },
          {
            segmentId: "9",
            cabin: "BUSINESS",
            fareBasis: "OAAAPPK1",
            brandedFare: "BSSAVER",
            brandedFareLabel: "BUSINESS SAVER",
            class: "O",
            includedCheckedBags: {
              weight: 40,
              weightUnit: "KG",
            },
            amenities: [
              {
                description: "PRE RESERVED SEAT ASSIGNMENT",
                isChargeable: false,
                amenityType: "PRE_RESERVED_SEAT",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "BEVERAGE",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "MEAL VOUCHER",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "FAST TRACK",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PREMIUM CHECK IN",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BOARDING",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BAGGAGE",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
            ],
          },
          {
            segmentId: "30",
            cabin: "BUSINESS",
            fareBasis: "OAAAPPK1",
            brandedFare: "BSSAVER",
            brandedFareLabel: "BUSINESS SAVER",
            class: "O",
            includedCheckedBags: {
              weight: 40,
              weightUnit: "KG",
            },
            amenities: [
              {
                description: "PRE RESERVED SEAT ASSIGNMENT",
                isChargeable: false,
                amenityType: "PRE_RESERVED_SEAT",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "BEVERAGE",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "MEAL VOUCHER",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "FAST TRACK",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PREMIUM CHECK IN",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BOARDING",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BAGGAGE",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
            ],
          },
          {
            segmentId: "31",
            cabin: "BUSINESS",
            fareBasis: "OAAAPPK1",
            brandedFare: "BSSAVER",
            brandedFareLabel: "BUSINESS SAVER",
            class: "O",
            includedCheckedBags: {
              weight: 40,
              weightUnit: "KG",
            },
            amenities: [
              {
                description: "PRE RESERVED SEAT ASSIGNMENT",
                isChargeable: false,
                amenityType: "PRE_RESERVED_SEAT",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "BEVERAGE",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "MEAL VOUCHER",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "FAST TRACK",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PREMIUM CHECK IN",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BOARDING",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BAGGAGE",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
            ],
          },
        ],
      },
    ],
  },
  {
    type: "flight-offer",
    id: "13",
    source: "GDS",
    instantTicketingRequired: false,
    nonHomogeneous: false,
    oneWay: false,
    lastTicketingDate: "2024-05-06",
    lastTicketingDateTime: "2024-05-06",
    numberOfBookableSeats: 7,
    itineraries: [
      {
        duration: "PT23H50M",
        segments: [
          {
            departure: {
              iataCode: "LHE",
              at: "2024-05-06T09:00:00",
            },
            arrival: {
              iataCode: "DXB",
              terminal: "3",
              at: "2024-05-06T11:15:00",
            },
            carrierCode: "EK",
            number: "625",
            aircraft: {
              code: "77W",
            },
            operating: {
              carrierCode: "EK",
            },
            duration: "PT3H15M",
            id: "23",
            numberOfStops: 0,
            blacklistedInEU: false,
          },
          {
            departure: {
              iataCode: "DXB",
              terminal: "2",
              at: "2024-05-07T04:45:00",
            },
            arrival: {
              iataCode: "JED",
              terminal: "1",
              at: "2024-05-07T06:50:00",
            },
            carrierCode: "EK",
            number: "2258",
            aircraft: {
              code: "7M8",
            },
            operating: {
              carrierCode: "FZ",
            },
            duration: "PT3H5M",
            id: "24",
            numberOfStops: 0,
            blacklistedInEU: false,
          },
        ],
      },
      {
        duration: "PT11H20M",
        segments: [
          {
            departure: {
              iataCode: "JED",
              terminal: "1",
              at: "2024-05-11T12:30:00",
            },
            arrival: {
              iataCode: "DXB",
              terminal: "2",
              at: "2024-05-11T16:30:00",
            },
            carrierCode: "EK",
            number: "2335",
            aircraft: {
              code: "73H",
            },
            operating: {
              carrierCode: "FZ",
            },
            duration: "PT3H",
            id: "40",
            numberOfStops: 0,
            blacklistedInEU: false,
          },
          {
            departure: {
              iataCode: "DXB",
              terminal: "3",
              at: "2024-05-11T21:50:00",
            },
            arrival: {
              iataCode: "LHE",
              at: "2024-05-12T01:50:00",
            },
            carrierCode: "EK",
            number: "622",
            aircraft: {
              code: "77W",
            },
            operating: {
              carrierCode: "EK",
            },
            duration: "PT3H",
            id: "41",
            numberOfStops: 0,
            blacklistedInEU: false,
          },
        ],
      },
    ],
    price: {
      currency: "USD",
      total: "1893.86",
      base: "1538.00",
      fees: [
        {
          amount: "0.00",
          type: "SUPPLIER",
        },
        {
          amount: "0.00",
          type: "TICKETING",
        },
      ],
      grandTotal: "1893.86",
    },
    pricingOptions: {
      fareType: ["PUBLISHED"],
      includedCheckedBagsOnly: true,
    },
    validatingAirlineCodes: ["EK"],
    travelerPricings: [
      {
        travelerId: "1",
        fareOption: "STANDARD",
        travelerType: "ADULT",
        price: {
          currency: "USD",
          total: "1893.86",
          base: "1538.00",
        },
        fareDetailsBySegment: [
          {
            segmentId: "23",
            cabin: "BUSINESS",
            fareBasis: "OAAAPPK1",
            brandedFare: "BSSAVER",
            brandedFareLabel: "BUSINESS SAVER",
            class: "O",
            includedCheckedBags: {
              weight: 40,
              weightUnit: "KG",
            },
            amenities: [
              {
                description: "PRE RESERVED SEAT ASSIGNMENT",
                isChargeable: false,
                amenityType: "PRE_RESERVED_SEAT",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "BEVERAGE",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "MEAL VOUCHER",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "FAST TRACK",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PREMIUM CHECK IN",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BOARDING",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BAGGAGE",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
            ],
          },
          {
            segmentId: "24",
            cabin: "BUSINESS",
            fareBasis: "OAAAPPK1",
            brandedFare: "BSSAVER",
            brandedFareLabel: "BUSINESS SAVER",
            class: "O",
            includedCheckedBags: {
              weight: 40,
              weightUnit: "KG",
            },
            amenities: [
              {
                description: "PRE RESERVED SEAT ASSIGNMENT",
                isChargeable: false,
                amenityType: "PRE_RESERVED_SEAT",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "BEVERAGE",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "MEAL VOUCHER",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "FAST TRACK",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PREMIUM CHECK IN",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BOARDING",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BAGGAGE",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
            ],
          },
          {
            segmentId: "40",
            cabin: "BUSINESS",
            fareBasis: "OAAAPPK1",
            brandedFare: "BSSAVER",
            brandedFareLabel: "BUSINESS SAVER",
            class: "O",
            includedCheckedBags: {
              weight: 40,
              weightUnit: "KG",
            },
            amenities: [
              {
                description: "PRE RESERVED SEAT ASSIGNMENT",
                isChargeable: false,
                amenityType: "PRE_RESERVED_SEAT",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "BEVERAGE",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "MEAL VOUCHER",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "FAST TRACK",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PREMIUM CHECK IN",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BOARDING",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BAGGAGE",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
            ],
          },
          {
            segmentId: "41",
            cabin: "BUSINESS",
            fareBasis: "OAAAPPK1",
            brandedFare: "BSSAVER",
            brandedFareLabel: "BUSINESS SAVER",
            class: "O",
            includedCheckedBags: {
              weight: 40,
              weightUnit: "KG",
            },
            amenities: [
              {
                description: "PRE RESERVED SEAT ASSIGNMENT",
                isChargeable: false,
                amenityType: "PRE_RESERVED_SEAT",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "BEVERAGE",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "MEAL VOUCHER",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "FAST TRACK",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PREMIUM CHECK IN",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BOARDING",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BAGGAGE",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
            ],
          },
        ],
      },
    ],
  },
  {
    type: "flight-offer",
    id: "14",
    source: "GDS",
    instantTicketingRequired: false,
    nonHomogeneous: false,
    oneWay: false,
    lastTicketingDate: "2024-05-06",
    lastTicketingDateTime: "2024-05-06",
    numberOfBookableSeats: 7,
    itineraries: [
      {
        duration: "PT24H40M",
        segments: [
          {
            departure: {
              iataCode: "LHE",
              at: "2024-05-06T03:30:00",
            },
            arrival: {
              iataCode: "DXB",
              terminal: "3",
              at: "2024-05-06T05:40:00",
            },
            carrierCode: "EK",
            number: "623",
            aircraft: {
              code: "77W",
            },
            operating: {
              carrierCode: "EK",
            },
            duration: "PT3H10M",
            id: "21",
            numberOfStops: 0,
            blacklistedInEU: false,
          },
          {
            departure: {
              iataCode: "DXB",
              terminal: "3",
              at: "2024-05-07T00:10:00",
            },
            arrival: {
              iataCode: "JED",
              terminal: "1",
              at: "2024-05-07T02:10:00",
            },
            carrierCode: "EK",
            number: "801",
            aircraft: {
              code: "388",
            },
            operating: {
              carrierCode: "EK",
            },
            duration: "PT3H",
            id: "22",
            numberOfStops: 0,
            blacklistedInEU: false,
          },
        ],
      },
      {
        duration: "PT11H20M",
        segments: [
          {
            departure: {
              iataCode: "JED",
              terminal: "1",
              at: "2024-05-11T12:30:00",
            },
            arrival: {
              iataCode: "DXB",
              terminal: "2",
              at: "2024-05-11T16:30:00",
            },
            carrierCode: "EK",
            number: "2335",
            aircraft: {
              code: "73H",
            },
            operating: {
              carrierCode: "FZ",
            },
            duration: "PT3H",
            id: "40",
            numberOfStops: 0,
            blacklistedInEU: false,
          },
          {
            departure: {
              iataCode: "DXB",
              terminal: "3",
              at: "2024-05-11T21:50:00",
            },
            arrival: {
              iataCode: "LHE",
              at: "2024-05-12T01:50:00",
            },
            carrierCode: "EK",
            number: "622",
            aircraft: {
              code: "77W",
            },
            operating: {
              carrierCode: "EK",
            },
            duration: "PT3H",
            id: "41",
            numberOfStops: 0,
            blacklistedInEU: false,
          },
        ],
      },
    ],
    price: {
      currency: "USD",
      total: "1893.86",
      base: "1538.00",
      fees: [
        {
          amount: "0.00",
          type: "SUPPLIER",
        },
        {
          amount: "0.00",
          type: "TICKETING",
        },
      ],
      grandTotal: "1893.86",
    },
    pricingOptions: {
      fareType: ["PUBLISHED"],
      includedCheckedBagsOnly: true,
    },
    validatingAirlineCodes: ["EK"],
    travelerPricings: [
      {
        travelerId: "1",
        fareOption: "STANDARD",
        travelerType: "ADULT",
        price: {
          currency: "USD",
          total: "1893.86",
          base: "1538.00",
        },
        fareDetailsBySegment: [
          {
            segmentId: "21",
            cabin: "BUSINESS",
            fareBasis: "OAAAPPK1",
            brandedFare: "BSSAVER",
            brandedFareLabel: "BUSINESS SAVER",
            class: "O",
            includedCheckedBags: {
              weight: 40,
              weightUnit: "KG",
            },
            amenities: [
              {
                description: "PRE RESERVED SEAT ASSIGNMENT",
                isChargeable: false,
                amenityType: "PRE_RESERVED_SEAT",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "BEVERAGE",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "MEAL VOUCHER",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "FAST TRACK",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PREMIUM CHECK IN",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BOARDING",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BAGGAGE",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
            ],
          },
          {
            segmentId: "22",
            cabin: "BUSINESS",
            fareBasis: "OAAAPPK1",
            brandedFare: "BSSAVER",
            brandedFareLabel: "BUSINESS SAVER",
            class: "O",
            includedCheckedBags: {
              weight: 40,
              weightUnit: "KG",
            },
            amenities: [
              {
                description: "PRE RESERVED SEAT ASSIGNMENT",
                isChargeable: false,
                amenityType: "PRE_RESERVED_SEAT",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "BEVERAGE",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "MEAL VOUCHER",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "FAST TRACK",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PREMIUM CHECK IN",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BOARDING",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BAGGAGE",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
            ],
          },
          {
            segmentId: "40",
            cabin: "BUSINESS",
            fareBasis: "OAAAPPK1",
            brandedFare: "BSSAVER",
            brandedFareLabel: "BUSINESS SAVER",
            class: "O",
            includedCheckedBags: {
              weight: 40,
              weightUnit: "KG",
            },
            amenities: [
              {
                description: "PRE RESERVED SEAT ASSIGNMENT",
                isChargeable: false,
                amenityType: "PRE_RESERVED_SEAT",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "BEVERAGE",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "MEAL VOUCHER",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "FAST TRACK",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PREMIUM CHECK IN",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BOARDING",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BAGGAGE",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
            ],
          },
          {
            segmentId: "41",
            cabin: "BUSINESS",
            fareBasis: "OAAAPPK1",
            brandedFare: "BSSAVER",
            brandedFareLabel: "BUSINESS SAVER",
            class: "O",
            includedCheckedBags: {
              weight: 40,
              weightUnit: "KG",
            },
            amenities: [
              {
                description: "PRE RESERVED SEAT ASSIGNMENT",
                isChargeable: false,
                amenityType: "PRE_RESERVED_SEAT",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "BEVERAGE",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "MEAL VOUCHER",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "FAST TRACK",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PREMIUM CHECK IN",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BOARDING",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BAGGAGE",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
            ],
          },
        ],
      },
    ],
  },
  {
    type: "flight-offer",
    id: "15",
    source: "GDS",
    instantTicketingRequired: false,
    nonHomogeneous: false,
    oneWay: false,
    lastTicketingDate: "2024-05-06",
    lastTicketingDateTime: "2024-05-06",
    numberOfBookableSeats: 7,
    itineraries: [
      {
        duration: "PT23H50M",
        segments: [
          {
            departure: {
              iataCode: "LHE",
              at: "2024-05-06T09:00:00",
            },
            arrival: {
              iataCode: "DXB",
              terminal: "3",
              at: "2024-05-06T11:15:00",
            },
            carrierCode: "EK",
            number: "625",
            aircraft: {
              code: "77W",
            },
            operating: {
              carrierCode: "EK",
            },
            duration: "PT3H15M",
            id: "23",
            numberOfStops: 0,
            blacklistedInEU: false,
          },
          {
            departure: {
              iataCode: "DXB",
              terminal: "2",
              at: "2024-05-07T04:45:00",
            },
            arrival: {
              iataCode: "JED",
              terminal: "1",
              at: "2024-05-07T06:50:00",
            },
            carrierCode: "EK",
            number: "2258",
            aircraft: {
              code: "7M8",
            },
            operating: {
              carrierCode: "FZ",
            },
            duration: "PT3H5M",
            id: "24",
            numberOfStops: 0,
            blacklistedInEU: false,
          },
        ],
      },
      {
        duration: "PT13H5M",
        segments: [
          {
            departure: {
              iataCode: "JED",
              terminal: "1",
              at: "2024-05-11T10:45:00",
            },
            arrival: {
              iataCode: "DXB",
              terminal: "3",
              at: "2024-05-11T14:45:00",
            },
            carrierCode: "EK",
            number: "806",
            aircraft: {
              code: "388",
            },
            operating: {
              carrierCode: "EK",
            },
            duration: "PT3H",
            id: "32",
            numberOfStops: 0,
            blacklistedInEU: false,
          },
          {
            departure: {
              iataCode: "DXB",
              terminal: "3",
              at: "2024-05-11T21:50:00",
            },
            arrival: {
              iataCode: "LHE",
              at: "2024-05-12T01:50:00",
            },
            carrierCode: "EK",
            number: "622",
            aircraft: {
              code: "77W",
            },
            operating: {
              carrierCode: "EK",
            },
            duration: "PT3H",
            id: "33",
            numberOfStops: 0,
            blacklistedInEU: false,
          },
        ],
      },
    ],
    price: {
      currency: "USD",
      total: "1893.86",
      base: "1538.00",
      fees: [
        {
          amount: "0.00",
          type: "SUPPLIER",
        },
        {
          amount: "0.00",
          type: "TICKETING",
        },
      ],
      grandTotal: "1893.86",
    },
    pricingOptions: {
      fareType: ["PUBLISHED"],
      includedCheckedBagsOnly: true,
    },
    validatingAirlineCodes: ["EK"],
    travelerPricings: [
      {
        travelerId: "1",
        fareOption: "STANDARD",
        travelerType: "ADULT",
        price: {
          currency: "USD",
          total: "1893.86",
          base: "1538.00",
        },
        fareDetailsBySegment: [
          {
            segmentId: "23",
            cabin: "BUSINESS",
            fareBasis: "OAAAPPK1",
            brandedFare: "BSSAVER",
            brandedFareLabel: "BUSINESS SAVER",
            class: "O",
            includedCheckedBags: {
              weight: 40,
              weightUnit: "KG",
            },
            amenities: [
              {
                description: "PRE RESERVED SEAT ASSIGNMENT",
                isChargeable: false,
                amenityType: "PRE_RESERVED_SEAT",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "BEVERAGE",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "MEAL VOUCHER",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "FAST TRACK",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PREMIUM CHECK IN",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BOARDING",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BAGGAGE",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
            ],
          },
          {
            segmentId: "24",
            cabin: "BUSINESS",
            fareBasis: "OAAAPPK1",
            brandedFare: "BSSAVER",
            brandedFareLabel: "BUSINESS SAVER",
            class: "O",
            includedCheckedBags: {
              weight: 40,
              weightUnit: "KG",
            },
            amenities: [
              {
                description: "PRE RESERVED SEAT ASSIGNMENT",
                isChargeable: false,
                amenityType: "PRE_RESERVED_SEAT",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "BEVERAGE",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "MEAL VOUCHER",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "FAST TRACK",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PREMIUM CHECK IN",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BOARDING",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BAGGAGE",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
            ],
          },
          {
            segmentId: "32",
            cabin: "BUSINESS",
            fareBasis: "OAAAPPK1",
            brandedFare: "BSSAVER",
            brandedFareLabel: "BUSINESS SAVER",
            class: "O",
            includedCheckedBags: {
              weight: 40,
              weightUnit: "KG",
            },
            amenities: [
              {
                description: "PRE RESERVED SEAT ASSIGNMENT",
                isChargeable: false,
                amenityType: "PRE_RESERVED_SEAT",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "BEVERAGE",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "MEAL VOUCHER",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "FAST TRACK",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PREMIUM CHECK IN",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BOARDING",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BAGGAGE",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
            ],
          },
          {
            segmentId: "33",
            cabin: "BUSINESS",
            fareBasis: "OAAAPPK1",
            brandedFare: "BSSAVER",
            brandedFareLabel: "BUSINESS SAVER",
            class: "O",
            includedCheckedBags: {
              weight: 40,
              weightUnit: "KG",
            },
            amenities: [
              {
                description: "PRE RESERVED SEAT ASSIGNMENT",
                isChargeable: false,
                amenityType: "PRE_RESERVED_SEAT",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "BEVERAGE",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "MEAL VOUCHER",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "FAST TRACK",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PREMIUM CHECK IN",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BOARDING",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BAGGAGE",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
            ],
          },
        ],
      },
    ],
  },
  {
    type: "flight-offer",
    id: "16",
    source: "GDS",
    instantTicketingRequired: false,
    nonHomogeneous: false,
    oneWay: false,
    lastTicketingDate: "2024-05-06",
    lastTicketingDateTime: "2024-05-06",
    numberOfBookableSeats: 7,
    itineraries: [
      {
        duration: "PT10H",
        segments: [
          {
            departure: {
              iataCode: "LHE",
              at: "2024-05-06T03:30:00",
            },
            arrival: {
              iataCode: "DXB",
              terminal: "3",
              at: "2024-05-06T05:40:00",
            },
            carrierCode: "EK",
            number: "623",
            aircraft: {
              code: "77W",
            },
            operating: {
              carrierCode: "EK",
            },
            duration: "PT3H10M",
            id: "1",
            numberOfStops: 0,
            blacklistedInEU: false,
          },
          {
            departure: {
              iataCode: "DXB",
              terminal: "2",
              at: "2024-05-06T09:25:00",
            },
            arrival: {
              iataCode: "JED",
              terminal: "1",
              at: "2024-05-06T11:30:00",
            },
            carrierCode: "EK",
            number: "2334",
            aircraft: {
              code: "73H",
            },
            operating: {
              carrierCode: "FZ",
            },
            duration: "PT3H5M",
            id: "2",
            numberOfStops: 0,
            blacklistedInEU: false,
          },
        ],
      },
      {
        duration: "PT27H45M",
        segments: [
          {
            departure: {
              iataCode: "JED",
              terminal: "1",
              at: "2024-05-11T20:05:00",
            },
            arrival: {
              iataCode: "DXB",
              terminal: "3",
              at: "2024-05-12T00:15:00",
            },
            carrierCode: "EK",
            number: "804",
            aircraft: {
              code: "388",
            },
            operating: {
              carrierCode: "EK",
            },
            duration: "PT3H10M",
            id: "50",
            numberOfStops: 0,
            blacklistedInEU: false,
          },
          {
            departure: {
              iataCode: "DXB",
              terminal: "3",
              at: "2024-05-12T21:50:00",
            },
            arrival: {
              iataCode: "LHE",
              at: "2024-05-13T01:50:00",
            },
            carrierCode: "EK",
            number: "622",
            aircraft: {
              code: "77W",
            },
            operating: {
              carrierCode: "EK",
            },
            duration: "PT3H",
            id: "51",
            numberOfStops: 0,
            blacklistedInEU: false,
          },
        ],
      },
    ],
    price: {
      currency: "USD",
      total: "1893.86",
      base: "1538.00",
      fees: [
        {
          amount: "0.00",
          type: "SUPPLIER",
        },
        {
          amount: "0.00",
          type: "TICKETING",
        },
      ],
      grandTotal: "1893.86",
    },
    pricingOptions: {
      fareType: ["PUBLISHED"],
      includedCheckedBagsOnly: true,
    },
    validatingAirlineCodes: ["EK"],
    travelerPricings: [
      {
        travelerId: "1",
        fareOption: "STANDARD",
        travelerType: "ADULT",
        price: {
          currency: "USD",
          total: "1893.86",
          base: "1538.00",
        },
        fareDetailsBySegment: [
          {
            segmentId: "1",
            cabin: "BUSINESS",
            fareBasis: "OAAAPPK1",
            brandedFare: "BSSAVER",
            brandedFareLabel: "BUSINESS SAVER",
            class: "O",
            includedCheckedBags: {
              weight: 40,
              weightUnit: "KG",
            },
            amenities: [
              {
                description: "PRE RESERVED SEAT ASSIGNMENT",
                isChargeable: false,
                amenityType: "PRE_RESERVED_SEAT",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "BEVERAGE",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "MEAL VOUCHER",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "FAST TRACK",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PREMIUM CHECK IN",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BOARDING",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BAGGAGE",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
            ],
          },
          {
            segmentId: "2",
            cabin: "BUSINESS",
            fareBasis: "OAAAPPK1",
            brandedFare: "BSSAVER",
            brandedFareLabel: "BUSINESS SAVER",
            class: "O",
            includedCheckedBags: {
              weight: 40,
              weightUnit: "KG",
            },
            amenities: [
              {
                description: "PRE RESERVED SEAT ASSIGNMENT",
                isChargeable: false,
                amenityType: "PRE_RESERVED_SEAT",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "BEVERAGE",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "MEAL VOUCHER",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "FAST TRACK",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PREMIUM CHECK IN",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BOARDING",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BAGGAGE",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
            ],
          },
          {
            segmentId: "50",
            cabin: "BUSINESS",
            fareBasis: "OAAAPPK1",
            brandedFare: "BSSAVER",
            brandedFareLabel: "BUSINESS SAVER",
            class: "O",
            includedCheckedBags: {
              weight: 40,
              weightUnit: "KG",
            },
            amenities: [
              {
                description: "PRE RESERVED SEAT ASSIGNMENT",
                isChargeable: false,
                amenityType: "PRE_RESERVED_SEAT",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "BEVERAGE",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "MEAL VOUCHER",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "FAST TRACK",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PREMIUM CHECK IN",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BOARDING",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BAGGAGE",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
            ],
          },
          {
            segmentId: "51",
            cabin: "BUSINESS",
            fareBasis: "OAAAPPK1",
            brandedFare: "BSSAVER",
            brandedFareLabel: "BUSINESS SAVER",
            class: "O",
            includedCheckedBags: {
              weight: 40,
              weightUnit: "KG",
            },
            amenities: [
              {
                description: "PRE RESERVED SEAT ASSIGNMENT",
                isChargeable: false,
                amenityType: "PRE_RESERVED_SEAT",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "BEVERAGE",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "MEAL VOUCHER",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "FAST TRACK",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PREMIUM CHECK IN",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BOARDING",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BAGGAGE",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
            ],
          },
        ],
      },
    ],
  },
  {
    type: "flight-offer",
    id: "17",
    source: "GDS",
    instantTicketingRequired: false,
    nonHomogeneous: false,
    oneWay: false,
    lastTicketingDate: "2024-05-06",
    lastTicketingDateTime: "2024-05-06",
    numberOfBookableSeats: 7,
    itineraries: [
      {
        duration: "PT23H50M",
        segments: [
          {
            departure: {
              iataCode: "LHE",
              at: "2024-05-06T09:00:00",
            },
            arrival: {
              iataCode: "DXB",
              terminal: "3",
              at: "2024-05-06T11:15:00",
            },
            carrierCode: "EK",
            number: "625",
            aircraft: {
              code: "77W",
            },
            operating: {
              carrierCode: "EK",
            },
            duration: "PT3H15M",
            id: "23",
            numberOfStops: 0,
            blacklistedInEU: false,
          },
          {
            departure: {
              iataCode: "DXB",
              terminal: "2",
              at: "2024-05-07T04:45:00",
            },
            arrival: {
              iataCode: "JED",
              terminal: "1",
              at: "2024-05-07T06:50:00",
            },
            carrierCode: "EK",
            number: "2258",
            aircraft: {
              code: "7M8",
            },
            operating: {
              carrierCode: "FZ",
            },
            duration: "PT3H5M",
            id: "24",
            numberOfStops: 0,
            blacklistedInEU: false,
          },
        ],
      },
      {
        duration: "PT16H",
        segments: [
          {
            departure: {
              iataCode: "JED",
              terminal: "1",
              at: "2024-05-11T07:50:00",
            },
            arrival: {
              iataCode: "DXB",
              terminal: "2",
              at: "2024-05-11T11:50:00",
            },
            carrierCode: "EK",
            number: "2259",
            aircraft: {
              code: "7M8",
            },
            operating: {
              carrierCode: "FZ",
            },
            duration: "PT3H",
            id: "30",
            numberOfStops: 0,
            blacklistedInEU: false,
          },
          {
            departure: {
              iataCode: "DXB",
              terminal: "3",
              at: "2024-05-11T21:50:00",
            },
            arrival: {
              iataCode: "LHE",
              at: "2024-05-12T01:50:00",
            },
            carrierCode: "EK",
            number: "622",
            aircraft: {
              code: "77W",
            },
            operating: {
              carrierCode: "EK",
            },
            duration: "PT3H",
            id: "31",
            numberOfStops: 0,
            blacklistedInEU: false,
          },
        ],
      },
    ],
    price: {
      currency: "USD",
      total: "1893.86",
      base: "1538.00",
      fees: [
        {
          amount: "0.00",
          type: "SUPPLIER",
        },
        {
          amount: "0.00",
          type: "TICKETING",
        },
      ],
      grandTotal: "1893.86",
    },
    pricingOptions: {
      fareType: ["PUBLISHED"],
      includedCheckedBagsOnly: true,
    },
    validatingAirlineCodes: ["EK"],
    travelerPricings: [
      {
        travelerId: "1",
        fareOption: "STANDARD",
        travelerType: "ADULT",
        price: {
          currency: "USD",
          total: "1893.86",
          base: "1538.00",
        },
        fareDetailsBySegment: [
          {
            segmentId: "23",
            cabin: "BUSINESS",
            fareBasis: "OAAAPPK1",
            brandedFare: "BSSAVER",
            brandedFareLabel: "BUSINESS SAVER",
            class: "O",
            includedCheckedBags: {
              weight: 40,
              weightUnit: "KG",
            },
            amenities: [
              {
                description: "PRE RESERVED SEAT ASSIGNMENT",
                isChargeable: false,
                amenityType: "PRE_RESERVED_SEAT",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "BEVERAGE",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "MEAL VOUCHER",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "FAST TRACK",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PREMIUM CHECK IN",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BOARDING",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BAGGAGE",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
            ],
          },
          {
            segmentId: "24",
            cabin: "BUSINESS",
            fareBasis: "OAAAPPK1",
            brandedFare: "BSSAVER",
            brandedFareLabel: "BUSINESS SAVER",
            class: "O",
            includedCheckedBags: {
              weight: 40,
              weightUnit: "KG",
            },
            amenities: [
              {
                description: "PRE RESERVED SEAT ASSIGNMENT",
                isChargeable: false,
                amenityType: "PRE_RESERVED_SEAT",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "BEVERAGE",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "MEAL VOUCHER",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "FAST TRACK",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PREMIUM CHECK IN",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BOARDING",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BAGGAGE",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
            ],
          },
          {
            segmentId: "30",
            cabin: "BUSINESS",
            fareBasis: "OAAAPPK1",
            brandedFare: "BSSAVER",
            brandedFareLabel: "BUSINESS SAVER",
            class: "O",
            includedCheckedBags: {
              weight: 40,
              weightUnit: "KG",
            },
            amenities: [
              {
                description: "PRE RESERVED SEAT ASSIGNMENT",
                isChargeable: false,
                amenityType: "PRE_RESERVED_SEAT",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "BEVERAGE",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "MEAL VOUCHER",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "FAST TRACK",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PREMIUM CHECK IN",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BOARDING",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BAGGAGE",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
            ],
          },
          {
            segmentId: "31",
            cabin: "BUSINESS",
            fareBasis: "OAAAPPK1",
            brandedFare: "BSSAVER",
            brandedFareLabel: "BUSINESS SAVER",
            class: "O",
            includedCheckedBags: {
              weight: 40,
              weightUnit: "KG",
            },
            amenities: [
              {
                description: "PRE RESERVED SEAT ASSIGNMENT",
                isChargeable: false,
                amenityType: "PRE_RESERVED_SEAT",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "BEVERAGE",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "MEAL VOUCHER",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "FAST TRACK",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PREMIUM CHECK IN",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BOARDING",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BAGGAGE",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
            ],
          },
        ],
      },
    ],
  },
  {
    type: "flight-offer",
    id: "18",
    source: "GDS",
    instantTicketingRequired: false,
    nonHomogeneous: false,
    oneWay: false,
    lastTicketingDate: "2024-05-06",
    lastTicketingDateTime: "2024-05-06",
    numberOfBookableSeats: 7,
    itineraries: [
      {
        duration: "PT24H40M",
        segments: [
          {
            departure: {
              iataCode: "LHE",
              at: "2024-05-06T03:30:00",
            },
            arrival: {
              iataCode: "DXB",
              terminal: "3",
              at: "2024-05-06T05:40:00",
            },
            carrierCode: "EK",
            number: "623",
            aircraft: {
              code: "77W",
            },
            operating: {
              carrierCode: "EK",
            },
            duration: "PT3H10M",
            id: "21",
            numberOfStops: 0,
            blacklistedInEU: false,
          },
          {
            departure: {
              iataCode: "DXB",
              terminal: "3",
              at: "2024-05-07T00:10:00",
            },
            arrival: {
              iataCode: "JED",
              terminal: "1",
              at: "2024-05-07T02:10:00",
            },
            carrierCode: "EK",
            number: "801",
            aircraft: {
              code: "388",
            },
            operating: {
              carrierCode: "EK",
            },
            duration: "PT3H",
            id: "22",
            numberOfStops: 0,
            blacklistedInEU: false,
          },
        ],
      },
      {
        duration: "PT16H",
        segments: [
          {
            departure: {
              iataCode: "JED",
              terminal: "1",
              at: "2024-05-11T07:50:00",
            },
            arrival: {
              iataCode: "DXB",
              terminal: "2",
              at: "2024-05-11T11:50:00",
            },
            carrierCode: "EK",
            number: "2259",
            aircraft: {
              code: "7M8",
            },
            operating: {
              carrierCode: "FZ",
            },
            duration: "PT3H",
            id: "30",
            numberOfStops: 0,
            blacklistedInEU: false,
          },
          {
            departure: {
              iataCode: "DXB",
              terminal: "3",
              at: "2024-05-11T21:50:00",
            },
            arrival: {
              iataCode: "LHE",
              at: "2024-05-12T01:50:00",
            },
            carrierCode: "EK",
            number: "622",
            aircraft: {
              code: "77W",
            },
            operating: {
              carrierCode: "EK",
            },
            duration: "PT3H",
            id: "31",
            numberOfStops: 0,
            blacklistedInEU: false,
          },
        ],
      },
    ],
    price: {
      currency: "USD",
      total: "1893.86",
      base: "1538.00",
      fees: [
        {
          amount: "0.00",
          type: "SUPPLIER",
        },
        {
          amount: "0.00",
          type: "TICKETING",
        },
      ],
      grandTotal: "1893.86",
    },
    pricingOptions: {
      fareType: ["PUBLISHED"],
      includedCheckedBagsOnly: true,
    },
    validatingAirlineCodes: ["EK"],
    travelerPricings: [
      {
        travelerId: "1",
        fareOption: "STANDARD",
        travelerType: "ADULT",
        price: {
          currency: "USD",
          total: "1893.86",
          base: "1538.00",
        },
        fareDetailsBySegment: [
          {
            segmentId: "21",
            cabin: "BUSINESS",
            fareBasis: "OAAAPPK1",
            brandedFare: "BSSAVER",
            brandedFareLabel: "BUSINESS SAVER",
            class: "O",
            includedCheckedBags: {
              weight: 40,
              weightUnit: "KG",
            },
            amenities: [
              {
                description: "PRE RESERVED SEAT ASSIGNMENT",
                isChargeable: false,
                amenityType: "PRE_RESERVED_SEAT",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "BEVERAGE",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "MEAL VOUCHER",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "FAST TRACK",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PREMIUM CHECK IN",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BOARDING",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BAGGAGE",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
            ],
          },
          {
            segmentId: "22",
            cabin: "BUSINESS",
            fareBasis: "OAAAPPK1",
            brandedFare: "BSSAVER",
            brandedFareLabel: "BUSINESS SAVER",
            class: "O",
            includedCheckedBags: {
              weight: 40,
              weightUnit: "KG",
            },
            amenities: [
              {
                description: "PRE RESERVED SEAT ASSIGNMENT",
                isChargeable: false,
                amenityType: "PRE_RESERVED_SEAT",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "BEVERAGE",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "MEAL VOUCHER",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "FAST TRACK",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PREMIUM CHECK IN",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BOARDING",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BAGGAGE",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
            ],
          },
          {
            segmentId: "30",
            cabin: "BUSINESS",
            fareBasis: "OAAAPPK1",
            brandedFare: "BSSAVER",
            brandedFareLabel: "BUSINESS SAVER",
            class: "O",
            includedCheckedBags: {
              weight: 40,
              weightUnit: "KG",
            },
            amenities: [
              {
                description: "PRE RESERVED SEAT ASSIGNMENT",
                isChargeable: false,
                amenityType: "PRE_RESERVED_SEAT",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "BEVERAGE",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "MEAL VOUCHER",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "FAST TRACK",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PREMIUM CHECK IN",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BOARDING",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BAGGAGE",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
            ],
          },
          {
            segmentId: "31",
            cabin: "BUSINESS",
            fareBasis: "OAAAPPK1",
            brandedFare: "BSSAVER",
            brandedFareLabel: "BUSINESS SAVER",
            class: "O",
            includedCheckedBags: {
              weight: 40,
              weightUnit: "KG",
            },
            amenities: [
              {
                description: "PRE RESERVED SEAT ASSIGNMENT",
                isChargeable: false,
                amenityType: "PRE_RESERVED_SEAT",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "BEVERAGE",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "MEAL VOUCHER",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "FAST TRACK",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PREMIUM CHECK IN",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BOARDING",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BAGGAGE",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
            ],
          },
        ],
      },
    ],
  },
  {
    type: "flight-offer",
    id: "19",
    source: "GDS",
    instantTicketingRequired: false,
    nonHomogeneous: false,
    oneWay: false,
    lastTicketingDate: "2024-05-06",
    lastTicketingDateTime: "2024-05-06",
    numberOfBookableSeats: 7,
    itineraries: [
      {
        duration: "PT23H50M",
        segments: [
          {
            departure: {
              iataCode: "LHE",
              at: "2024-05-06T09:00:00",
            },
            arrival: {
              iataCode: "DXB",
              terminal: "3",
              at: "2024-05-06T11:15:00",
            },
            carrierCode: "EK",
            number: "625",
            aircraft: {
              code: "77W",
            },
            operating: {
              carrierCode: "EK",
            },
            duration: "PT3H15M",
            id: "23",
            numberOfStops: 0,
            blacklistedInEU: false,
          },
          {
            departure: {
              iataCode: "DXB",
              terminal: "2",
              at: "2024-05-07T04:45:00",
            },
            arrival: {
              iataCode: "JED",
              terminal: "1",
              at: "2024-05-07T06:50:00",
            },
            carrierCode: "EK",
            number: "2258",
            aircraft: {
              code: "7M8",
            },
            operating: {
              carrierCode: "FZ",
            },
            duration: "PT3H5M",
            id: "24",
            numberOfStops: 0,
            blacklistedInEU: false,
          },
        ],
      },
      {
        duration: "PT19H40M",
        segments: [
          {
            departure: {
              iataCode: "JED",
              terminal: "1",
              at: "2024-05-11T04:10:00",
            },
            arrival: {
              iataCode: "DXB",
              terminal: "3",
              at: "2024-05-11T07:55:00",
            },
            carrierCode: "EK",
            number: "802",
            aircraft: {
              code: "388",
            },
            operating: {
              carrierCode: "EK",
            },
            duration: "PT2H45M",
            id: "44",
            numberOfStops: 0,
            blacklistedInEU: false,
          },
          {
            departure: {
              iataCode: "DXB",
              terminal: "3",
              at: "2024-05-11T21:50:00",
            },
            arrival: {
              iataCode: "LHE",
              at: "2024-05-12T01:50:00",
            },
            carrierCode: "EK",
            number: "622",
            aircraft: {
              code: "77W",
            },
            operating: {
              carrierCode: "EK",
            },
            duration: "PT3H",
            id: "45",
            numberOfStops: 0,
            blacklistedInEU: false,
          },
        ],
      },
    ],
    price: {
      currency: "USD",
      total: "1893.86",
      base: "1538.00",
      fees: [
        {
          amount: "0.00",
          type: "SUPPLIER",
        },
        {
          amount: "0.00",
          type: "TICKETING",
        },
      ],
      grandTotal: "1893.86",
    },
    pricingOptions: {
      fareType: ["PUBLISHED"],
      includedCheckedBagsOnly: true,
    },
    validatingAirlineCodes: ["EK"],
    travelerPricings: [
      {
        travelerId: "1",
        fareOption: "STANDARD",
        travelerType: "ADULT",
        price: {
          currency: "USD",
          total: "1893.86",
          base: "1538.00",
        },
        fareDetailsBySegment: [
          {
            segmentId: "23",
            cabin: "BUSINESS",
            fareBasis: "OAAAPPK1",
            brandedFare: "BSSAVER",
            brandedFareLabel: "BUSINESS SAVER",
            class: "O",
            includedCheckedBags: {
              weight: 40,
              weightUnit: "KG",
            },
            amenities: [
              {
                description: "PRE RESERVED SEAT ASSIGNMENT",
                isChargeable: false,
                amenityType: "PRE_RESERVED_SEAT",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "BEVERAGE",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "MEAL VOUCHER",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "FAST TRACK",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PREMIUM CHECK IN",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BOARDING",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BAGGAGE",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
            ],
          },
          {
            segmentId: "24",
            cabin: "BUSINESS",
            fareBasis: "OAAAPPK1",
            brandedFare: "BSSAVER",
            brandedFareLabel: "BUSINESS SAVER",
            class: "O",
            includedCheckedBags: {
              weight: 40,
              weightUnit: "KG",
            },
            amenities: [
              {
                description: "PRE RESERVED SEAT ASSIGNMENT",
                isChargeable: false,
                amenityType: "PRE_RESERVED_SEAT",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "BEVERAGE",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "MEAL VOUCHER",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "FAST TRACK",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PREMIUM CHECK IN",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BOARDING",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BAGGAGE",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
            ],
          },
          {
            segmentId: "44",
            cabin: "BUSINESS",
            fareBasis: "OAAAPPK1",
            brandedFare: "BSSAVER",
            brandedFareLabel: "BUSINESS SAVER",
            class: "O",
            includedCheckedBags: {
              weight: 40,
              weightUnit: "KG",
            },
            amenities: [
              {
                description: "PRE RESERVED SEAT ASSIGNMENT",
                isChargeable: false,
                amenityType: "PRE_RESERVED_SEAT",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "BEVERAGE",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "MEAL VOUCHER",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "FAST TRACK",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PREMIUM CHECK IN",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BOARDING",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BAGGAGE",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
            ],
          },
          {
            segmentId: "45",
            cabin: "BUSINESS",
            fareBasis: "OAAAPPK1",
            brandedFare: "BSSAVER",
            brandedFareLabel: "BUSINESS SAVER",
            class: "O",
            includedCheckedBags: {
              weight: 40,
              weightUnit: "KG",
            },
            amenities: [
              {
                description: "PRE RESERVED SEAT ASSIGNMENT",
                isChargeable: false,
                amenityType: "PRE_RESERVED_SEAT",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "BEVERAGE",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "MEAL VOUCHER",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "FAST TRACK",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PREMIUM CHECK IN",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BOARDING",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BAGGAGE",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
            ],
          },
        ],
      },
    ],
  },
  {
    type: "flight-offer",
    id: "20",
    source: "GDS",
    instantTicketingRequired: false,
    nonHomogeneous: false,
    oneWay: false,
    lastTicketingDate: "2024-05-06",
    lastTicketingDateTime: "2024-05-06",
    numberOfBookableSeats: 7,
    itineraries: [
      {
        duration: "PT23H50M",
        segments: [
          {
            departure: {
              iataCode: "LHE",
              at: "2024-05-06T09:00:00",
            },
            arrival: {
              iataCode: "DXB",
              terminal: "3",
              at: "2024-05-06T11:15:00",
            },
            carrierCode: "EK",
            number: "625",
            aircraft: {
              code: "77W",
            },
            operating: {
              carrierCode: "EK",
            },
            duration: "PT3H15M",
            id: "23",
            numberOfStops: 0,
            blacklistedInEU: false,
          },
          {
            departure: {
              iataCode: "DXB",
              terminal: "2",
              at: "2024-05-07T04:45:00",
            },
            arrival: {
              iataCode: "JED",
              terminal: "1",
              at: "2024-05-07T06:50:00",
            },
            carrierCode: "EK",
            number: "2258",
            aircraft: {
              code: "7M8",
            },
            operating: {
              carrierCode: "FZ",
            },
            duration: "PT3H5M",
            id: "24",
            numberOfStops: 0,
            blacklistedInEU: false,
          },
        ],
      },
      {
        duration: "PT27H45M",
        segments: [
          {
            departure: {
              iataCode: "JED",
              terminal: "1",
              at: "2024-05-11T20:05:00",
            },
            arrival: {
              iataCode: "DXB",
              terminal: "3",
              at: "2024-05-12T00:15:00",
            },
            carrierCode: "EK",
            number: "804",
            aircraft: {
              code: "388",
            },
            operating: {
              carrierCode: "EK",
            },
            duration: "PT3H10M",
            id: "50",
            numberOfStops: 0,
            blacklistedInEU: false,
          },
          {
            departure: {
              iataCode: "DXB",
              terminal: "3",
              at: "2024-05-12T21:50:00",
            },
            arrival: {
              iataCode: "LHE",
              at: "2024-05-13T01:50:00",
            },
            carrierCode: "EK",
            number: "622",
            aircraft: {
              code: "77W",
            },
            operating: {
              carrierCode: "EK",
            },
            duration: "PT3H",
            id: "51",
            numberOfStops: 0,
            blacklistedInEU: false,
          },
        ],
      },
    ],
    price: {
      currency: "USD",
      total: "1893.86",
      base: "1538.00",
      fees: [
        {
          amount: "0.00",
          type: "SUPPLIER",
        },
        {
          amount: "0.00",
          type: "TICKETING",
        },
      ],
      grandTotal: "1893.86",
    },
    pricingOptions: {
      fareType: ["PUBLISHED"],
      includedCheckedBagsOnly: true,
    },
    validatingAirlineCodes: ["EK"],
    travelerPricings: [
      {
        travelerId: "1",
        fareOption: "STANDARD",
        travelerType: "ADULT",
        price: {
          currency: "USD",
          total: "1893.86",
          base: "1538.00",
        },
        fareDetailsBySegment: [
          {
            segmentId: "23",
            cabin: "BUSINESS",
            fareBasis: "OAAAPPK1",
            brandedFare: "BSSAVER",
            brandedFareLabel: "BUSINESS SAVER",
            class: "O",
            includedCheckedBags: {
              weight: 40,
              weightUnit: "KG",
            },
            amenities: [
              {
                description: "PRE RESERVED SEAT ASSIGNMENT",
                isChargeable: false,
                amenityType: "PRE_RESERVED_SEAT",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "BEVERAGE",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "MEAL VOUCHER",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "FAST TRACK",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PREMIUM CHECK IN",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BOARDING",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BAGGAGE",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
            ],
          },
          {
            segmentId: "24",
            cabin: "BUSINESS",
            fareBasis: "OAAAPPK1",
            brandedFare: "BSSAVER",
            brandedFareLabel: "BUSINESS SAVER",
            class: "O",
            includedCheckedBags: {
              weight: 40,
              weightUnit: "KG",
            },
            amenities: [
              {
                description: "PRE RESERVED SEAT ASSIGNMENT",
                isChargeable: false,
                amenityType: "PRE_RESERVED_SEAT",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "BEVERAGE",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "MEAL VOUCHER",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "FAST TRACK",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PREMIUM CHECK IN",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BOARDING",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BAGGAGE",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
            ],
          },
          {
            segmentId: "50",
            cabin: "BUSINESS",
            fareBasis: "OAAAPPK1",
            brandedFare: "BSSAVER",
            brandedFareLabel: "BUSINESS SAVER",
            class: "O",
            includedCheckedBags: {
              weight: 40,
              weightUnit: "KG",
            },
            amenities: [
              {
                description: "PRE RESERVED SEAT ASSIGNMENT",
                isChargeable: false,
                amenityType: "PRE_RESERVED_SEAT",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "BEVERAGE",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "MEAL VOUCHER",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "FAST TRACK",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PREMIUM CHECK IN",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BOARDING",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BAGGAGE",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
            ],
          },
          {
            segmentId: "51",
            cabin: "BUSINESS",
            fareBasis: "OAAAPPK1",
            brandedFare: "BSSAVER",
            brandedFareLabel: "BUSINESS SAVER",
            class: "O",
            includedCheckedBags: {
              weight: 40,
              weightUnit: "KG",
            },
            amenities: [
              {
                description: "PRE RESERVED SEAT ASSIGNMENT",
                isChargeable: false,
                amenityType: "PRE_RESERVED_SEAT",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "BEVERAGE",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "MEAL VOUCHER",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "FAST TRACK",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PREMIUM CHECK IN",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BOARDING",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BAGGAGE",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
            ],
          },
        ],
      },
    ],
  },
  {
    type: "flight-offer",
    id: "21",
    source: "GDS",
    instantTicketingRequired: false,
    nonHomogeneous: false,
    oneWay: false,
    lastTicketingDate: "2024-05-06",
    lastTicketingDateTime: "2024-05-06",
    numberOfBookableSeats: 7,
    itineraries: [
      {
        duration: "PT7H15M",
        segments: [
          {
            departure: {
              iataCode: "LHE",
              at: "2024-05-06T03:30:00",
            },
            arrival: {
              iataCode: "DXB",
              terminal: "3",
              at: "2024-05-06T05:40:00",
            },
            carrierCode: "EK",
            number: "623",
            aircraft: {
              code: "77W",
            },
            operating: {
              carrierCode: "EK",
            },
            duration: "PT3H10M",
            id: "10",
            numberOfStops: 0,
            blacklistedInEU: false,
          },
          {
            departure: {
              iataCode: "DXB",
              terminal: "3",
              at: "2024-05-06T06:55:00",
            },
            arrival: {
              iataCode: "JED",
              terminal: "1",
              at: "2024-05-06T08:45:00",
            },
            carrierCode: "EK",
            number: "805",
            aircraft: {
              code: "388",
            },
            operating: {
              carrierCode: "EK",
            },
            duration: "PT2H50M",
            id: "11",
            numberOfStops: 0,
            blacklistedInEU: false,
          },
        ],
      },
      {
        duration: "PT13H5M",
        segments: [
          {
            departure: {
              iataCode: "JED",
              terminal: "1",
              at: "2024-05-11T10:45:00",
            },
            arrival: {
              iataCode: "DXB",
              terminal: "3",
              at: "2024-05-11T14:45:00",
            },
            carrierCode: "EK",
            number: "806",
            aircraft: {
              code: "388",
            },
            operating: {
              carrierCode: "EK",
            },
            duration: "PT3H",
            id: "32",
            numberOfStops: 0,
            blacklistedInEU: false,
          },
          {
            departure: {
              iataCode: "DXB",
              terminal: "3",
              at: "2024-05-11T21:50:00",
            },
            arrival: {
              iataCode: "LHE",
              at: "2024-05-12T01:50:00",
            },
            carrierCode: "EK",
            number: "622",
            aircraft: {
              code: "77W",
            },
            operating: {
              carrierCode: "EK",
            },
            duration: "PT3H",
            id: "33",
            numberOfStops: 0,
            blacklistedInEU: false,
          },
        ],
      },
    ],
    price: {
      currency: "USD",
      total: "1893.86",
      base: "1538.00",
      fees: [
        {
          amount: "0.00",
          type: "SUPPLIER",
        },
        {
          amount: "0.00",
          type: "TICKETING",
        },
      ],
      grandTotal: "1893.86",
    },
    pricingOptions: {
      fareType: ["PUBLISHED"],
      includedCheckedBagsOnly: true,
    },
    validatingAirlineCodes: ["EK"],
    travelerPricings: [
      {
        travelerId: "1",
        fareOption: "STANDARD",
        travelerType: "ADULT",
        price: {
          currency: "USD",
          total: "1893.86",
          base: "1538.00",
        },
        fareDetailsBySegment: [
          {
            segmentId: "10",
            cabin: "BUSINESS",
            fareBasis: "OAAAPPK1",
            brandedFare: "BSSAVER",
            brandedFareLabel: "BUSINESS SAVER",
            class: "O",
            includedCheckedBags: {
              weight: 40,
              weightUnit: "KG",
            },
            amenities: [
              {
                description: "PRE RESERVED SEAT ASSIGNMENT",
                isChargeable: false,
                amenityType: "PRE_RESERVED_SEAT",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "BEVERAGE",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "MEAL VOUCHER",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "FAST TRACK",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PREMIUM CHECK IN",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BOARDING",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BAGGAGE",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
            ],
          },
          {
            segmentId: "11",
            cabin: "BUSINESS",
            fareBasis: "OAAAPPK1",
            brandedFare: "BSSAVER",
            brandedFareLabel: "BUSINESS SAVER",
            class: "O",
            includedCheckedBags: {
              weight: 40,
              weightUnit: "KG",
            },
            amenities: [
              {
                description: "PRE RESERVED SEAT ASSIGNMENT",
                isChargeable: false,
                amenityType: "PRE_RESERVED_SEAT",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "BEVERAGE",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "MEAL VOUCHER",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "FAST TRACK",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PREMIUM CHECK IN",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BOARDING",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BAGGAGE",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
            ],
          },
          {
            segmentId: "32",
            cabin: "BUSINESS",
            fareBasis: "OAAAPPK1",
            brandedFare: "BSSAVER",
            brandedFareLabel: "BUSINESS SAVER",
            class: "O",
            includedCheckedBags: {
              weight: 40,
              weightUnit: "KG",
            },
            amenities: [
              {
                description: "PRE RESERVED SEAT ASSIGNMENT",
                isChargeable: false,
                amenityType: "PRE_RESERVED_SEAT",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "BEVERAGE",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "MEAL VOUCHER",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "FAST TRACK",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PREMIUM CHECK IN",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BOARDING",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BAGGAGE",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
            ],
          },
          {
            segmentId: "33",
            cabin: "BUSINESS",
            fareBasis: "OAAAPPK1",
            brandedFare: "BSSAVER",
            brandedFareLabel: "BUSINESS SAVER",
            class: "O",
            includedCheckedBags: {
              weight: 40,
              weightUnit: "KG",
            },
            amenities: [
              {
                description: "PRE RESERVED SEAT ASSIGNMENT",
                isChargeable: false,
                amenityType: "PRE_RESERVED_SEAT",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "BEVERAGE",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "MEAL VOUCHER",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "FAST TRACK",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PREMIUM CHECK IN",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BOARDING",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BAGGAGE",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
            ],
          },
        ],
      },
    ],
  },
  {
    type: "flight-offer",
    id: "22",
    source: "GDS",
    instantTicketingRequired: false,
    nonHomogeneous: false,
    oneWay: false,
    lastTicketingDate: "2024-05-06",
    lastTicketingDateTime: "2024-05-06",
    numberOfBookableSeats: 7,
    itineraries: [
      {
        duration: "PT11H",
        segments: [
          {
            departure: {
              iataCode: "LHE",
              at: "2024-05-06T09:00:00",
            },
            arrival: {
              iataCode: "DXB",
              terminal: "3",
              at: "2024-05-06T11:15:00",
            },
            carrierCode: "EK",
            number: "625",
            aircraft: {
              code: "77W",
            },
            operating: {
              carrierCode: "EK",
            },
            duration: "PT3H15M",
            id: "3",
            numberOfStops: 0,
            blacklistedInEU: false,
          },
          {
            departure: {
              iataCode: "DXB",
              terminal: "3",
              at: "2024-05-06T15:40:00",
            },
            arrival: {
              iataCode: "JED",
              terminal: "1",
              at: "2024-05-06T18:00:00",
            },
            carrierCode: "EK",
            number: "803",
            aircraft: {
              code: "388",
            },
            operating: {
              carrierCode: "EK",
            },
            duration: "PT3H20M",
            id: "4",
            numberOfStops: 0,
            blacklistedInEU: false,
          },
        ],
      },
      {
        duration: "PT13H5M",
        segments: [
          {
            departure: {
              iataCode: "JED",
              terminal: "1",
              at: "2024-05-11T10:45:00",
            },
            arrival: {
              iataCode: "DXB",
              terminal: "3",
              at: "2024-05-11T14:45:00",
            },
            carrierCode: "EK",
            number: "806",
            aircraft: {
              code: "388",
            },
            operating: {
              carrierCode: "EK",
            },
            duration: "PT3H",
            id: "32",
            numberOfStops: 0,
            blacklistedInEU: false,
          },
          {
            departure: {
              iataCode: "DXB",
              terminal: "3",
              at: "2024-05-11T21:50:00",
            },
            arrival: {
              iataCode: "LHE",
              at: "2024-05-12T01:50:00",
            },
            carrierCode: "EK",
            number: "622",
            aircraft: {
              code: "77W",
            },
            operating: {
              carrierCode: "EK",
            },
            duration: "PT3H",
            id: "33",
            numberOfStops: 0,
            blacklistedInEU: false,
          },
        ],
      },
    ],
    price: {
      currency: "USD",
      total: "1893.86",
      base: "1538.00",
      fees: [
        {
          amount: "0.00",
          type: "SUPPLIER",
        },
        {
          amount: "0.00",
          type: "TICKETING",
        },
      ],
      grandTotal: "1893.86",
    },
    pricingOptions: {
      fareType: ["PUBLISHED"],
      includedCheckedBagsOnly: true,
    },
    validatingAirlineCodes: ["EK"],
    travelerPricings: [
      {
        travelerId: "1",
        fareOption: "STANDARD",
        travelerType: "ADULT",
        price: {
          currency: "USD",
          total: "1893.86",
          base: "1538.00",
        },
        fareDetailsBySegment: [
          {
            segmentId: "3",
            cabin: "BUSINESS",
            fareBasis: "OAAAPPK1",
            brandedFare: "BSSAVER",
            brandedFareLabel: "BUSINESS SAVER",
            class: "O",
            includedCheckedBags: {
              weight: 40,
              weightUnit: "KG",
            },
            amenities: [
              {
                description: "PRE RESERVED SEAT ASSIGNMENT",
                isChargeable: false,
                amenityType: "PRE_RESERVED_SEAT",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "BEVERAGE",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "MEAL VOUCHER",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "FAST TRACK",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PREMIUM CHECK IN",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BOARDING",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BAGGAGE",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
            ],
          },
          {
            segmentId: "4",
            cabin: "BUSINESS",
            fareBasis: "OAAAPPK1",
            brandedFare: "BSSAVER",
            brandedFareLabel: "BUSINESS SAVER",
            class: "O",
            includedCheckedBags: {
              weight: 40,
              weightUnit: "KG",
            },
            amenities: [
              {
                description: "PRE RESERVED SEAT ASSIGNMENT",
                isChargeable: false,
                amenityType: "PRE_RESERVED_SEAT",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "BEVERAGE",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "MEAL VOUCHER",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "FAST TRACK",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PREMIUM CHECK IN",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BOARDING",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BAGGAGE",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
            ],
          },
          {
            segmentId: "32",
            cabin: "BUSINESS",
            fareBasis: "OAAAPPK1",
            brandedFare: "BSSAVER",
            brandedFareLabel: "BUSINESS SAVER",
            class: "O",
            includedCheckedBags: {
              weight: 40,
              weightUnit: "KG",
            },
            amenities: [
              {
                description: "PRE RESERVED SEAT ASSIGNMENT",
                isChargeable: false,
                amenityType: "PRE_RESERVED_SEAT",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "BEVERAGE",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "MEAL VOUCHER",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "FAST TRACK",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PREMIUM CHECK IN",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BOARDING",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BAGGAGE",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
            ],
          },
          {
            segmentId: "33",
            cabin: "BUSINESS",
            fareBasis: "OAAAPPK1",
            brandedFare: "BSSAVER",
            brandedFareLabel: "BUSINESS SAVER",
            class: "O",
            includedCheckedBags: {
              weight: 40,
              weightUnit: "KG",
            },
            amenities: [
              {
                description: "PRE RESERVED SEAT ASSIGNMENT",
                isChargeable: false,
                amenityType: "PRE_RESERVED_SEAT",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "BEVERAGE",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "MEAL VOUCHER",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "FAST TRACK",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PREMIUM CHECK IN",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BOARDING",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BAGGAGE",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
            ],
          },
        ],
      },
    ],
  },
  {
    type: "flight-offer",
    id: "23",
    source: "GDS",
    instantTicketingRequired: false,
    nonHomogeneous: false,
    oneWay: false,
    lastTicketingDate: "2024-05-06",
    lastTicketingDateTime: "2024-05-06",
    numberOfBookableSeats: 7,
    itineraries: [
      {
        duration: "PT7H15M",
        segments: [
          {
            departure: {
              iataCode: "LHE",
              at: "2024-05-06T03:30:00",
            },
            arrival: {
              iataCode: "DXB",
              terminal: "3",
              at: "2024-05-06T05:40:00",
            },
            carrierCode: "EK",
            number: "623",
            aircraft: {
              code: "77W",
            },
            operating: {
              carrierCode: "EK",
            },
            duration: "PT3H10M",
            id: "10",
            numberOfStops: 0,
            blacklistedInEU: false,
          },
          {
            departure: {
              iataCode: "DXB",
              terminal: "3",
              at: "2024-05-06T06:55:00",
            },
            arrival: {
              iataCode: "JED",
              terminal: "1",
              at: "2024-05-06T08:45:00",
            },
            carrierCode: "EK",
            number: "805",
            aircraft: {
              code: "388",
            },
            operating: {
              carrierCode: "EK",
            },
            duration: "PT2H50M",
            id: "11",
            numberOfStops: 0,
            blacklistedInEU: false,
          },
        ],
      },
      {
        duration: "PT19H40M",
        segments: [
          {
            departure: {
              iataCode: "JED",
              terminal: "1",
              at: "2024-05-11T04:10:00",
            },
            arrival: {
              iataCode: "DXB",
              terminal: "3",
              at: "2024-05-11T07:55:00",
            },
            carrierCode: "EK",
            number: "802",
            aircraft: {
              code: "388",
            },
            operating: {
              carrierCode: "EK",
            },
            duration: "PT2H45M",
            id: "44",
            numberOfStops: 0,
            blacklistedInEU: false,
          },
          {
            departure: {
              iataCode: "DXB",
              terminal: "3",
              at: "2024-05-11T21:50:00",
            },
            arrival: {
              iataCode: "LHE",
              at: "2024-05-12T01:50:00",
            },
            carrierCode: "EK",
            number: "622",
            aircraft: {
              code: "77W",
            },
            operating: {
              carrierCode: "EK",
            },
            duration: "PT3H",
            id: "45",
            numberOfStops: 0,
            blacklistedInEU: false,
          },
        ],
      },
    ],
    price: {
      currency: "USD",
      total: "1893.86",
      base: "1538.00",
      fees: [
        {
          amount: "0.00",
          type: "SUPPLIER",
        },
        {
          amount: "0.00",
          type: "TICKETING",
        },
      ],
      grandTotal: "1893.86",
    },
    pricingOptions: {
      fareType: ["PUBLISHED"],
      includedCheckedBagsOnly: true,
    },
    validatingAirlineCodes: ["EK"],
    travelerPricings: [
      {
        travelerId: "1",
        fareOption: "STANDARD",
        travelerType: "ADULT",
        price: {
          currency: "USD",
          total: "1893.86",
          base: "1538.00",
        },
        fareDetailsBySegment: [
          {
            segmentId: "10",
            cabin: "BUSINESS",
            fareBasis: "OAAAPPK1",
            brandedFare: "BSSAVER",
            brandedFareLabel: "BUSINESS SAVER",
            class: "O",
            includedCheckedBags: {
              weight: 40,
              weightUnit: "KG",
            },
            amenities: [
              {
                description: "PRE RESERVED SEAT ASSIGNMENT",
                isChargeable: false,
                amenityType: "PRE_RESERVED_SEAT",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "BEVERAGE",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "MEAL VOUCHER",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "FAST TRACK",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PREMIUM CHECK IN",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BOARDING",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BAGGAGE",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
            ],
          },
          {
            segmentId: "11",
            cabin: "BUSINESS",
            fareBasis: "OAAAPPK1",
            brandedFare: "BSSAVER",
            brandedFareLabel: "BUSINESS SAVER",
            class: "O",
            includedCheckedBags: {
              weight: 40,
              weightUnit: "KG",
            },
            amenities: [
              {
                description: "PRE RESERVED SEAT ASSIGNMENT",
                isChargeable: false,
                amenityType: "PRE_RESERVED_SEAT",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "BEVERAGE",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "MEAL VOUCHER",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "FAST TRACK",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PREMIUM CHECK IN",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BOARDING",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BAGGAGE",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
            ],
          },
          {
            segmentId: "44",
            cabin: "BUSINESS",
            fareBasis: "OAAAPPK1",
            brandedFare: "BSSAVER",
            brandedFareLabel: "BUSINESS SAVER",
            class: "O",
            includedCheckedBags: {
              weight: 40,
              weightUnit: "KG",
            },
            amenities: [
              {
                description: "PRE RESERVED SEAT ASSIGNMENT",
                isChargeable: false,
                amenityType: "PRE_RESERVED_SEAT",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "BEVERAGE",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "MEAL VOUCHER",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "FAST TRACK",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PREMIUM CHECK IN",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BOARDING",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BAGGAGE",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
            ],
          },
          {
            segmentId: "45",
            cabin: "BUSINESS",
            fareBasis: "OAAAPPK1",
            brandedFare: "BSSAVER",
            brandedFareLabel: "BUSINESS SAVER",
            class: "O",
            includedCheckedBags: {
              weight: 40,
              weightUnit: "KG",
            },
            amenities: [
              {
                description: "PRE RESERVED SEAT ASSIGNMENT",
                isChargeable: false,
                amenityType: "PRE_RESERVED_SEAT",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "BEVERAGE",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "MEAL VOUCHER",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "FAST TRACK",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PREMIUM CHECK IN",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BOARDING",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BAGGAGE",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
            ],
          },
        ],
      },
    ],
  },
  {
    type: "flight-offer",
    id: "24",
    source: "GDS",
    instantTicketingRequired: false,
    nonHomogeneous: false,
    oneWay: false,
    lastTicketingDate: "2024-05-06",
    lastTicketingDateTime: "2024-05-06",
    numberOfBookableSeats: 7,
    itineraries: [
      {
        duration: "PT16H30M",
        segments: [
          {
            departure: {
              iataCode: "LHE",
              at: "2024-05-06T03:30:00",
            },
            arrival: {
              iataCode: "DXB",
              terminal: "3",
              at: "2024-05-06T05:40:00",
            },
            carrierCode: "EK",
            number: "623",
            aircraft: {
              code: "77W",
            },
            operating: {
              carrierCode: "EK",
            },
            duration: "PT3H10M",
            id: "17",
            numberOfStops: 0,
            blacklistedInEU: false,
          },
          {
            departure: {
              iataCode: "DXB",
              terminal: "3",
              at: "2024-05-06T15:40:00",
            },
            arrival: {
              iataCode: "JED",
              terminal: "1",
              at: "2024-05-06T18:00:00",
            },
            carrierCode: "EK",
            number: "803",
            aircraft: {
              code: "388",
            },
            operating: {
              carrierCode: "EK",
            },
            duration: "PT3H20M",
            id: "18",
            numberOfStops: 0,
            blacklistedInEU: false,
          },
        ],
      },
      {
        duration: "PT13H5M",
        segments: [
          {
            departure: {
              iataCode: "JED",
              terminal: "1",
              at: "2024-05-11T10:45:00",
            },
            arrival: {
              iataCode: "DXB",
              terminal: "3",
              at: "2024-05-11T14:45:00",
            },
            carrierCode: "EK",
            number: "806",
            aircraft: {
              code: "388",
            },
            operating: {
              carrierCode: "EK",
            },
            duration: "PT3H",
            id: "32",
            numberOfStops: 0,
            blacklistedInEU: false,
          },
          {
            departure: {
              iataCode: "DXB",
              terminal: "3",
              at: "2024-05-11T21:50:00",
            },
            arrival: {
              iataCode: "LHE",
              at: "2024-05-12T01:50:00",
            },
            carrierCode: "EK",
            number: "622",
            aircraft: {
              code: "77W",
            },
            operating: {
              carrierCode: "EK",
            },
            duration: "PT3H",
            id: "33",
            numberOfStops: 0,
            blacklistedInEU: false,
          },
        ],
      },
    ],
    price: {
      currency: "USD",
      total: "1893.86",
      base: "1538.00",
      fees: [
        {
          amount: "0.00",
          type: "SUPPLIER",
        },
        {
          amount: "0.00",
          type: "TICKETING",
        },
      ],
      grandTotal: "1893.86",
    },
    pricingOptions: {
      fareType: ["PUBLISHED"],
      includedCheckedBagsOnly: true,
    },
    validatingAirlineCodes: ["EK"],
    travelerPricings: [
      {
        travelerId: "1",
        fareOption: "STANDARD",
        travelerType: "ADULT",
        price: {
          currency: "USD",
          total: "1893.86",
          base: "1538.00",
        },
        fareDetailsBySegment: [
          {
            segmentId: "17",
            cabin: "BUSINESS",
            fareBasis: "OAAAPPK1",
            brandedFare: "BSSAVER",
            brandedFareLabel: "BUSINESS SAVER",
            class: "O",
            includedCheckedBags: {
              weight: 40,
              weightUnit: "KG",
            },
            amenities: [
              {
                description: "PRE RESERVED SEAT ASSIGNMENT",
                isChargeable: false,
                amenityType: "PRE_RESERVED_SEAT",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "BEVERAGE",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "MEAL VOUCHER",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "FAST TRACK",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PREMIUM CHECK IN",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BOARDING",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BAGGAGE",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
            ],
          },
          {
            segmentId: "18",
            cabin: "BUSINESS",
            fareBasis: "OAAAPPK1",
            brandedFare: "BSSAVER",
            brandedFareLabel: "BUSINESS SAVER",
            class: "O",
            includedCheckedBags: {
              weight: 40,
              weightUnit: "KG",
            },
            amenities: [
              {
                description: "PRE RESERVED SEAT ASSIGNMENT",
                isChargeable: false,
                amenityType: "PRE_RESERVED_SEAT",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "BEVERAGE",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "MEAL VOUCHER",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "FAST TRACK",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PREMIUM CHECK IN",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BOARDING",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BAGGAGE",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
            ],
          },
          {
            segmentId: "32",
            cabin: "BUSINESS",
            fareBasis: "OAAAPPK1",
            brandedFare: "BSSAVER",
            brandedFareLabel: "BUSINESS SAVER",
            class: "O",
            includedCheckedBags: {
              weight: 40,
              weightUnit: "KG",
            },
            amenities: [
              {
                description: "PRE RESERVED SEAT ASSIGNMENT",
                isChargeable: false,
                amenityType: "PRE_RESERVED_SEAT",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "BEVERAGE",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "MEAL VOUCHER",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "FAST TRACK",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PREMIUM CHECK IN",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BOARDING",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BAGGAGE",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
            ],
          },
          {
            segmentId: "33",
            cabin: "BUSINESS",
            fareBasis: "OAAAPPK1",
            brandedFare: "BSSAVER",
            brandedFareLabel: "BUSINESS SAVER",
            class: "O",
            includedCheckedBags: {
              weight: 40,
              weightUnit: "KG",
            },
            amenities: [
              {
                description: "PRE RESERVED SEAT ASSIGNMENT",
                isChargeable: false,
                amenityType: "PRE_RESERVED_SEAT",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "BEVERAGE",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "MEAL VOUCHER",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "FAST TRACK",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PREMIUM CHECK IN",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BOARDING",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BAGGAGE",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
            ],
          },
        ],
      },
    ],
  },
  {
    type: "flight-offer",
    id: "25",
    source: "GDS",
    instantTicketingRequired: false,
    nonHomogeneous: false,
    oneWay: false,
    lastTicketingDate: "2024-05-06",
    lastTicketingDateTime: "2024-05-06",
    numberOfBookableSeats: 7,
    itineraries: [
      {
        duration: "PT11H",
        segments: [
          {
            departure: {
              iataCode: "LHE",
              at: "2024-05-06T09:00:00",
            },
            arrival: {
              iataCode: "DXB",
              terminal: "3",
              at: "2024-05-06T11:15:00",
            },
            carrierCode: "EK",
            number: "625",
            aircraft: {
              code: "77W",
            },
            operating: {
              carrierCode: "EK",
            },
            duration: "PT3H15M",
            id: "3",
            numberOfStops: 0,
            blacklistedInEU: false,
          },
          {
            departure: {
              iataCode: "DXB",
              terminal: "3",
              at: "2024-05-06T15:40:00",
            },
            arrival: {
              iataCode: "JED",
              terminal: "1",
              at: "2024-05-06T18:00:00",
            },
            carrierCode: "EK",
            number: "803",
            aircraft: {
              code: "388",
            },
            operating: {
              carrierCode: "EK",
            },
            duration: "PT3H20M",
            id: "4",
            numberOfStops: 0,
            blacklistedInEU: false,
          },
        ],
      },
      {
        duration: "PT19H40M",
        segments: [
          {
            departure: {
              iataCode: "JED",
              terminal: "1",
              at: "2024-05-11T04:10:00",
            },
            arrival: {
              iataCode: "DXB",
              terminal: "3",
              at: "2024-05-11T07:55:00",
            },
            carrierCode: "EK",
            number: "802",
            aircraft: {
              code: "388",
            },
            operating: {
              carrierCode: "EK",
            },
            duration: "PT2H45M",
            id: "44",
            numberOfStops: 0,
            blacklistedInEU: false,
          },
          {
            departure: {
              iataCode: "DXB",
              terminal: "3",
              at: "2024-05-11T21:50:00",
            },
            arrival: {
              iataCode: "LHE",
              at: "2024-05-12T01:50:00",
            },
            carrierCode: "EK",
            number: "622",
            aircraft: {
              code: "77W",
            },
            operating: {
              carrierCode: "EK",
            },
            duration: "PT3H",
            id: "45",
            numberOfStops: 0,
            blacklistedInEU: false,
          },
        ],
      },
    ],
    price: {
      currency: "USD",
      total: "1893.86",
      base: "1538.00",
      fees: [
        {
          amount: "0.00",
          type: "SUPPLIER",
        },
        {
          amount: "0.00",
          type: "TICKETING",
        },
      ],
      grandTotal: "1893.86",
    },
    pricingOptions: {
      fareType: ["PUBLISHED"],
      includedCheckedBagsOnly: true,
    },
    validatingAirlineCodes: ["EK"],
    travelerPricings: [
      {
        travelerId: "1",
        fareOption: "STANDARD",
        travelerType: "ADULT",
        price: {
          currency: "USD",
          total: "1893.86",
          base: "1538.00",
        },
        fareDetailsBySegment: [
          {
            segmentId: "3",
            cabin: "BUSINESS",
            fareBasis: "OAAAPPK1",
            brandedFare: "BSSAVER",
            brandedFareLabel: "BUSINESS SAVER",
            class: "O",
            includedCheckedBags: {
              weight: 40,
              weightUnit: "KG",
            },
            amenities: [
              {
                description: "PRE RESERVED SEAT ASSIGNMENT",
                isChargeable: false,
                amenityType: "PRE_RESERVED_SEAT",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "BEVERAGE",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "MEAL VOUCHER",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "FAST TRACK",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PREMIUM CHECK IN",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BOARDING",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BAGGAGE",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
            ],
          },
          {
            segmentId: "4",
            cabin: "BUSINESS",
            fareBasis: "OAAAPPK1",
            brandedFare: "BSSAVER",
            brandedFareLabel: "BUSINESS SAVER",
            class: "O",
            includedCheckedBags: {
              weight: 40,
              weightUnit: "KG",
            },
            amenities: [
              {
                description: "PRE RESERVED SEAT ASSIGNMENT",
                isChargeable: false,
                amenityType: "PRE_RESERVED_SEAT",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "BEVERAGE",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "MEAL VOUCHER",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "FAST TRACK",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PREMIUM CHECK IN",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BOARDING",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BAGGAGE",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
            ],
          },
          {
            segmentId: "44",
            cabin: "BUSINESS",
            fareBasis: "OAAAPPK1",
            brandedFare: "BSSAVER",
            brandedFareLabel: "BUSINESS SAVER",
            class: "O",
            includedCheckedBags: {
              weight: 40,
              weightUnit: "KG",
            },
            amenities: [
              {
                description: "PRE RESERVED SEAT ASSIGNMENT",
                isChargeable: false,
                amenityType: "PRE_RESERVED_SEAT",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "BEVERAGE",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "MEAL VOUCHER",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "FAST TRACK",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PREMIUM CHECK IN",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BOARDING",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BAGGAGE",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
            ],
          },
          {
            segmentId: "45",
            cabin: "BUSINESS",
            fareBasis: "OAAAPPK1",
            brandedFare: "BSSAVER",
            brandedFareLabel: "BUSINESS SAVER",
            class: "O",
            includedCheckedBags: {
              weight: 40,
              weightUnit: "KG",
            },
            amenities: [
              {
                description: "PRE RESERVED SEAT ASSIGNMENT",
                isChargeable: false,
                amenityType: "PRE_RESERVED_SEAT",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "BEVERAGE",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "MEAL VOUCHER",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "FAST TRACK",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PREMIUM CHECK IN",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BOARDING",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BAGGAGE",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
            ],
          },
        ],
      },
    ],
  },
  {
    type: "flight-offer",
    id: "26",
    source: "GDS",
    instantTicketingRequired: false,
    nonHomogeneous: false,
    oneWay: false,
    lastTicketingDate: "2024-05-06",
    lastTicketingDateTime: "2024-05-06",
    numberOfBookableSeats: 7,
    itineraries: [
      {
        duration: "PT19H10M",
        segments: [
          {
            departure: {
              iataCode: "LHE",
              at: "2024-05-06T09:00:00",
            },
            arrival: {
              iataCode: "DXB",
              terminal: "3",
              at: "2024-05-06T11:15:00",
            },
            carrierCode: "EK",
            number: "625",
            aircraft: {
              code: "77W",
            },
            operating: {
              carrierCode: "EK",
            },
            duration: "PT3H15M",
            id: "8",
            numberOfStops: 0,
            blacklistedInEU: false,
          },
          {
            departure: {
              iataCode: "DXB",
              terminal: "3",
              at: "2024-05-07T00:10:00",
            },
            arrival: {
              iataCode: "JED",
              terminal: "1",
              at: "2024-05-07T02:10:00",
            },
            carrierCode: "EK",
            number: "801",
            aircraft: {
              code: "388",
            },
            operating: {
              carrierCode: "EK",
            },
            duration: "PT3H",
            id: "9",
            numberOfStops: 0,
            blacklistedInEU: false,
          },
        ],
      },
      {
        duration: "PT13H5M",
        segments: [
          {
            departure: {
              iataCode: "JED",
              terminal: "1",
              at: "2024-05-11T10:45:00",
            },
            arrival: {
              iataCode: "DXB",
              terminal: "3",
              at: "2024-05-11T14:45:00",
            },
            carrierCode: "EK",
            number: "806",
            aircraft: {
              code: "388",
            },
            operating: {
              carrierCode: "EK",
            },
            duration: "PT3H",
            id: "32",
            numberOfStops: 0,
            blacklistedInEU: false,
          },
          {
            departure: {
              iataCode: "DXB",
              terminal: "3",
              at: "2024-05-11T21:50:00",
            },
            arrival: {
              iataCode: "LHE",
              at: "2024-05-12T01:50:00",
            },
            carrierCode: "EK",
            number: "622",
            aircraft: {
              code: "77W",
            },
            operating: {
              carrierCode: "EK",
            },
            duration: "PT3H",
            id: "33",
            numberOfStops: 0,
            blacklistedInEU: false,
          },
        ],
      },
    ],
    price: {
      currency: "USD",
      total: "1893.86",
      base: "1538.00",
      fees: [
        {
          amount: "0.00",
          type: "SUPPLIER",
        },
        {
          amount: "0.00",
          type: "TICKETING",
        },
      ],
      grandTotal: "1893.86",
    },
    pricingOptions: {
      fareType: ["PUBLISHED"],
      includedCheckedBagsOnly: true,
    },
    validatingAirlineCodes: ["EK"],
    travelerPricings: [
      {
        travelerId: "1",
        fareOption: "STANDARD",
        travelerType: "ADULT",
        price: {
          currency: "USD",
          total: "1893.86",
          base: "1538.00",
        },
        fareDetailsBySegment: [
          {
            segmentId: "8",
            cabin: "BUSINESS",
            fareBasis: "OAAAPPK1",
            brandedFare: "BSSAVER",
            brandedFareLabel: "BUSINESS SAVER",
            class: "O",
            includedCheckedBags: {
              weight: 40,
              weightUnit: "KG",
            },
            amenities: [
              {
                description: "PRE RESERVED SEAT ASSIGNMENT",
                isChargeable: false,
                amenityType: "PRE_RESERVED_SEAT",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "BEVERAGE",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "MEAL VOUCHER",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "FAST TRACK",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PREMIUM CHECK IN",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BOARDING",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BAGGAGE",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
            ],
          },
          {
            segmentId: "9",
            cabin: "BUSINESS",
            fareBasis: "OAAAPPK1",
            brandedFare: "BSSAVER",
            brandedFareLabel: "BUSINESS SAVER",
            class: "O",
            includedCheckedBags: {
              weight: 40,
              weightUnit: "KG",
            },
            amenities: [
              {
                description: "PRE RESERVED SEAT ASSIGNMENT",
                isChargeable: false,
                amenityType: "PRE_RESERVED_SEAT",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "BEVERAGE",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "MEAL VOUCHER",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "FAST TRACK",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PREMIUM CHECK IN",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BOARDING",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BAGGAGE",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
            ],
          },
          {
            segmentId: "32",
            cabin: "BUSINESS",
            fareBasis: "OAAAPPK1",
            brandedFare: "BSSAVER",
            brandedFareLabel: "BUSINESS SAVER",
            class: "O",
            includedCheckedBags: {
              weight: 40,
              weightUnit: "KG",
            },
            amenities: [
              {
                description: "PRE RESERVED SEAT ASSIGNMENT",
                isChargeable: false,
                amenityType: "PRE_RESERVED_SEAT",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "BEVERAGE",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "MEAL VOUCHER",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "FAST TRACK",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PREMIUM CHECK IN",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BOARDING",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BAGGAGE",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
            ],
          },
          {
            segmentId: "33",
            cabin: "BUSINESS",
            fareBasis: "OAAAPPK1",
            brandedFare: "BSSAVER",
            brandedFareLabel: "BUSINESS SAVER",
            class: "O",
            includedCheckedBags: {
              weight: 40,
              weightUnit: "KG",
            },
            amenities: [
              {
                description: "PRE RESERVED SEAT ASSIGNMENT",
                isChargeable: false,
                amenityType: "PRE_RESERVED_SEAT",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "BEVERAGE",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "MEAL VOUCHER",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "FAST TRACK",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PREMIUM CHECK IN",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BOARDING",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BAGGAGE",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
            ],
          },
        ],
      },
    ],
  },
  {
    type: "flight-offer",
    id: "27",
    source: "GDS",
    instantTicketingRequired: false,
    nonHomogeneous: false,
    oneWay: false,
    lastTicketingDate: "2024-05-06",
    lastTicketingDateTime: "2024-05-06",
    numberOfBookableSeats: 7,
    itineraries: [
      {
        duration: "PT7H15M",
        segments: [
          {
            departure: {
              iataCode: "LHE",
              at: "2024-05-06T03:30:00",
            },
            arrival: {
              iataCode: "DXB",
              terminal: "3",
              at: "2024-05-06T05:40:00",
            },
            carrierCode: "EK",
            number: "623",
            aircraft: {
              code: "77W",
            },
            operating: {
              carrierCode: "EK",
            },
            duration: "PT3H10M",
            id: "10",
            numberOfStops: 0,
            blacklistedInEU: false,
          },
          {
            departure: {
              iataCode: "DXB",
              terminal: "3",
              at: "2024-05-06T06:55:00",
            },
            arrival: {
              iataCode: "JED",
              terminal: "1",
              at: "2024-05-06T08:45:00",
            },
            carrierCode: "EK",
            number: "805",
            aircraft: {
              code: "388",
            },
            operating: {
              carrierCode: "EK",
            },
            duration: "PT2H50M",
            id: "11",
            numberOfStops: 0,
            blacklistedInEU: false,
          },
        ],
      },
      {
        duration: "PT27H45M",
        segments: [
          {
            departure: {
              iataCode: "JED",
              terminal: "1",
              at: "2024-05-11T20:05:00",
            },
            arrival: {
              iataCode: "DXB",
              terminal: "3",
              at: "2024-05-12T00:15:00",
            },
            carrierCode: "EK",
            number: "804",
            aircraft: {
              code: "388",
            },
            operating: {
              carrierCode: "EK",
            },
            duration: "PT3H10M",
            id: "50",
            numberOfStops: 0,
            blacklistedInEU: false,
          },
          {
            departure: {
              iataCode: "DXB",
              terminal: "3",
              at: "2024-05-12T21:50:00",
            },
            arrival: {
              iataCode: "LHE",
              at: "2024-05-13T01:50:00",
            },
            carrierCode: "EK",
            number: "622",
            aircraft: {
              code: "77W",
            },
            operating: {
              carrierCode: "EK",
            },
            duration: "PT3H",
            id: "51",
            numberOfStops: 0,
            blacklistedInEU: false,
          },
        ],
      },
    ],
    price: {
      currency: "USD",
      total: "1893.86",
      base: "1538.00",
      fees: [
        {
          amount: "0.00",
          type: "SUPPLIER",
        },
        {
          amount: "0.00",
          type: "TICKETING",
        },
      ],
      grandTotal: "1893.86",
    },
    pricingOptions: {
      fareType: ["PUBLISHED"],
      includedCheckedBagsOnly: true,
    },
    validatingAirlineCodes: ["EK"],
    travelerPricings: [
      {
        travelerId: "1",
        fareOption: "STANDARD",
        travelerType: "ADULT",
        price: {
          currency: "USD",
          total: "1893.86",
          base: "1538.00",
        },
        fareDetailsBySegment: [
          {
            segmentId: "10",
            cabin: "BUSINESS",
            fareBasis: "OAAAPPK1",
            brandedFare: "BSSAVER",
            brandedFareLabel: "BUSINESS SAVER",
            class: "O",
            includedCheckedBags: {
              weight: 40,
              weightUnit: "KG",
            },
            amenities: [
              {
                description: "PRE RESERVED SEAT ASSIGNMENT",
                isChargeable: false,
                amenityType: "PRE_RESERVED_SEAT",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "BEVERAGE",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "MEAL VOUCHER",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "FAST TRACK",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PREMIUM CHECK IN",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BOARDING",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BAGGAGE",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
            ],
          },
          {
            segmentId: "11",
            cabin: "BUSINESS",
            fareBasis: "OAAAPPK1",
            brandedFare: "BSSAVER",
            brandedFareLabel: "BUSINESS SAVER",
            class: "O",
            includedCheckedBags: {
              weight: 40,
              weightUnit: "KG",
            },
            amenities: [
              {
                description: "PRE RESERVED SEAT ASSIGNMENT",
                isChargeable: false,
                amenityType: "PRE_RESERVED_SEAT",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "BEVERAGE",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "MEAL VOUCHER",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "FAST TRACK",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PREMIUM CHECK IN",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BOARDING",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BAGGAGE",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
            ],
          },
          {
            segmentId: "50",
            cabin: "BUSINESS",
            fareBasis: "OAAAPPK1",
            brandedFare: "BSSAVER",
            brandedFareLabel: "BUSINESS SAVER",
            class: "O",
            includedCheckedBags: {
              weight: 40,
              weightUnit: "KG",
            },
            amenities: [
              {
                description: "PRE RESERVED SEAT ASSIGNMENT",
                isChargeable: false,
                amenityType: "PRE_RESERVED_SEAT",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "BEVERAGE",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "MEAL VOUCHER",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "FAST TRACK",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PREMIUM CHECK IN",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BOARDING",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BAGGAGE",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
            ],
          },
          {
            segmentId: "51",
            cabin: "BUSINESS",
            fareBasis: "OAAAPPK1",
            brandedFare: "BSSAVER",
            brandedFareLabel: "BUSINESS SAVER",
            class: "O",
            includedCheckedBags: {
              weight: 40,
              weightUnit: "KG",
            },
            amenities: [
              {
                description: "PRE RESERVED SEAT ASSIGNMENT",
                isChargeable: false,
                amenityType: "PRE_RESERVED_SEAT",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "BEVERAGE",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "MEAL VOUCHER",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "FAST TRACK",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PREMIUM CHECK IN",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BOARDING",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BAGGAGE",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
            ],
          },
        ],
      },
    ],
  },
  {
    type: "flight-offer",
    id: "28",
    source: "GDS",
    instantTicketingRequired: false,
    nonHomogeneous: false,
    oneWay: false,
    lastTicketingDate: "2024-05-06",
    lastTicketingDateTime: "2024-05-06",
    numberOfBookableSeats: 7,
    itineraries: [
      {
        duration: "PT16H30M",
        segments: [
          {
            departure: {
              iataCode: "LHE",
              at: "2024-05-06T03:30:00",
            },
            arrival: {
              iataCode: "DXB",
              terminal: "3",
              at: "2024-05-06T05:40:00",
            },
            carrierCode: "EK",
            number: "623",
            aircraft: {
              code: "77W",
            },
            operating: {
              carrierCode: "EK",
            },
            duration: "PT3H10M",
            id: "17",
            numberOfStops: 0,
            blacklistedInEU: false,
          },
          {
            departure: {
              iataCode: "DXB",
              terminal: "3",
              at: "2024-05-06T15:40:00",
            },
            arrival: {
              iataCode: "JED",
              terminal: "1",
              at: "2024-05-06T18:00:00",
            },
            carrierCode: "EK",
            number: "803",
            aircraft: {
              code: "388",
            },
            operating: {
              carrierCode: "EK",
            },
            duration: "PT3H20M",
            id: "18",
            numberOfStops: 0,
            blacklistedInEU: false,
          },
        ],
      },
      {
        duration: "PT19H40M",
        segments: [
          {
            departure: {
              iataCode: "JED",
              terminal: "1",
              at: "2024-05-11T04:10:00",
            },
            arrival: {
              iataCode: "DXB",
              terminal: "3",
              at: "2024-05-11T07:55:00",
            },
            carrierCode: "EK",
            number: "802",
            aircraft: {
              code: "388",
            },
            operating: {
              carrierCode: "EK",
            },
            duration: "PT2H45M",
            id: "44",
            numberOfStops: 0,
            blacklistedInEU: false,
          },
          {
            departure: {
              iataCode: "DXB",
              terminal: "3",
              at: "2024-05-11T21:50:00",
            },
            arrival: {
              iataCode: "LHE",
              at: "2024-05-12T01:50:00",
            },
            carrierCode: "EK",
            number: "622",
            aircraft: {
              code: "77W",
            },
            operating: {
              carrierCode: "EK",
            },
            duration: "PT3H",
            id: "45",
            numberOfStops: 0,
            blacklistedInEU: false,
          },
        ],
      },
    ],
    price: {
      currency: "USD",
      total: "1893.86",
      base: "1538.00",
      fees: [
        {
          amount: "0.00",
          type: "SUPPLIER",
        },
        {
          amount: "0.00",
          type: "TICKETING",
        },
      ],
      grandTotal: "1893.86",
    },
    pricingOptions: {
      fareType: ["PUBLISHED"],
      includedCheckedBagsOnly: true,
    },
    validatingAirlineCodes: ["EK"],
    travelerPricings: [
      {
        travelerId: "1",
        fareOption: "STANDARD",
        travelerType: "ADULT",
        price: {
          currency: "USD",
          total: "1893.86",
          base: "1538.00",
        },
        fareDetailsBySegment: [
          {
            segmentId: "17",
            cabin: "BUSINESS",
            fareBasis: "OAAAPPK1",
            brandedFare: "BSSAVER",
            brandedFareLabel: "BUSINESS SAVER",
            class: "O",
            includedCheckedBags: {
              weight: 40,
              weightUnit: "KG",
            },
            amenities: [
              {
                description: "PRE RESERVED SEAT ASSIGNMENT",
                isChargeable: false,
                amenityType: "PRE_RESERVED_SEAT",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "BEVERAGE",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "MEAL VOUCHER",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "FAST TRACK",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PREMIUM CHECK IN",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BOARDING",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BAGGAGE",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
            ],
          },
          {
            segmentId: "18",
            cabin: "BUSINESS",
            fareBasis: "OAAAPPK1",
            brandedFare: "BSSAVER",
            brandedFareLabel: "BUSINESS SAVER",
            class: "O",
            includedCheckedBags: {
              weight: 40,
              weightUnit: "KG",
            },
            amenities: [
              {
                description: "PRE RESERVED SEAT ASSIGNMENT",
                isChargeable: false,
                amenityType: "PRE_RESERVED_SEAT",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "BEVERAGE",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "MEAL VOUCHER",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "FAST TRACK",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PREMIUM CHECK IN",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BOARDING",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BAGGAGE",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
            ],
          },
          {
            segmentId: "44",
            cabin: "BUSINESS",
            fareBasis: "OAAAPPK1",
            brandedFare: "BSSAVER",
            brandedFareLabel: "BUSINESS SAVER",
            class: "O",
            includedCheckedBags: {
              weight: 40,
              weightUnit: "KG",
            },
            amenities: [
              {
                description: "PRE RESERVED SEAT ASSIGNMENT",
                isChargeable: false,
                amenityType: "PRE_RESERVED_SEAT",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "BEVERAGE",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "MEAL VOUCHER",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "FAST TRACK",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PREMIUM CHECK IN",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BOARDING",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BAGGAGE",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
            ],
          },
          {
            segmentId: "45",
            cabin: "BUSINESS",
            fareBasis: "OAAAPPK1",
            brandedFare: "BSSAVER",
            brandedFareLabel: "BUSINESS SAVER",
            class: "O",
            includedCheckedBags: {
              weight: 40,
              weightUnit: "KG",
            },
            amenities: [
              {
                description: "PRE RESERVED SEAT ASSIGNMENT",
                isChargeable: false,
                amenityType: "PRE_RESERVED_SEAT",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "BEVERAGE",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "MEAL VOUCHER",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "FAST TRACK",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PREMIUM CHECK IN",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BOARDING",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BAGGAGE",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
            ],
          },
        ],
      },
    ],
  },
  {
    type: "flight-offer",
    id: "29",
    source: "GDS",
    instantTicketingRequired: false,
    nonHomogeneous: false,
    oneWay: false,
    lastTicketingDate: "2024-05-06",
    lastTicketingDateTime: "2024-05-06",
    numberOfBookableSeats: 7,
    itineraries: [
      {
        duration: "PT24H40M",
        segments: [
          {
            departure: {
              iataCode: "LHE",
              at: "2024-05-06T03:30:00",
            },
            arrival: {
              iataCode: "DXB",
              terminal: "3",
              at: "2024-05-06T05:40:00",
            },
            carrierCode: "EK",
            number: "623",
            aircraft: {
              code: "77W",
            },
            operating: {
              carrierCode: "EK",
            },
            duration: "PT3H10M",
            id: "21",
            numberOfStops: 0,
            blacklistedInEU: false,
          },
          {
            departure: {
              iataCode: "DXB",
              terminal: "3",
              at: "2024-05-07T00:10:00",
            },
            arrival: {
              iataCode: "JED",
              terminal: "1",
              at: "2024-05-07T02:10:00",
            },
            carrierCode: "EK",
            number: "801",
            aircraft: {
              code: "388",
            },
            operating: {
              carrierCode: "EK",
            },
            duration: "PT3H",
            id: "22",
            numberOfStops: 0,
            blacklistedInEU: false,
          },
        ],
      },
      {
        duration: "PT13H5M",
        segments: [
          {
            departure: {
              iataCode: "JED",
              terminal: "1",
              at: "2024-05-11T10:45:00",
            },
            arrival: {
              iataCode: "DXB",
              terminal: "3",
              at: "2024-05-11T14:45:00",
            },
            carrierCode: "EK",
            number: "806",
            aircraft: {
              code: "388",
            },
            operating: {
              carrierCode: "EK",
            },
            duration: "PT3H",
            id: "32",
            numberOfStops: 0,
            blacklistedInEU: false,
          },
          {
            departure: {
              iataCode: "DXB",
              terminal: "3",
              at: "2024-05-11T21:50:00",
            },
            arrival: {
              iataCode: "LHE",
              at: "2024-05-12T01:50:00",
            },
            carrierCode: "EK",
            number: "622",
            aircraft: {
              code: "77W",
            },
            operating: {
              carrierCode: "EK",
            },
            duration: "PT3H",
            id: "33",
            numberOfStops: 0,
            blacklistedInEU: false,
          },
        ],
      },
    ],
    price: {
      currency: "USD",
      total: "1893.86",
      base: "1538.00",
      fees: [
        {
          amount: "0.00",
          type: "SUPPLIER",
        },
        {
          amount: "0.00",
          type: "TICKETING",
        },
      ],
      grandTotal: "1893.86",
    },
    pricingOptions: {
      fareType: ["PUBLISHED"],
      includedCheckedBagsOnly: true,
    },
    validatingAirlineCodes: ["EK"],
    travelerPricings: [
      {
        travelerId: "1",
        fareOption: "STANDARD",
        travelerType: "ADULT",
        price: {
          currency: "USD",
          total: "1893.86",
          base: "1538.00",
        },
        fareDetailsBySegment: [
          {
            segmentId: "21",
            cabin: "BUSINESS",
            fareBasis: "OAAAPPK1",
            brandedFare: "BSSAVER",
            brandedFareLabel: "BUSINESS SAVER",
            class: "O",
            includedCheckedBags: {
              weight: 40,
              weightUnit: "KG",
            },
            amenities: [
              {
                description: "PRE RESERVED SEAT ASSIGNMENT",
                isChargeable: false,
                amenityType: "PRE_RESERVED_SEAT",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "BEVERAGE",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "MEAL VOUCHER",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "FAST TRACK",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PREMIUM CHECK IN",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BOARDING",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BAGGAGE",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
            ],
          },
          {
            segmentId: "22",
            cabin: "BUSINESS",
            fareBasis: "OAAAPPK1",
            brandedFare: "BSSAVER",
            brandedFareLabel: "BUSINESS SAVER",
            class: "O",
            includedCheckedBags: {
              weight: 40,
              weightUnit: "KG",
            },
            amenities: [
              {
                description: "PRE RESERVED SEAT ASSIGNMENT",
                isChargeable: false,
                amenityType: "PRE_RESERVED_SEAT",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "BEVERAGE",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "MEAL VOUCHER",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "FAST TRACK",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PREMIUM CHECK IN",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BOARDING",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BAGGAGE",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
            ],
          },
          {
            segmentId: "32",
            cabin: "BUSINESS",
            fareBasis: "OAAAPPK1",
            brandedFare: "BSSAVER",
            brandedFareLabel: "BUSINESS SAVER",
            class: "O",
            includedCheckedBags: {
              weight: 40,
              weightUnit: "KG",
            },
            amenities: [
              {
                description: "PRE RESERVED SEAT ASSIGNMENT",
                isChargeable: false,
                amenityType: "PRE_RESERVED_SEAT",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "BEVERAGE",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "MEAL VOUCHER",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "FAST TRACK",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PREMIUM CHECK IN",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BOARDING",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BAGGAGE",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
            ],
          },
          {
            segmentId: "33",
            cabin: "BUSINESS",
            fareBasis: "OAAAPPK1",
            brandedFare: "BSSAVER",
            brandedFareLabel: "BUSINESS SAVER",
            class: "O",
            includedCheckedBags: {
              weight: 40,
              weightUnit: "KG",
            },
            amenities: [
              {
                description: "PRE RESERVED SEAT ASSIGNMENT",
                isChargeable: false,
                amenityType: "PRE_RESERVED_SEAT",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "BEVERAGE",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "MEAL VOUCHER",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "FAST TRACK",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PREMIUM CHECK IN",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BOARDING",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BAGGAGE",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
            ],
          },
        ],
      },
    ],
  },
  {
    type: "flight-offer",
    id: "30",
    source: "GDS",
    instantTicketingRequired: false,
    nonHomogeneous: false,
    oneWay: false,
    lastTicketingDate: "2024-05-06",
    lastTicketingDateTime: "2024-05-06",
    numberOfBookableSeats: 7,
    itineraries: [
      {
        duration: "PT11H",
        segments: [
          {
            departure: {
              iataCode: "LHE",
              at: "2024-05-06T09:00:00",
            },
            arrival: {
              iataCode: "DXB",
              terminal: "3",
              at: "2024-05-06T11:15:00",
            },
            carrierCode: "EK",
            number: "625",
            aircraft: {
              code: "77W",
            },
            operating: {
              carrierCode: "EK",
            },
            duration: "PT3H15M",
            id: "3",
            numberOfStops: 0,
            blacklistedInEU: false,
          },
          {
            departure: {
              iataCode: "DXB",
              terminal: "3",
              at: "2024-05-06T15:40:00",
            },
            arrival: {
              iataCode: "JED",
              terminal: "1",
              at: "2024-05-06T18:00:00",
            },
            carrierCode: "EK",
            number: "803",
            aircraft: {
              code: "388",
            },
            operating: {
              carrierCode: "EK",
            },
            duration: "PT3H20M",
            id: "4",
            numberOfStops: 0,
            blacklistedInEU: false,
          },
        ],
      },
      {
        duration: "PT27H45M",
        segments: [
          {
            departure: {
              iataCode: "JED",
              terminal: "1",
              at: "2024-05-11T20:05:00",
            },
            arrival: {
              iataCode: "DXB",
              terminal: "3",
              at: "2024-05-12T00:15:00",
            },
            carrierCode: "EK",
            number: "804",
            aircraft: {
              code: "388",
            },
            operating: {
              carrierCode: "EK",
            },
            duration: "PT3H10M",
            id: "50",
            numberOfStops: 0,
            blacklistedInEU: false,
          },
          {
            departure: {
              iataCode: "DXB",
              terminal: "3",
              at: "2024-05-12T21:50:00",
            },
            arrival: {
              iataCode: "LHE",
              at: "2024-05-13T01:50:00",
            },
            carrierCode: "EK",
            number: "622",
            aircraft: {
              code: "77W",
            },
            operating: {
              carrierCode: "EK",
            },
            duration: "PT3H",
            id: "51",
            numberOfStops: 0,
            blacklistedInEU: false,
          },
        ],
      },
    ],
    price: {
      currency: "USD",
      total: "1893.86",
      base: "1538.00",
      fees: [
        {
          amount: "0.00",
          type: "SUPPLIER",
        },
        {
          amount: "0.00",
          type: "TICKETING",
        },
      ],
      grandTotal: "1893.86",
    },
    pricingOptions: {
      fareType: ["PUBLISHED"],
      includedCheckedBagsOnly: true,
    },
    validatingAirlineCodes: ["EK"],
    travelerPricings: [
      {
        travelerId: "1",
        fareOption: "STANDARD",
        travelerType: "ADULT",
        price: {
          currency: "USD",
          total: "1893.86",
          base: "1538.00",
        },
        fareDetailsBySegment: [
          {
            segmentId: "3",
            cabin: "BUSINESS",
            fareBasis: "OAAAPPK1",
            brandedFare: "BSSAVER",
            brandedFareLabel: "BUSINESS SAVER",
            class: "O",
            includedCheckedBags: {
              weight: 40,
              weightUnit: "KG",
            },
            amenities: [
              {
                description: "PRE RESERVED SEAT ASSIGNMENT",
                isChargeable: false,
                amenityType: "PRE_RESERVED_SEAT",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "BEVERAGE",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "MEAL VOUCHER",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "FAST TRACK",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PREMIUM CHECK IN",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BOARDING",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BAGGAGE",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
            ],
          },
          {
            segmentId: "4",
            cabin: "BUSINESS",
            fareBasis: "OAAAPPK1",
            brandedFare: "BSSAVER",
            brandedFareLabel: "BUSINESS SAVER",
            class: "O",
            includedCheckedBags: {
              weight: 40,
              weightUnit: "KG",
            },
            amenities: [
              {
                description: "PRE RESERVED SEAT ASSIGNMENT",
                isChargeable: false,
                amenityType: "PRE_RESERVED_SEAT",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "BEVERAGE",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "MEAL VOUCHER",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "FAST TRACK",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PREMIUM CHECK IN",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BOARDING",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BAGGAGE",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
            ],
          },
          {
            segmentId: "50",
            cabin: "BUSINESS",
            fareBasis: "OAAAPPK1",
            brandedFare: "BSSAVER",
            brandedFareLabel: "BUSINESS SAVER",
            class: "O",
            includedCheckedBags: {
              weight: 40,
              weightUnit: "KG",
            },
            amenities: [
              {
                description: "PRE RESERVED SEAT ASSIGNMENT",
                isChargeable: false,
                amenityType: "PRE_RESERVED_SEAT",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "BEVERAGE",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "MEAL VOUCHER",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "FAST TRACK",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PREMIUM CHECK IN",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BOARDING",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BAGGAGE",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
            ],
          },
          {
            segmentId: "51",
            cabin: "BUSINESS",
            fareBasis: "OAAAPPK1",
            brandedFare: "BSSAVER",
            brandedFareLabel: "BUSINESS SAVER",
            class: "O",
            includedCheckedBags: {
              weight: 40,
              weightUnit: "KG",
            },
            amenities: [
              {
                description: "PRE RESERVED SEAT ASSIGNMENT",
                isChargeable: false,
                amenityType: "PRE_RESERVED_SEAT",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "BEVERAGE",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "MEAL VOUCHER",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "FAST TRACK",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PREMIUM CHECK IN",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BOARDING",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BAGGAGE",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
            ],
          },
        ],
      },
    ],
  },
  {
    type: "flight-offer",
    id: "31",
    source: "GDS",
    instantTicketingRequired: false,
    nonHomogeneous: false,
    oneWay: false,
    lastTicketingDate: "2024-05-06",
    lastTicketingDateTime: "2024-05-06",
    numberOfBookableSeats: 7,
    itineraries: [
      {
        duration: "PT19H10M",
        segments: [
          {
            departure: {
              iataCode: "LHE",
              at: "2024-05-06T09:00:00",
            },
            arrival: {
              iataCode: "DXB",
              terminal: "3",
              at: "2024-05-06T11:15:00",
            },
            carrierCode: "EK",
            number: "625",
            aircraft: {
              code: "77W",
            },
            operating: {
              carrierCode: "EK",
            },
            duration: "PT3H15M",
            id: "8",
            numberOfStops: 0,
            blacklistedInEU: false,
          },
          {
            departure: {
              iataCode: "DXB",
              terminal: "3",
              at: "2024-05-07T00:10:00",
            },
            arrival: {
              iataCode: "JED",
              terminal: "1",
              at: "2024-05-07T02:10:00",
            },
            carrierCode: "EK",
            number: "801",
            aircraft: {
              code: "388",
            },
            operating: {
              carrierCode: "EK",
            },
            duration: "PT3H",
            id: "9",
            numberOfStops: 0,
            blacklistedInEU: false,
          },
        ],
      },
      {
        duration: "PT19H40M",
        segments: [
          {
            departure: {
              iataCode: "JED",
              terminal: "1",
              at: "2024-05-11T04:10:00",
            },
            arrival: {
              iataCode: "DXB",
              terminal: "3",
              at: "2024-05-11T07:55:00",
            },
            carrierCode: "EK",
            number: "802",
            aircraft: {
              code: "388",
            },
            operating: {
              carrierCode: "EK",
            },
            duration: "PT2H45M",
            id: "44",
            numberOfStops: 0,
            blacklistedInEU: false,
          },
          {
            departure: {
              iataCode: "DXB",
              terminal: "3",
              at: "2024-05-11T21:50:00",
            },
            arrival: {
              iataCode: "LHE",
              at: "2024-05-12T01:50:00",
            },
            carrierCode: "EK",
            number: "622",
            aircraft: {
              code: "77W",
            },
            operating: {
              carrierCode: "EK",
            },
            duration: "PT3H",
            id: "45",
            numberOfStops: 0,
            blacklistedInEU: false,
          },
        ],
      },
    ],
    price: {
      currency: "USD",
      total: "1893.86",
      base: "1538.00",
      fees: [
        {
          amount: "0.00",
          type: "SUPPLIER",
        },
        {
          amount: "0.00",
          type: "TICKETING",
        },
      ],
      grandTotal: "1893.86",
    },
    pricingOptions: {
      fareType: ["PUBLISHED"],
      includedCheckedBagsOnly: true,
    },
    validatingAirlineCodes: ["EK"],
    travelerPricings: [
      {
        travelerId: "1",
        fareOption: "STANDARD",
        travelerType: "ADULT",
        price: {
          currency: "USD",
          total: "1893.86",
          base: "1538.00",
        },
        fareDetailsBySegment: [
          {
            segmentId: "8",
            cabin: "BUSINESS",
            fareBasis: "OAAAPPK1",
            brandedFare: "BSSAVER",
            brandedFareLabel: "BUSINESS SAVER",
            class: "O",
            includedCheckedBags: {
              weight: 40,
              weightUnit: "KG",
            },
            amenities: [
              {
                description: "PRE RESERVED SEAT ASSIGNMENT",
                isChargeable: false,
                amenityType: "PRE_RESERVED_SEAT",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "BEVERAGE",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "MEAL VOUCHER",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "FAST TRACK",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PREMIUM CHECK IN",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BOARDING",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BAGGAGE",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
            ],
          },
          {
            segmentId: "9",
            cabin: "BUSINESS",
            fareBasis: "OAAAPPK1",
            brandedFare: "BSSAVER",
            brandedFareLabel: "BUSINESS SAVER",
            class: "O",
            includedCheckedBags: {
              weight: 40,
              weightUnit: "KG",
            },
            amenities: [
              {
                description: "PRE RESERVED SEAT ASSIGNMENT",
                isChargeable: false,
                amenityType: "PRE_RESERVED_SEAT",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "BEVERAGE",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "MEAL VOUCHER",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "FAST TRACK",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PREMIUM CHECK IN",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BOARDING",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BAGGAGE",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
            ],
          },
          {
            segmentId: "44",
            cabin: "BUSINESS",
            fareBasis: "OAAAPPK1",
            brandedFare: "BSSAVER",
            brandedFareLabel: "BUSINESS SAVER",
            class: "O",
            includedCheckedBags: {
              weight: 40,
              weightUnit: "KG",
            },
            amenities: [
              {
                description: "PRE RESERVED SEAT ASSIGNMENT",
                isChargeable: false,
                amenityType: "PRE_RESERVED_SEAT",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "BEVERAGE",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "MEAL VOUCHER",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "FAST TRACK",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PREMIUM CHECK IN",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BOARDING",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BAGGAGE",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
            ],
          },
          {
            segmentId: "45",
            cabin: "BUSINESS",
            fareBasis: "OAAAPPK1",
            brandedFare: "BSSAVER",
            brandedFareLabel: "BUSINESS SAVER",
            class: "O",
            includedCheckedBags: {
              weight: 40,
              weightUnit: "KG",
            },
            amenities: [
              {
                description: "PRE RESERVED SEAT ASSIGNMENT",
                isChargeable: false,
                amenityType: "PRE_RESERVED_SEAT",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "BEVERAGE",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "MEAL VOUCHER",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "FAST TRACK",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PREMIUM CHECK IN",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BOARDING",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BAGGAGE",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
            ],
          },
        ],
      },
    ],
  },
  {
    type: "flight-offer",
    id: "32",
    source: "GDS",
    instantTicketingRequired: false,
    nonHomogeneous: false,
    oneWay: false,
    lastTicketingDate: "2024-05-06",
    lastTicketingDateTime: "2024-05-06",
    numberOfBookableSeats: 7,
    itineraries: [
      {
        duration: "PT16H30M",
        segments: [
          {
            departure: {
              iataCode: "LHE",
              at: "2024-05-06T03:30:00",
            },
            arrival: {
              iataCode: "DXB",
              terminal: "3",
              at: "2024-05-06T05:40:00",
            },
            carrierCode: "EK",
            number: "623",
            aircraft: {
              code: "77W",
            },
            operating: {
              carrierCode: "EK",
            },
            duration: "PT3H10M",
            id: "17",
            numberOfStops: 0,
            blacklistedInEU: false,
          },
          {
            departure: {
              iataCode: "DXB",
              terminal: "3",
              at: "2024-05-06T15:40:00",
            },
            arrival: {
              iataCode: "JED",
              terminal: "1",
              at: "2024-05-06T18:00:00",
            },
            carrierCode: "EK",
            number: "803",
            aircraft: {
              code: "388",
            },
            operating: {
              carrierCode: "EK",
            },
            duration: "PT3H20M",
            id: "18",
            numberOfStops: 0,
            blacklistedInEU: false,
          },
        ],
      },
      {
        duration: "PT27H45M",
        segments: [
          {
            departure: {
              iataCode: "JED",
              terminal: "1",
              at: "2024-05-11T20:05:00",
            },
            arrival: {
              iataCode: "DXB",
              terminal: "3",
              at: "2024-05-12T00:15:00",
            },
            carrierCode: "EK",
            number: "804",
            aircraft: {
              code: "388",
            },
            operating: {
              carrierCode: "EK",
            },
            duration: "PT3H10M",
            id: "50",
            numberOfStops: 0,
            blacklistedInEU: false,
          },
          {
            departure: {
              iataCode: "DXB",
              terminal: "3",
              at: "2024-05-12T21:50:00",
            },
            arrival: {
              iataCode: "LHE",
              at: "2024-05-13T01:50:00",
            },
            carrierCode: "EK",
            number: "622",
            aircraft: {
              code: "77W",
            },
            operating: {
              carrierCode: "EK",
            },
            duration: "PT3H",
            id: "51",
            numberOfStops: 0,
            blacklistedInEU: false,
          },
        ],
      },
    ],
    price: {
      currency: "USD",
      total: "1893.86",
      base: "1538.00",
      fees: [
        {
          amount: "0.00",
          type: "SUPPLIER",
        },
        {
          amount: "0.00",
          type: "TICKETING",
        },
      ],
      grandTotal: "1893.86",
    },
    pricingOptions: {
      fareType: ["PUBLISHED"],
      includedCheckedBagsOnly: true,
    },
    validatingAirlineCodes: ["EK"],
    travelerPricings: [
      {
        travelerId: "1",
        fareOption: "STANDARD",
        travelerType: "ADULT",
        price: {
          currency: "USD",
          total: "1893.86",
          base: "1538.00",
        },
        fareDetailsBySegment: [
          {
            segmentId: "17",
            cabin: "BUSINESS",
            fareBasis: "OAAAPPK1",
            brandedFare: "BSSAVER",
            brandedFareLabel: "BUSINESS SAVER",
            class: "O",
            includedCheckedBags: {
              weight: 40,
              weightUnit: "KG",
            },
            amenities: [
              {
                description: "PRE RESERVED SEAT ASSIGNMENT",
                isChargeable: false,
                amenityType: "PRE_RESERVED_SEAT",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "BEVERAGE",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "MEAL VOUCHER",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "FAST TRACK",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PREMIUM CHECK IN",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BOARDING",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BAGGAGE",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
            ],
          },
          {
            segmentId: "18",
            cabin: "BUSINESS",
            fareBasis: "OAAAPPK1",
            brandedFare: "BSSAVER",
            brandedFareLabel: "BUSINESS SAVER",
            class: "O",
            includedCheckedBags: {
              weight: 40,
              weightUnit: "KG",
            },
            amenities: [
              {
                description: "PRE RESERVED SEAT ASSIGNMENT",
                isChargeable: false,
                amenityType: "PRE_RESERVED_SEAT",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "BEVERAGE",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "MEAL VOUCHER",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "FAST TRACK",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PREMIUM CHECK IN",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BOARDING",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BAGGAGE",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
            ],
          },
          {
            segmentId: "50",
            cabin: "BUSINESS",
            fareBasis: "OAAAPPK1",
            brandedFare: "BSSAVER",
            brandedFareLabel: "BUSINESS SAVER",
            class: "O",
            includedCheckedBags: {
              weight: 40,
              weightUnit: "KG",
            },
            amenities: [
              {
                description: "PRE RESERVED SEAT ASSIGNMENT",
                isChargeable: false,
                amenityType: "PRE_RESERVED_SEAT",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "BEVERAGE",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "MEAL VOUCHER",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "FAST TRACK",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PREMIUM CHECK IN",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BOARDING",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BAGGAGE",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
            ],
          },
          {
            segmentId: "51",
            cabin: "BUSINESS",
            fareBasis: "OAAAPPK1",
            brandedFare: "BSSAVER",
            brandedFareLabel: "BUSINESS SAVER",
            class: "O",
            includedCheckedBags: {
              weight: 40,
              weightUnit: "KG",
            },
            amenities: [
              {
                description: "PRE RESERVED SEAT ASSIGNMENT",
                isChargeable: false,
                amenityType: "PRE_RESERVED_SEAT",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "BEVERAGE",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "MEAL VOUCHER",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "FAST TRACK",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PREMIUM CHECK IN",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BOARDING",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BAGGAGE",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
            ],
          },
        ],
      },
    ],
  },
  {
    type: "flight-offer",
    id: "33",
    source: "GDS",
    instantTicketingRequired: false,
    nonHomogeneous: false,
    oneWay: false,
    lastTicketingDate: "2024-05-06",
    lastTicketingDateTime: "2024-05-06",
    numberOfBookableSeats: 7,
    itineraries: [
      {
        duration: "PT24H40M",
        segments: [
          {
            departure: {
              iataCode: "LHE",
              at: "2024-05-06T03:30:00",
            },
            arrival: {
              iataCode: "DXB",
              terminal: "3",
              at: "2024-05-06T05:40:00",
            },
            carrierCode: "EK",
            number: "623",
            aircraft: {
              code: "77W",
            },
            operating: {
              carrierCode: "EK",
            },
            duration: "PT3H10M",
            id: "21",
            numberOfStops: 0,
            blacklistedInEU: false,
          },
          {
            departure: {
              iataCode: "DXB",
              terminal: "3",
              at: "2024-05-07T00:10:00",
            },
            arrival: {
              iataCode: "JED",
              terminal: "1",
              at: "2024-05-07T02:10:00",
            },
            carrierCode: "EK",
            number: "801",
            aircraft: {
              code: "388",
            },
            operating: {
              carrierCode: "EK",
            },
            duration: "PT3H",
            id: "22",
            numberOfStops: 0,
            blacklistedInEU: false,
          },
        ],
      },
      {
        duration: "PT19H40M",
        segments: [
          {
            departure: {
              iataCode: "JED",
              terminal: "1",
              at: "2024-05-11T04:10:00",
            },
            arrival: {
              iataCode: "DXB",
              terminal: "3",
              at: "2024-05-11T07:55:00",
            },
            carrierCode: "EK",
            number: "802",
            aircraft: {
              code: "388",
            },
            operating: {
              carrierCode: "EK",
            },
            duration: "PT2H45M",
            id: "44",
            numberOfStops: 0,
            blacklistedInEU: false,
          },
          {
            departure: {
              iataCode: "DXB",
              terminal: "3",
              at: "2024-05-11T21:50:00",
            },
            arrival: {
              iataCode: "LHE",
              at: "2024-05-12T01:50:00",
            },
            carrierCode: "EK",
            number: "622",
            aircraft: {
              code: "77W",
            },
            operating: {
              carrierCode: "EK",
            },
            duration: "PT3H",
            id: "45",
            numberOfStops: 0,
            blacklistedInEU: false,
          },
        ],
      },
    ],
    price: {
      currency: "USD",
      total: "1893.86",
      base: "1538.00",
      fees: [
        {
          amount: "0.00",
          type: "SUPPLIER",
        },
        {
          amount: "0.00",
          type: "TICKETING",
        },
      ],
      grandTotal: "1893.86",
    },
    pricingOptions: {
      fareType: ["PUBLISHED"],
      includedCheckedBagsOnly: true,
    },
    validatingAirlineCodes: ["EK"],
    travelerPricings: [
      {
        travelerId: "1",
        fareOption: "STANDARD",
        travelerType: "ADULT",
        price: {
          currency: "USD",
          total: "1893.86",
          base: "1538.00",
        },
        fareDetailsBySegment: [
          {
            segmentId: "21",
            cabin: "BUSINESS",
            fareBasis: "OAAAPPK1",
            brandedFare: "BSSAVER",
            brandedFareLabel: "BUSINESS SAVER",
            class: "O",
            includedCheckedBags: {
              weight: 40,
              weightUnit: "KG",
            },
            amenities: [
              {
                description: "PRE RESERVED SEAT ASSIGNMENT",
                isChargeable: false,
                amenityType: "PRE_RESERVED_SEAT",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "BEVERAGE",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "MEAL VOUCHER",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "FAST TRACK",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PREMIUM CHECK IN",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BOARDING",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BAGGAGE",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
            ],
          },
          {
            segmentId: "22",
            cabin: "BUSINESS",
            fareBasis: "OAAAPPK1",
            brandedFare: "BSSAVER",
            brandedFareLabel: "BUSINESS SAVER",
            class: "O",
            includedCheckedBags: {
              weight: 40,
              weightUnit: "KG",
            },
            amenities: [
              {
                description: "PRE RESERVED SEAT ASSIGNMENT",
                isChargeable: false,
                amenityType: "PRE_RESERVED_SEAT",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "BEVERAGE",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "MEAL VOUCHER",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "FAST TRACK",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PREMIUM CHECK IN",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BOARDING",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BAGGAGE",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
            ],
          },
          {
            segmentId: "44",
            cabin: "BUSINESS",
            fareBasis: "OAAAPPK1",
            brandedFare: "BSSAVER",
            brandedFareLabel: "BUSINESS SAVER",
            class: "O",
            includedCheckedBags: {
              weight: 40,
              weightUnit: "KG",
            },
            amenities: [
              {
                description: "PRE RESERVED SEAT ASSIGNMENT",
                isChargeable: false,
                amenityType: "PRE_RESERVED_SEAT",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "BEVERAGE",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "MEAL VOUCHER",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "FAST TRACK",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PREMIUM CHECK IN",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BOARDING",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BAGGAGE",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
            ],
          },
          {
            segmentId: "45",
            cabin: "BUSINESS",
            fareBasis: "OAAAPPK1",
            brandedFare: "BSSAVER",
            brandedFareLabel: "BUSINESS SAVER",
            class: "O",
            includedCheckedBags: {
              weight: 40,
              weightUnit: "KG",
            },
            amenities: [
              {
                description: "PRE RESERVED SEAT ASSIGNMENT",
                isChargeable: false,
                amenityType: "PRE_RESERVED_SEAT",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "BEVERAGE",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "MEAL VOUCHER",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "FAST TRACK",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PREMIUM CHECK IN",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BOARDING",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BAGGAGE",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
            ],
          },
        ],
      },
    ],
  },
  {
    type: "flight-offer",
    id: "34",
    source: "GDS",
    instantTicketingRequired: false,
    nonHomogeneous: false,
    oneWay: false,
    lastTicketingDate: "2024-05-06",
    lastTicketingDateTime: "2024-05-06",
    numberOfBookableSeats: 7,
    itineraries: [
      {
        duration: "PT19H10M",
        segments: [
          {
            departure: {
              iataCode: "LHE",
              at: "2024-05-06T09:00:00",
            },
            arrival: {
              iataCode: "DXB",
              terminal: "3",
              at: "2024-05-06T11:15:00",
            },
            carrierCode: "EK",
            number: "625",
            aircraft: {
              code: "77W",
            },
            operating: {
              carrierCode: "EK",
            },
            duration: "PT3H15M",
            id: "8",
            numberOfStops: 0,
            blacklistedInEU: false,
          },
          {
            departure: {
              iataCode: "DXB",
              terminal: "3",
              at: "2024-05-07T00:10:00",
            },
            arrival: {
              iataCode: "JED",
              terminal: "1",
              at: "2024-05-07T02:10:00",
            },
            carrierCode: "EK",
            number: "801",
            aircraft: {
              code: "388",
            },
            operating: {
              carrierCode: "EK",
            },
            duration: "PT3H",
            id: "9",
            numberOfStops: 0,
            blacklistedInEU: false,
          },
        ],
      },
      {
        duration: "PT27H45M",
        segments: [
          {
            departure: {
              iataCode: "JED",
              terminal: "1",
              at: "2024-05-11T20:05:00",
            },
            arrival: {
              iataCode: "DXB",
              terminal: "3",
              at: "2024-05-12T00:15:00",
            },
            carrierCode: "EK",
            number: "804",
            aircraft: {
              code: "388",
            },
            operating: {
              carrierCode: "EK",
            },
            duration: "PT3H10M",
            id: "50",
            numberOfStops: 0,
            blacklistedInEU: false,
          },
          {
            departure: {
              iataCode: "DXB",
              terminal: "3",
              at: "2024-05-12T21:50:00",
            },
            arrival: {
              iataCode: "LHE",
              at: "2024-05-13T01:50:00",
            },
            carrierCode: "EK",
            number: "622",
            aircraft: {
              code: "77W",
            },
            operating: {
              carrierCode: "EK",
            },
            duration: "PT3H",
            id: "51",
            numberOfStops: 0,
            blacklistedInEU: false,
          },
        ],
      },
    ],
    price: {
      currency: "USD",
      total: "1893.86",
      base: "1538.00",
      fees: [
        {
          amount: "0.00",
          type: "SUPPLIER",
        },
        {
          amount: "0.00",
          type: "TICKETING",
        },
      ],
      grandTotal: "1893.86",
    },
    pricingOptions: {
      fareType: ["PUBLISHED"],
      includedCheckedBagsOnly: true,
    },
    validatingAirlineCodes: ["EK"],
    travelerPricings: [
      {
        travelerId: "1",
        fareOption: "STANDARD",
        travelerType: "ADULT",
        price: {
          currency: "USD",
          total: "1893.86",
          base: "1538.00",
        },
        fareDetailsBySegment: [
          {
            segmentId: "8",
            cabin: "BUSINESS",
            fareBasis: "OAAAPPK1",
            brandedFare: "BSSAVER",
            brandedFareLabel: "BUSINESS SAVER",
            class: "O",
            includedCheckedBags: {
              weight: 40,
              weightUnit: "KG",
            },
            amenities: [
              {
                description: "PRE RESERVED SEAT ASSIGNMENT",
                isChargeable: false,
                amenityType: "PRE_RESERVED_SEAT",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "BEVERAGE",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "MEAL VOUCHER",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "FAST TRACK",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PREMIUM CHECK IN",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BOARDING",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BAGGAGE",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
            ],
          },
          {
            segmentId: "9",
            cabin: "BUSINESS",
            fareBasis: "OAAAPPK1",
            brandedFare: "BSSAVER",
            brandedFareLabel: "BUSINESS SAVER",
            class: "O",
            includedCheckedBags: {
              weight: 40,
              weightUnit: "KG",
            },
            amenities: [
              {
                description: "PRE RESERVED SEAT ASSIGNMENT",
                isChargeable: false,
                amenityType: "PRE_RESERVED_SEAT",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "BEVERAGE",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "MEAL VOUCHER",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "FAST TRACK",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PREMIUM CHECK IN",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BOARDING",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BAGGAGE",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
            ],
          },
          {
            segmentId: "50",
            cabin: "BUSINESS",
            fareBasis: "OAAAPPK1",
            brandedFare: "BSSAVER",
            brandedFareLabel: "BUSINESS SAVER",
            class: "O",
            includedCheckedBags: {
              weight: 40,
              weightUnit: "KG",
            },
            amenities: [
              {
                description: "PRE RESERVED SEAT ASSIGNMENT",
                isChargeable: false,
                amenityType: "PRE_RESERVED_SEAT",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "BEVERAGE",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "MEAL VOUCHER",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "FAST TRACK",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PREMIUM CHECK IN",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BOARDING",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BAGGAGE",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
            ],
          },
          {
            segmentId: "51",
            cabin: "BUSINESS",
            fareBasis: "OAAAPPK1",
            brandedFare: "BSSAVER",
            brandedFareLabel: "BUSINESS SAVER",
            class: "O",
            includedCheckedBags: {
              weight: 40,
              weightUnit: "KG",
            },
            amenities: [
              {
                description: "PRE RESERVED SEAT ASSIGNMENT",
                isChargeable: false,
                amenityType: "PRE_RESERVED_SEAT",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "BEVERAGE",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "MEAL VOUCHER",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "FAST TRACK",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PREMIUM CHECK IN",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BOARDING",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BAGGAGE",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
            ],
          },
        ],
      },
    ],
  },
  {
    type: "flight-offer",
    id: "35",
    source: "GDS",
    instantTicketingRequired: false,
    nonHomogeneous: false,
    oneWay: false,
    lastTicketingDate: "2024-05-06",
    lastTicketingDateTime: "2024-05-06",
    numberOfBookableSeats: 7,
    itineraries: [
      {
        duration: "PT24H40M",
        segments: [
          {
            departure: {
              iataCode: "LHE",
              at: "2024-05-06T03:30:00",
            },
            arrival: {
              iataCode: "DXB",
              terminal: "3",
              at: "2024-05-06T05:40:00",
            },
            carrierCode: "EK",
            number: "623",
            aircraft: {
              code: "77W",
            },
            operating: {
              carrierCode: "EK",
            },
            duration: "PT3H10M",
            id: "21",
            numberOfStops: 0,
            blacklistedInEU: false,
          },
          {
            departure: {
              iataCode: "DXB",
              terminal: "3",
              at: "2024-05-07T00:10:00",
            },
            arrival: {
              iataCode: "JED",
              terminal: "1",
              at: "2024-05-07T02:10:00",
            },
            carrierCode: "EK",
            number: "801",
            aircraft: {
              code: "388",
            },
            operating: {
              carrierCode: "EK",
            },
            duration: "PT3H",
            id: "22",
            numberOfStops: 0,
            blacklistedInEU: false,
          },
        ],
      },
      {
        duration: "PT27H45M",
        segments: [
          {
            departure: {
              iataCode: "JED",
              terminal: "1",
              at: "2024-05-11T20:05:00",
            },
            arrival: {
              iataCode: "DXB",
              terminal: "3",
              at: "2024-05-12T00:15:00",
            },
            carrierCode: "EK",
            number: "804",
            aircraft: {
              code: "388",
            },
            operating: {
              carrierCode: "EK",
            },
            duration: "PT3H10M",
            id: "50",
            numberOfStops: 0,
            blacklistedInEU: false,
          },
          {
            departure: {
              iataCode: "DXB",
              terminal: "3",
              at: "2024-05-12T21:50:00",
            },
            arrival: {
              iataCode: "LHE",
              at: "2024-05-13T01:50:00",
            },
            carrierCode: "EK",
            number: "622",
            aircraft: {
              code: "77W",
            },
            operating: {
              carrierCode: "EK",
            },
            duration: "PT3H",
            id: "51",
            numberOfStops: 0,
            blacklistedInEU: false,
          },
        ],
      },
    ],
    price: {
      currency: "USD",
      total: "1893.86",
      base: "1538.00",
      fees: [
        {
          amount: "0.00",
          type: "SUPPLIER",
        },
        {
          amount: "0.00",
          type: "TICKETING",
        },
      ],
      grandTotal: "1893.86",
    },
    pricingOptions: {
      fareType: ["PUBLISHED"],
      includedCheckedBagsOnly: true,
    },
    validatingAirlineCodes: ["EK"],
    travelerPricings: [
      {
        travelerId: "1",
        fareOption: "STANDARD",
        travelerType: "ADULT",
        price: {
          currency: "USD",
          total: "1893.86",
          base: "1538.00",
        },
        fareDetailsBySegment: [
          {
            segmentId: "21",
            cabin: "BUSINESS",
            fareBasis: "OAAAPPK1",
            brandedFare: "BSSAVER",
            brandedFareLabel: "BUSINESS SAVER",
            class: "O",
            includedCheckedBags: {
              weight: 40,
              weightUnit: "KG",
            },
            amenities: [
              {
                description: "PRE RESERVED SEAT ASSIGNMENT",
                isChargeable: false,
                amenityType: "PRE_RESERVED_SEAT",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "BEVERAGE",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "MEAL VOUCHER",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "FAST TRACK",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PREMIUM CHECK IN",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BOARDING",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BAGGAGE",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
            ],
          },
          {
            segmentId: "22",
            cabin: "BUSINESS",
            fareBasis: "OAAAPPK1",
            brandedFare: "BSSAVER",
            brandedFareLabel: "BUSINESS SAVER",
            class: "O",
            includedCheckedBags: {
              weight: 40,
              weightUnit: "KG",
            },
            amenities: [
              {
                description: "PRE RESERVED SEAT ASSIGNMENT",
                isChargeable: false,
                amenityType: "PRE_RESERVED_SEAT",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "BEVERAGE",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "MEAL VOUCHER",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "FAST TRACK",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PREMIUM CHECK IN",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BOARDING",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BAGGAGE",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
            ],
          },
          {
            segmentId: "50",
            cabin: "BUSINESS",
            fareBasis: "OAAAPPK1",
            brandedFare: "BSSAVER",
            brandedFareLabel: "BUSINESS SAVER",
            class: "O",
            includedCheckedBags: {
              weight: 40,
              weightUnit: "KG",
            },
            amenities: [
              {
                description: "PRE RESERVED SEAT ASSIGNMENT",
                isChargeable: false,
                amenityType: "PRE_RESERVED_SEAT",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "BEVERAGE",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "MEAL VOUCHER",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "FAST TRACK",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PREMIUM CHECK IN",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BOARDING",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BAGGAGE",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
            ],
          },
          {
            segmentId: "51",
            cabin: "BUSINESS",
            fareBasis: "OAAAPPK1",
            brandedFare: "BSSAVER",
            brandedFareLabel: "BUSINESS SAVER",
            class: "O",
            includedCheckedBags: {
              weight: 40,
              weightUnit: "KG",
            },
            amenities: [
              {
                description: "PRE RESERVED SEAT ASSIGNMENT",
                isChargeable: false,
                amenityType: "PRE_RESERVED_SEAT",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "BEVERAGE",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "MEAL VOUCHER",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "FAST TRACK",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PREMIUM CHECK IN",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BOARDING",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BAGGAGE",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
            ],
          },
        ],
      },
    ],
  },
  {
    type: "flight-offer",
    id: "36",
    source: "GDS",
    instantTicketingRequired: false,
    nonHomogeneous: false,
    oneWay: false,
    lastTicketingDate: "2024-05-06",
    lastTicketingDateTime: "2024-05-06",
    numberOfBookableSeats: 4,
    itineraries: [
      {
        duration: "PT11H55M",
        segments: [
          {
            departure: {
              iataCode: "LHE",
              at: "2024-05-06T05:45:00",
            },
            arrival: {
              iataCode: "IST",
              at: "2024-05-06T10:30:00",
            },
            carrierCode: "TK",
            number: "715",
            aircraft: {
              code: "333",
            },
            operating: {
              carrierCode: "TK",
            },
            duration: "PT6H45M",
            id: "25",
            numberOfStops: 0,
            blacklistedInEU: false,
          },
          {
            departure: {
              iataCode: "IST",
              at: "2024-05-06T11:45:00",
            },
            arrival: {
              iataCode: "JED",
              terminal: "1",
              at: "2024-05-06T15:40:00",
            },
            carrierCode: "TK",
            number: "92",
            aircraft: {
              code: "32Q",
            },
            operating: {
              carrierCode: "TK",
            },
            duration: "PT3H55M",
            id: "26",
            numberOfStops: 0,
            blacklistedInEU: false,
          },
        ],
      },
      {
        duration: "PT13H35M",
        segments: [
          {
            departure: {
              iataCode: "JED",
              terminal: "1",
              at: "2024-05-11T12:40:00",
            },
            arrival: {
              iataCode: "IST",
              at: "2024-05-11T16:35:00",
            },
            carrierCode: "TK",
            number: "127",
            aircraft: {
              code: "32B",
            },
            operating: {
              carrierCode: "TK",
            },
            duration: "PT3H55M",
            id: "42",
            numberOfStops: 0,
            blacklistedInEU: false,
          },
          {
            departure: {
              iataCode: "IST",
              at: "2024-05-11T20:30:00",
            },
            arrival: {
              iataCode: "LHE",
              at: "2024-05-12T04:15:00",
            },
            carrierCode: "TK",
            number: "714",
            aircraft: {
              code: "333",
            },
            operating: {
              carrierCode: "TK",
            },
            duration: "PT5H45M",
            id: "43",
            numberOfStops: 0,
            blacklistedInEU: false,
          },
        ],
      },
    ],
    price: {
      currency: "USD",
      total: "3493.26",
      base: "2700.00",
      fees: [
        {
          amount: "0.00",
          type: "SUPPLIER",
        },
        {
          amount: "0.00",
          type: "TICKETING",
        },
      ],
      grandTotal: "3493.26",
    },
    pricingOptions: {
      fareType: ["PUBLISHED"],
      includedCheckedBagsOnly: true,
    },
    validatingAirlineCodes: ["TK"],
    travelerPricings: [
      {
        travelerId: "1",
        fareOption: "STANDARD",
        travelerType: "ADULT",
        price: {
          currency: "USD",
          total: "3493.26",
          base: "2700.00",
        },
        fareDetailsBySegment: [
          {
            segmentId: "25",
            cabin: "BUSINESS",
            fareBasis: "KF2BX",
            brandedFare: "BX",
            brandedFareLabel: "BUSINESS RESTRICTED",
            class: "K",
            includedCheckedBags: {
              weight: 40,
              weightUnit: "KG",
            },
            amenities: [
              {
                description: "MEAL SERVICE",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "FAST TRACK IF AVAILABLE",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY CHECK IN",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BOARDING",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "STANDART SEAT SELECTION",
                isChargeable: false,
                amenityType: "BRANDED_FARES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "REFUNDABLE TICKET",
                isChargeable: true,
                amenityType: "BRANDED_FARES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "CHANGEABLE TICKET",
                isChargeable: true,
                amenityType: "BRANDED_FARES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
            ],
          },
          {
            segmentId: "26",
            cabin: "BUSINESS",
            fareBasis: "KF2BX",
            brandedFare: "BX",
            brandedFareLabel: "BUSINESS RESTRICTED",
            class: "K",
            includedCheckedBags: {
              weight: 40,
              weightUnit: "KG",
            },
            amenities: [
              {
                description: "MEAL SERVICE",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "FAST TRACK IF AVAILABLE",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY CHECK IN",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BOARDING",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "STANDART SEAT SELECTION",
                isChargeable: false,
                amenityType: "BRANDED_FARES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "REFUNDABLE TICKET",
                isChargeable: true,
                amenityType: "BRANDED_FARES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "CHANGEABLE TICKET",
                isChargeable: true,
                amenityType: "BRANDED_FARES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
            ],
          },
          {
            segmentId: "42",
            cabin: "BUSINESS",
            fareBasis: "KF2BX",
            brandedFare: "BX",
            brandedFareLabel: "BUSINESS RESTRICTED",
            class: "K",
            includedCheckedBags: {
              weight: 40,
              weightUnit: "KG",
            },
            amenities: [
              {
                description: "MEAL SERVICE",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "FAST TRACK IF AVAILABLE",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY CHECK IN",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BOARDING",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "STANDART SEAT SELECTION",
                isChargeable: false,
                amenityType: "BRANDED_FARES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "REFUNDABLE TICKET",
                isChargeable: true,
                amenityType: "BRANDED_FARES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "CHANGEABLE TICKET",
                isChargeable: true,
                amenityType: "BRANDED_FARES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
            ],
          },
          {
            segmentId: "43",
            cabin: "BUSINESS",
            fareBasis: "KF2BX",
            brandedFare: "BX",
            brandedFareLabel: "BUSINESS RESTRICTED",
            class: "K",
            includedCheckedBags: {
              weight: 40,
              weightUnit: "KG",
            },
            amenities: [
              {
                description: "MEAL SERVICE",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "FAST TRACK IF AVAILABLE",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY CHECK IN",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BOARDING",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "STANDART SEAT SELECTION",
                isChargeable: false,
                amenityType: "BRANDED_FARES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "REFUNDABLE TICKET",
                isChargeable: true,
                amenityType: "BRANDED_FARES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "CHANGEABLE TICKET",
                isChargeable: true,
                amenityType: "BRANDED_FARES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
            ],
          },
        ],
      },
    ],
  },
  {
    type: "flight-offer",
    id: "37",
    source: "GDS",
    instantTicketingRequired: false,
    nonHomogeneous: false,
    oneWay: false,
    lastTicketingDate: "2024-05-06",
    lastTicketingDateTime: "2024-05-06",
    numberOfBookableSeats: 4,
    itineraries: [
      {
        duration: "PT11H55M",
        segments: [
          {
            departure: {
              iataCode: "LHE",
              at: "2024-05-06T05:45:00",
            },
            arrival: {
              iataCode: "IST",
              at: "2024-05-06T10:30:00",
            },
            carrierCode: "TK",
            number: "715",
            aircraft: {
              code: "333",
            },
            operating: {
              carrierCode: "TK",
            },
            duration: "PT6H45M",
            id: "25",
            numberOfStops: 0,
            blacklistedInEU: false,
          },
          {
            departure: {
              iataCode: "IST",
              at: "2024-05-06T11:45:00",
            },
            arrival: {
              iataCode: "JED",
              terminal: "1",
              at: "2024-05-06T15:40:00",
            },
            carrierCode: "TK",
            number: "92",
            aircraft: {
              code: "32Q",
            },
            operating: {
              carrierCode: "TK",
            },
            duration: "PT3H55M",
            id: "26",
            numberOfStops: 0,
            blacklistedInEU: false,
          },
        ],
      },
      {
        duration: "PT19H40M",
        segments: [
          {
            departure: {
              iataCode: "JED",
              terminal: "1",
              at: "2024-05-11T06:35:00",
            },
            arrival: {
              iataCode: "IST",
              at: "2024-05-11T10:30:00",
            },
            carrierCode: "TK",
            number: "97",
            aircraft: {
              code: "333",
            },
            operating: {
              carrierCode: "TK",
            },
            duration: "PT3H55M",
            id: "46",
            numberOfStops: 0,
            blacklistedInEU: false,
          },
          {
            departure: {
              iataCode: "IST",
              at: "2024-05-11T20:30:00",
            },
            arrival: {
              iataCode: "LHE",
              at: "2024-05-12T04:15:00",
            },
            carrierCode: "TK",
            number: "714",
            aircraft: {
              code: "333",
            },
            operating: {
              carrierCode: "TK",
            },
            duration: "PT5H45M",
            id: "47",
            numberOfStops: 0,
            blacklistedInEU: false,
          },
        ],
      },
    ],
    price: {
      currency: "USD",
      total: "3493.26",
      base: "2700.00",
      fees: [
        {
          amount: "0.00",
          type: "SUPPLIER",
        },
        {
          amount: "0.00",
          type: "TICKETING",
        },
      ],
      grandTotal: "3493.26",
    },
    pricingOptions: {
      fareType: ["PUBLISHED"],
      includedCheckedBagsOnly: true,
    },
    validatingAirlineCodes: ["TK"],
    travelerPricings: [
      {
        travelerId: "1",
        fareOption: "STANDARD",
        travelerType: "ADULT",
        price: {
          currency: "USD",
          total: "3493.26",
          base: "2700.00",
        },
        fareDetailsBySegment: [
          {
            segmentId: "25",
            cabin: "BUSINESS",
            fareBasis: "KF2BX",
            brandedFare: "BX",
            brandedFareLabel: "BUSINESS RESTRICTED",
            class: "K",
            includedCheckedBags: {
              weight: 40,
              weightUnit: "KG",
            },
            amenities: [
              {
                description: "MEAL SERVICE",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "FAST TRACK IF AVAILABLE",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY CHECK IN",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BOARDING",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "STANDART SEAT SELECTION",
                isChargeable: false,
                amenityType: "BRANDED_FARES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "REFUNDABLE TICKET",
                isChargeable: true,
                amenityType: "BRANDED_FARES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "CHANGEABLE TICKET",
                isChargeable: true,
                amenityType: "BRANDED_FARES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
            ],
          },
          {
            segmentId: "26",
            cabin: "BUSINESS",
            fareBasis: "KF2BX",
            brandedFare: "BX",
            brandedFareLabel: "BUSINESS RESTRICTED",
            class: "K",
            includedCheckedBags: {
              weight: 40,
              weightUnit: "KG",
            },
            amenities: [
              {
                description: "MEAL SERVICE",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "FAST TRACK IF AVAILABLE",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY CHECK IN",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BOARDING",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "STANDART SEAT SELECTION",
                isChargeable: false,
                amenityType: "BRANDED_FARES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "REFUNDABLE TICKET",
                isChargeable: true,
                amenityType: "BRANDED_FARES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "CHANGEABLE TICKET",
                isChargeable: true,
                amenityType: "BRANDED_FARES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
            ],
          },
          {
            segmentId: "46",
            cabin: "BUSINESS",
            fareBasis: "KF2BX",
            brandedFare: "BX",
            brandedFareLabel: "BUSINESS RESTRICTED",
            class: "K",
            includedCheckedBags: {
              weight: 40,
              weightUnit: "KG",
            },
            amenities: [
              {
                description: "MEAL SERVICE",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "FAST TRACK IF AVAILABLE",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY CHECK IN",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BOARDING",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "STANDART SEAT SELECTION",
                isChargeable: false,
                amenityType: "BRANDED_FARES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "REFUNDABLE TICKET",
                isChargeable: true,
                amenityType: "BRANDED_FARES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "CHANGEABLE TICKET",
                isChargeable: true,
                amenityType: "BRANDED_FARES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
            ],
          },
          {
            segmentId: "47",
            cabin: "BUSINESS",
            fareBasis: "KF2BX",
            brandedFare: "BX",
            brandedFareLabel: "BUSINESS RESTRICTED",
            class: "K",
            includedCheckedBags: {
              weight: 40,
              weightUnit: "KG",
            },
            amenities: [
              {
                description: "MEAL SERVICE",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "FAST TRACK IF AVAILABLE",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY CHECK IN",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BOARDING",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "STANDART SEAT SELECTION",
                isChargeable: false,
                amenityType: "BRANDED_FARES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "REFUNDABLE TICKET",
                isChargeable: true,
                amenityType: "BRANDED_FARES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "CHANGEABLE TICKET",
                isChargeable: true,
                amenityType: "BRANDED_FARES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
            ],
          },
        ],
      },
    ],
  },
  {
    type: "flight-offer",
    id: "38",
    source: "GDS",
    instantTicketingRequired: false,
    nonHomogeneous: false,
    oneWay: false,
    lastTicketingDate: "2024-05-06",
    lastTicketingDateTime: "2024-05-06",
    numberOfBookableSeats: 4,
    itineraries: [
      {
        duration: "PT20H25M",
        segments: [
          {
            departure: {
              iataCode: "LHE",
              at: "2024-05-06T05:45:00",
            },
            arrival: {
              iataCode: "IST",
              at: "2024-05-06T10:30:00",
            },
            carrierCode: "TK",
            number: "715",
            aircraft: {
              code: "333",
            },
            operating: {
              carrierCode: "TK",
            },
            duration: "PT6H45M",
            id: "15",
            numberOfStops: 0,
            blacklistedInEU: false,
          },
          {
            departure: {
              iataCode: "IST",
              at: "2024-05-06T20:15:00",
            },
            arrival: {
              iataCode: "JED",
              terminal: "1",
              at: "2024-05-07T00:10:00",
            },
            carrierCode: "TK",
            number: "94",
            aircraft: {
              code: "332",
            },
            operating: {
              carrierCode: "TK",
            },
            duration: "PT3H55M",
            id: "16",
            numberOfStops: 0,
            blacklistedInEU: false,
          },
        ],
      },
      {
        duration: "PT13H35M",
        segments: [
          {
            departure: {
              iataCode: "JED",
              terminal: "1",
              at: "2024-05-11T12:40:00",
            },
            arrival: {
              iataCode: "IST",
              at: "2024-05-11T16:35:00",
            },
            carrierCode: "TK",
            number: "127",
            aircraft: {
              code: "32B",
            },
            operating: {
              carrierCode: "TK",
            },
            duration: "PT3H55M",
            id: "42",
            numberOfStops: 0,
            blacklistedInEU: false,
          },
          {
            departure: {
              iataCode: "IST",
              at: "2024-05-11T20:30:00",
            },
            arrival: {
              iataCode: "LHE",
              at: "2024-05-12T04:15:00",
            },
            carrierCode: "TK",
            number: "714",
            aircraft: {
              code: "333",
            },
            operating: {
              carrierCode: "TK",
            },
            duration: "PT5H45M",
            id: "43",
            numberOfStops: 0,
            blacklistedInEU: false,
          },
        ],
      },
    ],
    price: {
      currency: "USD",
      total: "3493.26",
      base: "2700.00",
      fees: [
        {
          amount: "0.00",
          type: "SUPPLIER",
        },
        {
          amount: "0.00",
          type: "TICKETING",
        },
      ],
      grandTotal: "3493.26",
    },
    pricingOptions: {
      fareType: ["PUBLISHED"],
      includedCheckedBagsOnly: true,
    },
    validatingAirlineCodes: ["TK"],
    travelerPricings: [
      {
        travelerId: "1",
        fareOption: "STANDARD",
        travelerType: "ADULT",
        price: {
          currency: "USD",
          total: "3493.26",
          base: "2700.00",
        },
        fareDetailsBySegment: [
          {
            segmentId: "15",
            cabin: "BUSINESS",
            fareBasis: "KF2BX",
            brandedFare: "BX",
            brandedFareLabel: "BUSINESS RESTRICTED",
            class: "K",
            includedCheckedBags: {
              weight: 40,
              weightUnit: "KG",
            },
            amenities: [
              {
                description: "MEAL SERVICE",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "FAST TRACK IF AVAILABLE",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY CHECK IN",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BOARDING",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "STANDART SEAT SELECTION",
                isChargeable: false,
                amenityType: "BRANDED_FARES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "REFUNDABLE TICKET",
                isChargeable: true,
                amenityType: "BRANDED_FARES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "CHANGEABLE TICKET",
                isChargeable: true,
                amenityType: "BRANDED_FARES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
            ],
          },
          {
            segmentId: "16",
            cabin: "BUSINESS",
            fareBasis: "KF2BX",
            brandedFare: "BX",
            brandedFareLabel: "BUSINESS RESTRICTED",
            class: "K",
            includedCheckedBags: {
              weight: 40,
              weightUnit: "KG",
            },
            amenities: [
              {
                description: "MEAL SERVICE",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "FAST TRACK IF AVAILABLE",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY CHECK IN",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BOARDING",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "STANDART SEAT SELECTION",
                isChargeable: false,
                amenityType: "BRANDED_FARES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "REFUNDABLE TICKET",
                isChargeable: true,
                amenityType: "BRANDED_FARES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "CHANGEABLE TICKET",
                isChargeable: true,
                amenityType: "BRANDED_FARES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
            ],
          },
          {
            segmentId: "42",
            cabin: "BUSINESS",
            fareBasis: "KF2BX",
            brandedFare: "BX",
            brandedFareLabel: "BUSINESS RESTRICTED",
            class: "K",
            includedCheckedBags: {
              weight: 40,
              weightUnit: "KG",
            },
            amenities: [
              {
                description: "MEAL SERVICE",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "FAST TRACK IF AVAILABLE",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY CHECK IN",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BOARDING",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "STANDART SEAT SELECTION",
                isChargeable: false,
                amenityType: "BRANDED_FARES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "REFUNDABLE TICKET",
                isChargeable: true,
                amenityType: "BRANDED_FARES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "CHANGEABLE TICKET",
                isChargeable: true,
                amenityType: "BRANDED_FARES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
            ],
          },
          {
            segmentId: "43",
            cabin: "BUSINESS",
            fareBasis: "KF2BX",
            brandedFare: "BX",
            brandedFareLabel: "BUSINESS RESTRICTED",
            class: "K",
            includedCheckedBags: {
              weight: 40,
              weightUnit: "KG",
            },
            amenities: [
              {
                description: "MEAL SERVICE",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "FAST TRACK IF AVAILABLE",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY CHECK IN",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BOARDING",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "STANDART SEAT SELECTION",
                isChargeable: false,
                amenityType: "BRANDED_FARES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "REFUNDABLE TICKET",
                isChargeable: true,
                amenityType: "BRANDED_FARES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "CHANGEABLE TICKET",
                isChargeable: true,
                amenityType: "BRANDED_FARES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
            ],
          },
        ],
      },
    ],
  },
  {
    type: "flight-offer",
    id: "39",
    source: "GDS",
    instantTicketingRequired: false,
    nonHomogeneous: false,
    oneWay: false,
    lastTicketingDate: "2024-05-06",
    lastTicketingDateTime: "2024-05-06",
    numberOfBookableSeats: 4,
    itineraries: [
      {
        duration: "PT11H55M",
        segments: [
          {
            departure: {
              iataCode: "LHE",
              at: "2024-05-06T05:45:00",
            },
            arrival: {
              iataCode: "IST",
              at: "2024-05-06T10:30:00",
            },
            carrierCode: "TK",
            number: "715",
            aircraft: {
              code: "333",
            },
            operating: {
              carrierCode: "TK",
            },
            duration: "PT6H45M",
            id: "25",
            numberOfStops: 0,
            blacklistedInEU: false,
          },
          {
            departure: {
              iataCode: "IST",
              at: "2024-05-06T11:45:00",
            },
            arrival: {
              iataCode: "JED",
              terminal: "1",
              at: "2024-05-06T15:40:00",
            },
            carrierCode: "TK",
            number: "92",
            aircraft: {
              code: "32Q",
            },
            operating: {
              carrierCode: "TK",
            },
            duration: "PT3H55M",
            id: "26",
            numberOfStops: 0,
            blacklistedInEU: false,
          },
        ],
      },
      {
        duration: "PT24H40M",
        segments: [
          {
            departure: {
              iataCode: "JED",
              terminal: "1",
              at: "2024-05-11T01:35:00",
            },
            arrival: {
              iataCode: "IST",
              at: "2024-05-11T05:25:00",
            },
            carrierCode: "TK",
            number: "95",
            aircraft: {
              code: "332",
            },
            operating: {
              carrierCode: "TK",
            },
            duration: "PT3H50M",
            id: "48",
            numberOfStops: 0,
            blacklistedInEU: false,
          },
          {
            departure: {
              iataCode: "IST",
              at: "2024-05-11T20:30:00",
            },
            arrival: {
              iataCode: "LHE",
              at: "2024-05-12T04:15:00",
            },
            carrierCode: "TK",
            number: "714",
            aircraft: {
              code: "333",
            },
            operating: {
              carrierCode: "TK",
            },
            duration: "PT5H45M",
            id: "49",
            numberOfStops: 0,
            blacklistedInEU: false,
          },
        ],
      },
    ],
    price: {
      currency: "USD",
      total: "3493.26",
      base: "2700.00",
      fees: [
        {
          amount: "0.00",
          type: "SUPPLIER",
        },
        {
          amount: "0.00",
          type: "TICKETING",
        },
      ],
      grandTotal: "3493.26",
    },
    pricingOptions: {
      fareType: ["PUBLISHED"],
      includedCheckedBagsOnly: true,
    },
    validatingAirlineCodes: ["TK"],
    travelerPricings: [
      {
        travelerId: "1",
        fareOption: "STANDARD",
        travelerType: "ADULT",
        price: {
          currency: "USD",
          total: "3493.26",
          base: "2700.00",
        },
        fareDetailsBySegment: [
          {
            segmentId: "25",
            cabin: "BUSINESS",
            fareBasis: "KF2BX",
            brandedFare: "BX",
            brandedFareLabel: "BUSINESS RESTRICTED",
            class: "K",
            includedCheckedBags: {
              weight: 40,
              weightUnit: "KG",
            },
            amenities: [
              {
                description: "MEAL SERVICE",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "FAST TRACK IF AVAILABLE",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY CHECK IN",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BOARDING",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "STANDART SEAT SELECTION",
                isChargeable: false,
                amenityType: "BRANDED_FARES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "REFUNDABLE TICKET",
                isChargeable: true,
                amenityType: "BRANDED_FARES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "CHANGEABLE TICKET",
                isChargeable: true,
                amenityType: "BRANDED_FARES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
            ],
          },
          {
            segmentId: "26",
            cabin: "BUSINESS",
            fareBasis: "KF2BX",
            brandedFare: "BX",
            brandedFareLabel: "BUSINESS RESTRICTED",
            class: "K",
            includedCheckedBags: {
              weight: 40,
              weightUnit: "KG",
            },
            amenities: [
              {
                description: "MEAL SERVICE",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "FAST TRACK IF AVAILABLE",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY CHECK IN",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BOARDING",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "STANDART SEAT SELECTION",
                isChargeable: false,
                amenityType: "BRANDED_FARES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "REFUNDABLE TICKET",
                isChargeable: true,
                amenityType: "BRANDED_FARES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "CHANGEABLE TICKET",
                isChargeable: true,
                amenityType: "BRANDED_FARES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
            ],
          },
          {
            segmentId: "48",
            cabin: "BUSINESS",
            fareBasis: "KF2BX",
            brandedFare: "BX",
            brandedFareLabel: "BUSINESS RESTRICTED",
            class: "K",
            includedCheckedBags: {
              weight: 40,
              weightUnit: "KG",
            },
            amenities: [
              {
                description: "MEAL SERVICE",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "FAST TRACK IF AVAILABLE",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY CHECK IN",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BOARDING",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "STANDART SEAT SELECTION",
                isChargeable: false,
                amenityType: "BRANDED_FARES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "REFUNDABLE TICKET",
                isChargeable: true,
                amenityType: "BRANDED_FARES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "CHANGEABLE TICKET",
                isChargeable: true,
                amenityType: "BRANDED_FARES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
            ],
          },
          {
            segmentId: "49",
            cabin: "BUSINESS",
            fareBasis: "KF2BX",
            brandedFare: "BX",
            brandedFareLabel: "BUSINESS RESTRICTED",
            class: "K",
            includedCheckedBags: {
              weight: 40,
              weightUnit: "KG",
            },
            amenities: [
              {
                description: "MEAL SERVICE",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "FAST TRACK IF AVAILABLE",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY CHECK IN",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BOARDING",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "STANDART SEAT SELECTION",
                isChargeable: false,
                amenityType: "BRANDED_FARES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "REFUNDABLE TICKET",
                isChargeable: true,
                amenityType: "BRANDED_FARES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "CHANGEABLE TICKET",
                isChargeable: true,
                amenityType: "BRANDED_FARES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
            ],
          },
        ],
      },
    ],
  },
  {
    type: "flight-offer",
    id: "40",
    source: "GDS",
    instantTicketingRequired: false,
    nonHomogeneous: false,
    oneWay: false,
    lastTicketingDate: "2024-05-06",
    lastTicketingDateTime: "2024-05-06",
    numberOfBookableSeats: 4,
    itineraries: [
      {
        duration: "PT25H30M",
        segments: [
          {
            departure: {
              iataCode: "LHE",
              at: "2024-05-06T05:45:00",
            },
            arrival: {
              iataCode: "IST",
              at: "2024-05-06T10:30:00",
            },
            carrierCode: "TK",
            number: "715",
            aircraft: {
              code: "333",
            },
            operating: {
              carrierCode: "TK",
            },
            duration: "PT6H45M",
            id: "19",
            numberOfStops: 0,
            blacklistedInEU: false,
          },
          {
            departure: {
              iataCode: "IST",
              at: "2024-05-07T01:20:00",
            },
            arrival: {
              iataCode: "JED",
              terminal: "1",
              at: "2024-05-07T05:15:00",
            },
            carrierCode: "TK",
            number: "96",
            aircraft: {
              code: "32Q",
            },
            operating: {
              carrierCode: "TK",
            },
            duration: "PT3H55M",
            id: "20",
            numberOfStops: 0,
            blacklistedInEU: false,
          },
        ],
      },
      {
        duration: "PT13H35M",
        segments: [
          {
            departure: {
              iataCode: "JED",
              terminal: "1",
              at: "2024-05-11T12:40:00",
            },
            arrival: {
              iataCode: "IST",
              at: "2024-05-11T16:35:00",
            },
            carrierCode: "TK",
            number: "127",
            aircraft: {
              code: "32B",
            },
            operating: {
              carrierCode: "TK",
            },
            duration: "PT3H55M",
            id: "42",
            numberOfStops: 0,
            blacklistedInEU: false,
          },
          {
            departure: {
              iataCode: "IST",
              at: "2024-05-11T20:30:00",
            },
            arrival: {
              iataCode: "LHE",
              at: "2024-05-12T04:15:00",
            },
            carrierCode: "TK",
            number: "714",
            aircraft: {
              code: "333",
            },
            operating: {
              carrierCode: "TK",
            },
            duration: "PT5H45M",
            id: "43",
            numberOfStops: 0,
            blacklistedInEU: false,
          },
        ],
      },
    ],
    price: {
      currency: "USD",
      total: "3493.26",
      base: "2700.00",
      fees: [
        {
          amount: "0.00",
          type: "SUPPLIER",
        },
        {
          amount: "0.00",
          type: "TICKETING",
        },
      ],
      grandTotal: "3493.26",
    },
    pricingOptions: {
      fareType: ["PUBLISHED"],
      includedCheckedBagsOnly: true,
    },
    validatingAirlineCodes: ["TK"],
    travelerPricings: [
      {
        travelerId: "1",
        fareOption: "STANDARD",
        travelerType: "ADULT",
        price: {
          currency: "USD",
          total: "3493.26",
          base: "2700.00",
        },
        fareDetailsBySegment: [
          {
            segmentId: "19",
            cabin: "BUSINESS",
            fareBasis: "KF2BX",
            brandedFare: "BX",
            brandedFareLabel: "BUSINESS RESTRICTED",
            class: "K",
            includedCheckedBags: {
              weight: 40,
              weightUnit: "KG",
            },
            amenities: [
              {
                description: "MEAL SERVICE",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "FAST TRACK IF AVAILABLE",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY CHECK IN",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BOARDING",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "STANDART SEAT SELECTION",
                isChargeable: false,
                amenityType: "BRANDED_FARES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "REFUNDABLE TICKET",
                isChargeable: true,
                amenityType: "BRANDED_FARES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "CHANGEABLE TICKET",
                isChargeable: true,
                amenityType: "BRANDED_FARES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
            ],
          },
          {
            segmentId: "20",
            cabin: "BUSINESS",
            fareBasis: "KF2BX",
            brandedFare: "BX",
            brandedFareLabel: "BUSINESS RESTRICTED",
            class: "K",
            includedCheckedBags: {
              weight: 40,
              weightUnit: "KG",
            },
            amenities: [
              {
                description: "MEAL SERVICE",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "FAST TRACK IF AVAILABLE",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY CHECK IN",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BOARDING",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "STANDART SEAT SELECTION",
                isChargeable: false,
                amenityType: "BRANDED_FARES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "REFUNDABLE TICKET",
                isChargeable: true,
                amenityType: "BRANDED_FARES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "CHANGEABLE TICKET",
                isChargeable: true,
                amenityType: "BRANDED_FARES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
            ],
          },
          {
            segmentId: "42",
            cabin: "BUSINESS",
            fareBasis: "KF2BX",
            brandedFare: "BX",
            brandedFareLabel: "BUSINESS RESTRICTED",
            class: "K",
            includedCheckedBags: {
              weight: 40,
              weightUnit: "KG",
            },
            amenities: [
              {
                description: "MEAL SERVICE",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "FAST TRACK IF AVAILABLE",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY CHECK IN",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BOARDING",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "STANDART SEAT SELECTION",
                isChargeable: false,
                amenityType: "BRANDED_FARES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "REFUNDABLE TICKET",
                isChargeable: true,
                amenityType: "BRANDED_FARES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "CHANGEABLE TICKET",
                isChargeable: true,
                amenityType: "BRANDED_FARES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
            ],
          },
          {
            segmentId: "43",
            cabin: "BUSINESS",
            fareBasis: "KF2BX",
            brandedFare: "BX",
            brandedFareLabel: "BUSINESS RESTRICTED",
            class: "K",
            includedCheckedBags: {
              weight: 40,
              weightUnit: "KG",
            },
            amenities: [
              {
                description: "MEAL SERVICE",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "FAST TRACK IF AVAILABLE",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY CHECK IN",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BOARDING",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "STANDART SEAT SELECTION",
                isChargeable: false,
                amenityType: "BRANDED_FARES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "REFUNDABLE TICKET",
                isChargeable: true,
                amenityType: "BRANDED_FARES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "CHANGEABLE TICKET",
                isChargeable: true,
                amenityType: "BRANDED_FARES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
            ],
          },
        ],
      },
    ],
  },
  {
    type: "flight-offer",
    id: "41",
    source: "GDS",
    instantTicketingRequired: false,
    nonHomogeneous: false,
    oneWay: false,
    lastTicketingDate: "2024-05-06",
    lastTicketingDateTime: "2024-05-06",
    numberOfBookableSeats: 4,
    itineraries: [
      {
        duration: "PT20H25M",
        segments: [
          {
            departure: {
              iataCode: "LHE",
              at: "2024-05-06T05:45:00",
            },
            arrival: {
              iataCode: "IST",
              at: "2024-05-06T10:30:00",
            },
            carrierCode: "TK",
            number: "715",
            aircraft: {
              code: "333",
            },
            operating: {
              carrierCode: "TK",
            },
            duration: "PT6H45M",
            id: "15",
            numberOfStops: 0,
            blacklistedInEU: false,
          },
          {
            departure: {
              iataCode: "IST",
              at: "2024-05-06T20:15:00",
            },
            arrival: {
              iataCode: "JED",
              terminal: "1",
              at: "2024-05-07T00:10:00",
            },
            carrierCode: "TK",
            number: "94",
            aircraft: {
              code: "332",
            },
            operating: {
              carrierCode: "TK",
            },
            duration: "PT3H55M",
            id: "16",
            numberOfStops: 0,
            blacklistedInEU: false,
          },
        ],
      },
      {
        duration: "PT19H40M",
        segments: [
          {
            departure: {
              iataCode: "JED",
              terminal: "1",
              at: "2024-05-11T06:35:00",
            },
            arrival: {
              iataCode: "IST",
              at: "2024-05-11T10:30:00",
            },
            carrierCode: "TK",
            number: "97",
            aircraft: {
              code: "333",
            },
            operating: {
              carrierCode: "TK",
            },
            duration: "PT3H55M",
            id: "46",
            numberOfStops: 0,
            blacklistedInEU: false,
          },
          {
            departure: {
              iataCode: "IST",
              at: "2024-05-11T20:30:00",
            },
            arrival: {
              iataCode: "LHE",
              at: "2024-05-12T04:15:00",
            },
            carrierCode: "TK",
            number: "714",
            aircraft: {
              code: "333",
            },
            operating: {
              carrierCode: "TK",
            },
            duration: "PT5H45M",
            id: "47",
            numberOfStops: 0,
            blacklistedInEU: false,
          },
        ],
      },
    ],
    price: {
      currency: "USD",
      total: "3493.26",
      base: "2700.00",
      fees: [
        {
          amount: "0.00",
          type: "SUPPLIER",
        },
        {
          amount: "0.00",
          type: "TICKETING",
        },
      ],
      grandTotal: "3493.26",
    },
    pricingOptions: {
      fareType: ["PUBLISHED"],
      includedCheckedBagsOnly: true,
    },
    validatingAirlineCodes: ["TK"],
    travelerPricings: [
      {
        travelerId: "1",
        fareOption: "STANDARD",
        travelerType: "ADULT",
        price: {
          currency: "USD",
          total: "3493.26",
          base: "2700.00",
        },
        fareDetailsBySegment: [
          {
            segmentId: "15",
            cabin: "BUSINESS",
            fareBasis: "KF2BX",
            brandedFare: "BX",
            brandedFareLabel: "BUSINESS RESTRICTED",
            class: "K",
            includedCheckedBags: {
              weight: 40,
              weightUnit: "KG",
            },
            amenities: [
              {
                description: "MEAL SERVICE",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "FAST TRACK IF AVAILABLE",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY CHECK IN",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BOARDING",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "STANDART SEAT SELECTION",
                isChargeable: false,
                amenityType: "BRANDED_FARES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "REFUNDABLE TICKET",
                isChargeable: true,
                amenityType: "BRANDED_FARES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "CHANGEABLE TICKET",
                isChargeable: true,
                amenityType: "BRANDED_FARES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
            ],
          },
          {
            segmentId: "16",
            cabin: "BUSINESS",
            fareBasis: "KF2BX",
            brandedFare: "BX",
            brandedFareLabel: "BUSINESS RESTRICTED",
            class: "K",
            includedCheckedBags: {
              weight: 40,
              weightUnit: "KG",
            },
            amenities: [
              {
                description: "MEAL SERVICE",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "FAST TRACK IF AVAILABLE",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY CHECK IN",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BOARDING",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "STANDART SEAT SELECTION",
                isChargeable: false,
                amenityType: "BRANDED_FARES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "REFUNDABLE TICKET",
                isChargeable: true,
                amenityType: "BRANDED_FARES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "CHANGEABLE TICKET",
                isChargeable: true,
                amenityType: "BRANDED_FARES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
            ],
          },
          {
            segmentId: "46",
            cabin: "BUSINESS",
            fareBasis: "KF2BX",
            brandedFare: "BX",
            brandedFareLabel: "BUSINESS RESTRICTED",
            class: "K",
            includedCheckedBags: {
              weight: 40,
              weightUnit: "KG",
            },
            amenities: [
              {
                description: "MEAL SERVICE",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "FAST TRACK IF AVAILABLE",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY CHECK IN",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BOARDING",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "STANDART SEAT SELECTION",
                isChargeable: false,
                amenityType: "BRANDED_FARES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "REFUNDABLE TICKET",
                isChargeable: true,
                amenityType: "BRANDED_FARES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "CHANGEABLE TICKET",
                isChargeable: true,
                amenityType: "BRANDED_FARES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
            ],
          },
          {
            segmentId: "47",
            cabin: "BUSINESS",
            fareBasis: "KF2BX",
            brandedFare: "BX",
            brandedFareLabel: "BUSINESS RESTRICTED",
            class: "K",
            includedCheckedBags: {
              weight: 40,
              weightUnit: "KG",
            },
            amenities: [
              {
                description: "MEAL SERVICE",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "FAST TRACK IF AVAILABLE",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY CHECK IN",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BOARDING",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "STANDART SEAT SELECTION",
                isChargeable: false,
                amenityType: "BRANDED_FARES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "REFUNDABLE TICKET",
                isChargeable: true,
                amenityType: "BRANDED_FARES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "CHANGEABLE TICKET",
                isChargeable: true,
                amenityType: "BRANDED_FARES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
            ],
          },
        ],
      },
    ],
  },
  {
    type: "flight-offer",
    id: "42",
    source: "GDS",
    instantTicketingRequired: false,
    nonHomogeneous: false,
    oneWay: false,
    lastTicketingDate: "2024-05-06",
    lastTicketingDateTime: "2024-05-06",
    numberOfBookableSeats: 4,
    itineraries: [
      {
        duration: "PT20H25M",
        segments: [
          {
            departure: {
              iataCode: "LHE",
              at: "2024-05-06T05:45:00",
            },
            arrival: {
              iataCode: "IST",
              at: "2024-05-06T10:30:00",
            },
            carrierCode: "TK",
            number: "715",
            aircraft: {
              code: "333",
            },
            operating: {
              carrierCode: "TK",
            },
            duration: "PT6H45M",
            id: "15",
            numberOfStops: 0,
            blacklistedInEU: false,
          },
          {
            departure: {
              iataCode: "IST",
              at: "2024-05-06T20:15:00",
            },
            arrival: {
              iataCode: "JED",
              terminal: "1",
              at: "2024-05-07T00:10:00",
            },
            carrierCode: "TK",
            number: "94",
            aircraft: {
              code: "332",
            },
            operating: {
              carrierCode: "TK",
            },
            duration: "PT3H55M",
            id: "16",
            numberOfStops: 0,
            blacklistedInEU: false,
          },
        ],
      },
      {
        duration: "PT24H40M",
        segments: [
          {
            departure: {
              iataCode: "JED",
              terminal: "1",
              at: "2024-05-11T01:35:00",
            },
            arrival: {
              iataCode: "IST",
              at: "2024-05-11T05:25:00",
            },
            carrierCode: "TK",
            number: "95",
            aircraft: {
              code: "332",
            },
            operating: {
              carrierCode: "TK",
            },
            duration: "PT3H50M",
            id: "48",
            numberOfStops: 0,
            blacklistedInEU: false,
          },
          {
            departure: {
              iataCode: "IST",
              at: "2024-05-11T20:30:00",
            },
            arrival: {
              iataCode: "LHE",
              at: "2024-05-12T04:15:00",
            },
            carrierCode: "TK",
            number: "714",
            aircraft: {
              code: "333",
            },
            operating: {
              carrierCode: "TK",
            },
            duration: "PT5H45M",
            id: "49",
            numberOfStops: 0,
            blacklistedInEU: false,
          },
        ],
      },
    ],
    price: {
      currency: "USD",
      total: "3493.26",
      base: "2700.00",
      fees: [
        {
          amount: "0.00",
          type: "SUPPLIER",
        },
        {
          amount: "0.00",
          type: "TICKETING",
        },
      ],
      grandTotal: "3493.26",
    },
    pricingOptions: {
      fareType: ["PUBLISHED"],
      includedCheckedBagsOnly: true,
    },
    validatingAirlineCodes: ["TK"],
    travelerPricings: [
      {
        travelerId: "1",
        fareOption: "STANDARD",
        travelerType: "ADULT",
        price: {
          currency: "USD",
          total: "3493.26",
          base: "2700.00",
        },
        fareDetailsBySegment: [
          {
            segmentId: "15",
            cabin: "BUSINESS",
            fareBasis: "KF2BX",
            brandedFare: "BX",
            brandedFareLabel: "BUSINESS RESTRICTED",
            class: "K",
            includedCheckedBags: {
              weight: 40,
              weightUnit: "KG",
            },
            amenities: [
              {
                description: "MEAL SERVICE",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "FAST TRACK IF AVAILABLE",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY CHECK IN",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BOARDING",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "STANDART SEAT SELECTION",
                isChargeable: false,
                amenityType: "BRANDED_FARES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "REFUNDABLE TICKET",
                isChargeable: true,
                amenityType: "BRANDED_FARES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "CHANGEABLE TICKET",
                isChargeable: true,
                amenityType: "BRANDED_FARES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
            ],
          },
          {
            segmentId: "16",
            cabin: "BUSINESS",
            fareBasis: "KF2BX",
            brandedFare: "BX",
            brandedFareLabel: "BUSINESS RESTRICTED",
            class: "K",
            includedCheckedBags: {
              weight: 40,
              weightUnit: "KG",
            },
            amenities: [
              {
                description: "MEAL SERVICE",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "FAST TRACK IF AVAILABLE",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY CHECK IN",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BOARDING",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "STANDART SEAT SELECTION",
                isChargeable: false,
                amenityType: "BRANDED_FARES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "REFUNDABLE TICKET",
                isChargeable: true,
                amenityType: "BRANDED_FARES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "CHANGEABLE TICKET",
                isChargeable: true,
                amenityType: "BRANDED_FARES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
            ],
          },
          {
            segmentId: "48",
            cabin: "BUSINESS",
            fareBasis: "KF2BX",
            brandedFare: "BX",
            brandedFareLabel: "BUSINESS RESTRICTED",
            class: "K",
            includedCheckedBags: {
              weight: 40,
              weightUnit: "KG",
            },
            amenities: [
              {
                description: "MEAL SERVICE",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "FAST TRACK IF AVAILABLE",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY CHECK IN",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BOARDING",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "STANDART SEAT SELECTION",
                isChargeable: false,
                amenityType: "BRANDED_FARES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "REFUNDABLE TICKET",
                isChargeable: true,
                amenityType: "BRANDED_FARES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "CHANGEABLE TICKET",
                isChargeable: true,
                amenityType: "BRANDED_FARES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
            ],
          },
          {
            segmentId: "49",
            cabin: "BUSINESS",
            fareBasis: "KF2BX",
            brandedFare: "BX",
            brandedFareLabel: "BUSINESS RESTRICTED",
            class: "K",
            includedCheckedBags: {
              weight: 40,
              weightUnit: "KG",
            },
            amenities: [
              {
                description: "MEAL SERVICE",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "FAST TRACK IF AVAILABLE",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY CHECK IN",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BOARDING",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "STANDART SEAT SELECTION",
                isChargeable: false,
                amenityType: "BRANDED_FARES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "REFUNDABLE TICKET",
                isChargeable: true,
                amenityType: "BRANDED_FARES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "CHANGEABLE TICKET",
                isChargeable: true,
                amenityType: "BRANDED_FARES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
            ],
          },
        ],
      },
    ],
  },
  {
    type: "flight-offer",
    id: "43",
    source: "GDS",
    instantTicketingRequired: false,
    nonHomogeneous: false,
    oneWay: false,
    lastTicketingDate: "2024-05-06",
    lastTicketingDateTime: "2024-05-06",
    numberOfBookableSeats: 4,
    itineraries: [
      {
        duration: "PT25H30M",
        segments: [
          {
            departure: {
              iataCode: "LHE",
              at: "2024-05-06T05:45:00",
            },
            arrival: {
              iataCode: "IST",
              at: "2024-05-06T10:30:00",
            },
            carrierCode: "TK",
            number: "715",
            aircraft: {
              code: "333",
            },
            operating: {
              carrierCode: "TK",
            },
            duration: "PT6H45M",
            id: "19",
            numberOfStops: 0,
            blacklistedInEU: false,
          },
          {
            departure: {
              iataCode: "IST",
              at: "2024-05-07T01:20:00",
            },
            arrival: {
              iataCode: "JED",
              terminal: "1",
              at: "2024-05-07T05:15:00",
            },
            carrierCode: "TK",
            number: "96",
            aircraft: {
              code: "32Q",
            },
            operating: {
              carrierCode: "TK",
            },
            duration: "PT3H55M",
            id: "20",
            numberOfStops: 0,
            blacklistedInEU: false,
          },
        ],
      },
      {
        duration: "PT19H40M",
        segments: [
          {
            departure: {
              iataCode: "JED",
              terminal: "1",
              at: "2024-05-11T06:35:00",
            },
            arrival: {
              iataCode: "IST",
              at: "2024-05-11T10:30:00",
            },
            carrierCode: "TK",
            number: "97",
            aircraft: {
              code: "333",
            },
            operating: {
              carrierCode: "TK",
            },
            duration: "PT3H55M",
            id: "46",
            numberOfStops: 0,
            blacklistedInEU: false,
          },
          {
            departure: {
              iataCode: "IST",
              at: "2024-05-11T20:30:00",
            },
            arrival: {
              iataCode: "LHE",
              at: "2024-05-12T04:15:00",
            },
            carrierCode: "TK",
            number: "714",
            aircraft: {
              code: "333",
            },
            operating: {
              carrierCode: "TK",
            },
            duration: "PT5H45M",
            id: "47",
            numberOfStops: 0,
            blacklistedInEU: false,
          },
        ],
      },
    ],
    price: {
      currency: "USD",
      total: "3493.26",
      base: "2700.00",
      fees: [
        {
          amount: "0.00",
          type: "SUPPLIER",
        },
        {
          amount: "0.00",
          type: "TICKETING",
        },
      ],
      grandTotal: "3493.26",
    },
    pricingOptions: {
      fareType: ["PUBLISHED"],
      includedCheckedBagsOnly: true,
    },
    validatingAirlineCodes: ["TK"],
    travelerPricings: [
      {
        travelerId: "1",
        fareOption: "STANDARD",
        travelerType: "ADULT",
        price: {
          currency: "USD",
          total: "3493.26",
          base: "2700.00",
        },
        fareDetailsBySegment: [
          {
            segmentId: "19",
            cabin: "BUSINESS",
            fareBasis: "KF2BX",
            brandedFare: "BX",
            brandedFareLabel: "BUSINESS RESTRICTED",
            class: "K",
            includedCheckedBags: {
              weight: 40,
              weightUnit: "KG",
            },
            amenities: [
              {
                description: "MEAL SERVICE",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "FAST TRACK IF AVAILABLE",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY CHECK IN",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BOARDING",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "STANDART SEAT SELECTION",
                isChargeable: false,
                amenityType: "BRANDED_FARES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "REFUNDABLE TICKET",
                isChargeable: true,
                amenityType: "BRANDED_FARES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "CHANGEABLE TICKET",
                isChargeable: true,
                amenityType: "BRANDED_FARES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
            ],
          },
          {
            segmentId: "20",
            cabin: "BUSINESS",
            fareBasis: "KF2BX",
            brandedFare: "BX",
            brandedFareLabel: "BUSINESS RESTRICTED",
            class: "K",
            includedCheckedBags: {
              weight: 40,
              weightUnit: "KG",
            },
            amenities: [
              {
                description: "MEAL SERVICE",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "FAST TRACK IF AVAILABLE",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY CHECK IN",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BOARDING",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "STANDART SEAT SELECTION",
                isChargeable: false,
                amenityType: "BRANDED_FARES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "REFUNDABLE TICKET",
                isChargeable: true,
                amenityType: "BRANDED_FARES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "CHANGEABLE TICKET",
                isChargeable: true,
                amenityType: "BRANDED_FARES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
            ],
          },
          {
            segmentId: "46",
            cabin: "BUSINESS",
            fareBasis: "KF2BX",
            brandedFare: "BX",
            brandedFareLabel: "BUSINESS RESTRICTED",
            class: "K",
            includedCheckedBags: {
              weight: 40,
              weightUnit: "KG",
            },
            amenities: [
              {
                description: "MEAL SERVICE",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "FAST TRACK IF AVAILABLE",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY CHECK IN",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BOARDING",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "STANDART SEAT SELECTION",
                isChargeable: false,
                amenityType: "BRANDED_FARES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "REFUNDABLE TICKET",
                isChargeable: true,
                amenityType: "BRANDED_FARES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "CHANGEABLE TICKET",
                isChargeable: true,
                amenityType: "BRANDED_FARES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
            ],
          },
          {
            segmentId: "47",
            cabin: "BUSINESS",
            fareBasis: "KF2BX",
            brandedFare: "BX",
            brandedFareLabel: "BUSINESS RESTRICTED",
            class: "K",
            includedCheckedBags: {
              weight: 40,
              weightUnit: "KG",
            },
            amenities: [
              {
                description: "MEAL SERVICE",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "FAST TRACK IF AVAILABLE",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY CHECK IN",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BOARDING",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "STANDART SEAT SELECTION",
                isChargeable: false,
                amenityType: "BRANDED_FARES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "REFUNDABLE TICKET",
                isChargeable: true,
                amenityType: "BRANDED_FARES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "CHANGEABLE TICKET",
                isChargeable: true,
                amenityType: "BRANDED_FARES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
            ],
          },
        ],
      },
    ],
  },
  {
    type: "flight-offer",
    id: "44",
    source: "GDS",
    instantTicketingRequired: false,
    nonHomogeneous: false,
    oneWay: false,
    lastTicketingDate: "2024-05-06",
    lastTicketingDateTime: "2024-05-06",
    numberOfBookableSeats: 4,
    itineraries: [
      {
        duration: "PT25H30M",
        segments: [
          {
            departure: {
              iataCode: "LHE",
              at: "2024-05-06T05:45:00",
            },
            arrival: {
              iataCode: "IST",
              at: "2024-05-06T10:30:00",
            },
            carrierCode: "TK",
            number: "715",
            aircraft: {
              code: "333",
            },
            operating: {
              carrierCode: "TK",
            },
            duration: "PT6H45M",
            id: "19",
            numberOfStops: 0,
            blacklistedInEU: false,
          },
          {
            departure: {
              iataCode: "IST",
              at: "2024-05-07T01:20:00",
            },
            arrival: {
              iataCode: "JED",
              terminal: "1",
              at: "2024-05-07T05:15:00",
            },
            carrierCode: "TK",
            number: "96",
            aircraft: {
              code: "32Q",
            },
            operating: {
              carrierCode: "TK",
            },
            duration: "PT3H55M",
            id: "20",
            numberOfStops: 0,
            blacklistedInEU: false,
          },
        ],
      },
      {
        duration: "PT24H40M",
        segments: [
          {
            departure: {
              iataCode: "JED",
              terminal: "1",
              at: "2024-05-11T01:35:00",
            },
            arrival: {
              iataCode: "IST",
              at: "2024-05-11T05:25:00",
            },
            carrierCode: "TK",
            number: "95",
            aircraft: {
              code: "332",
            },
            operating: {
              carrierCode: "TK",
            },
            duration: "PT3H50M",
            id: "48",
            numberOfStops: 0,
            blacklistedInEU: false,
          },
          {
            departure: {
              iataCode: "IST",
              at: "2024-05-11T20:30:00",
            },
            arrival: {
              iataCode: "LHE",
              at: "2024-05-12T04:15:00",
            },
            carrierCode: "TK",
            number: "714",
            aircraft: {
              code: "333",
            },
            operating: {
              carrierCode: "TK",
            },
            duration: "PT5H45M",
            id: "49",
            numberOfStops: 0,
            blacklistedInEU: false,
          },
        ],
      },
    ],
    price: {
      currency: "USD",
      total: "3493.26",
      base: "2700.00",
      fees: [
        {
          amount: "0.00",
          type: "SUPPLIER",
        },
        {
          amount: "0.00",
          type: "TICKETING",
        },
      ],
      grandTotal: "3493.26",
    },
    pricingOptions: {
      fareType: ["PUBLISHED"],
      includedCheckedBagsOnly: true,
    },
    validatingAirlineCodes: ["TK"],
    travelerPricings: [
      {
        travelerId: "1",
        fareOption: "STANDARD",
        travelerType: "ADULT",
        price: {
          currency: "USD",
          total: "3493.26",
          base: "2700.00",
        },
        fareDetailsBySegment: [
          {
            segmentId: "19",
            cabin: "BUSINESS",
            fareBasis: "KF2BX",
            brandedFare: "BX",
            brandedFareLabel: "BUSINESS RESTRICTED",
            class: "K",
            includedCheckedBags: {
              weight: 40,
              weightUnit: "KG",
            },
            amenities: [
              {
                description: "MEAL SERVICE",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "FAST TRACK IF AVAILABLE",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY CHECK IN",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BOARDING",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "STANDART SEAT SELECTION",
                isChargeable: false,
                amenityType: "BRANDED_FARES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "REFUNDABLE TICKET",
                isChargeable: true,
                amenityType: "BRANDED_FARES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "CHANGEABLE TICKET",
                isChargeable: true,
                amenityType: "BRANDED_FARES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
            ],
          },
          {
            segmentId: "20",
            cabin: "BUSINESS",
            fareBasis: "KF2BX",
            brandedFare: "BX",
            brandedFareLabel: "BUSINESS RESTRICTED",
            class: "K",
            includedCheckedBags: {
              weight: 40,
              weightUnit: "KG",
            },
            amenities: [
              {
                description: "MEAL SERVICE",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "FAST TRACK IF AVAILABLE",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY CHECK IN",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BOARDING",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "STANDART SEAT SELECTION",
                isChargeable: false,
                amenityType: "BRANDED_FARES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "REFUNDABLE TICKET",
                isChargeable: true,
                amenityType: "BRANDED_FARES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "CHANGEABLE TICKET",
                isChargeable: true,
                amenityType: "BRANDED_FARES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
            ],
          },
          {
            segmentId: "48",
            cabin: "BUSINESS",
            fareBasis: "KF2BX",
            brandedFare: "BX",
            brandedFareLabel: "BUSINESS RESTRICTED",
            class: "K",
            includedCheckedBags: {
              weight: 40,
              weightUnit: "KG",
            },
            amenities: [
              {
                description: "MEAL SERVICE",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "FAST TRACK IF AVAILABLE",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY CHECK IN",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BOARDING",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "STANDART SEAT SELECTION",
                isChargeable: false,
                amenityType: "BRANDED_FARES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "REFUNDABLE TICKET",
                isChargeable: true,
                amenityType: "BRANDED_FARES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "CHANGEABLE TICKET",
                isChargeable: true,
                amenityType: "BRANDED_FARES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
            ],
          },
          {
            segmentId: "49",
            cabin: "BUSINESS",
            fareBasis: "KF2BX",
            brandedFare: "BX",
            brandedFareLabel: "BUSINESS RESTRICTED",
            class: "K",
            includedCheckedBags: {
              weight: 40,
              weightUnit: "KG",
            },
            amenities: [
              {
                description: "MEAL SERVICE",
                isChargeable: false,
                amenityType: "MEAL",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "FAST TRACK IF AVAILABLE",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY CHECK IN",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "PRIORITY BOARDING",
                isChargeable: false,
                amenityType: "TRAVEL_SERVICES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "STANDART SEAT SELECTION",
                isChargeable: false,
                amenityType: "BRANDED_FARES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "REFUNDABLE TICKET",
                isChargeable: true,
                amenityType: "BRANDED_FARES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
              {
                description: "CHANGEABLE TICKET",
                isChargeable: true,
                amenityType: "BRANDED_FARES",
                amenityProvider: {
                  name: "BrandedFare",
                },
              },
            ],
          },
        ],
      },
    ],
  },
  {
    type: "flight-offer",
    id: "45",
    source: "GDS",
    instantTicketingRequired: false,
    nonHomogeneous: false,
    oneWay: false,
    lastTicketingDate: "2024-05-06",
    lastTicketingDateTime: "2024-05-06",
    numberOfBookableSeats: 4,
    itineraries: [
      {
        duration: "PT15H55M",
        segments: [
          {
            departure: {
              iataCode: "LHE",
              terminal: "M",
              at: "2024-05-06T09:55:00",
            },
            arrival: {
              iataCode: "DOH",
              at: "2024-05-06T11:40:00",
            },
            carrierCode: "WY",
            number: "6032",
            aircraft: {
              code: "788",
            },
            operating: {
              carrierCode: "QR",
            },
            duration: "PT3H45M",
            id: "27",
            numberOfStops: 0,
            blacklistedInEU: false,
          },
          {
            departure: {
              iataCode: "DOH",
              at: "2024-05-06T16:15:00",
            },
            arrival: {
              iataCode: "MCT",
              at: "2024-05-06T18:45:00",
            },
            carrierCode: "WY",
            number: "664",
            aircraft: {
              code: "7M8",
            },
            operating: {
              carrierCode: "WY",
            },
            duration: "PT1H30M",
            id: "28",
            numberOfStops: 0,
            blacklistedInEU: false,
          },
          {
            departure: {
              iataCode: "MCT",
              at: "2024-05-06T21:30:00",
            },
            arrival: {
              iataCode: "JED",
              terminal: "1",
              at: "2024-05-06T23:50:00",
            },
            carrierCode: "WY",
            number: "673",
            aircraft: {
              code: "739",
            },
            operating: {
              carrierCode: "WY",
            },
            duration: "PT3H20M",
            id: "29",
            numberOfStops: 0,
            blacklistedInEU: false,
          },
        ],
      },
      {
        duration: "PT15H15M",
        segments: [
          {
            departure: {
              iataCode: "JED",
              terminal: "1",
              at: "2024-05-11T14:35:00",
            },
            arrival: {
              iataCode: "MCT",
              at: "2024-05-11T18:50:00",
            },
            carrierCode: "WY",
            number: "690",
            aircraft: {
              code: "738",
            },
            operating: {
              carrierCode: "WY",
            },
            duration: "PT3H15M",
            id: "37",
            numberOfStops: 0,
            blacklistedInEU: false,
          },
          {
            departure: {
              iataCode: "MCT",
              at: "2024-05-11T20:10:00",
            },
            arrival: {
              iataCode: "DOH",
              at: "2024-05-11T20:45:00",
            },
            carrierCode: "WY",
            number: "661",
            aircraft: {
              code: "738",
            },
            operating: {
              carrierCode: "WY",
            },
            duration: "PT1H35M",
            id: "38",
            numberOfStops: 0,
            blacklistedInEU: false,
          },
          {
            departure: {
              iataCode: "DOH",
              at: "2024-05-12T02:20:00",
            },
            arrival: {
              iataCode: "LHE",
              terminal: "M",
              at: "2024-05-12T07:50:00",
            },
            carrierCode: "WY",
            number: "6031",
            aircraft: {
              code: "788",
            },
            operating: {
              carrierCode: "QR",
            },
            duration: "PT3H30M",
            id: "39",
            numberOfStops: 0,
            blacklistedInEU: false,
          },
        ],
      },
    ],
    price: {
      currency: "USD",
      total: "4203.26",
      base: "3069.00",
      fees: [
        {
          amount: "0.00",
          type: "SUPPLIER",
        },
        {
          amount: "0.00",
          type: "TICKETING",
        },
      ],
      grandTotal: "4203.26",
    },
    pricingOptions: {
      fareType: ["PUBLISHED"],
      includedCheckedBagsOnly: true,
    },
    validatingAirlineCodes: ["WY"],
    travelerPricings: [
      {
        travelerId: "1",
        fareOption: "STANDARD",
        travelerType: "ADULT",
        price: {
          currency: "USD",
          total: "4203.26",
          base: "3069.00",
        },
        fareDetailsBySegment: [
          {
            segmentId: "27",
            cabin: "BUSINESS",
            fareBasis: "CBPRQAQR",
            class: "C",
            includedCheckedBags: {
              weight: 50,
              weightUnit: "KG",
            },
          },
          {
            segmentId: "28",
            cabin: "BUSINESS",
            fareBasis: "CBPRQAQR",
            class: "C",
            includedCheckedBags: {
              weight: 50,
              weightUnit: "KG",
            },
          },
          {
            segmentId: "29",
            cabin: "BUSINESS",
            fareBasis: "CBPRQAQR",
            class: "C",
            includedCheckedBags: {
              weight: 50,
              weightUnit: "KG",
            },
          },
          {
            segmentId: "37",
            cabin: "BUSINESS",
            fareBasis: "CBPRQAQR",
            class: "C",
            includedCheckedBags: {
              weight: 50,
              weightUnit: "KG",
            },
          },
          {
            segmentId: "38",
            cabin: "BUSINESS",
            fareBasis: "CBPRQAQR",
            class: "C",
            includedCheckedBags: {
              weight: 50,
              weightUnit: "KG",
            },
          },
          {
            segmentId: "39",
            cabin: "BUSINESS",
            fareBasis: "CBPRQAQR",
            class: "C",
            includedCheckedBags: {
              weight: 50,
              weightUnit: "KG",
            },
          },
        ],
      },
    ],
  },
  {
    type: "flight-offer",
    id: "46",
    source: "GDS",
    instantTicketingRequired: false,
    nonHomogeneous: false,
    oneWay: false,
    lastTicketingDate: "2024-05-06",
    lastTicketingDateTime: "2024-05-06",
    numberOfBookableSeats: 4,
    itineraries: [
      {
        duration: "PT22H20M",
        segments: [
          {
            departure: {
              iataCode: "LHE",
              terminal: "M",
              at: "2024-05-06T03:30:00",
            },
            arrival: {
              iataCode: "DOH",
              at: "2024-05-06T05:15:00",
            },
            carrierCode: "WY",
            number: "6030",
            aircraft: {
              code: "351",
            },
            operating: {
              carrierCode: "QR",
            },
            duration: "PT3H45M",
            id: "12",
            numberOfStops: 0,
            blacklistedInEU: false,
          },
          {
            departure: {
              iataCode: "DOH",
              at: "2024-05-06T16:15:00",
            },
            arrival: {
              iataCode: "MCT",
              at: "2024-05-06T18:45:00",
            },
            carrierCode: "WY",
            number: "664",
            aircraft: {
              code: "7M8",
            },
            operating: {
              carrierCode: "WY",
            },
            duration: "PT1H30M",
            id: "13",
            numberOfStops: 0,
            blacklistedInEU: false,
          },
          {
            departure: {
              iataCode: "MCT",
              at: "2024-05-06T21:30:00",
            },
            arrival: {
              iataCode: "JED",
              terminal: "1",
              at: "2024-05-06T23:50:00",
            },
            carrierCode: "WY",
            number: "673",
            aircraft: {
              code: "739",
            },
            operating: {
              carrierCode: "WY",
            },
            duration: "PT3H20M",
            id: "14",
            numberOfStops: 0,
            blacklistedInEU: false,
          },
        ],
      },
      {
        duration: "PT15H15M",
        segments: [
          {
            departure: {
              iataCode: "JED",
              terminal: "1",
              at: "2024-05-11T14:35:00",
            },
            arrival: {
              iataCode: "MCT",
              at: "2024-05-11T18:50:00",
            },
            carrierCode: "WY",
            number: "690",
            aircraft: {
              code: "738",
            },
            operating: {
              carrierCode: "WY",
            },
            duration: "PT3H15M",
            id: "37",
            numberOfStops: 0,
            blacklistedInEU: false,
          },
          {
            departure: {
              iataCode: "MCT",
              at: "2024-05-11T20:10:00",
            },
            arrival: {
              iataCode: "DOH",
              at: "2024-05-11T20:45:00",
            },
            carrierCode: "WY",
            number: "661",
            aircraft: {
              code: "738",
            },
            operating: {
              carrierCode: "WY",
            },
            duration: "PT1H35M",
            id: "38",
            numberOfStops: 0,
            blacklistedInEU: false,
          },
          {
            departure: {
              iataCode: "DOH",
              at: "2024-05-12T02:20:00",
            },
            arrival: {
              iataCode: "LHE",
              terminal: "M",
              at: "2024-05-12T07:50:00",
            },
            carrierCode: "WY",
            number: "6031",
            aircraft: {
              code: "788",
            },
            operating: {
              carrierCode: "QR",
            },
            duration: "PT3H30M",
            id: "39",
            numberOfStops: 0,
            blacklistedInEU: false,
          },
        ],
      },
    ],
    price: {
      currency: "USD",
      total: "4203.26",
      base: "3069.00",
      fees: [
        {
          amount: "0.00",
          type: "SUPPLIER",
        },
        {
          amount: "0.00",
          type: "TICKETING",
        },
      ],
      grandTotal: "4203.26",
    },
    pricingOptions: {
      fareType: ["PUBLISHED"],
      includedCheckedBagsOnly: true,
    },
    validatingAirlineCodes: ["WY"],
    travelerPricings: [
      {
        travelerId: "1",
        fareOption: "STANDARD",
        travelerType: "ADULT",
        price: {
          currency: "USD",
          total: "4203.26",
          base: "3069.00",
        },
        fareDetailsBySegment: [
          {
            segmentId: "12",
            cabin: "BUSINESS",
            fareBasis: "CBPRQAQR",
            class: "C",
            includedCheckedBags: {
              weight: 50,
              weightUnit: "KG",
            },
          },
          {
            segmentId: "13",
            cabin: "BUSINESS",
            fareBasis: "CBPRQAQR",
            class: "C",
            includedCheckedBags: {
              weight: 50,
              weightUnit: "KG",
            },
          },
          {
            segmentId: "14",
            cabin: "BUSINESS",
            fareBasis: "CBPRQAQR",
            class: "C",
            includedCheckedBags: {
              weight: 50,
              weightUnit: "KG",
            },
          },
          {
            segmentId: "37",
            cabin: "BUSINESS",
            fareBasis: "CBPRQAQR",
            class: "C",
            includedCheckedBags: {
              weight: 50,
              weightUnit: "KG",
            },
          },
          {
            segmentId: "38",
            cabin: "BUSINESS",
            fareBasis: "CBPRQAQR",
            class: "C",
            includedCheckedBags: {
              weight: 50,
              weightUnit: "KG",
            },
          },
          {
            segmentId: "39",
            cabin: "BUSINESS",
            fareBasis: "CBPRQAQR",
            class: "C",
            includedCheckedBags: {
              weight: 50,
              weightUnit: "KG",
            },
          },
        ],
      },
    ],
  },
  {
    type: "flight-offer",
    id: "47",
    source: "GDS",
    instantTicketingRequired: false,
    nonHomogeneous: false,
    oneWay: false,
    lastTicketingDate: "2024-05-06",
    lastTicketingDateTime: "2024-05-06",
    numberOfBookableSeats: 4,
    itineraries: [
      {
        duration: "PT27H",
        segments: [
          {
            departure: {
              iataCode: "LHE",
              terminal: "M",
              at: "2024-05-06T09:55:00",
            },
            arrival: {
              iataCode: "DOH",
              at: "2024-05-06T11:40:00",
            },
            carrierCode: "WY",
            number: "6032",
            aircraft: {
              code: "788",
            },
            operating: {
              carrierCode: "QR",
            },
            duration: "PT3H45M",
            id: "5",
            numberOfStops: 0,
            blacklistedInEU: false,
          },
          {
            departure: {
              iataCode: "DOH",
              at: "2024-05-06T21:45:00",
            },
            arrival: {
              iataCode: "MCT",
              at: "2024-05-07T00:15:00",
            },
            carrierCode: "WY",
            number: "662",
            aircraft: {
              code: "7M8",
            },
            operating: {
              carrierCode: "WY",
            },
            duration: "PT1H30M",
            id: "6",
            numberOfStops: 0,
            blacklistedInEU: false,
          },
          {
            departure: {
              iataCode: "MCT",
              at: "2024-05-07T08:35:00",
            },
            arrival: {
              iataCode: "JED",
              terminal: "1",
              at: "2024-05-07T10:55:00",
            },
            carrierCode: "WY",
            number: "689",
            aircraft: {
              code: "739",
            },
            operating: {
              carrierCode: "WY",
            },
            duration: "PT3H20M",
            id: "7",
            numberOfStops: 0,
            blacklistedInEU: false,
          },
        ],
      },
      {
        duration: "PT15H15M",
        segments: [
          {
            departure: {
              iataCode: "JED",
              terminal: "1",
              at: "2024-05-11T14:35:00",
            },
            arrival: {
              iataCode: "MCT",
              at: "2024-05-11T18:50:00",
            },
            carrierCode: "WY",
            number: "690",
            aircraft: {
              code: "738",
            },
            operating: {
              carrierCode: "WY",
            },
            duration: "PT3H15M",
            id: "37",
            numberOfStops: 0,
            blacklistedInEU: false,
          },
          {
            departure: {
              iataCode: "MCT",
              at: "2024-05-11T20:10:00",
            },
            arrival: {
              iataCode: "DOH",
              at: "2024-05-11T20:45:00",
            },
            carrierCode: "WY",
            number: "661",
            aircraft: {
              code: "738",
            },
            operating: {
              carrierCode: "WY",
            },
            duration: "PT1H35M",
            id: "38",
            numberOfStops: 0,
            blacklistedInEU: false,
          },
          {
            departure: {
              iataCode: "DOH",
              at: "2024-05-12T02:20:00",
            },
            arrival: {
              iataCode: "LHE",
              terminal: "M",
              at: "2024-05-12T07:50:00",
            },
            carrierCode: "WY",
            number: "6031",
            aircraft: {
              code: "788",
            },
            operating: {
              carrierCode: "QR",
            },
            duration: "PT3H30M",
            id: "39",
            numberOfStops: 0,
            blacklistedInEU: false,
          },
        ],
      },
    ],
    price: {
      currency: "USD",
      total: "4203.26",
      base: "3069.00",
      fees: [
        {
          amount: "0.00",
          type: "SUPPLIER",
        },
        {
          amount: "0.00",
          type: "TICKETING",
        },
      ],
      grandTotal: "4203.26",
    },
    pricingOptions: {
      fareType: ["PUBLISHED"],
      includedCheckedBagsOnly: true,
    },
    validatingAirlineCodes: ["WY"],
    travelerPricings: [
      {
        travelerId: "1",
        fareOption: "STANDARD",
        travelerType: "ADULT",
        price: {
          currency: "USD",
          total: "4203.26",
          base: "3069.00",
        },
        fareDetailsBySegment: [
          {
            segmentId: "5",
            cabin: "BUSINESS",
            fareBasis: "CBPRQAQR",
            class: "C",
            includedCheckedBags: {
              weight: 50,
              weightUnit: "KG",
            },
          },
          {
            segmentId: "6",
            cabin: "BUSINESS",
            fareBasis: "CBPRQAQR",
            class: "C",
            includedCheckedBags: {
              weight: 50,
              weightUnit: "KG",
            },
          },
          {
            segmentId: "7",
            cabin: "BUSINESS",
            fareBasis: "CBPRQAQR",
            class: "C",
            includedCheckedBags: {
              weight: 50,
              weightUnit: "KG",
            },
          },
          {
            segmentId: "37",
            cabin: "BUSINESS",
            fareBasis: "CBPRQAQR",
            class: "C",
            includedCheckedBags: {
              weight: 50,
              weightUnit: "KG",
            },
          },
          {
            segmentId: "38",
            cabin: "BUSINESS",
            fareBasis: "CBPRQAQR",
            class: "C",
            includedCheckedBags: {
              weight: 50,
              weightUnit: "KG",
            },
          },
          {
            segmentId: "39",
            cabin: "BUSINESS",
            fareBasis: "CBPRQAQR",
            class: "C",
            includedCheckedBags: {
              weight: 50,
              weightUnit: "KG",
            },
          },
        ],
      },
    ],
  },
  {
    type: "flight-offer",
    id: "48",
    source: "GDS",
    instantTicketingRequired: false,
    nonHomogeneous: false,
    oneWay: false,
    lastTicketingDate: "2024-05-06",
    lastTicketingDateTime: "2024-05-06",
    numberOfBookableSeats: 4,
    itineraries: [
      {
        duration: "PT15H55M",
        segments: [
          {
            departure: {
              iataCode: "LHE",
              terminal: "M",
              at: "2024-05-06T09:55:00",
            },
            arrival: {
              iataCode: "DOH",
              at: "2024-05-06T11:40:00",
            },
            carrierCode: "WY",
            number: "6032",
            aircraft: {
              code: "788",
            },
            operating: {
              carrierCode: "QR",
            },
            duration: "PT3H45M",
            id: "27",
            numberOfStops: 0,
            blacklistedInEU: false,
          },
          {
            departure: {
              iataCode: "DOH",
              at: "2024-05-06T16:15:00",
            },
            arrival: {
              iataCode: "MCT",
              at: "2024-05-06T18:45:00",
            },
            carrierCode: "WY",
            number: "664",
            aircraft: {
              code: "7M8",
            },
            operating: {
              carrierCode: "WY",
            },
            duration: "PT1H30M",
            id: "28",
            numberOfStops: 0,
            blacklistedInEU: false,
          },
          {
            departure: {
              iataCode: "MCT",
              at: "2024-05-06T21:30:00",
            },
            arrival: {
              iataCode: "JED",
              terminal: "1",
              at: "2024-05-06T23:50:00",
            },
            carrierCode: "WY",
            number: "673",
            aircraft: {
              code: "739",
            },
            operating: {
              carrierCode: "WY",
            },
            duration: "PT3H20M",
            id: "29",
            numberOfStops: 0,
            blacklistedInEU: false,
          },
        ],
      },
      {
        duration: "PT27H10M",
        segments: [
          {
            departure: {
              iataCode: "JED",
              terminal: "1",
              at: "2024-05-11T02:40:00",
            },
            arrival: {
              iataCode: "MCT",
              at: "2024-05-11T06:55:00",
            },
            carrierCode: "WY",
            number: "674",
            aircraft: {
              code: "739",
            },
            operating: {
              carrierCode: "WY",
            },
            duration: "PT3H15M",
            id: "34",
            numberOfStops: 0,
            blacklistedInEU: false,
          },
          {
            departure: {
              iataCode: "MCT",
              at: "2024-05-11T20:10:00",
            },
            arrival: {
              iataCode: "DOH",
              at: "2024-05-11T20:45:00",
            },
            carrierCode: "WY",
            number: "661",
            aircraft: {
              code: "738",
            },
            operating: {
              carrierCode: "WY",
            },
            duration: "PT1H35M",
            id: "35",
            numberOfStops: 0,
            blacklistedInEU: false,
          },
          {
            departure: {
              iataCode: "DOH",
              at: "2024-05-12T02:20:00",
            },
            arrival: {
              iataCode: "LHE",
              terminal: "M",
              at: "2024-05-12T07:50:00",
            },
            carrierCode: "WY",
            number: "6031",
            aircraft: {
              code: "788",
            },
            operating: {
              carrierCode: "QR",
            },
            duration: "PT3H30M",
            id: "36",
            numberOfStops: 0,
            blacklistedInEU: false,
          },
        ],
      },
    ],
    price: {
      currency: "USD",
      total: "4203.26",
      base: "3069.00",
      fees: [
        {
          amount: "0.00",
          type: "SUPPLIER",
        },
        {
          amount: "0.00",
          type: "TICKETING",
        },
      ],
      grandTotal: "4203.26",
    },
    pricingOptions: {
      fareType: ["PUBLISHED"],
      includedCheckedBagsOnly: true,
    },
    validatingAirlineCodes: ["WY"],
    travelerPricings: [
      {
        travelerId: "1",
        fareOption: "STANDARD",
        travelerType: "ADULT",
        price: {
          currency: "USD",
          total: "4203.26",
          base: "3069.00",
        },
        fareDetailsBySegment: [
          {
            segmentId: "27",
            cabin: "BUSINESS",
            fareBasis: "CBPRQAQR",
            class: "C",
            includedCheckedBags: {
              weight: 50,
              weightUnit: "KG",
            },
          },
          {
            segmentId: "28",
            cabin: "BUSINESS",
            fareBasis: "CBPRQAQR",
            class: "C",
            includedCheckedBags: {
              weight: 50,
              weightUnit: "KG",
            },
          },
          {
            segmentId: "29",
            cabin: "BUSINESS",
            fareBasis: "CBPRQAQR",
            class: "C",
            includedCheckedBags: {
              weight: 50,
              weightUnit: "KG",
            },
          },
          {
            segmentId: "34",
            cabin: "BUSINESS",
            fareBasis: "CBPRQAQR",
            class: "C",
            includedCheckedBags: {
              weight: 50,
              weightUnit: "KG",
            },
          },
          {
            segmentId: "35",
            cabin: "BUSINESS",
            fareBasis: "CBPRQAQR",
            class: "C",
            includedCheckedBags: {
              weight: 50,
              weightUnit: "KG",
            },
          },
          {
            segmentId: "36",
            cabin: "BUSINESS",
            fareBasis: "CBPRQAQR",
            class: "C",
            includedCheckedBags: {
              weight: 50,
              weightUnit: "KG",
            },
          },
        ],
      },
    ],
  },
  {
    type: "flight-offer",
    id: "49",
    source: "GDS",
    instantTicketingRequired: false,
    nonHomogeneous: false,
    oneWay: false,
    lastTicketingDate: "2024-05-06",
    lastTicketingDateTime: "2024-05-06",
    numberOfBookableSeats: 4,
    itineraries: [
      {
        duration: "PT22H20M",
        segments: [
          {
            departure: {
              iataCode: "LHE",
              terminal: "M",
              at: "2024-05-06T03:30:00",
            },
            arrival: {
              iataCode: "DOH",
              at: "2024-05-06T05:15:00",
            },
            carrierCode: "WY",
            number: "6030",
            aircraft: {
              code: "351",
            },
            operating: {
              carrierCode: "QR",
            },
            duration: "PT3H45M",
            id: "12",
            numberOfStops: 0,
            blacklistedInEU: false,
          },
          {
            departure: {
              iataCode: "DOH",
              at: "2024-05-06T16:15:00",
            },
            arrival: {
              iataCode: "MCT",
              at: "2024-05-06T18:45:00",
            },
            carrierCode: "WY",
            number: "664",
            aircraft: {
              code: "7M8",
            },
            operating: {
              carrierCode: "WY",
            },
            duration: "PT1H30M",
            id: "13",
            numberOfStops: 0,
            blacklistedInEU: false,
          },
          {
            departure: {
              iataCode: "MCT",
              at: "2024-05-06T21:30:00",
            },
            arrival: {
              iataCode: "JED",
              terminal: "1",
              at: "2024-05-06T23:50:00",
            },
            carrierCode: "WY",
            number: "673",
            aircraft: {
              code: "739",
            },
            operating: {
              carrierCode: "WY",
            },
            duration: "PT3H20M",
            id: "14",
            numberOfStops: 0,
            blacklistedInEU: false,
          },
        ],
      },
      {
        duration: "PT27H10M",
        segments: [
          {
            departure: {
              iataCode: "JED",
              terminal: "1",
              at: "2024-05-11T02:40:00",
            },
            arrival: {
              iataCode: "MCT",
              at: "2024-05-11T06:55:00",
            },
            carrierCode: "WY",
            number: "674",
            aircraft: {
              code: "739",
            },
            operating: {
              carrierCode: "WY",
            },
            duration: "PT3H15M",
            id: "34",
            numberOfStops: 0,
            blacklistedInEU: false,
          },
          {
            departure: {
              iataCode: "MCT",
              at: "2024-05-11T20:10:00",
            },
            arrival: {
              iataCode: "DOH",
              at: "2024-05-11T20:45:00",
            },
            carrierCode: "WY",
            number: "661",
            aircraft: {
              code: "738",
            },
            operating: {
              carrierCode: "WY",
            },
            duration: "PT1H35M",
            id: "35",
            numberOfStops: 0,
            blacklistedInEU: false,
          },
          {
            departure: {
              iataCode: "DOH",
              at: "2024-05-12T02:20:00",
            },
            arrival: {
              iataCode: "LHE",
              terminal: "M",
              at: "2024-05-12T07:50:00",
            },
            carrierCode: "WY",
            number: "6031",
            aircraft: {
              code: "788",
            },
            operating: {
              carrierCode: "QR",
            },
            duration: "PT3H30M",
            id: "36",
            numberOfStops: 0,
            blacklistedInEU: false,
          },
        ],
      },
    ],
    price: {
      currency: "USD",
      total: "4203.26",
      base: "3069.00",
      fees: [
        {
          amount: "0.00",
          type: "SUPPLIER",
        },
        {
          amount: "0.00",
          type: "TICKETING",
        },
      ],
      grandTotal: "4203.26",
    },
    pricingOptions: {
      fareType: ["PUBLISHED"],
      includedCheckedBagsOnly: true,
    },
    validatingAirlineCodes: ["WY"],
    travelerPricings: [
      {
        travelerId: "1",
        fareOption: "STANDARD",
        travelerType: "ADULT",
        price: {
          currency: "USD",
          total: "4203.26",
          base: "3069.00",
        },
        fareDetailsBySegment: [
          {
            segmentId: "12",
            cabin: "BUSINESS",
            fareBasis: "CBPRQAQR",
            class: "C",
            includedCheckedBags: {
              weight: 50,
              weightUnit: "KG",
            },
          },
          {
            segmentId: "13",
            cabin: "BUSINESS",
            fareBasis: "CBPRQAQR",
            class: "C",
            includedCheckedBags: {
              weight: 50,
              weightUnit: "KG",
            },
          },
          {
            segmentId: "14",
            cabin: "BUSINESS",
            fareBasis: "CBPRQAQR",
            class: "C",
            includedCheckedBags: {
              weight: 50,
              weightUnit: "KG",
            },
          },
          {
            segmentId: "34",
            cabin: "BUSINESS",
            fareBasis: "CBPRQAQR",
            class: "C",
            includedCheckedBags: {
              weight: 50,
              weightUnit: "KG",
            },
          },
          {
            segmentId: "35",
            cabin: "BUSINESS",
            fareBasis: "CBPRQAQR",
            class: "C",
            includedCheckedBags: {
              weight: 50,
              weightUnit: "KG",
            },
          },
          {
            segmentId: "36",
            cabin: "BUSINESS",
            fareBasis: "CBPRQAQR",
            class: "C",
            includedCheckedBags: {
              weight: 50,
              weightUnit: "KG",
            },
          },
        ],
      },
    ],
  },
  {
    type: "flight-offer",
    id: "50",
    source: "GDS",
    instantTicketingRequired: false,
    nonHomogeneous: false,
    oneWay: false,
    lastTicketingDate: "2024-05-06",
    lastTicketingDateTime: "2024-05-06",
    numberOfBookableSeats: 4,
    itineraries: [
      {
        duration: "PT27H",
        segments: [
          {
            departure: {
              iataCode: "LHE",
              terminal: "M",
              at: "2024-05-06T09:55:00",
            },
            arrival: {
              iataCode: "DOH",
              at: "2024-05-06T11:40:00",
            },
            carrierCode: "WY",
            number: "6032",
            aircraft: {
              code: "788",
            },
            operating: {
              carrierCode: "QR",
            },
            duration: "PT3H45M",
            id: "5",
            numberOfStops: 0,
            blacklistedInEU: false,
          },
          {
            departure: {
              iataCode: "DOH",
              at: "2024-05-06T21:45:00",
            },
            arrival: {
              iataCode: "MCT",
              at: "2024-05-07T00:15:00",
            },
            carrierCode: "WY",
            number: "662",
            aircraft: {
              code: "7M8",
            },
            operating: {
              carrierCode: "WY",
            },
            duration: "PT1H30M",
            id: "6",
            numberOfStops: 0,
            blacklistedInEU: false,
          },
          {
            departure: {
              iataCode: "MCT",
              at: "2024-05-07T08:35:00",
            },
            arrival: {
              iataCode: "JED",
              terminal: "1",
              at: "2024-05-07T10:55:00",
            },
            carrierCode: "WY",
            number: "689",
            aircraft: {
              code: "739",
            },
            operating: {
              carrierCode: "WY",
            },
            duration: "PT3H20M",
            id: "7",
            numberOfStops: 0,
            blacklistedInEU: false,
          },
        ],
      },
      {
        duration: "PT27H10M",
        segments: [
          {
            departure: {
              iataCode: "JED",
              terminal: "1",
              at: "2024-05-11T02:40:00",
            },
            arrival: {
              iataCode: "MCT",
              at: "2024-05-11T06:55:00",
            },
            carrierCode: "WY",
            number: "674",
            aircraft: {
              code: "739",
            },
            operating: {
              carrierCode: "WY",
            },
            duration: "PT3H15M",
            id: "34",
            numberOfStops: 0,
            blacklistedInEU: false,
          },
          {
            departure: {
              iataCode: "MCT",
              at: "2024-05-11T20:10:00",
            },
            arrival: {
              iataCode: "DOH",
              at: "2024-05-11T20:45:00",
            },
            carrierCode: "WY",
            number: "661",
            aircraft: {
              code: "738",
            },
            operating: {
              carrierCode: "WY",
            },
            duration: "PT1H35M",
            id: "35",
            numberOfStops: 0,
            blacklistedInEU: false,
          },
          {
            departure: {
              iataCode: "DOH",
              at: "2024-05-12T02:20:00",
            },
            arrival: {
              iataCode: "LHE",
              terminal: "M",
              at: "2024-05-12T07:50:00",
            },
            carrierCode: "WY",
            number: "6031",
            aircraft: {
              code: "788",
            },
            operating: {
              carrierCode: "QR",
            },
            duration: "PT3H30M",
            id: "36",
            numberOfStops: 0,
            blacklistedInEU: false,
          },
        ],
      },
    ],
    price: {
      currency: "USD",
      total: "4203.26",
      base: "3069.00",
      fees: [
        {
          amount: "0.00",
          type: "SUPPLIER",
        },
        {
          amount: "0.00",
          type: "TICKETING",
        },
      ],
      grandTotal: "4203.26",
    },
    pricingOptions: {
      fareType: ["PUBLISHED"],
      includedCheckedBagsOnly: true,
    },
    validatingAirlineCodes: ["WY"],
    travelerPricings: [
      {
        travelerId: "1",
        fareOption: "STANDARD",
        travelerType: "ADULT",
        price: {
          currency: "USD",
          total: "4203.26",
          base: "3069.00",
        },
        fareDetailsBySegment: [
          {
            segmentId: "5",
            cabin: "BUSINESS",
            fareBasis: "CBPRQAQR",
            class: "C",
            includedCheckedBags: {
              weight: 50,
              weightUnit: "KG",
            },
          },
          {
            segmentId: "6",
            cabin: "BUSINESS",
            fareBasis: "CBPRQAQR",
            class: "C",
            includedCheckedBags: {
              weight: 50,
              weightUnit: "KG",
            },
          },
          {
            segmentId: "7",
            cabin: "BUSINESS",
            fareBasis: "CBPRQAQR",
            class: "C",
            includedCheckedBags: {
              weight: 50,
              weightUnit: "KG",
            },
          },
          {
            segmentId: "34",
            cabin: "BUSINESS",
            fareBasis: "CBPRQAQR",
            class: "C",
            includedCheckedBags: {
              weight: 50,
              weightUnit: "KG",
            },
          },
          {
            segmentId: "35",
            cabin: "BUSINESS",
            fareBasis: "CBPRQAQR",
            class: "C",
            includedCheckedBags: {
              weight: 50,
              weightUnit: "KG",
            },
          },
          {
            segmentId: "36",
            cabin: "BUSINESS",
            fareBasis: "CBPRQAQR",
            class: "C",
            includedCheckedBags: {
              weight: 50,
              weightUnit: "KG",
            },
          },
        ],
      },
    ],
  },
];
const MockAirlines = ["EK", "TK", "WY"];
const MockHotels = [
  {
    type: "hotel-offers",
    hotel: {
      type: "hotel",
      hotelId: "RTDXBNOV",
      chainCode: "RT",
      dupeId: "700084467",
      name: "Novotel Deira City Centre",
      cityCode: "DXB",
      latitude: 25.25359,
      longitude: 55.33293,
    },
    available: true,
    offers: [
      {
        id: "RRW6RXOZHS",
        checkInDate: "2024-05-09",
        checkOutDate: "2024-05-10",
        rateCode: "RDI",
        rateFamilyEstimated: {
          code: "RAC",
          type: "P",
        },
        boardType: "ROOM_ONLY",
        room: {
          type: "A1D",
          typeEstimated: {
            category: "SUPERIOR_ROOM",
            beds: 1,
            bedType: "DOUBLE",
          },
          description: {
            text: "Rack rate\nSuperior Room with 1 double bed and sofa b ed b\nasics",
            lang: "EN",
          },
        },
        guests: {
          adults: 1,
        },
        price: {
          currency: "AED",
          total: "1080.00",
          taxes: [
            {
              code: "CITY_TAX",
              pricingFrequency: "PER_NIGHT",
              pricingMode: "PER_PRODUCT",
              percentage: "10.00",
              included: true,
            },
          ],
          variations: {
            average: {
              base: "900.00",
            },
            changes: [
              {
                startDate: "2024-05-09",
                endDate: "2024-05-10",
                base: "900.00",
              },
            ],
          },
        },
        policies: {
          guarantee: {
            acceptedPayments: {
              creditCards: ["AX", "CA", "DC", "EC", "IK", "JC", "EE"],
              methods: ["CREDIT_CARD", "VCC_EXTERNAL_PROVIDER"],
              creditCardPolicies: [
                {
                  vendorCode: "AX",
                },
                {
                  vendorCode: "CA",
                },
                {
                  vendorCode: "DC",
                },
                {
                  vendorCode: "EC",
                },
                {
                  vendorCode: "IK",
                },
                {
                  vendorCode: "JC",
                },
                {
                  vendorCode: "EE",
                },
              ],
            },
          },
          paymentType: "guarantee",
        },
        self: "https://test.api.amadeus.com/v3/shopping/hotel-offers/RRW6RXOZHS",
      },
    ],
    self: "https://test.api.amadeus.com/v3/shopping/hotel-offers?hotelIds=RTDXBNOV",
  },
];


export { MockFlights, MockAirlines, MockHotels };
