import React, { useEffect, useRef, useState } from "react";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";

import { FaStar } from "react-icons/fa";
import { IoShareSocialSharp } from "react-icons/io5";
import HotelTickets from "../../../components/HotelTickets";
import TermsConditions from "../../Terms";
import Contactterms from "../../../components/contact";
import TicketSlider from "../../../components/Slider";
import FlightLayout from "../../../layouts/FlightLayout";
import { useNavigate, useParams } from "react-router-dom";
import httpRequest from "../../../axios/";
import { toast } from "react-toastify";
import { GET_SINGLE_BOOKING } from "../../../constants/apiEndPoints";
import { Loader } from "../../../components/common";

const HotelTerms = () => {
  const { id } = useParams();
  const [bookingDetail, setBookingDetail] = useState(undefined);
  const navigate = useNavigate();
  const fetchBookingDetails = async () => {
    try {
      const result = await httpRequest.get(`${GET_SINGLE_BOOKING}${id}`);
      console.log(result.data);

      setBookingDetail(result.data);
    } catch (error) {
      console.log(error);
      toast.error("No Such Booking Available ");
      window.location.href = "/hotels";
    }
  };
  useEffect(() => {
    if (id === undefined) {
      window.location.href = "/hotels";
    }
    fetchBookingDetails();
  }, []);

  // Download Ticket

  const divRef = useRef();

  const handleDownloadPDF = () => {
    const input = divRef.current;
    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "mm", "a4");
      const imgWidth = 210;
      const pageHeight = 200;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      let heightLeft = imgHeight;

      let position = 0;

      pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;

      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        pdf.addPage();
        pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
      }
      pdf.save("Ticket.pdf");
    });
  };

  return (
    <FlightLayout>
      {bookingDetail ? (
        <div className="w-[90%] ml-[5%] ">
          {/* header */}
          <div>
            {/* <div className="flex ml-[16px]">
              <h1 className="text-[14px] font-medium">Turkey</h1>
              <span className="ml-[8px] text-[14px]  ">{">"}</span>
              <h1 className="text-[14px] font-medium ml-[8px] ">Istambol</h1>
              <span className="ml-[8px] text-[14px] ">{">"}</span>
              <p
                className="text-[14px] font-medium ml-[8px]"
                style={{ color: "#A0A7A0" }}
              >
                Boshpuras Hostel Istambol
              </p>
            </div> */}
            <div className="col-span-2 p-4 ">
              {/* //// */}
              <div className="grid grid-cols-2  ">
                <div className="col-span-2 lg:col-span-1">
                  <div className="flex justify-start flex-wrap sm:algin-center overflow-hidden ">
                    <div className="">
                      <p className="font-semibold pb-2 text-[18px] md:text-[20px]">
                        {bookingDetail.bookingInfo.hotelName}
                      </p>
                    </div>
                    <div className=" ml-[8px] flex gap-2 pb-2 items-start flex-col sm:flex-row ">
                      <div className="flex ml-[7px] ">
                        <FaStar
                          style={{ color: "#FF8682", fontSize: "18px" }}
                        />
                        <FaStar
                          style={{ color: "#FF8682", fontSize: "18px" }}
                        />
                        <FaStar
                          style={{ color: "#FF8682", fontSize: "18px" }}
                        />
                        <FaStar
                          style={{ color: "#FF8682", fontSize: "18px" }}
                        />
                        <FaStar
                          style={{ color: "#FF8682", fontSize: "18px" }}
                        />
                      </div>
                      <p class=" flex justify-start items-center w-[250px] font-medium  ml-[8px] sm:ml-[2px] text-[12px] mt-[0px] sm:mt-[2.5px] ">
                        5 Star Hotel
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-span-2 lg:col-span-1 flex flex-col ">
                  <div className="text-[#FF8682] md:text-right text-[12px] md:text-[14px] ">
                    <p className="text-[24px] font-bold">
                      {bookingDetail?.paymentAmount}
                      {/* <span className="text-[12px] h-1">/night</span> */}
                    </p>
                    <div className="flex gap-3 pt-3 text-[12px] md:text-[14px] flex-row w-[100%] sm:w-[90%]  md:w-[68%] lg:w-[70%] float-right xl:float-right ">
                      <div className="flex justify-end w-[100%] flex-col sm:flex-row gap-4 ">
                        <button
                          onClick={() =>
                            navigate("/settings/booking/accomodations")
                          }
                          className=" mt-[5px] sm:-[0px] hover:text-white hover:bg-blue-400 transition-colors duration-300 border border-blue-500 py-[8px] px-[18px] text-blue-500 rounded-md "
                        >
                          View My Booking
                        </button>
                        <button className=" mt-[5px] sm:-[0px] hover:text-white hover:bg-blue-400 transition-colors duration-300 border border-blue-500 py-[8px] px-[18px] flex justify-center items-center  text-black rounded-md ">
                          <IoShareSocialSharp />
                        </button>
                        <button
                          onClick={handleDownloadPDF}
                          className="mt-[5px] sm:-[0px] bg-blue-500 text-white px-[18px] py-[8px] rounded-md "
                        >
                          Download
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* //// */}
            </div>
          </div>
          {/* Ticket */}
          <div className="mt-[30px]">
            <HotelTickets downRef={divRef} bookingDetail={bookingDetail} />
          </div>
          {/* terms and conditions */}
          <TermsConditions />
          {/* contact us */}
          <Contactterms />
          {/* slider */}
          <TicketSlider />
        </div>
      ) : (
        <div className="min-h-[50vh]">
          <Loader />
        </div>
      )}
    </FlightLayout>
  );
};

export default HotelTerms;
