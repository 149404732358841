import React from "react";
import HistoryLayout from "../../layouts/historylayout";
import { Flight, FlightImg, LineCenter, Playicon, clander } from "../../assets";

const Pastflights = () => {
  return (
    <HistoryLayout>
      <div className="flex justify-between py-[15px] font-Montserrat">
        <p className="font-semibold sm:text-xl text-base">Past Flights Bookings </p>
        <p className="font-semibold sm:text-xl text-sm  "> 21 results</p>
      </div>
      <div className="bg-white grid md:grid-cols-2 grid-cols-1 rounded p-4 font-Montserrat">
        <div className="flex md:flex-row flex-col ">
          <div className="flex justify-center items-center mb-4 md:mb-0">
            <img className="md:h-16 h-[17rem]" src={FlightImg} alt="" />
          </div>
          <div className="flex items-center lg:gap-8 gap-0 justify-between md:ml-4">
            <div className="flex items-center">
              <img className="w-8 md:w-auto" src={Flight} alt="" />
              <div className="ml-2 ">
                <p className="text-[#808080] font-medium min-w-max">12:00 pm</p>
                <p className="font-bold">Chicago</p>
              </div>
            </div>
            <img className="mt-2" src={LineCenter} alt="" />
            <div className="">
              <p className="text-[#808080] font-medium">4:00 pm</p>
              <p className="font-bold">Maldives</p>
            </div>
          </div>
        </div>

        <div className="flex items-center mt-4 md:mt-0">
          <div className="mr-4">
            <img className="w-8 md:w-auto" src={clander} alt="" />
          </div>
          <div className="flex flex-col">
            <p className="text-[#808080] font-semibold">Date</p>
            <p className="font-bold">12/06/2023 - 28/06/2023</p>
          </div>
          <div className="flex-grow flex justify-end">
            <img src={Playicon} alt="" />
          </div>
        </div>
      </div>
    </HistoryLayout>
  );
};

export default Pastflights;
