import React from "react";
import {
  Avtar,
  Logo,
  SignupSlide1,
  facebookicon,
  googleicon,
  Thinking,
  Personal,
  Plane,
  Login2,
  verifyImg,
  Globalimg,
  Login1,
  Building,
} from "../../../assets";
import Inputs from "../../../components/common/inputs";
import AuthButton from "../../../components/common/Buttons";
import { Link, useNavigate } from "react-router-dom";
import { SlPencil } from "react-icons/sl";
import { Sliders } from "../../../components/common";
import { useState } from "react";
import { toast } from "react-toastify";
import httpRequest from "../../../axios";
import { SIGNUP_USER } from "../../../constants/apiEndPoints";
import { setemail } from "../../../lib/Redux/slices/userslice";
import { useDispatch } from "react-redux";
import { UploadAssests } from "../../../components/common/UploadAssests";

const SliderArr = [
  <div className="relative h-[700px] flex justify-center items-center">
    {/* Background Image with Blur */}
    <div
      className="absolute inset-0 bg-cover bg-center filter blur-[3px]"
      style={{
        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${Personal})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    ></div>

    {/* Content */}
    <div className="relative w-[90%] flex flex-col items-center space-y-6 font-Montserrat">
      <img className="h-auto w-[75%]" src={Plane} alt="Plane" />
      <div className="flex flex-col items-center space-y-3">
        <h1 className="text-white text-3xl font-black text-center">
          FLIGHT BOOKINGS
        </h1>
        <p className="text-white text-center text-xl font-semibold">
          GET YOUR FLIGHTS BOOKED WITH EASE WITHIN MYGUIDEON
        </p>
      </div>
    </div>
  </div>,
  <div className="relative sm:h-[700px] h-[600px] flex justify-center items-center">
    {/* Background Image with Blur */}
    <div
      className="absolute inset-0 bg-cover bg-center filter blur-[3px]"
      style={{
        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${verifyImg})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    ></div>

    {/* Content */}
    <div className="relative w-[90%] flex flex-col items-center space-y-6 font-Montserrat">
      <img className="h-auto w-[45%]" src={Globalimg} alt="Global" />
      <div className="flex flex-col items-center space-y-3">
        <h1 className="text-white text-center sm:text-3xl text-xl font-black">
          LIVE INTERACTIVE MAPS
        </h1>
        <p className="text-white text-center sm:text-xl text-base font-semibold">
          EXPLORE MAPS IN REALTIME AND PLAN WHAT YOU WANNA DO
        </p>
      </div>
    </div>
  </div>,

  <div className="relative sm:h-[700px] h-[600px] flex justify-center items-center">
    {/* Background Image with Blur */}
    <div
      className="absolute inset-0 bg-cover bg-center filter blur-[3px]"
      style={{
        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${Login1})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    ></div>

    {/* Content */}
    <div className="relative w-[90%] flex flex-col items-center space-y-6 font-Montserrat">
      <img className="h-auto w-[45%]" src={Building} alt="Building" />
      <div className="flex flex-col items-center space-y-3">
        <h1 className="text-white sm:text-3xl text-xl font-black">
          ACCOMODATIONS
        </h1>
        <p className="text-white text-center sm:text-xl text-base font-semibold">
          WORRIED ABOUT YOUR STAY? WE GOT THAT COVERED TOO. FIND THE BEST
          ACCOMMODATIONS AROUND YOU.
        </p>
      </div>
    </div>
  </div>,
];

const Signup = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [Loading, setLoading] = useState(false);
  const [Agree, setAgree] = useState(false);

  const [formData, setFormData] = useState({
    fullName: "",
    username: "",
    email: "",
    phoneNumber: "",
    profilepic: "",
    password: "",
    confirmPassword: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSignup = async () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (formData?.fullName === "") {
      toast.error("Please enter full name");
      return;
    }
    if (formData?.username === "") {
      toast.error("Please enter username");
      return;
    }
    if (formData?.email === "") {
      toast.error("Please enter email");
      return;
    }
    if (!emailRegex.test(formData.email)) {
      toast.error("Invalid email format");
      return;
    }
    if (formData?.password === "") {
      toast.error("Please enter password");
      return;
    }
    if (formData?.password.length < 8) {
      toast.error("Password must be at least 8 characters");
      return;
    }
    if (formData?.confirmPassword === "") {
      toast.error("Please enter confirm password");
      return;
    }

    if (formData?.password !== formData?.confirmPassword) {
      toast.error("Password do not match !");
      return;
    }
    if (!Agree) {
      toast.error("Terms and Privacy Policy must be accept");
      return;
    }
    try {
      setLoading(true);
      if (selectedFile != null) {
        const Image = await UploadAssests(selectedFile);
        formData.profilepic = Image;
      }
      const response = await httpRequest.post(SIGNUP_USER, formData);

      if (response.status === 200 || response.status === 201) {
        toast.success(response?.data?.message);
        dispatch(setemail(response?.data?.email));
        navigate("/verify-code");
      }
    } catch (error) {
      if (
        error?.response?.data?.message ===
        "Your account exists but is not yet verified. Please verify your account"
      ) {
        toast.error(error?.response?.data?.message);
        dispatch(setemail(error?.response?.data?.email));
        navigate("/verify-code");
      } else {
        toast.error(error?.response?.data?.message);
      }
    } finally {
      setLoading(false);
    }
  };

  // Upload Image

  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileChange = async (event) => {
    setSelectedFile(event.target.files[0]);
  };

  return (
    <div className="2xl:p-24 md:p-16 p-6 min-h-screen w-full grid xl:grid-cols-3 lg:grid-cols-2 grid-cols-1 2xl:gap-20 lg:gap-10 font-Montserrat">
      <div className="col-span-1 rounded-[30px] overflow-hidden lg:order-first order-last  mt-10">
        <Sliders items={SliderArr} />
      </div>
      <div className="2xl:col-span-2 col-span-1 space-y-6">
        <Link to={"/"}>
          <img src={Logo} alt="Logo.svg" />
        </Link>
        <div className="space-y-4">
          <h1 className="font-Montserrat font-bold sm:text-[40px] text-2xl">
            Sign up
          </h1>
          <p className="text-BlackishGreen font-normal sm:text-base text-sm leading-5"></p>
          Let’s get you all set up so you can access your personal account.
        </div>
        <div className="grid sm:grid-cols-2 gap-3">
          <div className="col-span-2 grid sm:grid-cols-3">
            <div className="col-span-2 space-y-3 sm:order-first order-last sm:mt-0 mt-5">
              <Inputs
                label={"Full Name"}
                sizelabel={"14px"}
                sizeinput={"16px"}
                type={"text"}
                color={"#1C1B1F"}
                fontweight={"400"}
                ActiveColor={"#1C1B1F"}
                htmlfor={"name"}
                name={"fullName"}
                onchange={handleInputChange}
                mendetory={true}
              />
              <Inputs
                label={"Username"}
                sizelabel={"14px"}
                sizeinput={"16px"}
                type={"text"}
                color={"#1C1B1F"}
                fontweight={"400"}
                ActiveColor={"#1C1B1F"}
                htmlfor={"username"}
                name={"username"}
                mendetory={true}
                onchange={handleInputChange}
              />
            </div>
            <div className="sm:col-span-1 col-span-2 flex sm:justify-end justify-center">
              <div className="flex sm:justify-end justify-center w-full">
                <div className="w-[120px] h-[120px] relative">
                  <div className="w-[120px] h-[120px] rounded-full overflow-hidden">
                    {selectedFile ? (
                      <img
                        className="h-auto w-full"
                        src={URL.createObjectURL(selectedFile)}
                        alt="Selected Avatar"
                      />
                    ) : (
                      <img
                        className="h-auto w-full"
                        src={Avtar}
                        alt="Default Avatar"
                      />
                    )}
                  </div>
                  <label
                    htmlFor="avatar-upload"
                    className="h-[35px] w-[35px] cursor-pointer absolute rounded-full right-0 bottom-0 bg-primary flex items-center justify-center"
                  >
                    {/* Icon for file upload */}
                    <SlPencil className="text-white text-lg" />
                  </label>
                  {/* Hidden file input */}
                  <input
                    id="avatar-upload"
                    type="file"
                    accept="image/*"
                    className="hidden"
                    onChange={handleFileChange}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="md:col-span-1  col-span-2">
            <Inputs
              label={"Email"}
              sizelabel={"14px"}
              sizeinput={"16px"}
              type={"eamil"}
              color={"#1C1B1F"}
              fontweight={"400"}
              ActiveColor={"#1C1B1F"}
              htmlfor={"email"}
              name={"email"}
              onchange={handleInputChange}
              mendetory={true}
            />
          </div>
          <div className="md:col-span-1  col-span-2">
            <Inputs
              label={"Phone Number"}
              sizelabel={"14px"}
              sizeinput={"16px"}
              type={"number"}
              color={"#1C1B1F"}
              fontweight={"400"}
              ActiveColor={"#1C1B1F"}
              htmlfor={"number"}
              name={"phoneNumber"}
              onchange={handleInputChange}
            />
          </div>
          <div className="sm:col-span-1  col-span-2">
            <Inputs
              label={"Password"}
              sizelabel={"14px"}
              sizeinput={"16px"}
              type={"password"}
              color={"#1C1B1F"}
              fontweight={"400"}
              ActiveColor={"#1C1B1F"}
              htmlfor={"password"}
              name={"password"}
              onchange={handleInputChange}
              mendetory={true}
            />
          </div>
          <div className="sm:col-span-1  col-span-2">
            <Inputs
              label={"Confirm Password"}
              sizelabel={"14px"}
              sizeinput={"16px"}
              type={"password"}
              color={"#1C1B1F"}
              fontweight={"400"}
              ActiveColor={"#1C1B1F"}
              htmlfor={"confirmpassword"}
              name={"confirmPassword"}
              onchange={handleInputChange}
              mendetory={true}
            />
          </div>
        </div>
        <div className="col-span-3 flex space-x-5">
          <input
            onClick={() => setAgree(!Agree)}
            className="border accent-BlackishGreen"
            type="checkbox"
          />
          <span className="text-BlackishGreen text-sm leading-4 font-medium">
            I agree to all the{" "}
            <span className="text-[#FF8682] font-semibold cursor-pointer">
              Terms
            </span>{" "}
            and{" "}
            <span className="text-[#FF8682] font-semibold cursor-pointer">
              privacy Policies <sup className="text-red-600"> *</sup>
            </span>
          </span>
        </div>
        <div className="col-span-3 space-y-4">
          <AuthButton
            text={"Create account"}
            backColor={"#007BFF"}
            color={"#FFFFFF"}
            hoverback={"#007BFF"}
            onclick={handleSignup}
            loading={Loading}
          />
          <p className="text-BlackishGreen sm:text-sm text-xs leading-4 font-medium text-center">
            Already have an account?{" "}
            <Link
              className="text-[#FF8682] font-semibold cursor-pointer"
              to={"/login"}
            >
              Login
            </Link>
          </p>
        </div>
        <div className="relative py-4 col-span-2">
          <div className="w-full relative border-b-[2px] flex justify-center items-center">
            <span className="absolute bg-white px-4">Or Sign up with</span>
          </div>
        </div>
        <div className="col-span-2 grid sm:grid-cols-2 gap-5">
          <div className=" border border-[#8DD3BB] flex items-center justify-center rounded-lg p-[10px] cursor-pointer hover:bg-[#8DD3BB]">
            <img src={facebookicon} alt="facebook.svg" />
          </div>
          <div className=" border border-[#8DD3BB] flex items-center justify-center rounded-lg p-[10px] cursor-pointer hover:bg-[#8DD3BB]">
            <img src={googleicon} alt="facebook.svg" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Signup;
