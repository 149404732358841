import React, { useEffect, useState } from "react";
import FlightLayout from "../../../layouts/FlightLayout";
import { BreadcrumbArrow } from "../../../basecomponents";
import { useDispatch, useSelector } from "react-redux";
import { setpaths } from "../../../lib/Redux/slices/webData";
import { IoLocation, IoShareSocial } from "react-icons/io5";
import { CiHeart } from "react-icons/ci";
import { StopWatch } from "../../../assets";
import { FlightDetailsCard } from "../../../components";
import airports from "airport-codes";
import { useNavigate } from "react-router-dom";
import { setAllFlightsBasicPrices } from "../../../lib/Redux/slices/cartslice";
import { COMMISSION } from "../../../constants/apiEndPoints";
import httpRequest from "../../../axios";
import { toast } from "react-toastify";

const headers = [
  { label: "MyGuide", url: "/user-profile" },
  { label: "My World", url: "/" },
  { label: "Transport Booking", url: "/flights/booking" },
];


const BookDetails1 = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  dispatch(setpaths(headers));
  const [location, setLocation] = useState(undefined);
  const { paths = [] } = useSelector((state) => state.web);

  const selectedFlight = useSelector((state) => state.flight.selectedFlight);
  const { paymentSuccess } = useSelector((state) => state.flight);

  useEffect(() => {
    if (paymentSuccess) {
      navigate("/flights");
    }
  }, [paymentSuccess]);
  const dictionaries = useSelector((state) => state.flight.dictionaries);

  const getLocations = (iatacode) => {
    const flights = airports.findWhere({
      iata: iatacode,
    });
    const data = flights.toJSON();
    const foundLocations = `${data.name}, ${data.city}, ${data.country}`;
    return foundLocations;
  };

  useEffect(() => {
    const findLocation = getLocations(
      selectedFlight.itineraries[0].segments[0].departure.iataCode
    );
    setLocation(findLocation);
    return () => {};
  }, []);

  const HandleAddExtra = () => {
    const items = [
      {
        type: null,
        title: "Base Fare",
        price: selectedFlight?.price?.total,
        qty: 1,
        id: selectedFlight.id,
      },
      {
        type: null,
        title: "Service Fee",
        price:
          (selectedFlight?.price?.total / 100) *
          getCommissions?.flightCommission,
        qty: 1,
        id: 236,
      },
    ];

    dispatch(setAllFlightsBasicPrices(items));
  };

  const [getCommissions, setGetCommissions] = useState([]);

  const FetchCommissions = async () => {
    try {
      const Response = await httpRequest.get(COMMISSION);

      if (Response.status === 200 || Response.status === 201) {
        setGetCommissions(Response?.data);
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  };

  useEffect(() => {
    FetchCommissions();
  }, []);

  return (
    <FlightLayout>
      <div className="py-3 font-Montserrat">
        <BreadcrumbArrow items={paths} />
      </div>
      <div className="py-3">
        <div className="w-full flex justify-between flex-wrap sm:space-y-0 space-y-3">
          <div className=" flex flex-col gap-3">
            <h1 className="text-BlackishGreen sm:text-2xl text-base font-bold leading-normal">
              {dictionaries?.carriers[selectedFlight.validatingAirlineCodes[0]]}{" "}
              {
                dictionaries?.aircraft[
                  selectedFlight.itineraries[0]?.segments[0]?.aircraft?.code
                ]
              }
            </h1>
            <p className="text-BlackishGreen sm:text-sm text-xs font-medium flex gap-1">
              <IoLocation size={18} /> <span>{location}</span>
            </p>
          </div>
          <div className="flex flex-row sm:flex-col gap-4 sm:items-end justify-between items-center sm:w-max w-full">
            <h1 className="sm:text-3xl text-xl text-end font-bold leading-normal text-[#FF8682]">
              ${Math.round(selectedFlight?.price?.grandTotal)}
            </h1>
            <div className="flex gap-4">
              <div className="h-[48px] w-[48px] border border-[#8DD3BB] rounded cursor-pointer flex items-center justify-center">
                <CiHeart size={20} />
              </div>
              <div className="h-[48px] w-[48px] border border-[#8DD3BB] rounded cursor-pointer flex items-center justify-center">
                <IoShareSocial size={20} />
              </div>
              <button
                onClick={() => {
                  navigate("/flights/booking/cart");
                  HandleAddExtra();
                }}
                className="bg-primary text-BlackishGreen rounded sm:px-9 px-3 py-2 hover:bg-blue-800 hover:text-white"
              >
                Book now
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="py-4">
        <div className="w-full rounded overflow-hidden">
          <img
            className="w-full max-h-[80vh]"
            src={
              "https://static.ffx.io/images/$zoom_1%2C$multiply_0.5141%2C$ratio_1.777778%2C$width_1992%2C$x_0%2C$y_208/t_crop_custom/q_86%2Cf_auto/74e96500a9e08cd4166a1416e5b41eecc2ecd518"
            }
            alt="EmertiesPlane.svg"
          />
        </div>
      </div>
      <div className="pb-4">
        <div
          style={{ background: "rgba(0, 123, 255, 0.50)" }}
          className="p-4 my-8 rounded space-y-2"
        >
          <h1 className="text-[#121] sm:text-2xl text-xl font-bold leading-normal capitalize">
            {dictionaries?.carriers[selectedFlight.validatingAirlineCodes[0]]}{" "}
            Airlines Policies
          </h1>
          <div className="grid md:grid-cols-2 grid-cols-1 gap-4">
            <div className="flex space-x-2">
              <img src={StopWatch} alt="StopWatch.svg" />
              <span className="sm:text-base text-sm font-medium leading-normal">
                Pre-flight cleaning, installation of cabin HEPA filters.
              </span>
            </div>
            <div className="flex space-x-2">
              <img src={StopWatch} alt="StopWatch.svg" />
              <span className="sm:text-base text-sm font-medium leading-normal">
                Pre-flight cleaning, installation of cabin HEPA filters.
              </span>
            </div>
          </div>
        </div>
        <div>
          {selectedFlight.itineraries.map((item, index) =>
            item?.segments.map((segment) => (
              <FlightDetailsCard
                key={segment.id}
                flight={index == 0 ? "Origin" : "Return"}
                data={segment}
              />
            ))
          )}
        </div>
      </div>
    </FlightLayout>
  );
};

export default BookDetails1;
