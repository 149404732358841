import React, { useState } from "react";
import FavoritesLayout from "../../layouts/FavoritesLayout";
import { useDispatch } from "react-redux";
import {
  setheader,
  setpaths,
  setSidebarimg,
} from "../../lib/Redux/slices/webData";
import { Hotel, Location, Ratting, Cup, AccomodationS } from "../../assets";
import { InProgressComponent } from "../../components";

const paths = [
  { label: "MyGuide", url: "/user-profile" },
  { label: "My Odyssey", url: "/" },
  { label: "Settings", url: "/settings" },
  { label: "Bookings", url: "/settings/booking/flights" },
];

const FavroutesActivites = () => {
  const dispatch = useDispatch();
  dispatch(setpaths(paths));
  dispatch(setheader("Bookings"));
  dispatch(setSidebarimg(AccomodationS));

  return (
    <FavoritesLayout>
      <div className="w-full font-Montserrat">
        <h1 className="text-red-500 p-10">Not in this Milestone</h1>
      </div>
    </FavoritesLayout>
  );
};

export default FavroutesActivites;
