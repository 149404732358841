import React from "react";
import HistoryLayout from "../../layouts/historylayout";
import { Diving, Istanbul } from "../../assets";

const Activities = () => {
  return (
    <HistoryLayout>
      <div className="py-[15px] font-Montserrat">
        <p className="font-semibold sm:text-xl text-base text-[20px]">
          Favorite Activities
        </p>
      </div>

      <div className="grid md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-4 grid-cols-1 rounded font-Montserrat relative gap-3">
        <div className="font-Montserrat relative mb-3 w-full flex justify-center items-center z-10">
          <img className="h-auto w-full" src={Diving} alt="Diving" />
            <p className="text-[20px] font-semibold text-white absolute ">Scuba Diving</p>
        </div>
      </div>

      <div className="py-[15px] font-Montserrat">
        <p className="font-semibold sm:text-xl text-base text-[20px]">
          Favorite Destinations{" "}
        </p>
      </div>

      <div className="grid md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-4 grid-cols-1 rounded font-Montserrat relative gap-3">
        <div className="font-Montserrat relative mb-3 w-full flex justify-center items-center z-10">
          <img className="h-auto w-full" src={Istanbul} alt="Diving" />
          <p className="text-[20px] font-semibold text-white absolute z-10">Istanbul</p>
        </div>

        <div className="font-Montserrat relative mb-3 w-full flex justify-center items-center z-10">
          <img className="h-auto w-full" src={Istanbul} alt="Diving" />
          <p className="text-[20px] font-semibold text-white absolute z-10">Istanbul</p>
        </div>

        <div className="font-Montserrat relative mb-3 w-full flex justify-center items-center z-10">
          <img className="h-auto w-full" src={Istanbul} alt="Diving" />
          <p className="text-[20px] font-semibold text-white absolute z-10">Istanbul</p>
        </div>

        <div className="font-Montserrat relative mb-3 w-full flex justify-center items-center z-10">
          <img className="h-auto w-full" src={Istanbul} alt="Diving" />
          <p className="text-[20px] font-semibold text-white absolute z-10">Istanbul</p>
        </div>

        <div className="font-Montserrat relative mb-3 w-full flex justify-center items-center z-10">
          <img className="h-auto w-full" src={Istanbul} alt="Diving" />
          <p className="text-[20px] font-semibold text-white absolute z-10">Istanbul</p>
        </div>


        <div className="font-Montserrat relative mb-3 w-full flex justify-center items-center z-10">
          <img className="h-auto w-full" src={Istanbul} alt="Diving" />
          <p className="text-[20px] font-semibold text-white absolute z-10">Istanbul</p>
        </div>

        <div className="font-Montserrat relative mb-3 w-full flex justify-center items-center z-10">
          <img className="h-auto w-full" src={Istanbul} alt="Diving" />
          <p className="text-[20px] font-semibold text-white absolute z-10">Istanbul</p>
        </div>

        <div className="font-Montserrat relative mb-3 w-full flex justify-center items-center z-10">
          <img className="h-auto w-full" src={Istanbul} alt="Diving" />
          <p className="text-[20px] font-semibold text-white absolute z-10">Istanbul</p>
        </div>
      </div>
    </HistoryLayout>
  );
};

export default Activities;
