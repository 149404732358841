import React, { useState, useMemo } from "react";
import { RxCross2 } from "react-icons/rx";
import Select from "react-select";
import countryList from "react-select-country-list";
import httpRequest from "../../axios";
import { Loader } from "../common";
import { CARDS } from "../../constants/apiEndPoints";
  import { toast } from 'react-toastify';
const AddCards = ({ onclick = () => {}, FetchCards = () => {} }) => {
  const [loading, setLoading] = useState(false);
  const [selectCountry, setselectCountry] = useState("");
  const Countries = useMemo(() => countryList().getData(), []);
  const [cardNumber, setCardNumber] = useState("");
  const [cardCvv, setCardCvv] = useState("");
  const [cardName, setCardName] = useState("");
  const [expiryDate, setExpiryDate] = useState("");

  const [expiryMonth, setExpiryMonth] = useState("");
  const [expiryYear, setExpiryYear] = useState("");

  const handleExpiryDateChange = (e) => {
    const dateValue = e.target.value;
    setExpiryDate(dateValue);

    const parsedDate = new Date(dateValue);
    const month = parsedDate.getMonth() + 1;
    const year = parsedDate.getFullYear();

    setExpiryMonth(month.toString().padStart(2, "0"));
    setExpiryYear(year.toString());
  };

  const addCards = async () => {
    if (!cardName || !cardNumber || !selectCountry) {
      toast.error("Please fill all the fields");
      return;
    }
    try {
      setLoading(true);
      const response = await httpRequest.post(CARDS, {
        cardHolderName: cardName,
        cardNumber: cardNumber,
        expiryYear: expiryYear,
        expiryMonth: expiryMonth,
      });
      if ((response.status = 200)) {
        toast.success("Card added successfully");
        onclick(false);
        FetchCards();
      }
      setLoading(false);
    } catch (err) {
      console.log(err);
      toast.error(err?.response?.data?.message);
      setLoading(false);
    }
  };

  return (
    <div className="px-6 py-8 bg-white font-Montserrat relative xl:w-1/3 md:w-1/2 sm:w-3/4 w-[98%] rounded-md ">
      <div className="flex justify-end">
        <RxCross2 className="cursor-pointer" onClick={() => onclick(false)} />
      </div>
      <h1 className="text-2xl font-bold leading-normal">Add a new Card</h1>
      <div className="grid grid-cols-2 py-6 gap-4">
        <div
          style={{ padding: "4px 0px 4px 16px;" }}
          class="relative w-full h-12 col-span-2"
        >
          <input
            type="number"
            className="peer w-full h-full bg-transparent text-[#1C1B1F] font-sans font-normal outline outline-0 focus:outline-0 disabled:bg-blue-gray-50 disabled:border-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 border focus:border-2 border-t-transparent focus:border-t-transparent text-sm px-3 py-2.5 rounded-[7px] border-blue-gray-200 focus:border-gray-900"
            placeholder=" "
            name="cardNumber"
            value={cardNumber}
            onChange={(e) => setCardNumber(e.target.value)}
          />
          <label className="flex w-full h-full select-none pointer-events-none absolute left-0 font-normal !overflow-visible truncate peer-placeholder-shown:text-BlackishGreen leading-tight peer-focus:leading-tight peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-BlackishGreen transition-all -top-1.5 peer-placeholder-shown:text-sm text-[11px] peer-focus:text-[11px] before:content[' '] before:block before:box-border before:w-2.5 before:h-1.5 before:mt-[6.5px] before:mr-1 peer-placeholder-shown:before:border-transparent before:rounded-tl-md before:border-t peer-focus:before:border-t-2 before:border-l peer-focus:before:border-l-2 before:pointer-events-none before:transition-all peer-disabled:before:border-transparent after:content[' '] after:block after:flex-grow after:box-border after:w-2.5 after:h-1.5 after:mt-[6.5px] after:ml-1 peer-placeholder-shown:after:border-transparent after:rounded-tr-md after:border-t peer-focus:after:border-t-2 after:border-r peer-focus:after:border-r-2 after:pointer-events-none after:transition-all peer-disabled:after:border-transparent peer-placeholder-shown:leading-[4.2] blatext-BlackishGreen peer-focus:text-gray-900 before:border-blue-gray-200 peer-focus:before:!border-gray-900 after:border-blue-gray-200 peer-focus:after:!border-gray-900">
            Card Number
          </label>
        </div>
        <div
          style={{ padding: "4px 0px 4px 16px;" }}
          class="relative w-full h-12 col-span-2"
        >
          <input
            type="date"
            className="peer w-full h-full bg-transparent text-[#1C1B1F] font-sans font-normal outline outline-0 focus:outline-0 disabled:bg-blue-gray-50 disabled:border-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 border focus:border-2 border-t-transparent focus:border-t-transparent text-sm px-3 py-2.5 rounded-[7px] border-blue-gray-200 focus:border-gray-900"
            placeholder=" "
            name="expiryDate"
            value={expiryDate}
            onChange={handleExpiryDateChange}
            min={new Date().toISOString().split("T")[0]}
          />
          <label className="flex w-full h-full select-none pointer-events-none absolute left-0 font-normal !overflow-visible truncate peer-placeholder-shown:text-BlackishGreen leading-tight peer-focus:leading-tight peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-BlackishGreen transition-all -top-1.5 peer-placeholder-shown:text-sm text-[11px] peer-focus:text-[11px] before:content[' '] before:block before:box-border before:w-2.5 before:h-1.5 before:mt-[6.5px] before:mr-1 peer-placeholder-shown:before:border-transparent before:rounded-tl-md before:border-t peer-focus:before:border-t-2 before:border-l peer-focus:before:border-l-2 before:pointer-events-none before:transition-all peer-disabled:before:border-transparent after:content[' '] after:block after:flex-grow after:box-border after:w-2.5 after:h-1.5 after:mt-[6.5px] after:ml-1 peer-placeholder-shown:after:border-transparent after:rounded-tr-md after:border-t peer-focus:after:border-t-2 after:border-r peer-focus:after:border-r-2 after:pointer-events-none after:transition-all peer-disabled:after:border-transparent peer-placeholder-shown:leading-[4.2] blatext-BlackishGreen peer-focus:text-gray-900 before:border-blue-gray-200 peer-focus:before:!border-gray-900 after:border-blue-gray-200 peer-focus:after:!border-gray-900">
            Expiry Date
          </label>
        </div>
        <div
          style={{ padding: "4px 0px 4px 16px;" }}
          class="relative w-full h-12 col-span-2"
        >
          <input
            type="text"
            className="peer w-full h-full bg-transparent text-[#1C1B1F] font-sans font-normal outline outline-0 focus:outline-0 disabled:bg-blue-gray-50 disabled:border-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 border focus:border-2 border-t-transparent focus:border-t-transparent text-sm px-3 py-2.5 rounded-[7px] border-blue-gray-200 focus:border-gray-900"
            placeholder=" "
            name="Name"
            value={cardName}
            onChange={(e) => setCardName(e.target.value)}
          />
          <label className="flex w-full h-full select-none pointer-events-none absolute left-0 font-normal !overflow-visible truncate peer-placeholder-shown:text-BlackishGreen leading-tight peer-focus:leading-tight peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-BlackishGreen transition-all -top-1.5 peer-placeholder-shown:text-sm text-[11px] peer-focus:text-[11px] before:content[' '] before:block before:box-border before:w-2.5 before:h-1.5 before:mt-[6.5px] before:mr-1 peer-placeholder-shown:before:border-transparent before:rounded-tl-md before:border-t peer-focus:before:border-t-2 before:border-l peer-focus:before:border-l-2 before:pointer-events-none before:transition-all peer-disabled:before:border-transparent after:content[' '] after:block after:flex-grow after:box-border after:w-2.5 after:h-1.5 after:mt-[6.5px] after:ml-1 peer-placeholder-shown:after:border-transparent after:rounded-tr-md after:border-t peer-focus:after:border-t-2 after:border-r peer-focus:after:border-r-2 after:pointer-events-none after:transition-all peer-disabled:after:border-transparent peer-placeholder-shown:leading-[4.2] blatext-BlackishGreen peer-focus:text-gray-900 before:border-blue-gray-200 peer-focus:before:!border-gray-900 after:border-blue-gray-200 peer-focus:after:!border-gray-900">
            Name on Card
          </label>
        </div>
        <div className="relative col-span-2">
          <Select
            value={selectCountry}
            className="PersonalDeatilsFlight"
            onChange={(selectedOption) => setselectCountry(selectedOption)}
            options={Countries}
          />
          <label className="absolute -top-2 left-3 text-xs font-normal text-BlackishGreen bg-white px-1">
            Country of Issue
          </label>
        </div>
        <p className="col-span-2 flex items-center space-x-2">
          <input type="checkbox" className="accent-black" />
          <span className="sm:text-sm text-[10px] font-medium leading-normal">
            Securely save my information for 1-click checkout
          </span>
        </p>
        <div className="col-span-2 space-y-2">
          <button
            style={{ padding: "8px 16px" }}
            onClick={addCards}
            disabled={loading}
            // onClick={() => navigate("/flights/booking")}
            className="w-full h-[48px] bg-primary rounded-md hover:bg-blue-800 text-white text-sm font-semibold "
          >
            {loading ? <Loader /> : "Add Card"}
          </button>
          <p className="text-BlackishGreen sm:text-xs text-[10px] font-normal leading-normal text-center">
            By confirming your subscription, you allow The Guidone to charge
            your card for this payment and future payments in accordance with
            their terms. You can always cancel your subscription.
          </p>
        </div>
      </div>
    </div>
  );
};

export default AddCards;
