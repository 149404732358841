import React from 'react'
import { Navbar } from '../components'
import Footer from '../components/Footer'

const HostelbookingLayout = ({children}) => {

  const textOffooter={text:'Subscirbe to our Newsletter',button:'Subscirbe'};

  return (
    <>
      <Navbar/>
      {children}
      <Footer text={textOffooter.text} buttonText={textOffooter.button} />
    </>
  )
}

export default HostelbookingLayout
