import React from "react";
import Mainlayout from "./Mainlayout";
import { Link, useLocation } from "react-router-dom";

const Tabs = [
  {
    title: "Flights",
    link: "/user-profile",
  },
  {
    title: "Activites",
    link: "/user/Activity",
  },
  {
    title: "Accomonadation",
    link: "/user/Accomondation",
  },
];

const MainDataLayout = ({ children }) => {
  const location = useLocation();

  return (
    <div className="space-y-3">
      <div className="w-full bg-[#EFEFEF] p-2 overflow-hidden grid sm:grid-cols-3 grid-cols-1 gap-4 rounded-xl font-Montserrat ShadowMain">
        {Tabs &&
          Tabs.map((tab, index) => {
            return (
              <Link
                key={index}
                to={tab?.link}
                className={`border-b-[3px] rounded-xl ${
                  location.pathname === tab?.link ? "bg-white" : ""
                } `}
              >
                <div className="text-center p-5 ">
                  <p
                    className={` ${
                      location.pathname === tab?.link
                        ? "text-primary"
                        : "opacity-50 text-textcolor"
                    } text-sm  font-semibold leading-normal`}
                  >
                    {tab?.title}
                  </p>
                </div>
              </Link>
            );
          })}
      </div>
      <div className="w-full sm:p-4">{children}</div>
    </div>
  );
};

export default MainDataLayout;
