import React, { useEffect, useRef, useState } from "react";
import { Navbar, Sidear, Upcomming } from "../components";
import { Header } from "../basecomponents";
import Breadcrumb from "../basecomponents/Breadcrumb";
import { useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";

const FavoritesLayout = ({ children }) => {
  const { user } = useSelector((state) => state.user);
  const {
    header = "",
    paths = [],
    HotelBooked = 0,
    FlightBooked = 0,
  } = useSelector((state) => state.web);
  const location = useLocation();
  const [UpCoimming, setUpCommig] = useState(false);
  const upcomingRef = useRef(null);
  const navigate = useNavigate();

  const Tabs = [
    {
      title: "Flights",
      link: "/settings/favroutes/flights",
      total: FlightBooked,
    },
    {
      title: "Activities",
      link: "/settings/favroutes/activities",
      total: 0,
    },
    {
      title: "Accomodations",
      link: "/settings/favroutes/accomodations",
      total: HotelBooked,
    },
  ];

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 1024) {
        setUpCommig(false);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleBackgroundClick = () => {
    setUpCommig(false);
  };

  const handleUpcomingClick = (e) => {
    e.stopPropagation();
  };

  const [Catagory, setCatagory] = useState("Flights");

  return (
    <div>
      <Navbar />
      <div className="grid grid-cols-12 w-full m-auto relative">
        <div className="col-span-2 sticky left-0 top-0 lg:block hidden h-screen">
          <Sidear />
        </div>
        <div className="lg:col-span-10 col-span-12 grid grid-cols-8 gap-4 px-4 py-8 h-max relative">
          {/* //// Header Part  */}
          <div className="lg:col-span-8 col-span-12 space-y-3 grid grid-cols-8">
            <div className="lg:col-span-6 space-y-1 sm:col-span-3 col-span-12 pb-4">
              <h1>{<Header header={header} />}</h1>
              <div className="w-full">
                <Breadcrumb items={paths} />
              </div>
            </div>
          </div>
          {/* //// Header Part End  */}
          <div className=" col-span-12">
            <div
              // style={{ padding: "" }}
              className="grid sm:grid-cols-3 gap-4 grid-cols-1 shadow-sm bg-white sm:p-[16px_24px]"
            >
              {Tabs &&
                Tabs.map((tab, index) => {
                  return (
                    <div className="col-span-1 sm:border-b-0 border-b cursor-pointer shadow">
                      <div
                        onClick={() => navigate(tab?.link)}
                        className={` border-b-4 ${
                          location.pathname === tab?.link
                            ? "border-[#8DD3BB]"
                            : "border-transparent"
                        } sm:pb-3 py-3`}
                      >
                        <h1 className="text-BlackishGreen text-base font-semibold px-2 leading-normal">
                          {tab?.title}
                        </h1>
                        <span className="font-normal px-2 text-xs">
                          {tab?.total} Booked
                        </span>{" "}
                        <p className="text-BlackishGreen text-sm font-normal leading-normal  opacity-40"></p>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
          <div className="col-span-12">{children}</div>
        </div>
      </div>
    </div>
  );
};

export default FavoritesLayout;
