import React from "react";
import { Link } from "react-router-dom";

const Breadcrumb = ({ items }) => {
  return (
    <nav aria-label="breadcrumb">
      <ol className="flex items-center space-x-2 sm:text-sm text-[10px] flex-wrap">
        {items.map((item, index) => (
          <li
            key={index}
            className={`flex items-center cursor-pointer ${
              index === items.length - 1 ? "text-blue-500" : "text-gray-500"
            }`}
          >
            <Link to={item?.url}>
              <span className="mr-1">{item.label}</span>
            </Link>
            {index !== items.length - 1 && (
              <span className="text-gray-500">/</span>
            )}
          </li>
        ))}
      </ol>
    </nav>
  );
};

export default Breadcrumb;
