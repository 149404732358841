import {
  MessageIcon,
  WorldIcon,
  airplaneAround,
  TravelIcon,
  messageblue,
  MyGuidblue,
} from "../../assets";

const Links = [
  {
    id: 1,
    title: "MyGuide",
    icon: TravelIcon,
    link: "/user-profile",
    active: MyGuidblue,
  },
  {
    id: 2,
    title: "My World",
    icon: WorldIcon,
    link: "/",
    active: airplaneAround,
  },
  {
    id: 3,
    title: "Messages",
    icon: MessageIcon,
    link: "/messages",
    active: messageblue,
  },
];

export { Links };
