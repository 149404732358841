import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

export const PublicRoute = ({ children, type = "user" }) => {
  const { isAuthenticated = false } = useSelector((state) => state.user);
  const { PrevPathActive } = useSelector((state) => state.web);

  const path = localStorage.getItem("Path");

  if (isAuthenticated && type === "user" && !PrevPathActive) {
    return <Navigate to="/" replace={true} />;
  }
  if (path !== null && isAuthenticated) {
    return <Navigate to={`${path}`} replace={true} />;
  }
  return children;
};
