import {
  Heart,
  Information,
  PictureTag,
  Sheild,
  WellClock,
} from "../../assets";

const SettingsLinks = [
  {
    img: "https://icons.veryicon.com/png/o/leisure/crisp-app-icon-library-v3/booking-history.png",
    title: "My Bookings",
    link: "/settings/booking/flights",
  },
  {
    img: Heart,
    title: "My Favorites",
    link: "/settings/favroutes/flights",
  },
  {
    img: Information,
    title: "Personal Information",
    link: "/personal-info",
  },
  {
    img: Sheild,
    title: "Security & Privacy",
    link: "/security-privacy",
  },
  {
    img: PictureTag,
    title: "Payment & Vouchers",
    link: "/payment-vouchers",
  },
  {
    img: WellClock,
    title: "Odyssey History",
    link: "pastflights",
  },
];

export { SettingsLinks };
