import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setpaths } from "../../lib/Redux/slices/webData";
import { Navbar } from "../../components";
import {
  ClassisGoal,
  GuideGlobe,
  Mask,
  classicExplore1,
  classicExplore2,
  upclasic,
} from "../../assets";
import { Link, useNavigate } from "react-router-dom";
import { FaStar } from "react-icons/fa6";
import Breadcrumb from "../../basecomponents/Breadcrumb";
import { GoArrowLeft, GoArrowRight } from "react-icons/go";
import Slider from "react-slick";
import httpRequest from "../../axios";
import { Booking, GetAllDestinations } from "../../constants/apiEndPoints";
import { toast } from "react-toastify";

const headers = [
  { label: "Home", url: "#" },
  { label: "My World", url: "/" },
];

function SampleNextArrow(props) {
  const { style, onClick } = props;
  return (
    <div
      className="next-button "
      style={{
        ...style,
      }}
      onClick={onClick}
    >
      <GoArrowRight className="text-white text-xl" />
    </div>
  );
}

function SamplePrevArrow(props) {
  const { style, onClick } = props;
  return (
    <div
      className="prev-button"
      style={{
        ...style,
      }}
      onClick={onClick}
    >
      <GoArrowLeft className="text-white text-xl" />
    </div>
  );
}

const ClasicMap = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [AllDestination, setAllDestination] = useState([]);
  const [loading, setLoading] = useState(false);
  const { paths = [] } = useSelector((state) => state.web);
  const [Currentdestype, setCurrentdestype] = useState("destination");
  dispatch(setpaths(headers));

  const FetchDestination = async () => {
    setLoading(true);
    try {
      const response = await httpRequest.get(`${GetAllDestinations}`);

      if (response.status === 200 || response.status === 201) {
        setAllDestination(response?.data);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      return toast.error(error?.response?.data?.message);
    }
  };

  useEffect(() => {
    FetchDestination();
  }, []);

  const [Data, setData] = useState([]);

  const GetData = async () => {
    setLoading(true);
    try {
      const response = await httpRequest.get(`${Booking}/hotel`);
      if (response.status === 200 || response.status === 201) {
        setData(response?.data);
        setLoading(false);
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    GetData();
  }, []);

  const settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 10000,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    initialSlide: 0,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const Bookings = [
    {
      img: Mask,
      title: "Find the best and affordable holiday packages for Baku.",
      link: "Click here to learn more",
    },
    {
      img: Mask,
      title: "Find the best and affordable holiday packages for Baku.",
      link: "Click here to learn more",
    },
    {
      img: Mask,
      title: "Find the best and affordable holiday packages for Baku.",
      link: "Click here to learn more",
    },
  ];

  const Cardrender = AllDestination?.map((items, index) => {
    return (
      <div key={index} className="cursor-pointer p-4">
        <div className="w-[100%] h-40 rounded-xl overflow-hidden">
          <img
            className="object-cover w-[100%] h-[100%]"
            src={items?.images?.[0]}
            alt=""
          />
        </div>
        <div className="p-3">
          <div className="flex justify-between items-start">
            <h1>{items?.name}</h1>
            <p className="flex items-center space-x-1">
              <span>
                <FaStar />
              </span>
              <span className="text-[#222] font-Montserrat text-sm font-normal leading-normal">
                {items?.averageRating}
              </span>
            </p>
          </div>
          <div>
            <p>
              <span className="text-[#222] text-sm font-medium leading-normal">
                {items?.budget}
              </span>{" "}
              <span>.</span>{" "}
              <span className="text-[#5E5E5E] text-sm font-normal leading-normal">
                Estimated Trip Cost
              </span>
            </p>
          </div>
        </div>
      </div>
    );
  });

  return (
    <>
      <div className="bg-[#F8F8F8] min-h-screen relative">
        <Navbar />
        <div className="px-10">
          <div className="flex sm:justify-between sm:flex-row flex-col items-center py-4">
            <div className="py-3 font-Montserrat">
              <h1 className="text-black text-[28px] leading-normal font-semibold">
                My World
              </h1>
              <Breadcrumb items={paths} />
            </div>

            <div
              onClick={() => navigate("/")}
              className="border relative cursor-pointer border-[#89C947] rounded-md bg-white w-max py-3 px-5 flex items-center"
            >
              <img
                className="h-[100px] w-[100px] absolute left-2"
                src={GuideGlobe}
                alt="GuideGloble.svg"
              />
              <h1 className="text-black text-lg pl-24 font-semibold leading-normal">
                Switch to Guide Map
              </h1>
            </div>
          </div>
          <div className="relative bg-white rounded-lg p-[30px] mt-[50px] shadow-lg">
            <div className="w-full flex">
              <div className="flex sm:flex-row flex-col bg-[#EAEAEA] rounded-tl-2xl rounded-br-3xl">
                <div
                  onClick={() => setCurrentdestype("destination")}
                  className={`${
                    Currentdestype === "destination"
                      ? "bg-[#E3EFFD]"
                      : "bg-[#EAEAEA]"
                  }  px-6 py-2 rounded-tl-2xl rounded-br-3xl flex items-center space-x-3 cursor-pointer`}
                >
                  <img src={classicExplore1} alt="classicExplore1.svg" />
                  <h1 className="text-[#394149] sm:text-[22px] font-semibold leading-normal">
                    Explore Destinations
                  </h1>
                </div>
                <div
                  onClick={() => setCurrentdestype("activity")}
                  className={`${
                    Currentdestype === "activity"
                      ? "bg-[#E3EFFD]"
                      : "bg-[#EAEAEA]"
                  } px-6 py-2 rounded-tl-2xl rounded-br-3xl flex items-center space-x-3 cursor-pointer`}
                >
                  <img src={classicExplore2} alt="classicExplore1.svg" />
                  <h1 className="text-[#394149] sm:text-[22px] font-semibold leading-normal">
                    Explore Activities
                  </h1>
                </div>
              </div>
            </div>
            <div className="h-[80%] w-full mt-[20px]">
              <Slider {...settings}>{Cardrender}</Slider>
            </div>
          </div>
          <div className="w-full py-10 z-10 relative">
            <div className="sm:w-max p-4 m-auto bg-white rounded-3xl">
              <div className="flex space-x-2 border-b">
                <img src={upclasic} alt="upclasic.svg" />
                <div>
                  <h1 className="text-[#394149] text-lg font-semibold leading-normal">
                    Upcoming Bookings
                  </h1>
                  <p className="text-sm font-semibold leading-normal text-[#000] opacity-50">
                    Know what’s about to happen
                  </p>
                </div>
              </div>

              {Data &&
                Data.map((item, index) => {
                  return (
                    <div key={index} className="flex py-2 border-b">
                      <div className="h-[60px] w-[60px]">
                        <img
                          className="h-full w-full"
                          src={
                            "https://media.istockphoto.com/id/1328279749/vector/upcoming-events-origami-style-speech-bubble-banner-sticker-design-template-with-upcoming.jpg?s=612x612&w=0&k=20&c=4c8pcEI744X-_bU6J_PQCfZsEzseDN-ekzpVaNmrdJo="
                          }
                          alt="Logo.png"
                        />
                      </div>
                      <div className="w-[90%]">
                        <span className="text-textcolor text-xs font-semibold leading-normal">
                          {item?.bookingInfo?.hotelName} <br />
                          <Link
                            className="text-blue-500 text-xs underline"
                            to={`/hotels/ticket/${item?._id}`}
                          >
                            View
                          </Link>
                        </span>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
        <img
          src={ClassisGoal}
          className="absolute lg:visible invisible left-0 bottom-0"
          alt="ClassisGoal.svg"
        />
      </div>
    </>
  );
};

export default ClasicMap;
