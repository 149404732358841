import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import ReduxProvider from "./lib/Redux/Reduxprovider";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { GoogleOAuthProvider } from "@react-oauth/google";
import ScrollToTop from "./components/ScrollTop";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <GoogleOAuthProvider clientId="367270583214-ui5a6sd52et1ajmij5qnbeekoi7f0trq.apps.googleusercontent.com">
    <BrowserRouter>
      <ReduxProvider>
        <App />
      </ReduxProvider>
      <ToastContainer autoClose={false} />
      <ScrollToTop />
    </BrowserRouter>
  </GoogleOAuthProvider>
);
