import React from "react";
import { Sports, Yoga, HeartInHand, Sahkura } from "../../../assets";
import { sliceAndEllipsis } from "../../../constants/apiEndPoints/functions";
import { Tooltip } from "antd";
import "./treasures.css";

const Treasures = () => {
  const treasuresdata = [
    {
      title: "notiTravel upto 1000 miles in totalng",
    },
    {
      title: "Travel upto 1000 miles in total",
    },
    {
      title: "Travel upto 1000 miles in total",
    },
    {
      title: "Travel upto 1000 miles in total",
    },
    {
      title: "notTravel upto 1000 miles in totalng",
    },
    {
      title: "Travel upto 1000 miles in total",
    },
    {
      title: "Travel upto 1000 miles in total",
    },
    {
      title: "Travel upto 1000 miles in total",
    },
    {
      title: "Travel upto 1000 miles in total",
    },
    {
      title: "Travel upto 1000 miles in total",
    },
    {
      title: "Travel upto 1000 miles in total",
    },
    {
      title: "Travel upto 1000 miles in total",
    },
    {
      title: "Travel upto 1000 miles in total",
    },
    {
      title: "Travel upto 1000 miles in total",
    },
    {
      title: "Travel upto 1000 miles in total",
    },

    {
      title: "Travel upto 1000 miles in total",
    },
  ];

  const renderTreasures = treasuresdata.map((items) => {
    return (
      <div className=" font-Montserrat flex items-center flex-col lg:flex-row mt-[10px] pr-[15px] border borer-[#C9C9C9] py-[20px] px-[20px] lg:border-none lg:py-[0px] lg:px-[0px]  ">
        <div className="flex flex-col md:flex-row w-[100%] lg:[70%]  items-center ">
          <div className="h-[65px] max-w-[65px] min-w-[65px] rounded-lg bg-[#F0F0F0] flex justify-center items-center ">
            <img src={Sports} className="w-[35px] h-[35px] " />
          </div>

          {/* //// */}

          <div className="flex flex-col ml-[15px] item-start justify-start ">
            <p
              className=" text-[#394149] text-[14px] font-bold text-start "
              style={{ transform: "translateY(10px)" }}
            >
              {items?.title.length > 30 ? (
                <Tooltip title={items?.title}>
                  <p className="w-[220px]">
                    {sliceAndEllipsis(items?.title, 30)}
                  </p>
                </Tooltip>
              ) : (
                <p className="w-[220px]">{items?.title}</p>
              )}
            </p>
            <div className="flex justify-start items-center">
              <div className="flex flex-col  mt-[15px] ">
                <div className="h-[22px] w-[22px] rounded-full shadow-md border-2 border-white bg-[#C9C9C9] "></div>
                <h1 className="text-[#C9C9C9] text-[10px] font-bold ">0ml</h1>
              </div>
              <div className="bg-[#F0F0F0] w-[120px] xl:w-[350px] h-[20px] "></div>
              <div className="flex flex-col  mt-[15px] ">
                <div className="h-[22px] w-[22px] rounded-full shadow-md border-2 border-white bg-[#C9C9C9] "></div>
                <h1 className="text-[#C9C9C9] text-[10px] font-bold ">
                  2000ml
                </h1>
              </div>

              {/* <div class="wrapper">
                <div class="container">
                  <div class="slider-track"></div>
                  <input
                    type="range"
                    min="0"
                    max="100"
                    value="30"
                    id="slider-1"
                    oninput="slideOne()"
                  />
                  <input
                    type="range"
                    min="0"
                    max="100"
                    value="70"
                    id="slider-2"
                    oninput="slideTwo()"
                  />
                </div>
              </div> */}
            </div>
          </div>

          {/* ///// */}

          <div className="flex flex-col justify-center items-center ml-[30px] w-[150px] mt-[15px] md:mt-[0px] ">
            <div className="relative h-[30px] w-[30px] ">
              <img
                className="absolute w-[30px] h-[30px] ml-[-30px] rounded-full hover:z-50 "
                src="https://images.pexels.com/photos/1222271/pexels-photo-1222271.jpeg?auto=compress&cs=tinysrgb&w=600"
              />
              <img
                className="absolute w-[30px] h-[30px] rounded-full ml-[-15px] hover:z-50  "
                src="https://images.pexels.com/photos/1222271/pexels-photo-1222271.jpeg?auto=compress&cs=tinysrgb&w=600"
              />
              <img
                className="absolute w-[30px] h-[30px] rounded-full ml-[0px] hover:z-50 "
                src="https://images.pexels.com/photos/1222271/pexels-photo-1222271.jpeg?auto=compress&cs=tinysrgb&w=600"
              />
              <img
                className="absolute w-[30px] h-[30px] rounded-full ml-[15px] hover:z-50 "
                src="https://images.pexels.com/photos/1222271/pexels-photo-1222271.jpeg?auto=compress&cs=tinysrgb&w=600"
              />
            </div>
            <p className="text-[10px] font-bold text-[#394149] ">
              292 people Joined
            </p>
          </div>

          {/* ///// */}

          <div className="flex justify-start ml-[20px]  mt-[15px] md:mt-[0px]  ">
            <div className="  h-[60px] w-[60px] border border-[#FBC56D] bg-[#FEF3E2] rounded-full flex justify-center items-center ">
              <img src={Sahkura} className="w-[35px] h-[35px] " />
            </div>

            <div className="  h-[60px] w-[60px] border  bg-[#E9F4FF] rounded-full flex justify-center items-center  ml-[10px]  ">
              <h1 className=" text-[#007BFF] font-extrabold text-[20px]">XP</h1>
            </div>
          </div>

          {/* ///// */}
        </div>

        <div className="flex justify-center lg:justify-end w-[100%] lg:w-[30%] ">
          <button
            style={{
              background: "linear-gradient(180deg, #00B2FF 0%, #007BFF 100%)",
              borderRadius: "40px",
            }}
            className="text-[18px] text-white h-[50px] w-[180px] font-body mt-[25px] lg:mt-[0px] mr-[0px] xl:mr-[20px]  "
          >
            Participate Now
          </button>
        </div>
      </div>
    );
  });

  return (
    <>
      <div className="h-[75vh] overflow-x-auto font-Montserrat ">
        {renderTreasures}
      </div>
    </>
  );
};

export default Treasures;
