import React from "react";
import Imagescard from "../images/Imagescard";
import { modi } from "../../assets";

function convertToTitleCase(str) {
  return str.replace(/([A-Z])/g, " $1").replace(/^./, function (s) {
    return s.toUpperCase();
  });
}
const Amenities = ({ items }) => {
  const { key, value } = items;
  return (
    <>
      <div className="w-full flex  ">
        <Imagescard h={"25"} w={"25"} img={modi} />
        <h1 className="text-[16] font-normal ml-[8px] ">
          {convertToTitleCase(key)}
        </h1>
      </div>
    </>
  );
};

export default Amenities;
