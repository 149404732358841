import { createSlice, current } from "@reduxjs/toolkit";

const initialState = {
  user: null,
  isAuthenticated: false,
  email: "",
  currentUser: null,
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
      state.isAuthenticated = true;
    },
    setemail: (state, action) => {
      state.email = action.payload;
    },
    clearUser: (state) => {
      state.user = null;
      state.isAuthenticated = false;
    },
    SetCurrentuser: (state, action) => {
      state.currentUser = action.payload;
    },
  },
});

export const { setUser, clearUser, setemail, SetCurrentuser } =
  userSlice.actions;
export const selectUser = (state) => state.user.user;
export default userSlice.reducer;
