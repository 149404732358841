import React, { useState } from "react";
import { LeftLines, RightLines, Trash, VisaLogo } from "../../assets";
import { GoPlusCircle } from "react-icons/go";
import DeletePopup from "../../basecomponents/DeletePopup";
import { CARDS } from "../../constants/apiEndPoints";
import httpRequest from "../../axios";
  import { toast } from 'react-toastify';

const Cards = ({
  setAddCartActive = () => {},
  data = [],
  loading = false,
  FetchCards = () => {},
}) => {
  const [Loading, setLoading] = useState(false);
  const [selectedCard, setSelectedCard] = useState(null);

  const handleDeleteClick = (card) => {
    setSelectedCard(card);
  };

  const handleDeleteConfirm = async () => {
    setLoading(true);
    try {
      const response = await httpRequest.delete(
        `${CARDS}/${selectedCard?._id}`
      );
      if (response.status === 200 || response.status === 201) {
        setLoading(false);
        toast.success(response?.data?.message);
        setSelectedCard(null);
        FetchCards();
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  };

  return (
    <div className=" md:p-4 py-4 bg-white rounded-xl">
      <h1 className="text-textcolor text-base font-semibold leading-normal ">
        Add/Remove Credit Cards
      </h1>
      <div className="flex md:flex-row flex-col md:space-x-4 md:space-y-0 space-y-4 py-4">
        <div className="md:w-[90%] w-full grid md:grid-cols-2 grid-cols-1 gap-4">
          {data &&
            data.map((card) => {
              return (
                <div
                  style={{ backgroundColor: `${card?.color}` }}
                  key={card?._id}
                  className={` p-4  rounded-xl`}
                >
                  <div className="flex">
                    <div className="w-1/2">
                      <p className="text-sm text-white font-semibold leading-normal">
                        **** ***** **** ****
                      </p>
                      <h1 className="text-white text-xl font-semibold leading-normal">
                        {card?.cardNumber}
                      </h1>
                    </div>
                    <div className="w-1/2 relative">
                      <img className="-mt-4 ml-8" src={RightLines} alt="" />
                      <img
                        className="absolute right-3 top-3 cursor-pointer"
                        src={Trash}
                        onClick={() => handleDeleteClick(card)}
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="flex">
                    <div className="w-1/2 relative">
                      <img className="-ml-4 -mb-4" src={LeftLines} alt="" />
                      <div className="absolute top-2 space-y-1">
                        <p className="text-white text-sm font-semibold leading-normal">
                          {card?.cardHolderName}
                        </p>
                        <p className="text-white text-sm font-semibold leading-normal">
                          {card?.expiryDate}
                        </p>
                      </div>
                    </div>
                    <div className="w-1/2 flex justify-end items-end">
                      <img className="h-8" src={VisaLogo} alt="" />
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
        <div
          onClick={() => setAddCartActive(true)}
          className="md:w-[10%] w-full border-2 border-dashed border-primary rounded-xl flex items-center justify-center cursor-pointer md:h-[187px] h-[50px]"
        >
          <GoPlusCircle className="text-primary text-3xl" />
        </div>
      </div>
      {selectedCard && (
        <DeletePopup
          isOpen={!!selectedCard}
          onCancel={() => setSelectedCard(null)}
          onDelete={handleDeleteConfirm}
          loading={Loading}
        />
      )}
    </div>
  );
};

export default Cards;
