import React from 'react'
import Imagescard from '../../components/images/Imagescard'

const MapPhotoPin = () => {
  return (
    <>
      {/* <img src='' /> */}
    </>
  )
}

export default MapPhotoPin