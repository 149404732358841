import React from "react";
import { useDispatch } from "react-redux";
import {
  setSidebarimg,
  setheader,
  setpaths,
} from "../../lib/Redux/slices/webData";
import { SettingsSidebar } from "../../assets";
import { SettingsLinks } from "./Links";
import Mainlayout from "../../layouts/Mainlayout";
import { Link } from "react-router-dom";

const paths = [
  { label: "MyGuide", url: "/user-profile" },
  { label: "My Odyssey", url: "/" },
  { label: "Settings", url: "/settings" },
];
const Settings = () => {
  const dispatch = useDispatch();
  dispatch(setpaths(paths));
  dispatch(setheader("Settings"));
  dispatch(setSidebarimg(SettingsSidebar));

  return (
    <Mainlayout>
      <div className="grid md:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-5 font-Montserrat">
        {SettingsLinks &&
          SettingsLinks.map((item, index) => {
            return (
              <Link to={item?.link}>
                <div
                  style={{
                    boxShadow: "0px 8px 12px 0px rgba(62, 63, 122, 0.04)",
                  }}
                  key={index}
                  className="bg-white rounded-xl px-6 py-8 cursor-pointer hover:scale-105 duration-150"
                >
                  <img className="h-12 w-12" src={item?.img} alt="LinkImg.svg" />
                  <p className="text-[#7C7C7C] font-semibold leading-normal text-base my-4">
                    {item?.title}
                  </p>
                </div>
              </Link>
            );
          })}
      </div>
    </Mainlayout>
  );
};

export default Settings;
