import React, { useState } from "react";
import { dateFormatter } from "../../constants/apiEndPoints/functions";
import countryList from "react-select-country-list";
import validator from "validator";
  import { toast } from 'react-toastify';

const UploadTravelDocuments = ({
  isOpen = false,
  loading = false,
  handleClose = () => {},
  onSubmit = () => {},
  onchange = () => {},
  alldocuments = () => {},
  initialvalues = {},
}) => {
  const Countries = countryList().getData();
  const [passportNumber, setPassportNumber] = useState(
    initialvalues?.passportNumber || "" // Set initial value to empty string
  );
  const [CountryCode, setCountryCode] = useState("AF");

  const handleDateChange = (e) => {
    const selectedDate = e.target.value;
    const today = new Date().toISOString().split("T")[0];
    if (selectedDate < today) {
      e.target.value = today;
    }
    onchange(e);
  };

  const handleSubmit = () => {
    if (validator.isPassportNumber(passportNumber, CountryCode)) {
      onSubmit();
      alldocuments({
        passportNumber: "",
        expirationDate: "",
        nationality: "",
      });
      setPassportNumber(""); // Clear passport number input
    } else {
      toast.error("Invalid Passport Number");
    }
  };

  const handleCountryChange = (e) => {
    onchange(e);
    let value = e.target.value;
    let country = Countries.find((country) => country.label === value);
    if (country) {
      let countryCode = country.value;
      setCountryCode(countryCode);
    }
  };

  return (
    isOpen && (
      <div className="fixed inset-0 flex items-center justify-center z-[100] bg-gray-800 bg-opacity-70">
        <div className="bg-white p-8 rounded-md">
          <div className="mb-4">
            <label
              className="block mb-2 text-sm font-semibold"
              htmlFor="nationality"
            >
              Nationality:
            </label>
            <select
              id="nationality"
              name="nationality"
              onChange={handleCountryChange}
              value={initialvalues?.nationality}
              className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
            >
              {Countries.map((country) => (
                <option key={country.value} value={country.label}>
                  {country.label}
                </option>
              ))}
            </select>
          </div>
          <div className="mb-4">
            <label
              className="block mb-2 text-sm font-semibold"
              htmlFor="passportNumber"
            >
              Passport Number:
            </label>
            <input
              id="passportNumber"
              onChange={(e) => {
                setPassportNumber(e.target.value);
                onchange(e);
              }}
              name="passportNumber"
              type="text"
              value={passportNumber}
              className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
            />
          </div>
          <div className="mb-4">
            <label
              className="block mb-2 text-sm font-semibold"
              htmlFor="expirationDate"
            >
              Expiration Date:
            </label>
            <input
              id="expirationDate"
              type="date"
              name="expirationDate"
              onChange={handleDateChange}
              value={dateFormatter(initialvalues?.expirationDate)}
              className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
              min={new Date().toISOString().split("T")[0]}
            />
          </div>

          <div className="mt-4 flex justify-end">
            <button
              onClick={handleSubmit}
              className="px-4 py-2 mr-2 text-white bg-blue-500 rounded-md hover:bg-blue-600 focus:outline-none focus:bg-blue-600"
            >
              {loading ? "Loading..." : "Submit"}
            </button>
            <button
              onClick={() => {
                handleClose();
                setPassportNumber("");
              }}
              className="px-4 py-2 text-gray-600 bg-gray-200 rounded-md hover:bg-gray-300 focus:outline-none focus:bg-gray-300"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    )
  );
};

export default UploadTravelDocuments;
