import React, { useEffect, useState } from "react";
import {
  MapContainer,
  TileLayer,
  Marker,
  Polyline,
  Popup,
  useMap,
} from "react-leaflet";
import { getLocation } from "current-location-geo";
import "leaflet/dist/leaflet.css";
import { Icon } from "leaflet";
import {
  Oval,
  World,
  Hicking,
  TransPortWorld,
  AccomodatoinWorld,
  ActivityWorld,
  Arrowback,
} from "../../assets";
import CustomModel from "../../components/CustomModel/CustomModel";
import MapContent from "./MapContent";
import MapActivit from "./MapActivit";
import MapPills from "./MapPills";
import MyWorldlayout from "../../layouts/MyWorldlayout";
import Loader from "../../components/common/loader";
import { useNavigate } from "react-router-dom";
import httpRequest from "../../axios";
import {
  GetAllDestinations,
  GetSingleDestination,
  USER_BOOKINGS,
} from "../../constants/apiEndPoints";
import { toast } from "react-toastify";
import {
  Culture,
  Gallery,
  Journey,
  Practical,
  Treasures,
  Details,
  DetailsAndRatings,
} from "../destinations";
import { useDispatch, useSelector } from "react-redux";
import { SetCurrentDestination } from "../../lib/Redux/slices/webData";

const MapComponent = ({ CurrentInd }) => {
  const map = useMap();

  useEffect(() => {
    if (CurrentInd.length > 0) {
      map.setView(CurrentInd, map.getZoom());
    }
  }, [CurrentInd, map]);

  return null;
};

const Map = () => {
  const dispatch = useDispatch();
  const [longitude, setLongitude] = useState(null);
  const [latitude, setLatitude] = useState(null);
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [component, setComponent] = useState(null);
  const [headercontent, setHeadercontent] = useState(null);
  const [Mode, setMode] = useState("Exploration Mode");
  const [Methods, setMethods] = useState("Transport booking");
  const navigate = useNavigate();
  const [modelWidth, setModelWidth] = useState();
  const [modelBackArrow, setmodelBackArrow] = useState(false);
  const { CurrentInd = [] } = useSelector((state) => state.web);
  const { user = {}, isAuthenticated } = useSelector((state) => state.user);
  const handleResize = () => {
    const windowWidth = window.innerWidth;
    const width = (windowWidth * 85) / 100;
    return width;
  };

  useEffect(() => {
    getLocation((err, position) => {
      if (err) {
        console.error("Error:", err);
      } else {
        setLongitude(position.longitude);
        setLatitude(position.latitude);
        setLoading(false);
      }
    });
  }, []);

  const LocationIcon = (icon) =>
    new Icon({
      iconUrl: icon,
      iconSize: [16, 16],
      iconAnchor: [16, 32],
    });

  const FetchSingleDestination = async (id) => {
    try {
      const response = await httpRequest.get(GetSingleDestination + `/${id}`);
      if (response?.status === 200 || response?.status === 201) {
        dispatch(SetCurrentDestination(response?.data));
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  };

  const showModal = (content) => {
    if (content === "hotel") {
      setModelWidth(790);
      setComponent(<MapContent />);
      setHeadercontent("Hotel Details");
    }
    if (content === "activity") {
      setModelWidth(790);
      setComponent(<MapActivit />);
      setHeadercontent("Activity Details");
    }
    if (content === "Step1") {
      var getwidth = handleResize();
      setModelWidth(getwidth);
      setHeadercontent("Destination Details & Ratings");
      setComponent(<DetailsAndRatings showModal={showModal} />);
      setmodelBackArrow(false);
    }
    if (content === "Step2") {
      var getwidth = handleResize();
      setModelWidth(getwidth);
      setHeadercontent("Destination Details & Ratings");
      setComponent(<Details showModal={showModal} />);
      setmodelBackArrow(false);
    }
    if (content === "Step3") {
      var getwidth = handleResize();
      setModelWidth(getwidth);
      setHeadercontent("Culture");
      setComponent(<Culture showModal={showModal} />);
      setmodelBackArrow(true);
    }
    if (content === "Step4") {
      var getwidth = handleResize();
      setModelWidth(getwidth);
      setHeadercontent("Journey Throught Time");
      setComponent(<Journey showModal={showModal} />);
      setmodelBackArrow(true);
    }
    if (content === "Step5") {
      var getwidth = handleResize();
      setModelWidth(getwidth);
      setHeadercontent("Practical");
      setComponent(<Practical showModal={showModal} />);
      setmodelBackArrow(true);
    }
    if (content === "Step6") {
      var getwidth = handleResize();
      setModelWidth(getwidth);
      setComponent(<Gallery showModal={showModal} />);
      setHeadercontent("Gallery");
      setmodelBackArrow(true);
    }
    setOpen(true);
  };

  const handleOk = () => setOpen(false);
  const handleCancel = () => setOpen(false);

  const handleClick = (e, bigimg) => {
    if (bigimg) {
      setMethods(e);
      if (e === "Transport booking") navigate("/flights");
      if (e === "Accomodations") navigate("/hotels");
      if (e === "Activities") navigate("/inprogress");
      if (e === "Transport booking") {
        navigate("/flights");
      }
      if (e === "Accomodations") {
        navigate("/hotels");
      }
      if (e === "Activities") {
        var getwidth = handleResize();
        setModelWidth(getwidth);
        setHeadercontent("Quests & Treasures");
        setComponent(<Treasures />);
        setmodelBackArrow(true);

        setOpen(true);
      }
    } else {
      setMode(e);
    }
  };

  const [AllDestination, setAllDestination] = useState([]);
  const [AllTravelModes, setAllTravelModes] = useState([]);
  const [FlightsData, setFlightsData] = useState([]);
  const [HotelsData, setHotelsData] = useState([]);

  const FetchDestination = async () => {
    setLoading(true);
    try {
      const response = await httpRequest.get(`${GetAllDestinations}`);

      if (response.status === 200 || response.status === 201) {
        setAllTravelModes([]);
        setAllDestination(response?.data);
        setFlightsData([]);
        setHotelsData([]);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      return toast.error(error?.response?.data?.message);
    }
  };

  const FetchBookings = async () => {
    setLoading(true);
    try {
      const response = await httpRequest.get(`${USER_BOOKINGS}`);

      if (response.status === 200 || response.status === 201) {
        setAllDestination([]);
        setAllTravelModes(response?.data);
        setFlightsData(response?.data?.flights);
        setHotelsData(response?.data?.hotels);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      return toast.error(error?.response?.data?.message);
    }
  };

  useEffect(() => {
    if (Mode === "Exploration Mode") {
      FetchDestination();
    } else if (user == null || !isAuthenticated) {
      toast.error("Please login to continue");
      setTimeout(() => {
        navigate("/login");
      }, 2000);
    } else {
      FetchBookings();
    }
  }, [Mode]);

  const FlightIcon = new Icon({
    iconUrl: "https://codexperts1.s3.amazonaws.com/Plane.svg",
    iconSize: [40, 70],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
  });

  const HotelIcon = new Icon({
    iconUrl:
      "https://codexperts1.s3.us-east-1.amazonaws.com/1717571917539_8519765%206.svg",
    iconSize: [32, 32],
    iconAnchor: [16, 32],
  });

  const [popupOpen, setPopupOpen] = useState(true);


  

  return (
    <MyWorldlayout>
      <CustomModel
        showModal={showModal}
        header={headercontent}
        handleOk={handleOk}
        handleCancel={handleCancel}
        component={component}
        open={open}
        width={modelWidth}
        modelBackArrow={modelBackArrow}
      />
      <div className="h-[90vh]">
        <div className="border h-full w-full relative">
          <div
            onClick={() => navigate("/clasic-map")}
            className="p-4 bg-white cursor-pointer absolute z-50 rounded-full left-16 top-5 flex items-center space-x-3"
          >
            <img src={Arrowback} alt="" />
            <span className="text-base text-[#000] font-medium leading-normal font-Montserrat">
              Back to Classic GuideMap
            </span>
          </div>
          <div className="absolute w-full z-50 bottom-[25px] bg-transparent grid grid-cols-10">
            <div className="sm:col-span-5 col-span-10 flex justify-end flex-col lg:flex-row lg:justify-start items-start sm:py-0 py-10">
              <div className="sm:ml-[15px]">
                <MapPills
                  fun={handleClick}
                  mode={Mode}
                  Methods={Methods}
                  bigimg={false}
                  borderW={"0"}
                  borderC={"#007BFF"}
                  textC={"#3E4E7E"}
                  img={World}
                  Activebg={"#c0D2DC"}
                  ActiveColor={"white"}
                  bg={"white"}
                  imgH={30}
                  imgW={30}
                  content={"Exploration Mode"}
                />
              </div>
              <div className="sm:ml-[15px] mt-[8px] lg:mt-[0px] lg:ml-[30px]">
                {user !== null && isAuthenticated && (
                  <MapPills
                    fun={handleClick}
                    mode={Mode}
                    Methods={Methods}
                    bigimg={false}
                    borderW={"0"}
                    borderC={"#007BFF"}
                    textC={"#3E4E7E"}
                    img={Hicking}
                    bg={"white"}
                    Activebg={"#c0D2DC"}
                    ActiveColor={"white"}
                    imgH={30}
                    imgW={30}
                    content={"Travel Mode"}
                  />
                )}
              </div>
            </div>
            <div className="sm:col-span-5 col-span-10 font-Montserrat flex sm:space-x-10 md:flex-row flex-col sm:justify-end justify-start sm:px-5 px-10">
              <div
                onClick={() => navigate("/flights")}
                className={`shadow-lg w-max bg-white hover:text-[#007BFF] hover:bg-[#B1E4FA] text-[#3E4E7E] space-x-14 px-4 relative flex justify-end items-center h-[50px] rounded-3xl border-[2px] hover:border-[#007BFF] border-transparent  cursor-pointer duration-200`}
              >
                <img
                  className="absolute left-[-30px] h-[96px] w-[90px]"
                  src={TransPortWorld}
                  alt=""
                />
                <p className="sm:text-sm text-xs font-bold mx-4 ">
                  Transport booking
                </p>
              </div>
              <div
                onClick={() => navigate("/hotels")}
                className={`shadow-lg w-max mt-10 md:mt-0 bg-white hover:text-[#007BFF] hover:bg-[#B1E4FA] text-[#3E4E7E] space-x-14 px-4 relative flex justify-end items-center h-[50px] rounded-3xl border-[2px] hover:border-[#007BFF] border-transparent  cursor-pointer duration-200`}
              >
                <img
                  className="absolute left-[-30px] h-[80px] w-[80px]"
                  src={AccomodatoinWorld}
                  alt=""
                />
                <p className="sm:text-sm text-xs font-bold mx-4 ">
                  Accomodations
                </p>
              </div>
            </div>
          </div>
          <div className="absolute z-10 h-[100%] w-[100%]">
            {!loading ? (
              latitude &&
              longitude && (
                <MapContainer
                  style={{ height: "100%", width: "100%" }}
                  center={[latitude, longitude]}
                  zoom={10}
                  minZoom={2}
                  maxBounds={[
                    [-90, -180],
                    [90, 180],
                  ]}
                >
                  <MapComponent CurrentInd={CurrentInd} />
                  <TileLayer
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                  />
                  {AllDestination.length > 0 &&
                    AllDestination.map((position, index) => (
                      <Marker
                        eventHandlers={{
                          click: () => {
                            showModal("Step1");
                            FetchSingleDestination(position?._id);
                          },
                        }}
                        key={index}
                        position={[position?.latitude, position?.longitude]}
                        icon={
                          new Icon({
                            iconUrl: position?.icon?.imageUrl,
                            iconSize: [24, 24],
                            iconAnchor: [16, 32],
                          })
                        }
                      />
                    ))}
                  {FlightsData.length > 0 &&
                    FlightsData.map((item, index) => {
                      return (
                        <>
                          <Marker
                            position={[
                              item?.departure?.lat,
                              item?.departure?.lon,
                            ]}
                            eventHandlers={{
                              click: () => {
                                navigate(`/flights/ticket/${item?._id}`);
                              },
                            }}
                            icon={FlightIcon}
                          >
                            <Popup>
                              <strong>Departure:</strong>{" "}
                              {item?.departure?.name}
                            </Popup>
                          </Marker>
                          <Polyline
                            positions={[
                              [item?.departure?.lat, item?.departure?.lon],
                              [item?.arrival?.lat, item?.arrival?.lon],
                            ]}
                            // color="red"
                            pathOptions={{
                              color: "#007BFF",
                              dashArray: "5, 5",
                            }}
                          />
                          <Marker
                            position={[item?.arrival?.lat, item?.arrival?.lon]}
                            icon={FlightIcon}
                            eventHandlers={{
                              click: () => {
                                navigate(`/flights/ticket/${item?._id}`);
                              },
                            }}
                          >
                            <Popup>
                              <strong>Arrival:</strong> {item?.arrival?.name}
                            </Popup>
                          </Marker>
                        </>
                      );
                    })}

                  {/* // Booked Hotels Rendring */}

                  {HotelsData.length > 0 &&
                    HotelsData.map((item, index) => (
                      <Marker
                        key={index}
                        position={[item?.address?.lat, item?.address?.lon]}
                        icon={HotelIcon}
                        eventHandlers={{
                          click: () => {
                            navigate(`/hotels/ticket/${item?._id}`);
                          },
                        }}
                      >
                        <div className="popup-container">
                          {popupOpen && (
                            <Popup
                              autoPan={false}
                              onClose={() => setPopupOpen(false)}
                            >
                              <div>
                                <p>
                                  <strong>Name:</strong>{" "}
                                  {item?.bookingInfo?.hotelName}
                                </p>
                              </div>
                            </Popup>
                          )}
                        </div>
                      </Marker>
                    ))}

                  <Marker
                    position={[latitude, longitude]}
                    icon={LocationIcon(Oval)}
                  />
                </MapContainer>
              )
            ) : (
              <div className="h-screen w-full flex items-center justify-center">
                <Loader />
              </div>
            )}
          </div>
        </div>
      </div>
    </MyWorldlayout>
  );
};

export default Map;
