import React from "react";
import { CrownPic, Mask } from "../../assets";
import { Link, useNavigate } from "react-router-dom";
import { FaXmark } from "react-icons/fa6";

const Bookings = [
  {
    img: Mask,
    title: "Find the best and affordable holiday packages for Baku.",
    link: "Click here to learn more",
  },
  {
    img: Mask,
    title: "Find the best and affordable holiday packages for Baku.",
    link: "Click here to learn more",
  },
  {
    img: Mask,
    title: "Find the best and affordable holiday packages for Baku.",
    link: "Click here to learn more",
  },
];

const Upcomming = ({ setUpCommig = () => {} }) => {
  const navigate = useNavigate();
  return (
    <div>
      <div className="UpcomingStyle p-4 relative">
        <div className="pb-4 border-b relative">
          <div>
            <h1 className="text-textcolor text-lg font-semibold leading-normal">
              Upcoming Bookings
            </h1>
            <span className="text-black opacity-50 text-sm font-semibold leading-normal">
              Know what’s about to happen
            </span>
          </div>
          <div className="lg:hidden block">
            <FaXmark
              className="text-red-600 mx-2 text-xl absolute right-0 top-0"
              onClick={() => setUpCommig(false)}
            />
          </div>
        </div>
        {Bookings &&
          Bookings.map((item, index) => {
            return (
              <div key={index} className="flex py-2 border-b">
                <div className="h-[60px] w-[60px]">
                  <img
                    className="h-full w-full"
                    src={item?.img}
                    alt="Logo.png"
                  />
                </div>
                <div className="w-[90%]">
                  <span className="text-textcolor text-xs font-semibold leading-normal">
                    {item?.title} <br />
                    <Link className="text-blue-500 text-xs underline" to={""}>
                      {item?.link}
                    </Link>
                  </span>
                </div>
              </div>
            );
          })}
      </div>
      <div className="py-4 space-y-2">
        <div className="h-36 flex justify-center items-start overflow-hidden">
          <img className="h-auto" src={CrownPic} alt="CrownPic" />
        </div>
        <h1 className="text-textcolor text-lg font-medium leading-normal text-center">
          Subscribe to our Premium membership
        </h1>
        <button
          onClick={() => navigate("/settings/Subscription")}
          className="w-full py-3 text-base bg-secondary rounded-md text-textcolor font-semibold leading-normal"
        >
          Upgrade to Pro
        </button>
      </div>
    </div>
  );
};

export default Upcomming;
