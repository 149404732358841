import httpRequest from "../../axios";
import { GET_USER_INFORMATION } from ".";

// Date Formater

export const dateFormatter = (dateString) => {
  const dateObj = new Date(dateString);
  const day = String(dateObj.getUTCDate()).padStart(2, "0");
  const month = String(dateObj.getUTCMonth() + 1).padStart(2, "0");
  const year = dateObj.getUTCFullYear();
  return `${year}-${month}-${day}`;
};

export const FetchUserInfo = async () => {
  try {
    const resposne = await httpRequest.get(GET_USER_INFORMATION);

    if (resposne.status === 200 || resposne.status === 201) {
      return resposne.data.user;
    }
  } catch (error) {
    console.log(console.log(error));
  }
};

export const sliceAndEllipsis = (inputString, number) => {
  if (inputString.length < number) {
    return inputString;
  } else {
    var firstWords = inputString.slice(0, number);
    return (firstWords += "...");
  }
};

export function formatDate(dateString) {
  const date = new Date(dateString);
  const options = { weekday: 'long', month: 'long', day: 'numeric' };
  return date.toLocaleDateString('en-US', options);
}


export const convertToLocalTime = (utcDateStr) => {
  const utcDate = new Date(utcDateStr);

  const localTime = utcDate.toLocaleTimeString([], {
    hour: "numeric",
    minute: "2-digit",
    hour12: true,
  });

  return localTime;
};
