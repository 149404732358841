import React, { useEffect, useState } from "react";
import FavoritesLayout from "../../layouts/FavLayout";
import { useDispatch } from "react-redux";
import {
  SetFlightBooked,
  setheader,
  setpaths,
  setSidebarimg,
} from "../../lib/Redux/slices/webData";
import { Cup, AccomodationS, FlightTicket } from "../../assets";
  import { toast } from 'react-toastify';
import httpRequest from "../../axios";
import { Booking } from "../../constants/apiEndPoints";
import { useNavigate } from "react-router-dom";
import moment from "moment";

const paths = [
  { label: "MyGuide", url: "/user-profile" },
  { label: "My Odyssey", url: "/" },
  { label: "Settings", url: "/settings" },
  { label: "Favorites", url: "/settings/favroutes/flights" },
];

const FavroutesFlightsN = () => {
  const dispatch = useDispatch();
  dispatch(setpaths(paths));
  dispatch(setheader("Bookings"));
  dispatch(setSidebarimg(AccomodationS));
  const navigate = useNavigate();

  const [Data, setData] = useState([]);

  const GetData = async () => {
    try {
      const response = await httpRequest.get(`${Booking}/flight`);
      if (response.status === 200 || response.status === 201) {
        setData(response?.data);
        dispatch(SetFlightBooked(response?.data?.length));
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  };

  useEffect(() => {
    GetData();
  }, []);

  return (
    <FavoritesLayout flight={Data?.length}>
      {Data.length > 0 ? (
        Data.map((data, i) => {
          return (
            <div className="w-full font-Montserrat">
              <div className="">
                <div className="grid md:grid-cols-3 grid-cols-1 rounded font-Montserrat gap-3 shadow-sm bg-white  mt-6">
                  <div className="relative">
                    <img
                      className="h-full w-full object-cover"
                      src={
                        "https://images.unsplash.com/photo-1569629743817-70d8db6c323b?q=80&w=1796&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                      }
                      alt="First Hotel image"
                    />
                    <span className="text-[12px] absolute top-3 right-7 bg-white bg-opacity-50 px-2 py-1 rounded-[10px]">
                      9 images
                    </span>
                  </div>

                  <div className="col-span-2  p-4 ">
                    <div className="grid md:grid-cols-3 grid-cols-1 font-Montserrat gap-3 pb-3 w-full border-b">
                      <div className="col-span-2 ">
                        <p className="font-semibold pb-2 text-[18px] md:text-[20px]">
                          {data?.bookingInfo?.career} (
                          {data?.bookingInfo?.aircraftCode})
                        </p>
                        <div className="flex items-center gap-2 pb-2">
                          <p className="text-[12px] md:text-[14px]">
                            {
                              data?.bookingInfo?.flightOffers[0]?.departure
                                ?.iataCode
                            }{" "}
                            {moment(
                              data?.bookingInfo?.flightOffers[0]?.departure?.at
                            ).format("DD-MM-YYYY HH:mm A")}
                          </p>
                          <div className="w-[40%] flex justify-center items-start">
                            <img
                              className="-rotate-90 h-16"
                              src={FlightTicket}
                              alt="FlightTicket.svg"
                            />
                          </div>
                          <p className="text-[12px] md:text-[14px]">
                            {
                              data?.bookingInfo?.flightOffers[0]?.arrival
                                ?.iataCode
                            }{" "}
                            {moment(
                              data?.bookingInfo?.flightOffers[0]?.arrival?.at
                            ).format("DD-MM-YYYY")}
                          </p>
                        </div>

                        <div className="flex gap-2 pb-2">
                          <img src={Cup} alt="cup icon" />
                          <p className="font-medium text-[12px] md:text-[14px]">
                            {" "}
                            <span className="font-semibold">
                              20+
                            </span> Aminities{" "}
                          </p>
                        </div>
                        <div className="flex items-center gap-2 text-[12px] md:text-[14px]">
                          <button class=" text-black font-normal py-1 px-2 border border-[#8DD3BB] rounded-[0.25rem]">
                            4.2
                          </button>
                          <p className="font-medium">
                            <span className="font-semibold ">Very Good </span>{" "}
                            371 reviews
                          </p>
                        </div>
                      </div>
                      <div className="text-[#FF8682] md:text-right text-[12px] md:text-[14px]">
                        <p className="text-[24px] font-bold">
                          {data?.paymentAmount}
                          <span className="text-[12px] h-1"></span>
                        </p>
                        <p className="text-black font-[12px] ">excl.tax</p>
                      </div>
                    </div>
                    <div className="flex gap-5 pt-3 text-[12px] md:text-[14px]">
                      <button class=" text-black font-normal py-1 px-2 border border-[#8DD3BB] rounded-[0.25rem]">
                        🖤
                      </button>
                      <button
                        onClick={() => navigate(`/flights/ticket/${data?._id}`)}
                        className="bg-blue-500 hover:bg-blue-400 transition-colors duration-300 text-white py-2 w-full rounded-md"
                      >
                        View Place
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })
      ) : (
        <h1 className="sm:p-10 p-5">No Data Found</h1>
      )}
    </FavoritesLayout>
  );
};

export default FavroutesFlightsN;
