import React, { useState } from "react";
import {
  Logo,
  verifyImg,
  ForgotCenter,
  Forgotpass,
  Login1,
  Building,
  SignupSlide1,
  UpperLogin2,
  Globalimg,
  Personal,
  Plane,
} from "../../../assets";
import Inputs from "../../../components/common/inputs";
import AuthButton from "../../../components/common/Buttons";
import { Sliders } from "../../../components/common";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import httpRequest from "../../../axios";
import { RESET_PASSWORD_REQUEST } from "../../../constants/apiEndPoints";
import { setemail } from "../../../lib/Redux/slices/userslice";

const SliderArr = [
  <div
    style={{
      backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${Forgotpass})`,
      backgroundSize: "cover",
      backgroundPosition: "top",
      position: "relative",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    }}
    className="sm:h-[700px] h-[600px]"
  >
    {/* Background Image with Blur */}
    <div
      className="absolute inset-0 bg-cover bg-center filter blur-[3px]"
      style={{
        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${Forgotpass})`,
        backgroundSize: "cover",
        backgroundPosition: "top",
      }}
    ></div>

    {/* Content */}
    <div className="relative w-[90%] flex flex-col items-center space-y-6 font-Montserrat">
      <img className="h-auto w-[45%]" src={ForgotCenter} alt="ForgotCenter" />
      <div className="flex flex-col items-center space-y-3">
        <h1 className="text-white sm:text-3xl text-xl font-black">
          ACTIVITIES & FUN
        </h1>
        <p className="text-white text-center sm:text-xl text-base font-semibold">
          EXPLORE HUNDREDS OF OUR PROFESSIONALS OFFERING AMAZING ACTIVITIES
          AROUND THE GLOBE
        </p>
      </div>
    </div>
  </div>,
  <div
    style={{
      backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${Login1})`,
      backgroundSize: "cover",
      backgroundPosition: "center",
      position: "relative",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    }}
    className="sm:h-[700px] h-[600px]"
  >
    {/* Background Image with Blur */}
    <div
      className="absolute inset-0 bg-cover bg-center filter blur-[3px]"
      style={{
        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${Login1})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    ></div>

    {/* Content */}
    <div className="relative w-[90%] flex flex-col items-center space-y-6 font-Montserrat">
      <img className="h-auto w-[45%]" src={Building} alt="Building" />
      <div className="flex flex-col items-center space-y-3">
        <h1 className="text-white sm:text-3xl text-xl font-black">
          ACCOMODATIONS
        </h1>
        <p className="text-white text-center sm:text-xl text-base font-semibold">
          WORRIED ABOUT YOUR STAY? WE GOT THAT COVERED TOO. FIND THE BEST
          ACCOMODATIONS AROUND YOU.
        </p>
      </div>
    </div>
  </div>,
  <div
    style={{
      backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${SignupSlide1})`,
      backgroundSize: "cover",
      backgroundPosition: "center",
      position: "relative",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    }}
    className="sm:h-[700px] h-[600px]"
  >
    {/* Background Image with Blur */}
    <div
      className="absolute inset-0 bg-cover bg-center filter blur-[3px]"
      style={{
        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${SignupSlide1})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    ></div>

    {/* Content */}
    <div className="relative w-[90%] flex flex-col items-center space-y-6 font-Montserrat">
      <img className="h-auto w-[45%]" src={UpperLogin2} alt="UpperLogin2" />
      <div className="flex flex-col items-center space-y-3">
        <h1 className="text-white sm:text-3xl text-xl font-black">
          QUESTS & CHALLENGES
        </h1>
        <p className="text-white text-center sm:text-xl text-base font-semibold">
          ENJOY YOUR TRIP BY COMPLETING FUN QUESTS AND EARN REWARDS
        </p>
      </div>
    </div>
  </div>,
  <div className="relative sm:h-[700px] h-[600px] flex justify-center items-center">
    {/* Background Image with Blur */}
    <div
      className="absolute inset-0 bg-cover bg-center filter blur-[3px]"
      style={{
        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${verifyImg})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    ></div>

    {/* Content */}
    <div className="relative w-[90%] flex flex-col items-center space-y-6 font-Montserrat">
      <img className="h-auto w-[45%]" src={Globalimg} alt="Global" />
      <div className="flex flex-col items-center space-y-3">
        <h1 className="text-white text-center sm:text-3xl text-xl font-black">
          LIVE INTERACTIVE MAPS
        </h1>
        <p className="text-white text-center sm:text-xl text-base font-semibold">
          EXPLORE MAPS IN REALTIME AND PLAN WHAT YOU WANNA DO
        </p>
      </div>
    </div>
  </div>,
  <div className="relative h-[700px] flex justify-center items-center">
    {/* Background Image with Blur */}
    <div
      className="absolute inset-0 bg-cover bg-center filter blur-[3px]"
      style={{
        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${Personal})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    ></div>

    {/* Content */}
    <div className="relative w-[90%] flex flex-col items-center space-y-6 font-Montserrat">
      <img className="h-auto w-[75%]" src={Plane} alt="Plane" />
      <div className="flex flex-col items-center space-y-3">
        <h1 className="text-white text-3xl font-black text-center">
          FLIGHT BOOKINGS
        </h1>
        <p className="text-white text-center text-xl font-semibold">
          GET YOUR FLIGHTS BOOKED WITH EASE WITHIN MYGUIDEON
        </p>
      </div>
    </div>
  </div>,
];

const Password = () => {
  const { email } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [Loading, setLoading] = useState(false);

  const [formData, setFormData] = useState({
    password: "",
    confirmpassword: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handlesubmit = async () => {
    if (formData?.password == "") {
      toast.error("Password is required");
      return;
    }

    if (formData?.password.length < 8) {
      toast.error("Password must be at least 8 characters long");
      return;
    }

    if (formData?.confirmpassword == "") {
      toast.error("Confirm password is required");
      return;
    }

    if (formData?.confirmpassword.length < 8) {
      toast.error("Confirm password must be at least 8 characters long");
      return;
    }
    if (formData?.password !== formData?.confirmpassword) {
      toast.error("Password do not match!");
      return;
    }
    setLoading(true);
    try {
      let response;
      response = await httpRequest.post(RESET_PASSWORD_REQUEST, {
        email: email,
        newPassword: formData?.password,
      });

      if (response.status == 200 || response.status == 201) {
        toast.success(response?.data?.message);
        navigate("/login");
        dispatch(setemail(""));
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="2xl:p-24 md:p-16 p-6 min-h-screen w-full grid lg:grid-cols-2 grid-cols-1 2xl:gap-32 gap-10 font-Montserrat">
      <div className=" col-span-1 space-y-6">
        <Link to={"/"}>
          <img src={Logo} alt="Logo.svg" />
        </Link>
        <div className="space-y-4">
          <h1 className="font-Montserrat font-bold sm:text-[40px] text-2xl">
            Set a password
          </h1>
          <p className="text-BlackishGreen font-normal sm:text-base text-sm leading-5">
            Your previous password has been reseted. Please set a new password
            for your account.
          </p>
        </div>
        <div className="grid sm:grid-cols-2 gap-3">
          <div className=" col-span-2">
            <Inputs
              label={"Create Password"}
              sizelabel={"14px"}
              sizeinput={"16px"}
              type={"password"}
              color={"#1C1B1F"}
              fontweight={"400"}
              ActiveColor={"#1C1B1F"}
              htmlfor={"newpassword"}
              name={"password"}
              onchange={handleInputChange}
            />
          </div>
          <div className=" col-span-2">
            <Inputs
              label={"Re-enter Password"}
              sizelabel={"14px"}
              sizeinput={"16px"}
              type={"password"}
              color={"#1C1B1F"}
              fontweight={"400"}
              ActiveColor={"#1C1B1F"}
              htmlfor={"confirmpassword"}
              name={"confirmpassword"}
              onchange={handleInputChange}
            />
          </div>

          <div className="col-span-2">
            <AuthButton
              loading={Loading}
              onclick={handlesubmit}
              text={"Set password"}
            />
          </div>
        </div>
      </div>
      <div className="col-span-1 rounded-[30px] overflow-hidden ">
        <Sliders items={SliderArr} />
      </div>
    </div>
  );
};

export default Password;
