import React, { useEffect, useState } from "react";
import Users from "./Users";
import { Navbar } from "../../components";
import ViewChat from "./Chats";
import { GET_CHAT_HISTORY, READ_MESSAGES } from "../../constants/apiEndPoints";
import httpRequest from "../../axios";
  import { toast } from 'react-toastify';
import Files from "./Files";

const MainChat = () => {
  const [UserHistory, setUserHistory] = useState([]);
  const GetuserHistory = async () => {
    try {
      const response = await httpRequest.get(GET_CHAT_HISTORY);
      if (response.status === 200 || response.status === 201) {
        setUserHistory(response?.data?.users);
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  };

  useEffect(() => {
    GetuserHistory();
  }, []);

  const [chatResponse, setchatResponse] = useState(false);
  const [FileResponse, setFileResponse] = useState(false);

  const markedAsRead = async (id) => {
    try {
      await httpRequest.post(READ_MESSAGES, { otherUserId: id });
      GetuserHistory();
    } catch (err) {
      console.error("Error marking messages as read:", err);
    }
  };

  return (
    <div className="w-full bg-[#F0F0F0]">
      <Navbar />
      <div className="grid grid-cols-4 gap-1">
        <div className="col-span-1 lg:block hidden">
          <Users markedAsRead={markedAsRead} UserHistory={UserHistory} />
        </div>
        <div className="lg:col-span-3 col-span-4">
          <ViewChat GetuserHistory={GetuserHistory} />
        </div>
      </div>
      <div
        onClick={() => setchatResponse(true)}
        className="bg-[#000000a6] lg:hidden block fixed top-48 -left-3 p-2 text-white font-semibold rotate-90"
      >
        <h1>Chats</h1>
      </div>
      <div
        onClick={() => setFileResponse(true)}
        className="bg-[#000000a6] lg:hidden block fixed top-48 -right-3 p-2 text-white font-semibold -rotate-90"
      >
        <h1>Files</h1>
      </div>

      <div
        onClick={() => setchatResponse(false)}
        className={`h-screen w-full bg-[#000000c4]  duration-200 fixed z-10 top-0 ${
          chatResponse ? "left-0" : "-left-full"
        } `}
      >
        <div
          onClick={(e) => e.stopPropagation()}
          className="h-screen w-[90%] sm:w-1/2 bg-white"
        >
          <Users UserHistory={UserHistory} setchatResponse={setchatResponse} />
        </div>
      </div>
      <div
        onClick={() => setFileResponse(false)}
        className={`h-screen w-full bg-[#000000c4] flex justify-end duration-200 fixed z-10 top-0 ${
          FileResponse ? "right-0" : "-right-full "
        } `}
      >
        <div
          onClick={(e) => e.stopPropagation()}
          className="h-screen w-[90%] sm:w-1/2 bg-white"
        >
          <Files setFileResponse={setFileResponse} />
        </div>
      </div>
    </div>
  );
};

export default MainChat;
