import React from 'react'
import Imagescard from '../../images/Imagescard'
import { Shiningstar } from '../../../assets'

const Cards = ({ data }) => {
  const { title, img, rating, starting } = data
  return (
    <>
      <div style={{overflow:'hidden' }} className='w-[100%] flex justify-center items-center sm:flex sm:justify-center sm:items-center md:justify-start ' >
        <div className='w-[90%] sm:w-[85%] md:w-[90%] lg:w-[95%] xl:w-[280px] bg-white rounded-xl overflow-hidden ' >
        <img src={img} className='w-[100%] h-[200px] ' alt="" />
        <div className='h-[95px] w-full border ' >
          <div className='flex justify-between w-[90%] ml-[5%] mt-[15px] ' >
            <h1 className='text-[#394149] text-[16px] font-bold  ' >{title}</h1>
            <div className='flex items-center ' >
              <Imagescard img={Shiningstar} h={'15'} w={'15'} />
              <div className='ml-[5px] flex ' >
                 <div className='text-[12px] font-medium ' >5.0</div>
                <div className='ml-[5px] text-[12px] font-medium' >{`(${rating})`}</div>
              </div>
            </div>
          </div>
          <div  className='w-[90%] ml-[5%] mt-[9px] ' >
            <div className='text-[14px] text-[#999] font-bold  ' >Starting at <span className='text-[#007BFF] text-[20px] ' >${starting}</span>/hour</div>
          </div>
        </div>
        </div>
      </div>
    </>
  )
}

export default Cards